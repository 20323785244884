import { Component } from 'react';
import { Redirect, Switch } from 'react-router-dom';

import AssessmentTypeAccreditation from 'components/assessments/AssessmentTypeAccreditation';
import ProtectedRoute from 'components/elements/ProtectedRoute';
import Search from 'components/service-provider/Search';
import ServiceProviderAddEdit from 'components/service-provider/ServiceProviderAddEdit';
import ServiceProviderDetails from 'components/service-provider/ServiceProviderDetails';

class ServiceProviders extends Component {
    render() {
        const { match } = this.props;

        return (
            <Switch>
                <ProtectedRoute
                    path={`${match.url}`}
                    component={Search}
                    exact
                />
                <ProtectedRoute
                    path={`${match.url}/:companyId/all-assessments/:id/view`}
                    component={AssessmentTypeAccreditation}
                />
                <ProtectedRoute
                    path={`${match.url}/:id/edit`}
                    component={ServiceProviderAddEdit}
                />
                <ProtectedRoute
                    path={`${match.url}/:id`}
                    component={ServiceProviderDetails}
                />

                <Redirect to={match.url} />
            </Switch>
        );
    }
}

export default ServiceProviders;
