import ArchiveClient from 'components/dialogs/ArchiveClient';
import { ButtonLink, Icon, List } from 'components/elements';
import { SUBSCRIPTION_STATUSES } from 'constants/client';
import { YES_NO_OPTIONS } from 'constants/general';
import { NONE_SELECTED_OPTION } from 'constants/none-selected';
import { delay, getColourClass } from 'helpers/helpers';
import {
    FILTER_SUBSCRIPTION_LEVELS,
    FILTER_SUBSCRIPTION_STATUSES,
} from 'interface';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { HTTP, Response } from 'service';

const ClientList = (props) => {
    const { isArchive, match } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [collection, setCollection] = useState({});
    const [filter, setFilter] = useState({});
    const [filterData, setFilterData] = useState({});
    const [isActive, setIsActive] = useState(isArchive ? 'false' : 'true');
    const [activeClient, setActiveClient] = useState();
    const [isOpenArchivePopup, setIsOpenArchiveClient] = useState(false);

    const getRoute = (row, column) => {
        return isArchive
            ? '/clients/archived-list/' + row.id
            : '/clients/list/' + row.id;
    };

    const openArchivePopup = async (client) => {
        setActiveClient(client);
        await delay(0);
        setIsOpenArchiveClient(true);
    };

    const columns = [
        { name: 'Name', property: 'name', sortable: true, grow: 3 },
        {
            name: 'Subscription Status',
            property: 'subscriptionStatus',
            type: 'callback',
            sortable: false,
            grow: 1,
            callback: (row) => {
                if (row.subscriptionStatus) {
                    return (
                        <span
                            className={getColourClass(
                                row,
                                'subscriptionStatus'
                            )}
                        >
                            {SUBSCRIPTION_STATUSES[row.subscriptionStatus]}
                        </span>
                    );
                }

                return 'No Data';
            },
        },
        {
            name: 'Level',
            property: 'subscriptionLevel',
            type: 'callback',
            sortable: false,
            grow: 1,
            callback: ({ subscriptionLevel, subscriptionStatus }) => {
                return subscriptionStatus === 'ACTIVE'
                    ? subscriptionLevel
                    : 'No Data';
            },
        },
        {
            name: 'Elogs CAFM Integration',
            property: 'hasElogs',
            sortable: true,
            grow: 1,
            type: 'callback',
            callback: (row) => {
                return row.hasElogs ? 'Yes' : 'No';
            },
        },
        {
            name: 'Meridian Integration',
            property: 'hasMeridian',
            sortable: true,
            grow: 1,
            type: 'callback',
            callback: (row) => {
                return row.hasMeridian ? 'Yes' : 'No';
            },
        },
        {
            name: '',
            property: '',
            type: 'button',
            grow: 1,
            onClick: (event, client) => {
                openArchivePopup(client);
            },
            intent: 'primary',
            text: isArchive ? 'Unarchive' : 'Archive',
            right: true,
            className: 'icon-free',
        },
        {
            name: '',
            property: '',
            type: 'nav-button',
            grow: 1,
            route: getRoute,
            buttonIntent: 'primary',
            buttonText: 'View',
            right: true,
        },
    ];

    const updateFilterData = (key, value) => {
        setFilterData({
            ...filterData,
            [key]: value,
        });
    };

    const defaultFilters = {
        isActive: isActive,
        order: 'name',
    };

    const filterConfig = {
        name: {
            type: 'typeahead',
            label: 'Client Name',
            key: 'name',
            url: `/clients`,
            responseProperty: 'clients',
            valueProperty: 'name',
            searchProperty: 'name',
            onSelect: (item) => updateFilterData('client', item),
            defaultValue: filterData?.client,
        },
        subscriptionStatus: {
            type: 'single-select',
            defaultValue: NONE_SELECTED_OPTION.value,
            label: 'Subscription Status',
            key: 'subscriptionStatus',
            options: FILTER_SUBSCRIPTION_STATUSES,
        },
        subscriptionLevel: {
            type: 'single-select',
            defaultValue: NONE_SELECTED_OPTION.value,
            label: 'Level',
            key: 'subscriptionLevel',
            options: FILTER_SUBSCRIPTION_LEVELS,
        },
        elogsIntegration: {
            type: 'single-select',
            defaultValue: NONE_SELECTED_OPTION.value,
            label: 'Elogs CAFM Integration',
            key: 'hasElogs',
            options: [NONE_SELECTED_OPTION, ...YES_NO_OPTIONS],
        },
        meridianIntegration: {
            type: 'single-select',
            defaultValue: NONE_SELECTED_OPTION.value,
            label: 'Meridian Integration',
            key: 'hasMeridian',
            options: [NONE_SELECTED_OPTION, ...YES_NO_OPTIONS],
        },
    };

    const load = (filter, clear) => {
        let params = { ...filter };

        if (clear) {
            params = { ...defaultFilters, ...params };
        }

        setFilter(params);

        setIsLoading(true);

        if (params.client) {
            params.name = params.client?.name;
            delete params.client;
        }

        HTTP.get('/clients', params).then(
            (response) => {
                response.data.data = response.data.clients;

                setIsLoading(false);
                setCollection(response.data);

                return true;
            },
            (response) => {
                setIsLoading(false);

                toast.error('Unable to fetch a list of Clients');
                return false;
            }
        );
    };

    const getHeaderButton = (collection, match) => {
        return Response.getLink(collection, 'create') === null ? null : (
            <ButtonLink
                type="button"
                to={`${match.url}/add`}
                intent="primary"
                className="float-right"
            >
                <Icon icon="plus-circle" />
                Add new Client
            </ButtonLink>
        );
    };

    const onClear = () => {
        setFilter({});
        setFilterData({});
        load({}, true);
    };

    useEffect(() => {
        setIsActive(isArchive ? 'false' : 'true');
        load({ isActive: isArchive ? 'false' : 'true' }, true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isArchive]);

    return (
        <div className="ClientList">
            <List
                title="Client List"
                columns={columns}
                load={(filter) => load({ ...filter, ...filterData })}
                collection={collection}
                filter={filter}
                filters={filterConfig}
                defaultFilter={defaultFilters}
                isLoading={isLoading}
                headerButton={() => getHeaderButton(collection, match)}
                onClear={onClear}
                noRecordsFoundText="There are no Clients"
            />
            <ArchiveClient
                client={activeClient}
                isOpen={isOpenArchivePopup}
                onClose={() => {
                    setIsOpenArchiveClient(false);
                    load({ isActive: isActive }, false);
                }}
            />
        </div>
    );
};

export default ClientList;
