import { useEffect } from "react";
import { LoadingIndicator } from "components/elements";

const ThankYou = (props) => {
    const { history, forwardUrl, messageContent } = props;

    useEffect(() => {
        clearTimeout(window.purchaseTimer);
        window.purchaseTimer = setTimeout(() => { history.replace(forwardUrl); }, 5000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="page">
            <div className="page-wrapper no-styling">
                <div className="thank-you-message">
                    <h2>Thank you for your purchase !</h2>
                    { messageContent }
                    <div className="center"><LoadingIndicator /></div>
                </div>
            </div>
        </div>
    );
};

export default ThankYou;
