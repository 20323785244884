import DashboardCard from 'components/dashboard/DashboardCard';

import { ButtonLink } from 'components/elements';
import { ListCollection, TaskData } from 'interface';
import { useEffect, useState } from 'react';
import { getRouteFromType, loadTaskList } from 'service/Company/TaskService';

import './dashboard-task-card.scss';

interface DashboardTaskCardProps {
    isLoading: boolean;
    taskCount: number;
}

const DashboardTaskCard = (props: DashboardTaskCardProps) => {
    const { isLoading = false, taskCount = 0 } = props;

    const [tasks, setTasks] = useState<ListCollection<TaskData>>(
        {} as ListCollection<TaskData>
    );

    useEffect(() => {
        load();
    }, []);

    const load = async () => {
        const tasks = await loadTaskList({
            limit: 2,
        });

        setTasks(tasks);
    };

    const getTaskTitle = () => {
        return `Tasks Outstanding (${taskCount})`;
    };

    const getTasks = () => {
        const taskData = tasks.data;
        if (!taskData?.length) {
            return 'You have no Outstanding Tasks';
        }

        const tasksContent = taskData.map((task: TaskData, index) => (
            <div key={`task-wrapper-${index}`} className="task-wrapper">
                <div className="task-message">{task.message}</div>
                <div className='task-card-resolve-wrapper'>
                    <ButtonLink
                        className="task-resolve-btn icon-free"
                        to={getRouteFromType(task)}
                        intent="primary"
                        type="button"
                    >
                        Resolve
                    </ButtonLink>
                </div>
            </div>
        ));

        return <div className="task-list">{tasksContent}</div>;
    };

    return (
        <DashboardCard
            isLoading={isLoading}
            title={getTaskTitle()}
            moreText="View All Tasks"
            moreDetailsLink="/tasks"
        >
            {getTasks()}
        </DashboardCard>
    );
};

export default DashboardTaskCard;
