import { Button, FormGroup } from '@blueprintjs/core';
import { ComponentProps, FormEvent, useState } from 'react';
import { useSelector } from 'react-redux';

import Confirmation from 'components/dialogs/Confirmation';
import { Icon } from 'components/elements';
import {
    checkIsCaseByCase,
    checkIsReviewDisabled,
    getAssessmentAnswer,
    HTTP,
    Response,
    validateAssessmentReviews,
} from 'service';

import SetDatesDialog from 'components/assessments/tabs/questions/components/SetDatesDialog';
import { AssessmentStateData } from 'interface';

interface QuestionProps extends ComponentProps<any> {
    isSubmitDisabled: boolean;
    handleSubmit: Function;
    handleReviewsSubmit: Function;
}

const QuestionActions = (props: QuestionProps) => {
    const { isSubmitDisabled, handleSubmit, handleReviewsSubmit } = props;
    const [isReturnToServiceProvider, setIsReturnToServiceProvider] =
        useState();
    const [showSetDatesDialog, setShowSetDatesDialog] = useState(false);
    const [showAssessorConfirmation, setShowAssessorConfirmation] =
        useState(false);
    const {
        assessmentTypeInstance,
        changes,
        reviewChanges,
    }: AssessmentStateData = useSelector((state: any) => state.assessments);
    const isReviewsDisabled = checkIsReviewDisabled();

    const confirmAssessmentSubmit = async () => {
        if (!validateAssessmentReviews()) {
            return false;
        }

        setShowAssessorConfirmation(false);
        const reviewStatusUrl = Response.getLink(
            assessmentTypeInstance,
            'check-review-returnable-status'
        );
        const otherReviewData: { [key: string]: string } = {};
        if (checkIsCaseByCase()) {
            otherReviewData['validFrom'] = getAssessmentAnswer('validFrom');
            otherReviewData['expiryDate'] = getAssessmentAnswer('expiryDate');
        }
        const statusData = await HTTP.action(
            'post',
            reviewStatusUrl,
            {
                reviews: reviewChanges,
                ...otherReviewData,
            },
            {},
            'Could not fetch review status'
        );

        const reviewStatus = statusData?.data;

        setIsReturnToServiceProvider(reviewStatus);

        if (reviewStatus !== true && checkIsCaseByCase()) {
            setShowSetDatesDialog(true);
        } else {
            setShowAssessorConfirmation(true);
        }
    };

    const closeDialog = () => {
        setShowSetDatesDialog(false);
    };

    const sendToReviewerButton = () => {
        if (typeof assessmentTypeInstance.clientName !== 'undefined') {
            return null;
        }

        return (
            <Button
                intent="primary"
                className="me-2"
                disabled={isSubmitDisabled || isReviewsDisabled}
                onClick={(event) => {
                    handleReviewsSubmit(event, 'sendToReviewer');
                }}
            >
                Send to Reviewer
            </Button>
        );
    };

    return (
        <>
            {checkIsCaseByCase() && (
                <>
                    <SetDatesDialog
                        onClose={closeDialog}
                        isDialogOpen={showSetDatesDialog}
                        onSubmithandler={(event: FormEvent) =>
                            handleReviewsSubmit(event, 'submit')
                        }
                    ></SetDatesDialog>
                </>
            )}

            <Confirmation
                isOpen={showAssessorConfirmation}
                onYes={(event) => handleReviewsSubmit(event, 'submit')}
                onClose={() => {
                    setShowAssessorConfirmation(false);
                }}
            >
                <div className="m-2">
                    {isReturnToServiceProvider
                        ? 'This assessment has pending questions and will be passed back to the Service Provider'
                        : 'This assessment will be marked as complete'}
                </div>
            </Confirmation>

            {!assessmentTypeInstance._links['assess-answers'] ? null : (
                <FormGroup className="float-right">
                    {sendToReviewerButton()}
                    <Button
                        intent="primary"
                        className="me-2"
                        type="submit"
                        disabled={isSubmitDisabled || isReviewsDisabled}
                        onClick={(event) => {
                            handleReviewsSubmit(event);
                        }}
                    >
                        Save Progress
                    </Button>
                    <Button
                        intent="success"
                        disabled={isSubmitDisabled || isReviewsDisabled}
                        onClick={confirmAssessmentSubmit}
                    >
                        Submit
                    </Button>
                </FormGroup>
            )}

            {!assessmentTypeInstance._links['review-answers'] ? null : (
                <FormGroup className="float-right">
                    <Button
                        intent="primary"
                        className="me-2"
                        disabled={isSubmitDisabled || isReviewsDisabled}
                        onClick={(event) => {
                            handleReviewsSubmit(event, 'sendToAssessor');
                        }}
                    >
                        Send to Assessor
                    </Button>
                    <Button
                        intent="primary"
                        className="me-2"
                        type="submit"
                        disabled={isSubmitDisabled || isReviewsDisabled}
                        onClick={(event) => {
                            handleReviewsSubmit(event);
                        }}
                    >
                        Save Progress
                    </Button>
                </FormGroup>
            )}

            {!assessmentTypeInstance._links['update-answers'] ? null : (
                <FormGroup className="float-right">
                    {!Object.keys(changes).length ? null : (
                        <Button
                            intent="primary"
                            className="me-2"
                            disabled={isSubmitDisabled}
                            type="submit"
                        >
                            <Icon icon="paper-plane" />
                            Save Progress
                        </Button>
                    )}
                    <Button
                        intent="success"
                        disabled={isSubmitDisabled}
                        onClick={(event) => {
                            handleSubmit(event, 'submit');
                        }}
                    >
                        <Icon icon="paper-plane" />
                        Submit
                    </Button>
                </FormGroup>
            )}
        </>
    );
};

export default QuestionActions;
