import { Button, Switch } from '@blueprintjs/core';
import Icon from 'components/elements/Icon';
import { FlatObject } from 'interface';
import { SyntheticEvent, useEffect, useState } from 'react';
import StateHandler from 'service/StateHandler';
import './index.scss';

/**
 * Each switch field should have the properties as below:
 *
 * title: string,
 * value: ID string,
 * name: string,
 * checked: boolean,
 * children: undefined or array of switches
 *
 */
const MultiLevelSwitch = (props: any) => {
    const { field, onUpdate, classNames, clear } = props;
    const [showChildren, setShowChildren] = useState(!!field.showChildren);

    const className =
        'multi-level-switch ' +
        (field.children ? 'parent ' : 'single ') +
        (showChildren ? 'showChildren ' : 'hideChildren ') +
        classNames;

    // set all to false on clear
    useEffect(() => {
        field.checked = false;
        field.children && checkChildren(field.children);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clear]);

    const setTreeValues = (fieldUpdated: FlatObject) => {
        if (field.children) {
            field.checked = field.children.every(
                (child: FlatObject) => child.checked
            );
        }

        // always return parent node (due to propagating changes)
        typeof onUpdate === 'function' && onUpdate(field);
    };

    const checkChildren = (children: FlatObject[]) =>
        children.forEach((child) => {
            child.checked = field.checked;

            if (child.children) {
                checkChildren(child.children);
            }
        });

    const checkChecked = (fieldObj: FlatObject) => {
        field.checked = fieldObj[field.id];

        if (field.children) {
            checkChildren(field.children);
        }

        setTreeValues(field);
    };

    const toggleShowChildren = (e: SyntheticEvent) => {
        e.stopPropagation();
        e.preventDefault();

        field.showChildren = !showChildren;
        setShowChildren(field.showChildren);
    };

    return (
        <div className={className}>
            <label className="item-label">
                <div className="title flex">
                    {field.children ? (
                        <Button
                            type="button"
                            className="icon-only expand-button"
                            onClick={toggleShowChildren}
                        >
                            <Icon icon={showChildren ? 'minus' : 'plus'} />
                        </Button>
                    ) : null}
                    <span>{field.label}</span>
                </div>
                <div className="child-toggle-switch">
                    <Switch
                        className="mb-2 multi-level-switch-input"
                        name={field.id}
                        alignIndicator={'right'}
                        checked={field.checked}
                        onChange={(e) =>
                            checkChecked(StateHandler.getStateObject(e))
                        }
                    />
                </div>
            </label>
            {field.children ? (
                <div className="children">
                    {field.children.map((child: FlatObject, key: number) => (
                        <MultiLevelSwitch
                            field={child}
                            key={'switch-child-' + key}
                            onUpdate={setTreeValues}
                        />
                    ))}
                </div>
            ) : null}
        </div>
    );
};
export default MultiLevelSwitch;
