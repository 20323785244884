export const SET_ADDRESS = 'SET_ADDRESS';
export const CLEAR_ADDRESS = 'CLEAR_ADDRESS';

export {
    setAddress,
    clearAddress
};

function setAddress(address)
{
    return {
        type: SET_ADDRESS,
        address: address,
    };
}

function clearAddress()
{
    return {
        type: CLEAR_ADDRESS
    }
}
