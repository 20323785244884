import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { Filters, HTTP, Location, Response } from "service";
import {
    HTMLSelect,
    LoadingIndicator,
    Paginator,
    SearchWidget,
    SupplyChainCard,
} from "components/elements";
import { Loading } from "components/elements/wrappers";
import { delay } from 'helpers/helpers';
import LeaveSupplyChainDialog from '../LeaveSupplyChainDialog';

const ClientSearch = (props) => {
    const [ isLoading, setIsLoading ] = useState(true);
    const [ searchResponse, setSearchResponse ] = useState({});
    const [ supplyChain, setSupplyChain ] = useState();
    const [ currentSupplyChain, setCurrentSupplyChain ] = useState();
    const [ isInit, setIsInit ] = useState(true);
    const [ company, setCompany ] = useState(true);
    const [ pathname, setPathname ] = useState(props.location.pathname);
    const [ leaveDialogOpen, setLeaveDialogOpen ] = useState(false);
    const [ refreshData, setRefreshData ] = useState(false);

    const isPending = props.location.pathname.includes('pending');
    const sortFilters = [
        {
            key: 'clientName',
            value: 'Client name: A-Z',
        },
        {
            key: '-clientName',
            value: 'Client name: Z-A',
        },
    ];
    const [ defaultFilters, setDefaultFilters ] = useState({
        datagroup: 'search',
        limit: 8,
        order : 'clientName',
        status: isPending ? 'status_pending' : 'status_active',
    });

    const rootResponse = useSelector(state => state.root.response);

    const loadCompanyData = async () => {
        try {
            let companyResponse = await HTTP.get(Response.getLink(rootResponse, 'company'), { datagroup: 'details' })

            setCompany(companyResponse.data);
        } catch (error) {
            toast.error("Unable to load Company Details")
        }
    }

    useEffect(()=> {
        updateSearchFilters(defaultFilters);
        loadCompanyData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(()=> {
        if (pathname !== props.location.pathname) {
            setDefaultFilters(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.location]);

    useEffect(()=> {
        if (!defaultFilters) {
            const newDefaultFilters = {
                datagroup: 'search',
                limit: 8,
                order : 'clientName',
                status: isPending ? 'status_pending' : 'status_active',
            };
            setDefaultFilters(newDefaultFilters);
            updateSearchFilters(newDefaultFilters);
            setPathname(props.location.pathname);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultFilters]);

    const onSubmit = (event) => {
        setIsLoading(true);
        setSupplyChain(void 0);
    }

    const reloadSearch = (event) => {
        updateSearchFilters(defaultFilters);
    }

    const onResult = (searchResults) => {
        setIsInit(false);
        setSearchResponse(searchResults);
        setSupplyChain(searchResults.supplyChainInstances || []);
        setIsLoading(false);
    }

    const resetDialogState = () => {
        setLeaveDialogOpen(false)
        setCurrentSupplyChain(null);
    }

    const reloadData = () => {
        setRefreshData(!refreshData);
    }

    const onReset = async () => {
        const {history} = props;
        history.push('/supply-chains');
        await delay(200);
        if (isPending) {
            history.push('/supply-chains/pending');
        } else {
            history.push('/supply-chains/active');
        }
    };

    const updateSearchFilters = (filters) => {
        const { history, location } = props;
        const urlFilters = Filters.getFilterStateFromUrl(location.search.slice(1), {});
        urlFilters.page = 1;

        Object.keys(filters).forEach(filterName => {
            urlFilters[filterName] = filters[filterName];
        });
        Location.setQueryStringFromObject(history, location, Filters.convertFilterDataToRequestData(urlFilters), false);
    }

    const updateSort = (value) => {
        const filterValue = sortFilters.find(filter => filter.value === value);
        updateSearchFilters({ order: filterValue.key })
    }

    return (
        <>
            <div className={ "SearchPage ClientSearch " + (!isInit ? 'reduced' : '') }>
                <div className="no-styling search-results-widget ">
                    {/* Note: using the delay between setting defaultFilters to reset the Search Widget */}
                    <Loading isLoading={ !defaultFilters }>
                        <SearchWidget
                            searchLink={ { method: 'GET', href: '/supply-chain' } }
                            searchPlaceholder={ `Search ${ isPending ? 'pending' : 'active' } clients` }
                            searchLabel="Client"
                            searchKey="clientName"
                            defaultFilters={ defaultFilters }
                            onSubmit={ onSubmit }
                            onResult={ onResult }
                            onReset={onReset}
                            refreshSearch={refreshData}
                        >
                        </SearchWidget>
                    </Loading>
                </div>

                <div className="no-styling search-results">{
                    isInit ? null : <>
                        <div className="search-results-header">
                            <h1 className="page-title">{ isPending ? 'Pending Client Invitations' : 'Active Clients' }</h1>
                            <div className="search-results-sort">
                                <HTMLSelect
                                    options={ sortFilters.map(f => f.value) }
                                    onChange={ e => updateSort(e.target.value) }
                                />
                            </div>
                        </div>

                        <div className="search-results-body">
                            {
                                !supplyChain || !company || !defaultFilters ? <div className="full-width"><LoadingIndicator /></div> :
                                    supplyChain.length ? (
                                            supplyChain.map((supplyChainInstance, index) => (
                                                <SupplyChainCard
                                                    supplyChainStatus={defaultFilters.status}
                                                    company={company}
                                                    key={`supply-chain-${index}`}
                                                    supplyChainInstance={supplyChainInstance}
                                                    reloadSearch={reloadSearch}
                                                    canLeave={Response.getLink(supplyChainInstance, 'leave')}
                                                    onLeaveClick={() => {
                                                        setLeaveDialogOpen(true);
                                                        setCurrentSupplyChain(supplyChainInstance);
                                                    }}
                                                />
                                        ))
                                    ) :
                                        <div className="no-results">No results found.</div>
                            }
                        </div>
                        <div className="search-results-footer">
                            {
                                isLoading || (searchResponse && !(searchResponse.count > searchResponse.limit)) ? null : (
                                    <Paginator page={ searchResponse.page }
                                        count={ searchResponse.count }
                                        limit={ searchResponse.limit }
                                        onPage={ updateSearchFilters } />
                                )
                            }
                        </div>
                    </>
                }</div>
            </div>
            <LeaveSupplyChainDialog
                isOpen={leaveDialogOpen}
                supplyChain={currentSupplyChain}
                onClose={resetDialogState}
                onSuccess={reloadData}
            />
        </>
    );
}

export default withRouter(ClientSearch);
