import { useEffect, useState } from 'react';

import { DonutChart, LoadingIndicator } from 'components/elements';
import { ELBSPLinkedCounts } from 'interface/Integrations';
import { Link } from 'react-router-dom';
import { loadLinkedServiceProviderNumbers } from 'service/Integration/IntegrationService';
import WidgetCard from './WidgetCard';

interface Props {
    clientId: string;
}

const defaultCounts = {
    linkedCount: 0,
    linkedNotSC: 0,
    notLinkedCount: 0,
};

const ProsureLinkedServiceProviders = (props: Props) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [counts, setCounts] = useState<ELBSPLinkedCounts>(
        defaultCounts as ELBSPLinkedCounts
    );

    useEffect(() => {
        getLinkedCounts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.clientId]);

    const getLinkedCounts = async () => {
        setIsLoading(true);

        const linkedCounts = await loadLinkedServiceProviderNumbers(
            props.clientId
        );

        setCounts(linkedCounts);

        setIsLoading(false);
    };

    const getPercentage = () => {
        const percentage = Math.round(
            (counts.linkedCount /
                (counts.linkedCount +
                    counts.linkedNotSC +
                    counts.notLinkedCount)) *
                100
        );

        if (isNaN(percentage)) {
            return '';
        }

        return `${percentage}%`;
    };

    const renderChart = () => {
        if (isLoading) {
            return <LoadingIndicator />;
        }

        if (
            !isLoading &&
            counts.linkedCount === 0 &&
            counts.linkedNotSC === 0 &&
            counts.notLinkedCount === 0
        ) {
            return <p>No Service Provider Data To Show</p>;
        }

        return (
            <>
                <DonutChart
                    data={[
                        {
                            label: 'Linked To Prosure (In Supply Chain)',
                            name: 'linked',
                            value: counts.linkedCount,
                            link: `#/supply-chain/list?isLinked=LINKED_CLIENT&limit=8&modifiers=%7B%7D&order=-rating&page=1&supplyChain=active`,
                        },
                        {
                            label: 'Linked to Prosure (Not in Supply Chain)',
                            name: 'linkedNotInSupplyChain',
                            value: counts.linkedNotSC,
                        },
                        {
                            label: 'Not Linked to Prosure',
                            name: 'notLinked',
                            value: counts.notLinkedCount,
                        },
                        {
                            label: 'Not Required in CAFM',
                            name: 'notRequired',
                            value: counts.notRequired,
                        },
                    ]}
                    height={300}
                    width={250}
                    legendWidth={200}
                    text={getPercentage()}
                    colours={[
                        {
                            name: 'linked',
                            label: 'Linked To Prosure (In Supply Chain)',
                            colour: '#7bbb4c',
                        },
                        {
                            name: 'linkedNotInSupplyChain',
                            label: 'Linked to Prosure (Not in Supply Chain)',
                            colour: '#ed5565',
                        },
                        {
                            name: 'notLinked',
                            label: 'Not Linked to Prosure',
                            colour: '#414243',
                        },
                        {
                            name: 'notRequired',
                            label: 'Not Required in CAFM',
                            colour: '#d9d917',
                        },
                    ]}
                />
                <p>
                    You have
                    <Link to="/supply-chain/list?supplyChain=active&isLinked=NOT_LINKED">
                        {` ${counts.notLinkedToProsure} `}
                    </Link>
                    Service Providers in Prosure not yet linked to CAFM
                </p>
            </>
        );
    };

    return (
        <WidgetCard
            title="Service Providers in your Elogs CAFM which are linked to you on Prosure"
            tooltip="This chart displays a breakdown of all the Service Providers in your Elogs CAFM, showing what percentage of your CAFM Service Providers are linked and in your active supply chain."
        >
            {renderChart()}
        </WidgetCard>
    );
};

export default ProsureLinkedServiceProviders;
