import { H1 } from '@blueprintjs/core';
import React, { useEffect, useState } from 'react';

import EditClient from 'components/dialogs/EditClient';
import { ItemCount, Paginator, Reload, Table } from 'components/elements';
import { Loading, NoResultsFound } from 'components/elements/wrappers';
import { Company } from 'interface';
import {
    SupplyChain,
    SupplyChainFilter,
    SupplyChainList,
} from 'interface/SupplyChain';
import { DateTime } from 'luxon';
import { Response } from 'service';
import SupplyChainService from 'service/SupplyChain/SupplyChainService';
import SupplyChainDescription from 'components/elements/supply-chain/SupplyChainDescription';

interface CompanyClientsListProps extends React.ComponentProps<any> {
    companyResponse: Company;
}

const CompanyClientsList = (props: CompanyClientsListProps) => {
    const { companyResponse } = props;
    const [isLoading, setIsLoading] = useState(true);
    const [collection, setCollection] = useState<SupplyChainList>(
        {} as SupplyChainList
    );
    const defaultFilter: SupplyChainFilter = {
        order: 'clientName',
        _links: {
            company: companyResponse._links['self'],
        },
        status: 'status_active,status_pending',
    };
    const [filter, setFilter] = useState<SupplyChainFilter>(defaultFilter);
    const [currentClient, setCurrentClient] = useState<SupplyChain>();
    const [isEditOpen, setIsEditOpen] = useState<boolean>(false);

    const onEditClick = async (event: any, supplyChain: SupplyChain) => {
        event.preventDefault();
        event.stopPropagation();

        setCurrentClient(supplyChain);
        setIsEditOpen(true);
    };

    const columns = [
        {
            name: 'Client Name',
            property: 'clientName',
            type: 'callback',
            callback: (row: SupplyChain) => {
                return Response.getLinkAttribute(row, 'client', 'name');
            },
            sortable: true,
            grow: 1,
        },
        {
            name: 'Status',
            property: 'calculatedStatus',
            type: 'callback',
            callback: (row: SupplyChain) => {
                return (
                    <p className="full-width">
                        <SupplyChainDescription
                            supplyChain={row}
                            history={props.history}
                            location={props.location}
                            match={props.match}
                        />
                    </p>
                );
            },
            sortable: false,
            grow: 3,
        },
        {
            name: 'SP Category',
            property: 'spCategory',
            type: 'callback',
            callback: (row: SupplyChain) => {
                return Response.getLinkAttribute(row, 'sp-category', 'name');
            },
            sortable: false,
            grow: 2,
        },
        {
            name: 'Original Invite Date',
            property: 'createdAt',
            type: 'callback',
            callback: (row: SupplyChain) => {
                return (
                    <>
                        {DateTime.fromISO(
                            row.originalInvitedAtDate ?? row.createdAt
                        ).toLocaleString(DateTime.DATE_MED)}
                    </>
                );
            },
            sortable: false,
            grow: 1,
        },
        {
            name: 'Original Join Date',
            property: 'acceptedAt',
            type: 'callback',
            callback: (row: SupplyChain) => {
                const date = row.originalAcceptedAtDate ?? row.acceptedAt;
                if (!date) {
                    return '-';
                }
                return (
                    <>
                        {DateTime.fromISO(date).toLocaleString(
                            DateTime.DATE_MED
                        )}
                    </>
                );
            },
            sortable: false,
            grow: 1,
        },
        {
            name: 'Current Join Date',
            property: 'acceptedAt',
            type: 'date',
            sortable: false,
            grow: 1,
        },
        {
            name: 'Current Expiry Date',
            property: 'expiresOn',
            type: 'date',
            sortable: false,
            grow: 1,
        },
        {
            name: '',
            property: '',
            type: 'button',
            intent: 'primary',
            text: 'Edit',
            onClick: onEditClick,
            grow: 2,
            right: true,
        },
    ];

    const load = (params?: object) => {
        (async () => {
            const filter = { ...defaultFilter, ...params };
            const response = await SupplyChainService.loadSupplyChainsByCompany(
                filter
            );

            setFilter(filter);
            setCollection(response);
            setIsLoading(false);
        })();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(load, []);

    return (
        <>
            <div className="CompanyClientsList">
                <H1>
                    Clients
                    <ItemCount count={collection.count} />
                    <Reload load={load} />
                </H1>

                <Loading isLoading={isLoading}>
                    <NoResultsFound count={collection.count}>
                        <Table
                            data={collection.supplyChainInstances}
                            columns={columns}
                            onSort={load}
                            ordering={filter.order}
                        />

                        <Paginator
                            page={collection.page}
                            count={collection.count}
                            limit={collection.limit}
                            onPage={load}
                        />
                    </NoResultsFound>
                </Loading>
            </div>
            <EditClient
                client={currentClient}
                isOpen={isEditOpen}
                onClose={() => {
                    setIsEditOpen(false);
                    load();
                }}
            />
        </>
    );
};

export default CompanyClientsList;
