import { Table } from 'components/elements';
import { FlatObject } from 'interface';

const RfaActiveDirectors = (props: { data?: FlatObject[] }) => {
    const columns = [
        { name: 'First Name', property: 'first_name' },
        { name: 'Surname', property: 'surname' },
    ];

    return (
        <div className="rfa-active-directors">
            {props.data?.length ? (
                <Table data={props.data} columns={columns} />
            ): "No data to display"}
        </div>
    );
};

export default RfaActiveDirectors;
