document.addEventListener('DOMContentLoaded', () => {
    document.addEventListener(
        'input',
        (event) => {
            if (
                event.target instanceof HTMLInputElement ||
                event.target instanceof HTMLTextAreaElement
            ) {
                event.target.classList.add('isDirty');
            }
        },
        true
    );
    document.addEventListener(
        'focus',
        (event) => {
            if (
                event.target instanceof HTMLInputElement ||
                event.target instanceof HTMLTextAreaElement
            ) {
                event.target.classList.add('isTouched');
            }
        },
        true
    );
    document.addEventListener(
        'blur',
        (event) => {
            if (
                event.target instanceof HTMLInputElement ||
                event.target instanceof HTMLTextAreaElement
            ) {
                event.target.classList.add('isTouched');
            }
        },
        true
    );
    document.addEventListener(
        'submit',
        (event) => {
            event.target
                .querySelectorAll('input,textarea')
                .forEach((elem) => elem.classList.add('isTouched'));
        },
        true
    );
});
