import React, { Component } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import QuestionList from 'components/admin/questions/QuestionList';
import QuestionAddEdit from 'components/admin/questions/QuestionAddEdit';
import QuestionDetails from 'components/admin/questions/QuestionDetails';
import ProtectedRoute from 'components/elements/ProtectedRoute';

class Questions extends Component
{
    render()
    {
        return (
            <div className="Questions">
                <Switch>
                    <ProtectedRoute path="/admin/questions" component={ QuestionList } exact />
                    <ProtectedRoute path="/admin/questions/add" component={ QuestionAddEdit } exact />
                    <ProtectedRoute path="/admin/questions/:id" component={ QuestionDetails } exact />
                    <ProtectedRoute path="/admin/questions/:id/edit" component={ QuestionAddEdit } />

                    <Redirect to="/" />
                </Switch>
            </div>
        );
    }
}

export default Questions;
