import { groupBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { toast } from 'react-toastify';

import InsuranceTable from 'components/company/insurances/InsuranceTable';
import SingleSelect from 'components/elements/filter/SingleSelect';
import { Filter } from 'components/elements/wrappers';
import {
    INSURANCE_STATUSES,
    INSURANCE_TYPES,
    INSURANCE_TYPE_OPTIONS_NONE_SELECTED,
} from 'constants/insuranceTypes';

import {
    CompanyData,
    InsuranceData,
    InsuranceFilter,
    InsuranceFilterData,
    InsuranceResponseData,
} from 'interface';
import { Filters, HTTP, Location, Response } from 'service';

import { getColourClass } from 'helpers/helpers';
import './insurance-list.scss';

interface InsuranceListProps {
    companyResponse: CompanyData;
}

const InsuranceList = (props: InsuranceListProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [filter, setFilter] = useState<InsuranceFilter>(
        {} as InsuranceFilter
    );
    const [filterData, setFilterData] = useState<InsuranceFilterData>(
        {} as InsuranceFilterData
    );
    const [filterOpen, setFilterOpen] = useState(false);
    const [insuranceResponse, setInsuranceResponse] =
        useState<InsuranceResponseData>({} as InsuranceResponseData);

    const history = useHistory();
    const location = useLocation();

    const sectionRoot = location.pathname.includes('service-providers')
        ? `/service-providers/${props.companyResponse.id}`
        : `/company`;

    const DEFAULT_FILTERS = {
        order: '-expiry',
        limit: 999,
    };

    useEffect(() => {
        loadInsurances();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const clearFilters = () => {
        setFilterData({});
        onFilter({});
    };

    const onFilter = (filters: InsuranceFilter) => {
        delete filters.modifiers;
        loadInsurances(
            Object.assign({}, DEFAULT_FILTERS, filters, { page: 1 }),
            true
        );
    };

    const loadInsurances = (params = {}, paramsOverride = false) => {
        if (isLoading) {
            return;
        }

        const { companyResponse } = props;
        const filterParams = paramsOverride
            ? params
            : Object.assign({}, DEFAULT_FILTERS, filter, params);

        Location.setQueryStringFromObject(history, location, filterParams);

        setFilter(filterParams);
        setIsLoading(true);

        HTTP.get(
            Response.getLink(companyResponse, 'insurances'),
            Filters.convertFilterDataToRequestData(filterParams)
        ).then((response) => {
            if (response) {
                const collection = response.data;

                setInsuranceResponse(collection);
                setIsLoading(false);

                return true;
            }

            toast.error('Unable to fetch Insurances');
            setIsLoading(false);

            return false;
        });
    };

    const getInsuranceRoute = (row: InsuranceData) =>
        `${sectionRoot}/insurances/${row.id}`;

    const getCertificateRoute = (row: InsuranceData) =>
        row._links.certificate.download;

    const getInsurancesByStatus = () => {
        const { insurances } = insuranceResponse;

        if (!insurances) {
            return {};
        }

        const {
            ACTIVE = [],
            PENDING = [],
            EXPIRED = [],
        } = groupBy(insurances, 'status');

        return {
            expiredInsurances: EXPIRED,
            activeInsurances: ACTIVE,
            upcomingInsurances: PENDING,
        };
    };

    const columns = [
        {
            name: 'Insurance Type',
            type: 'mapping',
            mapping: INSURANCE_TYPES,
            property: 'insuranceType',
            grow: 2,
        },
        {
            name: 'Status',
            property: 'status',
            colourClass: (row: InsuranceData) => getColourClass(row),
            type: 'mapping',
            mapping: INSURANCE_STATUSES,
            grow: 2,
        },
        {
            name: 'Policy Commencement Date',
            property: 'policyCommencementDate',
            type: 'date',
            sortable: false,
            grow: 2,
        },
        {
            name: 'Policy Expiry Date',
            property: 'policyExpiryDate',
            type: 'date',
            sortable: false,
            grow: 2,
        },
        {
            name: 'Indemnity Limit (£)',
            property: 'indemnityLimit',
            type: 'currency',
            sortable: false,
            grow: 2,
        },
        { name: 'Insurer', property: 'insurer', type: 'text', grow: 2 },
        {
            name: 'Exclusions & Restrictions',
            property: 'exclusionsRestrictions',
            type: 'text',
            grow: 2,
        },
        {
            name: '',
            property: '',
            type: 'external-link',
            grow: 2,
            route: getCertificateRoute,
            buttonIntent: 'primary',
            buttonText: 'View Certificate',
            right: true,
        },
        {
            name: '',
            property: '',
            type: 'nav-button',
            grow: 1,
            route: getInsuranceRoute,
            buttonIntent: 'primary',
            buttonText: 'View',
            right: true,
        },
    ];

    const {
        expiredInsurances = [],
        activeInsurances = [],
        upcomingInsurances = [],
    } = getInsurancesByStatus();

    return (
        <div className="InsuranceList">
            <p>
                Professional Indemnity Insurance holders should take care that
                they do not breach policy conditions through inappropriate
                disclosure; copies of the policy should not be uploaded and the
                request is for a broker issued proof of cover certificate
            </p>

            <Filter
                onClear={clearFilters}
                onFilter={onFilter}
                filterData={filterData}
                isOpen={filterOpen}
            >
                <SingleSelect
                    defaultValue={filterData.types}
                    label="Insurance Type"
                    filterKey="type"
                    options={INSURANCE_TYPE_OPTIONS_NONE_SELECTED}
                />
            </Filter>

            <div className="insurance-tables">
                {/* Active Insurances */}
                <InsuranceTable
                    reloadData={loadInsurances}
                    isFilterOpen={filterOpen}
                    onFilterClick={() => setFilterOpen(!filterOpen)}
                    title="Active Insurance List"
                    isLoading={isLoading}
                    data={activeInsurances}
                    columns={columns}
                    onSort={loadInsurances}
                    ordering={filter.order}
                />

                {/* Pending Insurances */}
                <InsuranceTable
                    reloadData={loadInsurances}
                    title="Upcoming Insurance List"
                    isLoading={isLoading}
                    data={upcomingInsurances}
                    columns={columns}
                    onSort={loadInsurances}
                    ordering={filter.order}
                />

                {/* Expired Insurances */}
                <InsuranceTable
                    reloadData={loadInsurances}
                    title="Expired Insurance List"
                    isLoading={isLoading}
                    data={expiredInsurances}
                    columns={columns}
                    onSort={loadInsurances}
                    ordering={filter.order}
                />
            </div>
        </div>
    );
};

export default InsuranceList;
