import { FormGroup } from '@blueprintjs/core';

import { MultiSelectRenderer } from 'components/elements';
import { CLIENT_ROLE_OPTIONS } from 'constants/role';
import { NotificationSelectProps } from 'interface/Client';
import { Option } from 'interfaces';

const NotificationsSelect = (props: NotificationSelectProps) => {
    const { label, id, getRoles, setRoles } = props;

    const parseUserRoles = (roles: any[] | string) => {
        if (!(roles instanceof Array)) {
            return [];
        }

        roles.forEach((item, index, arr) => {
            arr[index] = { ...item, id: index };
        });

        return roles;
    };

    return (
        <FormGroup
            label={label}
            labelFor={id}
            className={'bp3-inline form-fill multi-select-container'}
        >
            <MultiSelectRenderer
                id={id}
                onRemoveItem={(items: Option[]) => {
                    setRoles(items);
                }}
                onItemSelect={(items: Option[]) => {
                    setRoles(items);
                }}
                fill={true}
                items={parseUserRoles(CLIENT_ROLE_OPTIONS)}
                selectedItems={getRoles}
                valueProperty={'label'}
            />
        </FormGroup>
    );
};

export default NotificationsSelect;
