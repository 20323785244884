import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { updatingBreadcrumbResolves } from 'action/breadcrumb';
import { ButtonLink, Icon } from 'components/elements';
import { Loading } from 'components/elements/wrappers';
import { HTTP, Response } from 'service';
import { H1 } from '@blueprintjs/core';

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = {
    updatingBreadcrumbResolves: updatingBreadcrumbResolves,
};

class RegionDetails extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            id: props.match.params.id,
            isLoading: false,
            response: {},
        };

        this.load = this.load.bind(this);
    }

    componentDidMount()
    {
        this.props.updatingBreadcrumbResolves({region: undefined});
        this.load();
    }

    render()
    {
        const { isLoading, response } = this.state;
        const { match } = this.props;

        return (
            <div className="RegionDetails">
                <Loading isLoading={ isLoading }>
                    <H1 className="clearfix">
                        { response.name }

                        {
                            Response.getLink(response, 'edit') === null ?
                                null :
                                (
                                    <ButtonLink type="button"
                                        to={`${match.url}/edit`}
                                        intent="primary"
                                        className="float-right">
                                        <Icon icon="pencil" />
                                        Edit
                                    </ButtonLink>
                                )
                        }
                    </H1>
                </Loading>
            </div>
        );
    }

    load()
    {
        if (this.state.isLoading) {
            return;
        }

        this.setState({isLoading: true});

        HTTP
            .get(`/regions/${this.state.id}`, {datagroup: 'details'})
            .then((response) => {
                if (response) {
                    this.setState({isLoading: false, response: response.data});
                    this.props.updatingBreadcrumbResolves({region: response.data});

                    return true;
                }

                toast.error('Unable to fetch Region details');
                this.setState({isLoading: false});

                return false;
            });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegionDetails);
