import PropTypes from 'prop-types';
import { ABSTRACT_COLLECTION } from 'constants/collection';

const STATUS_DRAFT = 0;
const STATUS_PENDING_PAYMENT = 1;
const STATUS_PENDING_REVIEW = 2;
const STATUS_ACTION_REQUIRED = 3;
const STATUS_ACCREDITED = 4;
const STATUS_PENDING_RENEWAL = 5;
const STATUS_DOCUMENT_EXPIRED = 6;
const STATUS_PAYMENT_FAILED = 7;
const STATUS_ARCHIVED = 10;

const STATUSES = {
    STATUS_DRAFT: 'status_draft',
    STATUS_PENDING_PAYMENT: 'status_pending_payment',
    STATUS_PENDING_REVIEW: 'status_pending_review',
    STATUS_ACTION_REQUIRED: 'status_action_required',
    STATUS_ACCREDITED: 'status_accredited',
    STATUS_PENDING_RENEWAL: 'status_pending_renewal',
    STATUS_DOCUMENT_EXPIRED: 'status_document_expired',
    STATUS_PAYMENT_FAILED: 'status_payment_failed',
    STATUS_ARCHIVED: 'status_archived',
};

const STATUS_OPTIONS_DRAFT = { label: 'Draft', value: STATUSES.STATUS_DRAFT };
const STATUS_OPTIONS_PENDING_PAYMENT = {
    label: 'Pending Payment',
    value: STATUSES.STATUS_PENDING_PAYMENT,
};
const STATUS_OPTIONS_PENDING_REVIEW = {
    label: 'Pending Review',
    value: STATUSES.STATUS_PENDING_REVIEW,
};
const STATUS_OPTIONS_ACTION_REQUIRED = {
    label: 'Action Required',
    value: STATUSES.STATUS_ACTION_REQUIRED,
};
const STATUS_OPTIONS_ACCREDITED = {
    label: 'Accredited',
    value: STATUSES.STATUS_ACCREDITED,
};
const STATUS_OPTIONS_PENDING_RENEWAL = {
    label: 'Pending Renewal',
    value: STATUSES.STATUS_PENDING_RENEWAL,
};
const STATUS_OPTIONS_DOCUMENT_EXPIRED = {
    label: 'Document Expired',
    value: STATUSES.STATUS_DOCUMENT_EXPIRED,
};
const STATUS_OPTIONS_PAYMENT_FAILED = {
    label: 'Payment Failed',
    value: STATUSES.STATUS_PAYMENT_FAILED,
};
const STATUS_OPTIONS_ARCHIVED = {
    label: 'Archived',
    value: STATUSES.STATUS_ARCHIVED,
};

const STATUS_OPTIONS = [
    STATUS_OPTIONS_DRAFT,
    STATUS_OPTIONS_PENDING_PAYMENT,
    STATUS_OPTIONS_PENDING_REVIEW,
    STATUS_OPTIONS_ACTION_REQUIRED,
    STATUS_OPTIONS_ACCREDITED,
    STATUS_OPTIONS_PENDING_RENEWAL,
    STATUS_OPTIONS_DOCUMENT_EXPIRED,
    STATUS_OPTIONS_PAYMENT_FAILED,
    STATUS_OPTIONS_ARCHIVED,
];

const SHAPE_COMPANY_RESPONSE = {
    _links: PropTypes.object,
    id: PropTypes.string,
    registeredCompanyName: PropTypes.string.isRequired,
    tradingName: PropTypes.string,
    companyBio: PropTypes.string,
    companiesHouseRegistrationNumber: PropTypes.string,
    uniqueTaxReferenceNumber: PropTypes.string,
    vatNumber: PropTypes.string,
    webAddress: PropTypes.string,
    phone: PropTypes.string,
    organisationSize: PropTypes.number,
    organisationType: PropTypes.string,
    primaryContactFirstName: PropTypes.string,
    primaryContactSurname: PropTypes.string,
    primaryContactEmail: PropTypes.string,
    primaryContactPhoneNumber: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    addressLine3: PropTypes.string,
    town: PropTypes.string,
    county: PropTypes.string,
    postcode: PropTypes.string,
    completedAssessments: PropTypes.array,
    supplyChainStatus: PropTypes.number,
    clientRequirements: PropTypes.object,
};

const SHAPE_COMPANY_COLLECTION = Object.assign({}, ABSTRACT_COLLECTION, {
    companies: PropTypes.arrayOf(PropTypes.shape(SHAPE_COMPANY_RESPONSE)),
});

const ORGANISATION_SIZE_1 = '0-1';
const ORGANISATION_SIZE_4 = '2-4';
const ORGANISATION_SIZE_15 = '5-15';
const ORGANISATION_SIZE_30 = '16-30';
const ORGANISATION_SIZE_50 = '31-50';
const ORGANISATION_SIZE_100 = '51-100';
const ORGANISATION_SIZE_200 = '101-200';
const ORGANISATION_SIZE_500 = '201-500';
const ORGANISATION_SIZE_1000 = '501-1000';
const ORGANISATION_SIZE_1001_PLUS = '1001+';

const ORGANISATION_SIZES = [
    ORGANISATION_SIZE_1,
    ORGANISATION_SIZE_4,
    ORGANISATION_SIZE_15,
    ORGANISATION_SIZE_30,
    ORGANISATION_SIZE_50,
    ORGANISATION_SIZE_100,
    ORGANISATION_SIZE_200,
    ORGANISATION_SIZE_500,
    ORGANISATION_SIZE_1000,
    ORGANISATION_SIZE_1001_PLUS,
];

const ORGANISATION_TYPE_CHARITY = 'Charity or Voluntary Sector';
const ORGANISATION_TYPE_COMPANY_LTD_BY_GUARANTEE =
    'Company Limited by Guarantee';
const ORGANISATION_TYPE_LTD = 'Limited Company';
const ORGANISATION_TYPE_LOCAL_AUTHORITY = 'Local Authority';
const ORGANISATION_TYPE_LLP = 'Limited Liability Partnership';
const ORGANISATION_TYPE_OTHER = 'Other';
const ORGANISATION_TYPE_PARTNERSHIP = 'Partnership';
const ORGANISATION_TYPE_PUBLIC_LTD_COMPANY = 'Public Limited Company';
const ORGANISATION_TYPE_SOLE_TRADER = 'Sole Trader or Proprietor';
const ORGANISATION_TYPE_UNIVERSITY = 'University';

const ORGANISATION_TYPES = [
    ORGANISATION_TYPE_CHARITY,
    ORGANISATION_TYPE_COMPANY_LTD_BY_GUARANTEE,
    ORGANISATION_TYPE_LTD,
    ORGANISATION_TYPE_LOCAL_AUTHORITY,
    ORGANISATION_TYPE_LLP,
    ORGANISATION_TYPE_OTHER,
    ORGANISATION_TYPE_PARTNERSHIP,
    ORGANISATION_TYPE_PUBLIC_LTD_COMPANY,
    ORGANISATION_TYPE_SOLE_TRADER,
    ORGANISATION_TYPE_UNIVERSITY,
];

const IS_PART_OF_GROUP_OPTIONS = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
];

const COMPANY_FIELD_TRANSLATIONS = [
    { value: 'organisationSize', label: 'Number of Effective Employees' },
];

const FORM_COMPLETION_PROPERTIES = [
    'registeredCompanyName',
    'companyBio',
    'companiesHouseRegistrationNumber',
    'phone',
    'webAddress',
    'organisationSize',
    'organisationType',
    'minWorkValue',
    'maxWorkValue',
    'is24hour',
    'isServiceBySiteVisit',
    'standardPaymentDays',
    'isActive',
    'logo'
];

export {
    STATUS_OPTIONS,
    STATUSES,
    STATUS_DRAFT,
    STATUS_PENDING_PAYMENT,
    STATUS_PENDING_REVIEW,
    STATUS_ACTION_REQUIRED,
    STATUS_ACCREDITED,
    STATUS_PENDING_RENEWAL,
    STATUS_DOCUMENT_EXPIRED,
    STATUS_PAYMENT_FAILED,
    STATUS_ARCHIVED,
    ORGANISATION_SIZES,
    ORGANISATION_SIZE_1,
    ORGANISATION_SIZE_4,
    ORGANISATION_SIZE_15,
    ORGANISATION_SIZE_30,
    ORGANISATION_SIZE_50,
    ORGANISATION_SIZE_100,
    ORGANISATION_SIZE_200,
    ORGANISATION_SIZE_500,
    ORGANISATION_SIZE_1000,
    ORGANISATION_SIZE_1001_PLUS,
    ORGANISATION_TYPES,
    ORGANISATION_TYPE_CHARITY,
    ORGANISATION_TYPE_COMPANY_LTD_BY_GUARANTEE,
    ORGANISATION_TYPE_LTD,
    ORGANISATION_TYPE_LOCAL_AUTHORITY,
    ORGANISATION_TYPE_LLP,
    ORGANISATION_TYPE_OTHER,
    ORGANISATION_TYPE_PARTNERSHIP,
    ORGANISATION_TYPE_PUBLIC_LTD_COMPANY,
    ORGANISATION_TYPE_SOLE_TRADER,
    ORGANISATION_TYPE_UNIVERSITY,
    IS_PART_OF_GROUP_OPTIONS,
    COMPANY_FIELD_TRANSLATIONS,
    SHAPE_COMPANY_COLLECTION,
    SHAPE_COMPANY_RESPONSE,
    FORM_COMPLETION_PROPERTIES
};
