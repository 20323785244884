const routes = {
    service_providers: {
        href: '/service-providers',
        text: 'Service Providers',
    },
    service_providers_view: {
        href: '/service-providers/:companyId',
        text: '{serviceProvider.registeredCompanyName}',
    },
    service_providers_users: {
        href: '/service-providers/:companyId/users',
        text: 'Users',
    },
    service_providers_users_add: {
        href: '/service-providers/:companyId/users/add',
        text: 'Add a User',
        skipNavigation: true,
    },
    service_providers_services: {
        href: '/service-providers/:companyId/services',
        text: 'Services',
    },
    service_providers_clients: {
        href: '/service-providers/:companyId/clients',
        text: 'Clients',
    },
    service_providers_services_edit: {
        href: '/service-providers/:companyId/services/edit',
        text: 'Edit Services',
        skipNavigation: true,
    },
    service_providers_areas: {
        href: '/service-providers/:companyId/regions',
        text: 'Areas Covered',
    },
    service_providers_areas_edit: {
        href: '/service-providers/:companyId/regions/edit',
        text: 'Edit Areas',
        skipNavigation: true,
    },
    service_providers_assessments: {
        href: '/service-providers/:companyId/assessments',
        text: 'Assessments',
    },
    service_providers_assessments_view: {
        href: '/service-providers/:companyId/assessments/:instanceId/view',
        text: '{assessmentType.name}',
        skipNavigation: true,
    },
    service_providers_insurances: {
        href: '/service-providers/:companyId/insurances?currentOrExpired=current&order=-expiry',
        text: 'Insurances',
        icon: 'house-damage',
    },
    service_providers_insurance_add: {
        href: '/service-providers/:companyId/insurances/add',
        text: 'Add Insurance',
        skipNavigation: true,
    },
    service_providers_insurance_details: {
        href: '/service-providers/:companyId/insurances/:id',
        text: 'View Insurance',
        icon: 'house-damage',
    },
    service_providers_insurance_details_edit: {
        href: '/service-providers/:companyId/insurances/:id/edit',
        text: 'Edit',
        icon: 'house-damage',
    },
    service_providers_certificate_memberships: {
        href: '/service-providers/:companyId/certification-memberships?currentOrExpired=current&order=-expiry',
        text: 'Certification & Memberships',
        icon: 'file-certificate',
    },
    service_providers_certificate_membership_add: {
        href: '/service-providers/:companyId/certification-memberships/add',
        text: 'Add Certification/Membership',
        skipNavigation: true,
    },
    service_providers_certificate_membership_details: {
        href: '/service-providers/:companyId/certification-memberships/:id',
        text: 'View Certification/Membership',
        icon: 'file-certificate',
    },
    service_providers_certificate_membership_edit: {
        href: '/service-providers/:companyId/certification-memberships/:id/edit',
        text: 'Edit',
        icon: 'file-certificate',
    },
    service_providers_contracts: {
        href: '/service-providers/:companyId/contracts',
        text: 'Contracts',
        icon: 'file-certificate',
    },
    service_providers_contracts_add: {
        href: '/service-providers/:companyId/contracts/add',
        text: 'Add Contract',
        skipNavigation: true,
    },
    service_providers_contracts_view: {
        href: '/service-providers/:companyId/contracts/:id',
        text: 'Contract Details',
        icon: 'file-certificate',
    },
    service_providers_contracts_edit: {
        href: '/service-providers/:companyId/contracts/:id/edit',
        text: 'Edit',
        icon: 'file-certificate',
    },
};

export default routes;
