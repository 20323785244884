import { Redirect, Switch } from 'react-router-dom';
import { useState } from 'react';

import ServiceProviderCategoryAddEdit from 'components/clients/sp-category/ServiceProviderCategoryAddEdit';
import ServiceProviderCategoryList from 'components/clients/sp-category/ServiceProviderCategoryList';
import { Loading } from 'components/elements/wrappers';
import { Routing } from 'service';

const ServiceProviderCategory = (props) => {
    const { match, clientResponse, reload } = props;
    const [updateData, setUpdateData] = useState();

    return (
        <div className="ServiceProviderCategories">
            <Loading isLoading={typeof clientResponse.id === 'undefined'}>
                <Switch>
                    <Routing.ProppedRoute
                        path={`${match.url}`}
                        component={ServiceProviderCategoryList}
                        clientResponse={clientResponse}
                        exact
                    />
                    <Routing.ProppedRoute
                        path={`${match.url}/new`}
                        component={ServiceProviderCategoryAddEdit}
                        clientResponse={clientResponse}
                        updateData={updateData}
                        reload={reload}
                        exact
                    />
                    <Routing.ProppedRoute
                        path={`${match.url}/:id/edit`}
                        component={ServiceProviderCategoryAddEdit}
                        clientResponse={clientResponse}
                        mergeResponse={(data) => setUpdateData(data)}
                        reload={reload}
                        exact
                    />
                    <Redirect to={`${match.url}`} />
                </Switch>
            </Loading>
        </div>
    );
};

export default ServiceProviderCategory;
