import { Switch } from '@blueprintjs/core';
import { FlatObject } from 'interface';
import { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';

import { Accordion } from 'components/elements';
import { globalDebounce } from 'helpers/helpers';
import { StateHandler } from 'service';
import './insurances-filter.scss';

interface Props {
    clear: boolean;
    onUpdate: (data: InsuranceFilterState) => void;
    initialValues?: FlatObject;
}

interface InsuranceFilterState {
    [key: string]: any;
}

const InsurancesFilter = (props: Props) => {
    const insuranceOptionsInit = [
        {
            id: 'contractor_all_risk',
            label: 'Contractor All Risk',
            key: 'contractorAllRisk',
            checked: !!props.initialValues?.contractorAllRisk ?? false,
            value: props.initialValues?.contractorAllRisk ?? 0,
        },
        {
            id: 'employers_liability',
            label: 'Employers Liability',
            key: 'employersLiability',
            checked: !!props.initialValues?.employersLiability ?? false,
            value: props.initialValues?.employersLiability ?? 0,
        },
        {
            id: 'products_liability',
            label: 'Products Liability',
            key: 'productsLiability',
            checked: !!props.initialValues?.productsLiability ?? false,
            value: props.initialValues?.productsLiability ?? 0,
        },
        {
            id: 'professional_indemnity',
            label: 'Professional Indemnity',
            key: 'professionalIndemnity',
            checked: !!props.initialValues?.professionalIndemnity ?? false,
            value: props.initialValues?.professionalIndemnity ?? 0,
        },
        {
            id: 'public_liability',
            label: 'Public Liability',
            key: 'publicLiability',
            checked: !!props.initialValues?.publicLiability ?? false,
            value: props.initialValues?.publicLiability ?? 0,
        },
    ];

    const DEFAULT_STATE = {
        professionalIndemnity: false,
        publicLiability: false,
        employersLiability: false,
        productsLiability: false,
        contractorAllRisk: false,
    };
    const { clear, onUpdate } = props;
    const [form, setForm] = useState({
        contractorAllRisk: props.initialValues?.contractorAllRisk ?? false,
        employersLiability: props.initialValues?.employersLiability ?? false,
        productsLiability: props.initialValues?.productsLiability ?? false,
        professionalIndemnity:
            props.initialValues?.professionalIndemnity ?? false,
        publicLiability: props.initialValues?.publicLiability ?? false,
    } as InsuranceFilterState);
    const [insuranceOptions, setInsuranceOptions] =
        useState(insuranceOptionsInit);

    useEffect(() => {
        globalDebounce(handleOnUpdate, 'insurance-filter-update', 1000, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form]);

    useEffect(() => {
        if (clear === true || clear === false) {
            setForm(DEFAULT_STATE as InsuranceFilterState);
            const insuranceOptionsReset = insuranceOptions.map((row: any) => {
                row.value = 0;
                row.checked = false;
                return row;
            });
            setInsuranceOptions(insuranceOptionsReset);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clear]);

    const handleOnUpdate = () => {
        onUpdate(form);
    };

    const setInsuranceToggle = (insurance: FlatObject) => {
        const insuranceOptionsTemp = [...insuranceOptions];
        const index = insuranceOptions.findIndex(
            (row) => row.id === insurance.id
        );
        insuranceOptionsTemp[index].checked = insurance.checked;
        setInsuranceOptions(insuranceOptionsTemp);
        setForm({
            ...form,
            [insurance.key]: !insurance.checked
                ? insurance.checked
                : insurance.value,
        });
    };

    const insuranceRows = () => {
        return insuranceOptions.map((insurance) => {
            return (
                <div className="insurance-filter mb-2">
                    <div className="flex-between title mb-2">
                        <label htmlFor={insurance.key}>{insurance.label}</label>
                        <Switch
                            id={insurance.key}
                            name={insurance.id}
                            alignIndicator={'right'}
                            checked={insurance.checked}
                            onChange={(e) => {
                                setInsuranceToggle(
                                    StateHandler.getStateObject(
                                        e,
                                        insurance,
                                        'checked'
                                    )
                                );
                            }}
                        />
                    </div>
                    <div
                        key={insurance.key}
                        className={
                            'insurance-input-container ' +
                            (insurance.checked ? 'show' : 'hidden')
                        }
                    >
                        <label>With a minimum value of </label>
                        <CurrencyInput
                            key={insurance.key + '-input'}
                            id={insurance.key}
                            allowNegativeValue={false}
                            name={insurance.id}
                            className="bp3-input insurance-filter-input"
                            placeholder="£0.00"
                            maxLength={10}
                            value={
                                form[insurance.key] === false
                                    ? 0
                                    : form[insurance.key]
                            }
                            decimalsLimit={2}
                            disableAbbreviations={true}
                            step={1}
                            required
                            prefix="£"
                            onValueChange={(value) => {
                                if (value !== form[insurance.key]) {
                                    insurance.value = parseFloat(value ?? '0');
                                    setInsuranceToggle(insurance);
                                }
                            }}
                        />
                    </div>
                </div>
            );
        });
    };

    return (
        <Accordion title="INSURANCES">
            <div className="insurances-filter-form">{insuranceRows()}</div>
        </Accordion>
    );
};

export default InsurancesFilter;
