import { InputGroup, Menu, MenuItem } from "@blueprintjs/core";
import { useEffect, useRef, useState } from "react";

import styles from './styles.module.scss';

interface TextSuggestionProps {
    displayProperty?: string;
    items: Array<any>;
    onItemSelect: (text: string) => any;
    placeholder?: string;
    reset?: boolean;
    value?: string;
    valueProperty?: string;
}

const TextSuggestion = (props: TextSuggestionProps) => {
    const {
        items,
        placeholder,
        onItemSelect,
        displayProperty = "name",
        valueProperty = "id",
        reset = false,
        value = ""
    } = props;

    const [inputValue, setInputValue] = useState("");
    const [showDropdown, setShowDropdown] = useState(false);

    const inputRef = useRef<HTMLInputElement | null>(null);
    const dropdownRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    useEffect(() => {
        setInputValue("");
        setShowDropdown(false);

        if (inputRef.current) {
            inputRef.current.blur();
        }
    }, [reset]);

    const filteredItems = items.filter((item) => {
        const value = item[displayProperty];
        return value.toLowerCase().includes(inputValue.toLowerCase());
    });

    const handleOnFocus = (e: any) => {
        e.stopPropagation();
        setShowDropdown(true);
    }

    const handleOnBlur = (e: any) => {
        e.stopPropagation();

        //Checks if you click off the dropdown and input...
        if (
            dropdownRef.current &&
            inputRef.current &&
            !dropdownRef.current.contains(e.relatedTarget) &&
            !inputRef.current.contains(e.relatedTarget)
        ) {
            setShowDropdown(false);
        }
    }

    const handleOnSelect = (e: any, item: any) => {
        e.stopPropagation();
        setInputValue(item[displayProperty]);

        if (inputRef.current) {
            inputRef.current.focus()
        }

        onItemSelect(item[displayProperty]);
    }

    const handleOnChange = (e: any) => {
        setInputValue(e.target.value);
        onItemSelect(e.target.value);
    }

    return (
        <div>
            <InputGroup
                inputRef={inputRef}
                onChange={handleOnChange}
                value={inputValue}
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
                placeholder={placeholder}
            />
            {(showDropdown && filteredItems.length > 0) && (
                <div
                    ref={dropdownRef}
                    className={styles.dropdownContainer}
                >
                    <Menu>
                        {filteredItems.map((item) => {
                            let classes = item?.parent
                                ? styles.childItem
                                : styles.parentItem;

                                return (
                                    <MenuItem
                                        textClassName={classes}
                                        key={item[valueProperty]}
                                        text={item[displayProperty]}
                                        onClick={(e: any ) => handleOnSelect(e, item)}
                                    />
                                );
                        })}
                    </Menu>
                </div>
            )}
        </div>
    )
}

export default TextSuggestion;
