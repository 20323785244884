import { Menu, MenuItem, Tag } from '@blueprintjs/core';
import { MultiSelect } from '@blueprintjs/select';
import { InputElementIcon } from 'components/elements';
import { NoResultsFound } from 'components/elements/wrappers';
import { find, findIndex, get } from 'lodash';
import { Component } from 'react';
import { Utility } from 'service';

class MultiSelectRenderer extends Component
{
    constructor(props)
    {
        super(props);

        // variable to pad the results depending hierarchy
        this.childrenIndent = 20;

        this.state = {
            selectedItems: (props.selectedItems ? props.selectedItems : []),
        };

        this.getDisplayValueFromItem = this.getDisplayValueFromItem.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.selectedItems !== prevProps.selectedItems) {
            this.setState({selectedItems: this.props.selectedItems});
        }
    }

    getDisplayValueFromItem(item)
    {
        const { valueProperty } = this.props;

        if ((valueProperty || '').indexOf(',') !== -1) {
            let predicates = valueProperty.split(','),
                haystack = [];

            predicates.forEach((predicate) => {
                let matchedValue = get(item, predicate);
                if (matchedValue) {
                    haystack.push(matchedValue);
                }
            });

            return haystack.join(' ');
        }

        return get(item, valueProperty || 'name');
    }

    getMenuItemStyle(item)
    {
        const style = {};

        if (item.hasOwnProperty('level') && item.level > 0) {
            style.paddingLeft = this.childrenIndent * item.level + "px";
        }

        return style;
    }

    selectAllChildren(selectedItems, items, valueProperty)
    {
        items.forEach((item) => {
            if (!find(selectedItems, {[valueProperty]: item[valueProperty]})) {
                selectedItems.push(item);
            }
            if (item.hasOwnProperty('children') && item.children.length > 0) {
                selectedItems = this.selectAllChildren(selectedItems, item.children);
            }
        });

        return selectedItems;
    }

    deselectAllChildren(selectedItems, items, valueProperty)
    {
        items.forEach((item) => {
            selectedItems.splice(findIndex(selectedItems, {[valueProperty]: item[valueProperty]}), 1);

            if (item.hasOwnProperty('children') && item.children.length > 0) {
                selectedItems = this.deselectAllChildren(selectedItems, item.children, valueProperty);
            }
        });

        return selectedItems;
    }

    render()
    {
        const {
            id,
            fill,
            resetOnSelect,
            resetOnClose,
            resetOnQuery,
            closeOnSelect,
            popoverProps,
            itemListPredicate,
            inputValueRenderer,
            itemRenderer,
            valueProperty,
            idProperty,
            isRequired,
            onItemSelect,
            defaultItem,
            ...rest
        } = this.props;

        const inputProps = {
            required: isRequired,
            rightElement: InputElementIcon('Clear', 'times', (event) => {
                this.setState({selectedItem: null});
                this.props.onItemSelect(null, event);
            }),
        };

        const { selectedItems } = this.state;

        return (
            <MultiSelect id={ id }
                popoverProps={ typeof popoverProps === 'undefined' ? { minimal: true, usePortal: false, } : popoverProps }
                noResults={<MenuItem disabled={true}><NoResultsFound /></MenuItem>}
                fill={ typeof fill === 'undefined' ? true : fill }
                resetOnSelect={ typeof resetOnSelect === 'undefined' ? true : resetOnSelect }
                closeOnSelect={ typeof closeOnSelect === 'undefined' ? true : closeOnSelect }
                resetOnClose={ typeof resetOnClose === 'undefined' ? true : resetOnClose }
                resetOnQuery={ typeof resetOnQuery === 'undefined' ? true : resetOnQuery }
                selectedItems={ selectedItems }
                inputValueRenderer={ typeof inputValueRenderer === 'function' ? inputValueRenderer : (item) => {
                    return this.getDisplayValueFromItem(item);
                } }
                inputProps={ inputProps }
                onKeyDown={ (event) => {
                    // Disable enter press submitting form
                    if (event.key === "Enter") {
                        event.preventDefault();
                        event.stopPropagation();
                    }

                    return true;
                }}
                itemListPredicate={ (query, items) => {
                    if (itemListPredicate) {
                        return itemListPredicate(query, items);
                    }

                    const filteredItems = [];

                    items.forEach((item, index) => {
                        let value = this.getDisplayValueFromItem(item);

                        if (value.match(new RegExp(query, 'i')) !== null) {
                            filteredItems.push(item);
                        }
                    });

                    return filteredItems;
                } }
                itemsEqual={ (a, b) => {
                    const idA = get(a, idProperty || 'id');
                    const idB = get(b, idProperty || 'id');

                    return idA === idB;
                } }
                onItemSelect={ (item, event) => {
                    event.preventDefault();
                    event.stopPropagation();

                    let { selectedItems } = this.state;
                    let matches = [];

                    if (item.hasOwnProperty('children') && item.children.length > 0) {
                        matches = item.children;
                    }

                    if (find(selectedItems, {[valueProperty]: item[valueProperty]})) {
                        selectedItems.splice(findIndex(selectedItems, {[valueProperty]: item[valueProperty]}), 1);

                        // if parent is deselected remove children from selected
                        if (matches.length > 0) {
                            selectedItems = this.deselectAllChildren(selectedItems, matches, valueProperty);
                        }

                        if (item.hasOwnProperty('parent') && item.parent !== undefined) {
                            const parent = this.props.items.find((x) => x.id === item.parent );
                            const matchedSelectedItems = selectedItems.filter((match) => match.parent === item.parent);

                            if (matchedSelectedItems.length === 0) {
                                const index = findIndex(selectedItems, {[valueProperty]: parent[valueProperty]});
                                if (index !== -1) {
                                    selectedItems.splice(index, 1);
                                }
                            }
                        }
                    } else {
                        selectedItems.push(item);

                        // if parent has children select all children
                        if (matches.length > 0) {
                            selectedItems = this.selectAllChildren(selectedItems, matches, valueProperty);
                        }
                    }

                    this.props.onItemSelect(selectedItems, event);
                    this.setState({selectedItems: selectedItems});
                } }
                itemListRenderer={
                    (itemListProps) => {
                        const items = (itemListProps.items || []).map(itemListProps.renderItem);

                        return (
                            <Menu>
                                {items}
                            </Menu>
                        );
                    }
                }
                itemRenderer={ typeof itemRenderer === 'function' ? itemRenderer : (item, options) => {
                    if (!options.modifiers.matchesPredicate) {
                        return null;
                    }

                    const id = get(item, idProperty || 'id');
                    let value = this.getDisplayValueFromItem(item);
                    let style = this.getMenuItemStyle(item);

                    return (
                        <MenuItem
                            active={ options.modifiers.active }
                            style={ style }
                            disabled={ options.modifiers.disabled }
                            icon={ (find(selectedItems, {[valueProperty]: item[valueProperty]})? "tick" : "blank") }
                            key={ id }
                            onClick={ options.handleClick }
                            text={ Utility.highlightText(value, options.query) } />
                    );
                }}
                tagRenderer={ (item) => {
                    const { valueProperty, showTags } = this.props;

                    if (typeof showTags !== 'undefined' && showTags === false) {
                        return undefined;
                    }

                    return (
                        <Tag>
                            { get(item, valueProperty || 'name') }
                        </Tag>
                    );
                } }
                tagInputProps={
                    {
                        disabled: ((this.props.disabled)? this.props.disabled : false)
                    }
                }
                onRemove={ (node, index) => {
                    const { selectedItems } = this.state;

                    if (typeof selectedItems[index] === 'undefined') {
                        return;
                    }

                    selectedItems.splice(index, 1);

                    this.props.onRemoveItem(selectedItems);
                    this.setState({selectedItems: selectedItems});
                    }
                }
                { ...rest } />
        );
    }
}

export default MultiSelectRenderer;
