import React, { Component } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import QuestionCategoryList from 'components/admin/question-categories/QuestionCategoryList';
import QuestionCategoryDetails from 'components/admin/question-categories/QuestionCategoryDetails';
// import QuestionCategoryAddEdit from 'components/admin/question-categories/QuestionCategoryAddEdit';
import ProtectedRoute from 'components/elements/ProtectedRoute';

class QuestionCategories extends Component
{
    render()
    {
        return (
            <div className="QuestionCategories">
                <Switch>
                    <ProtectedRoute path="/admin/question-categories" component={ QuestionCategoryList } exact />
                    <ProtectedRoute path="/admin/question-categories/:id" component={ QuestionCategoryDetails } />
                    {/*<ProtectedRoute path="/admin/question-categories/add" component={ QuestionCategoryAddEdit } exact />*/}

                    <Redirect to="/" />
                </Switch>
            </div>
        );
    }
}

export default QuestionCategories;
