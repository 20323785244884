import queryString from 'query-string';
import { Utility } from 'service';

class Location
{
    static setQueryStringFromObject(history, location, params = {}, replace = false)
    {
        let queryStringObject = {};

        Object.entries(params).forEach((param, index) => {
            if (Array.isArray(param) &&
                param.length === 2) {
                if (Utility.isStrictJSON(param[1])) {
                    if(typeof param[1] === "object") {
                        queryStringObject[param[0]] = JSON.stringify(param[1]);
                    } else {
                        queryStringObject[param[0]] = param[1];
                    }
                } else if (!Utility.isStrictJSON(param[1]) &&
                    param[1]) {
                    queryStringObject[param[0]] = param[1];
                }

                return;
            }

            queryStringObject[param[0]] = param[1];
        });

        let queryStringParams = queryString.stringify(queryStringObject);
        if (queryStringParams !== queryString.stringify(location.search)) {
            history[replace ? 'replace' : 'push']({search: queryStringParams});
        }

        return queryStringObject;
    }
}

export default Location;
