import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import FileUploadCard from './file-upload/FileUploadCard';
import { useDropzone } from 'react-dropzone';

import { MAX_FILE_UPLOAD_SIZE_MB } from 'constants/general';
import { Response } from 'service';
import { FileList, File } from 'interface/File';
import FileService from 'service/File/FileService';

interface MultiFileUploadProps {
    onUpdate: { (files: File[], initialise: boolean): void };
    uploadLinksObject: any;
    inputID: string;
    isRequired: boolean;
    name: string;
    allowDocumentsOnly: boolean;
}

const MultiFileUpload = (props: MultiFileUploadProps) => {
    const { uploadLinksObject, name, inputID, isRequired, allowDocumentsOnly } =
        props;
    const [displayFiles, setDisplayFiles] = useState<FileList>({ files: [] });
    const files = useRef<FileList>();
    const [isLoading, setIsLoading] = useState(true);
    const [acceptedFormats, setAcceptedFormats] = useState<string[]>([]);
    const imageFormats = [
        '.jpg, .gif, .png',
        'image/png',
        'image/jpg',
        'image/gif', // to allow for file extension variations
    ];
    const documentFormats = [
        '.doc, .docx, .pdf, .pps, .ppt, .pptx, .xls, .xlsx',
    ];

    const maxPerFileSize = MAX_FILE_UPLOAD_SIZE_MB * 1024 ** 2;

    useEffect(() => {
        if (isLoading) {
            setIsLoading(false);

            if (allowDocumentsOnly) {
                setAcceptedFormats(documentFormats);
            } else {
                setAcceptedFormats(documentFormats.concat(imageFormats));
            }

            if (uploadLinksObject.length > 0) {
                uploadLinksObject.forEach((file: any, index: number) => {
                    const filesList: FileList = { files: [], ...files.current };
                    // download link is not available when switching tabs, so hardcoding to /download
                    const downloadLink =
                        Response.getLink(file, 'self') + '/download';
                    const savedFile: File = {
                        errorText: '',
                        hasErrors: false,
                        name: '',
                        progress: 0,
                        mimeType: file.mimeType,
                        isUploaded: true,
                        href: Response.getLink(file, 'self'),
                        _links: { self: file._links.self },
                        title: file.title,
                        download: downloadLink,
                    };
                    filesList.files.push(savedFile);
                    updateFiles(filesList, true);
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateFiles = (filesList: FileList, initialise = false) => {
        files.current = filesList;
        typeof props.onUpdate === 'function' &&
            props.onUpdate(filesList.files, initialise);
        setDisplayFiles(filesList);
    };

    const onDrop = useCallback((acceptedFiles, fileRejections) => {
        fileRejections.forEach(
            (file: {
                errors: {
                    code: string;
                    message: any;
                }[];
                file: { name: any };
            }) => {
                const rejectMessage =
                    file.errors[0].code === `file-too-large`
                        ? `File exceeds size limit of ${MAX_FILE_UPLOAD_SIZE_MB}MB`
                        : file.errors[0].message;
                toast.error(
                    `${file.file.name} not uploaded (${rejectMessage})`
                );
            }
        );

        acceptedFiles.forEach((file: any) => {
            const filesList: FileList = { files: [], ...files.current };
            const addedFile: File = {
                name: '',
                progress: 0,
                mimeType: file.type,
                isUploaded: false,
                title: file.name,
                hasErrors: false,
            };
            filesList.files.push(addedFile);

            FileService.upload(
                file,
                addedFile,
                filesList,
                uploadLinksObject,
                updateFiles
            );
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onDelete = (deletedFile: File) => {
        if (files.current) {
            const filesList = files.current.files.filter(
                (file) => file.href !== deletedFile.href
            );

            updateFiles({ files: filesList });
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: acceptedFormats.join(', '),
        maxSize: maxPerFileSize,
        minSize: 1,
    });

    return (
        <div className="file-upload-wrap">
            <div
                {...getRootProps({
                    className: 'file-upload',
                })}
            >
                <input
                    {...getInputProps({
                        id: inputID,
                        required: isRequired && !displayFiles.files.length,
                    })}
                />
                {isDragActive ? (
                    <div className="dragging-frame">
                        Drop the files here ...
                    </div>
                ) : (
                    <div className="drag-frame">
                        Drag your files and drop them here, or{' '}
                        <u>click to select files</u>
                    </div>
                )}
            </div>
            <div className="file-list">
                {displayFiles.files.map((file, index) => (
                    <FileUploadCard
                        key={`${name}_child_${index}`}
                        {...file}
                        isEditable={true}
                        onDelete={() => {
                            onDelete(file);
                        }}
                    />
                ))}
            </div>
        </div>
    );
};

export default MultiFileUpload;
