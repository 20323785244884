import { camelCase, parseDateFormat, toMoney } from 'helpers/helpers';
import { FlatObject } from 'interface';

const RfaGeneral = (props: { data?: FlatObject }) => {
    return (
        <div className="rfa-general">
            <dl>
                <dt>Red Flag Alert Rating</dt>
                <dd>{camelCase(props.data?.generated_rfa_rating)}</dd>
                <dt>Company Name</dt>
                <dd>{camelCase(props.data?.company_name)}</dd>
                <dt>Companies House Number</dt>
                <dd>{props.data?.company_number}</dd>
                <dt>Incorporation Date</dt>
                <dd>
                    {parseDateFormat(
                        props.data?.incorporation_date,
                        'do of MMM YYYY'
                    )}
                </dd>
                <dt>Employees</dt>
                <dd>{props.data?.employees ?? 'No data'}</dd>
                <dt>Legal Status</dt>
                <dd>{props.data?.legal_status}</dd>
                <dt>Dissolution Status</dt>
                <dd>{props.data?.dissolution_status ?? '-'}</dd>
                <dt>Dissolution Date</dt>
                <dd>{props.data?.dissolution_date ?? 'Not Applicable'}</dd>
                <dt>Turnover</dt>
                <dd>{props.data?.turnover ?? 'No data'}</dd>
                <dt>Credit Limit</dt>
                <dd>
                    {props.data?.credit_limit === null
                        ? 'No data'
                        : toMoney(props.data?.credit_limit)}
                </dd>
                <dt>Credit Score</dt>
                <dd>{props.data?.credit_score ?? 'No data'}</dd>
            </dl>
        </div>
    );
};

export default RfaGeneral;
