import { Button, Classes, Dialog, Intent } from '@blueprintjs/core';
import { SyntheticEvent, MouseEvent, ComponentProps, useState, useEffect } from 'react';

interface AreYouSureProps extends ComponentProps<any> {
    title: string,
    noText: string,
    onNo: (event: SyntheticEvent) => void,
    yesText: string,
    onYes: (event: SyntheticEvent) => void,
    onClose: (event: SyntheticEvent) => void,
    isOpen: boolean,
}

const Confirmation = (props: Partial<AreYouSureProps>) => {
    const [isOpen, setIsOpen] = useState(props.isOpen);

    useEffect(() => {
        setIsOpen(props.isOpen);
    }, [props.isOpen]);

    const onNo = (event: MouseEvent) => {
        setIsOpen(false);
        typeof props.onNo === 'function' && props.onNo(event);
        typeof props.onClose === 'function' && props.onClose(event);
    }

    const onYes = (event: MouseEvent) => {
        setIsOpen(false);
        typeof props.onYes === 'function' && props.onYes(event);
        typeof props.onClose === 'function' && props.onClose(event);
    }

    return <>
        <Dialog
            className="confirmation-dialog"
            onClose={(event) => {
                typeof props.onClose === 'function' && props.onClose(event)
            }}
            title={props.title || 'Are you sure?'}
            autoFocus={true}
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            usePortal={true}
            isOpen={isOpen}
        >
            <div className={Classes.DIALOG_BODY}>
                {props.children}
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className="dialog-footer-buttons">
                    <Button onClick={onNo}>{props.noText || 'Cancel'}</Button>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button type="submit" onClick={onYes} intent={Intent.SUCCESS}>
                            {props.yesText || 'Confirm'}
                        </Button>
                    </div>
                </div>
            </div>
        </Dialog>
    </>
};

export default Confirmation;
