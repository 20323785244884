import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { Tooltip2 } from "@blueprintjs/popover2";

import { HTTP } from "service";
import { LoadingIndicator, Icon } from "components/elements";
import "./style.scss";

const FileUploadCard = (props) => {
    const [state, updateState] = useState({
        hasErrors: false,
        isEditable: false,
        isUploaded: false,
        onDelete: void 0,
        title: "",
        download: void 0,
        href: void 0,
        errorText: void 0,
        mimeType: "",
    });

    useEffect(() => {
        updateState(props);
    }, [props]);

    const getIcon = () => {
        let icon;
        switch(true) {
            case state.mimeType.indexOf('image') !== -1 :       icon = 'image.svg'; break;
            case state.mimeType.indexOf('pdf') !== -1 :         icon = 'pdf.svg'; break;
            case state.mimeType.indexOf('text') !== -1 :        icon = 'txt.svg'; break;
            case state.mimeType.indexOf('spreadsheet') !== -1 : icon = 'xls.svg'; break;
            case state.mimeType.indexOf('zip') !== -1 :         icon = 'zip.svg'; break;
            case state.mimeType.indexOf('ppt') !== -1 :
            case state.mimeType.indexOf('pptx') !== -1 :        icon = 'ppt.svg'; break;
            case state.mimeType.indexOf('msword') !== -1 :
            case state.mimeType.indexOf('doc') !== -1 :
            case state.mimeType.indexOf('docx') !== -1 :        icon = 'doc.svg'; break;
            default : icon = 'default.svg';
        }
        return <img className="file-icon" src={ `/assets/file-icons/${icon}` } alt="file icon" title={ state.mimeType } />
    }

    const getClassList = () => {
        let classList = ["file-upload-card"];

        if (state.hasErrors) { classList.push(' has-errors') }
        if (!state.isUploaded) { classList.push('uploading') }

        return classList.join(" ");
    }

    return (
        <div className={ getClassList() } title={ state.title }>
            { getIcon() }
            {
                state.onDelete && state.isEditable ? (
                    // eslint-disable-next-line
                    <a
                        className="close-button"
                        tabIndex="0"
                        role="button"
                        onClick={ () => state.onDelete() }
                        onKeyDown={ (event) => event.key === 'Enter' && state.onDelete() }
                    ><Icon icon="plus" className="rotate45"/></a>
                ) : null
            }
            <h3>{ state.title }</h3>
            {
                state.isUploaded ? (
                    <button onClick={ (e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        HTTP.stream(state.download);
                    } }>
                        <Icon icon="download" className="icon" />
                        Download
                    </button>
                ) : state.hasErrors ? null : (
                    <button>
                        <LoadingIndicator />
                        Uploading
                    </button>
                )
            }
            {
                state.hasErrors ? (
                    <Tooltip2 content={ state.errorText || 'An error has occurred. File not uploaded.' } popoverClassName="the-tooltip" key="tooltip">
                        <Icon icon="exclamation" className="icon" />
                    </Tooltip2>
                ) : null
            }
        </div>
    )
};

FileUploadCard.propTypes = {
    hasErrors: PropTypes.bool,
    isEditable: PropTypes.bool,
    isUploaded: PropTypes.bool,
    onDelete: PropTypes.func,
    title: PropTypes.string.isRequired,
    download: PropTypes.string,
    href: PropTypes.string,
    errorText: PropTypes.string,
    mimeType: PropTypes.string.isRequired,
}

export default FileUploadCard;
