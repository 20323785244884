import { H2 } from "@blueprintjs/core";

import {
    ACTIVE_OPTIONS_ACTIVE,
    ACTIVE_OPTIONS_INACTIVE,
} from "constants/active";
import { ButtonLink, Icon } from "components/elements";
import { Response } from "service";

const CertificationMembershipSummary = (props) => {
    const { certificationMembershipDetails, match } = props;

    return (
        <>
            <div className="AssessmentTypeSummary">
                <H2>
                    Summary
                    {Response.getLink(
                        certificationMembershipDetails,
                        "edit"
                    ) !== null && (
                        <ButtonLink
                            type="button"
                            to={`${match.url}/edit`}
                            intent="primary"
                            className="float-right"
                        >
                            <Icon icon="pencil" />
                            Edit
                        </ButtonLink>
                    )}
                </H2>

                <dl className="horizontal">
                    <dt>Name</dt>
                    <dd>{certificationMembershipDetails.name}</dd>
                    <dt>Description</dt>
                    <dd>{certificationMembershipDetails.description}</dd>
                    {certificationMembershipDetails._links.logo ? (
                        <>
                            <dt>Logo</dt>
                            <dd>
                                <img
                                    className="badge-img"
                                    alt={
                                        certificationMembershipDetails._links
                                            .logo.title
                                    }
                                    title={
                                        certificationMembershipDetails._links
                                            .logo.title
                                    }
                                    src={
                                        process.env.REACT_APP_BASE_URL +
                                        certificationMembershipDetails._links
                                            .logo.publicUrl
                                    }
                                ></img>
                            </dd>
                        </>
                    ) : null}
                    <dt>Expires</dt>
                    <dd>
                        {certificationMembershipDetails.expires === true
                            ? "Yes"
                            : "No"}
                    </dd>
                    <dt>Status</dt>
                    <dd>
                        {certificationMembershipDetails.isActive
                            ? ACTIVE_OPTIONS_ACTIVE.label
                            : ACTIVE_OPTIONS_INACTIVE.label}
                    </dd>
                </dl>
            </div>
        </>
    );
};

export default CertificationMembershipSummary;
