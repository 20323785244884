import { Redirect, Switch } from 'react-router-dom';

import BenefitsInterestList from 'components/benefits/BenefitsInterestList';
import BenefitsMembership from 'components/benefits/BenefitsMembership';
import ProtectedRoute from 'components/elements/ProtectedRoute';

const Benefits = () => {
    return (
        <Switch>
            <ProtectedRoute
                path="/benefits/membership"
                component={BenefitsMembership}
            />
            <ProtectedRoute
                path="/benefits/interest"
                component={BenefitsInterestList}
            />
            <Redirect to="/benefits/membership" />
        </Switch>
    );
};

export default Benefits;
