import { NONE_SELECTED_OPTION } from 'constants/none-selected';

export const THREE_CORE_CERT_LTD = {
    label: '3Core2 Certification Limited',
    value: 'three-core-cert-ltd',
};
export const THREE_CORE_SAFE_ARB = {
    label: '3core2 Certification Limited Trading as SafeArb',
    value: 'three-core-safe-arb',
};
export const A_CUBE_ACT = {
    label: 'A Cube TIC Limited trading as A Cube & ACT',
    value: 'a-cube-act',
};
export const ABCB = { label: 'ABCB', value: 'abcb' };
export const ACE_ASSOC = {
    label: 'ACE Association for Consultancy and Engineering',
    value: 'ace-assoc',
};
const ACCLAIM = {
    label: 'Acclaim Accreditation / Fortius Ltd',
    value: 'acclaim-accreditation',
};
export const ACDC = {
    label: 'Salus Certification: ACDC Register',
    value: 'acdc-register',
};
export const ACHILLES = {
    label: 'Achilles Building Confidence',
    value: 'achilles-building-confidence',
};
export const ACM = { label: 'ACM', value: 'acm' };
export const ADVANCEDCERT = {
    label: 'Advanced Certification',
    value: 'advanced-certification',
};
export const AJAEUROPE = { label: 'AJA Europe', value: 'aja-europe' };
export const ALCUMUS = { label: 'Alcumus ISOQAR', value: 'alcumus-isoqar' };
export const ALCUMUS_SAFECONTRACTOR = {
    label: 'Alcumus SafeContractor',
    value: 'alcumus-safecontractor',
};
export const ALTIUS = {
    label: 'Altius VA Ltd CDM Comply',
    value: 'altium-va-ltd-cdm-comply',
};
export const ARB = {
    label: 'ARB Approved Contractor Scheme',
    value: 'arb-approved-contractor-scheme',
};
export const APS = {
    label: 'Association for Project Safety (APS)',
    value: 'aps-registered-practice-membership-scheme',
};
export const ATLASTCERT = {
    label: 'Atlas Certification',
    value: 'atlast-certification',
};
export const AVUVA = { label: 'Auva Certification', value: 'avuva' };
export const AVETTA = { label: 'Avetta', value: 'avetta' };
export const BMTRADA = {
    label: 'BM TRADA Certification',
    value: 'bm-trada-certification',
};
export const BSI = {
    label: 'BSI Assurance UK Limited',
    value: 'british-standards-institution',
};
export const BVC = {
    label: 'Bureau Veritas Certification UK',
    value: 'bureau-veritas-certification',
};
export const CFA = {
    label: 'Centre for Assessment',
    value: 'centre-for-assessment',
};
export const CEF = {
    label: 'Construction Federation Service Safe-T-Cert - Construction Employers',
    value: 'cef-safe-t-cert',
};
export const CIF = {
    label: 'Construction Federation Service Safe-T-Cert - Industry Employers',
    value: 'cif-safe-t-cert',
};
export const CHAS = {
    label: 'Contractors Health and Safety Assessment Scheme (CHAS)',
    value: 'chas',
};
export const COMP_CHAIN = {
    label: 'Compliance Chain Ltd',
    value: 'compliance-chain-ltd',
};
export const CQMS = {
    label: 'CQMS Safety Scheme',
    value: 'cqms-safety-scheme',
};
export const DWHS = {
    label: 'D W Health & Safety Contractor Competency Scheme',
    value: 'dwhs-contractors-competency-scheme',
};
export const DNV = { label: 'DNV Business Assurance UK Limited', value: 'dnv' };
export const EUROSAFE = {
    label: 'Eurosafe - CDM Competent Scheme',
    value: 'eurosafe-cdm',
};
export const FASET = {
    label: 'FASET Membership Audit',
    value: 'faset-membership-audit',
};
export const FOREFRONT = {
    label: 'Forefront Certification Limited',
    value: 'forefront-certification',
};
export const GREENLIGHT = {
    label: 'Greenlight Safety Assessment Scheme Ltd',
    value: 'greenlight-safety-assessment-scheme',
};
export const HIRE = {
    label: 'Hire Association Europe',
    value: 'hire-association-europe',
};
export const IASSESSOR = { label: 'IAssessor', value: 'i-assessor' };
export const INTERFACE_NRM = { label: 'Interface NRM', value: 'interface-nrm' };
export const IPAF = {
    label: 'International Powered Access Federation (IPAF)',
    value: 'international-powered-access-federation',
};
export const INTERTEK = {
    label: 'Intertek Certification',
    value: 'intertek-certification',
};
export const LRQA = {
    label: 'LRQA SSIP Registration Scheme',
    value: 'lrqa-ssip-registration-scheme',
};
export const MSLPS = {
    label: 'MSL Safepartner',
    value: 'mslpc-safepartner-scheme',
};
export const NASC = {
    label: 'National Access & Scaffolding Confederation (NASC)',
    value: 'national-access-scaffolding-confederation',
};
export const NFDC = {
    label: 'National Federation of Demolition Contractors',
    value: 'national-federation-demolition-contractors',
};
export const NQA = { label: 'NQA', value: 'nqa' };
export const NSAI = { label: 'NSAI', value: 'nsai' };
export const OCEAN = {
    label: 'Ocean Certification',
    value: 'ocean-certification',
};
export const PQS = {
    label: 'Peers Quality Assurance Ltd',
    value: 'peers-quality-assurance',
};
export const PREPQS = {
    label: 'PQS Pre Qualification Scheme',
    value: 'pre-peers-quality-assurance',
};
export const PS = {
    label: 'Construction Safety Solutions Ltd (PASS)',
    value: 'ps-pass',
};
export const SMAS = {
    label: 'Safety Management Advisory Services (SMAS)',
    value: 'smas-worksafe-scheme',
};
export const SGS = {
    label: 'SGS Systems & Services Certification Services',
    value: 'sgs-systems-services',
};
export const SOCOTEC = { label: 'SOCOTEC Certification UK', value: 'socotec' };
export const SCCS = {
    label: 'Steel construction Certification Scheme',
    value: 'steel-construction-scheme',
};
export const SCSL = {
    label: 'System Certification Services Ltd',
    value: 'system-certification-services',
};
export const SSG = {
    label: 'SSG Training and Consultancy Ltd',
    value: 'ssg-training-consultancy'
};
export const TBAB = {
    label: 'The British Assessment Bureau',
    value: 'the-british-assessment-bureau',
};
export const URS = { label: 'URS Certification', value: 'urs' };
export const YUV = { label: 'TUV Ltd', value: 'yuv-uk' };

export const SSIP_SCHEME_OPERATOR_OPTIONS = [
    NONE_SELECTED_OPTION,
    THREE_CORE_CERT_LTD,
    THREE_CORE_SAFE_ARB,
    A_CUBE_ACT,
    ABCB,
    ACE_ASSOC,
    ACCLAIM,
    ACHILLES,
    ADVANCEDCERT,
    ALCUMUS,
    ALCUMUS_SAFECONTRACTOR,
    ALTIUS,
    ARB,
    APS,
    ATLASTCERT,
    AVETTA,
    AVUVA,
    BMTRADA,
    BSI,
    BVC,
    CFA,
    CEF,
    CIF,
    CHAS,
    COMP_CHAIN,
    PS,
    CQMS,
    DWHS,
    DNV,
    FASET,
    FOREFRONT,
    GREENLIGHT,
    HIRE,
    IASSESSOR,
    INTERFACE_NRM,
    IPAF,
    INTERTEK,
    LRQA,
    MSLPS,
    NASC,
    NFDC,
    NQA,
    NSAI,
    PQS,
    PREPQS,
    SMAS,
    ACDC,
    SGS,
    SSG,
    SOCOTEC,
    SCCS,
    SCSL,
    TBAB,
    YUV,
    URS,
];

export const SSIP_SCHEME_OPERATOR_OPTIONS_LEGACY = [
    ...SSIP_SCHEME_OPERATOR_OPTIONS,
    ACM,
    AJAEUROPE,
    EUROSAFE,
    OCEAN,
];
