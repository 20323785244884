import React, { Component } from 'react';
import { withRouter, Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import 'FontAwesomeIcons';
import JWT from '@elb/jsonwebtoken';
import * as jsonwebtoken from 'jsonwebtoken';

import App from 'App';
import { clearRedirect, setRedirect, setBuildTimestamp } from "action/auth";
import PreAuthContainer from 'components/pre-auth/PreAuthContainer';
import Verification from "components/pre-auth/Verification";

const mapStateToProps = (state) => {
    return {
        token: state.auth.token,
        redirect: state.auth.redirect,
        buildTimestamp: state.auth.buildTimeStamp,
    };
};

const mapDispatchToProps = {
    setRedirect: setRedirect,
    clearRedirect: clearRedirect,
    setBuildTimestamp: setBuildTimestamp,
};

class AppContainer extends Component
{
    constructor(props) {
        super(props);
        this.state = { canRender: true };

        const url = window.location.hash.substring(1);
        this.url = url.split('?')[0];
        this.isRendered = true;
    }

    componentDidMount() {
        // check for updates every 5 minutes
        const timeoutDuration =  1000 * 60 * 5;
        this.setTimeStampCheckInterval(timeoutDuration);
    }

    checkTimeStamp = async () => {
        let meta = {};
        try {
            // enforce no caching loading server
            const metaFileUrl = `/meta.json?${new Date().getTime()}`;
            const requestOptions = { cache: 'no-cache' };
            const response = await fetch(metaFileUrl, requestOptions);
            meta =  await response.json();
        } catch { return void 0 }

        if (typeof this.props.buildTimestamp === "undefined") {
            // if buildTimeStamp not set, we can assume app was not loaded until this point
            // or cache reset, so initialise to match server
            this.props.setBuildTimestamp(meta.buildDate);
        }

        if (meta.buildDate > this.props.buildTimestamp) {
            const text = `New updates have been released, do you wish to reload your app?
                \n\nClick OK to load latest updates, or Cancel to postpone this update for 30minutes.`;

            let timeoutDuration = 1000 * 60 * 5;

            if (window.confirm(text) === true) {
                this.props.setBuildTimestamp(meta.buildDate);
                window.location.reload(true);
                clearInterval(this.timer);
            } else {
                clearInterval(this.timer);
                timeoutDuration = 1000 * 60 * 30; // 30 mins
            }

            this.setTimeStampCheckInterval(timeoutDuration);
        }
    }

    setTimeStampCheckInterval(timeoutDuration) {
        // can't use globalDebounce here
        this.timer = setInterval(
            () => this.checkTimeStamp(),
            timeoutDuration
        );
    }

    componentWillUnmount () {
        this.isRendered = false;
        clearInterval(this.timer);
    }

    checkJWT = () => {
        const decodedToken = jsonwebtoken.decode(this.props.token);
        const unixTimestamp = Math.ceil(+new Date()/1000);

        return this.props.token
               && decodedToken
               && (!decodedToken.exp || decodedToken.exp > unixTimestamp);
    }

    isLoggedInPage = () => {
        return ![
            'login',
            'register',
            'password-reset',
            'forgotten-password',
        ].some(str => window.location.hash.substring(1).includes(str));
    }

    render()
    {
        if(!this.state.canRender) { return null }

        if(this.url === '/verify') {
            return <>
                <Route path="/verify" component={ Verification } exact />
            </>
        }

        let component = PreAuthContainer;

        /**
         * Replaced JWT.isValid check with local function.
         * JWT.isValid iat check is not needed, but when it does check,
         * it doesn't account for fractional seconds, so the function is wrong
         */
        if (this.checkJWT()) {
            component = App;
            if (JWT.token !== this.props.token) {
                JWT.setToken(this.props.token);
            }

            if(this.props.redirect && this.props.redirect.length) {
                const redirect = atob(this.props.redirect);
                this.props.clearRedirect();
                return <Redirect to={redirect} />
            }
        } else if (this.isLoggedInPage()) {
            this.props.setRedirect(btoa(window.location.hash.substring(1)));
        }

        return (
            <div className="AppContainer bp3">
                <Route path="/" component={ component } />
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppContainer));
