import { Redirect, Switch } from 'react-router-dom';

import { ProtectedRoute } from 'components/elements';
import AssessmentTypeInstanceList from 'components/review/assessment-types/AssessmentTypeInstanceList';

const AssessmentTypes = (props) => {
    const { companyResponse, match } = props;

    return (
        <Switch>
            <ProtectedRoute
                path={`${match.url}`}
                component={AssessmentTypeInstanceList}
                companyResponse={companyResponse}
            />
            <Redirect to={`${match.url}`} />
        </Switch>
    );
};

export default AssessmentTypes;
