import { Button, Classes, Dialog, FormGroup, Intent } from '@blueprintjs/core';
import { SyntheticEvent } from 'react';

import InviteeUpdateForm from 'components/clients/campaign/invitee/InviteeUpdateForm';
import { Loading } from 'components/elements/wrappers';
import { parseInviteeStatusText } from 'helpers/helpers';
import { ListCollection } from 'interface';
import { CampaignUpdate, InviteeStatus } from 'interface/Client/Campaign';

import './index.scss';

interface CampaignUpdateDialogProps {
    initialData: object;
    isLoading: boolean;
    isOpen: boolean;
    onChange: (key: string, value: any) => void;
    onClose: (e: SyntheticEvent) => void;
    onConfirm: () => void;
    title: string;
    updates: ListCollection<InviteeStatus> | ListCollection<CampaignUpdate>;
    isGlobalUpdate?: boolean;
}

const CampaignUpdateDialog = (props: CampaignUpdateDialogProps) => {
    const {
        initialData,
        isLoading,
        isOpen,
        onChange,
        onClose,
        onConfirm,
        title,
        updates,
        isGlobalUpdate = false,
    } = props;

    return (
        <Dialog
            onClose={onClose}
            title={title || 'Update Invitee Status'}
            autoFocus={true}
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            isCloseButtonShown={false}
            usePortal={true}
            isOpen={isOpen}
        >
            <div className={Classes.DIALOG_BODY}>
                <InviteeUpdateForm
                    initialState={initialData}
                    onChange={onChange}
                    isGlobalUpdate={isGlobalUpdate}
                />
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <FormGroup>
                    <div className="dialog-footer-buttons">
                        <Button onClick={onClose}>Cancel</Button>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button
                                type="submit"
                                intent={Intent.SUCCESS}
                                disabled={isLoading}
                                onClick={onConfirm}
                            >
                                Confirm
                            </Button>
                        </div>
                    </div>
                </FormGroup>

                <hr />

                <label className="bp3-label" htmlFor="updateText">
                    Update History
                </label>

                <Loading isLoading={isLoading && !updates?.data}>
                    <div className="invitee-update-scrollable">
                        {updates?.data?.map((update) => {
                            const content = (
                                <div
                                    className="campaign-update mb-3"
                                    key={'campaign-update-' + update.id}
                                >
                                    <strong>
                                        {update.campaignName
                                            ? update.campaignName + ' - '
                                            : 'All Campaigns - '}
                                    </strong>
                                    <strong>
                                        {parseInviteeStatusText(update)}
                                    </strong>
                                    <br />
                                    {update.updateDetails}
                                </div>
                            );

                            return content;
                        })}
                    </div>
                </Loading>
            </div>
        </Dialog>
    );
};

export default CampaignUpdateDialog;
