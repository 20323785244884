import { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { Button, Card, H3 } from '@blueprintjs/core';
import { MultiLevelCheckboxArray } from 'components/elements';

const mapStateToProps = (state) => {
    return {
        form: state.auth.form,
    };
};

const mapDispatchToProps = {};

const LocationsServiced = (props) => {
    const { form, errorMessage, closePanel, triggerHeightChange } = props;

    const getSelected = (field) => {
        return field && field.length
            ? field.reduce((total, next) => {
                  if (typeof total === 'object') {
                      const childrenSelected = getSelected(total.children);
                      total = total.checked ? 1 : 0;
                      total += childrenSelected;
                  }
                  total += next.checked ? 1 : 0;
                  return total + getSelected(next.children);
              }, 0)
            : 0;
    };

    const getTotal = (field) => {
        return field && field.length
            ? field.reduce((total, next) => {
                  if (typeof total === 'object') {
                      total = 1 + getTotal(total.children);
                  }
                  return total + 1 + getTotal(next.children);
              }, 0)
            : 0;
    };

    const [allSelected, setSelected] = useState(getSelected(form.regions));

    const updateCheckboxTotals = () => {
        setSelected(getSelected(form.regions));
        triggerHeightChange();
    };

    return (
        <>
            <div className="header small">
                <div className="header-desc">
                    <H3>Tailor your experience</H3>
                    <p>Next, where do you primarily offer your services?</p>
                    <p className="mb-4">
                        Once registered, you can always change these and provide
                        more granular detail
                    </p>
                </div>
                <img
                    className="header-logo"
                    src="/assets/images/logo-new.png"
                    alt="Prosure360 logo"
                />
            </div>
            <div className="body">
                {!errorMessage ? null : (
                    <Card className="alert">{errorMessage}</Card>
                )}
                <MultiLevelCheckboxArray
                    fields={form.regions}
                    onUpdate={updateCheckboxTotals}
                />
                <div className="flex-between mt-5">
                    <Button
                        type="button"
                        text="Previous"
                        large={true}
                        onClick={closePanel}
                    />
                    <span>
                        {allSelected} out of {getTotal(form.regions)} selected
                    </span>
                    <Button
                        type="submit"
                        text="Next"
                        large={true}
                        intent="primary"
                    />
                </div>
            </div>
            <div className="footer box-footer flex-start">
                <Link to={props.cancelUrl ?? '/login'}>Cancel</Link>
            </div>
        </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationsServiced);
