import { Tab, Tabs } from '@blueprintjs/core';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { Link, Redirect, Switch } from 'react-router-dom';

import { updatingBreadcrumbResolves } from 'action';
import CampaignAddEdit from 'components/clients/campaign/CampaignAddEdit';
import CampaignDetails from 'components/clients/campaign/CampaignDetails';
import CampaignHistory from 'components/clients/campaign/CampaignHistory';
import { Icon, ProtectedRoute } from 'components/elements';
import { Loading } from 'components/elements/wrappers';
import { Campaign } from 'interface/Client/Campaign';
import { Response, Routing } from 'service';
import CampaignService from 'service/Client/CampaignService';
import CampaignInviteeAddEdit from './CampaignInviteeAddEdit';
import CampaignInviteeAddExisting from './CampaignInviteeAddExisting';
import CampaignInviteeList from './CampaignInviteeList';
import CampaignInviteeTracker from './CampaignInviteeTracker';
import CampaignInviteeUploadErrorsList from './CampaignInviteeUploadErrorsList';
import CampaignPurchaseTracker from './CampaignPurchaseTracker';
import CampaignRevenuesByMonth from './CampaignRevenuesByMonth';
import TemplateAddEdit from './Email/TemplateAddEdit';
import TemplateDetails from './Email/TemplateDetails';
import CampaignEmailTemplateList from './Email/TemplateList';

const CampaignSummary = (props: any) => {
    const { match, location } = props;
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [campaign, setCampaign] = useState<Campaign>({} as Campaign);
    const tabId = Routing.getSelectedTabId(location.pathname, match.url);

    const merge = (newCampaign: Campaign) => {
        setCampaign({ ...campaign, ...newCampaign });
    };

    useEffect(() => {
        (async () => {
            if (isLoading) {
                setIsLoading(false);
                const response = await CampaignService.loadById(
                    match.params.campaignId
                );
                setCampaign(response);
                dispatch(
                    updatingBreadcrumbResolves({
                        campaign: response,
                    })
                );
            }
        })();
    });

    return (
        <div className="CampaignSummary">
            <Loading isLoading={isLoading}>
                <h1 className="clearfix">
                    {Response.getLinkAttribute(campaign, 'client', 'name')} -{' '}
                    {campaign.name}
                </h1>
                <Tabs renderActiveTabPanelOnly={true} selectedTabId={tabId}>
                    <Tab id={'/details'}>
                        <Link to={`${match.url}/details`}>
                            <Icon icon="info" />
                            Campaign Details
                        </Link>
                    </Tab>
                    {Response.getLink(campaign, 'add-invitee') && (
                        <Tab id={'/invitees'}>
                            <Link to={`${match.url}/invitees`}>
                                <Icon icon="user-hard-hat" />
                                Invitee List
                            </Link>
                        </Tab>
                    )}
                    {Response.getLink(campaign, 'add-invitee') && (
                        <Tab id={'/invitee-tracker'}>
                            <Link to={`${match.url}/invitee-tracker`}>
                                <Icon icon="user-check" />
                                Invitee Tracker
                            </Link>
                        </Tab>
                    )}
                    <Tab id={'/purchase-tracker'}>
                        <Link to={`${match.url}/purchase-tracker`}>
                            <Icon icon="pound-sign" />
                            Purchase Tracker
                        </Link>
                    </Tab>
                    <Tab id={'/revenue-by-month'}>
                        <Link to={`${match.url}/revenue-by-month`}>
                            <Icon icon="calendar-alt" />
                            Revenue By Month
                        </Link>
                    </Tab>
                    <Tab id={'/email-templates'}>
                        <Link to={`${match.url}/email-templates`}>
                            <Icon icon="envelope" />
                            Email Templates
                        </Link>
                    </Tab>
                    <Tab id={'/history'}>
                        <Link to={`${match.url}/history`}>
                            <Icon icon="clock" />
                            History
                        </Link>
                    </Tab>
                </Tabs>
                <div className="TabContent">
                    <Switch>
                        <ProtectedRoute
                            path={`${match.url}/details`}
                            component={CampaignDetails}
                            campaign={campaign}
                            exact
                        />
                        <ProtectedRoute
                            path={`${match.url}/details/edit`}
                            component={CampaignAddEdit}
                            campaign={campaign}
                            merge={merge}
                            exact
                        />
                        <ProtectedRoute
                            path={`${match.url}/email-templates`}
                            component={CampaignEmailTemplateList}
                            campaign={campaign}
                            exact
                        />
                        <ProtectedRoute
                            path={`${match.url}/email-templates/new`}
                            component={TemplateAddEdit}
                            campaign={campaign}
                            merge={merge}
                            exact
                        />
                        <ProtectedRoute
                            path={`${match.url}/email-templates/:emailTemplateId`}
                            component={TemplateDetails}
                            campaign={campaign}
                            merge={merge}
                        />
                        <ProtectedRoute
                            path={`${match.url}/history`}
                            component={CampaignHistory}
                            campaign={campaign}
                            exact
                        />
                        <ProtectedRoute
                            path={`${match.url}/invitees`}
                            component={CampaignInviteeList}
                            campaign={campaign}
                            exact
                        />

                        <ProtectedRoute
                            path={`${match.url}/invitees/add`}
                            component={CampaignInviteeAddEdit}
                            campaign={campaign}
                            exact
                        />

                        <ProtectedRoute
                            path={`${match.url}/invitees/upload-errors`}
                            component={CampaignInviteeUploadErrorsList}
                            campaign={campaign}
                            exact
                        />

                        <ProtectedRoute
                            path={`/clients/campaigns/list/:campaignId/invitees/:inviteeId/edit`}
                            component={CampaignInviteeAddEdit}
                            campaign={campaign}
                            exact
                        />

                        <Redirect
                            from={`${match.url}/invitees/:inviteeId`}
                            to={`${match.url}/invitees`}
                        />

                        <ProtectedRoute
                            path={`${match.url}/invitee-tracker`}
                            component={CampaignInviteeTracker}
                            campaign={campaign}
                            exact
                        />
                        <ProtectedRoute
                            path={`${match.url}/invitee-tracker/add`}
                            component={CampaignInviteeAddEdit}
                            campaign={campaign}
                            exact
                        />
                        <ProtectedRoute
                            path={`${match.url}/invitee-tracker/add-existing-sp`}
                            component={CampaignInviteeAddExisting}
                            campaign={campaign}
                            exact
                        />
                        <ProtectedRoute
                            path={`/clients/campaigns/list/:campaignId/invitee-tracker/:inviteeId/edit`}
                            component={CampaignInviteeAddEdit}
                            campaign={campaign}
                            exact
                        />

                        <ProtectedRoute
                            path={`${match.url}/purchase-tracker`}
                            component={CampaignPurchaseTracker}
                            campaign={campaign}
                            exact
                        />

                        <ProtectedRoute
                            path={`${match.url}/revenue-by-month`}
                            component={CampaignRevenuesByMonth}
                            campaign={campaign}
                            exact
                        />

                        <Redirect to={`${match.url}/details`} />
                    </Switch>
                </div>
            </Loading>
        </div>
    );
};

export default withRouter(CampaignSummary);
