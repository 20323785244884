export default class Key {
    static LEFT_ARROW = "LeftArrow";
    static UP_ARROW = "UpArrow";
    static RIGHT_ARROW = "RightArrow";
    static DOWN_ARROW = "DownArrow";

    static isArrowKey = (key) => {
        const ARROWS = [
            this.LEFT_ARROW,
            this.RIGHT_ARROW,
            this.UP_ARROW,
            this.DOWN_ARROW,
        ];

        return ARROWS.includes(key);
    }
}
