import { AxiosError, AxiosResponse } from 'axios';
import { ComponentProps, FormEvent } from 'react';
import { RouteComponentProps } from 'react-router';
import { toast } from 'react-toastify';

import { NONE_SELECTED_OPTION } from 'constants/none-selected';
import { UserData } from 'interface/User';
import { HTTP, Response, Routing } from 'service';

class UserService {
    static getUsers = async (filters: any) => {
        return await HTTP.action(
            'get',
            '/users',
            filters,
            undefined,
            'Unable to fetch user data'
        );
    };

    static getAssessors = async (filters: any) => {
        return await HTTP.action(
            'get',
            '/users-assessors',
            filters,
            undefined,
            'Unable to fetch user data'
        );
    };

    static handleSubmit = async (
        e: FormEvent<HTMLFormElement>,
        user: UserData,
        props: RouteComponentProps<any>,
        mergeUserResponse: Function | void
    ) => {
        e.preventDefault();
        e.stopPropagation();

        const { history, match } = props;

        const response: AxiosResponse | void = await HTTP.action(
            'put',
            '/users/' + user.id,
            user,
            {},
            'Unable to update profile information'
        );

        if (response) {
            toast.success('Profile updated');
            typeof mergeUserResponse === 'function' && mergeUserResponse();
            Routing.navigateUpOneLevel(history, match, null);
        }
    };

    static handleSubmitPasswordChange = async (
        e: FormEvent<HTMLFormElement>,
        user: UserData,
        formData: { oldPassword: string; password: string },
        props: ComponentProps<any>,
        callback: Function | void
    ) => {
        e.preventDefault();
        e.stopPropagation();

        const { history, match } = props;

        const response: AxiosResponse | void = await HTTP.action(
            'patch',
            Response.getLink(user, 'update-password'),
            formData,
            {},
            'Unable to update profile password',
            (error: AxiosError | void) => {
                if (error) {
                    typeof callback === 'function' &&
                        callback(error.response?.data);
                }
            }
        );

        if (response) {
            toast.success('Profile password updated');
            typeof callback === 'function' && callback();
            Routing.navigateUpOneLevel(history, match, null);
        }
    };

    static getAssessorDropdown = async () => {
        const options = [NONE_SELECTED_OPTION];
        const response = await this.getAssessors({
            limit: 999,
        });
        response?.data.users.forEach((user: any) => {
            options.push({
                label: user.forename + ' ' + user.surname,
                value: user.id,
            });
        });

        return options;
    };

    static getCampaignUpdatersDropdown = async () => {
        const options = [NONE_SELECTED_OPTION];
        const response = await this.getUsers({
            limit: 999,
            hasCampaigns: true,
        });

        const users = response?.data?.users;

        if (users) {
            users.forEach((user: any) => {
                options.push({
                    label: user.forename + ' ' + user.surname,
                    value: user.id,
                });
            });
        }

        return options;
    };

    static getChaseUsersDropdown = async () => {
        const options = [NONE_SELECTED_OPTION];
        const response = await this.getUsers({
            limit: 999,
            hasChases: true,
            datagroup: 'list',
        });

        const users = response?.data?.users;

        if (users) {
            users.forEach((user: any) => {
                options.push({
                    label: user.forename + ' ' + user.surname,
                    value: user.id,
                });
            });
        }

        return options;
    };
}

export default UserService;
