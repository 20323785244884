import { useEffect, useReducer } from 'react';
import { H3 } from '@blueprintjs/core';

import { SupplyChainTrendReducerState } from 'interface';
import { ClientService } from 'service';
import { TooltipIcon, LineChart } from 'components/elements';
import { Loading } from 'components/elements/wrappers';

const SupplyChainTrendChart = (props: any) => {
    const { client, selectedCategory } = props;
    const [supplyChainTrendState, setSupplyChainTrendState]: [
        state: SupplyChainTrendReducerState,
        setState: React.ReducerWithoutAction<any>
    ] = useReducer(
        (state: SupplyChainTrendReducerState, edits: any) => {
            return { ...state, ...edits };
        },
        {
            isVisible: true,
            isLoading: true,
            trends: void 0,
        }
    );
    const load = async () => {
        setSupplyChainTrendState({ isLoading: true });
        if (client.id === 'all-clients') {
            setSupplyChainTrendState({
                isVisible: false,
                isLoading: false,
                trends: void 0,
            });
        } else {
            setSupplyChainTrendState(
                await ClientService.getSupplyChainTrendState(
                    client,
                    selectedCategory
                )
            );
        }
    };

    useEffect(() => {
        load();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client, selectedCategory]);

    return (
        <>
            {supplyChainTrendState.isVisible && (
                <section className="supply-chain-card info-card chart-parent">
                    <section className="info-card-header">
                        <h2 className="title">
                            <TooltipIcon
                                text={
                                    <section>
                                        Status of your service providers in your
                                        supply chain, over the last 6 months.
                                    </section>
                                }
                                icon="info"
                            />
                            <span className="ps-2">
                                Supply Chain Status Trend
                            </span>
                        </h2>
                    </section>
                    <section className="info-card-body flex-justify-center flex-v-center">
                        <Loading isLoading={supplyChainTrendState.isLoading}>
                            {!supplyChainTrendState.trends ? (
                                <H3>
                                    There are currently no Service Providers in
                                    your supply chain
                                </H3>
                            ) : (
                                <LineChart
                                    data={supplyChainTrendState.trends}
                                    height={300}
                                    width={480}
                                    xScale={{ type: 'date' }}
                                    yScale={{ type: 'percentage' }}
                                />
                            )}
                        </Loading>
                    </section>
                </section>
            )}
        </>
    );
};

export default SupplyChainTrendChart;
