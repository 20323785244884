import { Component } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { ButtonLink, Icon } from 'components/elements';
import { Button, FormGroup, Text } from '@blueprintjs/core';
import { SHAPE_COMPANY_RESPONSE } from 'constants/company';
import { HTTP, Response } from 'service';

class ResubmitApplication extends Component
{
    static propTypes = {
        companyResponse: PropTypes.shape(SHAPE_COMPANY_RESPONSE).isRequired,
    };
    static defaultProps = {};

    constructor(props)
    {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event)
    {
        event.stopPropagation();
        event.preventDefault();

        const { companyResponse, history } = this.props;

        HTTP
            .post(Response.getLink(companyResponse, 'resubmit'))
            .then((response) => {
                if (response) {
                    toast.success('Application Submitted Successfully')

                    history.push(`/dashboard/company`);

                    return true;
                }

                toast.error('Unable to resubmit your application');

                return false;
            });
    }

    render()
    {
        return (
            <div className="ResubmitApplication">
                <form onSubmit={ event => this.handleSubmit(event) }>
                    <Text>
                        Before submitting this form, please confirm that all areas of your application have been corrected
                    </Text>

                    <FormGroup>
                        <ButtonLink type="button"
                            intent="default"
                            className="float-left"
                            to="/dashboard/company">
                            <Icon icon="ban" />
                            Cancel
                        </ButtonLink>
                        <Button type="submit"
                            intent="primary"
                            className="float-right">
                            <Icon icon="paper-plane" />
                            Submit
                        </Button>
                    </FormGroup>
                </form>
            </div>
        );
    }
}

export default ResubmitApplication;
