
const translate = (key, translationArray) => {
    if (typeof key === 'undefined') {
        return "";
    }
    let property = parseInt(key);
    if (["true", "false", true, false].indexOf(key) !== -1) {
        property = (key === "true" || key === true);
    } else if (isNaN(property)) {
        property = key + "";
    } else if (typeof property === 'number') {
        // do nothing
    } else {
        throw new Error('Translation requires a key to be a string, boolean or integer!');
    }
    translationArray.forEach(row => {
        if(row.value === property) { property = row.label; }
    });

    return property;
}

class Translator {
    constructor(translationArray) {
        if (!Array.isArray(translationArray)) {
            throw new Error('Translator requires a translation array!');
        }
        this.translationArray = translationArray;
    }

    translate = (key) => translate(key, this.translationArray);
}

export {
    Translator,
    translate
};
