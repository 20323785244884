import { Accordion, MultiSwitchToggle } from 'components/elements';

const ActiveFilter = (props: any) => {
    const activeOptions = [
        {
            id: 'true',
            key: 'isActive',
            label: 'Active',
            value: props.initialValue === 'true' ?? false,
        },
        {
            id: 'false',
            key: 'isActive',
            label: 'Inactive',
            value: props.initialValue === 'false' ?? false,
        },
    ];

    return (
        <Accordion title="SERVICE PROVIDER STATUS (CATEGORY)">
            <MultiSwitchToggle
                options={activeOptions}
                onUpdate={(options: Array<any>) => props.onUpdate(options)}
                clear={props.clear}
                onlyOneActive={true}
            />
        </Accordion>
    );
};

export default ActiveFilter;
