import { Card, H2 } from '@blueprintjs/core';
import { DateTime } from 'luxon';

import { ButtonLink, FileUpload, Icon } from 'components/elements';
import { Response } from 'service';

const ContractInfo = (props: any) => {
    const { contract, match } = props;

    return (
        <>
            <Card>
                <H2>
                    Contract Details
                    {Response.getLink(contract, 'edit') !== null && (
                        <ButtonLink
                            type="button"
                            to={`${match.url}/edit`}
                            intent="primary"
                            className="float-right"
                        >
                            <Icon icon="pencil" />
                            Edit
                        </ButtonLink>
                    )}
                </H2>

                <dl className="grid medium">
                    <dt>Contract</dt>
                    <dd>{contract.name}</dd>

                    <dt>Commencement Date</dt>
                    <dd>
                        {DateTime.fromISO(
                            contract.commencementDate
                        ).toLocaleString(DateTime.DATE_MED)}
                    </dd>

                    <dt>Expiry Date</dt>
                    <dd>
                        {DateTime.fromISO(contract.expiresAt).toLocaleString(
                            DateTime.DATE_MED
                        )}
                    </dd>

                    {contract.comments && (
                        <>
                            <dt>Comments</dt>
                            <dd>{contract.comments}</dd>
                        </>
                    )}

                    {contract._links && (
                        <>
                            <dt>Certificate</dt>
                            <dd>
                                <FileUpload
                                    inputID="certificate"
                                    isEditable={false}
                                    onUpdate={() => {}}
                                    onUpload={() => {}}
                                    response={contract}
                                    responseLink="file"
                                    uploadDataObject={{}}
                                />
                            </dd>
                        </>
                    )}
                </dl>
            </Card>
        </>
    );
};

export default ContractInfo;
