import { H1, PopoverPosition } from "@blueprintjs/core";
import { Tooltip2 } from "@blueprintjs/popover2";
import { Icon, ItemCount, Reload } from "components/elements";

interface ListHeaderControlsProps {
    count: number;
    filterOpen?: boolean;
    onFilter?: () => any;
    onReload: () => any;
    title: string;
};

const ListHeaderControls = (props: ListHeaderControlsProps) => {
    const {
        count = 0,
        filterOpen = false,
        onFilter,
        onReload,
        title,
    } = props;

    return (
        <H1>
            { title ?? 'List' }
            <ItemCount count={count} />
            <Reload load={onReload} />
            { onFilter ? (
                <Tooltip2
                    content="Filters"
                    position={PopoverPosition.TOP}
                    className="ms-2"
                >
                    <button
                        className={`clear-button ${
                            filterOpen && 'success'
                        }`}
                        onClick={onFilter}
                    >
                        <Icon icon="filter" className="icon" />
                    </button>
                </Tooltip2>
            )
            : null }
        </H1>
    );
};

export default ListHeaderControls;
