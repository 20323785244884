import { Button, Classes, Dialog, FormGroup, Intent } from '@blueprintjs/core';
import { ComponentProps, SyntheticEvent, useEffect, useReducer } from 'react';
import { toast } from 'react-toastify';

import { CampaignInvitee } from 'interface/Client/Campaign';
import SupplyChainService from 'service/SupplyChain/SupplyChainService';

interface SupplyChainLeaveProps extends ComponentProps<any> {
    supplyChain?: CampaignInvitee;
    isOpen: boolean;
    onClose: Function;
    onSuccess: Function;
}

const LeaveSupplyChainDialog = (props: SupplyChainLeaveProps) => {
    const { supplyChain, isOpen, onClose, onSuccess } = props;
    const [state, setState] = useReducer(
        (state: any, edits: any) => {
            return { ...state, ...edits };
        },
        {
            dialogOpen: !!isOpen,
            isLoading: false,
        }
    );

    useEffect(() => {
        setState({ dialogOpen: isOpen });
    }, [isOpen]);

    const dialogClose = (event: SyntheticEvent) => {
        setState({
            dialogOpen: false,
            isLoading: false,
        });

        typeof onClose === 'function' && onClose(event);
    };

    const dialogConfirm = async () => {
        // || !Response.getLink(invitee, 'update-status')
        if (!supplyChain) {
            toast.error('Could not leave supply chain.');
            return;
        }

        setState({ isLoading: true });

        const response = await SupplyChainService.leaveSupplyChain(
            supplyChain.id
        );

        dialogClose({} as SyntheticEvent);

        if (response && typeof onSuccess === 'function') {
            onSuccess();
        }
    };

    return (
        <Dialog
            onClose={dialogClose}
            title="Leave Supply Chain"
            autoFocus
            canEscapeKeyClose
            canOutsideClickClose
            isCloseButtonShown={false}
            usePortal
            isOpen={state.dialogOpen}
        >
            <div className={Classes.DIALOG_BODY}>
                <p>
                    Are you sure you wish to leave this supply chain, with
                    immediate effect?
                </p>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <FormGroup>
                    <div className="dialog-footer-buttons">
                        <Button onClick={dialogClose}>Cancel</Button>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button
                                type="submit"
                                intent={Intent.SUCCESS}
                                disabled={state.isLoading}
                                onClick={dialogConfirm}
                            >
                                Confirm
                            </Button>
                        </div>
                    </div>
                </FormGroup>
            </div>
        </Dialog>
    );
};

export default LeaveSupplyChainDialog;
