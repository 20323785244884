import { library } from '@fortawesome/fontawesome-svg-core';
import { faHeart as faHeartOutline } from '@fortawesome/pro-regular-svg-icons';
import {
    faAddressBook,
    faArrowAltDown,
    faArrowAltRight,
    faAsterisk,
    faAward,
    faBan,
    faBars,
    faBell,
    faBuilding,
    faCalendarAlt,
    faChartPie,
    faCheckCircle,
    faCheckSquare,
    faChevronDoubleLeft,
    faChevronDoubleRight,
    faChevronLeft,
    faChevronRight,
    faClock,
    faCog,
    faCogs,
    faColumns,
    faConveyorBeltAlt,
    faCubes,
    faDebug,
    faDownload,
    faEnvelope,
    faExclamation,
    faExclamationTriangle,
    faEye,
    faEyeSlash,
    faFileCertificate,
    faFileInvoice,
    faFileInvoiceDollar,
    faFilter,
    faGripVertical,
    faHandshake,
    faHeart,
    faHouseDamage,
    faInfo,
    faInfoCircle,
    faLink,
    faListAlt,
    faLock,
    faLockOpen,
    faMapMarker,
    faMinus,
    faMinusCircle,
    faPaperPlane,
    faPencil,
    faPhone,
    faPhoneSquare,
    faPlug,
    faPlus,
    faPlusCircle,
    faPoundSign,
    faQuestionCircle,
    faQuestionSquare,
    faRepeat,
    faSearch,
    faSignInAlt,
    faSignOutAlt,
    faSitemap,
    faSpinnerThird,
    faSync,
    faTasks,
    faTimes,
    faToolbox,
    faTrash,
    faTrashAlt,
    faUserCheck,
    faUserHardHat,
    faUserTie,
    faUsers
} from '@fortawesome/pro-solid-svg-icons';

library.add(
    faAddressBook,
    faArrowAltDown,
    faArrowAltRight,
    faAsterisk,
    faAward,
    faBan,
    faBars,
    faBell,
    faBuilding,
    faCalendarAlt,
    faChartPie,
    faCheckCircle,
    faCheckSquare,
    faChevronDoubleLeft,
    faChevronDoubleRight,
    faChevronLeft,
    faChevronRight,
    faClock,
    faCog,
    faCogs,
    faColumns,
    faConveyorBeltAlt,
    faCubes,
    faDebug,
    faDownload,
    faExclamation,
    faEye,
    faEyeSlash,
    faFilter,
    faFileCertificate,
    faFileInvoice,
    faFileInvoiceDollar,
    faGripVertical,
    faHandshake,
    faHeart,
    faHeartOutline,
    faHouseDamage,
    faInfo,
    faInfoCircle,
    faListAlt,
    faLock,
    faLockOpen,
    faMapMarker,
    faMinus,
    faMinusCircle,
    faPaperPlane,
    faPencil,
    faPhone,
    faPhoneSquare,
    faPlus,
    faPlusCircle,
    faPoundSign,
    faQuestionCircle,
    faQuestionSquare,
    faRepeat,
    faSearch,
    faSignInAlt,
    faSignOutAlt,
    faSitemap,
    faSpinnerThird,
    faSync,
    faTasks,
    faTimes,
    faToolbox,
    faTrash,
    faTrashAlt,
    faUserHardHat,
    faUserTie,
    faUsers,
    faExclamationTriangle,
    faUserCheck,
    faLink,
    faEnvelope,
    faPlug
);
