const QUESTION_TYPE_NUMBER = {label: 'Number', value: 'number'};
const QUESTION_TYPE_TEXT = {label: 'Text', value: 'text'};
const QUESTION_TYPE_DATEPICKER = {label: 'Datepicker', value: 'datepicker'};
const QUESTION_TYPE_FILE = {label: 'File Upload', value: 'file'};
// const QUESTION_TYPE_SELECT = {label: 'Select', value: 'select'};
const QUESTION_TYPE_CHOICE = {label: 'Choice', value: 'choice'};
const QUESTION_TYPE_BOOLEAN = {label: 'Yes or No', value: 'boolean'};
const QUESTION_TYPE_TREND = {label: 'Trend', value: 'trend'};
const QUESTION_TYPE_OPTIONS = [
    QUESTION_TYPE_TEXT,
    QUESTION_TYPE_NUMBER,
    // QUESTION_TYPE_SELECT,
    QUESTION_TYPE_CHOICE,
    QUESTION_TYPE_BOOLEAN,
    QUESTION_TYPE_DATEPICKER,
    QUESTION_TYPE_FILE,
    QUESTION_TYPE_TREND,
];

const QUESTION_OPTIONS_REQUIREMENT_OPTION_NOT_REQUESTED = {label: 'Not Requested', value: 0, icon: 'ban'};
const QUESTION_OPTIONS_REQUIREMENT_OPTION_REQUESTED = {label: 'Requested', value: 1, icon: 'question-square'};
const QUESTION_OPTIONS_REQUIREMENT_OPTION_REQUIRED = {label: 'Required', value: 2, icon: 'asterisk'};
const QUESTION_OPTIONS_REQUIREMENT_OPTIONS = [
    QUESTION_OPTIONS_REQUIREMENT_OPTION_NOT_REQUESTED,
    QUESTION_OPTIONS_REQUIREMENT_OPTION_REQUESTED,
    QUESTION_OPTIONS_REQUIREMENT_OPTION_REQUIRED,
];

export {
    QUESTION_OPTIONS_REQUIREMENT_OPTION_NOT_REQUESTED,
    QUESTION_OPTIONS_REQUIREMENT_OPTION_REQUESTED,
    QUESTION_OPTIONS_REQUIREMENT_OPTION_REQUIRED,
    QUESTION_OPTIONS_REQUIREMENT_OPTIONS,

    QUESTION_TYPE_NUMBER,
    QUESTION_TYPE_DATEPICKER,
    QUESTION_TYPE_BOOLEAN,
    QUESTION_TYPE_FILE,
    QUESTION_TYPE_TEXT,
    QUESTION_TYPE_TREND,
    QUESTION_TYPE_CHOICE,
    QUESTION_TYPE_OPTIONS,
};
