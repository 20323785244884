const routes = {
    clients_campaigns_new: {
        href: '/clients/campaigns/new',
        text: 'Add Campaign',
        skipNavigation: true,
    },
    clients_campaigns_invitees_list: {
        href: '/clients/campaigns/all-invitees',
        text: 'All Invitees',
        skipNavigation: true,
    },
    clients_campaigns_details: {
        href: '/clients/campaigns/list/:campaignId',
        text: '{campaign.name}',
    },
    clients_campaigns_details_edit: {
        href: '/clients/campaigns/list/:campaignId/details/edit',
        text: 'Edit',
        skipNavigation: true,
    },
    clients_campaigns_history: {
        href: '/clients/campaigns/list/:campaignId/history',
        text: 'Event History',
        skipNavigation: true,
    },
    clients_campaigns_invitee_list: {
        href: '/clients/campaigns/list/:campaignId/invitees',
        text: 'Invitees',
    },
    clients_campaigns_invitee_add: {
        href: '/clients/campaigns/list/:campaignId/invitees/add',
        text: 'Add',
        skipNavigation: true,
    },
    clients_campaigns_invitee: {
        href: '/clients/campaigns/list/:campaignId/invitees/:invitee',
        text: '{invitee.inviteeName}',
    },
    clients_campaigns_invitee_edit: {
        href: '/clients/campaigns/list/:campaignId/invitees/:invitee/edit',
        text: 'Edit',
        skipNavigation: true,
    },
    clients_campaigns_invitee_tracker: {
        href: '/clients/campaigns/list/:campaignId/invitee-tracker',
        text: 'Invitee Tracker',
        skipNavigation: true,
    },
    clients_campaigns_purchase_tracker: {
        href: '/clients/campaigns/list/:campaignId/purchase-tracker',
        text: 'Purchase Tracker',
        skipNavigation: true,
    },
    clients_campaigns_revenue_by_month: {
        href: '/clients/campaigns/list/:campaignId/revenue-by-month',
        text: 'Revenue By Month',
        skipNavigation: true,
    },
};

export default routes;
