import { Redirect, Switch } from "react-router-dom";

import { ProtectedRoute } from "components/elements";
import DiscountList from "components/admin/discounts/DiscountList";
import DiscountAddEdit from "components/admin/discounts/DiscountAddEdit";
import DiscountDetails from "components/admin/discounts/DiscountDetails";

const Discounts = () =>
{
    return (
        <div className="Discounts">
            <Switch>
                <ProtectedRoute path="/admin/discounts" component={ DiscountList } exact />
                <ProtectedRoute path="/admin/discounts/add" component={ DiscountAddEdit } exact />
                <ProtectedRoute path="/admin/discounts/:discountId" component={ DiscountDetails } />

                <Redirect to="/admin/discounts" />
            </Switch>
        </div>
    );
}

export default Discounts;
