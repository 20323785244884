import { Switch } from 'react-router-dom';

import ProtectedRoute from 'components/elements/ProtectedRoute';
import TemplatesList from 'components/admin/email/TemplatesList';
import TemplateAddEdit from 'components/admin/email/TemplateAddEdit';
import TemplateDetails from 'components/admin/email/TemplateDetails';
import { EmailTemplateProps } from 'interface/Email';

const Templates = (props: EmailTemplateProps) => {
    const { match } = props;

    return (
        <>
            <div className="email-templates">
                <Switch>
                    <ProtectedRoute
                        path={`${match.url}`}
                        component={TemplatesList}
                        exact
                    />
                    <ProtectedRoute
                        path={`${match.url}/add`}
                        component={TemplateAddEdit}
                    />
                    <ProtectedRoute
                        path={`${match.url}/:id`}
                        component={TemplateDetails}
                    />
                </Switch>
            </div>
        </>
    );
};

export default Templates;
