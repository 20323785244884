import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { canNavigateToRoute } from "helpers/helpers";

class ProtectedRoute extends Component
{
    static propTypes = {
        path: PropTypes.string.isRequired,
        component: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.object,
        ]).isRequired,
    };

    static defaultProps = {
    };

    render()
    {
        const { path, component, ...rest } = this.props;
        const ChildComponent = component;

        return (
            <Route { ...rest }
                path={ path }
                render={props => {
                    return (
                        canNavigateToRoute(path) ?
                            <ChildComponent { ...props } { ...rest } /> :
                            <Redirect to="/" />
                    )
                }} />
        );
    }
}

export default ProtectedRoute;
