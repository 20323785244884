import { NumericInput } from '@blueprintjs/core';
import React from 'react';

const PositiveNumericInput = (props) => {
    const handleValueChange = (number, string, element) => {
        if (number >= props.min) {
            props.onUpdate(number);
        }
    };

    return (
        <NumericInput
            id={props.id}
            fill={props.fill}
            min={props.min}
            max={props.max}
            value={props.value}
            placeholder={props.placeholder}
            disabled={props.disabled}
            onValueChange={props.handleValueChange ?? handleValueChange}
        />
    );
};

export default PositiveNumericInput;
