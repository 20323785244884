import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Callout } from '@blueprintjs/core';
import { Utility } from 'service';

class MissingInformation extends Component
{
    static propTypes = {
        response: PropTypes.object.isRequired,
    };

    render()
    {
        const { response } = this.props;

        let warnings = [];

        if (!(Utility.validateMinimumOneKey(response, ['addressLine1', 'addressLine2', 'addressLine3']) &&
            response.postCode)) {
            warnings.push(this.getWarning('A Company Address is required.'));
        }

        return warnings.map((item) => {
            return item;
        });
    }

    getWarning(text)
    {
        return (
            <Callout intent="warning">
                { text }
            </Callout>
        );
    }
}

export default MissingInformation;
