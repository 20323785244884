import { toast } from 'react-toastify';
import { HTTP, Response, sortQuestionsByTopic } from 'service';
import { updatingBreadcrumbResolves } from "action/breadcrumb";
import { DateTime } from 'luxon';

import { getDefaultAnswerObject } from "helpers/helpers";

export const SET_CHANGES = 'SET_CHANGES';
export const CLEAR_CHANGES = 'CLEAR_CHANGES';
export const SET_REVIEW_CHANGES = 'SET_REVIEW_CHANGES';
export const CLEAR_REVIEW_CHANGES = 'CLEAR_REVIEW_CHANGES';
export const SET_ERRORS = 'SET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const GET_ASSESSMENT_REVIEW_DATA    = 'GET_ASSESSMENT_REVIEW_DATA';
export const ASSESSMENT_REVIEW_DATA_LOADED = 'ASSESSMENT_REVIEW_DATA_LOADED';
export const ANSWERS_COPIED = 'ANSWERS_COPIED';
export const CLEAR_ASSESSMENT_SUBMITTED = 'CLEAR_ASSESSMENT_SUBMITTED';
export const SET_ASSESSMENT_SUBMITTED = 'SET_ASSESSMENT_SUBMITTED';
export const CLEAR_ASSESSMENT_DISCOUNT = 'CLEAR_ASSESSMENT_DISCOUNT';
export const SET_ASSESSMENT_DISCOUNT = 'SET_ASSESSMENT_DISCOUNT';
export const SET_SORTED_QUESTIONS = 'SET_SORTED_QUESTIONS';

export {
    clearErrors,
    setErrors,
    setChanges,
    clearChanges,
    setReviewChanges,
    clearReviewChanges,
    setSortedQuestions,
    clearAssessmentSubmitted,
    setAssessmentSubmitted
};

function setErrors(errors)
{
    return {
        type: SET_ERRORS,
        errors: errors,
    };
}

function clearErrors()
{
    return {
        type: CLEAR_ERRORS,
        errors: [],
    };
}

function setChanges(changes)
{
    return {
        type: SET_CHANGES,
        changes: changes,
    };
}

function clearChanges()
{
    return {
        type: CLEAR_CHANGES,
        changes: {},
    };
}

function setReviewChanges(changes)
{
    return {
        type: SET_REVIEW_CHANGES,
        changes: changes,
    };
}

function clearReviewChanges()
{
    return {
        type: CLEAR_REVIEW_CHANGES,
        changes: {},
    };
}

function setSortedQuestions(sortedQuestions)
{
    return {
        type: SET_SORTED_QUESTIONS,
        sortedQuestions: sortedQuestions,
    };
}

function clearAssessmentSubmitted()
{
    return {
        type: CLEAR_ASSESSMENT_SUBMITTED
    };
}

function setAssessmentSubmitted()
{
    return {
        type: SET_ASSESSMENT_SUBMITTED
    };
}

export function clearAssessmentDiscount()
{
    return {
        type: CLEAR_ASSESSMENT_DISCOUNT
    };
}

export function setAssessmentDiscount(discount)
{
    return {
        type: SET_ASSESSMENT_DISCOUNT,
        discount,
    };
}

export function copyPreviousAnswers() {
    return async (dispatch, getState) => {
        const { assessmentTypeInstance, changes, questionsCollection } =
            getState().assessments;
        let answers = {};

        try {
           const url = Response.getLink(assessmentTypeInstance, 'previous-answers');
           const response = await HTTP
                .get(url, { datagroup: 'list' });
            answers = response.data.answers;

            answers.forEach(answer => {
                let assessmentName = answer.assessmentName;
                let completeDate = DateTime.fromISO(
                    answer.assessmentCompletedAtDate
                ).toLocaleString(DateTime.DATE_MED);

                answer.copiedFromText = `This answer has been copied from the '${assessmentName}' assessment, dated ${completeDate}`;
            });
        } catch(e) {
            toast.error('Failed to load previous answers.');

            return false;
        }

        let copiedAnswers = {};

        questionsCollection.questions.forEach((question) => {
            let matchedAnswer = answers.find(answer =>
                Response.getLink(answer, 'question') === Response.getLink(question, 'question')
            );

            if (typeof matchedAnswer !== 'undefined') {
                // do not use original answer's links & review
                delete matchedAnswer._links;
                delete matchedAnswer.review;

                let answerObject = getDefaultAnswerObject(
                    assessmentTypeInstance,
                    question.question,
                    matchedAnswer,
                    {
                        files: matchedAnswer.files
                    }
                );

                copiedAnswers = {
                    ...copiedAnswers,
                    ...answerObject
                }
            }
        });

        dispatch(setChanges({
            ...changes,
            ...copiedAnswers
        }));

        return dispatch(answersCopied());
    }
}

export function loadAssessmentData(assessmentInstanceId, rootResponse, mode) {
    return async dispatch => {
        dispatch(getReviewData());
        const loadData = {};
        await HTTP
            .get(`/assessment-type-instances/${ assessmentInstanceId }`, { datagroup: 'details' })
            .then(response => {
                loadData.assessmentResponse = response.data;
                const topicLink = Response.getLink(loadData.assessmentResponse, 'topics');

                return HTTP.get(`${topicLink}`, { datagroup: 'list' });
            })
            .then(topicsResponse => {
                loadData.topics = topicsResponse.data;

                return HTTP.get(Response.getLink(loadData.assessmentResponse, 'questions'), {
                    datagroup: 'details',
                    assessmentTypeId: loadData.assessmentResponse.assessmentTypeId,
                    order: 'order',
                    limit: 999,
                });
            })
            .then(questionsResponse => {
                loadData.questions = questionsResponse.data;

                return HTTP.get(Response.getLink(rootResponse, 'answers'), {
                    assessmentInstanceId: loadData.assessmentResponse.id,
                    limit: 999,
                });
            })
            .then(answersResponse => {
                const answersDataWithReviews = {...answersResponse.data};
                const initialReviews = [];
                answersResponse.data.answers.forEach((item, index) => {
                    if (!item.review) {
                        answersDataWithReviews.answers[index].review = getDefaultReviewObject(item);
                    } else if (mode === 'review') {
                        // don't pre-load comment
                        item.review.comment = "";
                    }
                    initialReviews.push(item.review);
                });

                loadData.answers = answersDataWithReviews;
                loadData.reviewChanges = initialReviews;
            }).then(() => {
                const reviewsLink = Response.getLink(loadData.assessmentResponse, 'reviews');
                return HTTP.get(`${reviewsLink}`, { datagroup: 'list' });
            }).then((reviewsResponse) => {
                loadData.reviews = reviewsResponse.data;
            }).then(async () => {
                let stockResponses = {};

                if (mode === 'review') {
                    await HTTP.get(`/question/stock-responses`, { assessmentId: loadData.assessmentResponse.id }).then((response)=>{
                        if (response.data.stockResponses
                            && response.data.stockResponses.length > 0
                        ) {
                            response.data.stockResponses.forEach((stockResponse) => {
                                const questionId = Response.getLinkAttribute(stockResponse, 'question', 'id');
                                stockResponses[questionId] = stockResponses[questionId] ?? [];
                                stockResponses[questionId].push({
                                    id: stockResponse.id,
                                    label: stockResponse.name
                                });
                            });
                        }
                    });
                }

                return stockResponses;
            }).then((stockResponses) => {
                loadData.stockResponses = stockResponses;
            }).then(() => {
                const sortedData = {
                    assessmentTypeInstance: loadData.assessmentResponse,
                    topicCollection: loadData.topics,
                    questionsCollection: loadData.questions,
                    answersCollection: loadData.answers,
                    reviewsCollection: loadData.reviews,
                    stockResponsesCollection: loadData.stockResponses,
                };
                loadData.sortedQuestions = sortQuestionsByTopic(sortedData);
            }).catch(error => {
                console.error('Error doing Assessment fetch stack', error);
                toast.error('Unable to fetch data');
            });

        const assessmentTypeName = Response.getLinkAttribute(loadData.assessmentResponse, 'assessment-type', 'name');
        const breadCrumbsResolve = mode === 'review' ? { assessment: { review: assessmentTypeName + ' Review' } } :
            { assessment: { completion: assessmentTypeName + ' Completion' } };

        dispatch(updatingBreadcrumbResolves(
            breadCrumbsResolve
        ));

        return dispatch(reviewDataLoaded(loadData));
    };
}

export function answersCopied()
{
    return {
        type: ANSWERS_COPIED,
    };
}

export function getReviewData()
{
    return {
        type: GET_ASSESSMENT_REVIEW_DATA,
    };
}

export function reviewDataLoaded(loadData)
{
    return {
        type: ASSESSMENT_REVIEW_DATA_LOADED,
        payload: loadData
    };
}

const getDefaultReviewObject = (answerResponse) =>
{
    return {
        _links: {
            answer: {
                href: Response.getLink(answerResponse, 'self'),
                method: 'GET'
            }
        },
        comment: "",
        isApproved: null,
    };
}
