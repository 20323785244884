import { ButtonLink, Icon, List } from "components/elements";
import { ListCollection } from "interface";
import { EmailTemplateData, EmailTemplateListData } from "interface/Email";
import { useState } from "react";
import { loadEmailTemplateList } from "service/Email/TemplateService";

const RenewalEmailTemplateList = (props: any) => {
    const { match } = props;

    const [isLoading, setIsLoading] = useState(true);
    const [collection, setCollection] = useState({} as ListCollection<EmailTemplateData>);

    const load = async () => {
        setIsLoading(true);

        let response = await loadEmailTemplateList(
            { groupName: 'renewals' }
        ) as EmailTemplateListData;

        let newResponse = response as ListCollection<EmailTemplateData>;

        if (response.emailTemplates) {
            newResponse.data = [...response.emailTemplates];
            delete response.emailTemplates;
        }

        setCollection(response as ListCollection<EmailTemplateData>);
        setIsLoading(false);
    };

    const viewButton = (row: EmailTemplateData) => {
        return (
            <ButtonLink
                type="button"
                to={`${match.url}/${row.id}`}
                intent="primary"
            >
                <Icon icon="eye" />
                View
            </ButtonLink>
        );
    };

    const columns: object[] = [
        {
            name: `Email Name`,
            property: 'name',
            sortable: false,
            grow: 2,
        },
        {
            name: `Description`,
            property: 'description',
            sortable: false,
            grow: 5,
        },
        {
            name: '',
            property: '',
            type: 'callback',
            grow: 1,
            callback: viewButton,
            right: true,
        },
    ];

    return (
        <div className="TemplateList">
            <List
                title="Email Templates"
                columns={columns}
                collection={collection}
                load={() => load()}
                isLoading={isLoading}
                noRecordsFoundText="There are no renewal emails"
                filter={{}}
            />
        </div>
    );
}

export default RenewalEmailTemplateList;
