const ASSESSMENT_TYPE_TYPE_PROSURE = {
    label: 'Prosure Assessment',
    value: 'prosure',
};
const ASSESSMENT_TYPE_TYPE_CLIENT = {
    label: 'Client Assessment',
    value: 'client',
};
const ASSESSMENT_TYPE_TYPE_OPTIONS = [
    ASSESSMENT_TYPE_TYPE_PROSURE,
    ASSESSMENT_TYPE_TYPE_CLIENT,
];

const assessmentCategoryAndTypeOptions = [
    {
        id: 'ssip-all',
        checked: false,
        label: 'SSIP Assessments (United Kingdom)',
        showChildren: false,
        children: [
            {
                id: 'contractor',
                checked: false,
                label: 'Contractor',
            },
            {
                id: 'designer',
                checked: false,
                label: 'Designer',
            },
            {
                id: 'non-construction',
                checked: false,
                label: 'Non-Construction',
            },
            {
                id: 'principal-contractor',
                checked: false,
                label: 'Principal Contractor',
            },
            {
                id: 'principal-designer',
                checked: false,
                label: 'Principal Designer',
            },
        ],
    },
    {
        id: 'ssip-ireland',
        checked: false,
        label: 'SSIP Assessments (Rep. of Ireland)',
        showChildren: false,
        children: [
            {
                id: 'ssip-approved-ie',
                checked: false,
                label: 'SSIP Approved IE',
            },
            {
                id: 'ssip-approved-ie-contractor',
                checked: false,
                label: 'SSIP Approved IE Contractor',
            },
            {
                id: 'ssip-approved-ie-pscs',
                checked: false,
                label: 'SSIP Approved IE Project Supervisor Construction Stage (PSCS)',
            },
        ],
    },
    {
        id: 'deem-to-satisfy-all',
        checked: false,
        label: 'SSIP Deem-To-Satisfy Assessments',
        showChildren: false,
        children: [
            {
                id: 'deem-to-satisfy',
                checked: false,
                label: 'Deem-To-Satisfy',
            },
        ],
    },
    {
        id: 'pre-qualified-all',
        checked: false,
        label: 'Pre-Qualified Assessments',
        showChildren: false,
        children: [
            {
                id: 'pre-qualified',
                checked: false,
                label: 'Pre-Qualified',
            },
        ],
    },
];

export {
    ASSESSMENT_TYPE_TYPE_CLIENT,
    ASSESSMENT_TYPE_TYPE_OPTIONS,
    ASSESSMENT_TYPE_TYPE_PROSURE,
    assessmentCategoryAndTypeOptions,
};
