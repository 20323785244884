import { Button } from '@blueprintjs/core';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { isCompanyAdmin } from 'helpers/helpers';
import { HTTP, Response } from 'service';

import './index.scss';

const BenefitsMembership = (props) => {
    const rootResponse = useSelector((state) => state.root.response);

    const registerInterest = async (benefit) => {
        try {
            await HTTP.post(
                Response.getLink(
                    rootResponse.user,
                    'register-interest-in-benefit'
                ),
                { benefit }
            );

            if (['barbour', 'wmcompliance'].indexOf(benefit) !== -1) {
                toast.success(
                    'Thank you for your interest! Please check your email.'
                );
            } else {
                toast.success('Thank you for your interest!');
            }

            return true;
        } catch (e) {
            toast.error(
                'Unable to register your interest, please try again later'
            );

            return false;
        }
    };

    const marloweGroup = [
        {
            logo: '/assets/images/benefits/vinciworks-new.webp',
            title: 'VinciWorks – Access to 3 Free Training Courses and 10% Discount on All Courses',
            desc: (
                <>
                    <p>
                        VinciWorks is an online training solutions provider
                        established in 1990. The business offers innovative
                        courses about health and safety, compliance, and
                        performance.
                    </p>
                    <div>
                        <p>
                            Through VinciWorks International you and your staff
                            can access the following selection of accredited
                            courses, free of charge for 90 days:
                        </p>
                        <ul>
                            <li>
                                <span className="bold">
                                    Asbestos Management –{' '}
                                </span>
                                explore how to mitigate the risk of asbestos
                                exposure
                            </li>
                            <li>
                                <span className="bold">Fire Safety– </span>
                                explore the causes of workplace fires and how to
                                handle them when they break out
                            </li>
                            <li>
                                <span className="bold">
                                    Manager's Overview of Health & Safety –{' '}
                                </span>
                                explore the roles managers play in creating a
                                positive health and safety culture
                            </li>
                        </ul>
                    </div>

                    <p>
                        Unlike other trials, as a customer of Prosure360 you
                        will be able to complete courses and download your
                        course certificates of completion.
                    </p>
                </>
            ),
            alignment: 'left',
            buttonText: 'Redeem',
            buttonLink: 'https://www.vinciworks.com/prosure?astute_id=52565',
            benefit: 'vinciworks',
        },
        {
            logo: '/assets/images/benefits/marlowe.png',
            title: 'William Martin – Preferential Rates for Assessments, Training and Compliance Systems',
            desc: (
                <>
                    <p>
                        William Martin is a health and safety consultancy
                        specialising in bespoke property related health and
                        safety risk management solutions across all business
                        sectors. The business is particularly adept at managing
                        risk on multiple site applications.
                    </p>

                    <div>
                        <h4>Core services include:</h4>
                        <ul>
                            <li>Health and safety risk assessments</li>
                            <li>Fire safety risk assessments</li>
                            <li>Water risk assessments</li>
                            <li>Access audits</li>
                            <li>Tutor led training</li>
                            <li>
                                Online compliance management systems - Meridian
                            </li>
                            <li>CAFM helpdesk systems - Elogbooks</li>
                        </ul>
                    </div>
                    <p>
                        We are pleased to announce that all Prosure360 members
                        can benefit from preferential rates.
                    </p>
                </>
            ),
            alignment: 'right',
            buttonText: 'Submit Your Interest',
            benefit: 'wmcompliance',
        },
        {
            logo: '/assets/images/benefits/barbour.svg',
            title: 'Barbour Environment, Health and Safety (EHS) ',
            desc: (
                <>
                    <p>
                        Barbour is an online library of two halves. Firstly, it
                        is a single place to source information from HSE,
                        British standards and trade associations, with a bespoke
                        legal register designed to help you meet your
                        legislation and compliance requirements. Within this,
                        bookmarking tools enable you to quickly save and update
                        your favourite documents. Secondly, it is a catalogue of
                        tools to help you educate, train, and improve the
                        culture your workforce.
                    </p>
                    <p>
                        As a Prosure360 customer you will be eligible to a
                        first-year discount of 15%.
                    </p>
                </>
            ),
            alignment: 'left',
            buttonText: 'Submit Your Interest',
            benefit: 'barbour',
        },
        {
            logo: '/assets/images/benefits/optima-health.png',
            title: 'Optima Health',
            desc: (
                <>
                    <div className="optima-card">
                        10% off support your workforce to thrive with
                        occupational health expertise on-demand with code
                        <span className="bold"> KU5HVT6R</span>
                    </div>
                    <div className="optima-card">
                        <span className="bold">OH Direct</span> allows you to
                        easily purchase a range of services and products online
                        to help you support the health and wellbeing of your
                        staff. There are no tie-ins or contracts, and you can
                        choose to create an account or simply check-out as a
                        guest.
                    </div>
                    <div className="optima-card">
                        <span className="bold">
                            Management advice referrals:
                        </span>{' '}
                        A core service we provide is management advice
                        referrals. Using our occupational health portal,
                        managers can request professional advice and refer
                        employees into relevant services to support with
                        scenarios such as a return to work or advice with
                        workplace adjustments.
                    </div>
                    <div className="optima-card">
                        <span className="bold">Other available products:</span>{' '}
                        Beyond management referrals, explore a diverse range of
                        products designed to support your staff’s year-round
                        health. From health screenings and flu vouchers to
                        training videos and ergonomic equipment, we’ve got your
                        workplace health and wellbeing needs covered.
                    </div>
                    <div className="optima-card">
                        <h3 className="optima-header">
                            Benefits for employees and your organisation:
                        </h3>
                        <ul className="optima-benefits-list">
                            <li>
                                <span className="bold">
                                    Improve sickness absence
                                </span>
                            </li>
                            <li>
                                <span className="bold">
                                    Adopt early intervention
                                </span>
                            </li>
                            <li>
                                <span className="bold">Help reduce costs</span>
                            </li>
                            <li>
                                <span className="bold">
                                    Expert manager support
                                </span>
                            </li>
                            <li>
                                <span className="bold">
                                    Access to clinicians
                                </span>
                            </li>
                            <li>
                                <span className="bold">
                                    No big contract tie-ins
                                </span>
                            </li>
                        </ul>
                    </div>
                </>
            ),
            alignment: 'right',
            buttonText: 'Submit Your Interest',
            buttonLink: 'https://ohdirect.tphealth.co.uk/',
            benefit: 'optimahealth',
        },
        {
            logo: '/assets/images/benefits/my-wellbeing-check.png',
            title: 'My Wellbeing Check® - Get 10% off supporting your employees in living their healthiest lives.',
            desc: (
                <>
                    <div className="wellbeing-card">
                        My Wellbeing Check® is the first Body, Mind, Work and
                        Life assessment that also includes newsletters, webinars
                        and 24/7 resources. It’s the ideal digital solution to
                        drive employee engagement and proactively tackle employee
                        health and wellbeing across your organisation.
                        <br/>
                        <br/>
                        Measuring employee wellbeing has never been easier as
                        My Wellbeing Check® can segment big data by location or
                        department. Employees complete a short 8-10 minute
                        assessment that provides their 10 year risk of
                        cardiovascular disease and diabetes along with an easy
                        to understand dashboard covering numerous aspects of
                        employee health such as; activity, stress, finance,
                        sleep, work demands and more.
                        <br/>
                        <br/>
                        Your admin dashboard allows you to monitor trends and
                        hotspots which is perfect for those who are looking to
                        understand their employees wellbeing and continue to
                        develop your wellbeing strategy.
                        <br/>
                        <br/>
                        Targeted resources and company specific referral
                        pathways offer support to help make lifestyle
                        improvements.
                        <br/>
                        <br/>
                        Click below today to be contacted by one of the My
                        Wellbeing Check® team as to how you can better look
                        after the wellbeing of your employees, no matter how
                        big or small your business is.
                    </div>
                </>
            ),
            alignment: 'left',
            buttonText: 'Submit Your Interest',
            buttonLink: 'https://v3.mywellbeingcheck.co.uk/user/prosure360',
            benefit: 'mywellbeingcheck',
        },
    ];

    const partners = [
        {
            logo: '/assets/images/benefits/fleetmax.png',
            title: 'Fleetmaxx Solutions – Free Fuel Cards with Savings on Fuel and £25 Credited to Each Card',
            desc: (
                <>
                    <p>
                        Fleetmaxx solution is a truly independent fuel card
                        services provider, working with most of the major fuel
                        brands in the UK. Whether you have one or thousands of
                        vehicles on the road, Fleetmaxx Solutions can help you
                        reduce running cost with discounted diesel and petrol,
                        adblue, low saps engine oil, asset tracking, vehicle
                        cameras, business mobile, landline, broadband and
                        business insurance solutions.
                    </p>
                    <div>
                        We are proud to offer our Prosure360 members access to
                        exclusive discounts via our partnership. These include:
                        <ul>
                            <li>
                                Fuel Cards – Free Fuel Cards (on any one card
                                type) for the first year.
                            </li>
                            <li>
                                Fuel Card Price Guarantee – Fleetmaxx are one of
                                very few companies that guarantee to be lower
                                than the national average for Standard Diesel,
                                (excluding pump price cards and petrol), as we
                                do not have an escalating price structure.
                            </li>
                            <li>
                                Free Fuel - £25 credited to members fuel card
                                account. If the account is used within the first
                                month.
                            </li>
                            <li>
                                Card Guard – £250 zero liability for just 50p
                                per card per month.
                            </li>
                            <li>
                                Mobile Phones – Lowest rates offered to some of
                                the best promotional and bespoke tariffs
                                available from EE, O2 and Vodafone
                            </li>
                            <li>
                                Vehicle and Asset Tracking – Lowest rates
                                offered for tailored, cost-effective GPS vehicle
                                and asset tracking solutions.
                            </li>
                            <li>
                                Vehicle/Business Insurance – Lowest Rates
                                Offered for insurance to the motor trade,
                                commercial vehicle, and business sectors.
                                Liability, Courier, Goods in Transit, Indemnity
                                and Commercial Property Insurance.
                            </li>
                        </ul>
                    </div>
                    <div>
                        Other Benefits with Fleetmaxx Solutions
                        <ul>
                            <li>
                                Save up to 5p per litre on national average pump
                                prices.
                            </li>
                            <li>
                                Save up to 10p when using our extensive motorway
                                pump networks.
                            </li>
                            <li>
                                Wide range of different fuel card products,
                                including a pre-paid card for customers who we
                                have been unable to secure credit for.
                            </li>
                            <li>
                                Always talk to the same dedicated account
                                manager, who understands their customer’s needs,
                                whenever you call.
                            </li>
                            <li>
                                Reduce the burden of administration with Invoice
                                management.
                            </li>
                            <li>
                                Zero liability insurance available – Card Guard.
                            </li>
                            <li>Interest-free credit.</li>
                            <li>No contracts.</li>
                            <li>No minimum usage.</li>
                            <li>No hidden extras.</li>
                        </ul>
                    </div>
                </>
            ),
            alignment: 'center',
            buttonText: 'Click Here to View Offer',
            buttonLink:
                'https://www.fleetmaxxsolutions.co.uk/introduction/prosure360-discount-fuel-cards/',
            benefit: 'fleetmaxxsolutions',
        },
        {
            logo: '/assets/images/benefits/fortus.svg',
            title: 'Fortus - Free One Hour Consultation – Professional Accounting Advice and 10% Discount',
            desc: (
                <>
                    <p>
                        Book a FREE 1:1 one hour consultation session with a
                        senior member of the Fortus team, to discuss your
                        accounting requirements. Fortus will look to understand
                        your business and the accounting services you require.
                        Fortus will be your business partner, providing insight
                        and advice delivered in a recommendation report.
                    </p>
                    <p>
                        If you become a client, you will also receive a 10%
                        reduction in your 1st years fees.
                    </p>
                    <p>Contact us for a FREE initial consultation.</p>
                </>
            ),
            alignment: 'right',
            buttonText: 'Click Here to View Offer',
            buttonLink:
                'https://landing.fortus.co.uk/WMCompliance/freeconsultation.html',
            benefit: 'fortus',
        },
    ];

    const renderFooterButton = (row) => {
        const { benefit, buttonLink, buttonText } = row;

        const handleOnClick = (e) => {
            if (buttonLink) {
                window.open(buttonLink, '_blank').focus();
            }

            if (benefit) {
                registerInterest(benefit);
            }
        };

        return (
            <Button
                className='icon-free'
                type="button"
                onClick={handleOnClick}
                intent="primary"
            >
                {buttonText || 'Coming Soon'}
            </Button>
        );
    };

    const renderBenefitCard = (row, key) => {
        const { alignment, logo, title, desc } = row;
        const showFooter =
            isCompanyAdmin() || Response.getLink(rootResponse, 'company');

        return (
            <div className="benefits-card" key={key}>
                <div className={`benefit ${alignment}`}>
                    {logo && (
                        <div className="logo">
                            <img src={logo} alt="logo" />
                        </div>
                    )}
                    <div className="content">
                        <h3 className="title">{title}</h3>
                        <div className="desc">{desc}</div>
                    </div>
                </div>
                {showFooter && (
                    <div className="footer">{renderFooterButton(row)}</div>
                )}
            </div>
        );
    };

    return (
        <>
            <div className="benefits-banner">
                <div className="inner no-styling text-center">
                    Prosure360 Membership Benefits{' '}
                    <span className="highlight">– Saving £1500 per year.</span>
                </div>
            </div>
            <div className="page BenefitsPage">
                <div className="page-wrapper no-styling">
                    <div className="page-body">
                        <div className="section-header top">
                            <h2 className="section-title">
                                Welcome to the family.
                            </h2>
                            <p>
                                As a Service Provider that subscribes to our
                                assessments, we can offer you access to a suite
                                of tailor-made benefits that we think will help,
                                and save you money.
                            </p>
                        </div>

                        {marloweGroup.map((row, index) =>
                            renderBenefitCard(row, 'marlowe' + index)
                        )}

                        <div className="section-header">
                            <h2 className="section-title">Selected Partners</h2>
                            <p>
                                In addition to Marlowe Group partnerships, we
                                have also listened to what our customers want
                                and added two impressive organisations with
                                exclusive membership benefits.
                            </p>
                        </div>

                        {partners.map((row, index) =>
                            renderBenefitCard(row, 'partners' + index)
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default BenefitsMembership;
