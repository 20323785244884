import { useEffect, useState } from 'react';

import { H1 } from '@blueprintjs/core';
import { ButtonLink, Icon } from 'components/elements';
import { Response, HTTP } from 'service';
import FileUploadCard from 'components/elements/file-upload/FileUploadCard';

const SupplyChainInfo = (props) => {
    const { clientResponse, match } = props;
    const [displayFiles, setDisplayFiles] = useState([]);

    const load = () => {
        (async () => {
            if (Response.getLink(clientResponse, 'supply-chain-files')) {
                const filesResponse = await HTTP.get(
                    Response.getLink(clientResponse, 'supply-chain-files')
                );
                setDisplayFiles(filesResponse.data.files);
            }
        })();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(load, []);

    return (
        <>
            <div className="ClientInfo">
                <H1 className="clearfix">
                    Supply Chain Configuration
                    {Response.getLink(clientResponse, 'edit') ===
                    null ? null : (
                        <ButtonLink
                            type="button"
                            to={`${match.url}/edit`}
                            intent="primary"
                            className="float-right"
                        >
                            <Icon icon="pencil" />
                            Edit
                        </ButtonLink>
                    )}
                </H1>

                <dl className="horizontal">
                    <dt>Entry Requirements Description</dt>
                    <dd>{clientResponse.entryRequirementsDescription}</dd>
                    <dt>Documents to Confirm</dt>
                    <dd>
                        <div className="file-upload-wrap">
                            <div className="file-list">
                                {displayFiles.map((file, index) => (
                                    <FileUploadCard
                                        {...file}
                                        isEditable={false}
                                        isUploaded={true}
                                        download={Response.getLink(
                                            file,
                                            'download'
                                        )}
                                    />
                                ))}
                            </div>
                        </div>
                    </dd>
                </dl>
            </div>
        </>
    );
};

export default SupplyChainInfo;
