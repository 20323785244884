import { ButtonLink, Icon, List } from 'components/elements';
import { delay } from 'helpers/helpers';
import { ListCollection, ListFilter } from 'interface';
import {
    CampaignComponentProps,
    CampaignEmailListFilter,
} from 'interface/Client/Campaign';
import { EmailTemplateData } from 'interface/Email';
import { useState } from 'react';
import { CampaignService, Response } from 'service';

const CampaignEmailTemplateList = (props: CampaignComponentProps) => {
    const { match, campaign } = props;
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [collection, setCollection] = useState<ListCollection<any>>({
        _links: [],
        count: 0,
        data: [],
        limit: 0,
        page: 0,
        pages: 0,
    });
    const defaultFilters: ListFilter = { order: '-createdAt' } as ListFilter;
    const listFilter = { ...defaultFilters };
    const searchFilters = {};

    const load = async (filter?: ListFilter) => {
        setIsLoading(true);
        setCollection(
            await CampaignService.loadEmailTemplates(
                Response.getLink(campaign, 'email-templates'),
                filter
            )
        );
        await delay(0);
        setIsLoading(false);
    };

    const viewButtons = (row: EmailTemplateData) => {
        return (
            <ButtonLink
                type="button"
                to={`${match.url}/${row.id}`}
                intent="primary"
            >
                <Icon icon="eye" />
                View
            </ButtonLink>
        );
    };

    const getHeaderButton = () => {
        return (
            <ButtonLink
                type="button"
                to={`${match.url}/new`}
                intent="primary"
                className="float-right"
            >
                <Icon icon="plus-circle" />
                Add New Email Template
            </ButtonLink>
        );
    };

    const columns: object[] = [
        {
            name: `Email Name`,
            property: 'name',
            sortable: false,
            grow: 2,
        },
        {
            name: `Description`,
            property: 'description',
            sortable: false,
            grow: 5,
        },
        {
            name: '',
            property: '',
            type: 'callback',
            grow: 1,
            callback: viewButtons,
            right: true,
        },
    ];

    return (
        <div className="CampaignList">
            <List
                title="Campaign Email Templates"
                columns={columns}
                load={(filter) => load(filter as CampaignEmailListFilter)}
                preLoadList={false}
                delayedLoad={!!campaign?.id}
                collection={collection}
                filter={listFilter}
                isLoading={isLoading}
                noRecordsFoundText="There are no campaign emails"
                headerButton={getHeaderButton}
                filters={searchFilters}
            />
        </div>
    );
};

export default CampaignEmailTemplateList;
