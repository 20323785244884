import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import queryString from "query-string";
import { H1 } from "@blueprintjs/core";

import {
    ButtonLink,
    Icon,
    ItemCount,
    Paginator,
    Reload,
    Table,
} from "components/elements";
import { Loading, NoResultsFound } from "components/elements/wrappers";
import { HTTP, Response } from "service";

const CertificationMembershipsList = (props) => {
    const { match } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [filter, setFilter] = useState(
        queryString.parse(props.location.search)
    );
    const [collectionResponse, setCollectionResponse] = useState({});
    const defaultFilters = {
        order: "name",
        isActive: true,
    };

    const getRoute = (row) => {
        return `/admin/certification-memberships/${row.id}/summary`;
    };

    const columns = [
        { name: "Name", property: "name", sortable: true, grow: 6 },
        {
            name: "Description",
            property: "description",
            sortable: true,
            grow: 6,
        },
        {
            name: "Expires",
            property: "expires",
            type: "yesNo",
            sortable: true,
            grow: 1,
        },
        {
            name: "",
            property: "",
            type: "nav-button",
            grow: 1,
            route: getRoute,
            buttonIntent: "primary",
            buttonText: "View",
            right: true,
        },
    ];

    const load = async (params) => {
        if (isLoading === true) {
            return;
        }

        try {
            const filterParams = Object.assign(
                {},
                defaultFilters,
                props.filter,
                filter,
                params
            );
            let queryStringFilterParams = queryString.stringify(filterParams);
            if (
                props.location.search &&
                "?" + queryStringFilterParams !== props.location.search
            ) {
                props.history.push({ search: queryStringFilterParams });
            }

            setIsLoading(true);
            setFilter(filterParams);

            let response = await HTTP.get(
                "/certification-memberships",
                filterParams
            );
            setCollectionResponse(response.data);
            setIsLoading(false);
        } catch (e) {
            toast.error("Unable to fetch a list of Certificate memberships.");
            console.warn(e.code, e.message);

            return false;
        }
    };

    useEffect(() => {
        load();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="AssessmentTypeList">
            <H1 className="clearfix">
                Certification & Memberships List
                <ItemCount count={collectionResponse.count} />
                <Reload load={load} />
                {Response.getLink(collectionResponse, "create") !== null && (
                    <ButtonLink
                        type="button"
                        to={`${match.url}/add`}
                        intent="primary"
                        className="float-right"
                    >
                        <Icon icon="plus-circle" />
                        Add new Certification Membership
                    </ButtonLink>
                )}
            </H1>

            <Loading isLoading={isLoading}>
                <NoResultsFound count={collectionResponse.count}>
                    <Table
                        data={collectionResponse.certificationMemberships}
                        columns={columns}
                        onSort={load}
                        ordering={filter.order}
                    />

                    <Paginator
                        page={collectionResponse.page}
                        count={collectionResponse.count}
                        limit={collectionResponse.limit}
                        onPage={load}
                    />
                </NoResultsFound>
            </Loading>
        </div>
    );
};

export default CertificationMembershipsList;
