import { FormGroup, PopoverPosition } from '@blueprintjs/core';
import { orderBy } from 'lodash';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
    Accordion,
    CompanyCard,
    DownloadButton,
    HTMLSelect,
    LoadingIndicator,
    MultiSelectRenderer,
    MultiSwitchToggle,
    Paginator,
    ResultsCount,
    SearchWidget,
} from 'components/elements';
import MultiLevelSwitchArray from 'components/elements/multiLevelSwitch/MultiLevelSwitchArray';
import TextSuggestion from 'components/elements/text-suggestion/TextSuggestion';
import { Loading } from 'components/elements/wrappers';
import LinkedFilter from 'components/linked-filter/linked-filter';
import ActiveFilter from 'components/service-provider/ActiveFilter';
import InsurancesFilter from 'components/service-provider/InsurancesFilter';
import RatingFilter from 'components/service-provider/RatingFilter';
import RedFlagFilter from 'components/service-provider/RedFlagFilter';
import { assessmentCategoryAndTypeOptions } from 'constants/assessmentTypeTypes';
import {
    delay,
    flattenChildren,
    isAdmin,
    parseOptionGroups,
} from 'helpers/helpers';
import { Filters, HTTP, Location, Response } from 'service';
import SupplyChainService from 'service/SupplyChain/SupplyChainService';

const ssipFilterParentNames = assessmentCategoryAndTypeOptions.map(
    (category) => category.id
);
const SupplyChainSearch = (props) => {
    const params = queryString.parse(props.location.search);
    const root = useSelector((state) => state.root);
    const [clientResponse, setClientResponse] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [searchResponse, setSearchResponse] = useState({});
    const [serviceProviders, setServiceProviders] = useState();
    const [selectedRegions, setSelectedRegions] = useState([]);
    const [regions, setRegions] = useState([]);
    const [services, setServices] = useState([]);
    const [serviceSearch, setServiceSearch] = useState("");
    const [isInit, setIsInit] = useState(true);
    const [pathname, setPathname] = useState(props.location.pathname);
    const [clear, setClear] = useState(null);
    const [searchClear, setSearchClear] = useState(false);
    const [supplyChainSpCategories, setSupplyChainSpCategories] = useState([]);
    const searchOrderingDefaultValue = 'Company Rating: Highest to Lowest';
    const sortFilters = [
        {
            key: 'registeredCompanyName',
            value: 'Company Name: A-Z',
        },
        {
            key: '-registeredCompanyName',
            value: 'Company Name: Z-A',
        },
        {
            key: '-createdAt',
            value: 'Joined Prosure Date: Newest First',
        },
        {
            key: 'createdAt',
            value: 'Joined Prosure Date: Oldest First',
        },
        {
            key: '-rating',
            value: 'Company Rating: Highest to Lowest',
        },
        {
            key: 'rating',
            value: 'Company Rating: Lowest to Highest',
        },
    ];

    const defaultFilters = {
        regions: [],
        categories: [],
        services: [],
        client: undefined,
        datagroup: 'search',
        limit: 10,
        order: '-rating',
        supplyChain: params.supplyChain ?? 'active',
    };

    const [filters, setFilters] = useState({
        ...defaultFilters,
        spCategory: params.spCategory ?? '',
        clientRequirement: params.clientRequirement ?? '',
        suspendedStatus: params.suspendedStatus ?? '',
        redFlagStatus: params.redFlagStatus ?? '',
        isLinked: params.isLinked ?? '',
        isActive: params.isActive ?? '',
        rating: params.rating ?? '',
        contractorAllRisk: params.contractorAllRisk,
        employersLiability: params.employersLiability,
        productsLiability: params.productsLiability,
        professionalIndemnity: params.professionalIndemnity,
        publicLiability: params.publicLiability,
        serviceSearch: params.serviceSearch,
    });

    const supplyChainStatusOptions = [
        {
            id: 'active',
            key: 'supplyChain',
            value: params.supplyChain === 'active',
            label: 'In Supply Chain',
        },
        {
            id: 'pending',
            key: 'supplyChain',
            value: params.supplyChain === 'pending',
            label: 'Invited / Renewals',
        },
        {
            id: 'expired',
            key: 'supplyChain',
            value: params.supplyChain === 'expired',
            label: 'Removed / Expired / Declined',
        },
    ];

    const requirementOptions = [
        {
            id: 'SUCCESS',
            key: 'clientRequirement',
            value: params.clientRequirement === 'SUCCESS',
            label: 'Meets Client Requirements',
        },
        {
            id: 'EXPIRING',
            key: 'clientRequirement',
            value: params.clientRequirement === 'EXPIRING',
            label: 'Requirements Expiring Soon',
        },
        {
            id: 'BELOW',
            key: 'clientRequirement',
            value: params.clientRequirement === 'BELOW',
            label: 'Below Client Requirements',
        },
    ];

    const supplyChainSuspendedStatusesOptions = [
        {
            id: 'NOT_SUSPENDED',
            key: 'suspendedStatus',
            value: params.suspendedStatus === 'NOT_SUSPENDED',
            label: 'Not Suspended',
        },
        {
            id: 'SUSPENDED',
            key: 'suspendedStatus',
            value: params.suspendedStatus === 'SUSPENDED',
            label: 'Suspended',
        },
    ];

    const setMultiLevelSwitchValues = (options, values) => {
        return options.map((row) => {
            row.children = Array.isArray(row.children)
                ? [...setMultiLevelSwitchValues(row.children, values)]
                : row.children;
            row.showChildren = row.children?.some((row) => row.checked);
            row.checked =
                row.children?.every((row) => row.checked) ??
                values?.includes(row.id) ??
                false;
            return row;
        });
    };

    const assessmentCategoriesAndTypes = [
        ...setMultiLevelSwitchValues(
            assessmentCategoryAndTypeOptions,
            params.assessmentCategoriesAndTypes?.split(',') ?? []
        ),
    ];

    useEffect(() => {
        updateSearchFilters(defaultFilters);
        loadClient();
        loadServices();
        loadRegions();

        const urlFilters = Filters.getFilterStateFromUrl(
            props.location.search.slice(1),
            {}
        );

        setServiceSearch(urlFilters.serviceSearch);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //set client
    useEffect(() => {
        (async () => {
            const clientLink = Response.getLink(root.response, 'client');
            if (clientLink !== null) {
                return;
            }

            await setClientResponse(root.client);
            // needs to be emptied to trigger the change of the component
            await setSupplyChainSpCategories([]);
            await loadSpCategories(root.client);

            updateSearchFilters({
                ...filters,
                client: root.client?.id,
            });
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [root.client]);

    const loadClient = async () => {
        if (clientResponse) {
            return;
        }
        const clientLink = Response.getLink(root.response, 'client');
        if (clientLink !== null) {
            const response = await HTTP.get(clientLink, {
                datagroup: 'details',
            });
            await setClientResponse(response.data);
            await loadSpCategories(response.data);
        }
    };

    const loadSpCategories = async (clientResponse) => {
        const categoriesLink = Response.getLink(
            clientResponse,
            'sp-categories'
        );

        if (categoriesLink !== null) {
            const response = await HTTP.get(categoriesLink, {
                limit: 9999,
            });

            let categories = [];

            if (response.data.categories) {
                response.data.categories.forEach((category) => {
                    categories.push({
                        id: category.id,
                        key: 'spCategory',
                        value: params.spCategory === category.id ?? false,
                        label: category.name,
                    });
                });
            }

            setSupplyChainSpCategories(categories);
        }
    };

    useEffect(() => {
        if (pathname !== props.location.pathname) {
            setPathname(props.location.pathname);
            updateSearchFilters(defaultFilters);
            setFilters(defaultFilters);
            setClear(!clear);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.location]);

    useEffect(() => {
        if (!filters) {
            setFilters(defaultFilters);
            updateSearchFilters(defaultFilters);
            setPathname(props.location.pathname);
            setClear(!clear);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    const onSubmit = (event) => {
        setIsLoading(true);
        setServiceProviders(void 0);
    };

    const onResult = (searchResults) => {
        setIsInit(false);
        setSearchResponse(searchResults);
        setServiceProviders(searchResults.companies || []);
        setIsLoading(false);
    };

    const updateSearchFilters = (filters) => {
        const { history, location } = props;
        const urlFilters = Filters.getFilterStateFromUrl(
            location.search.slice(1),
            {}
        );
        urlFilters.page = 1;

        Object.keys(filters).forEach((filterName) => {
            urlFilters[filterName] = filters[filterName];
        });

        setFilters({
            ...urlFilters,
        });

        Location.setQueryStringFromObject(
            history,
            location,
            Filters.convertFilterDataToRequestData(urlFilters),
            false
        );
    };

    const updateSort = (value) => {
        const filterValue = sortFilters.find(
            (filter) => filter.value === value
        );
        updateSearchFilters({ order: filterValue.key });
    };

    const loadServices = async () => {
        const response = await HTTP.get('/registration/services', {
            limit: 999,
            order: 'name',
        });

        if (response) {
            const services = parseOptionGroups(response.data.services);
            setServices(orderBy(services, 'treePath'));
        } else {
            toast.error('Unable to load Services');
        }
    };

    const loadRegions = async () => {
        const response = await HTTP.get('/registration/regions', {
            limit: 999,
            order: 'name',
        });

        if (response) {
            const regions = parseOptionGroups(response.data.regions);

            setRegions(regions);
            setSelectedRegions(loadSelected(regions, 'regions'));
        } else {
            toast.error('Unable to load Regions');
        }
    };

    const loadSelected = (items, property) => {
        const { location } = props;
        const result = [];

        const urlFilters = Filters.getFilterStateFromUrl(
            location.search.slice(1),
            {}
        );

        if (urlFilters[property]) {
            urlFilters[property].split('-').forEach((item) => {
                const found = items.find((i) => item === i.name);

                if (typeof found !== 'undefined') {
                    result.push(items.find((i) => item === i.name));
                }
            });
        }

        return result;
    };

    const onUpdateFilterSelect = (options, key) => {
        const activeOptionIds = options
            .filter((option) => option.value)
            .map((option) => option.id);

        setFilters({
            ...filters,
            [key]: activeOptionIds.join(','),
        });

        updateSearchFilters({
            ...filters,
            [key]: activeOptionIds.join(','),
        });
    };

    const onUpdateAssessmentCategories = (options, key) => {
        const flatOptions = flattenMultiLevelSwitch(options);

        const activeOptionIds = flatOptions
            .filter((option) => option.checked)
            .map((option) => option.id)
            .filter((value) => !ssipFilterParentNames.includes(value));

        updateSearchFilters({
            ...filters,
            [key]: activeOptionIds.join(','),
        });
    };

    const onInsuranceUpdate = (insurances) => {
        const insuranceFilters = {};

        for (const type in insurances) {
            const value = insurances[type];

            if (value === false) {
                insuranceFilters[type] = '';
            } else {
                insuranceFilters[type] = '' + value;
            }
        }

        updateSearchFilters({
            ...filters,
            ...insuranceFilters,
        });
    };

    const flattenMultiLevelSwitch = (options) => {
        let flatOptions = [];

        options.forEach((row) => {
            const rows = flattenChildren(row);
            flatOptions = [...flatOptions, ...rows];
        });

        return flatOptions;
    };

    const getPlaceholderText = (type, data) => {
        let text = '';

        if (data.length > 0) {
            text = `${data.length} ${type}${
                data.length > 1 ? 's' : ''
            } selected`;
        } else {
            text = `Limit by ${type}?`;
        }

        return text;
    };

    if (isAdmin() && !clientResponse) {
        return (
            <div className="SearchPage SupplyChainSearch reduced">
                <div className="no-styling search-results">
                    <div className="no-results">
                        Please select a Client to view Supply Chain List
                    </div>
                </div>
            </div>
        );
    }

    const onReset = async () => {
        const { history } = props;
        history.push('/supply-chain');
        await delay(200);
        history.push('/supply-chain/list');

        setSearchClear(!searchClear);
    };

    const onResetFilters = () => {
        setFilters({
            ...filters,
            ...defaultFilters,
            supplyChain: '',
        });

        updateSearchFilters({
            ...filters,
            ...defaultFilters,
            supplyChain: '',
        });

        setClear(!clear);
    };

    const removeEmptyFilters = (filters) => {
        for (const filter in filters) {
            if (!filters[filter]) {
                delete filters[filter];
            }
        }

        return filters;
    };

    const downloadHandler = async () => {
        const { id: clientId } = clientResponse;
        let requestData = Filters.convertFilterDataToRequestData(filters);
        requestData = removeEmptyFilters(requestData);

        const res = await SupplyChainService.downloadClientSupplyChain(
            clientId,
            requestData
        );

        res && toast.success('Supply Chain Downloaded');
        !res &&
            toast.error(
                'An error occured, supply chain could not be downloaded'
            );
    };

    const link = clientResponse?._links['client-supply-chain'];

    if (!link) {
        return null;
    }

    return (
        <div
            className={
                'SearchPage SupplyChainSearch ' + (!isInit ? 'reduced' : '')
            }
        >
            <div className="no-styling search-results-widget ">
                <Loading isLoading={!filters && !clientResponse?._links}>
                    <SearchWidget
                        searchLink={link}
                        searchPlaceholder="What company are you looking for?"
                        searchLabel="Company Name"
                        searchKey="registeredCompanyName"
                        defaultFilters={filters}
                        onSubmit={onSubmit}
                        onResult={onResult}
                        onReset={onReset}
                    >
                        <FormGroup
                            label={'Services Offered'}
                            labelFor={'services'}
                            className={
                                'bp3-inline form-fill multi-select-container'
                            }
                        >
                            <TextSuggestion
                                id="services"
                                items={services}
                                placeholder="Limit By Services?"
                                reset={searchClear}
                                value={serviceSearch}
                                onItemSelect={(value) => {
                                    setFilters({
                                        ...filters,
                                        serviceSearch: value,
                                    });
                                }}
                            />
                        </FormGroup>
                        <FormGroup
                            label={'Regions Operated'}
                            labelFor={'regions'}
                            className={
                                'bp3-inline form-fill multi-select-container'
                            }
                        >
                            <MultiSelectRenderer
                                id={'regions'}
                                onRemoveItem={(items, event) => {
                                    setFilters({
                                        ...filters,
                                        regions: items.map((e) => e.name),
                                    });
                                    setSelectedRegions(items);
                                }}
                                onItemSelect={(items, event) => {
                                    setFilters({
                                        ...filters,
                                        regions: items.map((e) => e.name),
                                    });
                                    setSelectedRegions(items);
                                }}
                                file={true}
                                items={regions}
                                selectedItems={selectedRegions}
                                valueProperty={'name'}
                                placeholder={getPlaceholderText(
                                    'Region',
                                    selectedRegions
                                )}
                                showTags={false}
                            />
                        </FormGroup>
                    </SearchWidget>
                </Loading>
            </div>

            <div className="no-styling search-results">
                {isInit ? null : (
                    <>
                        <div className="search-results-header">
                            <h1 className="page-title">
                                Supply Chain List View
                                <ResultsCount count={searchResponse.count} />
                            </h1>
                            <div className="search-results-sort">
                                <DownloadButton
                                    tooltipText="Download"
                                    tooltipPosition={PopoverPosition.TOP}
                                    disabled={isLoading}
                                    onClick={downloadHandler}
                                />
                                <span>Sort By : </span>
                                <HTMLSelect
                                    options={sortFilters.map((f) => f.value)}
                                    onChange={(e) => updateSort(e.target.value)}
                                    defaultValue={searchOrderingDefaultValue}
                                />
                            </div>
                        </div>

                        <div className="search-results-filters">
                            <div className="filter-header">
                                <span>Filters</span>
                                <button
                                    className="clear-button success"
                                    onClick={onResetFilters}
                                >
                                    Reset
                                </button>
                            </div>
                            <div className="filter-body">
                                <Accordion title="SUPPLY CHAIN STATUS">
                                    <MultiSwitchToggle
                                        options={supplyChainStatusOptions}
                                        onlyOneActive={true}
                                        onUpdate={(options) =>
                                            onUpdateFilterSelect(
                                                options,
                                                'supplyChain'
                                            )
                                        }
                                        clear={clear}
                                    />
                                </Accordion>
                                <div className="search-filter mb-4">
                                    <Accordion title="REQUIREMENT STATUS">
                                        <MultiSwitchToggle
                                            options={requirementOptions}
                                            onlyOneActive={true}
                                            onUpdate={(options) =>
                                                onUpdateFilterSelect(
                                                    options,
                                                    'clientRequirement'
                                                )
                                            }
                                            clear={clear}
                                        />
                                    </Accordion>
                                </div>
                                <div className="search-filter mb-4">
                                    <Accordion title="SUSPENDED STATUS">
                                        <MultiSwitchToggle
                                            options={
                                                supplyChainSuspendedStatusesOptions
                                            }
                                            onlyOneActive={true}
                                            onUpdate={(options) =>
                                                onUpdateFilterSelect(
                                                    options,
                                                    'suspendedStatus'
                                                )
                                            }
                                            clear={clear}
                                        />
                                    </Accordion>
                                </div>
                                {supplyChainSpCategories.length > 0 && (
                                    <div className="search-filter mb-4">
                                        <Accordion title="SERVICE PROVIDER CATEGORY">
                                            <MultiSwitchToggle
                                                options={
                                                    supplyChainSpCategories
                                                }
                                                onlyOneActive={true}
                                                onUpdate={(options) =>
                                                    onUpdateFilterSelect(
                                                        options,
                                                        'spCategory'
                                                    )
                                                }
                                                clear={clear}
                                            />
                                        </Accordion>
                                    </div>
                                )}
                                <div className="search-filter mb-4">
                                    <Accordion title="ASSESSMENTS">
                                        <MultiLevelSwitchArray
                                            fields={
                                                assessmentCategoriesAndTypes
                                            }
                                            onUpdate={(options) =>
                                                onUpdateAssessmentCategories(
                                                    options,
                                                    'assessmentCategoriesAndTypes'
                                                )
                                            }
                                            clear={clear}
                                        />
                                    </Accordion>
                                </div>
                                <div className="search-filter mb-4">
                                    <LinkedFilter
                                        onUpdate={(options) =>
                                            onUpdateFilterSelect(
                                                options,
                                                'isLinked'
                                            )
                                        }
                                        clear={clear}
                                        initialValue={params.isLinked ?? ''}
                                    />
                                </div>
                                <ActiveFilter
                                    onUpdate={(opt) =>
                                        onUpdateFilterSelect(opt, 'isActive')
                                    }
                                    initialValue={params.isActive}
                                    clear={clear}
                                />
                                <RatingFilter
                                    onUpdate={(options) =>
                                        onUpdateFilterSelect(options, 'ratings')
                                    }
                                    clear={clear}
                                    initialValue={params.ratings ?? ''}
                                />
                                <RedFlagFilter
                                    onUpdate={(options) =>
                                        onUpdateFilterSelect(
                                            options,
                                            'redFlagStatus'
                                        )
                                    }
                                    clear={clear}
                                    initialValues={
                                        params.redFlagStatus?.split(',') ?? []
                                    }
                                />
                                <InsurancesFilter
                                    onUpdate={(options) =>
                                        onInsuranceUpdate(options)
                                    }
                                    clear={clear}
                                    initialValues={{
                                        contractorAllRisk:
                                            params.contractorAllRisk,
                                        employersLiability:
                                            params.employersLiability,
                                        productsLiability:
                                            params.productsLiability,
                                        professionalIndemnity:
                                            params.professionalIndemnity,
                                        publicLiability: params.publicLiability,
                                    }}
                                />
                            </div>
                        </div>

                        <div className="search-results-body">
                            <div className="full-width">
                                {!serviceProviders ? (
                                    <div className="full-width">
                                        <LoadingIndicator />
                                    </div>
                                ) : serviceProviders.length ? (
                                    serviceProviders.map((company) => (
                                        <CompanyCard
                                            supplyChainStatus={
                                                defaultFilters.supplyChain
                                            }
                                            key={'company-card-' + company.id}
                                            company={company}
                                            clientResponse={clientResponse}
                                            showRequirements={true}
                                            onReset={onReset}
                                        />
                                    ))
                                ) : (
                                    <div className="no-results">
                                        No results found.
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="search-results-footer">
                            {isLoading ||
                            (searchResponse &&
                                !(
                                    searchResponse.count > searchResponse.limit
                                )) ? null : (
                                <Paginator
                                    page={searchResponse.page}
                                    count={searchResponse.count}
                                    limit={searchResponse.limit}
                                    onPage={updateSearchFilters}
                                />
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default withRouter(SupplyChainSearch);
