import { H1 } from '@blueprintjs/core';
import { Redirect, Switch } from 'react-router-dom';

import AssessmentTypeInstanceList from 'components/assessment-types/AssessmentTypeInstanceList';
import { ProtectedRoute } from 'components/elements';

const AssessmentTypes = (props) => {
    const { companyResponse, match } = props;

    return (
        <div className="page AssessmentTypes">
            <div className="page-wrapper no-styling">
                <div className="page-header">
                    <H1 className="page-title">
                        My Assessments
                    </H1>
                </div>

                <Switch>
                    <ProtectedRoute
                        path={`${match.url}`}
                        component={ AssessmentTypeInstanceList }
                        companyResponse={ companyResponse }
                        exact
                    />
                    <Redirect to={`${match.url}`} />
                </Switch>
            </div>
        </div>
    );
}

export default AssessmentTypes;
