import { useEffect, useState } from 'react';
import { Switch } from 'react-router-dom';

import CertificationMembershipAddEdit from 'components/company/certification-memberships/CertificationMembershipAddEdit';
import CertificationMembershipInfo from 'components/company/certification-memberships/CertificationMembershipInfo';
import { HTTP, Response } from 'service';
import { Loading } from 'components/elements/wrappers';
import { ProtectedRoute } from 'components/elements';
import "./styles.scss";

const CertificationMembershipDetails = props => {
    const { companyResponse, match } = props;
    const certificatemembershipId = match.params.id;

    const [ certificationMembershipDetails, setCertificationMembershipDetails ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);

    useEffect(() => {
        loadCertificationMembershipData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadCertificationMembershipData = async () => {
        setIsLoading(true);

        const certificatemembershipLink = Response.getLink(companyResponse, 'certification-memberships');

        await HTTP
            .get(`${ certificatemembershipLink }/${ certificatemembershipId }`, { datagroup: 'details' })
            .then(response => {
                setCertificationMembershipDetails(response.data);
            });

        setIsLoading(false);
    }

    return <>
        <div className="certificationMembershipDetails">
            <Loading isLoading={ isLoading || certificationMembershipDetails === false }>
                <Switch>
                    <ProtectedRoute
                        path={`${match.url}`}
                        component={ CertificationMembershipInfo }
                        certificationMembershipDetails={ certificationMembershipDetails }
                        exact
                    />
                    <ProtectedRoute
                        path={`${match.url}/edit`}
                        component={ CertificationMembershipAddEdit }
                        reloadData= { loadCertificationMembershipData }
                        companyResponse={ companyResponse }
                        certificationMembershipDetails={ certificationMembershipDetails }
                        exact
                    />
                </Switch>
            </Loading>
        </div>
    </>;
}

export default CertificationMembershipDetails;
