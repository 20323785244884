import { useEffect, useReducer } from 'react';
import { H3 } from '@blueprintjs/core';

import { SupplyChainSuspendedStatusReducerState } from 'interface';
import { ClientService, Response } from 'service';
import { TooltipIcon, DonutChart } from 'components/elements';
import { Loading } from 'components/elements/wrappers';

const SPSuspendedStatusChart = (props: any) => {
    const { client, selectedCategory } = props;
    const [supplyChainSuspendedStatus, setSupplyChainSuspendedStatus]: [
        state: SupplyChainSuspendedStatusReducerState,
        setState: React.ReducerWithoutAction<any>
    ] = useReducer(
        (state: SupplyChainSuspendedStatusReducerState, edits: any) => {
            return { ...state, ...edits };
        },
        {
            isLoading: true,
            isVisible: true,
            suspendedCount: void 0,
            notSuspendedCount: void 0,
            colors: [
                {
                    name: 'notSuspendedCount',
                    label: 'Not Suspended',
                    colour: '#7bbb4c',
                },
                {
                    name: 'suspendedCount',
                    label: 'Suspended',
                    colour: '#ed5565',
                },
            ],
        }
    );
    const load = async () => {
        setSupplyChainSuspendedStatus({ isLoading: true });

        if (client.id === 'all-clients') {
            setSupplyChainSuspendedStatus({ isVisible: false });
        } else {
            setSupplyChainSuspendedStatus({ isVisible: true });
        }

        if (Response.getLink(client, 'supply-chain-suspension-counts')) {
            setSupplyChainSuspendedStatus(
                await ClientService.getSupplyChainSuspendedState(
                    client,
                    selectedCategory
                )
            );
        }
    };

    useEffect(() => {
        load();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client, selectedCategory]);

    return (
        <>
            {supplyChainSuspendedStatus.isVisible && (
                <section className="supply-chain-card info-card donut-parent">
                    <section className="info-card-header">
                        <h2 className="title">
                            <TooltipIcon
                                text={
                                    <section>
                                        The breakdown on your active supply
                                        chain by those that are currently
                                        suspended vs those that are not
                                        suspended.
                                        <br />
                                        <br />
                                        Green - Not suspended
                                        <br />
                                        <br />
                                        Red - Suspended
                                    </section>
                                }
                                icon="info"
                            />
                            <span className="ps-2">Suspended Status</span>
                        </h2>
                    </section>
                    <section className="info-card-body flex-justify-center flex-v-center">
                        <Loading
                            isLoading={supplyChainSuspendedStatus.isLoading}
                        >
                            {supplyChainSuspendedStatus.suspendedCount +
                                supplyChainSuspendedStatus.notSuspendedCount >
                            0 ? (
                                <H3>
                                    There are currently no Service Providers in
                                    your supply chain
                                </H3>
                            ) : (
                                <DonutChart
                                    data={supplyChainSuspendedStatus.counts}
                                    height={250}
                                    width={250}
                                    legendWidth={210}
                                    text=""
                                    colours={
                                        supplyChainSuspendedStatus.colors ?? []
                                    }
                                />
                            )}
                        </Loading>
                    </section>
                </section>
            )}
        </>
    );
};

export default SPSuspendedStatusChart;
