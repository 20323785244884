import { toast } from 'react-toastify';

import {
    RESET_ROOT,
    SETTING_ROOT_CLIENT,
    SETTING_ROOT_RESPONSE
} from "action/rootConstants";
import { HTTP } from 'service';

export {
    getRootClient, getRootResponse, resetRoot, setRootClient
};

function getRootResponse()
{
    return dispatch => {
        return HTTP
            .get('/')
            .then((response) => {
                if (response) {
                    dispatch(setRootResponse(response.data));

                    return true;
                }

                toast.error('Unable to fetch root response');

                return false;
            });
    }
}

function setRootResponse(response)
{
    return {
        type: SETTING_ROOT_RESPONSE,
        response: response,
    };
}

function getRootClient() {
    return {
        type: SETTING_ROOT_CLIENT,
    }
}

function setRootClient(response) {
    return {
        type: SETTING_ROOT_CLIENT,
        client: response,
    }
}

function resetRoot() {
    return {
        type: RESET_ROOT
    };
}
