import {
    Button,
    FormGroup,
    H1,
    InputGroup,
    Radio,
    RadioGroup,
} from '@blueprintjs/core';
import { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { toast } from 'react-toastify';

import { ButtonLink, Icon, SuggestRenderer } from 'components/elements';
import { Loading } from 'components/elements/wrappers';
import { ASSESSMENT_TYPE_VERSION_OPTIONS } from 'constants/AssessmentTypeVersion';
import { globalDebounce } from 'helpers/helpers';
import { HTTP, Response, Routing, StateHandler } from 'service';

const ServiceProviderCategoryAddEdit = (props) => {
    const { history, match, clientResponse, mergeResponse, reload } = props;

    const [isLoading, setIsLoading] = useState(true);
    const [assessments, setAssessments] = useState([]);
    const [assessmentSearchValue, setAssessmentSearchValue] = useState('');
    const [editData, setEditData] = useState({
        spCategoryId: match.params.id,
        data: null,
        isLoading: false,
        loaded: false,
    });

    const [form, setForm] = useState({
        name: null,
        isActive: 'true',
        professionalIndemnityInsurance: null,
        publicLiabilityInsurance: null,
        employersLiabilityInsurance: null,
        productsLiabilityInsurance: null,
        contractorAllRiskInsurance: null,
        requiresClientSpecificAssessment: 'false',
        requiresProsurePreQualified: 'false',
        requiresProsureSSIPVerified: 'false',
        requiresProsureAnnualAdministrativeFee: 'false',
        requiresClientAnnualFee: 'false',
        requiresElogsCafmIntegration: 'false',
        allowProsureDTS: 'false',
        clientAssessment: null,
        _links: {
            'assessment-type': {
                href: null,
            },
        },
    });

    const loadAssessmentTypes = (query = null) => {
        const filterParams = {
            client: clientResponse.id,
        };

        if (query) {
            filterParams.name = query;
        }

        HTTP.get('/assessment-types', filterParams).then((response) => {
            setAssessments(
                response.data.assessmentTypes.map((at) => {
                    at.name += ` (${
                        ASSESSMENT_TYPE_VERSION_OPTIONS[at.versionStatus]
                    })`;
                    return at;
                })
            );
        });
    };

    const assessmentSearch = (query = null) => {
        globalDebounce(
            () => {
                setAssessmentSearchValue(query ?? '');
                loadAssessmentTypes(query);
            },
            'assessmentSearch',
            250
        );
    };

    useEffect(() => {
        if (isLoading) {
            if (
                editData.spCategoryId &&
                !editData.isLoading &&
                !editData.loaded
            ) {
                loadSpCategoryData();
            }
            loadAssessmentTypes();
            setIsLoading(false);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const loadSpCategoryData = async () => {
        try {
            setEditData({ ...editData, isLoading: true });
            const response = await HTTP.get(
                `/clients/${clientResponse.id}/service-provider-category/${editData.spCategoryId}`,
                { datagroup: 'details' }
            );

            if (response) {
                const data = response.data;

                setEditData({
                    ...editData,
                    data,
                    isLoading: false,
                    loaded: true,
                });
                const formdata = { ...form, ...data };
                if (data.clientAssessment) {
                    formdata._links['assessment-type'] =
                        data.clientAssessment._links.self;
                }
                setForm(formdata);
            }
        } catch (e) {
            toast.error('Failed to load Service Provider Category.');
            console.warn(e.code, e.message);

            return false;
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (editData.spCategoryId) {
            if (!editData.loaded) {
                return toast.error('Data not loaded. Could not update');
            }

            const result = await HTTP.action(
                'put',
                Response.getLink(editData.data, 'edit-sp-category'),
                form,
                {},
                'Unable to update Service Provider Requirements.',
                () => {
                    toast.error(
                        'Unable to update Service Provider Requirements.'
                    );
                }
            );

            if (!result) {
                return false;
            }

            toast.success('Service Provider Requirement updated');
            reload();
            Routing.navigateUpOneLevel(history, match);

            if (mergeResponse) {
                mergeResponse(form);
            }

            return true;
        }

        return HTTP.post(
            Response.getLink(clientResponse, 'add-sp-category'),
            form
        ).then((response) => {
            if (response) {
                toast.success('Service Provider Requirement created');
                Routing.navigateUpOneLevel(history, match);

                reload();

                if (props.reloadData) {
                    props.reloadData();
                }

                return true;
            }

            toast.error('Unable to add Requirement');

            return false;
        });
    };

    return (
        <div className="ServiceProviderCategoryAdd">
            <H1 className="clearfix">
                {editData.spCategoryId ? 'Edit' : 'Add new'} Requirement
            </H1>

            <Loading isLoading={isLoading}>
                <form className="Add" onSubmit={(e) => handleSubmit(e)}>
                    <FormGroup
                        label="Name"
                        inline={true}
                        className="form-fill required"
                        labelFor="name"
                    >
                        <InputGroup
                            id="name"
                            placeholder="Name"
                            minLength={2}
                            maxLength={60}
                            value={form.name}
                            onChange={(e) =>
                                setForm(
                                    StateHandler.getStateObject(e, form, 'name')
                                )
                            }
                            required
                        />
                    </FormGroup>

                    <FormGroup
                        label="State"
                        inline={true}
                        className="form-fill"
                    >
                        <RadioGroup
                            id="isActive"
                            fill={true}
                            inline={true}
                            className="form-fill"
                            value={form.isActive}
                            selectedValue={
                                ['boolean', 'string'].indexOf(
                                    typeof form.isActive
                                ) !== -1
                                    ? form.isActive.toString()
                                    : null
                            }
                            onChange={(e) =>
                                setForm(
                                    StateHandler.getStateObject(
                                        e,
                                        form,
                                        'isActive'
                                    )
                                )
                            }
                        >
                            <Radio label="Active" value={'true'} />
                            <Radio label="Inactive" value={'false'} />
                        </RadioGroup>
                    </FormGroup>

                    <FormGroup
                        label="Professional Indemnity Insurance"
                        inline={true}
                        className="form-fill"
                    >
                        <CurrencyInput
                            id="professionalIndemnityInsurance"
                            name="professionalIndemnityInsurance"
                            className="bp3-input currencyInput"
                            placeholder="Please enter a number"
                            value={form.professionalIndemnityInsurance}
                            allowNegativeValue={false}
                            decimalsLimit={2}
                            maxLength={10}
                            disableAbbreviations={true}
                            step={1}
                            prefix="£"
                            onValueChange={(value) =>
                                setForm(
                                    Object.assign({}, form, {
                                        professionalIndemnityInsurance: value,
                                    })
                                )
                            }
                        />
                    </FormGroup>

                    <FormGroup
                        label="Public Liability Insurance"
                        inline={true}
                        className="form-fill"
                    >
                        <CurrencyInput
                            id="publicLiabilityInsurance"
                            name="publicLiabilityInsurance"
                            className="bp3-input currencyInput"
                            placeholder="Please enter a number"
                            value={form.publicLiabilityInsurance}
                            allowNegativeValue={false}
                            decimalsLimit={2}
                            maxLength={10}
                            disableAbbreviations={true}
                            step={1}
                            prefix="£"
                            onValueChange={(value) =>
                                setForm(
                                    Object.assign({}, form, {
                                        publicLiabilityInsurance: value,
                                    })
                                )
                            }
                        />
                    </FormGroup>

                    <FormGroup
                        label="Employers Liability Insurance"
                        inline={true}
                        className="form-fill"
                    >
                        <CurrencyInput
                            id="employersLiabilityInsurance"
                            name="employersLiabilityInsurance"
                            className="bp3-input currencyInput"
                            placeholder="Please enter a number"
                            value={form.employersLiabilityInsurance}
                            allowNegativeValue={false}
                            decimalsLimit={2}
                            maxLength={10}
                            disableAbbreviations={true}
                            step={1}
                            prefix="£"
                            onValueChange={(value) =>
                                setForm(
                                    Object.assign({}, form, {
                                        employersLiabilityInsurance: value,
                                    })
                                )
                            }
                        />
                    </FormGroup>

                    <FormGroup
                        label="Products Liability Insurance"
                        inline={true}
                        className="form-fill"
                    >
                        <CurrencyInput
                            id="productsLiabilityInsurance"
                            name="productsLiabilityInsurance"
                            className="bp3-input currencyInput"
                            placeholder="Please enter a number"
                            value={form.productsLiabilityInsurance}
                            allowNegativeValue={false}
                            decimalsLimit={2}
                            maxLength={10}
                            disableAbbreviations={true}
                            step={1}
                            prefix="£"
                            onValueChange={(value) =>
                                setForm(
                                    Object.assign({}, form, {
                                        productsLiabilityInsurance: value,
                                    })
                                )
                            }
                        />
                    </FormGroup>

                    <FormGroup
                        label="Contractor All Risk Insurance"
                        inline={true}
                        className="form-fill"
                    >
                        <CurrencyInput
                            id="contractorAllRiskInsurance"
                            name="contractorAllRiskInsurance"
                            className="bp3-input currencyInput"
                            placeholder="Please enter a number"
                            value={form.contractorAllRiskInsurance}
                            allowNegativeValue={false}
                            decimalsLimit={2}
                            maxLength={10}
                            disableAbbreviations={true}
                            step={1}
                            prefix="£"
                            onValueChange={(value) =>
                                setForm(
                                    Object.assign({}, form, {
                                        contractorAllRiskInsurance: value,
                                    })
                                )
                            }
                        />
                    </FormGroup>

                    <FormGroup
                        label="Requires Prosure Pre Qualified"
                        inline={true}
                        className="form-fill required"
                    >
                        <RadioGroup
                            fill={true}
                            inline={true}
                            className="form-fill"
                            selectedValue={
                                ['boolean', 'string'].indexOf(
                                    typeof form.requiresProsurePreQualified
                                ) !== -1
                                    ? form.requiresProsurePreQualified.toString()
                                    : null
                            }
                            onChange={(e) =>
                                setForm(
                                    StateHandler.getStateObject(
                                        e,
                                        form,
                                        'requiresProsurePreQualified'
                                    )
                                )
                            }
                            required
                        >
                            <Radio label="Yes" value={'true'} />
                            <Radio label="No" value={'false'} />
                        </RadioGroup>
                    </FormGroup>

                    <FormGroup
                        label="Requires Prosure SSIP Verified"
                        inline={true}
                        className="form-fill required"
                    >
                        <RadioGroup
                            fill={true}
                            inline={true}
                            className="form-fill"
                            selectedValue={
                                ['boolean', 'string'].indexOf(
                                    typeof form.requiresProsureSSIPVerified
                                ) !== -1
                                    ? form.requiresProsureSSIPVerified.toString()
                                    : null
                            }
                            onChange={(e) => {
                                const formState = { ...form };

                                if (
                                    e.target.value === 'true' &&
                                    (formState.allowProsureDTS === 'true' ||
                                        formState.allowProsureDTS)
                                ) {
                                    formState.allowProsureDTS = false;
                                }

                                setForm(
                                    StateHandler.getStateObject(
                                        e,
                                        formState,
                                        'requiresProsureSSIPVerified'
                                    )
                                );
                            }}
                            required
                        >
                            <Radio label="Yes" value={'true'} />
                            <Radio label="No" value={'false'} />
                        </RadioGroup>
                    </FormGroup>

                    {(form.requiresProsureSSIPVerified === 'true' ||
                        form.requiresProsureSSIPVerified === true) && (
                        <FormGroup
                            label="Also Allow Prosure DTS"
                            inline={true}
                            className="form-fill required"
                        >
                            <RadioGroup
                                fill={true}
                                inline={true}
                                className="form-fill"
                                selectedValue={
                                    ['boolean', 'string'].indexOf(
                                        typeof form.allowProsureDTS
                                    ) !== -1
                                        ? form.allowProsureDTS.toString()
                                        : null
                                }
                                onChange={(e) =>
                                    setForm(
                                        StateHandler.getStateObject(
                                            e,
                                            form,
                                            'allowProsureDTS'
                                        )
                                    )
                                }
                                required
                            >
                                <Radio label="Yes" value={'true'} />
                                <Radio label="No" value={'false'} />
                            </RadioGroup>
                        </FormGroup>
                    )}

                    <FormGroup
                        label="Requires Client Specific Assessment"
                        inline={true}
                        className="form-fill required"
                    >
                        <RadioGroup
                            fill={true}
                            inline={true}
                            className="form-fill"
                            selectedValue={
                                ['boolean', 'string'].indexOf(
                                    typeof form.requiresClientSpecificAssessment
                                ) !== -1
                                    ? form.requiresClientSpecificAssessment.toString()
                                    : null
                            }
                            onChange={(e) =>
                                setForm(
                                    StateHandler.getStateObject(
                                        e,
                                        form,
                                        'requiresClientSpecificAssessment'
                                    )
                                )
                            }
                            required
                        >
                            <Radio label="Yes" value={'true'} />
                            <Radio label="No" value={'false'} />
                        </RadioGroup>
                    </FormGroup>

                    {(form.requiresClientSpecificAssessment === 'true' ||
                        form.requiresClientSpecificAssessment === true) && (
                        <FormGroup
                            label="Client Assessment Name"
                            key="assessment"
                            inline={true}
                            className="form-fill"
                            labelFor="assessment"
                        >
                            <SuggestRenderer
                                id="assessment"
                                valueProperty="name"
                                onItemSelect={(item, event) => {
                                    let links = {
                                        ...form._links,
                                        'assessment-type': {
                                            href: Response.getLink(
                                                item,
                                                'self'
                                            ),
                                        },
                                    };
                                    setForm({
                                        ...form,
                                        _links: links,
                                        clientAssessment: item,
                                    });

                                    return;
                                }}
                                defaultItem={form.clientAssessment}
                                items={assessments}
                                onKeyUp={(event) => {
                                    if (
                                        assessmentSearchValue !==
                                        event.target.value.trim()
                                    ) {
                                        assessmentSearch(event.target.value);
                                    }
                                }}
                                isRequired={true}
                            />
                        </FormGroup>
                    )}

                    <FormGroup
                        label="Requires Prosure Annual Administrative Fee?"
                        inline={true}
                        className="form-fill required"
                    >
                        <RadioGroup
                            fill={true}
                            inline={true}
                            className="form-fill"
                            selectedValue={
                                ['boolean', 'string'].indexOf(
                                    typeof form.requiresProsureAnnualAdministrativeFee
                                ) !== -1
                                    ? form.requiresProsureAnnualAdministrativeFee.toString()
                                    : null
                            }
                            onChange={(e) =>
                                setForm(
                                    StateHandler.getStateObject(
                                        e,
                                        form,
                                        'requiresProsureAnnualAdministrativeFee'
                                    )
                                )
                            }
                            required
                        >
                            <Radio label="Yes" value={'true'} />
                            <Radio label="No" value={'false'} />
                        </RadioGroup>
                    </FormGroup>

                    <FormGroup
                        label="Requires Client Annual Fee?"
                        inline={true}
                        className="form-fill required"
                    >
                        <RadioGroup
                            fill={true}
                            inline={true}
                            className="form-fill"
                            selectedValue={
                                ['boolean', 'string'].indexOf(
                                    typeof form.requiresClientAnnualFee
                                ) !== -1
                                    ? form.requiresClientAnnualFee.toString()
                                    : null
                            }
                            onChange={(e) =>
                                setForm(
                                    StateHandler.getStateObject(
                                        e,
                                        form,
                                        'requiresClientAnnualFee'
                                    )
                                )
                            }
                            required
                        >
                            <Radio label="Yes" value={'true'} />
                            <Radio label="No" value={'false'} />
                        </RadioGroup>
                    </FormGroup>

                    <FormGroup
                        label="Elogs CAFM Integration Required"
                        className="form-fill required"
                        inline
                    >
                        <RadioGroup
                            fill
                            inline
                            className="form-fill"
                            selectedValue={
                                ['boolean', 'string'].indexOf(
                                    typeof form.requiresElogsCafmIntegration
                                ) !== -1
                                    ? form.requiresElogsCafmIntegration.toString()
                                    : null
                            }
                            onChange={(e) =>
                                setForm(
                                    StateHandler.getStateObject(
                                        e,
                                        form,
                                        'requiresElogsCafmIntegration'
                                    )
                                )
                            }
                            required
                        >
                            <Radio label="Yes" value={'true'} />
                            <Radio label="No" value={'false'} />
                        </RadioGroup>
                    </FormGroup>

                    <FormGroup>
                        <ButtonLink
                            type="button"
                            intent="default"
                            className="float-left"
                            to={Routing.getRouteUpOneLevel(history, match)}
                        >
                            <Icon icon="ban" />
                            Cancel
                        </ButtonLink>
                        <Button
                            type="submit"
                            intent="primary"
                            className="float-right"
                        >
                            <Icon icon="paper-plane" />
                            {editData.spCategoryId ? 'Update' : 'Create'}
                        </Button>
                    </FormGroup>
                </form>
            </Loading>
        </div>
    );
};

export default ServiceProviderCategoryAddEdit;
