import { Button, Callout, Collapse, FormGroup } from '@blueprintjs/core';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { withRouter } from 'react-router';

import { Icon } from 'components/elements';
import MultiSelect from 'components/elements/filter/MultiSelect';
import SingleSelect from 'components/elements/filter/SingleSelect';
import Text from 'components/elements/filter/Text';
import { Filters, Location, Utility } from 'service';

class Filter extends Component {
    static propTypes = {
        onFilter: PropTypes.func.isRequired,
        onClear: PropTypes.func.isRequired,
        filterData: PropTypes.object.isRequired,
        isOpen: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        isOpen: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            filterData: props.filterData || {},
            filterGrid: this.buildFilters(),
            isClearing: false,
            isOpen: props.isOpen || false,
        };

        this.handleClear = this.handleClear.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
        this.buildFilters = this.buildFilters.bind(this);
    }

    componentDidMount() {
        // this.updateUrl(true);
        let hasFilters = false;
        Object.keys(this.state.filterData).forEach((current) => {
            // ignore pagination and ordering
            if (['order', 'page'].indexOf(current) !== -1) {
                return false;
            }
            // ignore -object options
            if (current.includes('-object')) {
                return false;
            }

            hasFilters = true;
        });
        if (hasFilters) {
            this.setState({ isOpen: true });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isOpen !== this.props.isOpen) {
            this.setState({ isOpen: this.props.isOpen });
        }

        if (
            isEqual(prevProps.filterData, this.props.filterData) === false ||
            this.state.isClearing
        ) {
            this.setState(
                {
                    filterData: this.props.filterData,
                    filterGrid: this.buildFilters(),
                    isClearing: false,
                } /*, () => this.setUrl*/
            );
        }
    }

    render() {
        return (
            <div className="Filter">
                <Collapse isOpen={this.state.isOpen} transitionDuration={0}>
                    <Callout>
                        <form
                            onSubmit={(event) => {
                                event.preventDefault();
                                event.stopPropagation();

                                this.handleFilter(event);
                            }}
                        >
                            {this.state.filterGrid}

                            <FormGroup>
                                <Button
                                    type="button"
                                    intent="default"
                                    className="clear-button success"
                                    onClick={this.handleClear}
                                >
                                    <Icon icon="minus-circle" />
                                    Clear Filters
                                </Button>

                                <Button
                                    type="submit"
                                    className="float-right"
                                    intent="primary"
                                >
                                    <Icon icon="paper-plane" />
                                    Filter
                                </Button>
                            </FormGroup>
                        </form>
                    </Callout>
                </Collapse>
            </div>
        );
    }

    handleClear = (event) => {
        event.preventDefault();
        event.stopPropagation();

        this.setState({ isClearing: true, filterData: {} }, this.props.onClear);
    };

    handleFilter = (event) => {
        event.stopPropagation();
        event.preventDefault();

        const { filterData } = this.state;
        const filters = Filters.convertFilterDataToRequestData(filterData);

        this.props.onFilter(filters, filterData);

        //Location.setQueryStringFromObject(history, location, filters);
    };

    handleFilterChange = (selected, filterKey) => {
        let newFilterData = Object.assign({}, this.state.filterData, {
            [filterKey]: selected,
        });

        this.setState({ filterData: newFilterData });
        // this.updateUrl();
    };

    buildFilters() {
        const filterId = Utility.generateUuid();
        const columns = isMobile ? 1 : 4;

        let filterGroups = [];
        React.Children.forEach(this.props.children, (child, index) => {
            if (index % columns === 0) {
                filterGroups.push([]);
            }

            filterGroups[filterGroups.length - 1].push(
                React.cloneElement(child, {
                    onChange: this.handleFilterChange,
                    value: this.props.filterData[child.props.filterKey] ?? '',
                })
            );
        });

        let grid = [];
        filterGroups.forEach((filterGroup, rowIndex) => {
            let row = [];

            // Pad out row to correct number of columns
            if (filterGroup.length !== columns) {
                filterGroup = [
                    ...filterGroup,
                    ...Array(columns - filterGroup.length).fill(null),
                ];
            }

            filterGroup.forEach((filter, filterIndex) => {
                row.push(
                    <div
                        className="FilterItem"
                        key={`${filterId}-${rowIndex}-${filterIndex}`}
                    >
                        {filter}
                    </div>
                );
            });

            grid.push(
                <div className="FilterRow" key={`${filterId}-${rowIndex}`}>
                    {row}
                </div>
            );
        });

        return grid;
    }

    getActiveFilterCount() {
        return Object.keys(this.state.filterData || {}).filter((key) => {
            return !!(this.state.filterData[key]
                ? this.state.filterData[key].value
                : false);
        }).length;
    }

    updateUrl(replace = false) {
        const { history, location } = this.props;
        const { filterData } = this.state;

        Location.setQueryStringFromObject(
            history,
            location,
            filterData,
            replace
        );

        return;
    }

    static formatSelectResponse(response, responseKey, idKey, labelKey) {
        return response[responseKey].map((item) => {
            return {
                id: item[idKey || 'id'],
                label: item[labelKey || 'name'],
            };
        });
    }
}

Filter.MultiSelect = MultiSelect;
Filter.SingleSelect = SingleSelect;
Filter.Text = Text;

export default withRouter(Filter);
