import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'components/elements';

class Tel extends Component
{
    static propTypes = {
        tel: PropTypes.string.isRequired,
    };
    static defaultProps = {};

    render()
    {
        const { tel } = this.props;
        return (
            <a href={ `tel:${ tel }` }>
                { tel ?
                    <Icon icon="phone" /> :
                    null
                }

                { tel }
            </a>
        );
    }
}

export default Tel;
