import { ComponentProps, useState } from 'react';

import List from 'components/elements/List';
import { BENEFIT_OPTIONS_WITH_NONE_SELECTED } from 'constants/benefits';
import { NONE_SELECTED_OPTION } from 'constants/none-selected';
import { delay } from 'helpers/helpers';
import {
    BenefitsData,
    BenefitsListFilter,
    ListCollection,
    ListSearchFilter,
} from 'interface';
import { BenefitsService } from 'service';

interface BenefitsListProps extends ComponentProps<any> {}

interface BenefitsListSearchFilter {
    benefit: ListSearchFilter;
}

const BenefitsInterestList = (props: BenefitsListProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [collection, setCollection] = useState<ListCollection<BenefitsData>>(
        {} as ListCollection<BenefitsData>
    );
    const [listFilter, setListFilter] = useState<BenefitsListFilter>({
        page: 1,
        limit: 8,
        order: 'name',
    });
    const searchFilters: BenefitsListSearchFilter = {
        benefit: {
            type: 'single-select',
            defaultValue: NONE_SELECTED_OPTION,
            label: 'Benefit Type',
            key: 'benefit',
            options: BENEFIT_OPTIONS_WITH_NONE_SELECTED,
        },
    };

    const columns = [
        {
            name: 'SP Users full name',
            property: 'userFullName',
            sortable: true,
            grow: 2,
        },
        {
            name: 'Service Provider',
            property: 'companyName',
            sortable: true,
            grow: 2,
        },
        {
            name: 'Which Benefit',
            property: 'benefitName',
            sortable: true,
            grow: 2,
        },
        {
            name: 'Date Clicked',
            property: 'createdAt',
            type: 'datetime',
            sortable: true,
            grow: 2,
        },
    ];

    const load = async (filter: BenefitsListFilter) => {
        setIsLoading(true);
        const copyFilter = { ...listFilter, ...filter };
        const benefitsInterest = await BenefitsService.loadList(copyFilter);

        setCollection(benefitsInterest);
        setListFilter(copyFilter);
        await delay(0);
        setIsLoading(false);
    };

    return (
        <div className="list">
            <div className="BenefitsList">
                <List
                    title="Membership Benefits Registered Interest"
                    columns={columns}
                    load={(filter) => load(filter as BenefitsListFilter)}
                    collection={collection}
                    filter={listFilter}
                    isLoading={isLoading}
                    filters={searchFilters}
                />
            </div>
        </div>
    );
};

export default BenefitsInterestList;
