const routes = {
    admin_assessment_types: {
        href: '/admin/assessment-types',
        text: 'Assessment Types',
        icon: 'cubes',
    },
    admin_assessment_types_add: {
        href: '/admin/assessment-types/add',
        text: 'Add new Assessment Type',
        skipNavigation: true,
    },
    admin_assessment_types_details: {
        href: '/admin/assessment-types/:assessmentTypeId',
        text: '{assessmentType.name}',
        skipNavigation: true,
    },
    admin_client_details_summary: {
        href: '/admin/assessment-types/:assessmentTypeId/summary',
        text: 'Summary',
    },
    admin_client_details_questions: {
        href: '/admin/assessment-types/:assessmentTypeId/questions',
        text: 'Questions',
    },
    admin_client_details_sla: {
        href: '/admin/assessment-types/:assessmentTypeId/slas',
        text: 'SLAs',
    },
    admin_client_details_history: {
        href: '/admin/assessment-types/:assessmentTypeId/history',
        text: 'History',
    },
    admin_client_details_pricing: {
        href: '/admin/assessment-types/:assessmentTypeId/pricing',
        text: 'Commercial',
    },
};

export default routes;
