import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ProgressBar as BPProgressBar } from '@blueprintjs/core';

class ProgressBar extends Component
{
    static propTypes = {
        uploadProgress: PropTypes.number,
    };
    static defaultProps = {
        uploadProgress: 0,
    };

    constructor(props)
    {
        super(props);

        this.state = {
            uploadProgress: 0,
            uploadProgressInterval: undefined,
        };

        this.updateProgress = this.updateProgress.bind(this);
    }

    componentDidMount()
    {
        this.updateProgress();
    }

    componentWillUnmount()
    {
        window.clearInterval(this.state.uploadProgressInterval);
    }

    render()
    {
        const { uploadProgress } = this.state;

        return (
            <BPProgressBar animate={true}
                stripes={true}
                intent={ uploadProgress < 50 ? 'danger' : (uploadProgress < 90 ? 'warning' : (uploadProgress < 99 ? 'primary' : 'success')) }
                value={ (uploadProgress / 100) } />
        );
    }

    updateProgress()
    {
        let uploadProgressInterval = window.setInterval(() => {
            const { uploadProgress } = this.state;

            if (uploadProgress < 99) {
                this.setState({uploadProgress: parseInt((uploadProgress + ((100 - uploadProgress) / 8)), 10)});
            }
        }, 100);

        this.setState({uploadProgressInterval: uploadProgressInterval});
    }
}

export default ProgressBar;
