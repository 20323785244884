import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { updatingBreadcrumbResolves } from 'action/breadcrumb';
import { Card, H1, H3, Button, FormGroup, TextArea } from '@blueprintjs/core';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { cloneDeep } from 'lodash';

import { Loading, NoResultsFound } from 'components/elements/wrappers';
import { Icon, SuggestRenderer } from 'components/elements';
import { DragDrop, HTTP, Response } from 'service';
import NestedQuestions from "./AssessmentTypeNestedQuestions";
import "./styles.scss";

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = {
    updatingBreadcrumbResolves: updatingBreadcrumbResolves,
};

class AssessmentTypeQuestionList extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            id: props.match.params.id,
            isLoading: false,
            isLoadingTopics: false,
            response: {},
            topics: {},
            assessmentTypeId: props.assessmentTypeResponse.id,
            showAddTopicButton: true,
            showAddTopic: false,
            showSaveButton: false,
            newTopicName: '',
            newTopicDescription: '',
            topicDefault: {
                _links: {
                    self: {
                        href: null,
                        method: "GET"
                    }
                },
                name: null,
                id: null,
                newQuestionLink: null,
                showAddQuestonForm: false,
                showAddQuestonButton: true,
                questions: []
            },
            questionDefault: {
                _links: {
                    self: {
                        href: null,
                        method: "GET"
                    },
                    question: {
                        href: null,
                        method: "GET",
                        name: null,
                        id: null
                    }
                }
            }
        };

        this.getTopics = this.getTopics.bind(this);
        this.handleSelectTopicQuestion = this.handleSelectTopicQuestion.bind(this);
        this.handleSubmitAddTopic = this.handleSubmitAddTopic.bind(this);
        this.handleSubmitAddTopicQuestion = this.handleSubmitAddTopicQuestion.bind(this);
        this.hideAddTopic = this.hideAddTopic.bind(this);
        this.hideAddTopicQuestion = this.hideAddTopicQuestion.bind(this);
        this.hideSaveButton = this.hideSaveButton.bind(this);
        this.load = this.load.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.removeQuestion = this.removeQuestion.bind(this);
        this.removeTopic = this.removeTopic.bind(this);
        this.saveQuestions = this.saveQuestions.bind(this);
        this.showAddTopic = this.showAddTopic.bind(this);
        this.showAddTopicQuestion = this.showAddTopicQuestion.bind(this);
        this.showSaveButton = this.showSaveButton.bind(this);
    }

    componentDidMount()
    {
        this.props.updatingBreadcrumbResolves({questionCategory: undefined});
        this.load();
    }

    render()
    {
        const { isLoading } = this.state;
        const topics = this.getTopics();

        const addTopicButton = this.state.showAddTopicButton ? (
            <Button type="button"
                intent="primary"
                icon="add"
                onClick={ this.showAddTopic }
                className="float-right">
                <Icon icon="plus-circle" />
                Topic
            </Button>
        ) : null;

        const saveButton = this.state.showSaveButton ? (
            <FormGroup>
                <Button
                    onClick={ this.load }
                    className="float-left">
                    <Icon icon="paper-plane" />
                    Cancel
                </Button>
                <Button
                    intent="primary"
                    onClick={ this.saveQuestions }
                    className="float-right">
                    <Icon icon="paper-plane" />
                    Save
                </Button>

            </FormGroup>
        ) : null;

        const addTopicForm = this.state.showAddTopic ? (
            <Card>
                <form
                    onSubmit={ event => this.handleSubmitAddTopic(event) }>
                    <div className="input-group topic-input-group">
                        <div className="input-fields">
                            <input
                                id="topicName"
                                type="text"
                                onChange={ e => this.setState({newTopicName: e.target.value}) }
                                className="bp3-input bp3-form-content"
                                required
                                placeholder="Enter Topic Name.." />

                            <TextArea id="topicDescription"
                                fill={true}
                                onChange={ e => this.setState({newTopicDescription: e.target.value}) }
                                placeholder="Enter Topic Description.." />
                        </div>

                        <div className="buttons">
                            <Button
                                intent="default"
                                onClick={ this.hideAddTopic }
                                className="float-right">
                                <Icon icon="paper-plane" />
                                Cancel
                            </Button>

                            <Button
                                type="submit"
                                intent="primary"
                                className="float-right">
                                <Icon icon="paper-plane" />
                                Add
                            </Button>
                        </div>
                    </div>
                </form>
            </Card>
        ) : null;

        return (
            <div className="QuestionCategoryDetails">
                <Loading isLoading={ isLoading }>
                    <H1 className="clearfix">
                        Topics
                        { addTopicButton }
                    </H1>
                    <div className="QuestionCategoryQuestions">
                        { addTopicForm }
                        <DragDropContext
                            onDragEnd={ this.onDragEnd }>
                            <Droppable
                                droppableId="topicList"
                                type="TOPICS">
                                { provided => {
                                    return (
                                        <div className="Droppable"
                                            ref={ provided.innerRef }
                                            { ...provided.droppableProps }>
                                            <Loading isLoading={ this.state.isLoadingTopics }>
                                                <NoResultsFound count={ topics.length }>
                                                    { topics }
                                                </NoResultsFound>
                                            </Loading>
                                            { provided.placeholder }
                                        </div>
                                    );
                                } }
                            </Droppable>
                        </DragDropContext>
                        { saveButton }
                    </div>
                </Loading>
            </div>
        );
    }

    showAddTopic() {
        this.setState({
            showAddTopic: true,
            showAddTopicButton: false
        });
    }

    showAddTopicQuestion = (index) => {
        let updatedTopics = [
            ...this.state.topics
        ]

        updatedTopics[index].showAddQuestonButton = false;
        updatedTopics[index].showAddQuestonForm = true;
        updatedTopics[index].newQuestionLink = null;

        this.setState({
            topics: updatedTopics
        });
    }

    hideAddTopicQuestion = (topicIndex) => {
        let updatedTopics = [
            ...this.state.topics
        ]

        updatedTopics[topicIndex].showAddQuestonButton = true;
        updatedTopics[topicIndex].showAddQuestonForm = false;
        updatedTopics[topicIndex].newQuestionLink = null;

        this.setState({
            topics: updatedTopics
        });
    }

    handleSelectTopicQuestion = (topicIndex, questionLink) => {
        let updatedTopics = [
            ...this.state.topics
        ]

        updatedTopics[topicIndex].newQuestionLink = questionLink;

        this.setState({
            topics: updatedTopics
        });
    }

    hideAddTopic() {
        this.setState({
            showAddTopic: false,
            showAddTopicButton: true
        });
    }

    handleSubmitAddTopic(event)
    {
        event.stopPropagation();
        event.preventDefault();

        const newTopic = {
            id: Date.now(),
            name: this.state.newTopicName,
            description: this.state.newTopicDescription,
            questions: [],
            showAddQuestonForm: false,
            showAddQuestonButton: true,
        };

        var updatedTopics = [newTopic, ...this.state.topics];
        this.setState({
            topics: updatedTopics
        });

        this.resetQuestionForm();
        this.showSaveButton();
    }

    handleSubmitAddTopicQuestion(event, topicIndex)
    {
        event.stopPropagation();
        event.preventDefault();

        let updatedTopics = [
            ...this.state.topics
        ]

        const newQuestion = cloneDeep(this.state.questionDefault);
        newQuestion.name = updatedTopics[topicIndex].newQuestionLink.summary;
        newQuestion._links.question.href = updatedTopics[topicIndex].newQuestionLink.link

        updatedTopics[topicIndex].questions.push(newQuestion);

        this.setState({
            topics: updatedTopics
        });

        this.hideAddTopicQuestion(topicIndex);
        this.resetQuestionForm();
        this.showSaveButton();
    }

    saveQuestions() {
        const patchData = { topics: this.state.topics };

        HTTP
            .patch(Response.getLink(this.props.assessmentTypeResponse, 'questions'), patchData)
            .then((response) => {
                if (response) {
                    toast.success('Assessment Type Questions Saved');
                    this.load();
                    return true;
                }

                toast.error('Unable to save criteria');

                return false;
            });
    }

    resetQuestionForm()
    {
        this.setState({
            showAddTopic: false,
            showAddTopicButton: true,
            newTopicName: "",
            newTopicDescription: "",
        });
    }

    showSaveButton()
    {
        this.setState({
            showSaveButton: true
        });
    }

    hideSaveButton() {
        this.setState({
            showSaveButton: false
        });
    }

    removeQuestion = (questionIndex, topicIndex) => {
        let updatedTopics = [
            ...this.state.topics
        ]

        updatedTopics[topicIndex].questions.splice(questionIndex, 1);

        this.setState({
            topics: updatedTopics
        });

        this.showSaveButton();
    }

    onDragEnd(result)
    {
        if(result.type === 'TOPICS') {
            let newTopics = DragDrop.getSingularReorderedList(result, this.state.topics);

            if (newTopics) {
                this.setState({ topics: newTopics });
                this.showSaveButton();
            }
        } else {
            const topicIndex = result.type;
            const newTopicQuestions = DragDrop.getSingularReorderedList(result, this.state.topics[topicIndex].questions);

            if (newTopicQuestions) {
                let updatedTopics = [
                    ...this.state.topics
                ]

                updatedTopics[topicIndex].questions = newTopicQuestions;

                this.setState({
                    topics: updatedTopics
                });

                this.showSaveButton();
            }
        }
    }

    getTopicDescription = (description) => {
        if (description && description.length) {
            description = description
                .split("\n")
                .map(text => <>{ text }<br/></>);

            return <p className="topic-description">{ description }</p>
        }

        return null;
    }

    removeTopic = (index) => {
        let updatedTopics = [
            ...this.state.topics
        ]
        updatedTopics.splice(index, 1);

        this.setState({
            topics: updatedTopics
        });

        this.showSaveButton();
    }

    getTopics()
    {
        const { topics } = this.state;
        const cards = [];

        if (Array.isArray(topics)) {
            topics.forEach((topic, index) => {
                let addQuestionButton = topic.showAddQuestonButton ? (
                    <Button type="button"
                        intent="primary"
                        icon="add"
                        onClick={ () => this.showAddTopicQuestion(index) }>
                        Question
                    </Button>
                ) : null;

                let addQuestionForm = topic.showAddQuestonForm ? (
                    <Card >
                        <form
                            onSubmit={ event => this.handleSubmitAddTopicQuestion(event, index) }>
                            <div className="input-group question-add ">
                                <div className="bp3-form-content">
                                    <SuggestRenderer
                                        onItemSelect={ (item, event) => {
                                            if (!item) {
                                                return;
                                            }

                                            this.handleSelectTopicQuestion(index, {summary: item.summary, link: Response.getLink(item, 'self')});
                                        } }
                                        searchUrl="/questions"
                                        searchObjectName="questions"
                                        searchKey="summary"
                                        searchParams={ {
                                            order: 'summary',
                                            isActive: 'true',
                                            excludeAssessmentTypeId: this.state.assessmentTypeId
                                        } }
                                        isRequired={true}
                                        valueProperty="summary" />
                                </div>

                                <div className="buttons">
                                    <Button
                                        intent="default"
                                        onClick={ () => this.hideAddTopicQuestion(index) }
                                        className="float-right">
                                        <Icon icon="paper-plane" />
                                        Cancel
                                    </Button>

                                    <Button
                                        type="submit"
                                        intent="primary"
                                        className="float-right">
                                        <Icon icon="paper-plane" />
                                        Add
                                    </Button>
                                </div>
                            </div>

                        </form>
                    </Card>
                ) : null;

                cards.push((
                    <Draggable draggableId={ topic.id }
                        index={ index }
                        key={ topic.id }>
                        { provided => {
                            return (
                                <div ref={ provided.innerRef }
                                    key={ topic.id }
                                    { ...provided.draggableProps }>
                                    <Card>
                                        <div className="topic-header flex-v-top">
                                            <span className="topic-drag-handle" { ...provided.dragHandleProps }>
                                                <Icon icon="grip-vertical" />
                                            </span>

                                            <div className="topic-header-text">
                                                <h3>{ topic.name }</h3>
                                                { this.getTopicDescription(topic.description) }
                                            </div>

                                            <div className="topic-buttons">
                                                { addQuestionButton }

                                                <Button
                                                    onClick = {() => this.removeTopic(index)}
                                                    intent='danger'
                                                    icon="trash">
                                                    Topic
                                                </Button>
                                            </div>
                                        </div>

                                        <H3 className="clearfix">
                                            Questions
                                        </H3>

                                        { addQuestionForm }

                                        <NestedQuestions
                                            topicIndex={index}
                                            topic={topic}
                                            removeQuestionHandler={ this.removeQuestion } />
                                    </Card>
                                </div>
                            );
                        } }
                    </Draggable>
                ));
            });
        }

        return cards;
    }

    load()
    {
        if (this.state.isLoading) {
            return;
        }

        this.setState({ isLoading: true });
        this.resetQuestionForm();
        this.hideSaveButton();

        const getUrl = Response.getLink(this.props.assessmentTypeResponse, 'questions');

        HTTP
            .get(getUrl, {
                order: 'order'
            })
            .then((response) => {
                if (response) {
                    this.loadQuestionsByTopic(response);
                    return;
                }
            });
    }

    loadQuestionsByTopic(response) {
        const questionList = response.data.questions;
        const questionsByTopics = [];
        let currentTopicLink = null;
        let currentLinkedTopicIndex = -1;

        questionList.forEach((question) => {
            let linkedTopic = question._links.topic;

            // if linked topic, check if different to currentTopic
            if (currentTopicLink === null || currentTopicLink !==  linkedTopic.href) {
                currentTopicLink = linkedTopic.href;
                let newTopic = cloneDeep(this.state.topicDefault);
                newTopic.id = linkedTopic.id;
                newTopic.name = linkedTopic.name;
                newTopic.description = linkedTopic.description;
                newTopic._links.self.href = linkedTopic.href;

                questionsByTopics.push(newTopic);
                currentLinkedTopicIndex++;
            }

            // add question to topic
            let newQuestion = cloneDeep(this.state.questionDefault);
            newQuestion.id = question.id;
            newQuestion.name = question._links.question.name;
            newQuestion._links.self.href = question._links.self.href;
            newQuestion._links.question.href = question._links.question.href;
            questionsByTopics[currentLinkedTopicIndex].questions.push(newQuestion);
        });

        this.setState({ isLoadingTopics: false, isLoading: false, topics: questionsByTopics });

        return;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentTypeQuestionList);
