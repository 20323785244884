import { Button, FormGroup, H1 } from '@blueprintjs/core';
import { FormEvent, useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';

import { ButtonLink, Icon, SuggestRenderer } from 'components/elements';
import { Loading } from 'components/elements/wrappers';
import { globalDebounce } from 'helpers/helpers';
import type { ClientData } from 'interface';
import { HTTP, Response, Routing } from 'service';

const IntegationsAddMulti = (props: any) => {
    const { history, match } = props;

    const rootResponse = useSelector((state: any) => state.root.response);
    const userId = rootResponse.user.id;

    const [state, setState]: [
        state: any,
        setState: React.ReducerWithoutAction<any>
    ] = useReducer((state: any, edits: any) => ({ ...state, ...edits }), {
        filters: {
            name: '',
            order: 'name',
            hasElogs: 'true',
        },
        isLoading: true,
        isDisabled: true,
        clients: [],
        elbSps: [],
        form: {
            userId: userId,
            client: null,
        },
        errorMsg: null,
        successMsg: null,
    });

    const searchClients = (client?: string) => {
        const filters = { ...state.filters, client: client };
        !client && delete filters.client;
        setState({ filters });
    };

    const loadClients = () => {
        globalDebounce(
            async () => {
                const response = await HTTP.action(
                    'get',
                    '/clients',
                    state.filters
                );

                setState({
                    isLoading: false,
                    clients: response?.data?.clients ?? [],
                });
            },
            'clientsWithElb',
            80,
            true
        );
    };

    const loadSuggestedLinks = async (client: ClientData) => {
        globalDebounce(
            async () => {
                const response = await HTTP.action(
                    'get',
                    Response.getLink(client, 'suggestedLinks') ??
                        `/clients/${client.id}/suggested-links`,
                    { datagroup: 'details', accuracy: 0.03 },
                    {},
                    'Error fetching Service Provider Integrations. Please try again.'
                );

                const total = response?.data.length ?? 0;

                if (total > 0) {
                    setState({
                        suggestedLinks: response?.data ?? [],
                        isDisabled: false,
                        errorMsg: null,
                        successMsg: `${total} potential Service Provider integrations found`,
                        isLoading: false,
                    });
                } else {
                    setState({
                        suggestedLinks: [],
                        isDisabled: true,
                        errorMsg:
                            'no new potential Service Provider Integrations found. Please pick another',
                        successMsg: '',
                        isLoading: false,
                    });
                }
            },
            'suggestedLinks',
            500,
            true
        );
    };

    const selectClient = (item: any) => {
        if (item === null) {
            setState({
                errorMsg: null,
                successMsg: null,
                isLoading: false,
            });
            searchClients('');

            return;
        }

        setState({
            form: {
                ...state.form,
                client: item,
            },
        });
        loadSuggestedLinks(item);
    };

    useEffect(() => {
        loadClients();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(loadClients, [state.filters]);

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();

        history.push(`${match.url}/${state.form.client.id}`);
    };

    return (
        <div className="IntegrationsAddEdit">
            <H1>Add Multiple Service Provider Integrations</H1>

            <Loading isLoading={state.isLoading}>
                <form className="AddEdit" onSubmit={onSubmit}>
                    <FormGroup
                        label="Client"
                        key="client"
                        inline={true}
                        className="form-fill required"
                        labelFor="client"
                    >
                        <SuggestRenderer
                            id="client"
                            onItemSelect={(item: any) => selectClient(item)}
                            placeholder={'Select Client'}
                            valueProperty={'name,elogsClientName'}
                            wrapperLeft="("
                            wrapperRight=")"
                            items={state.clients}
                            onKeyUp={(e: any) => {
                                searchClients(e.target.value);
                            }}
                            isRequired={true}
                        />
                    </FormGroup>

                    {state.errorMsg && (
                        <FormGroup
                            label=" "
                            inline={true}
                            className="form-fill"
                        >
                            <span className="error">{state.errorMsg}</span>
                        </FormGroup>
                    )}

                    {state.successMsg && (
                        <FormGroup
                            label=" "
                            inline={true}
                            className="form-fill"
                        >
                            <span className="success">{state.successMsg}</span>
                        </FormGroup>
                    )}

                    <FormGroup>
                        <ButtonLink
                            type="button"
                            intent="default"
                            className="float-left"
                            to={Routing.getRouteUpOneLevel(
                                history,
                                match,
                                null
                            )}
                        >
                            <Icon icon="ban" />
                            Cancel
                        </ButtonLink>
                        <Button
                            type="submit"
                            intent="primary"
                            className="float-right"
                            disabled={state.isDisabled}
                        >
                            <Icon icon="paper-plane" />
                            Next
                        </Button>
                    </FormGroup>
                </form>
            </Loading>
        </div>
    );
};

export default IntegationsAddMulti;
