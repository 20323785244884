import { createRef, RefObject, useEffect } from 'react';
import * as d3 from 'd3';
import { DateTime } from 'luxon';

import { LineChartData, LineChartInterface } from 'interface/AnalyticalData';
import './index.scss';

/**
 * REQUIRED: Put .chart-parent to the HTML parent element that is responsible for sizing.
 *           This is required so that sizing and placements are consistent.
 * @param props DonutChartInterface
 */
const LineChart = (props: LineChartInterface) => {
    const ref: RefObject<HTMLDivElement> = createRef();
    const margin = { top: 10, right: 45, bottom: 30, left: 38 };
    const data = props.data as LineChartData[];
    const postfix = props.yScale.type === 'percentage' ? '%' : '';
    let y = d3.scaleLinear();
    let x:
        | d3.ScaleBand<any>
        | d3.ScaleTime<any, any>
        | d3.ScaleLinear<any, any>;

    const draw = () => {
        if (!ref.current || !props.data) {
            return;
        }

        // clear old chart
        d3.select(ref.current).select('svg').remove();

        const svg = d3
            .select(ref.current)
            .append('svg')
            .attr('width', props.width)
            .attr('height', props.height);

        drawScale(svg);
        drawDataLine(svg);
    };

    const drawScale = (svg: d3.Selection<any, any, any, any>) => {
        let dataY;

        switch (props.yScale.type) {
            case 'percentage':
                dataY = data.map((row: any) => row.y);
                y.domain([
                    Math.ceil(Math.max(50, ...dataY) / 10) * 10,
                    Math.floor(Math.min(40, ...dataY) / 10) * 10,
                ]).range([margin.top, props.height - margin.bottom]);
                break;
            case 'linear':
                dataY = data.map((row: any) => row.y);
                y.domain([Math.ceil(Math.max(5, ...dataY) / 10) * 10, 0]).range(
                    [margin.top, props.height - margin.bottom]
                );
                break;
        }
        svg.append('g')
            .attr('transform', `translate(${margin.left}, ${margin.top})`)
            .call(
                d3
                    .axisLeft(y)
                    .tickSize(props.width - margin.left - margin.right)
                    .tickFormat((value) => `${value}${postfix}`)
            )
            .call((g) => g.select('.domain').remove())
            .call((g) => {
                g.selectAll('.tick line')
                    .attr('x1', 0)
                    .attr('x2', props.width - margin.left - margin.right)
                    .attr('stroke-opacity', 0.5)
                    .attr('stroke-dasharray', '2,2');
                g.selectAll('.tick text').attr('x', -10);

                return g;
            });

        switch (props.xScale.type) {
            case 'date':
                x = d3
                    .scaleTime()
                    .domain([
                        d3.min(data, (d) =>
                            (d.x as DateTime)
                                ?.minus({ month: 1 })
                                .set({ day: 15 })
                                .toJSDate()
                        ) || new Date(),
                        d3.max(data, (d) =>
                            (d.x as DateTime)?.set({ day: 15 }).toJSDate()
                        ) || new Date(),
                    ])
                    .range([margin.left, props.width - margin.right]);

                svg.append('g')
                    .attr(
                        'transform',
                        `translate(0, ${
                            props.height - margin.bottom + margin.top
                        })`
                    )
                    .call(
                        d3
                            .axisBottom(x)
                            .ticks(d3.timeMonth.every(1))
                            .tickFormat((value?: any) => {
                                if (value instanceof Date) {
                                    return DateTime.fromJSDate(value).toFormat(
                                        'MMM yyyy'
                                    );
                                }

                                return '';
                            })
                    );
                break;
            case 'band':
                x = d3
                    .scaleBand()
                    .domain(
                        data.map((row) =>
                            row.x instanceof DateTime
                                ? row.x.toFormat('MMM y')
                                : row.x.toString()
                        )
                    )
                    .range([margin.left, props.width - margin.right]);
                svg.append('g')
                    .attr(
                        'transform',
                        `translate(0, ${
                            props.height - margin.bottom + margin.top
                        })`
                    )
                    .call(d3.axisBottom(x));
                break;
            case 'linear':
                x = d3
                    .scaleLinear()
                    .domain(
                        data.map((row) =>
                            typeof row.x === 'number'
                                ? row.x
                                : parseFloat(row.x.toString())
                        )
                    )
                    .range([margin.left, props.width - margin.right]);
                svg.append('g')
                    .attr(
                        'transform',
                        `translate(${margin.left}, ${margin.top})`
                    )
                    .call(d3.axisLeft(x));
                break;
        }
    };

    const drawDataLine = (svg: d3.Selection<any, any, any, any>) => {
        const dataLine = svg
            .append('g')
            .attr('class', 'data-line')
            .style('transform', `translateY(${margin.top}px)`);

        const line = d3.line(
            (d: any) => x(d.x),
            (d: any) => y(d.y)
        );

        //circles on data points
        data.forEach((row: any) => {
            dataLine
                .append('circle')
                .attr('cx', x(row.x))
                .attr('cy', y(row.y))
                .attr('r', 3);
        });

        dataLine.append('path').datum(data).attr('d', line);
        //text on data points
        data.forEach((row: any) => {
            dataLine
                .append('text')
                .attr('dx', '0.5em')
                .attr('dy', '0.3em')
                .attr('y', y(row.y))
                .attr('x', x(row.x))
                .text(row.y + postfix);
        });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(draw, [props.data, ref.current]);

    return (
        <div className="line-chart" ref={ref}>
            <div className="chart-tooltip"></div>
        </div>
    );
};

export default LineChart;
