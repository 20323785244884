import {
    CLEAR_AUTH_ERRORS,
    CLEAR_ERRORS,
    CLEAR_MESSAGE,
    CLEAR_REDIRECT,
    LOGIN_FAILURE,
    LOGIN_SUCCESS,
    LOGOUT,
    REQUEST_LOGIN,
    SET_AUTH_ERRORS,
    SET_BUILD_TIMESTAMP,
    SET_ERRORS,
    SET_REDIRECT,
    TOKEN_INVALID,
    TOKEN_VALID,
    UPDATE_REGISTER_FORM,
} from 'action/authConstants';

const defaultState = {
    isAuthenticated: false,
    token: undefined,
    errors: undefined,
    authErrors: undefined,
    loggingOut: false,
    buildTimeStamp: undefined,
};

export default function auth(state = defaultState, action) {
    const baseState = Object.assign({}, defaultState, state);
    let redirect;

    switch (action.type) {
        case REQUEST_LOGIN:
            return Object.assign(baseState, {
                isRequesting: true,
                isAuthenticated: false,
            });

        case LOGIN_SUCCESS:
            return Object.assign(baseState, {
                isRequesting: false,
                isAuthenticated: true,
                token: action.token,
            });

        case LOGIN_FAILURE:
            return Object.assign(baseState, {
                isRequesting: false,
                isAuthenticated: false,
                token: undefined,
            });

        case TOKEN_VALID:
            return Object.assign(baseState, {
                token: action.token,
            });

        case LOGOUT:
            return Object.assign({}, defaultState, {
                redirect: undefined,
                message: 'You have been successfully logged out',
                messageType: 'success',
                token: undefined,
                loggingOut: true,
            });

        case TOKEN_INVALID:
            redirect = state.redirect ? state.redirect : action.redirect; // only do first redirect
            return Object.assign({}, defaultState, {
                redirect: redirect,
                message: action.message,
                messageType: 'error',
            });

        case SET_REDIRECT:
            if (state.loggingOut) {
                return Object.assign({}, defaultState, {
                    loggingOut: false,
                });
            }

            redirect = state.redirect ? state.redirect : action.redirect; // only do first redirect

            return Object.assign(baseState, {
                redirect: redirect,
            });

        case CLEAR_REDIRECT:
            return Object.assign(baseState, {
                redirect: undefined,
            });

        case CLEAR_MESSAGE:
            return Object.assign(baseState, {
                message: undefined,
            });

        case UPDATE_REGISTER_FORM:
            return Object.assign(baseState, {
                form: action.form,
            });

        case SET_AUTH_ERRORS:
            return Object.assign(baseState, {
                authErrors: action.errors,
            });

        case SET_ERRORS:
            return Object.assign(baseState, {
                errors: action.errors,
            });

        case CLEAR_AUTH_ERRORS:
            return Object.assign(baseState, {
                authErrors: undefined,
            });

        case CLEAR_ERRORS:
            return Object.assign(baseState, {
                errors: undefined,
            });

        case SET_BUILD_TIMESTAMP:
            return Object.assign(baseState, {
                buildTimeStamp: action.timeStamp,
            });

        default:
            return Object.assign(baseState);
    }
}
