const ASSESSMENT_TYPE_SERVICE_PROVIDER_SIZE_APPLICABILITY_ANY = {label: 'Any number of Employees', value: null};
const ASSESSMENT_TYPE_SERVICE_PROVIDER_SIZE_APPLICABILITY_0_TO_4 = {label: '0-4 Employees', value: 4};
const ASSESSMENT_TYPE_SERVICE_PROVIDER_SIZE_APPLICABILITY_5_PLUS = {label: '5 or More Employees', value: 9999999};
const ASSESSMENT_TYPE_SERVICE_PROVIDER_SIZE_APPLICABILITY_OPTIONS = [
    ASSESSMENT_TYPE_SERVICE_PROVIDER_SIZE_APPLICABILITY_ANY,
    ASSESSMENT_TYPE_SERVICE_PROVIDER_SIZE_APPLICABILITY_0_TO_4,
    ASSESSMENT_TYPE_SERVICE_PROVIDER_SIZE_APPLICABILITY_5_PLUS,
 ];

export {
    ASSESSMENT_TYPE_SERVICE_PROVIDER_SIZE_APPLICABILITY_ANY,
    ASSESSMENT_TYPE_SERVICE_PROVIDER_SIZE_APPLICABILITY_0_TO_4,
    ASSESSMENT_TYPE_SERVICE_PROVIDER_SIZE_APPLICABILITY_5_PLUS,
    ASSESSMENT_TYPE_SERVICE_PROVIDER_SIZE_APPLICABILITY_OPTIONS,
};
