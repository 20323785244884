import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, Redirect, Switch } from "react-router-dom";
import { Tabs, Tab } from "@blueprintjs/core";

import CertificationMembershipAddEdit from "components/admin/certification-memberships/CertificationMembershipAddEdit";
import CertificationMembershipSummary from "components/admin/certification-memberships/CertificationMembershipSummary";
import { HTTP, Routing } from "service";
import { Icon, ProtectedRoute } from "components/elements";
import { Loading } from "components/elements/wrappers";
import { updatingBreadcrumbResolves } from "action/breadcrumb";

const CertificationMembershipDetails = (props) => {
    const { location, match } = props;
    const certificationMembershipId = match.params.certificationMembershipId;
    const dispatch = useDispatch();

    const [certificationMembershipDetails, setCertificationMembershipDetails] =
        useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        loadCertificationMembershipData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadCertificationMembershipData = async () => {
        setIsLoading(true);

        await HTTP.get(
            `/certification-memberships/${certificationMembershipId}`,
            { datagroup: "details" }
        ).then((response) => {
            setCertificationMembershipDetails(response.data);
            dispatch(
                updatingBreadcrumbResolves({
                    certificationMembership: { name: response.data.name },
                })
            );
        });

        setIsLoading(false);
    };

    const tabId = Routing.getSelectedTabId(location.pathname, match.url);

    return (
        <>
            <div className="CertificationMembershipDetails">
                <Loading
                    isLoading={
                        isLoading || certificationMembershipDetails === false
                    }
                >
                    <h1 className="clearfix">
                        {certificationMembershipDetails.name}
                    </h1>

                    <Tabs renderActiveTabPanelOnly={true} selectedTabId={tabId}>
                        <Tab id={"/summary"}>
                            <Link to={`${match.url}/summary`}>
                                <Icon icon="info" />
                                Summary
                            </Link>
                        </Tab>
                    </Tabs>
                    <div className="TabContent">
                        <Switch>
                            <ProtectedRoute
                                path={`${match.url}/summary`}
                                component={CertificationMembershipSummary}
                                certificationMembershipDetails={
                                    certificationMembershipDetails
                                }
                                exact
                            />
                            <ProtectedRoute
                                path={`${match.url}/summary/edit`}
                                component={CertificationMembershipAddEdit}
                                reloadData={loadCertificationMembershipData}
                                certificationMembershipDetails={
                                    certificationMembershipDetails
                                }
                                exact
                            />
                            <Redirect to={`${match.url}/summary`} />
                        </Switch>
                    </div>
                </Loading>
            </div>
        </>
    );
};

export default CertificationMembershipDetails;
