import assessmentTypeRoutes from 'routes/admin/assessmenttype';
import reportRoutes from 'routes/admin/report';
import certificaitonMemberships from 'routes/admin/certificationMembership';
import questionRoutes from 'routes/admin/question';
import regionRoutes from 'routes/admin/region';
import serviceProviderRoutes from 'routes/admin/serviceprovider';
import serviceRoutes from 'routes/admin/service';
import userRoutes from 'routes/admin/user';
import discountRoutes from 'routes/admin/discount';
import integrationRoutes from 'routes/admin/integration';

const routes = {
    admin: {
        href: '/admin',
        text: 'Administration',
        icon: 'cogs',
        skipNavigation: true,
        skipBreadcrumb: false,
    },

    admin_question_categories: {
        href: '/admin/question-categories',
        text: 'Question Categories',
        icon: 'cubes',
    },
    admin_question_category_details: {
        href: '/admin/question-categories/:id',
        text: '{questionCategory.name}',
    },
    admin_email_templates: {
        href: '/admin/email-templates',
        text: 'Email Templates',
        icon: 'mail',
    },
    admin_email_templates_details: {
        href: '/admin/email-templates/:id/info',
        text: '{template.name}',
    },
    admin_email_templates_details_edit: {
        href: '/admin/email-templates/:id/info/edit',
        text: 'Edit',
    },
};

const exportedRoutes = Object.assign(
    {},
    routes,
    assessmentTypeRoutes,
    reportRoutes,
    certificaitonMemberships,
    regionRoutes,
    questionRoutes,
    serviceProviderRoutes,
    serviceRoutes,
    userRoutes,
    discountRoutes,
    integrationRoutes
);

export default exportedRoutes;
