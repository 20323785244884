import * as d3 from 'd3';
import { createRef, RefObject, useEffect } from 'react';

import { DonutChartData, DonutChartInterface } from 'interface/AnalyticalData';
import './index.scss';

/**
 * REQUIRED: Put .chart-parent to the HTML parent element that is responsible for sizing.
 *           This is required so that sizing and placements are consistent.
 * @param props DonutChartInterface
 */
const DonutChart = (props: DonutChartInterface) => {
    const ref: RefObject<HTMLDivElement> = createRef();

    const draw = () => {
        if (!ref.current || !props.data) {
            return;
        }
        const colourNames = props.colours.map((colour) => colour.name);
        const colourColours = props.colours.map((colour) => colour.colour);
        const color = d3
            .scaleOrdinal()
            .domain(colourNames)
            .range(colourColours);

        // clear old chart
        d3.select(ref.current).select('svg').remove();

        const svg = d3
            .select(ref.current)
            .append('svg')
            .attr('width', props.width + props.legendWidth + 50)
            .attr('height', props.height)
            .append('g')
            .attr(
                'transform',
                `translate(${props.width / 2},${props.height / 2})`
            );

        if (props.text !== '') {
            svg.append('text').attr('text-anchor', 'middle').text(props.text);
        }

        drawPieChart(svg, color);
        drawLegend(svg, color);
    };

    const drawPieChart = (
        svg: d3.Selection<any, any, any, any>,
        color: d3.ScaleOrdinal<any, any>
    ) => {
        // NOTE: put .chart-parent to the HTML parent element that is responsible for sizing
        const posElem = ref.current?.closest('.chart-parent') as HTMLDivElement;

        const pie = d3
            .pie<DonutChartData>()
            .value(function (d) {
                return d.value;
            })
            .sort(null)
            .padAngle(0.0);

        const tooltip = d3.select(ref.current).select('.chart-tooltip');

        const arch = svg
            .selectAll('.arc')
            .data(pie(props.data))
            .enter()
            .append('g')
            .attr('class', (d) => {
                return d.data.link ? 'arc hover' : 'arc';
            })
            .attr('fill', (d) => {
                return color(d.data.name) as string;
            })
            .on('click', (event, d) => {
                if (d.data.link) {
                    window.location.href = d.data.link;
                }
            })
            .on('mouseover', (event, d) => {
                if (d.data.link) {
                    tooltip.style('visibility', 'visible');
                }
            })
            .on('mousemove', (event, d) => {
                const cx =
                    event.clientX - posElem?.getBoundingClientRect()?.x + 40;
                const cy =
                    event.clientY - posElem?.getBoundingClientRect()?.y - 15;

                tooltip
                    .style('visibility', 'visible')
                    .style('top', cy + 'px')
                    .style('left', cx + 'px').html(`
                        <span>${d.data.label} : ${d.data.value}</span>
                    `);
            })
            .on('mouseout', (event) => {
                tooltip.style('visibility', 'hidden');
            });

        const outerRadius = 125;
        const innerRadius = 80;

        const path = d3
            .arc<d3.PieArcDatum<DonutChartData>>()
            .outerRadius(outerRadius)
            .innerRadius(innerRadius);

        arch.append('path').attr('d', path);
    };

    const drawLegend = (
        svg: d3.Selection<any, any, any, any>,
        color: d3.ScaleOrdinal<any, any>
    ) => {
        const startCy = -110;
        let lastCyHeight = -12;

        color.range().forEach((color: string, index: number) => {
            svg.append('circle')
                .attr('class', 'legendIcon' + index)
                .attr('cx', 160)
                .attr('cy', startCy + 20 * index)
                .attr('r', 6)
                .style('fill', color);
        });
        color.domain().forEach((text: string, index: number) => {
            const dataValue = props.data.find((data) => data.name === text);
            const colourData = props.colours.find((data) => data.name === text);
            const legendText = dataValue
                ? `${colourData?.label}: ${dataValue?.value}`
                : `${colourData?.label}: 0`;

            svg.append('foreignObject')
                .attr('class', 'legendText' + index)
                .attr('x', 175)
                .attr('y', startCy + lastCyHeight)
                .style('width', props.legendWidth + 'px')
                .style('height', '100px')
                .append('xhtml:p')
                .html(legendText)
                .style('width', props.legendWidth + 'px')
                .style('font-size', '15px')
                .style('margin', '0')
                .style('padding-top', '2px')
                .style('vertical-align', 'middle');

            svg.select('.legendIcon' + index).attr(
                'cy',
                startCy + lastCyHeight + 12
            );

            const coords = svg
                .select('.legendText' + index + ' p')
                .node() as HTMLElement;
            lastCyHeight =
                lastCyHeight +
                coords.getBoundingClientRect().bottom -
                coords.getBoundingClientRect().y;
            // svg.append('text')
            //     .attr('x', 175)
            //     .attr('y', startCy + 20 * index - 12)
            //     .text(legendText)
            //     .style('width', '100px')
            //     .style('font-size', '15px')
            //     .attr('alignment-baseline', 'middle');
        });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(draw, [props.data, ref.current]);

    return (
        <div className="donut-chart" ref={ref}>
            <div className="chart-tooltip"></div>
        </div>
    );
};

export default DonutChart;
