import { withRouter } from 'react-router-dom';
import { Payment } from 'components/elements';
import { useEffect, useState } from 'react';
import { HTTP, Response } from 'service';
import { updatingBreadcrumbResolves } from 'action/breadcrumb';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { Loading } from 'components/elements/wrappers';
import { clientInvitationCalculateTotal } from 'helpers/helpers';

const InvitationPayment = (props) => {
    const { history } = props;
    const dispatch = useDispatch();
    const [supplyChainResponse, setSupplyChainResponse] = useState({
        id: undefined,
    });
    const [clientResponse, setClientResponse] = useState({});
    const [total, setTotal] = useState(0.0);

    const confirmationPage = () => {
        history.push('/supply-chains/thank-you');
    };

    useEffect(() => {
        HTTP.get(`/supply-chain/${props.match.params.id}`, {
            datagroup: 'details',
        }).then((response) => {
            if (response) {
                dispatch(
                    updatingBreadcrumbResolves({
                        client: { name: response.data.client.name },
                    })
                );
                setSupplyChainResponse(response.data);

                HTTP.get(Response.getLink(response.data, 'sp-category')).then(
                    (categoryResponse) => {
                        const [total, tax] = clientInvitationCalculateTotal(
                            response.data,
                            categoryResponse.data
                        );
                        setTotal(total + tax);
                    }
                );

                setClientResponse(response.data.client);

                return true;
            }

            toast.error('Unable to fetch Supply Chain Details');

            return false;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Loading isLoading={supplyChainResponse.id === undefined}>
                <Payment
                    instance={clientResponse}
                    total={total}
                    logo={'logo'}
                    title={`Purchase Entry to ${clientResponse.name} Supply Chain`}
                    purchaseUrl={Response.getLink(
                        supplyChainResponse,
                        'purchase'
                    )}
                    confirmUrl={Response.getLink(
                        supplyChainResponse,
                        'confirm-payment'
                    )}
                    confirmationPage={confirmationPage}
                />
            </Loading>
        </>
    );
};

export default withRouter(InvitationPayment);
