import { MouseEvent, Component, ComponentProps, createRef, FormEvent } from 'react';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router';
import {
    Button,
    FormGroup,
    H1,
    HTMLSelect,
    InputGroup,
    Radio,
    RadioGroup,
    Switch,
    TextArea
} from '@blueprintjs/core';
import { AxiosResponse } from 'axios';

import StateHandler from 'service/StateHandler';
import HTTP from 'service/HTTP';
import Response from 'service/Response';
import {
    ACTIVE_OPTIONS,
    QUESTION_TYPE_BOOLEAN,
    QUESTION_TYPE_CHOICE,
    QUESTION_TYPE_OPTIONS,
    QUESTION_TYPE_NUMBER,
    QUESTION_TYPE_FILE,
    QUESTION_TYPE_DATEPICKER,
    QUESTION_OPTIONS_REQUIREMENT_OPTIONS,
    YES_NO_OPTIONS
} from 'constants/all';
import { Option } from 'interfaces';
import { ButtonLink,
    Icon,
    InputElementIcon,
    MultiSelectRenderer,
    MultiSwitch,
    SuggestRenderer,
    PositiveNumericInput
} from 'components/elements';
import { Loading } from 'components/elements/wrappers';
import { globalDebounce } from "helpers/helpers";
import "./index.scss";

class QuestionAddEdit extends Component
{
    private inputs: { option: React.RefObject<any> };
    public readonly state: any;
    public readonly props: any;

    constructor(props: ComponentProps<any>)
    {
        super(props);

        this.state = {
            categoryCollection: {categories: []},
            form: {
                _links: {
                    category: {
                        href: null,
                    },
                    "conditional-question": {
                        href: null,
                    },
                },
                summary: '',
                guidanceNotes: '',
                assessorGuidanceNotes: '',
                category: '',
                type: 'text',
                isActive: 'false',
                isRequired: false,

                minLength: 0,
                maxLength: 3000,
                isTextarea: false,

                minValue: 0,
                maxValue: 0,
                step: 1,

                hasMinDateOffset: false,
                minDateOffset: 0,
                hasMaxDateOffset: false,
                maxDateOffset: 0,

                fileRequirement: 0,
                expiryRequirement: 0,
                allowMultiple: false,
                numberRequirement: 0,
                conditionRequirement: false,

                options: [],

                allowAnAnswerToFailAssessment: false,
                failReason: '',
                yesNoAutoFailOption: false,
                selectedFailOptions: [],

                allowAdditionalComments: null,
            },
            id: props.match.params.id,
            isLoading: false,
            isLoadingCategory: false,
            isLoadingCategories: false,
            isLoadingQuestion: false,
            questionCollection: {questions: []},
            response: {},
            selectedCategory: {},
            selectedQuestion: {},
            conditionalQuestionSearchValue: '',
        };

        this.inputs = {
            option: createRef(),
        };

        this.load = this.load.bind(this);
        this.loadSelectedCategory = this.loadSelectedCategory.bind(this);
        this.loadSelectedQuestion = this.loadSelectedQuestion.bind(this);
        this.loadCategories = this.loadCategories.bind(this);
        this.loadQuestions = this.loadQuestions.bind(this);
        this.handleOptionAdd = this.handleOptionAdd.bind(this);
        this.getQuestionOptionsByType = this.getQuestionOptionsByType.bind(this);
        this.getQuestionFileOptions = this.getQuestionFileOptions.bind(this);
        this.getQuestionOptionsByTypeText = this.getQuestionOptionsByTypeText.bind(this);
        this.getQuestionOptionsByTypeNumber = this.getQuestionOptionsByTypeNumber.bind(this);
        this.getQuestionOptionsByTypeDatepicker = this.getQuestionOptionsByTypeDatepicker.bind(this);
        this.getQuestionOptionsByTypeSelect = this.getQuestionOptionsByTypeSelect.bind(this);
        this.getQuestionOptionsByTypeFile = this.getQuestionOptionsByTypeFile.bind(this);
    }

    componentDidMount()
    {
        this.load();
    }

    handleOptionAdd(event?: MouseEvent)
    {
        const { form } = this.state;
        const { options } = form;
        const { value } = this.inputs.option.current as HTMLSelectElement;

        if (value.length) {
            let existing = options.find((element: Option) => element.value === value);

            if (value.length && typeof existing === 'undefined') {
                options.push({ value: value });
                options.sort((prev: Option, next: Option) => {
                    if (prev.value > next.value) return 1;
                    if (prev.value < next.value) return -1;

                    return 0;
                });

                this.setState({form: Object.assign({}, form, {options})});
            }
        }

        (this.inputs.option.current as HTMLSelectElement).value = '';
    }

    handleOptionRemove(index: number)
    {
        const { form } = this.state as any;
        const { options, selectedFailOptions } = form;

        const deletedItem = options.splice(index, 1);

        const updatedSelectedFailOptions =
            selectedFailOptions.length > 0
                ? selectedFailOptions.filter(
                (failOption: Option) => failOption.value !== deletedItem[0].value
                )
                : selectedFailOptions;

        this.setState({
            ...this.state,
            form: {
                ...form,
                options,
                selectedFailOptions: updatedSelectedFailOptions
            },
        });
    }

    getQuestionOptionsByType()
    {
        switch (this.state.form.type) {
            case 'text':
                return [
                    this.getQuestionOptionsByTypeText(),
                    this.getQuestionFileOptions(),
                ];

            case 'number':
                return [
                    this.getQuestionOptionsByTypeNumber(),
                ];

            case 'select':
                return [
                    this.getQuestionOptionsByTypeSelect(),
                ];

            case 'choice':
                return [
                    this.getQuestionOptionsByTypeChoice(),
                ];

            case 'datepicker':
                return [
                    this.getQuestionOptionsByTypeDatepicker(),
                ];

            case 'file':
                return [
                    this.getQuestionOptionsByTypeFile(),
                ];

            default:
                return;
        }
    }

    getQuestionFileOptions()
    {
        return (
            <>
                <FormGroup label="File"
                    inline={true}
                    className="form-fill required"
                    labelFor="...">
                    <MultiSwitch options={ QUESTION_OPTIONS_REQUIREMENT_OPTIONS }
                        selected={ this.state.form.fileRequirement }
                        onClick={ (e: Event, value: string) => {
                            this.setState({form: StateHandler.getStateObject(e, this.state.form, 'fileRequirement', value)});
                        } } />
                </FormGroup>
                {
                    this.state.form.fileRequirement === 0 ?
                        null :
                        this.getQuestionOptionsByTypeFile()
                }
            </>
        );
    }

    getQuestionOptionsByTypeFile()
    {
        if (this.state.form.type !== 'file') {
            return;
        }

        return (
            <>
                <FormGroup label="Allow Expiration Date"
                    inline={true}
                    className="form-fill"
                    labelFor="expiryRequirement">
                    <MultiSwitch options={ QUESTION_OPTIONS_REQUIREMENT_OPTIONS }
                        selected={ this.state.form.expiryRequirement }
                        onClick={ (e: Event, value: string) => {
                            this.setState({form: StateHandler.getStateObject(e, this.state.form, 'expiryRequirement', value)});
                        } } />
                </FormGroup>
                <FormGroup label="Allow Numeric Input"
                    inline={true}
                    className="form-fill">
                    <MultiSwitch options={ QUESTION_OPTIONS_REQUIREMENT_OPTIONS }
                        selected={ this.state.form.numberRequirement }
                        onClick={ (e: Event, value: string) => {
                            this.setState({form: StateHandler.getStateObject(e, this.state.form, 'numberRequirement', value)});
                        } } />
                </FormGroup>
                {
                    this.state.form.numberRequirement === 0 ?
                        null :
                        this.getQuestionOptionsByTypeNumber()
                }
            </>
        );
    }

    getQuestionOptionsByTypeText()
    {
        return (
            <>
                <FormGroup label="Min. Length"
                    inline={true}
                    className="form-fill"
                    labelFor="minLength">
                    <PositiveNumericInput id="minLength"
                        fill={true}
                        min={0}
                        max={3000}
                        value={ this.state.form.minLength }
                        onUpdate={(numericValue: number) => this.setState({form: { ...this.state.form, minLength: numericValue }})}
                    />
                </FormGroup>
                <FormGroup label="Max. Length"
                    inline={true}
                    className="form-fill"
                    labelFor="maxLength">
                    <PositiveNumericInput id="maxLength"
                        fill={true}
                        min={0}
                        max={3000}
                        value={ this.state.form.maxLength }
                        onUpdate={ (numericValue: number) => this.setState({form: { ...this.state.form, maxLength: numericValue}})
                        } />
                </FormGroup>
                <FormGroup label="Is Textarea"
                    inline={true}
                    className="form-fill"
                    labelFor="isTextarea">
                    <Switch id="isTextarea"
                        checked={ this.state.form.isTextarea }
                        onChange={ e => this.setState({form: StateHandler.getStateObject(e, this.state.form)}) } />
                </FormGroup>
            </>
        );
    }

    getQuestionOptionsByTypeNumber()
    {
        return (
            <>
                <FormGroup label="Min. Value"
                    inline={true}
                    className="form-fill"
                    labelFor="minValue">
                    <PositiveNumericInput id="minValue"
                        fill={true}
                        min={0}
                        value={ this.state.form.minValue }
                        onUpdate={ (numericValue: number) => this.setState({form: { ...this.state.form, minValue: numericValue}})}
                    />
                </FormGroup>
                <FormGroup label="Max. Value"
                    inline={true}
                    className="form-fill"
                    labelFor="maxValue">
                    <PositiveNumericInput id="maxValue"
                        fill={true}
                        min={0}
                        value={ this.state.form.maxValue }
                        onUpdate={ (numericValue: number) => this.setState({form: { ...this.state.form, maxValue: numericValue}})}
                    />
                </FormGroup>
                <FormGroup label="Step"
                    inline={true}
                    className="form-fill"
                    labelFor="step">
                    <PositiveNumericInput id="step"
                        fill={true}
                        min={1}
                        value={ this.state.form.step }
                        onUpdate={ (numericValue: number) => this.setState({form: { ...this.state.form, step: numericValue}})}
                    />
                </FormGroup>
            </>
        );
    }

    getQuestionOptionsByTypeSelect()
    {
        return (
            <>
                <FormGroup label="Options"
                    inline={true}
                    className="form-fill"
                    labelFor="option">
                    <InputGroup id="option"
                        placeholder="Enter an option"
                        minLength={0}
                        maxLength={3000}
                        inputRef={ this.inputs.option }
                        rightElement={
                            <Button type="button"
                                intent="primary"
                                onClick={ (event: MouseEvent) => this.handleOptionAdd(event) }>
                                <Icon icon="plus-circle" />
                                Add Option
                            </Button>
                        } />
                </FormGroup>
            </>
        );
    }

    getQuestionOptionsByTypeChoice()
    {
        return (
            <>
                <FormGroup label="Choices"
                    inline={true}
                    className="form-fill"
                    labelFor="choice">
                    <InputGroup id="choice"
                        placeholder="Enter a new choice"
                        minLength={0}
                        maxLength={3000}
                        onKeyDown={ event => {
                            if (event.key === 'Enter') {
                                this.handleOptionAdd();
                                event.preventDefault();
                                event.stopPropagation();
                            }
                        } }
                        inputRef={ this.inputs.option }
                        rightElement={
                            <Button type="button"
                                intent="primary"
                                onClick={ this.handleOptionAdd }>
                                <Icon icon="plus-circle" />
                                Add Choice
                            </Button>
                        } />
                </FormGroup>
                <div className="input-choices">
                    {
                        this.state.form.options.map((option: Option, index: number) => (
                            <div className="input-choice">
                                <span className="name">{option.value}</span>
                                <button type="button" title="remove this choice"
                                    onClick={ () => this.handleOptionRemove(index) }>
                                    <Icon icon="minus-circle" />
                                </button>
                            </div>
                        ))
                    }
                </div>
            </>
        );
    }

    filterDatePickerMaxValue(max: number)
    {
        const form = {
            ...this.state.form,
            maxDateOffset: max
        }

        if (form.minDateOffset > max) {
            form.minDateOffset = max;
        }

        this.setState({form: form});
    }

    filterDatePickerMinValue(min: number)
    {
        const form = {
            ...this.state.form,
            minDateOffset: min
        }

        if (min > form.maxDateOffset ) {
            form.maxDateOffset = min;
        }

        this.setState({form: form});
    }

    getQuestionOptionsByTypeDatepicker()
    {
        return (
            <>
                <FormGroup label="Min. Date Offset (Days)"
                    inline={true}
                    className="form-fill multi-inputs"
                    labelFor="minDateOffset">
                    <Switch id="hasMinDateOffset"
                        checked={ this.state.form.hasMinDateOffset }
                        onChange={ e => this.setState({form: StateHandler.getStateObject(e, this.state.form)}) } />

                    <PositiveNumericInput id="minDateOffset"
                        fill={true}
                        placeholder="Min. Date Offset"
                        disabled={ !this.state.form.hasMinDateOffset }
                        value={ this.state.form.minDateOffset }
                        handleValueChange={ (numericValue: number) => this.filterDatePickerMinValue(numericValue) }
                        rightElement={ InputElementIcon('Enter a negative number for offset to be in the past, or a positive number for the future', 'info') } />
                </FormGroup>
                <FormGroup label="Max. Date Offset (Days)"
                    inline={true}
                    className="form-fill multi-inputs"
                    labelFor="maxDateOffset">
                    <Switch id="hasMaxDateOffset"
                        checked={ this.state.form.hasMaxDateOffset }
                        onChange={ e => this.setState({form: StateHandler.getStateObject(e, this.state.form)}) } />

                    <PositiveNumericInput id="maxDateOffset"
                        fill={true}
                        placeholder="Max. Date Offset"
                        disabled={ !this.state.form.hasMaxDateOffset }
                        value={ this.state.form.maxDateOffset }
                        handleValueChange={ (numericValue: number) => this.filterDatePickerMaxValue(numericValue) }
                        rightElement={ InputElementIcon('Enter a negative number for offset to be in the past, or a positive number for the future', 'info') } />
                </FormGroup>
            </>
        );
    }

    canAllowAdditionalComments = () => {
        return [
            QUESTION_TYPE_BOOLEAN.value,
            QUESTION_TYPE_CHOICE.value,
            QUESTION_TYPE_NUMBER.value,
            QUESTION_TYPE_FILE.value,
            QUESTION_TYPE_DATEPICKER.value,
        ].includes(this.state.form.type);
    }

    handleSubmit(event: FormEvent)
    {
        const { form } = this.state;
        const { history } = this.props;

        event.preventDefault();
        event.stopPropagation();

        const formData = {
            ...form,
            allowAdditionalComments: this.canAllowAdditionalComments() ? form.allowAdditionalComments : 'false',
        };

        if (this.state.id) {
            HTTP
                .put(Response.getLink(this.state.response, 'edit'), formData)
                .then((response) => {
                    if (response) {
                        toast.success('Question updated');
                        return history.push(`/admin/questions/${this.state.id}`);
                    }

                    toast.error('Unable to update Question');
                });
            return;
        }

        HTTP
            .post('/questions/new', formData)
            .then((response) => {
                if (response) {
                    toast.success('Question created');
                    history.push(`/admin/questions/${response.data.id}`);
                }
            });

    }

    updateSelectedFailOptions = (items: Option[]) => {
        let updatedForm = {
            ...this.state.form,
            selectedFailOptions: items
        }
        this.setState({
            ...this.state,
            form: updatedForm,
        });
    }

    render()
    {
        const isEditing = typeof this.state.id !== 'undefined';
        const { form, isLoading, isLoadingCategory, isLoadingCategories, isLoadingQuestion, selectedCategory, selectedQuestion } = this.state;
        const questionOptionsByType = this.getQuestionOptionsByType();
        const questionCategories = this.state.categoryCollection.categories || [];
        const questions = this.state.questionCollection && this.state.questionCollection.questions ? this.state.questionCollection.questions : []

        return (
            <div className="QuestionAddEdit">
                <H1>
                    {
                        isEditing ?
                            "Edit Question" :
                            "Add new Question"
                    }
                </H1>

                <Loading isLoading={ isLoading || isLoadingCategory || isLoadingCategories || isLoadingQuestion }>
                    <form className="AddEdit"
                        onSubmit={ event => this.handleSubmit(event) }>
                        <FormGroup label="Summary"
                            key="summary"
                            inline={true}
                            className="form-fill required"
                            labelFor="summary">
                            <InputGroup id="summary"
                                placeholder="Summary"
                                minLength={1}
                                maxLength={500}
                                value={ form.summary }
                                onChange={ e => this.setState({form: StateHandler.getStateObject(e, form)}) }
                                required />
                        </FormGroup>
                        <FormGroup label="Guidance Notes"
                            key="guidanceNotes"
                            inline={true}
                            className="form-fill"
                            labelFor="guidanceNotes">
                            <TextArea
                                id="guidanceNotes"
                                placeholder="Guidance Notes"
                                onChange={ e => this.setState({form: StateHandler.getStateObject(e, form)}) }
                                minLength={1}
                                maxLength={3000}
                                value={ form.guidanceNotes }
                            />
                        </FormGroup>

                        <FormGroup label="Assessor Guidance Notes"
                            key="assessorGuidanceNotes"
                            inline={true}
                            className="form-fill"
                            labelFor="assessorGuidanceNotes">
                            <TextArea
                                id="assessorGuidanceNotes"
                                placeholder="Assessor Guidance Notes"
                                onChange={ e => this.setState({form: StateHandler.getStateObject(e, form)}) }
                                minLength={1}
                                maxLength={3000}
                                value={ form.assessorGuidanceNotes }
                            />
                        </FormGroup>

                        <FormGroup label="Category"
                            key="category"
                            inline={true}
                            className="form-fill required"
                            labelFor="category">
                            <SuggestRenderer id="category"
                                onItemSelect={ (item: Option, event: Event) => {
                                    let links = Object.assign({}, form._links, {category: {href: Response.getLink(item, 'self')}, "conditional-question": null});
                                    let newFormState = Object.assign({}, form, {_links: links});
                                    this.setState({form: newFormState, selectedCategory: item, questionCollection: null}, () => {this.conditionalQuestionSearch()});

                                    return;
                                } }
                                defaultItem={ selectedCategory }
                                items={ questionCategories }
                                isRequired={true} />
                        </FormGroup>

                        <FormGroup label="Required"
                            key="isRequired"
                            inline={true}
                            className="form-fill"
                            labelFor="isRequired">
                            <Switch id="isRequired"
                                checked={ form.isRequired }
                                onChange={ e => this.setState({form: StateHandler.getStateObject(e, form)}) } />
                        </FormGroup>

                        <FormGroup label="Question Type"
                            key="questionType"
                            inline={true}
                            className="form-fill required"
                            labelFor="type">
                            <HTMLSelect id="type"
                                disabled={isEditing}
                                fill={true}
                                defaultValue={ form.type }
                                onChange={ e => this.setState({form: StateHandler.getStateObject(e, form)}) }
                                options={ QUESTION_TYPE_OPTIONS } />
                        </FormGroup>

                        { questionOptionsByType }

                        {(form.type === QUESTION_TYPE_BOOLEAN.value ||
                            form.type === QUESTION_TYPE_CHOICE.value) && (
                            <FormGroup
                                label="Allow An Answer to Fail Assessment"
                                key="allowAnAnswerToFailAssessment"
                                inline={true}
                                className="form-fill"
                                labelFor="allowAnAnswerToFailAssessment"
                            >
                                <Switch
                                    id="allowAnAnswerToFailAssessment"
                                    checked={form.allowAnAnswerToFailAssessment}
                                    onChange={(e) =>
                                        this.setState({
                                            form: StateHandler.getStateObject(
                                                e,
                                                form
                                            ),
                                        })
                                    }
                                />
                            </FormGroup>
                        )}

                        {form.allowAnAnswerToFailAssessment === true && (
                            <>
                                {form.type === QUESTION_TYPE_BOOLEAN.value &&
                                <FormGroup
                                    label="Fail Answer"
                                    key="yesNoAutoFailOption"
                                    inline={true}
                                    className="form-fill required"
                                    labelFor="yesNoAutoFailOption"
                                >
                                    <HTMLSelect
                                        id="yesNoAutoFailOption"
                                        fill={true}
                                        defaultValue={form.yesNoAutoFailOption}
                                        onChange={(e) =>
                                            this.setState({
                                                form: StateHandler.getStateObject(
                                                    e,
                                                    form
                                                ),
                                            })
                                        }
                                        options={YES_NO_OPTIONS}
                                    />
                                </FormGroup>
                                }

                                {form.type === QUESTION_TYPE_CHOICE.value &&
                                form.options.length > 0 &&
                                <FormGroup
                                    label="Fail Answers"
                                    key="failAnswers"
                                    inline={true}
                                    className="form-fill required"
                                    labelFor="failAnswers"
                                >
                                    <MultiSelectRenderer id={ "failAnswers" }
                                        onRemoveItem={ this.updateSelectedFailOptions }
                                        onItemSelect={ this.updateSelectedFailOptions }
                                        fill={true}
                                        items={this.parseOptions(form.options)}
                                        valueProperty={ "label" }
                                        selectedItems={ this.state.form.selectedFailOptions }
                                    />
                                </FormGroup>
                                }

                                <FormGroup
                                    label="Fail Reason"
                                    key="failReason"
                                    inline={true}
                                    className="form-fill required"
                                    labelFor="failReason"
                                >
                                    <TextArea
                                        id="failReason"
                                        placeholder="Fail Reason"
                                        onChange={(e) =>
                                            this.setState({
                                                form: StateHandler.getStateObject(
                                                    e,
                                                    form
                                                ),
                                            })
                                        }
                                        minLength={1}
                                        maxLength={3000}
                                        required
                                        value={form.failReason}
                                    />
                                </FormGroup>
                            </>
                        )}

                        {
                            this.canAllowAdditionalComments() && (
                                <FormGroup label="Allow Additional Comments"
                                    key="allowAdditionalComments"
                                    inline={true}
                                    className="form-fill"
                                    labelFor="allowAdditionalComments">
                                    <Switch id="allowAdditionalComments"
                                        checked={ form.allowAdditionalComments }
                                        onChange={ e => this.setState({form: StateHandler.getStateObject(e, form)}) } />
                                </FormGroup>
                            )}

                        <FormGroup label="Conditional Question"
                            key="conditionalQuestion"
                            inline={true}
                            className="form-fill"
                            labelFor="conditional-question">
                            <SuggestRenderer id="conditional-question"
                                valueProperty="summary"
                                onItemSelect={ (item: Option, event: Event) => {

                                    let links = Object.assign({}, form._links, {"conditional-question": {href: Response.getLink(item, 'self')}});
                                    let newFormState = Object.assign({}, form, {_links: links});

                                    this.setState({form: newFormState});

                                    return;
                                } }
                                defaultItem={ selectedQuestion }
                                items={ questions }
                                onKeyUp={ (event: KeyboardEvent) => {
                                    if (this.state.conditionalQuestionSearchValue !== (event.target as any)?.value?.trim()) {
                                        this.conditionalQuestionSearch((event.target as any)?.value);
                                    }
                                } }
                                isRequired={false} />
                        </FormGroup>

                        {
                            Response.getLink(form, 'conditional-question') === null ?
                                null :
                                (
                                    <FormGroup label="Condition"
                                        inline={ true }
                                        className="form-fill required">
                                        <RadioGroup
                                            inline={true}
                                            className="form-fill"
                                            selectedValue={ ['boolean', 'string'].indexOf(typeof form.conditionRequirement) !== -1 ? form.conditionRequirement.toString() : null }
                                            onChange={ e => this.setState({form: StateHandler.getStateObject(e, form, 'conditionRequirement')}) }
                                        >
                                            <Radio label="Yes" value="true" />
                                            <Radio label="No" value="false" />
                                        </RadioGroup>
                                    </FormGroup>
                                )
                        }

                        <FormGroup label="State"
                            key="isActive"
                            inline={true}
                            className="form-fill"
                            labelFor="isActive">
                            <HTMLSelect id="isActive"
                                fill={true}
                                defaultValue={ form.isActive.toString() }
                                onChange={ e => this.setState({form: StateHandler.getStateObject(e, form)}) }
                                options={ ACTIVE_OPTIONS } />
                        </FormGroup>

                        <FormGroup
                            key="buttons">
                            <ButtonLink type="button"
                                intent="default"
                                className="float-left"
                                to={ this.state.id ? `/admin/questions/${this.state.id}` : '/admin/questions' }>
                                <Icon icon="ban" />
                                Cancel
                            </ButtonLink>
                            <Button type="submit"
                                intent="primary"
                                className="float-right">
                                <Icon icon="paper-plane" />
                                { this.state.id ? 'Update' : 'Create' }
                            </Button>
                        </FormGroup>
                    </form>
                </Loading>
            </div>
        );
    }

    parseOptions = (options: Option[]) =>
    {
        const optionsArray: Option[] = [];

        options.forEach((option, index) => {
            optionsArray[index] = { label: option.value, value: option.value, id: index};
        });

        return optionsArray;
    };

    load()
    {
        if (this.state.loading ||
            typeof this.state.id === 'undefined') {
            this.loadCategories();
            this.loadQuestions();

            return;
        }

        this.setState({isLoading: true});

        HTTP
            .get(`/questions/${this.state.id}`)
            .then((response: AxiosResponse|void) => {
                if (!response) { return console.error("Empty Question response.", this.state.id); } // eliminate void errors

                this.setState({
                    isLoading: false,
                    form: Object.assign({}, this.state.form, response.data),
                    response: response.data,
                });

                this.loadSelectedCategory(response.data);
                if (Response.getLink(response.data, 'conditional-question')) {
                    this.loadSelectedQuestion(response.data);
                }

                this.loadCategories();
            }).catch(() => {
            toast.error("Failed to load Question data");
        });
    }

    conditionalQuestionSearch = (query = null) => {
        globalDebounce(() => {
            this.setState({ conditionalQuestionSearchValue: query ?? '' });
            this.loadQuestions(query);
        }, 'conditionalQuestionSearch', 250);
    }

    loadSelectedCategory(questionResponse: object)
    {
        this.setState({isLoadingCategory: true});

        HTTP
            .get(Response.getLink(questionResponse, 'category'))
            .then((response) => {
                if (response) {
                    this.setState({isLoadingCategory: false, selectedCategory: response.data});

                    this.conditionalQuestionSearch();

                    return true;
                }

                toast.error('Unable to fetch Question Category');

                return false;
            });
    }

    loadSelectedQuestion(questionResponse: object)
    {
        this.setState({isLoadingQuestion: true});

        HTTP
            .get(Response.getLink(questionResponse, 'conditional-question'))
            .then((response) => {
                if (response) {
                    this.setState({isLoadingQuestion: false, selectedQuestion: response.data});

                    return true;
                }

                toast.error('Unable to fetch Conditional Question');
                this.setState({isLoadingQuestion: false});

                return false;
            });
    }

    loadCategories(query = null)
    {
        this.setState({isLoadingCategories: true});

        let params = {
            limit: 999,
            name: query || ""
        };

        HTTP
            .get('/question/categories', params)
            .then((response) => {
                response && this.setState({isLoadingCategories: false, categoryCollection: response.data});
            })
    }

    loadQuestions(query = null)
    {
        const { selectedCategory } = this.state;

        if (!selectedCategory || typeof selectedCategory.id === 'undefined') {

            return;
        }

        let params = {
            categoryId: selectedCategory.id,
            'type[]': 'boolean',
            excludeQuestionId: this.state.id || undefined,
            summary: query || undefined,
        };

        HTTP
            .get('/questions', params)
            .then((response) => {
                response && this.setState({ questionCollection: response.data });
            })
            .catch(() => toast.error("Failed to load Questions"));
    }
}

export default withRouter(QuestionAddEdit);
