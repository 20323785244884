import { H3 } from '@blueprintjs/core';
import { useEffect, useReducer } from 'react';

import { DonutChart, TooltipIcon } from 'components/elements';
import { Loading } from 'components/elements/wrappers';
import { RedFlagReducerState } from 'interface';
import { ClientService } from 'service';

const RedFlagStatusChart = (props: any) => {
    const { client, selectedCategory } = props;
    const [redFlagState, setRedFlagState]: [
        state: RedFlagReducerState,
        setState: React.ReducerWithoutAction<any>
    ] = useReducer(
        (state: RedFlagReducerState, edits: any) => {
            return { ...state, ...edits };
        },
        {
            isLoading: true,
            counts: void 0,
            colours: [
                { name: 'GOLD', label: 'Gold', colour: '#9d8110' },
                { name: 'SILVER', label: 'Silver', colour: '#6d757d' },
                { name: 'BRONZE', label: 'Bronze', colour: '#a16836' },
                {
                    name: 'ONE_RED_FLAG',
                    label: '1 Red Flag',
                    colour: '#ec6f69',
                },
                {
                    name: 'TWO_RED_FLAGS',
                    label: '2 Red Flags',
                    colour: '#ee2e24',
                },
                {
                    name: 'THREE_RED_FLAGS',
                    label: '3 Red Flags',
                    colour: '#961912',
                },
                {
                    name: 'NEWLY_INCORPORATED',
                    label: 'Newly Incorporated',
                    colour: '#ec6608',
                },
                {
                    name: 'PRE_INSOLVENT',
                    label: 'Pre Insolvent',
                    colour: '#ec6608',
                },
                {
                    name: 'PROVISIONAL_SILVER',
                    label: 'Prov. Silver',
                    colour: '#6d757d',
                },
                {
                    name: 'PROVISIONAL_BRONZE',
                    label: 'Prov. Bronze',
                    colour: '#a16836',
                },
                {
                    name: 'PROVISIONAL_ONE_RED_FLAG',
                    label: 'Prov. 1 Red Flag',
                    colour: '#ec6f69',
                },
                {
                    name: 'PROVISIONAL_TWO_RED_FLAGS',
                    label: 'Prov. 2 Red Flags',
                    colour: '#ee2e24',
                },
            ],
        }
    );
    const load = async () => {
        setRedFlagState({ isLoading: true });
        if (client === null || !client?.id) {
            setRedFlagState({ isLoading: false, counts: void 0 });
            return;
        }
        setRedFlagState(
            await ClientService.getRedFlagState(client, selectedCategory)
        );
    };

    useEffect(() => {
        load();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client, selectedCategory]);

    return (
        <section className="supply-chain-card info-card chart-parent">
            <section className="info-card-header">
                <h2 className="title">
                    <TooltipIcon
                        text={
                            <section>
                                Status of your service providers in your supply
                                chain. Red Flag Data only shows for companies
                                who have a red flag status and are not dissolved
                                (new ones might not have a status)
                                <br />
                                <ul>
                                    <li>
                                        Gold - A good history of filing
                                        compliance, with ideal levels of
                                        gearing, optimal liquidity, and
                                        favourable trends. Very low risk and
                                        open credit is recommended.
                                    </li>
                                    <li>
                                        Silver - Companies will be stable with
                                        healthy financials and a full history of
                                        filing compliance. Gearing and liquidity
                                        will be reasonable. Low risk and open
                                        credit is recommended.
                                    </li>
                                    <li>
                                        Bronze - Companies may be new with no
                                        detrimental information, or where
                                        financial data is available, it may not
                                        be ideal. Fair trade risk and open
                                        credit is recommended.
                                    </li>
                                    <li>
                                        Amber - Companies are newly incorporated
                                        or have only passed the lower threshold
                                        of the credit score algorithms, and
                                        display some financial, payment or
                                        filing characteristics that make them an
                                        elevated risk. Moderate risk and open
                                        credit is only recommended with caution.
                                    </li>
                                    <li>
                                        1 Red Flag - Companies are in the
                                        weakest 20% in their size category and
                                        display risk factors that might include
                                        a deteriorating financial position,
                                        sub-optimal gearing or liquidity, and
                                        the possible presence of recent or
                                        significant legal notices. The risk is
                                        elevated, and suppliers should seek
                                        suitable assurances or guarantees.
                                    </li>
                                    <li>
                                        2 Red Flags - All the negative
                                        characteristics of a business with 1 red
                                        flag, plus additional significant risk
                                        factors, such as a county court
                                        judgement. Very high risk and guarantees
                                        advised.
                                    </li>
                                    <li>
                                        3 Red Flags - All the negative
                                        characteristics of a business with 2 red
                                        flags, but the situation is so dire that
                                        immediate insolvency is probable. 56% of
                                        companies with three red flags will
                                        cease to trade within seven days. Very
                                        high risk and guarantees advised.
                                    </li>
                                </ul>
                            </section>
                        }
                        icon="info"
                    />
                    <span className="ps-2">Red Flag Status</span>
                </h2>
            </section>
            <section className="info-card-body flex-justify-center flex-v-center">
                <Loading isLoading={redFlagState.isLoading}>
                    {!redFlagState.counts ? (
                        <H3>
                            There are currently no Service Providers in your
                            supply chain
                        </H3>
                    ) : (
                        <DonutChart
                            data={redFlagState.counts}
                            height={300}
                            width={250}
                            legendWidth={210}
                            text=""
                            colours={redFlagState.colours ?? []}
                        />
                    )}
                </Loading>
            </section>
        </section>
    );
};

export default RedFlagStatusChart;
