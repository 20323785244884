import { PopoverPosition } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import { useEffect, useReducer } from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';

import List from 'components/elements/List';
import PartialRefundPaymentDialog from 'components/elements/payment/refund/PartialRefundPaymentDialog';
import CancelDialog from 'components/elements/supply-chain/cancel/CancelDialog';
import { NONE_SELECTED_OPTION } from 'constants/none-selected';
import { delay, toMoney } from 'helpers/helpers';
import type { FlatObject, ListCollection, SupplyChain } from 'interface';
import { ClientService, Response, SupplyChainService } from 'service';

const PurchasedSupplyChainsList = (props: RouteComponentProps<any>) => {
    const defaultFilters = {
        datagroup: 'list',
        order: '-createdAt',
        companyName: null,
        clientName: null,
    };

    const defaultState = {
        collection: {} as ListCollection<SupplyChain>,
        listFilter: defaultFilters,
        client: null,
        searchFilters: {
            companyName: {
                type: 'text',
                label: 'Service Provider Name',
                key: 'companyName',
                placeholder: '',
            },
            clientName: {
                type: 'single-select',
                label: 'Client Name',
                key: 'clientName',
                defaultValue: NONE_SELECTED_OPTION,
                options: [],
            },
        },
        showRefund: false,
    };

    const [state, setState]: [
        state: any,
        setState: React.ReducerWithoutAction<any>
    ] = useReducer(
        (state: any, edits: any) => ({ ...state, ...edits }),
        defaultState
    );

    const columns: FlatObject[] = [
        {
            name: 'Service Provider',
            property: 'registeredCompanyName',
            sortable: true,
            grow: 2,
            type: 'callback',
            callback: (row: any) => {
                const companyId = Response.getLinkAttribute(
                    row,
                    'company',
                    'id'
                );
                const link = `/service-providers/${companyId}/info`;

                return (
                    <Link to={link}>{row.registeredCompanyName || 'N/A'}</Link>
                );
            },
        },
        {
            name: 'Client',
            property: 'clientName',
            sortable: true,
            grow: 2,
        },
        {
            name: 'Purchased Date',
            property: 'acceptedAt',
            type: 'date',
            sortable: true,
            grow: 2,
        },
        {
            name: 'Cost Ex VAT',
            property: 'priceExVat',
            type: 'callback',
            callback: (row: SupplyChain) => {
                const {
                    paymentAmount,
                    paymentTax,
                    clientPricePortion,
                    prosurePricePortion,
                } = row;

                if (!row.hasOwnProperty('paymentAmount')) {
                    return 'N/A';
                }

                const tooltip = (
                    <div>
                        <div>
                            <strong>Prosure Cost: </strong>
                            {toMoney(prosurePricePortion)}
                        </div>
                        <div>
                            <strong>Client Cost: </strong>
                            {toMoney(clientPricePortion)}
                        </div>
                    </div>
                );

                return (
                    <Tooltip2
                        content={tooltip}
                        position={PopoverPosition.TOP}
                        className="ms-2 contact-tooltip"
                    >
                        {toMoney(
                            parseFloat(paymentAmount ?? '0') -
                                parseFloat(paymentTax ?? '0')
                        )}
                    </Tooltip2>
                );
            },
            grow: 1,
        },
        {
            name: 'VAT',
            property: 'vat',
            type: 'callback',
            callback: (row: SupplyChain) => {
                if (!row.hasOwnProperty('paymentTax')) {
                    return 'N/A';
                }

                return toMoney(parseFloat(row.paymentTax ?? '0'));
            },
            grow: 1,
        },
        {
            name: 'Refunded Amount Ex VAT',
            property: 'refundAmount',
            type: 'callback',
            callback: (row: SupplyChain) => {
                // if paymentAmount is N/A then refunded should also be N/A
                if (!row.hasOwnProperty('paymentAmount')) {
                    return 'N/A';
                }
                return <>{toMoney(parseFloat(row.refundAmount ?? '0'))}</>;
            },
            grow: 1,
        },
        {
            name: 'Refunded VAT',
            property: 'redfunded vat',
            type: 'callback',
            callback: (row: SupplyChain) => {
                if (!row.hasOwnProperty('paymentAmount')) {
                    return 'N/A';
                }

                return toMoney(parseFloat(row.refundTax ?? '0'));
            },
            grow: 1,
        },
        {
            name: 'Refunded Date',
            property: 'refundDate',
            type: 'date',
            grow: 1,
        },
        {
            name: 'Type',
            property: 'isRenewing',
            type: 'callback',
            callback: (row: SupplyChain) => {
                const { cancelledAt, isRenewing } = row;

                if (cancelledAt) {
                    return <span className="error">Cancelled</span>;
                }

                return isRenewing ? 'Renewal' : 'New';
            },
            grow: 1,
        },
        {
            name: '',
            property: '',
            right: true,
            type: 'callback',
            callback: (row: SupplyChain) => {
                if (!row.hasOwnProperty('paymentAmount')) {
                    return '';
                }

                if (
                    parseFloat(row.paymentAmount ?? '0') -
                        parseFloat(row.refundAmount ?? '0') -
                        parseFloat(row.refundTax ?? '0') <=
                    0
                ) {
                    return '';
                }

                return (
                    <PartialRefundPaymentDialog
                        refundableObject={row}
                        reload={() => load(state.listFilter)}
                    ></PartialRefundPaymentDialog>
                );
            },
            grow: 1,
        },

        {
            name: '',
            property: '',
            right: true,
            type: 'callback',
            callback: (row: SupplyChain) => renderCancelDialog(row),
            grow: 2,
        },
    ];

    const renderCancelDialog = (row: SupplyChain) => {
        const amountLeft =
            parseFloat(row.paymentAmount ?? '0') -
            parseFloat(row.refundAmount ?? '0') -
            parseFloat(row.refundTax ?? '0');

        if (row.cancelledAt) {
            return '';
        }

        if (
            Response.getLink(row, 'cancel') ||
            (row.hasOwnProperty('paymentAmount') && amountLeft <= 0)
        ) {
            return (
                <CancelDialog
                    refundableObject={row}
                    reload={() => load(state.listFilter)}
                ></CancelDialog>
            );
        }

        return '';
    };

    useEffect(() => {
        (async () => {
            let clientOptions = [NONE_SELECTED_OPTION];
            const copySearchFilters = { ...state.searchFilters };
            const clients = await ClientService.loadClientList({}, true);
            if (clients) {
                clients.clients.forEach((type) => {
                    clientOptions.push({
                        label: type.name as string,
                        value: type.name as string,
                    });
                });
            }
            copySearchFilters.clientName.options = clientOptions;
            setState({ searchFilters: copySearchFilters });
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const load = async (filters: any = {}) => {
        setState({ isLoading: true });

        const mergedFilters = {
            ...state.listFilter,
            ...filters,
        };

        let supplyChainData = {} as ListCollection<SupplyChain>;

        const data = await SupplyChainService.loadSupplyChainsByCompany(
            mergedFilters
        );

        if (data.hasOwnProperty('supplyChainInstances')) {
            supplyChainData = {
                ...data,
                data: data.supplyChainInstances.map((row: any) => {
                    row.registeredCompanyName = Response.getLinkAttribute(
                        row,
                        'company',
                        'registeredCompanyname'
                    );
                    row.clientName = Response.getLinkAttribute(
                        row,
                        'client',
                        'name'
                    );
                    const price = row.refundAmount || '0';
                    const vat = row.refundTax || '0';
                    row.priceExVat = parseFloat(price) - parseFloat(vat);

                    return row;
                }),
            };
        }

        setState({
            collection: supplyChainData,
            listFilter: mergedFilters,
        });
        await delay(0);
        setState({ isLoading: false });
    };

    return (
        <div className="SupplyChains">
            <div className="PurchasedSupplyChains page">
                <List
                    title="Purchased Supply Chains"
                    columns={columns}
                    load={load}
                    collection={state.collection}
                    defaultFilter={defaultFilters}
                    filter={state.listFilter}
                    hideFilters={false}
                    filters={state.searchFilters}
                    onClear={() => setState({ ...defaultFilters, page: 1 })}
                    isLoading={state.isLoading}
                />
            </div>
        </div>
    );
};

export default PurchasedSupplyChainsList;
