import { useState, useEffect } from 'react';
import { Button, FormGroup, H1, InputGroup, TextArea } from '@blueprintjs/core';
import { DateInput } from '@blueprintjs/datetime';
import { DateTime } from 'luxon';
import { isMobile } from 'react-device-detect';
import { toast } from 'react-toastify';
import moment from 'moment';

import FileSingleUpload from "components/elements/file-upload/FileSingleUpload";
import { ButtonLink, Icon, SuggestRenderer } from 'components/elements';
import { HTTP, Response, Routing, StateHandler } from 'service';
import { InputElementIcon } from 'components/elements';
import { Loading } from 'components/elements/wrappers';
import { MAX_FILE_UPLOAD_SIZE_MB } from "constants/general";
import { globalDebounce } from "helpers/helpers";

const CertificationMembershipAddEdit = props => {
    const { history, match, companyResponse, certificationMembershipDetails } = props;

    const [ isLoading, setIsLoading ] = useState(true);

    const [ certificateMemberships, setCertificateMemberships ] = useState([]);

    const [ certificateMembershipSearchValue, setCertificateMembershipSearchValue ] = useState('');

    const [ form, setForm ] = useState({
        certificateRegistrationNumber: '',
        commencementDate: null,
        expiresAt: null,
        comments: '',
        _links: {
            'certification-membership': null,
            'certificate-file': null,
        }
    });

    const [ selectedCerificateMembership, setSelectedCerificateMembership ] = useState({});

    useEffect(() => {
        if (certificationMembershipDetails) {
            // if edit page, pre-load data and format dates correctly
            let loadedcertificationMembershipDetails = {
                ...certificationMembershipDetails,
                commencementDate: new Date(certificationMembershipDetails.commencementDate),
                expiresAt: new Date(certificationMembershipDetails.expiresAt),
            };

            setSelectedCerificateMembership(loadedcertificationMembershipDetails._links['certification-membership']);

            setForm({
                ...form,
                ...loadedcertificationMembershipDetails
            });
        }

        loadCertificateMemberships();
        setIsLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fileLabel = (
        <>
            Certificate Upload
            {InputElementIcon(`Max file size ${MAX_FILE_UPLOAD_SIZE_MB}MB`, 'info')}
        </>
    )

    const loadCertificateMemberships = (query = null) => {
        const filterParams = {};

        if (query) {
            filterParams.name = query;
        }

        HTTP
            .get('/certification-memberships', filterParams)
            .then((response) => {
                setCertificateMemberships(response.data.certificationMemberships);
            })
    }

    const certificateMembershipSearch = (query = null) => {
        globalDebounce(() => {
            setCertificateMembershipSearchValue(query ?? '');
            loadCertificateMemberships(query);
        }, 'certificateMembershiptSearch', 250);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (certificationMembershipDetails) {
            return HTTP
                .put(Response.getLink(certificationMembershipDetails, 'edit'), form)
                .then((response) => {
                    if (response) {
                        toast.success('Certificate/Membership updated');
                        Routing.navigateUpOneLevel(history, match);

                        if (props.reloadData) {
                            props.reloadData(form);
                        }

                        return true;
                    }

                    toast.error('Unable to update Certificate/Membership');

                    return false;
                });
        }


        HTTP
            .post(Response.getLink(companyResponse, 'add-certification-membership'), form)
            .then((response) => {
                if (response) {
                    toast.success('Certificate/Membership added.');
                    Routing.navigateUpOneLevel(history, match);

                    return true;
                }

                toast.error('Unable to add Certificate/Membership.');

                return false;
            });
    }

    const updateFile = (fileJson) => {
        let updatedLinks = {
            ...form._links,
            ...fileJson._links
        }
        setForm({
            ...form,
            _links: updatedLinks
        });
    }

    let maxDate = DateTime.local().plus({years: 5}).toJSDate();

    return (
        <div className="certificateMembershipAdd">
            <H1>
                {certificationMembershipDetails
                    ? 'Edit Certificate/Membership'
                    : 'Add Certificate/Membership'}
            </H1>

            <Loading isLoading={isLoading}>
                <form className="AddEdit" onSubmit={(e) => handleSubmit(e)}>
                    <FormGroup
                        label="Certificate/Membership"
                        key="certificateMembership"
                        inline={true}
                        className="form-fill"
                        labelFor="certificateMembership"
                    >
                        <SuggestRenderer
                            id="certificateMembership"
                            valueProperty="name"
                            onItemSelect={(item) => {
                                let updatedLinks = {
                                    ...form._links,
                                    'certification-membership': {
                                        href: Response.getLink(item, 'self'),
                                    },
                                };
                                setSelectedCerificateMembership(item);
                                setForm({
                                    ...form,
                                    _links: updatedLinks,
                                });

                                return;
                            }}
                            defaultItem={selectedCerificateMembership}
                            items={certificateMemberships}
                            onKeyUp={(event) => {
                                if (
                                    certificateMembershipSearchValue !==
                                    event.target.value.trim()
                                ) {
                                    certificateMembershipSearch(
                                        event.target.value
                                    );
                                }
                            }}
                            isRequired={true}
                        />
                    </FormGroup>

                    <FormGroup
                        label="Certificate Registration Number"
                        inline={true}
                        className="form-fill required"
                        labelFor="certificateRegistrationNumber"
                    >
                        <InputGroup
                            id="certificateRegistrationNumber"
                            placeholder="Certificate Registration Number"
                            minLength={2}
                            maxLength={255}
                            value={form.certificateRegistrationNumber}
                            onChange={(e) =>
                                setForm(StateHandler.getStateObject(e, form))
                            }
                            required
                        />
                    </FormGroup>

                    <FormGroup
                        label="Commencement Date:"
                        inline={!isMobile}
                        className="form-fill choice required"
                    >
                        <DateInput
                            id="commencementDate"
                            name="commencementDate"
                            maxDate={maxDate}
                            parseDate={(str) => moment(str, 'DD/MM/YYYY').toDate()}
                            formatDate={(date) => DateTime.fromJSDate(date).toLocaleString()}
                            popoverProps={{ position: 'bottom', fill: true }}
                            inputProps={{
                                fill: true,
                                id: 'commencementDate',
                                rightElement: InputElementIcon(
                                    'Select a date...',
                                    'calendar-alt'
                                ),
                            }}
                            onChange={(newDate) => {
                                newDate = new Date(+newDate + 1000);
                                setForm({
                                    ...form,
                                    commencementDate: newDate,
                                });
                            }}
                            required={true}
                            value={form.commencementDate}
                        />
                    </FormGroup>

                    <FormGroup
                        label="Expiry Date:"
                        inline={!isMobile}
                        className="form-fill choice required"
                    >
                        <DateInput
                            id="expiresAt"
                            name="expiresAt"
                            maxDate={maxDate}
                            parseDate={(str) => moment(str, 'DD/MM/YYYY').toDate()}
                            formatDate={(date) => DateTime.fromJSDate(date).toLocaleString()}
                            popoverProps={{ position: 'bottom', fill: true }}
                            inputProps={{
                                fill: true,
                                id: 'expiresAt',
                                rightElement: InputElementIcon(
                                    'Select a date...',
                                    'calendar-alt'
                                ),
                            }}
                            onChange={(newDate) => {
                                newDate = new Date(+newDate + 1000);
                                setForm({
                                    ...form,
                                    expiresAt: newDate,
                                });
                            }}
                            required
                            value={form.expiresAt}
                        />
                    </FormGroup>

                    <FormGroup
                        label="Comments"
                        inline={true}
                        className="form-fill"
                        labelFor="name"
                    >
                        <TextArea
                            id="comments"
                            placeholder="Comments (Optional)"
                            onChange={(e) =>
                                setForm(StateHandler.getStateObject(e, form))
                            }
                            minLength={1}
                            value={form.comments}
                        />
                    </FormGroup>

                    <FileSingleUpload
                        form={form}
                        fileId="certificate-file"
                        isMobile={isMobile}
                        isRequired={true}
                        fileLabel={fileLabel}
                        onUpdate={updateFile}
                    ></FileSingleUpload>

                    <FormGroup>
                        <ButtonLink
                            type="button"
                            intent="default"
                            className="float-left"
                            to={Routing.getRouteUpOneLevel(history, match)}
                        >
                            <Icon icon="ban" />
                            Cancel
                        </ButtonLink>
                        <Button
                            type="submit"
                            intent="primary"
                            className="float-right"
                        >
                            <Icon icon="paper-plane" />
                            Submit
                        </Button>
                    </FormGroup>
                </form>
            </Loading>
        </div>
    );
}

export default CertificationMembershipAddEdit;
