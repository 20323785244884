import { Tab, Tabs } from '@blueprintjs/core';
import { Link, Redirect, Switch, useLocation } from 'react-router-dom';

import { Icon, ProtectedRoute } from 'components/elements';
import { Routing } from 'service';
import ElogsCAFMAnalytics from './ElogsCAFMAnalytics';
import ElogsCAFMStats from './ElogsCAFMStats';

const ElogsCAFM = (props: any) => {
    const { companyResponse, match } = props;

    const location = useLocation();
    const tabId = Routing.getSelectedTabId(location.pathname, match.url);

    return (
        <div className="page ElogsCAFM">
            <div className="no-styling page-wrapper">
                <Tabs renderActiveTabPanelOnly={true} selectedTabId={tabId}>
                    <Tab id={'/reporting'}>
                        <Link to={`${match.url}/reporting`}>
                            <Icon icon="chart-pie" />
                            Reporting
                        </Link>
                    </Tab>
                    <Tab id={'/list'}>
                        <Link to={`${match.url}/list?type=reactive`}>
                            <Icon icon="info" />
                            Data
                        </Link>
                    </Tab>
                </Tabs>

                <div className="cafm-stats-tab-content">
                    <Switch>
                        <ProtectedRoute
                            path={`${match.url}/list`}
                            component={ElogsCAFMStats}
                            companyResponse={companyResponse}
                        />
                        <ProtectedRoute
                            path={`${match.url}/reporting`}
                            component={ElogsCAFMAnalytics}
                            companyResponse={companyResponse}
                        />
                        <Redirect
                            from={`${match.url}`}
                            to={`${match.url}/reporting`}
                        />
                    </Switch>
                </div>
            </div>
        </div>
    );
};

export default ElogsCAFM;
