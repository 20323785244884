import { useState } from 'react';

import List from 'components/elements/List';
import { delay } from 'helpers/helpers';
import { ListCollection, TaskData, TaskListFilter } from 'interface';
import { isEmpty } from 'lodash';
import { getRouteFromType, loadTaskList } from 'service/Company/TaskService';

interface Props {
    title: string;
    listType: string;
}

const TaskList = (props: Props) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [collection, setCollection] = useState<ListCollection<TaskData>>(
        {} as ListCollection<TaskData>
    );

    const { title } = props;

    const [listFilter, setListFilter] = useState<TaskListFilter>({
        page: 1,
        limit: 8,
        isActive: true,
        order: 'clientName',
    });

    const columns = [
        {
            name: 'Client',
            sortable: true,
            type: 'callback',
            callback: (row: TaskData) => {
                if (isEmpty(row.clientName)) {
                    return 'N/A';
                } else {
                    return row.clientName;
                }
            },
            grow: 4,
        },
        {
            name: 'Task To Resolve',
            property: 'message',
            sortable: false,
            grow: 8,
        },
        {
            name: '',
            property: '',
            type: 'nav-button',
            grow: 1,
            route: getRouteFromType,
            buttonIntent: 'primary',
            buttonText: 'Resolve',
            right: true,
        },
    ];

    const load = async (filter: TaskListFilter) => {
        setIsLoading(true);
        const copyFilter = { ...listFilter, ...filter };
        const tasks = await loadTaskList(copyFilter);

        setCollection(tasks);
        setListFilter(copyFilter);
        await delay(0);
        setIsLoading(false);
    };

    return (
        <div className="TaskList">
            <List
                title={title}
                columns={columns}
                load={(filter) => load(filter as TaskListFilter)}
                collection={collection}
                filter={listFilter}
                isLoading={isLoading}
                hideFilters={true}
            />
        </div>
    );
};

export default TaskList;
