import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link, Redirect, Switch } from 'react-router-dom';
import { Tab, Tabs } from '@blueprintjs/core';

import { updatingBreadcrumbResolves } from 'action/breadcrumb';
import { Icon, ProtectedRoute } from 'components/elements';
import { Loading } from 'components/elements/wrappers';
import { Routing } from 'service';
import ServiceInfo from 'components/admin/services/ServiceInfo';
import ServiceAddEdit from 'components/admin/services/ServiceAddEdit';
import { Service } from 'interface/Service';
import ServiceService from 'service/Service/ServiceService';

const ServiceDetails = (props: RouteComponentProps<any>) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [service, setService] = useState<Service>(
        ServiceService.createService()
    );
    const { match, location } = props;
    const tabId = Routing.getSelectedTabId(location.pathname, match.url);

    const getService = async () => {
        const service: Service = await ServiceService.loadService(
            props.match.params.id
        );
        setService(service);
        return service;
    };

    const reload = async () => {
        await getService();
    };

    const load = () => {
        (async () => {
            if (isLoading) {
                const service = await getService();
                dispatch(updatingBreadcrumbResolves({ service: service }));
                setIsLoading(false);
            }
        })();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(load, []);

    return (
        <div className="ServiceDetails">
            <Loading isLoading={isLoading}>
                <h1 className="clearfix">{service.name}</h1>

                <Tabs renderActiveTabPanelOnly={true} selectedTabId={tabId}>
                    <Tab id={'/info'}>
                        <Link to={`${match.url}/info`}>
                            <Icon icon="info" />
                            Info
                        </Link>
                    </Tab>
                </Tabs>
                <div className="TabContent">
                    <Switch>
                        <ProtectedRoute
                            path={`${match.url}/info`}
                            component={ServiceInfo}
                            service={service}
                            exact
                        />
                        <ProtectedRoute
                            path={`${match.url}/info/edit`}
                            component={ServiceAddEdit}
                            service={service}
                            reload={reload}
                        />
                        <Redirect to={`${match.url}/info`} />
                    </Switch>
                </div>
            </Loading>
        </div>
    );
};

export default ServiceDetails;
