import { useEffect, useState } from 'react';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';

import { HTTP, Response } from "service";
import { updatingBreadcrumbResolves, clearAssessmentDiscount, clearAddress } from "action";
import { Payment } from "components/elements";

const AssessmentTypePayment = (props) => {
    const dispatch = useDispatch();

    const { history, match } = props;
    const [assessment, setAssessment] = useState({
        id: undefined,
        name: undefined,
        description: undefined,
        _links: {},
    });
    const address = useSelector(state => state.address);
    const discount = useSelector(state => state.assessments?.discount);

    const isAddressEmpty = () => {
        return Object.values(address).every( field => field === null || field === '');
    }

    const confirmationPage = (response) => {
        const requestStickersLink = Response.getLink(response.assessmentTypeInstance, 'request-stickers');

        if (requestStickersLink && !isAddressEmpty()) {
            HTTP
                .put(requestStickersLink, address).then(() => dispatch(clearAddress()))
        }
        dispatch(clearAssessmentDiscount());

        history.push('/assessments/thank-you');
    };

    useEffect(() =>
    {
        dispatch(updatingBreadcrumbResolves({company: props.companyResponse, assessment: undefined }));

        HTTP
            .get(`/assessment-types/${match.params.id}`, {datagroup: 'details'})
            .then((response) => {
                if (response) {
                    setAssessment(response.data);
                    dispatch(updatingBreadcrumbResolves({ assessment: { payment: 'Purchase ' + response.data.name + ' - Payment' }}));

                    return true;
                }

                toast.error('Unable to fetch Assessment Type details');

                return false;
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getTotal = () => {
        let total = assessment.pricing.standardAssessmentFeeIncludingTax;
        if (!discount) {
            return total;
        }
        total -= total * parseFloat(discount.discount) / 100;

        return isNaN(total) ? 0 : total;
    }

    return (
        <>
            {
                assessment.id && (
                    <Payment
                        total={getTotal()}
                        instance={assessment}
                        discount={discount}
                        logo={"file"}
                        title={`Purchase ${assessment.name}`}
                        confirmationPage={confirmationPage}
                        purchaseUrl={Response.getLink(assessment, 'purchase')}
                        confirmUrl={Response.getLink(assessment, 'confirm-payment')}
                    />
                )
            }
        </>
    );
};

export default AssessmentTypePayment;
