import { SET_ADDRESS, CLEAR_ADDRESS } from 'action/address';

const defaultState = {
    title: '',
    firstName: '',
    surname: '',
    registeredCompanyName: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    town: '',
    county: '',
    postCode: '',
    country: '',
};

export default function address(state = defaultState, action) {
    const baseState = { ...defaultState, ...state };

    switch (action.type) {
        case SET_ADDRESS:
            return { ...baseState, ...action.address };
        case CLEAR_ADDRESS:
            return { ...defaultState };
        default:
            return { ...baseState };
    }
}
