const routes = {
    company: {
        href: '/company',
        text: '{company.registeredCompanyName}',
    },
    company_info_edit: {
        href: '/company/edit',
        text: 'Edit',
        icon: 'pencil',
        skipNavigation: true,
    },
    supply_chain: {
        href: '/supply-chain',
        text: 'Supply Chain',
    },
    pending_invites: {
        href: '/supply-chains/pending',
        text: 'Pending Invites'
    },
    supply_chain_analytics: {
        href: '/supply-chains/analytics',
        text: 'Analytics'
    },
    supply_chain_invitation: {
        href: '/supply-chains/pending/:supplyChainId',
        text: '{client.name}'
    },
    supply_chain_invitation_payment: {
        href: '/supply-chains/pending/:supplyChainId/payment',
        text: 'Payment'
    },
    company_services: {
        href: '/company/services?order=treePath',
        text: 'Services',
        icon: 'conveyor-belt-alt',
    },
    company_services_edit: {
        href: '/company/services/edit',
        text: 'Edit',
        icon: 'pencil',
        skipNavigation: true,
    },
    company_users: {
        href: '/company/users',
        text: 'Users',
        icon: 'users',
    },
    company_clients: {
        href: '/company/clients',
        text: 'Clients',
        icon: 'user-tie',
    },
    company_clients_edit: {
        href: '/company/clients/edit',
        text: 'Edit',
        icon: 'pencil',
    },
    company_regions: {
        href: '/company/regions?order=treePath',
        text: 'Areas Covered',
        icon: 'map-marker',
    },
    company_regions_edit: {
        href: '/company/regions/edit',
        text: 'Edit',
        icon: 'pencil',
        skipNavigation: true,
    },
    company_insurances: {
        href: '/company/insurances?currentOrExpired=current&order=-expiry',
        text: 'Insurances',
        icon: 'house-damage',
    },
    company_insurance_add: {
        href: '/company/insurances/add',
        text: 'Add Insurance',
        skipNavigation: true,
    },
    company_insurance_details: {
        href: '/company/insurances/:id',
        text: 'View Insurance',
        icon: 'house-damage',
    },
    company_insurance_details_edit: {
        href: '/company/insurances/:id/edit',
        text: 'Edit',
        icon: 'house-damage',
    },
    company_certificates: {
        href: '/company/certificates',
        text: 'Certificates',
        icon: 'file-certificate',
    },
    company_certificates_edit: {
        href: '/company/certificates/edit',
        text: 'Edit',
        icon: 'pencil',
    },

    company_submit: {
        href: '/company/submit',
        text: 'Submit Application',
        icon: 'paper-plane',
    },
};

export default routes;
