import { Component } from 'react';
import PropTypes from 'prop-types';
import StateHandler from 'service/StateHandler';
import { FormGroup, InputGroup } from '@blueprintjs/core';

class Text extends Component {
    static propTypes = {
        filterKey: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        name: PropTypes.string,
        placeholder: PropTypes.string,
    };

    static defaultProps = {};

    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value ?? '',
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.value !== this.props.value) {
            this.setState({ value: this.props.value });
        }
    }

    render() {
        return (
            <div className="FilterText">
                <FormGroup
                    label={this.props.label}
                    inline={true}
                    className="form-fill"
                    labelFor={this.props.filterKey}
                >
                    <InputGroup
                        id={this.props.filterKey}
                        placeholder={this.props.placeholder}
                        name={this.props.name}
                        value={this.state.value}
                        onChange={(event) => {
                            this.setState(
                                StateHandler.getStateObject(event, {}, 'value'),
                                () => {
                                    this.handleChange(event);
                                }
                            );
                        }}
                        onBlur={(event) => {
                            this.handleChange(event);
                        }}
                    />
                </FormGroup>
            </div>
        );
    }

    handleChange(event) {
        this.props.onChange(
            {
                value: this.state.value,
                data: this.state.value,
            },
            this.props.filterKey
        );
    }
}

export default Text;
