import { useEffect, useState } from 'react';
import { Switch } from 'react-router-dom';

import InsuranceAddEdit from 'components/company/insurances/InsuranceAddEdit';
import InsuranceInfo from 'components/company/insurances/InsuranceInfo';
import { HTTP, Response } from 'service';
import { Loading } from 'components/elements/wrappers';
import { ProtectedRoute } from 'components/elements';
import "./styles.scss";

const InsuranceDetails = props => {
    const { companyResponse, match } = props;
    const insuranceId = match.params.id;

    const [ insuranceDetails, setInsuranceDetails ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);

    useEffect(() => {
        loadInsuranceData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadInsuranceData = async () => {
        setIsLoading(true);

        const insurancesLink = Response.getLink(companyResponse, 'insurances');

        await HTTP
            .get(`${ insurancesLink }/${ insuranceId }`, { datagroup: 'details' })
            .then(response => {
                setInsuranceDetails(response.data);
            });

        setIsLoading(false);
    }

    return <>
        <div className="insuranceDetails">
            <Loading isLoading={ isLoading || insuranceDetails === false }>
                <Switch>
                    <ProtectedRoute
                        path={`${match.url}`}
                        component={ InsuranceInfo }
                        insuranceDetails={ insuranceDetails }
                        exact
                    />
                    <ProtectedRoute
                        path={`${match.url}/edit`}
                        component={ InsuranceAddEdit }
                        reloadData= { loadInsuranceData }
                        companyResponse={ companyResponse }
                        insuranceDetails={ insuranceDetails }
                        exact
                    />
                </Switch>
            </Loading>
        </div>
    </>;
}

export default InsuranceDetails;
