import { Component } from 'react';
import { Redirect, Switch } from 'react-router-dom';

import AssessmentTypeAddEdit from 'components/admin/assessment-types/AssessmentTypeAddEdit';
import AssessmentTypeDetails from 'components/admin/assessment-types/AssessmentTypeDetails';
import AssessmentTypeList from 'components/admin/assessment-types/AssessmentTypeList';
import ProtectedRoute from 'components/elements/ProtectedRoute';

class AssessmentTypes extends Component
{
    render()
    {
        return (
            <div className="AssessmentTypes">
                <Switch>
                    <ProtectedRoute path="/admin/assessment-types" component={ AssessmentTypeList } exact />
                    <ProtectedRoute path="/admin/assessment-types/add" component={ AssessmentTypeAddEdit } exact />
                    <ProtectedRoute path="/admin/assessment-types/:assessmentTypeId" component={ AssessmentTypeDetails } />
                    <Redirect to="/" />
                </Switch>
            </div>
        );
    }
}

export default AssessmentTypes;
