import { CLEAR_TASKS_COUNT, SET_TASKS_COUNT } from 'action/tasks';

const defaultState = {
    tasksCount: 0,
};

export default function tasks(state, action) {
    const baseState = Object.assign({}, defaultState, state);

    switch (action.type) {
        case SET_TASKS_COUNT:
            return {
                ...baseState,
                tasksCount: action.tasksCount
            };
        case CLEAR_TASKS_COUNT:
        default:
            return baseState;
    }
}

