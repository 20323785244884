import { Button, Card, FormGroup, InputGroup } from '@blueprintjs/core';
import { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { clearMessage, login } from 'action/auth';
import { setRootClient } from 'action/root';
import {
    ButtonLink,
    Icon,
    InputElementIcon,
    MarloweFooter,
} from 'components/elements';

const mapStateToProps = (state) => {
    return {
        message: state.auth.message,
        messageType: state.auth.messageType,
    };
};

const mapDispatchToProps = {
    login: login,
    clearMessage: clearMessage,
    setRootClient: setRootClient,
};

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showPassword: false,
        };

        this.message = void 0;

        this.inputs = {
            email: createRef(),
            password: createRef(),
        };

        this.loginForm = this.loginForm.bind(this);
    }

    handleSubmit(event) {
        event.stopPropagation();
        event.preventDefault();

        const credentials = {
            email: this.inputs.email.current.value,
            password: this.inputs.password.current.value,
        };

        this.props.login(credentials);
        this.props.setRootClient(null);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.message) {
            this.message = this.props.message + '';
            setTimeout(() => {
                this.props.clearMessage();
            }, 0);
        }
    }

    render() {
        return (
            <div className="login-page marlowe-theme box-page">
                <div className="login-box box">
                    <div className="box-body">
                        <img
                            className="box-logo"
                            src="/assets/images/logo-new.png"
                            alt="Prosure360 logo"
                        />
                        <div className="box-body-inner login-form">{this.loginForm()}</div>
                    </div>
                    <div className="box-footer">
                        <a href="tel:0203 404 6750">
                            <Icon icon="phone-square" />
                            <span>0203 404 6750</span>
                        </a>
                        <a href="mailto:support@prosure360.co.uk">
                            <Icon icon="envelope" />
                            <span>Contact us</span>
                        </a>
                    </div>
                </div>
                <MarloweFooter />
            </div>
        );
    }

    loginForm() {
        let { showPassword } = this.state;

        return (
            <form onSubmit={(e) => this.handleSubmit(e)}>
                {!this.message ? null : (
                    <Card className={this.props.messageType || 'error'}>
                        <p>{this.message}</p>
                    </Card>
                )}
                <FormGroup className="form-field">
                    <InputGroup
                        id="email"
                        type="email"
                        className="bp3-fill"
                        placeholder="Email Address"
                        autoComplete="username"
                        inputRef={this.inputs.email}
                    />
                </FormGroup>
                <FormGroup className="form-field">
                    <InputGroup
                        id="password"
                        type={showPassword ? 'text' : 'password'}
                        className="bp3-fill"
                        placeholder="Password"
                        autoComplete="current-password"
                        rightElement={InputElementIcon(
                            ...(showPassword
                                ? ['Hide Password', 'eye']
                                : ['Show Password', 'eye-slash']),
                            () => {
                                this.setState({
                                    showPassword: !showPassword,
                                });
                            }
                        )}
                        inputRef={this.inputs.password}
                    />
                </FormGroup>
                <FormGroup className="form-buttons">
                    <Link to="/forgotten-password">Reset password</Link>

                    <Button type="submit" intent="primary" large={true}>
                        Log in
                    </Button>
                </FormGroup>
                <div className="create-account">
                    <span>Are you a new Service Provider?</span>
                    <ButtonLink
                        type="button"
                        intent="success"
                        large={true}
                        text="Create Free Account"
                        to="/register"
                    />
                </div>
            </form>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
