import { AxiosResponse } from 'axios';
import {FormEvent} from "react";
import {toast} from "react-toastify";

import { HTTP, Response, Routing } from 'service';
import {DiscountCodeStatus, DiscountData, DiscountListData, DiscountStatus, DiscountFilter} from 'interface/Discount';
import { AssessmentTypeData, emptyListData } from 'interface';

class DiscountService {
    static loadList : (filter: DiscountFilter|null) => Promise<DiscountListData> = async (filter: DiscountFilter|null) => {
        const response : AxiosResponse|void = await HTTP.action("get", '/discounts', filter ?? {}, {}, "Unable to load discounts list");
        return this.returnDiscountListOrEmpty(response);
    }

    // i realised too late i didn't really need a list of Discounts for an assessment,
    // this was all to be checked backend, but keeping code in case it can be used
    // in the future, maybe
    static loadListForAssessment : (assessment: AssessmentTypeData) => Promise<DiscountListData> = async (assessment: AssessmentTypeData) => {
        const response : AxiosResponse|void = await HTTP.action("get", Response.getLink(assessment, 'discounts'), {}, {}, "Unable to load discounts list");
        return this.returnDiscountListOrEmpty(response);
    }

    static returnDiscountListOrEmpty : (response: AxiosResponse|void) => Promise<DiscountListData> = async (response) => {
        if (response && response.data) {
            return response.data as DiscountListData;
        } else {
            return { ...emptyListData, discounts: [] } as DiscountListData;
        }
    }

    static getDiscountForAssessment : (code: string, assessmentLink: string) => Promise<DiscountData|void> = async (code, assessmentLink) => {
        const response = await HTTP.action("get", `/discounts/validate/${code}`, {
            _links: {
                assessment : {
                    href: assessmentLink,
                    method: 'GET'
                }
            }
        });

        return response && response.data as DiscountData;
    }

    static loadDiscount : (id: string) => Promise<DiscountData> = async (id: string) => {
        const response : AxiosResponse|void = await HTTP.action("get", "/discounts/" + id, {}, {}, "Unable to load discount");
        if (response) {
            return response.data as DiscountData;
        } else {
            return this.createDiscount() as DiscountData;
        }
    }

    static handleSubmit: (e: FormEvent<HTMLFormElement>, form: DiscountData, history: any, match: any, reload: any) => void = async (e: FormEvent<HTMLFormElement>, form: DiscountData, history: any, match: any, reload: any) => {
        e.preventDefault();
        e.stopPropagation();

        let response : AxiosResponse|void;
        let editLink: string|null = Response.getLink(form, 'edit');

        if (editLink) {
            response = await HTTP.action("put", editLink, form, {}, "Unable to update discount");
        } else {
            response = await HTTP.action("post", '/discounts/new', form, {}, "Unable to create discount");
        }

        if (response) {
            if (editLink) {
                toast.success('Discount updated');
                if (typeof reload === 'function') {
                    reload();
                }
            } else {
                toast.success('Discount created');
            }
            Routing.navigateUpOneLevel(history, match);
        }
    }

    static getCodeStatus : (status: DiscountCodeStatus) => string = (status: DiscountCodeStatus) => {
        switch (status) {
            case DiscountCodeStatus.EXPIRED: return "expired";
            case DiscountCodeStatus.LIVE:    return "live";
            case DiscountCodeStatus.FUTURE:  return "future";
        }
    }

    static getStatus : (status: DiscountStatus) => string = (status: DiscountStatus) => {
        switch (status) {
            case DiscountStatus.INACTIVE: return "Inactive";
            case DiscountStatus.ACTIVE:    return "Active";
        }
    }

    static createDiscount(): DiscountData {
        return {
            assessments: [],
            code: "",
            codeStatus: 0,
            discount: 0,
            expiryDate: new Date(),
            id: "",
            serviceProviders: [],
            startDate: new Date(),
            timesUsed: 0,
            usageLimit: 0,
            limitedUsage: false,
            isLimitedToSpecificServiceProviders: false,
            status: 0,
            usageRevenue: 0,
            usageRevenueTax: 0,
            usageRevenueIncludingTax: 0,
        };
    }
}

export default DiscountService;
