import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link, Redirect, Switch } from 'react-router-dom';
import { H1, Tab, Tabs } from '@blueprintjs/core';

import { UserData } from 'interface/User';
import { ProtectedRoute } from 'components/elements';
import { Routing } from 'service';
import { getRootResponse } from 'action';
import ProfileDetails from 'components/user/ProfileDetails';
import ProfileEdit from 'components/user/ProfileEdit';
import ChangePassword from 'components/user/ChangePassword';

interface ProfileInfoProps extends RouteComponentProps<any> {
    user: UserData;
}

const ProfileInfo = (props: ProfileInfoProps) => {
    const dispatch = useDispatch();
    const { user, match, location } = props;

    let tabId = Routing.getSelectedTabId(location.pathname, match.url);

    const mergeUserResponse = () => {
        dispatch(getRootResponse());
    };

    return (
        <div className="page ProfileDetails">
            <div className="no-styling page-wrapper">
                <div className="page-header">
                    <H1 className={'page-title'}>My Profile</H1>
                </div>
                <Tabs renderActiveTabPanelOnly={true} selectedTabId={tabId}>
                    <Tab id="/details">
                        <Link to={`${match.url}/details`}>Details</Link>
                    </Tab>
                </Tabs>
                <div className="TabContent">
                    <Switch>
                        <ProtectedRoute
                            path={`${match.url}/details`}
                            component={ProfileDetails}
                            user={user}
                            exact
                        />
                        <ProtectedRoute
                            path={`${match.url}/details/edit`}
                            component={ProfileEdit}
                            user={user}
                            mergeUserResponse={mergeUserResponse}
                            exact
                        />
                        <ProtectedRoute
                            path={`${match.url}/change-password`}
                            component={ChangePassword}
                            user={user}
                            mergeUserResponse={mergeUserResponse}
                        />

                        <Redirect to={`${match.url}/details`} />
                    </Switch>
                </div>
            </div>
        </div>
    );
};

export default ProfileInfo;
