import {
    Button,
    Classes,
    Dialog,
    FormGroup,
    TextArea,
} from '@blueprintjs/core';
import {
    ComponentProps,
    FormEvent,
    SyntheticEvent,
    useEffect,
    useReducer,
} from 'react';
import { toast } from 'react-toastify';

import { ClientData } from 'interface';
import { ClientService, HTTP, Response } from 'service';
import './index.scss';

interface ArchiveClientProps extends ComponentProps<any> {
    client?: ClientData;
    isOpen: boolean;
    onClose: Function;
}

const ArchiveClient = (props: ArchiveClientProps) => {
    const { client, isOpen, onClose } = props;
    const [state, setState] = useReducer(
        (state: any, edits: any) => {
            return { ...state, ...edits };
        },
        {
            clientDetails: {} as ClientData,
            reason: '',
            dialogOpen: !!isOpen,
        }
    );
    useEffect(() => {
        setState({ dialogOpen: isOpen, reason: '' });
    }, [isOpen]);
    const dialogClose = (event: SyntheticEvent) => {
        setState({
            dialogOpen: false,
            statusMessage: '',
            reason: '',
        });
        typeof onClose === 'function' && onClose(event);
    };

    const dialogConfirm = async () => {
        if (!client || !Response.getLink(state.clientDetails, 'edit')) {
            toast.error('Not possible to update Client.');
            return;
        }
        //use invitee and submit here
        setState({ isLoading: true });
        const response = await HTTP.action(
            'put',
            Response.getLink(state.clientDetails, 'edit'),
            {
                ...state.clientDetails,
                reason: state.reason,
                isActive: !client.isActive,
            },
            {},
            'Could not update Client'
        );
        setState({ isLoading: false });
        response && toast.success('Status has been updated successfully.');
        dialogClose({} as SyntheticEvent);
    };

    useEffect(() => {
        if (!client) {
            return;
        }
        (async () => {
            const clientDetails = await ClientService.loadClient(client.id);
            setState({ clientDetails });
        })();
    }, [client]);

    const clientArchiveText = () => {
        let text = `Are you sure you wish to ${
            client?.isActive ? 'archive' : 'unarchive'
        } ${client?.name}?`;
        if (client?.isActive && client?.subscriptionLevel) {
            text += ` This client currently has a ${client?.subscriptionLevel} subscription level.`;
        }

        return text;
    };

    return (
        <Dialog
            onClose={dialogClose}
            title={`${client?.isActive ? 'Archive' : 'Unarchive'} Client`}
            autoFocus={true}
            className="form-content"
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            usePortal={true}
            isOpen={state.dialogOpen}
        >
            <form onSubmit={dialogConfirm}>
                <div className={Classes.DIALOG_BODY}>
                    <div className="mb-2">{clientArchiveText()}</div>
                    <FormGroup
                        label="Reason"
                        inline={true}
                        className="form-fill required"
                        labelFor="updateText"
                    >
                        <TextArea
                            id="updateText"
                            placeholder="Enter a reason..."
                            className="full-width no-resize"
                            required
                            rows={4}
                            value={state.reason}
                            onChange={(e: FormEvent<HTMLTextAreaElement>) => {
                                const { value } =
                                    e.target as HTMLTextAreaElement;
                                setState({ reason: value });
                            }}
                        />
                    </FormGroup>

                    <FormGroup className={Classes.DIALOG_FOOTER}>
                        <Button
                            type="button"
                            className="float-left"
                            onClick={dialogClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            intent="success"
                            className="float-right"
                        >
                            Continue
                        </Button>
                    </FormGroup>
                </div>
            </form>
        </Dialog>
    );
};
export default ArchiveClient;
