import React, { Component } from 'react';
import { toast } from 'react-toastify';
import queryString from 'query-string';
import { H1 } from '@blueprintjs/core';
import { ButtonLink, Icon, ItemCount, LoadingIndicator, Paginator, Reload, Table } from 'components/elements';
import { Loading, NoResultsFound } from 'components/elements/wrappers';
import { HTTP, Response } from 'service';

class RegionList extends Component
{
    columns = [
        { name: 'Name', property: 'name', sortable: true, grow: 6, },
        { name: 'Parent', property: '_links.parent.name', sortable: false, grow: 6 },
        { name: '', property: '', type: 'nav-button', grow: 1, route: this.getRoute, buttonIntent: 'primary', buttonText: 'View', right: true, },
    ];
    
    defaultFilters = {
        order: 'name',
    };

    constructor(props)
    {
        super(props);

        this.state = {
            collectionResponse: {},
            filter: queryString.parse(props.location.search),
            isLoading: false,
        };

        this.load = this.load.bind(this);
    }

    componentDidMount()
    {
        this.load();
    }

    getRoute(row, column)
    {
        return '/admin/regions/' + row.id;
    }

    render()
    {
        const collection = this.state.collectionResponse || {};
        const { match } = this.props;

        return (
            <div className="RegionList">
                <H1 className="clearfix">
                    Regions List

                    {
                        this.state.isLoading ?
                            <LoadingIndicator inline={true} size="sm" /> :
                            <ItemCount count={ collection.count } />
                    }

                    <Reload load={ this.load } />

                    {
                        Response.getLink(collection, 'create') === null ?
                            null :
                            (
                                <ButtonLink type="button"
                                    to={`${match.url}/add`}
                                    intent="primary"
                                    className="float-right">
                                    <Icon icon="plus-circle" />
                                    Add new Region
                                </ButtonLink>
                            )
                    }
                </H1>

                <Loading isLoading={ this.state.isLoading }>
                    <NoResultsFound count={ collection.count }>
                        <Table data={ collection.regions }
                            columns={ this.columns }
                            onSort={ this.load }
                            ordering={ this.state.filter.order } />

                        <Paginator page={ collection.page }
                            count={ collection.count }
                            limit={ collection.limit }
                            onPage={ this.load } />
                    </NoResultsFound>
                </Loading>
            </div>
        );
    }

    load(params = {})
    {
        if (this.state.isLoading) {
            return;
        }
    
        const filterParams = Object.assign({}, this.defaultFilters, this.props.filter, this.state.filter, params);
        let queryStringFilterParams = queryString.stringify(filterParams)
        if (this.props.location.search &&
            '?'+queryStringFilterParams !== this.props.location.search) {
            this.props.history.push({search: queryStringFilterParams});
        }

        this.setState({isLoading: true, filter: filterParams});

        HTTP
            .get('/regions', filterParams)
            .then((response) => {
                if (response) {
                    this.setState({isLoading: false, collectionResponse: response.data});

                    return true;
                }

                toast.error('Unable to fetch a list of Regions');
                this.setState({isLoading: false});

                return false;
            });
    }
}

export default RegionList;
