import store from 'store';
import { TOKEN_INVALID } from "action/authConstants";

const {dispatch} = store;

/**
 * Error interceptor is used to intercept JWT errors, then stop the request and redirect to login
 * @param request Error object
 * @var request.response.data {code: int, message: string}[]
 */
const errorInterceptor = (request) => {
    if(!request.response || !request.response.data) {
        return Promise.reject(request);
    }

    // if only one error is returned, it is returned as an object, so convert to array
    const errors = Object.assign([], Array.isArray(request.response.data) ? request.response.data : [request.response.data]);

    let loopErrors = true;
    while(loopErrors) {
        const error = errors.pop();
        loopErrors = !!errors.length; // when errors length is 0 this goes false and exits loop

        /** check type of error code */
        if(error.code === 401) { // logout on 401: Unauthorised (JWT mismatch)
            dispatch({ type: TOKEN_INVALID, message: error.message, redirect: btoa(window.location.hash.substring(1)) });

            // exit loop on first JWT error
            loopErrors = false;
        }
        if(error.code === 403) { } // do nothing for 403: unauthorised (No access role for page)
    }
    return Promise.reject(request);
}

export default errorInterceptor;
