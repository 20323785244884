import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { isMobile } from "react-device-detect";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { getRootResponse } from 'action/root';
import { Loading } from "components/elements/wrappers";
import { MarloweFooter } from 'components/elements';
import {
    Background,
    Header,
    Main,
    ResendVerificationBanner
} from 'components/elements/layout';

import 'service/InputState';

const mapDispatchToProps = {
    getRootResponse: getRootResponse,
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

class App extends Component
{
    constructor(props)
    {
        super(props);

        this.state = { isLoading: true };
    }

    componentDidMount()
    {
        this.load();
    }

    componentWillUnmount () {
        this.isCancelled = true;
    }

    render()
    {
        const { isLoading } = this.state;

        return (
            <div className="App"
                data-is-mobile={isMobile}>
                <Header />
                <Background />
                <ResendVerificationBanner />
                <Loading isLoading={ isLoading } >
                    <Elements stripe={stripePromise}>
                        <Main />
                    </Elements>
                </Loading>
                <MarloweFooter fixed={false} />
            </div>
        );
    }

    load = async () =>
    {
        await this.props.getRootResponse();
        if (!this.isCancelled) {
            this.setState({ isLoading: false });
        }

        if (process.env.NODE_ENV === 'development') {
            return;
        }

        window.STONLY_WID = "6ad4372f-bc9f-11eb-8dbf-062882f67cfe";
        ((s,t,o,n,l,y,w,g) => {return s.StonlyWidget||((w=s.StonlyWidget=function(){
            w._api?w._api.apply(w,arguments):w.queue.push(arguments)}).scriptPath=n,w.queue=[],(y=t.createElement(o)).async=!0,
            (g=new XMLHttpRequest()).open("GET",n+"version?v="+Date.now(),!0),g.onreadystatechange=function(){
            return 4===g.readyState&&(y.src=n+"stonly-widget.js?v="+(200===g.status?g.responseText:Date.now()),
                (l=t.getElementsByTagName(o)[0]).parentNode.insertBefore(y,l))},g.send())
        })(window,document,"script","https://stonly.com/js/widget/v2/");
    }
}

export default withRouter(connect(null, mapDispatchToProps)(App));
