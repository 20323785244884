import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Login from 'components/pre-auth/Login';
import RegisterWizard from 'components/pre-auth/RegisterWizard';
import ForgottenPassword from 'components/pre-auth/ForgottenPassword';
import PasswordReset from 'components/pre-auth/PasswordReset';
import ExhibitionSignUp from "components/pre-auth/ExhibitionSignUp";

class PreAuthContainer extends Component
{
    render() {
        return (
            <div className="PreAuthContainer">
                <Switch>
                    <Route path="/login" component={ Login } exact />
                    <Route path="/register" component={ RegisterWizard } />
                    <Route path="/forgotten-password" component={ ForgottenPassword } />
                    <Route path="/password-reset" component={ PasswordReset } />

                    <Route path="/register-exhibitions" component={ RegisterWizard } />
                    <Route path="/signup-exhibitions" component={ ExhibitionSignUp } />

                    <Redirect from="/" to="/login"/>
                 </Switch>
            </div>
        );
    }
}

export default PreAuthContainer;
