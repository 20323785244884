import address from "reducer/address";
import assessments from 'reducer/assessments';
import auth from 'reducer/auth';
import breadcrumb from 'reducer/breadcrumb';
import root from 'reducer/root';
import sidebar from 'reducer/sidebar';
import tasks from 'reducer/tasks';
import { combineReducers } from 'redux';

const interimReducer = combineReducers({
    auth,
    address,
    assessments,
    breadcrumb,
    root,
    sidebar,
    tasks
});

const rootReducer = (state = {}, action) => {
    return interimReducer(state, action);
};

export default rootReducer;
