const REVIEW_FREQUENCY_ONE_OFF = 'one-off';
const REVIEW_FREQUENCY_ANNUAL = 'annual';
const REVIEW_FREQUENCY_CASE_BY_CASE = 'case-by-case';
const REVIEW_FREQUENCY_BY_CLIENT = 'by-client';

export {
    REVIEW_FREQUENCY_ONE_OFF,
    REVIEW_FREQUENCY_ANNUAL,
    REVIEW_FREQUENCY_CASE_BY_CASE,
    REVIEW_FREQUENCY_BY_CLIENT,
};
