import { ComponentProps } from 'react';
import { Button, Classes, Dialog, FormGroup, Intent } from '@blueprintjs/core';
import { DateTime } from 'luxon';
import { DateInput } from '@blueprintjs/datetime';
import { useSelector, useDispatch } from 'react-redux';
import { merge } from 'lodash';
import { Card } from '@blueprintjs/core';
import moment from 'moment';

import { AssessmentStateData } from 'interface';
import { Icon, InputElementIcon } from 'components/elements';
import { setChanges } from 'action/assessments';

interface SetDatesDialogProps extends ComponentProps<any> {
    onClose: Function;
    isDialogOpen: boolean;
    onSubmithandler: Function;
}

const SetDatesDialog = (props: SetDatesDialogProps) => {
    const { onClose, isDialogOpen, onSubmithandler } = props;

    const dispatch = useDispatch();
    const { assessmentTypeInstance, changes }: AssessmentStateData =
        useSelector((state: any) => state.assessments);

    const onUpdate = (key: string, mergingValue: any) => {
        let result = merge({}, changes, { [key]: mergingValue });
        dispatch(setChanges(result));
    };

    let storedValidFrom =
        changes['validFrom'] ?? assessmentTypeInstance['validFrom'];
    let dateValueValidFrom = storedValidFrom ? new Date(storedValidFrom) : null;
    let maxDate = DateTime.local().plus({ years: 5 }).toJSDate();

    let storedValueExpiry =
        changes['expiryDate'] ?? assessmentTypeInstance['expiryDate'];
    let dateValueExpiry = storedValueExpiry
        ? new Date(storedValueExpiry)
        : null;

    const expiryLTFrom =
        dateValueValidFrom !== null &&
        dateValueExpiry !== null &&
        dateValueExpiry < storedValidFrom
            ? true
            : false;

    return (
        <Dialog
            className="dialog-form"
            onClose={() => onClose()}
            title="Complete Assessment"
            autoFocus={true}
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            usePortal={true}
            isOpen={isDialogOpen}
        >
            <form
                className="dialog-form"
                onSubmit={(event) => {
                    onClose();
                    onSubmithandler(event);
                }}
            >
                <div className={Classes.DIALOG_BODY}>
                    <p>
                        This assessment type has been set as requiring a
                        flexible start and end date
                    </p>

                    {expiryLTFrom && (
                        <Card className={`error question-error test`}>
                            <Icon icon="exclamation-triangle" />
                            Expiry Date has to be later than Valid From Date
                        </Card>
                    )}

                    <FormGroup
                        label="Assessment Valid From"
                        inline={true}
                        className="form-fill required"
                        labelFor="invoiceContactName"
                    >
                        <DateInput
                            maxDate={maxDate}
                            parseDate={(str) => moment(str, 'DD/MM/YYYY').toDate()}
                            formatDate={(date) =>
                                DateTime.fromJSDate(date).toLocaleString()
                            }
                            popoverProps={{ position: 'bottom', fill: true }}
                            inputProps={{
                                fill: true,
                                id: 'validFrom',
                                required: true,
                                rightElement: InputElementIcon(
                                    'Select a date...',
                                    'calendar-alt'
                                ),
                            }}
                            onChange={(newDate) =>
                                onUpdate('validFrom', newDate)
                            }
                            value={dateValueValidFrom}
                        />
                    </FormGroup>

                    <FormGroup
                        label="Assessment Expiry Date"
                        inline={true}
                        className="form-fill required"
                        labelFor="invoiceContactName"
                    >
                        <DateInput
                            maxDate={maxDate}
                            parseDate={(str) => moment(str, 'DD/MM/YYYY').toDate()}
                            formatDate={(date) =>
                                DateTime.fromJSDate(date).toLocaleString()
                            }
                            popoverProps={{ position: 'bottom', fill: true }}
                            inputProps={{
                                fill: true,
                                id: 'expiryDate',
                                required: true,
                                rightElement: InputElementIcon(
                                    'Select a date...',
                                    'calendar-alt'
                                ),
                            }}
                            onChange={(newDate) =>
                                onUpdate('expiryDate', newDate)
                            }
                            value={dateValueExpiry}
                        />
                    </FormGroup>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className="dialog-footer-buttons">
                        <Button onClick={() => onClose()}>Cancel</Button>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button
                                type="submit"
                                intent={Intent.SUCCESS}
                                disabled={expiryLTFrom}
                            >
                                Confirm
                            </Button>
                        </div>
                    </div>
                </div>
            </form>
        </Dialog>
    );
};

export default SetDatesDialog;
