import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Text } from '@blueprintjs/core';
import { Icon, InputElementIcon } from 'components/elements';
import { HTTP, Response } from 'service';

class FileDownload extends Component
{
    static propTypes = {
        isEditable: PropTypes.bool,
        link: PropTypes.string.isRequired,
        onUpdate: PropTypes.func,
        response: PropTypes.object.isRequired,
    };

    static defaultProps = {
        isEditable: true,
    };

    render()
    {
        const { isEditable, link, onUpdate, response } = this.props;

        return (
            <Text className="bp3-fill display-text display-text-input cursor-as-clickable">
                <div onClick={ (event) => {
                    event.preventDefault();
                    event.stopPropagation();

                    HTTP.stream(Response.getLinkAttribute(response, link, 'download'))
                } }>
                    <Icon icon="download" />

                    { Response.getLinkAttribute(response, link, 'title') }

                    {
                        isEditable === false || typeof onUpdate !== 'function' ?
                            null :
                            (
                                <div className="float-right">
                                    { InputElementIcon('Remove File', 'trash', () => {
                                        onUpdate();
                                    }) }
                                </div>
                            )
                    }
                </div>
            </Text>
        );
    }
}

export default FileDownload;
