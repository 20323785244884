import { ProgressBar } from "@blueprintjs/core";

import './bio-progress.scss';

type ProgressData = {
    [key: string]: any;
}

interface BioProgessProps {
    data: ProgressData;
    completedProperties: string[];
}

const BioProgress = (props: BioProgessProps) => {
    const { data, completedProperties } = props;

    const getCompletionPercentage = () => {
        let completedCount = 0;

        completedProperties.forEach((completionProperty) => {
            if (data[completionProperty]) {
                completedCount += 1;
            }
        });

        return (completedCount / completedProperties.length);
    }

    const completionValue = getCompletionPercentage();
    const completionPercentage = Math.round(completionValue * 100);

    return (
        <>
            <p className="bio-label completion-text">Bio Completion</p>
            <div className="progress-info">
                <ProgressBar
                    intent='primary'
                    value={completionValue}
                    stripes={false}
                    className='completion-progress'
                />
                <p className="bio-label">{completionPercentage + "%"}</p>
            </div>
        </>
    );
}

export default BioProgress;