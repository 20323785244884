import { Tooltip2 } from "@blueprintjs/popover2";
import Icon from 'components/elements/Icon';

const InputElementIcon = (text, icon, callback) =>
{
    return (
        <Tooltip2 content={ text } popoverClassName="the-tooltip"
            key="tooltip">
            <Icon className="cursor-as-clickable"
                icon={ icon }
                tabIndex="-1"
                onClick={ e => {
                    e.preventDefault();
                    e.stopPropagation();

                    if (callback) {
                        callback(e);
                    }
                } } />
        </Tooltip2>
    );
}

export default InputElementIcon;
