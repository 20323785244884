import { Button, FormGroup, InputGroup } from '@blueprintjs/core';
import { useRef } from 'react';

import { ButtonInputProps } from 'interface/input/ButtonInput';
import "./index.scss";

const ButtonInput = (props: ButtonInputProps) => {
    const inputRef = useRef({} as HTMLInputElement);

    const { title, onUpdate, message, disabled, ...inputGroupProps } = props;
    const applyChange = () => {
        const { value } = inputRef.current as HTMLInputElement;
        !!onUpdate && onUpdate(value);
        inputRef.current.value = "";
    }

    return <section className="button-input">
        {
            title && !disabled && <h3>{ title }</h3>
        }
        <FormGroup inline={true} className={ disabled ? 'hidden' : '' }>
            <InputGroup
                { ...inputGroupProps }
                disabled={ disabled }
                onKeyDown={ e => e.key === 'Enter' && e.preventDefault() }
                inputRef={ inputRef }
            />
            <Button intent="primary" onClick={ applyChange }>Apply Voucher</Button>
        </FormGroup>
        {
            message && <span className={ `message ${message.type}` }>{ message.message }</span>
        }
    </section>
}

export default ButtonInput;
