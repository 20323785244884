import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from 'reducer/index';
import thunk from 'redux-thunk';

import Storage from '@elb/storage';

const composeEnhancers = composeWithDevTools({trace: true});
const store = createStore(rootReducer, Storage.load(), composeEnhancers(applyMiddleware(thunk)));

store.subscribe(() => {
    Storage.save(store.getState());
});

export default store;
