import Storage from '@elb/storage';
import axios from 'axios';
import json2formdata from 'json2formdata';
import { isPlainObject } from 'lodash';
import { DateTime } from 'luxon';
import { toast } from 'react-toastify';

import { clearErrors, setAuthErrors, setErrors } from 'action/auth';
import { COMPANY_FIELD_TRANSLATIONS } from 'constants/company';
import store from 'store';
import errorInterceptor from './HttpErrorIntercept';
import { translate } from './Translator';

// Add a request interceptor
axios.interceptors.request.use((config) => config, errorInterceptor);

// Add a response interceptor
axios.interceptors.response.use((response) => response, errorInterceptor);

class HTTP {
    static getHeaders(headers = {}) {
        if (!headers.Authorization) {
            let token = Storage.load('auth.token');
            if (token) {
                headers.Authorization = 'Bearer ' + token;
            }
        }

        return headers;
    }

    static handleError(error = {}) {
        if (!error.request) {
            return;
        }
        // if error is "entity too large", skip handleError and let code catch it
        if (error.response.status === 413) {
            throw error;
        }

        let response = error.response;

        if (
            response &&
            Array.isArray(response.data) &&
            typeof response.data[0] === 'object' &&
            response.data[0].hasOwnProperty('message')
        ) {
            store.dispatch(setAuthErrors(response.data));

            throw error;
        }

        if (response && response.message) {
            toast.error(response.message);
        }

        // sometimes errors are a bit custom, so chain it if any data is returned
        if (error.response.data) {
            store.dispatch(setErrors(response.data));

            throw error;
        }
    }

    static handleFormErrors = (errorObj) => {
        const errors = errorObj?.response?.data;
        if (errors && Object.keys(errors).length) {
            Object.values(errors).forEach((error) => {
                toast.error(
                    <div>
                        {error.property_path} : {error.message}
                    </div>
                );
            });
        }
    };

    static translateErrorProperty(property) {
        translate(property, COMPANY_FIELD_TRANSLATIONS);
        return property;
    }

    static async action(
        type,
        url,
        data = {},
        headers = {},
        errorMessage = '',
        errorCallback
    ) {
        try {
            let result;
            switch (type) {
                case 'delete':
                    result = await HTTP.delete(url, headers);
                    break;
                case 'get':
                    result = await HTTP.get(url, data, headers);
                    break;
                case 'patch':
                    result = await HTTP.patch(url, data, headers);
                    break;
                case 'post':
                    result = await HTTP.post(url, data, headers);
                    break;
                case 'put':
                    result = await HTTP.put(url, data, headers);
                    break;
                default:
                    result = void 0;
            }
            if (!result) {
                errorMessage && console.error(errorMessage);
                typeof errorCallback === 'function' && errorCallback();
                return void 0;
            }
            return result;
        } catch (error) {
            errorMessage && toast.error(errorMessage);
            typeof errorCallback === 'function' && errorCallback(error);
            return void 0;
        }
    }

    static get(url, params, headers = {}) {
        store.dispatch(clearErrors());
        params = HTTP.convertParamsToString(params);
        const route = HTTP.getUrl(url);

        if (route.indexOf('?') === -1) {
            params = '?' + params;
        } else {
            params = '&' + params;
        }

        return axios
            .get(route + params, {
                headers: HTTP.getHeaders(headers),
                responseType: 'json',
                validateStatus: (status) => {
                    return [200, 204].indexOf(status) !== -1;
                },
            })
            .catch(HTTP.handleError);
    }

    static getAll(requests = []) {
        var liveRequests = [];

        requests.forEach((request, index) => {
            liveRequests.push(HTTP.get(...request));
        });

        return axios.all(liveRequests);
    }

    static post(url, data, headers = {}) {
        store.dispatch(clearErrors());
        data = HTTP.normaliseData(data);
        return axios
            .post(HTTP.getUrl(url), data, {
                headers: HTTP.getHeaders(headers),
                responseType: 'json',
                validateStatus: (status) => {
                    return [200, 201, 202].indexOf(status) !== -1;
                },
            })
            .catch(HTTP.handleError);
    }

    static put(url, data, headers = {}) {
        store.dispatch(clearErrors());
        data = HTTP.normaliseData(data);

        return axios
            .put(HTTP.getUrl(url), data, {
                headers: HTTP.getHeaders(headers),
                responseType: 'json',
                validateStatus: (status) => {
                    return [200, 201, 204].indexOf(status) !== -1;
                },
            })
            .catch(HTTP.handleError);
    }

    static patch(url, data, headers = {}) {
        store.dispatch(clearErrors());
        data = HTTP.normaliseData(data);

        return axios.patch(HTTP.getUrl(url), data, {
            headers: HTTP.getHeaders(headers),
            responseType: 'json',
            validateStatus: (status) => {
                return [200, 201, 204].indexOf(status) !== -1;
            },
        });
    }

    static delete(url, headers = {}) {
        store.dispatch(clearErrors());
        return axios.delete(HTTP.getUrl(url), {
            headers: HTTP.getHeaders(headers),
            responseType: 'json',
            validateStatus: (status) => {
                return [200, 202, 204].indexOf(status) !== -1;
            },
        });
    }

    static upload(
        file,
        data,
        uploadProgressCallback,
        headers = {},
        url = null
    ) {
        store.dispatch(clearErrors());
        data = HTTP.normaliseData(data);

        var formData = json2formdata(data);
        formData.append('file', file);

        // var config = {
        //     headers: HTTP.getHeaders(Object.assign({}, headers, {
        //         'Content-type': 'multipart/form-data',
        //     })),
        //     validateStatus: (status) => {
        //         return [204].indexOf(status) !== -1;
        //     },
        //     onUploadProgress: (progressEvent) => {
        //         uploadProgressCallback(progressEvent, Math.round((progressEvent * 100) / progressEvent.total));
        //     },
        // }

        // var progress = 0,
        //     interval;
        // if (uploadProgressCallback) {
        //     interval = window.setInterval(() => {
        //         if (progress > 99) {
        //             return window.clearInterval(interval);
        //         }

        //         progress += ((100 - progress) / 5);
        //         uploadProgressCallback(null, progress);
        //     }, 100);
        // }

        return axios
            .post(HTTP.getUrl(url ?? '/files/new'), formData, {
                headers: HTTP.getHeaders(headers),
                validateStatus: (status) => {
                    return [200, 201].indexOf(status) !== -1;
                },
                onUploadProgress: (progressEvent) => {
                    if (uploadProgressCallback) {
                        uploadProgressCallback(
                            progressEvent,
                            progressEvent.total
                        );
                    }
                    return Math.round(
                        (progressEvent * 100) / progressEvent.total
                    );
                },
            })
            .catch(HTTP.handleError);
    }

    static stream(url, headers = {}, params = {}) {
        store.dispatch(clearErrors());
        params = HTTP.convertParamsToString(params);

        const route = HTTP.getUrl(url);

        if (route.indexOf('?') === -1) {
            params = '?' + params;
        } else {
            params = '&' + params;
        }

        return axios
            .get(route + params, {
                headers: HTTP.getHeaders(headers),
                validateStatus: (status) => {
                    return [200, 204].indexOf(status) !== -1;
                },
                responseType: 'blob',
            })
            .then((response) => {
                const downloadUrl = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', response.headers['x-filename']);

                document.body.appendChild(link);

                link.click();
                link.remove();
                return true;
            })
            .catch(HTTP.handleError);
    }

    static getUrl(url) {
        return process.env.REACT_APP_BASE_URL + url;
    }

    static convertParamsToString(params = {}, prefix) {
        var result = [];

        Object.keys(params)?.forEach((property) => {
            var key = prefix ? prefix + '[' + property + ']' : property,
                value = params[property],
                toPush =
                    value !== null && typeof value === 'object'
                        ? HTTP.convertParamsToString(value, key)
                        : encodeURIComponent(key) +
                          '=' +
                          encodeURIComponent(value);
            if (value !== null) {
                result.push(toPush);
            }
        });

        return result.join('&');
    }

    static normaliseData(data = {}) {
        let normalisedData = Object.assign({}, data);

        if (Array.isArray(normalisedData)) {
            normalisedData.forEach((value, index) => {
                normalisedData[index] = HTTP.normaliseData(value);
            });
        } else {
            Object.entries(normalisedData).forEach((value, index) => {
                if (isPlainObject(value[1])) {
                    normalisedData[value[0]] = HTTP.normaliseData(value[1]);
                } else if (typeof value[1] === 'boolean') {
                    normalisedData[value[0]] = value[1].toString();
                } else if (
                    isNaN(value[1]) &&
                    DateTime.fromISO(value[1]) &&
                    DateTime.fromISO(value[1]).isValid &&
                    /\d{4}/.test(value[1]) //check for year
                ) {
                    normalisedData[value[0]] = DateTime.fromJSDate(
                        new Date(value[1])
                    ).toISO();
                }
            });
        }

        return normalisedData;
    }
}

export default HTTP;
