import { Switch } from 'react-router-dom';

import InsuranceDetails from 'components/company/insurances/InsuranceDetails';
import InsuranceList from 'components/company/insurances/InsuranceList';
import ProtectedRoute from 'components/elements/ProtectedRoute';
import InsuranceAddEdit from 'components/company/insurances/InsuranceAddEdit';

const Insurances = props => {
    const { companyResponse, match } = props;

    return <>
        <div className="Insurances">
            <Switch>
                <ProtectedRoute path={`${match.url}`} component={ InsuranceList } companyResponse={ companyResponse } exact />
                <ProtectedRoute path={`${match.url}/add`} component={ InsuranceAddEdit } companyResponse={ companyResponse } exact />
                <ProtectedRoute path={`${match.url}/:id`} component={ InsuranceDetails } companyResponse={ companyResponse } />
            </Switch>
        </div>
    </>;
}

export default Insurances;
