import UserAdd from 'components/company/users/UserAdd';
import UserList from 'components/company/users/UserList';
import ProtectedRoute from 'components/elements/ProtectedRoute';
import { Component } from 'react';
import { Switch } from 'react-router-dom';

// const UserList = () => { return 'User List'; }
// const UserAdd = () => { return 'User Add'; }

class Users extends Component {
    render() {
        const { reloadData, companyResponse, match } = this.props;

        return (
            <div className="Users">
                <Switch>
                    <ProtectedRoute
                        path={`${match.url}`}
                        component={UserList}
                        companyResponse={companyResponse}
                        reloadData={reloadData}
                        exact
                    />
                    <ProtectedRoute
                        path={`${match.url}/add`}
                        component={UserAdd}
                        companyResponse={companyResponse}
                        reloadData={reloadData}
                        exact
                    />
                </Switch>
            </div>
        );
    }
}

export default Users;
