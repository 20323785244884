import { useEffect, useState } from "react";
import {
    Button,
    FormGroup,
    H1,
    InputGroup,
    Radio,
    RadioGroup,
    TextArea,
} from "@blueprintjs/core";
import { isMobile } from "react-device-detect";
import { toast } from "react-toastify";

import { ButtonLink, HTMLSelect, Icon } from "components/elements";
import { HTTP, Response, Routing, StateHandler } from "service";
import { InputElementIcon } from "components/elements";
import { Loading } from "components/elements/wrappers";
import FileSingleUpload from "components/elements/file-upload/FileSingleUpload";
import { MAX_FILE_UPLOAD_SIZE_MB } from "constants/general";
import { ACTIVE_OPTIONS } from "constants/active";

const CertificationMembershipAddEdit = (props) => {
    const { history, match, certificationMembershipDetails } = props;

    const [isLoading, setIsLoading] = useState(true);

    const [form, setForm] = useState({
        name: "",
        description: null,
        expires: true,
        isActive: true,
        _links: {
            logo: null,
        },
    });

    useEffect(() => {
        if (certificationMembershipDetails) {
            // if edit page, pre-load data and format dates correctly
            let loadedData = {
                ...certificationMembershipDetails,
            };

            setForm({
                ...form,
                ...loadedData,
            });
        }

        setIsLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [certificationMembershipDetails]);

    const fileLabel = (
        <>
            Logo Upload
            {InputElementIcon(
                `Required size 175x175px. Max file size ${MAX_FILE_UPLOAD_SIZE_MB}MB`,
                "info"
            )}
        </>
    );

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (certificationMembershipDetails) {
            try {
                let response = await HTTP.put(
                    Response.getLink(certificationMembershipDetails, "edit"),
                    form
                );

                if (response) {
                    toast.success("Certification Membership updated");
                    Routing.navigateUpOneLevel(history, match);

                    if (props.reloadData) {
                        props.reloadData(form);
                    }

                    return true;
                }
            } catch (e) {
                toast.error("Unable to update Certification Membership");
                console.warn(e.code, e.message);

                return false;
            }
        } else {
            try {
                let response = await HTTP.post(
                    "/certification-memberships/new",
                    form
                );

                if (response) {
                    toast.success("Certification Membership added");
                    Routing.navigateUpOneLevel(history, match);

                    return true;
                }
            } catch (e) {
                toast.error("Unable to add Certification Membership.");
                console.warn(e.code, e.message);

                return false;
            }
        }
    };

    const updateFile = (fileJson) => {
        setForm({
            ...form,
            ...fileJson,
        });
    };

    return (
        <div className="certificationMembershipAddEdit">
            <H1>
                {certificationMembershipDetails
                    ? "Edit Certification Membership"
                    : "Add Certification Membership"}
            </H1>

            <Loading isLoading={isLoading}>
                <form className="AddEdit" onSubmit={(e) => handleSubmit(e)}>
                    <FormGroup
                        label="Name"
                        inline={true}
                        className="form-fill required"
                        labelFor="name"
                    >
                        <InputGroup
                            id="name"
                            placeholder="Name"
                            minLength={2}
                            maxLength={255}
                            value={form.name}
                            onChange={(e) =>
                                setForm(StateHandler.getStateObject(e, form))
                            }
                            required
                        />
                    </FormGroup>

                    <FormGroup
                        label="Description"
                        inline={true}
                        className="form-fill required"
                        labelFor="name"
                    >
                        <TextArea
                            id="description"
                            growVertically="true"
                            placeholder="Description"
                            required
                            onChange={(e) =>
                                setForm(StateHandler.getStateObject(e, form))
                            }
                            minLength={1}
                            maxLength={3000}
                            value={form.description}
                        />
                    </FormGroup>

                    <FileSingleUpload
                        form={form}
                        fileId="logo"
                        isMobile={isMobile}
                        isImage={true}
                        isRequired={false}
                        fileLabel={fileLabel}
                        onUpdate={updateFile}
                    ></FileSingleUpload>

                    <FormGroup
                        label="Expires"
                        inline={true}
                        className="form-fill required"
                    >
                        <RadioGroup
                            fill={true}
                            inline={true}
                            className="form-fill"
                            selectedValue={
                                ["boolean", "string"].indexOf(
                                    typeof form.expires
                                ) !== -1
                                    ? form.expires.toString()
                                    : null
                            }
                            onChange={(e) =>
                                setForm(
                                    StateHandler.getStateObject(
                                        e,
                                        form,
                                        "expires"
                                    )
                                )
                            }
                            required
                        >
                            <Radio label="Yes" value={"true"} />
                            <Radio label="No" value={"false"} />
                        </RadioGroup>
                    </FormGroup>

                    <FormGroup
                        label="Status"
                        inline={true}
                        className="form-fill"
                        labelFor="isActive"
                    >
                        <HTMLSelect
                            id="isActive"
                            fill={true}
                            defaultValue={form.isActive.toString()}
                            onChange={(e) =>
                                setForm(StateHandler.getStateObject(e, form))
                            }
                            options={ACTIVE_OPTIONS}
                        />
                    </FormGroup>

                    <FormGroup>
                        <ButtonLink
                            type="button"
                            intent="default"
                            className="float-left"
                            to={Routing.getRouteUpOneLevel(history, match)}
                        >
                            <Icon icon="ban" />
                            Cancel
                        </ButtonLink>
                        <Button
                            type="submit"
                            intent="primary"
                            className="float-right"
                        >
                            <Icon icon="paper-plane" />
                            {certificationMembershipDetails
                                ? "Update"
                                : "Create"}
                        </Button>
                    </FormGroup>
                </form>
            </Loading>
        </div>
    );
};

export default CertificationMembershipAddEdit;
