import {
    ComponentProps,
    SyntheticEvent,
    useEffect,
    useReducer,
    useState,
} from 'react';

import { ListCollection } from 'interface';
import {
    CampaignInvitee,
    CampaignUpdate,
    CampaignUpdatesListFilter,
} from 'interface/Client/Campaign';
import { toast } from 'react-toastify';
import { Response } from 'service';
import CampaignService from 'service/Client/CampaignService';
import CampaignUpdateDialog from './CampaignUpdateDialog';

interface AssessmentStatusProps extends ComponentProps<any> {
    invitee?: CampaignInvitee;
    isOpen: boolean;
    onClose: Function;
}

const UpdateInviteeProgress = (props: AssessmentStatusProps) => {
    const { invitee, isOpen, onClose } = props;
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [state, setState] = useReducer(
        (state: any, edits: any) => {
            return { ...state, ...edits };
        },
        {
            dialogOpen: !!isOpen,
            isLoading: false,
            statusMessage: '',
            followUpDate: null,
            updateType: null,
            isDeclined: false,
            clearFollowUpDate: true,
        }
    );

    const defaultFilters = {
        order: '-updatedOn',
        limit: 999,
        invitee: invitee?.id,
    };

    const [listFilter, setListFilter] = useState<CampaignUpdatesListFilter>({
        ...defaultFilters,
    } as CampaignUpdatesListFilter);

    const [updates, setUpdates] = useState<ListCollection<CampaignUpdate>>(
        {} as ListCollection<CampaignUpdate>
    );

    useEffect(() => {
        setListFilter({
            ...defaultFilters,
            invitee: invitee?.id,
        } as CampaignUpdatesListFilter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invitee]);

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            await load({ ...listFilter, invitee: invitee?.id });

            setIsLoading(false);
            setState({ dialogOpen: isOpen });
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invitee, isOpen, listFilter]);

    // cleanup
    useEffect(
        () => () => {
            setUpdates({} as ListCollection<CampaignUpdate>);
        },
        []
    );

    const load = async (filter: CampaignUpdatesListFilter) => {
        if (typeof invitee === 'undefined') {
            return;
        }

        const updates = await CampaignService.loadInviteeUpdatesList(
            invitee.id,
            filter
        );
        const followUpDate = updates.data[0]?.optionalFollowUpDate || null;

        if (updates.data.length) {
            setState({
                followUpDate: followUpDate ? new Date(followUpDate) : null,
                clearFollowUpDate: followUpDate ? false : true,
            });
        }
        setUpdates(updates);
        setIsLoading(false);
    };

    const dialogClose = (event: SyntheticEvent) => {
        setState({
            dialogOpen: false,
            statusMessage: '',
            followUpDate: null,
            isDeclined: false,
            isLoading: false,
            updateType: '',
            clearFollowUpDate: true,
        });
        typeof onClose === 'function' && onClose(event);
    };

    const dialogConfirm = async () => {
        if (!invitee || !Response.getLink(invitee, 'update-status')) {
            toast.error('Could not send update for invitee.');
            return;
        }

        if (!state.updateType) {
            toast.error('Please select an update type');
            return;
        }

        //use invitee and submit here
        setState({ isLoading: true });

        const {
            statusMessage,
            isDeclined,
            updateType,
            followUpDate,
            clearFollowUpDate,
        } = state;

        const response = await CampaignService.updateInviteeStatus(invitee, {
            statusMessage,
            isDeclined,
            updateType,
            optionalFollowUpDate: clearFollowUpDate ? null : followUpDate,
        });

        response && toast.success('Status has been updated successfully.');
        dialogClose({} as SyntheticEvent);
    };

    const companyName =
        typeof invitee?.companyName !== 'undefined'
            ? invitee?.companyName
            : 'Company TBC';

    const handleOnChange = (key: string, value: any) => {
        setState({ [key]: value });
    };

    return (
        <CampaignUpdateDialog
            onClose={dialogClose}
            updates={updates}
            isOpen={state.dialogOpen}
            title={`View Campaign Updates - ${companyName}`}
            onChange={handleOnChange}
            initialData={state}
            isLoading={isLoading}
            onConfirm={dialogConfirm}
        />
    );
};

export default UpdateInviteeProgress;
