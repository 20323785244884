import { HTTP, Response } from 'service';
import { toast } from 'react-toastify';
import { MAX_FILE_UPLOAD_SIZE_MB } from '../../constants/general';
import { File, FileList } from '../../interface/File';

class FileService {
    static upload: (
        file: File,
        addedFile: File,
        filesList: FileList,
        uploadLinksObject: any,
        updateFiles: (filesList: FileList, initialise?: boolean) => void
    ) => void = (
        file: File,
        addedFile: File,
        filesList: FileList,
        uploadLinksObject: any,
        updateFiles: (filesList: FileList, initialise?: boolean) => void
    ) => {
        HTTP.upload(
            file,
            Object.assign({}, uploadLinksObject, {
                title: file.name,
            }),
            (progressEvent: ProgressEvent) => {
                addedFile.progress = progressEvent.loaded / progressEvent.total;
            }
        )
            .then((response) => {
                if (response) {
                    Object.assign(addedFile, {
                        isUploaded: true,
                        href: Response.getLink(response.data, 'self'),
                        _links: { self: response.data._links.self },
                        title: response.data.title,
                        download: Response.getLink(response.data, 'download'),
                    });
                    // force update
                    updateFiles(filesList);

                    return true;
                }

                addedFile.hasErrors = true;
                addedFile.errorText = 'Unable to upload file';
                toast.error('Unable to upload file');
                // force update
                updateFiles(filesList);

                return false;
            })
            .catch((error) => {
                addedFile.hasErrors = true;
                let response = error.response;

                if (error.request && error.request.status === 413) {
                    addedFile.errorText = `File exceeds size limit of ${MAX_FILE_UPLOAD_SIZE_MB}MB`;
                } else if (response.data[0].hasOwnProperty('message')) {
                    addedFile.errorText = response.data[0].message;
                } else {
                    addedFile.errorText = error.message;
                }

                toast.error('Unable to upload file');
                // force update
                updateFiles(filesList);
                return false;
            });
    };
}

export default FileService;
