import { Redirect, Switch } from "react-router-dom";

import CertificationMembershipAddEdit from "components/admin/certification-memberships/CertificationMembershipAddEdit";
import CertificationMembershipDetails from "components/admin/certification-memberships/CertificationMembershipDetails";
import CertificationMembershipsList from "components/admin/certification-memberships/CertificationMembershipsList";
import ProtectedRoute from "components/elements/ProtectedRoute";

const CertificationMemberships = () => {
    return (
        <div className="CertificationMemberships">
            <Switch>
                <ProtectedRoute
                    path="/admin/certification-memberships"
                    component={CertificationMembershipsList}
                    exact
                />
                <ProtectedRoute
                    path="/admin/certification-memberships/add"
                    component={CertificationMembershipAddEdit}
                    exact
                />
                <ProtectedRoute
                    path="/admin/certification-memberships/:certificationMembershipId"
                    component={CertificationMembershipDetails}
                />
                <Redirect to="/" />
            </Switch>
        </div>
    );
};

export default CertificationMemberships;
