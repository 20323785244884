export const UPDATING_BREADCRUMB_RESOLVES = 'UPDATING_BREADCRUMB_RESOLVES';

export {
    updatingBreadcrumbResolves,
};

function updatingBreadcrumbResolves(resolve)
{
    return {
        type: UPDATING_BREADCRUMB_RESOLVES,
        resolve: resolve,
    };
}
