import {
    UPDATING_BREADCRUMB_RESOLVES,
} from 'action/breadcrumb';

const defaultState = {
    id: null,
    isUpdating: false,
    resolves: {
        question: {},
        assessment: { completion: null, purchase: null, payment: null, review: null }
    },
};

export default function breadcrumb(state = defaultState, action)
{
    const baseState = Object.assign({}, defaultState, state);

    switch (action.type) {
        case UPDATING_BREADCRUMB_RESOLVES:
            return Object.assign(baseState, {
                lastUpdatedAt: +(new Date()),
                resolves: Object.assign(
                    {},
                    baseState.resolves,
                    action.resolve
                ),
            });

        default:
            return Object.assign(baseState);
    }
}
