import { AxiosResponse } from 'axios';
import { FormEvent } from 'react';
import { toast } from 'react-toastify';

import { emptyListData } from 'interface';
import {
    ELBClientListData,
    ELBServiceProviderData,
    ELBServiceProviderListData,
    ELBSitesListData,
    ELBSPJobCounts,
    ELBSPLinkedCounts,
    ElogbooksClientsFilter,
    ElogbooksServiceProviderFilter,
    IntegrationByAdminData,
    IntegrationData,
    IntegrationsFilter,
    IntegrationsListData,
} from 'interface/Integrations';
import { HTTP, Routing } from 'service';

export const createIntegrationObject = (
    integrationType?: string
): IntegrationData => {
    return {
        name: '',
        createdAt: new Date(),
        id: '',
        integrationURL: '',
        companyReferenceId: '',
        companyReferenceName: '',
        status: 0,
        type: integrationType?.toLowerCase(),
        _links: {},
    };
};

export const loadIntegrationList = async (
    filter: IntegrationsFilter | null
): Promise<IntegrationsListData> => {
    const response: AxiosResponse | void = await HTTP.action(
        'get',
        '/integrations',
        filter ?? {},
        {},
        'Unable to load Integrations list'
    );

    if (response && response.data) {
        return response.data as IntegrationsListData;
    } else {
        return {
            ...emptyListData,
            integrations: [],
        } as IntegrationsListData;
    }
};

export const deleteIntegration = async (
    id: string
): Promise<AxiosResponse | void> => {
    const response: AxiosResponse | void = await HTTP.action(
        'delete',
        `/integrations/${id}`,
        {},
        {},
        'Unable to delete integration'
    );

    return response;
};

export const setupIntegrations = async (
    data: string | string[]
): Promise<AxiosResponse | void> => {
    let response: AxiosResponse | void;
    response = await HTTP.action(
        'post',
        '/integrations',
        { encryptedData: data },
        {},
        'Integration setup failed'
    );

    if (response) {
        if (response.data.updatedCount < 1) {
            toast.error('All selected links already exist');
        } else {
            toast.success('Integrations updated successfully');
        }
    } else {
        toast.error('Unable to add integrations');
    }

    return response;
};

export const submitPostIntegration = async (
    e: FormEvent<HTMLFormElement>,
    form: IntegrationData,
    history: any,
    match: any,
    reload: any
): Promise<AxiosResponse | void> => {
    e.preventDefault();
    e.stopPropagation();

    let response: AxiosResponse | void;

    const lastUrlChar = form.integrationURL.charAt(
        form.integrationURL.length - 1
    );
    if (lastUrlChar !== '/') {
        form.integrationURL += '/';
    }

    response = await HTTP.action(
        'post',
        '/integrations/new',
        form,
        {},
        'Unable to create Integration'
    );

    if (response) {
        toast.success('Integration created');

        Routing.navigateUpOneLevel(history, match);
    }

    return response;
};

export const getEncryptedCompanyInfo = async (): Promise<
    AxiosResponse | undefined
> => {
    return await HTTP.action(
        'get',
        '/integrations/company-info',
        {},
        {},
        'Unable to load Company Info'
    );
};

export const redirectToIntegration = async (
    e: FormEvent<HTMLFormElement>,
    form: IntegrationData
): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();

    let encryptedCompanyInfo = await getEncryptedCompanyInfo();

    if (typeof encryptedCompanyInfo === 'undefined') {
        toast.error('Unable to load Company Info');

        return;
    }

    let url = form.integrationURL;

    const lastUrlChar = url.charAt(url.length - 1);
    if (lastUrlChar !== '/') {
        url += '/';
    }

    let companyInfo = encryptedCompanyInfo.data.encryptedInfo;

    window.location.replace(
        `${url}#/user/integrations/prosure?company=${companyInfo}`
    );
};

export const removeIntegration = async (
    removeLink: string,
    reload: any
): Promise<void> => {
    let response: AxiosResponse | void;
    response = await HTTP.action(
        'put',
        removeLink,
        {},
        {},
        'Unable to remove Integration'
    );
    if (response) {
        toast.success('Integration Removed');
        if (typeof reload === 'function') {
            reload();
        }
    }
};

export const handleSubmitAdminIntegations = async (
    e: FormEvent<HTMLFormElement>,
    form: IntegrationByAdminData,
    history: any,
    match: any
): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();

    let response: AxiosResponse | void;

    const serviceProviders = form.serviceProviderAdd.map(function (
        sp: ELBServiceProviderData
    ) {
        return {
            id: sp.elogbooksID,
            name: sp.elogbooksName,
        };
    });

    form = {
        ...form,
        serviceProviderAdd: serviceProviders,
    };

    response = await HTTP.action(
        'post',
        '/integrations',
        form,
        {},
        'Unable to create integration links'
    );

    if (response) {
        toast.success('Integration Links Updated');

        Routing.navigateUpOneLevel(history, match);
    }
};

export const handleSubmitIntegration = async (form: any): Promise<any> => {
    form.serviceProviderAdd = form.serviceProviderAdd.map(function (
        sp: ELBServiceProviderData
    ) {
        return {
            id: sp.elogbooksID,
            name: sp.elogbooksName,
        };
    });

    const response = await HTTP.action(
        'post',
        `/integrations/client/${form.clientId}`,
        form,
        {},
        'Unable to create integration link'
    );

    if (response) {
        toast.success('Integration Link Added');

        return response;
    }

    return null;
};

export const handleSubmitUnIntegration = async (
    integrationId: string
): Promise<any> => {
    const response = await HTTP.action(
        'delete',
        `/integrations/${integrationId}`,
        {},
        {},
        'Unable to remove integration link'
    );

    if (response) {
        toast.success('Integration Link Removed');

        return response;
    }

    return null;
};

export const createElogbooksClientFilter = (): ElogbooksClientsFilter => {
    return {
        client: '',
        order: 'client',
    };
};

export const loadELBClients = async (
    filter?: Partial<ElogbooksClientsFilter>
): Promise<ELBClientListData> => {
    const response: AxiosResponse | void = await HTTP.action(
        'get',
        '/integrations/elb-clients',
        filter ? filter : {},
        {},
        'Unable to load assessment list list'
    );
    if (response) {
        return response.data as ELBClientListData;
    } else {
        return {
            count: 0,
            serviceProviders: [],
        } as ELBClientListData;
    }
};

export const createElogbooksSPFilter = (): ElogbooksServiceProviderFilter => {
    return {
        spClient: '',
        name: '',
        order: 'name',
    };
};

export const loadELBServiceProviders = async (
    filter?: Partial<ElogbooksServiceProviderFilter>
): Promise<ELBServiceProviderListData> => {
    const response: AxiosResponse | void = await HTTP.action(
        'get',
        '/integrations/elb-sps',
        filter ? filter : {},
        {},
        'Unable to load ELB Service Providers'
    );
    if (response) {
        return response.data as ELBServiceProviderListData;
    } else {
        return {
            count: 0,
            serviceProviders: [],
        } as ELBServiceProviderListData;
    }
};

export const loadClientELBServiceProviders = async (
    clientId: string,
    filter?: Partial<ElogbooksServiceProviderFilter>
): Promise<ELBServiceProviderListData> => {
    const response: AxiosResponse | void = await HTTP.action(
        'get',
        `/integrations/${clientId}/elb-sps`,
        filter ? filter : {},
        {},
        'Unable to load ELB Service Providers'
    );
    if (response) {
        return response.data as ELBServiceProviderListData;
    } else {
        return {
            count: 0,
            serviceProviders: [],
        } as ELBServiceProviderListData;
    }
};

export const loadClientELBServiceProviderCounts = async (
    clientId: string
): Promise<ELBSPJobCounts> => {
    const response: AxiosResponse | void = await HTTP.action(
        'get',
        `/integrations/${clientId}/elb-sps-job-counts`,
        {},
        {},
        'Unable to load ELB Service Provider Counts'
    );
    if (response) {
        return response.data as ELBSPJobCounts;
    } else {
        return {
            belowRequirementCount: 0,
            expiringSoonCount: 0,
            meetsRequirementsCount: 0,
            notInSupplyChainCount: 0,
        } as ELBSPJobCounts;
    }
};

export const loadClientELBSUpdatedAt = async (
    clientId: string
): Promise<string | null> => {
    const response: AxiosResponse | void = await HTTP.action(
        'get',
        `/integrations/${clientId}/elb-updated-time`,
        {},
        {},
        'Unable to load ELB Updated At Time'
    );
    if (response) {
        let updatedAtValue = response.data.updatedAt;
        return typeof updatedAtValue === 'undefined' || updatedAtValue === null
            ? null
            : updatedAtValue;
    } else {
        return null;
    }
};

export const loadLinkedServiceProviderNumbers = async (
    clientId: string
): Promise<ELBSPLinkedCounts> => {
    const response: AxiosResponse | void = await HTTP.action(
        'get',
        `/integrations/${clientId}/elb-sps-linked-counts`,
        {},
        {},
        'Unable to load ELB Link Counts'
    );
    if (response) {
        return response.data as ELBSPLinkedCounts;
    } else {
        return {
            linkedCount: 0,
            linkedNotSC: 0,
            notLinkedCount: 0,
        } as ELBSPLinkedCounts;
    }
};

export const loadELBSites = async (
    clientId: string
): Promise<ELBSitesListData> => {
    const response: AxiosResponse | void = await HTTP.action(
        'get',
        `/integrations/${clientId}/elb-sites`,
        {},
        {},
        'Unable to load ELB Sites'
    );

    if (response) {
        return response.data as ELBSitesListData;
    } else {
        return {
            count: 0,
            sites: [],
        } as ELBSitesListData;
    }
};
