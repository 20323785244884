import {
    Button,
    Classes,
    Dialog,
    FormGroup,
    InputGroup,
    Intent,
} from '@blueprintjs/core';
import { DateInput } from '@blueprintjs/datetime';
import { DateTime } from 'luxon';
import moment from 'moment';

import { InputElementIcon } from 'components/elements';
import './styles.scss';

const ConfirmPaymentDialog = (props) => {
    const { form, isDialogOpen, onClose, onSubmithandler, setForm } = props;

    const defaultMinDate = DateTime.local(2021, 11, 1).toJSDate();
    const maxDate = DateTime.local().plus({ years: 3 }).toJSDate();

    return (
        <Dialog
            onClose={onClose}
            title="Confirm payment"
            autoFocus={true}
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            enforceClose={true}
            usePortal={true}
            isOpen={isDialogOpen}
        >
            <form
                className="dialog-form"
                onSubmit={(event, form) => onSubmithandler(event, form)}
            >
                <div className={Classes.DIALOG_BODY}>
                    <FormGroup
                        label="Payment Date"
                        inline={true}
                        className="form-fill choice required"
                    >
                        <DateInput
                            id="paymentDate"
                            name="paymentDate"
                            minDate={defaultMinDate}
                            maxDate={maxDate}
                            parseDate={(str) => moment(str, 'DD/MM/YYYY').toDate()}
                            formatDate={(date) =>
                                DateTime.fromJSDate(date).toLocaleString()
                            }
                            popoverProps={{ position: 'bottom', fill: true }}
                            inputProps={{
                                fill: true,
                                id: 'paymentDate',
                                required: true,
                                placeholder: 'Select Payment Date',
                                rightElement: InputElementIcon(
                                    'Select a date...',
                                    'calendar-alt'
                                ),
                            }}
                            onChange={(newDate) => {
                                newDate = new Date(+newDate + 1000);
                                setForm({
                                    ...form,
                                    paymentDate: newDate,
                                });
                            }}
                            value={form.paymentDate}
                        />
                    </FormGroup>

                    <FormGroup
                        label="Payment ID"
                        inline={true}
                        className="form-fill required"
                        labelFor="paymentId"
                    >
                        <InputGroup
                            id="paymentId"
                            placeholder="Payment ID"
                            minLength={0}
                            min={0}
                            maxLength={75}
                            required
                            value={form.paymentId}
                            onChange={(event) => {
                                setForm({
                                    ...form,
                                    paymentId: event.target.value,
                                });
                            }}
                        />
                    </FormGroup>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className="dialog-footer-buttons">
                        <Button onClick={onClose}>Cancel</Button>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button type="submit" intent={Intent.SUCCESS}>
                                Confim Payment
                            </Button>
                        </div>
                    </div>
                </div>
            </form>
        </Dialog>
    );
};

export default ConfirmPaymentDialog;
