import { Button, Classes, Dialog, FormGroup, Intent } from '@blueprintjs/core';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { HTMLSelect, SuggestRenderer } from 'components/elements';
import Icon from 'components/elements/Icon';
import { SHAPE_COMPANY_RESPONSE } from 'constants/company';
import { STATUS_ACCEPTED, STATUS_PENDING } from 'constants/supplyChain';
import { globalDebounce, isAdmin } from 'helpers/helpers';
import {
    CampaignPriority,
    CampaignPriorityOptions,
} from 'interface/Client/Campaign';
import { ClientService, HTTP, Response } from 'service';
import CampaignService from 'service/Client/CampaignService';
import './index.scss';

const AddToSupplyChain = (props) => {
    const { company } = props;
    const [selectedLinks, setSelectedLinks] = useState({
        _links: {},
    });
    const [state, setState] = useReducer(
        (state, edits) => {
            return { ...state, ...edits };
        },
        {
            dialogOpen: false,
            isLoading: false,
            clientSearchParam: null,
            clients: [],
            client: null,
            campaigns: [],
            campaign: null,
            campaignSearchParam: null,
            spCategoriesSearchParam: null,
            spCategories: [],
            spCategory: null,
            showPriority: false,
            priority: CampaignPriority.High,
            supplyChainStatus:
                !isAdmin() &&
                (company?.currentSupplyChainInstance?.status ?? null),
        }
    );
    const rootResponse = useSelector((state) => state.root.response);

    const loadSpCategories = useCallback(async () => {
        if (state.client !== null) {
            globalDebounce(
                async () => {
                    const spCategories = await ClientService.getSPCategories(
                        state.client,
                        {
                            ...state.spCategoriesSearchParam,
                            order: 'name',
                        }
                    );
                    setState({ spCategories });
                },
                'categorySearch',
                250
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.client]);

    const loadClients = useCallback(
        async (search) => {
            const param = {
                companyId: company.id,
                datagroup: 'details',
                order: 'name',
                invitable: true,
                isActive: 'true',
            };

            if (search) {
                param.name = search;
            }

            const response = await HTTP.get('/clients', param);

            setState({ clients: response.data.clients });
        },
        [company]
    );

    const onClientSelect = async (client) => {
        loadCategoriesFromClient(client);
        loadCampaignsFromClient(client);

        const links = Response.updateLink(
            selectedLinks,
            'client',
            Response.getLink(client, 'self')
        );
        setSelectedLinks(links);

        return;
    };

    const onCategorySelect = (category) => {
        const links = Response.updateLink(
            selectedLinks,
            'sp-category',
            Response.getLink(category, 'self')
        );
        setSelectedLinks(links);

        setState({ spCategory: category });

        return;
    };

    const onCampaignSelect = (campaign) => {
        let links;
        if (!campaign) {
            links = { ...selectedLinks };
            links._links.campaign = void 0;
            setState({ showPriority: false });
        } else {
            links = Response.updateLink(
                selectedLinks,
                'campaign',
                Response.getLink(campaign, 'self')
            );
            setState({ showPriority: true });
        }
        setSelectedLinks(links);
        setState({ campaign: campaign });

        return;
    };

    const loadCategoriesFromClient = async (client) => {
        const link = Response.getLink(client, 'sp-categories');
        if (link !== undefined) {
            const clientsCopy = state.clients;
            const index = clientsCopy.findIndex((row) => row.id === client.id);

            if (index === -1) {
                return;
            }

            HTTP.get(link).then((response) => {
                client.spCategorisations = response.data.categories;
                setState({ spCategories: client.spCategorisations });
                clientsCopy[index] = client;
                setState({ clients: clientsCopy });
            });
        }
    };

    const loadCampaignsFromClient = async (clientResponse) => {
        const campaignCollection =
            clientResponse.id &&
            (await CampaignService.loadList({
                client: clientResponse.id,
            }));
        setState({ campaigns: campaignCollection.data ?? null });
    };

    useEffect(() => {
        if (isAdmin() && state.dialogOpen) {
            setState({ spCategories: [] });
            loadClients();
        } else if (state.client !== null) {
            loadSpCategories();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.dialogOpen]);

    const addToSupplyChain = async () => {
        setState({ isLoading: true });

        const response = await HTTP.post(
            Response.getLink(company, 'supply-chain-add'),
            selectedLinks
        ).catch((error) => {
            toast.error(
                error.response?.data[0]?.message ||
                    `Could not invite ${company.registeredCompanyName} to supply chain. please try again later.`
            );
        });

        setState({ isLoading: false });

        if (
            !response ||
            (response && [200, 201].indexOf(response.code) !== -1)
        ) {
            toast.error(
                `Could not invite ${company.registeredCompanyName} to supply chain. please try again later.`
            );
            return;
        }

        company.supplyChainStatus = STATUS_PENDING;
        setState({ dialogOpen: false, supplyChainStatus: STATUS_PENDING });
        toast.success(
            `Invited ${company.registeredCompanyName} to your supply chain`
        );
    };

    const openDialog = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        const clientLink = Response.getLink(rootResponse, 'client');
        let client = state.client;

        if (
            company.supplyChainStatus !== STATUS_PENDING &&
            company.supplyChainStatus !== STATUS_ACCEPTED &&
            clientLink
        ) {
            if (client === null) {
                client = await HTTP.get(clientLink, {
                    datagroup: 'details',
                }).then((clientResponse) => clientResponse.data);
                setState({ client });
            }
        }
        setState({ dialogOpen: true });
    };

    const handleClose = (event) => {
        event.preventDefault();
        event.stopPropagation();
        resetDialogForm();
    };

    const resetDialogForm = () => {
        setState({
            dialogOpen: false,
            showPriority: false,
            campaigns: [],
            campaign: null,
            client: null,
            spCategory: null,
            priority: CampaignPriority.High,
        });
    };

    const onSubmit = async (event) => {
        event.stopPropagation();
        event.preventDefault();

        if (!state.showPriority) {
            addToSupplyChain(event);
            resetDialogForm();
        } else {
            setState({ isLoading: true });
            const form = {
                priority: state.priority,
                _links: {
                    'sp-category': state.spCategory._links['self'],
                    company: company._links['self'],
                },
            };

            const response = await CampaignService.addExistingInvitee(
                state.campaign,
                form,
                HTTP.handleFormErrors
            );
            setState({ isLoading: false });

            if (!response) {
                // error toast should already show from the CampaignService
                return;
            }

            resetDialogForm();
            toast.success(
                'Successfully Added Service Provider to Supply Chain & Campaign'
            );
        }
    };

    useEffect(() => {
        state.clientSearchParam &&
            globalDebounce(
                loadClients(state.clientSearchParam),
                'clientSearch',
                250
            );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.clientSearchParam]);

    const supplyChainStatusButton = () => {
        if (
            !Response.getLink(company, 'supply-chain-add') &&
            !company.hasOwnProperty('currentSupplyChainInstance')
        ) {
            return null;
        }

        switch (state.supplyChainStatus) {
            case STATUS_PENDING:
                return (
                    <Button
                        className="add-to-supply-chain icon-only"
                        onClick={handleClose}
                        title="Pending acceptance into supply chain"
                    >
                        <Icon icon="heart" className="grey" />
                    </Button>
                );
            case STATUS_ACCEPTED:
                return (
                    <Button
                        className="add-to-supply-chain icon-only"
                        onClick={handleClose}
                        title="Accepted invitation into supply chain"
                    >
                        <Icon icon="heart" />
                    </Button>
                );
            default:
                return (
                    <>
                        <Button
                            className="add-to-supply-chain icon-only"
                            onClick={openDialog}
                            title="Not in supply chain"
                        >
                            <Icon icon="heart" prefix="far" />
                        </Button>
                        <Dialog
                            className="add-supply-chain-dialog"
                            onClose={handleClose}
                            title="Invite to Supply Chain"
                            autoFocus={true}
                            canEscapeKeyClose={true}
                            canOutsideClickClose={true}
                            enforceClose={true}
                            usePortal={true}
                            isOpen={state.dialogOpen}
                        >
                            <form
                                onClick={(event) => event.stopPropagation()}
                                className="dialog-form"
                                onSubmit={onSubmit}
                            >
                                <div className={Classes.DIALOG_BODY}>
                                    {state.isLoading === false &&
                                    state.client !== null &&
                                    state.spCategories.length === 0 ? (
                                        <p>
                                            {' '}
                                            There are currently no categories
                                            setup for your account.
                                        </p>
                                    ) : (
                                        <>
                                            {isAdmin() && clientFormElement()}

                                            {categoryFormElement()}

                                            {isAdmin() &&
                                                state.campaigns?.length > 0 &&
                                                campaignFormElement()}

                                            {isAdmin() &&
                                                state.campaign &&
                                                state.showPriority &&
                                                priorityFormElement()}
                                        </>
                                    )}
                                </div>
                                <div className={Classes.DIALOG_FOOTER}>
                                    <div className="dialog-footer-buttons">
                                        <Button onClick={handleClose}>
                                            Cancel
                                        </Button>
                                        {state.isLoading === false &&
                                        state.client !== null &&
                                        state.spCategories.length ===
                                            0 ? null : (
                                            <>
                                                <div
                                                    className={
                                                        Classes.DIALOG_FOOTER_ACTIONS
                                                    }
                                                >
                                                    <Button
                                                        type="submit"
                                                        disabled={
                                                            state.isLoading
                                                        }
                                                        intent={Intent.SUCCESS}
                                                    >
                                                        Accept
                                                    </Button>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </form>
                        </Dialog>
                    </>
                );
        }
    };

    const clientFormElement = () => {
        return (
            <>
                <p>
                    Which client are you currently inviting this Service
                    Provider on behalf of?
                </p>
                <FormGroup
                    label="Client"
                    key="client"
                    inline={true}
                    className="form-fill required"
                    labelFor="category"
                >
                    <SuggestRenderer
                        id="client"
                        valueProperty="name"
                        onItemSelect={onClientSelect}
                        items={state.clients}
                        onKeyUp={(event) => {
                            let keyedValue = event.target.value.trim();
                            if (state.clientSearchParam !== keyedValue) {
                                setState({
                                    clientSearchParam: keyedValue,
                                });
                            }
                        }}
                        isRequired={true}
                    />
                </FormGroup>
            </>
        );
    };

    const categoryFormElement = () => {
        return (
            <>
                <p>
                    Please select the category of Service Provider. An invite
                    will then be sent via email.
                </p>

                <FormGroup
                    label="Category"
                    key="category"
                    inline={true}
                    className="form-fill required"
                    labelFor="category"
                >
                    <SuggestRenderer
                        id="category"
                        valueProperty="name"
                        onItemSelect={onCategorySelect}
                        items={state.spCategories}
                        onKeyUp={(event) => {
                            let keyedValue = event.target.value.trim();
                            if (
                                state.spCategoriesSearchParam?.name !==
                                keyedValue
                            ) {
                                setState({
                                    spCategoriesSearchParam: {
                                        name: keyedValue,
                                    },
                                });
                            }
                        }}
                        isRequired={true}
                    />
                </FormGroup>
            </>
        );
    };

    const campaignFormElement = () => {
        return (
            <>
                <p>
                    If applicable, add the Campaign this Service Provider should
                    be added to
                </p>

                <FormGroup
                    label="Campaign"
                    key="campaign"
                    inline={true}
                    className="form-fill"
                    labelFor="campaign"
                >
                    <SuggestRenderer
                        id="campaign"
                        valueProperty="name"
                        onItemSelect={onCampaignSelect}
                        items={state.campaigns}
                        onKeyUp={(event) => {
                            let keyedValue = event.target.value.trim();
                            if (
                                state.campaignSearchParam?.name !== keyedValue
                            ) {
                                setState({
                                    campaignSearchParam: {
                                        name: keyedValue,
                                    },
                                });
                            }
                        }}
                    />
                </FormGroup>
            </>
        );
    };

    const priorityFormElement = () => {
        return (
            <FormGroup
                label="Priority for Client"
                key="priority"
                inline={true}
                className="form-fill required"
                labelFor="priority"
            >
                <HTMLSelect
                    id="priority"
                    fill={true}
                    defaultValue={state.priority}
                    onChange={(e) => setState({ priority: e.target.value })}
                    options={CampaignPriorityOptions}
                    required
                />
            </FormGroup>
        );
    };

    return supplyChainStatusButton();
};

AddToSupplyChain.propTypes = {
    company: PropTypes.shape(SHAPE_COMPANY_RESPONSE).isRequired,
};

export default AddToSupplyChain;
