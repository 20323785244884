import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from '@blueprintjs/core';
import { MultiSelectRenderer } from 'components/elements';

class MultiSelect extends Component
{
    static propTypes = {
        filterKey: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        items: PropTypes.array.isRequired,
        placeholder: PropTypes.string,
    };

    static defaultProps = {};

    constructor(props)
    {
        super(props);

        this.state = {
            value: props.value,
        };

        this.handleChange = this.handleChange.bind(this);
    }

    render()
    {
        const { items, ...rest } = this.props;

        return (
            <div className="FilterMultiSelect">
                <FormGroup label={ this.props.label }
                    inline={true}
                    className="form-fill"
                    labelFor={ this.props.filterKey }>
                    <MultiSelectRenderer id={ this.props.filterKey }
                        onItemSelect={ (item, event) => {
                            this.setState({value: item}, () => {
                                this.handleChange(event);
                            });
                        } }
                        fill={true}
                        items={ items }
                        { ...rest } />
                </FormGroup>
            </div>
        );
    }

    handleChange(event)
    {
        this.props.onChange({
            value: this.state.value,
            data: this.state.value,
        }, this.props.filterKey);
    }
}

export default MultiSelect;
