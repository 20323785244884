import { FormEvent, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Button, FormGroup, H2, InputGroup } from '@blueprintjs/core';

import { UserData } from 'interface/User';
import { ButtonLink, Icon } from 'components/elements';
import UserService from '../../service/User/UserService';

interface ProfileEditProps extends RouteComponentProps<any> {
    user: UserData;
    mergeUserResponse: { (userResponse: UserData): void };
}

const ProfileEdit = (props: ProfileEditProps) => {
    const [form, setForm] = useState<UserData>(props.user);
    const { mergeUserResponse } = props;

    return (
        <div className="ProfileEdit">
            <H2>Edit Profile</H2>
            <form
                className="AddEdit"
                onSubmit={(e: FormEvent<HTMLFormElement>) =>
                    UserService.handleSubmit(e, form, props, mergeUserResponse)
                }
            >
                <FormGroup
                    label="Forename"
                    inline={true}
                    className="form-fill required"
                    labelFor="Forename"
                >
                    <InputGroup
                        id="forename"
                        placeholder="Forename"
                        minLength={2}
                        maxLength={75}
                        value={form.forename}
                        onChange={(e) =>
                            setForm({ ...form, forename: e.target.value })
                        }
                        required
                    />
                </FormGroup>
                <FormGroup
                    label="Surname"
                    inline={true}
                    className="form-fill required"
                    labelFor="Surname"
                >
                    <InputGroup
                        id="surname"
                        placeholder="Surname"
                        minLength={2}
                        maxLength={75}
                        value={form.surname}
                        onChange={(e) =>
                            setForm({ ...form, surname: e.target.value })
                        }
                        required
                    />
                </FormGroup>
                <FormGroup
                    label="Job Title"
                    inline={true}
                    className="form-fill required"
                    labelFor="Job Title"
                >
                    <InputGroup
                        id="jobTitle"
                        placeholder="Job Title"
                        minLength={2}
                        maxLength={50}
                        value={form.jobTitle}
                        onChange={(e) =>
                            setForm({ ...form, jobTitle: e.target.value })
                        }
                        required
                    />
                </FormGroup>
                <FormGroup
                    label="Phone Number"
                    inline={true}
                    className="form-fill"
                    labelFor="Phone Number"
                >
                    <InputGroup
                        id="phone"
                        placeholder="Phone Number"
                        minLength={0}
                        maxLength={75}
                        value={form.phone}
                        onChange={(e) =>
                            setForm({ ...form, phone: e.target.value })
                        }
                    />
                </FormGroup>
                <FormGroup
                    label="Email"
                    inline={true}
                    className="form-fill"
                    labelFor="Email"
                >
                    <InputGroup
                        id="email"
                        readOnly={true}
                        placeholder="Email"
                        value={form.email}
                    />
                </FormGroup>
                <FormGroup>
                    <ButtonLink
                        type="button"
                        intent="default"
                        className="float-left"
                        to={'/profile'}
                    >
                        <Icon icon="ban" />
                        Cancel
                    </ButtonLink>
                    <Button
                        type="submit"
                        intent="primary"
                        className="float-right"
                    >
                        <Icon icon="paper-plane" />
                        {'Update'}
                    </Button>
                </FormGroup>
            </form>
        </div>
    );
};

export default ProfileEdit;
