import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Switch } from 'react-router-dom';
import { ProtectedRoute } from 'components/elements';
import CompanyServicesList from 'components/company/services/CompanyServicesList';
import CompanyServicesEdit from 'components/company/services/CompanyServicesEdit';

class CompanyServices extends Component
{
    static propTypes = {
        companyResponse: PropTypes.object.isRequired,
        mergeCompanyResponse: PropTypes.func,
    };

    static defaultProps = {};

    render()
    {
        const { companyResponse, match, mergeCompanyResponse } = this.props;

        return (
            <div className="CompanyServices">
                <Switch>
                    <ProtectedRoute path={`${match.url}`}
                        component={ CompanyServicesList }
                        companyResponse={ companyResponse }
                        exact />

                    <ProtectedRoute path={`${match.url}/edit`}
                        component={ CompanyServicesEdit }
                        companyResponse={ companyResponse }
                        mergeCompanyResponse={ mergeCompanyResponse }
                        exact />

                    <Redirect to={`${match.url}`} />
                </Switch>
            </div>
        );
    }
}

export default CompanyServices;
