import { Button, H2 } from '@blueprintjs/core';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { toast } from 'react-toastify';

import ConfirmPaymentDialog from 'components/clients/subscriptions/dialogs/ConfirmPaymentDialog';
import IssueInvoiceDialog from 'components/clients/subscriptions/dialogs/IssueInvoiceDialog';
import { ButtonLink, FileUpload, Icon } from 'components/elements';
import { SUBSCRIPTION_STATUSES } from 'constants/client';
import { getColourClass } from 'helpers/helpers';
import { HTTP, NumberFormatting, Response } from 'service';

const SubscriptionInfo = (props) => {
    const { clientResponse, reloadData, subscriptionDetails, match } = props;
    const [isOpenIssueDialogOpen, setIsOpenIssueDialogOpen] = useState(false);
    const [isConfirmPaymentDialogOpen, setIsConfirmPaymentDialogOpen] =
        useState(false);
    const [issueInvoiceform, setIssueInvoiceform] = useState({
        email: subscriptionDetails.email ?? subscriptionDetails.email,
        invoiceContactName:
            subscriptionDetails.invoiceContactName ??
            subscriptionDetails.invoiceContactName,
        addressLine1: clientResponse.addressLine1 ?? '',
        addressLine2: clientResponse.addressLine2 ?? '',
        addressLine3: clientResponse.addressLine3 ?? '',
        town: clientResponse.town ?? '',
        postCode: clientResponse.postCode ?? '',
        country: 'GB',
        purchaseOrderNumber: '',
        _links: {
            purchaseOrderFile: {},
            contract: null,
        },
    });
    const [confirmPaymentform, setConfirmPaymentform] = useState({
        paymentDate: new Date(),
        paymentId: '',
    });
    const issueInvoiceLink = Response.getLink(
        subscriptionDetails,
        'issue-invoice'
    );
    const confirmPaymentLink = Response.getLink(
        subscriptionDetails,
        'confirm-payment'
    );

    const openDialog = (dialog) => {
        if (dialog === 'issueInvoice') {
            setIsOpenIssueDialogOpen(true);
        } else {
            setIsConfirmPaymentDialogOpen(true);
        }
    };

    const closeDialog = () => {
        setIsOpenIssueDialogOpen(false);
        setIsConfirmPaymentDialogOpen(false);
    };

    const issueInvoiceHandler = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        try {
            const response = await HTTP.put(issueInvoiceLink, {
                ...issueInvoiceform,
            });

            if (response) {
                setIsOpenIssueDialogOpen(false);
                reloadData();

                toast.success(`Invoice Issued`);
            }
        } catch (error) {
            toast.error(`Unable to issue invoice`);
        }
    };

    const confirmPaymentHandler = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        try {
            const response = await HTTP.put(confirmPaymentLink, {
                ...confirmPaymentform,
            });

            if (response) {
                setIsConfirmPaymentDialogOpen(false);
                reloadData();

                toast.success('Payment Confirmed');
            }
        } catch (error) {
            toast.error(`Unable to confirm Payment`);
        }
    };

    const {
        validFrom,
        validUntil,
        level,
        status,
        chargeable,
        invoiceId,
        invoiceAmountExcludingTax,
        invoiceAmountIncludingTax,
        invoiceAmountTax,
        invoiceContactName,
        invoiceDate,
        purchaseOrderNumber,
        paymentId,
        paymentDate,
        email
    } = subscriptionDetails;

    return (
        <>
            <H2>
                Prosure Subscription
                {issueInvoiceLink && (
                    <>
                        <Button
                            type="button"
                            onClick={() => openDialog('issueInvoice')}
                            intent="primary"
                            className="float-right ms-1"
                        >
                            <Icon icon="pencil" />
                            Issue Invoice
                        </Button>
                        <IssueInvoiceDialog
                            onClose={closeDialog}
                            isDialogOpen={isOpenIssueDialogOpen}
                            setForm={setIssueInvoiceform}
                            form={issueInvoiceform}
                            onSubmithandler={issueInvoiceHandler}
                        ></IssueInvoiceDialog>
                    </>
                )}
                {confirmPaymentLink && (
                    <>
                        <Button
                            type="button"
                            onClick={() => openDialog('confirmPayment')}
                            intent="primary"
                            className="float-right ms-1"
                        >
                            <Icon icon="pencil" />
                            Confirm Payment
                        </Button>
                        <ConfirmPaymentDialog
                            onClose={closeDialog}
                            isDialogOpen={isConfirmPaymentDialogOpen}
                            form={confirmPaymentform}
                            setForm={setConfirmPaymentform}
                            onSubmithandler={confirmPaymentHandler}
                        ></ConfirmPaymentDialog>
                    </>
                )}
                {Response.getLink(subscriptionDetails, 'edit') && (
                    <>
                        <ButtonLink
                            type="button"
                            to={`${match.url}/edit`}
                            intent="primary"
                            className="float-right"
                        >
                            <Icon icon="pencil" />
                            Edit
                        </ButtonLink>
                    </>
                )}
            </H2>

            <dl className="grid medium">
                <dt>Valid From</dt>
                <dd>
                    {DateTime.fromISO(
                        validFrom
                    ).toLocaleString(DateTime.DATE_MED)}
                </dd>

                <dt>Valid Until</dt>
                <dd>
                    {DateTime.fromISO(
                        validUntil
                    ).toLocaleString(DateTime.DATE_MED)}
                </dd>

                <dt>Status</dt>
                <dd>
                    <span className={getColourClass(subscriptionDetails)}>
                        {SUBSCRIPTION_STATUSES[status]}
                    </span>
                </dd>

                <dt>Level</dt>
                <dd>{level}</dd>

                <dt>Chargeable</dt>
                <dd>{chargeable ? 'Yes' : 'No'}</dd>

                {chargeable && (
                    <>
                        {invoiceId && (
                            <>
                                <dt>Invoice Number</dt>
                                <dd>{invoiceId}</dd>
                            </>
                        )}

                        {invoiceAmountExcludingTax && (
                            <>
                                <dt>Invoice Amount Excluding Tax</dt>
                                <dd>
                                    {NumberFormatting.formatCurrency(
                                        invoiceAmountExcludingTax
                                    )}
                                </dd>
                            </>
                        )}

                        {invoiceAmountTax && (
                            <>
                                <dt>Invoice Amount Tax</dt>
                                <dd>
                                    {NumberFormatting.formatCurrency(
                                        invoiceAmountTax
                                    )}
                                </dd>
                            </>
                        )}

                        {invoiceAmountIncludingTax && (
                            <>
                                <dt>Invoice Amount Including Tax</dt>
                                <dd>
                                    {NumberFormatting.formatCurrency(
                                        invoiceAmountIncludingTax
                                    )}
                                </dd>
                            </>
                        )}

                        {invoiceDate && (
                            <>
                                <dt>Invoice Date</dt>
                                <dd>
                                    {DateTime.fromISO(
                                        invoiceDate
                                    ).toLocaleString(DateTime.DATE_MED)}
                                </dd>
                            </>
                        )}

                        {invoiceContactName && (
                            <>
                                <dt>Invoice Contact Name</dt>
                                <dd>
                                    {invoiceContactName}
                                </dd>
                            </>
                        )}

                        {email && (
                            <>
                                <dt>Invoice Email Address</dt>
                                <dd>{email}</dd>
                            </>
                        )}

                        {paymentDate && (
                            <>
                                <dt>Payment Date</dt>
                                <dd>
                                    {DateTime.fromISO(
                                        paymentDate
                                    ).toLocaleString(DateTime.DATE_MED)}
                                </dd>
                            </>
                        )}

                        {paymentId && (
                            <>
                                <dt>Payment ID</dt>
                                <dd>{paymentId}</dd>
                            </>
                        )}

                        {purchaseOrderNumber && (
                            <>
                                <dt>Purchase Order Number</dt>
                                <dd>
                                    {purchaseOrderNumber}
                                </dd>
                            </>
                        )}

                        {subscriptionDetails._links.purchaseOrderFile && (
                            <>
                                <dt>Purchase Order File</dt>
                                <dd>
                                    <FileUpload
                                        inputID="purchaseOrderFile"
                                        isEditable={false}
                                        onUpdate={() => {}}
                                        onUpload={() => {}}
                                        response={subscriptionDetails}
                                        responseLink="purchaseOrderFile"
                                        uploadDataObject={{}}
                                    />
                                </dd>
                            </>
                        )}

                        <>
                            <dt>Contract</dt>
                            <dd>
                                <FileUpload
                                    inputID="contract"
                                    isEditable={false}
                                    onUpdate={() => {}}
                                    onUpload={() => {}}
                                    response={subscriptionDetails}
                                    responseLink="contract"
                                    uploadDataObject={{}}
                                />
                            </dd>
                        </>
                    </>
                )}
            </dl>
        </>
    );
};

export default SubscriptionInfo;
