import { H1 } from '@blueprintjs/core';
import queryString from 'query-string';
import { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import NumberOfEmployeesPopup from 'components/NumberOfEmployeesPopup';
import Confirmation from 'components/dialogs/Confirmation';
import {
    ItemCount,
    LoadingIndicator,
    Paginator,
    Reload,
    Table,
} from 'components/elements';
import { Loading, NoResultsFound } from 'components/elements/wrappers';
import { toMoney } from 'helpers/helpers';
import { HTTP, Response } from 'service';

const mapStateToProps = (state) => {
    return {
        rootResponse: state.root.response,
    };
};
class AssessmentTypesList extends Component {
    onClickPurchase = (column, row) => {
        const { history } = this.props;
        const { inProgressCollection } = this.state;
        const hasExisting =
            inProgressCollection.assessmentTypeInstances &&
            inProgressCollection.assessmentTypeInstances.some(
                (instance) => instance.assessmentTypeId === row.id
            );
        if (hasExisting) {
            this.setState({ selectedRow: row, showPurchaseConfirmation: true });
        } else {
            this.clearSelectedRow();
            history.push(this.getRoute(row, column));
        }
    };

    columns = [
        {
            name: 'Assessment logo',
            property: 'file',
            type: 'image',
            sortable: false,
            grow: 2,
        },
        { name: 'Assessment name', property: 'name', sortable: true, grow: 4 },
        {
            name: 'Assessment description',
            property: 'description',
            sortable: false,
            grow: 5,
        },
        {
            name: 'Assessment Price',
            type: 'callback',
            callback: (row) => {
                return (
                    row.pricing &&
                    row.pricing.standardAssessmentFeeExcludingTax &&
                    toMoney(row.pricing.standardAssessmentFeeExcludingTax)
                );
            },
            sortable: false,
            grow: 2,
        },
        {
            name: '',
            property: '',
            type: 'button',
            grow: 1,
            onClick: this.onClickPurchase,
            intent: 'primary',
            text: 'Purchase',
            right: true,
            className: 'icon-free'
        },
    ];

    defaultFilters = {
        order: 'name',
        purchasable: '1',
        isActive: true,
        companySize: Response.getLinkAttribute(
            this.props.rootResponse,
            'company',
            'companySize'
        ),
    };

    constructor(props) {
        super(props);

        this.state = {
            collection: {},
            filter: queryString.parse(props.location.search),
            isLoading: false,
            inProgressCollection: void 0,
            showPurchaseConfirmation: false,
            selectedRow: void 0,
        };

        this.load = this.load.bind(this);
    }

    componentDidMount() {
        this.load();
    }

    getRoute(row, column) {
        return `/assessments/${row.id}/purchase`;
    }

    onConfirmPurchase = (event) => {
        const { history } = this.props;
        const { selectedRow } = this.state;

        history.push(this.getRoute(selectedRow));
        this.clearSelectedRow();
    };

    clearSelectedRow = () => {
        this.setState({ selectedRow: void 0, showPurchaseConfirmation: false });
    };

    render() {
        const { collection, isLoading, showPurchaseConfirmation } = this.state;

        return (
            <div className="AssessmentTypesList">
                <Confirmation
                    isOpen={showPurchaseConfirmation}
                    onYes={this.onConfirmPurchase}
                    onClose={this.clearSelectedRow}
                >
                    <div className="m-2">
                        You already have an assessment of this type 'In
                        Progress'. Please click 'Cancel' and check the 'In
                        Progress' tab, else click 'Confirm' to continue this
                        purchase.
                    </div>
                </Confirmation>
                <Loading isLoading={isLoading}>
                    <H1>
                        Assessment Types
                        {isLoading ? (
                            <LoadingIndicator inline={true} size="sm" />
                        ) : (
                            <ItemCount count={collection.count} />
                        )}
                        <Reload load={this.load} />
                    </H1>

                    <span>
                        The results in this list are limited based on the number
                        of effective employees set in your company profile. If
                        the correct assessment is not appearing, please change
                        this value in your company profile.
                    </span>
                    <br />
                    <br />

                    <NoResultsFound count={collection.count}>
                        <Table
                            data={collection.assessmentTypes}
                            columns={this.columns}
                            onSort={this.load}
                            ordering={this.state.filter.order}
                        />

                        <Paginator
                            page={collection.page}
                            count={collection.count}
                            limit={collection.limit}
                            onPage={this.load}
                        />
                    </NoResultsFound>
                </Loading>
                <NumberOfEmployeesPopup />
            </div>
        );
    }

    load(params = {}) {
        if (this.state.isLoading) {
            return;
        }

        const filterParams = Object.assign(
            {},
            this.defaultFilters,
            this.props.filter,
            this.state.filter,
            params
        );
        let queryStringFilterParams = queryString.stringify(filterParams);
        if (
            this.props.location.search &&
            '?' + queryStringFilterParams !== this.props.location.search
        ) {
            this.props.history.push({ search: queryStringFilterParams });
        }

        this.setState({ isLoading: true, filter: filterParams });

        HTTP.get('/assessment-types', filterParams).then((response) => {
            if (response) {
                this.setState({ isLoading: false, collection: response.data });

                return true;
            }

            toast.error('Unable to fetch Assessment Types');
            this.setState({ isLoading: false });

            return false;
        });

        HTTP.action('get', '/assessment-type-instances', {
            status: 'in-progress',
        }).then((response) => {
            if (!response) {
                toast.error('Unable to fetch current in-progress assessments');
                return;
            }

            this.setState({ inProgressCollection: response.data });
        });
    }
}

export default connect(mapStateToProps, {})(AssessmentTypesList);
