import { FormEvent, useState } from 'react';
import { Button, FormGroup, H1, InputGroup } from '@blueprintjs/core';

import { ButtonLink, Icon, InputElementIcon } from 'components/elements';
import {
    IntegrationData,
    IntegrationsComponentProps,
} from 'interface/Integrations';

import {
    createIntegrationObject,
    redirectToIntegration,
} from 'service/Integration/IntegrationService';

import { Routing } from 'service';

const IntegrationAdd = (props: Partial<IntegrationsComponentProps>) => {
    const { history, integrationType, match } = props;
    const [form, setForm] = useState<IntegrationData>(
        createIntegrationObject(integrationType)
    );

    const integrationInfo = (
        <>
            {`${integrationType} Client URL`},
            {InputElementIcon(`Example: https://acme.v2.elogbooks.net`, 'info')}
        </>
    );

    // allow for prod/sandpit/test or dev envs
    const pattern = `(http(s?)://)[A-Za-z0-9]+(.sandpit)?.v2.elogbooks.net(/)?|http://ma.local.v2.elogbooks.net(/)?|http://ewv-[0-9]+\\.testing.v2.elogbooks.net(/)?$`;
    const patternMsg = `Requires valid ${integrationType} URL e.g. https://acme.v2.elogbooks.net`;

    return (
        <div>
            <H1>Add Integration</H1>

            <form
                className="AddEdit"
                onSubmit={(e: FormEvent<HTMLFormElement>) =>
                    redirectToIntegration(e, form)
                }
            >
                <FormGroup
                    label={integrationInfo}
                    inline={true}
                    className="form-fill required"
                    labelFor="Integration URL"
                >
                    <InputGroup
                        id="code"
                        pattern={pattern}
                        title={patternMsg}
                        placeholder={patternMsg}
                        minLength={5}
                        maxLength={200}
                        value={form.integrationURL}
                        onChange={(e) => {
                            setForm({
                                ...form,
                                integrationURL: e.target.value,
                            });
                        }}
                        required
                    />
                </FormGroup>

                <FormGroup>
                    <ButtonLink
                        type="button"
                        intent="default"
                        className="float-left"
                        to={Routing.getRouteUpOneLevel(history, match, null)}
                    >
                        <Icon icon="ban" />
                        Cancel
                    </ButtonLink>
                    <Button
                        type="submit"
                        intent="primary"
                        className="float-right"
                    >
                        <Icon icon="paper-plane" />
                        Create
                    </Button>
                </FormGroup>
            </form>
        </div>
    );
};

export default IntegrationAdd;
