import { Switch } from '@blueprintjs/core';
import { Option } from 'interface';
import { ComponentProps, FormEvent, useEffect, useState } from 'react';

interface MultiSwitchToggleProps extends ComponentProps<any> {
    options: Option[];
    onUpdate?: Function;
    onlyOneActive?: boolean;
}

const MultiSwitchToggle = (props: MultiSwitchToggleProps) => {
    const { onlyOneActive, onUpdate, options, clear } = props;
    const [theOptions, setOptions] = useState(options);

    useEffect(() => {
        if (typeof clear !== 'boolean') {
            return;
        }
        setOptions(
            theOptions.map((option) => {
                option.value = false;
                return option;
            })
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clear]);

    const onChange = (event: FormEvent) => {
        const target = event.target as HTMLInputElement;
        const oldOption = theOptions.find((option) => option.id === target.id);

        const newOptions = theOptions.map((option) => {
            // if only one should be active, make all off
            if (!!onlyOneActive) {
                option.value = false;
            }

            return option.id !== target.id
                ? option
                : Object.assign({}, oldOption, {
                      value: target.checked,
                  });
        });

        setOptions(newOptions);
        onUpdate && typeof onUpdate === 'function' && onUpdate(newOptions);
    };

    return (
        <div className="multi-switch-toggle">
            {theOptions.map((option, index) => {
                return (
                    <Switch
                        className="mb-2"
                        key={'switch-' + index}
                        id={option.id ?? 'switch-' + index}
                        label={option.label ?? 'Selection ' + index}
                        alignIndicator={'right'}
                        checked={option.value}
                        onChange={onChange}
                    />
                );
            })}
        </div>
    );
};

export default MultiSwitchToggle;
