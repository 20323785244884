import { FormEvent, useState } from 'react';
import { toast } from 'react-toastify';
import {
    Button,
    Classes,
    Dialog,
    FormGroup,
    TextArea,
} from '@blueprintjs/core';

import { Response, HTTP } from 'service';
import { CompanyData } from 'interface';
import './index.scss';

interface SuspendServiceProviderReasonDialogProps {
    company: CompanyData;
    reload: { (): void };
}

const SuspendServiceProviderReasonDialog = (
    props: SuspendServiceProviderReasonDialogProps
) => {
    const { company, reload } = props;
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [suspensionReason, setSuspensionReason] = useState<string>('');

    const closeDialog = () => {
        setSuspensionReason('');
        setIsDialogOpen(false);
    };

    const openDialog = () => {
        setIsDialogOpen(true);
    };

    const submit = async (event: FormEvent<HTMLFormElement>) => {
        event.stopPropagation();
        event.preventDefault();

        const suspendLink = Response.getLink(company, 'suspend-company');
        if (suspendLink) {
            await HTTP.patch(suspendLink, { reason: suspensionReason })
                .then(() => {
                    toast.success('Successfully suspended company');
                    closeDialog();
                    reload();
                })
                .catch((error: any) => {
                    const { response } = error;

                    if (response?.data && response.data.length > 0) {
                        toast.error(response.data[0].message);
                    } else {
                        toast.error('Unable to suspend company');
                    }
                });
        } else {
            closeDialog();
        }
    };

    return (
        <>
            <Button
                onClick={openDialog}
                intent="warning"
                title="Suspend Service Provider"
            >
                Suspend Service Provider
            </Button>
            <Dialog
                title="Suspend Service Provider"
                className="suspension-reason-modal"
                onClose={closeDialog}
                autoFocus={true}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                usePortal={true}
                isOpen={isDialogOpen}
            >
                <form
                    onSubmit={(event: FormEvent<HTMLFormElement>) =>
                        submit(event)
                    }
                >
                    <div className={Classes.DIALOG_BODY}>
                        <FormGroup
                            label="Reason"
                            inline={true}
                            className="form-fill required"
                            labelFor="Reason"
                        >
                            <TextArea
                                placeholder="Reason"
                                minLength={1}
                                maxLength={1000}
                                value={suspensionReason}
                                onChange={(
                                    e: FormEvent<HTMLTextAreaElement>
                                ) => {
                                    const { value } =
                                        e.target as HTMLTextAreaElement;
                                    setSuspensionReason(value);
                                }}
                                required
                            />
                        </FormGroup>
                        <FormGroup className={Classes.DIALOG_FOOTER}>
                            <Button
                                type="button"
                                className="float-left"
                                onClick={closeDialog}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                intent="success"
                                className="float-right"
                            >
                                Confirm
                            </Button>
                        </FormGroup>
                    </div>
                </form>
            </Dialog>
        </>
    );
};

export default SuspendServiceProviderReasonDialog;
