import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { updatingBreadcrumbResolves } from 'action/breadcrumb';
import { Card, H1, H2 } from '@blueprintjs/core';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Loading, NoResultsFound } from 'components/elements/wrappers';
import { ButtonLink, Icon, Reload } from 'components/elements';
import { DragDrop, HTTP, Response } from 'service';

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = {
    updatingBreadcrumbResolves: updatingBreadcrumbResolves,
};

class QuestionCategoryDetails extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            id: props.match.params.id,
            isLoading: false,
            isLoadingSubSection: false,
            response: {},
            subSectionResponse: {},
        };

        this.load = this.load.bind(this);
        this.loadQuestions = this.loadQuestions.bind(this);
        this.getQuestions = this.getQuestions.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    componentDidMount()
    {
        this.props.updatingBreadcrumbResolves({questionCategory: undefined});
        this.load();
    }

    render()
    {
        const { isLoading, response } = this.state;
        const { match } = this.props;
        const questions = this.getQuestions();

        return (
            <div className="QuestionCategoryDetails">
                <Loading isLoading={ isLoading }>
                    <H1 className="clearfix">
                        { response.name }
                        <Reload load={ this.load } />

                        {
                            Response.getLink(response, 'edit') === null ?
                                null :
                                (
                                    <ButtonLink type="button"
                                        to={`${match.url}/edit`}
                                        intent="primary"
                                        className="float-right">
                                        <Icon icon="pencil" />
                                        Edit
                                    </ButtonLink>
                                )
                        }
                    </H1>

                    <H2 className="clearfix">
                        Questions
                    </H2>

                    <div className="QuestionCategoryQuestions">
                        <DragDropContext
                            onDragStart={ this.onDragStart }
                            onDragUpdate={ this.onDragUpdate }
                            onDragEnd={ this.onDragEnd }>
                            <Droppable droppableId={1}>
                                { provided => {
                                    return (
                                        <div className="Droppable"
                                            ref={ provided.innerRef }
                                            { ...provided.droppableProps }>
                                            <Loading isLoading={ this.state.isLoadingSubSection }>
                                                <NoResultsFound count={ this.state.subSectionResponse.count }>
                                                    { questions }
                                                </NoResultsFound>
                                            </Loading>

                                            { provided.placeholder }
                                        </div>
                                    );
                                } }
                            </Droppable>
                        </DragDropContext>
                    </div>
                </Loading>
            </div>
        );
    }

    onDragEnd(result)
    {
        let newQuestions = DragDrop.getSingularReorderedList(result, this.state.subSectionResponse.questions);

        if (newQuestions) {
            this.setState({subSectionResponse: Object.assign({}, this.state.subSectionResponse, {questions: newQuestions})});

            let questionOrder = newQuestions.map((question) => {
                return question.id;
            });

            HTTP
                .put(`/question/categories/${this.state.id}/question-order`, {
                    questionOrder: questionOrder,
                })
                .then((response) => {
                    if (response) {
                        toast.success('Question Order Updated');

                        return true;
                    }

                    toast.error('Unable to update Question Order');

                    return false;
                });
        }
    }

    getQuestions()
    {
        const { subSectionResponse } = this.state;
        const cards = [];

        if (subSectionResponse &&
            Array.isArray(subSectionResponse.questions)) {
            subSectionResponse.questions.forEach((question,  index) => {
                cards.push((
                    <Draggable draggableId={ question.id }
                        index={ index }
                        key={ question.id }>
                        { provided => {
                            return (
                                <div ref={ provided.innerRef }
                                    key={ question.id }
                                    { ...provided.draggableProps }>
                                    <Card>
                                        <span { ...provided.dragHandleProps }>
                                            <Icon icon="grip-vertical" />
                                        </span>
                                        { question.summary }
                                    </Card>
                                </div>
                            );
                        } }
                    </Draggable>
                ));
            });
        }

        return cards;
    }

    load(params = {})
    {
        if (this.state.isLoading) {
            return;
        }

        this.setState({isLoading: true});

        HTTP
            .get(`/question/categories/${this.state.id}`)
            .then((response) => {
                if (response) {
                    this.setState({isLoading: false, response: response.data}, this.loadQuestions);
                    this.props.updatingBreadcrumbResolves({questionCategory: {name: response.data.name}});

                    return true;
                }

                toast.error('Unable to fetch Question Category');
                this.setState({isLoading: false});

                return false;
            });
    }

    loadQuestions(params = {})
    {
        if (this.state.isLoadingSubSection) {
            return;
        }

        this.setState({isLoadingSubSection: true});

        HTTP
            .get(`/questions`, {
                categoryId: this.state.id,
                order: 'order',
                limit: 999,
            })
            .then((response) => {
                if (response) {
                    this.setState({isLoadingSubSection: false, subSectionResponse: response.data});

                    return;
                }
            });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionCategoryDetails);
