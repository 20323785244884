
import { Redirect, Switch } from 'react-router-dom';

import { ProtectedRoute } from 'components/elements';
import AssessmentTypesList from 'components/assessment-types/AssessmentTypesList';
import { CompanyData } from 'interface';

interface ShopProps {
    companyResponse: CompanyData;
    match: any;
};

const Shop = (props: ShopProps) => {
    const { companyResponse, match } = props;

    return (
        <div className="page">
            <div className="page-wrapper no-styling">
                <h1 className="bp3-heading">Shop</h1>
                <Switch>
                    <ProtectedRoute
                        path={`${match.url}`}
                        component={AssessmentTypesList}
                        companyResponse={companyResponse}
                        title="Shop"
                        exact
                    />
                    <Redirect to={`${match.url}`} />
                </Switch>
            </div>
        </div>
    );
};

export default Shop;