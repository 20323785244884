import { Component } from 'react';
import moment from 'moment';
import queryString from 'query-string';
import { H1, Card } from '@blueprintjs/core';
import { toast } from 'react-toastify';
import { Loading } from 'components/elements/wrappers';
import { ItemCount, Reload, Paginator } from 'components/elements';
import { HTTP, Response } from 'service';

class AssessmentTypeHistory extends Component {
    defaultFilters = {
        order: '-createdAt',
    };

    constructor(props) {
        super(props);

        this.state = {
            collectionResponse: {
                events: [],
            },
            filter: queryString.parse(props.location.search),
            isLoading: false,
            events: [],
        };

        this.load = this.load.bind(this);
    }

    componentDidMount() {
        this.load();
    }

    render() {
        const collection = this.state.collectionResponse || {};

        const mappedEvents = this.state.collectionResponse.events.map(
            (event, index) => {
                let eventType;
                let eventUser = 'System user';
                let createdAt = moment(event.createdAt).format(
                    'MMMM Do YYYY H:mm'
                );
                let metaData = null;
                if (event.metaData) {
                    let metaDatafields = event.metaData.map((field, index) => {
                        if ('company' in field) {
                            return (
                                <>
                                    <div key={`${index}-company`}>
                                        Purchased for{' '}
                                        <span>{field.company.toString()}</span>
                                    </div>

                                    {'supplyChainClient' in field && (
                                        <div key={`${index}-client`}>
                                            Raised on Supply Chain Accept invite
                                            from{' '}
                                            <span>
                                                {field.supplyChainClient.toString()}
                                            </span>
                                        </div>
                                    )}

                                    {'supplyChainCategory' in field && (
                                        <div
                                            key={`${index}-supplyChainCategory`}
                                        >
                                            For SP Category{' '}
                                            <span>
                                                {field.supplyChainCategory.toString()}
                                            </span>
                                        </div>
                                    )}

                                    {'stripeId' in field && (
                                        <div key={`${index}-stripe`}>
                                            Stripe Id:{' '}
                                            <span>{field.stripeId}</span>
                                        </div>
                                    )}

                                    {'amount' in field && (
                                        <div key={`${index}-amount`}>
                                            Amount:{' '}
                                            <span>&pound;{field.amount}</span>
                                        </div>
                                    )}
                                </>
                            );
                        } else if ('fromValue' in field && 'toValue' in field) {
                            return (
                                <div key={index}>
                                    <span>{field.Field}</span> changed from{' '}
                                    <span>{field.fromValue.toString()}</span>,
                                    to <span>{field.toValue.toString()}</span>
                                </div>
                            );
                        } else if ('fromValue' in field) {
                            return (
                                <div key={index}>
                                    <span>{field.Field}</span> cleared
                                </div>
                            );
                        } else if (
                            'fromVersion' in field &&
                            'toVersion' in field
                        ) {
                            return (
                                <div key={index}>
                                    <span>Version</span> changed from{' '}
                                    <span>{field.fromVersion.toString()}</span>,
                                    to <span>{field.toVersion.toString()}</span>
                                </div>
                            );
                        } else if ('toValue' in field) {
                            return (
                                <div key={index}>
                                    <span>{field.Field}</span> added:{' '}
                                    <span>{field.toValue.toString()}</span>
                                </div>
                            );
                        }

                        return null;
                    });

                    metaData = (
                        <div>
                            <dt>Fields updated:</dt>
                            <dd className="historyFields">
                                {' '}
                                {metaDatafields}{' '}
                            </dd>
                        </div>
                    );
                }

                switch (event.type) {
                    case 'assessment_purchased':
                        eventType = 'Assessment Type Purchased';
                        break;
                    case 'assessment_updated':
                        eventType = 'Assessment Type Updated';
                        break;
                    case 'assessment_questions_updated':
                        eventType = 'Assessment Type Questions Updated';
                        break;
                    case 'assessment_pricing_updated':
                        eventType = 'Assessment Type Pricing Updated';
                        break;
                    case 'assessment_created_from_version':
                        eventType = 'Assessment Type Created From Version';
                        break;
                    case 'assessment_activated':
                        eventType = 'Assessment Type Activated';
                        break;
                    case 'assessment_deactivated':
                        eventType = 'Assessment Type Deactivated';
                        break;
                    default:
                        eventType = 'Assessment Type Created';
                }

                if (event._links) {
                    eventUser = event._links.user.username;
                }

                return (
                    <Card key={event.id}>
                        <div>
                            <dt>Event Type:</dt>
                            <dd>{eventType}</dd>
                        </div>
                        <div>
                            <dt>Raised by:</dt>
                            <dd>{eventUser}</dd>
                        </div>
                        <div>
                            <dt>Raised On:</dt>
                            <dd>{createdAt}</dd>
                        </div>
                        {metaData}
                    </Card>
                );
            }
        );

        return (
            <div className="AssessmentTypeList">
                <H1 className="clearfix">
                    History
                    <ItemCount count={collection.count} />
                    <Reload load={this.load} />
                </H1>

                <Loading isLoading={this.state.isLoading}>
                    <div className="events">{mappedEvents}</div>

                    <Paginator
                        page={collection.page}
                        count={collection.count}
                        limit={collection.limit}
                        onPage={this.load}
                    />
                </Loading>
            </div>
        );
    }

    load(params = {}) {
        if (this.state.isLoading) {
            return;
        }

        this.setState({ isLoading: true });

        const filterParams = Object.assign(
            {},
            this.defaultFilters,
            this.props.filter,
            this.state.filter,
            params
        );
        let queryStringFilterParams = queryString.stringify(filterParams);
        if (
            this.props.location.search &&
            '?' + queryStringFilterParams !== this.props.location.search
        ) {
            this.props.history.push({ search: queryStringFilterParams });
        }

        this.setState({ isLoading: true, filter: filterParams });

        const getUrl = Response.getLink(
            this.props.assessmentTypeResponse,
            'events'
        );

        HTTP.get(getUrl, filterParams).then(
            (response) => {
                this.setState({
                    isLoading: false,
                    collectionResponse: response.data,
                });
                return true;
            },
            (response) => {
                this.setState({ isLoading: false });
                toast.error('Unable to fetch a list of Assessment Type Events');
                return false;
            }
        );
    }
}

export default AssessmentTypeHistory;
