const routes = {
    admin_certification_memberships: {
        href: '/admin/certification-memberships',
        text: 'Certification & Memberships',
    },
    admin_certification_memberships_details: {
        href: '/admin/certification-memberships/:certificationMembershipId',
        text: '{certificationMembership.name}',
        skipNavigation: true,
    },
    admin_certification_memberships_details_summary: {
        href: '/admin/certification-memberships/:certificationMembershipId/summary',
        text: 'Summary',
    },
};

export default routes;
