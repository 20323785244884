export * from './AnalyticalData';
export * from './AssessmentType';
export * from './AssessmentType/Instance';
export * from './AssessmentType/SLAS';
export * from './Benefits';
export * from './Cafm';
export * from './Client';
export * from './ClientCompanyContract';
export * from './Company';
export * from './Discount';
export * from './input/ButtonInput';
export * from './Insurance';
export * from './List';
export * from './Sticker';
export * from './SupplyChain';
export * from './Task';
export * from './User';

export interface FlatObject {
    [property: string]: any;
}

export interface ListData {
    count: number;
    page: number;
    limit: number;
    pages: number;
    _links: FlatObject;
}

export const emptyListData = {
    count: 0,
    page: 1,
    limit: 10,
    pages: 0,
    _links: {},
};

export interface ErrorResponseRow {
    property_path: string;
    message: string;
}

export interface Option {
    value: any;
    key?: string;
    label?: string;
    id?: any;
}

// taken from Redux
export type DeepPartial<T> = {
    [K in keyof T]?: T[K] extends object ? DeepPartial<T[K]> : T[K];
};
