import { H2, H3, Tab, Tabs } from '@blueprintjs/core';
import { useAsyncEffect } from '@dwarvesf/react-hooks';
import { useReducer } from 'react';
import { toast } from 'react-toastify';

import { camelCase, hasCreditRatingImageAvailable } from 'helpers/helpers';
import { FlatObject } from 'interface';
import { HTTP, Response } from 'service';
import RfaActiveDirectors from './red-flag-metadata/ActiveDirectors';
import RfaBalanceSheet from './red-flag-metadata/BalanceSheet';
import RfaGeneral from './red-flag-metadata/General';
import RfaProfitLoss from './red-flag-metadata/ProfitLoss';
import RfaSicCodes from './red-flag-metadata/SicCodes';

interface redFlagState {
    hasCreditInfo: boolean;
    hasCreditRatingImageAvailable: boolean;
    rating: {
        rfaRating: string;
        metadata: FlatObject;
    };
}

const RedFlagSection = (props: any) => {
    const { companyResponse } = props;

    const [state, setState]: [
        state: redFlagState,
        setState: React.ReducerWithoutAction<any>
    ] = useReducer(
        (state: any, edits: any) => {
            return { ...state, ...edits };
        },
        {
            hasCreditInfo: false,
            rating: undefined,
        }
    );

    const { hasCreditInfo, rating } = state;

    const rfaRatingDescription = () => {
        switch (rating?.rfaRating) {
            case 'GOLD':
                return (
                    <p>
                        These are the top rated companies and carry the lowest
                        amount of risk going insolvent.
                        <br />
                        <br />
                        These companies have the strongest key financial ratios,
                        have an excellent history of filing accounts on and
                        compliance on time and have extremely healthy all-round
                        financials.
                        <br />
                        <br />
                        The chance of a gold company failing is less than 0.1%
                        and Gold companies will make up between 12-14% of all UK
                        companies at any given time.
                    </p>
                );
            case 'SILVER':
                return (
                    <p>
                        Silver companies, whilst not a strong as Gold, are solid
                        companies representing a low risk and have a low chance
                        of going insolvent.
                        <br />
                        <br />
                        These companies still have strong financial ratios but
                        may have slightly higher gearing or lower liquidity,
                        efficiency or profitability ratios than ideal.
                        <br />
                        <br />
                        These companies are still very unlikely to fail but are
                        less financially stable than gold companies. Silver
                        companies will make up around 25% of UK companies at any
                        given time.
                    </p>
                );
            case 'BRONZE':
                return (
                    <p>
                        Bronze companies have passed our scorecard and are
                        deemed as healthy but are the lowest band to have done
                        so.
                        <br />
                        <br />
                        These companies still have a low chance of failure but
                        are less financially stable as Gold and Silver.
                        <br />
                        <br />
                        A Bronze company may have some suboptimal financial
                        ratios (eg. gearing, efficiency, liquidity and
                        profitability) that have held them back form a higher
                        rating.
                        <br />
                        <br />
                        These companies are in acceptable health and nothing
                        significantly detrimental is known, though there may be
                        some history of legal notices but are considered to be a
                        fair trade risk and recommended for open credit. Between
                        35-40% of UK companies will be Bronze rated at any given
                        time.
                    </p>
                );
            case 'ONE_RED_FLAG':
                return (
                    <p>
                        1 Red Flag companies have not passed our scorecard's
                        threshold to be described as healthy. They have a much
                        higher chance of failure than Bronze companies and
                        represent a moderate risk.
                        <br />
                        <br />
                        A 1 Red Flag company may be in the process of an ongoing
                        downward slide into insolvency or may have held a 1 Red
                        Flag rating for an extended period of time. If the
                        latter is the case then this is typically due to
                        connected parties giving unsecured loans, leading to the
                        business being well-funded and unlikely to fail despite
                        the poor financial performance. These loans will
                        typically make the balance sheet of these companies
                        appear even worse while allowing the company to avoid
                        insolvency and continue trading; this may be the case
                        for many startups.
                        <br />
                        <br />
                        There may be evidence of recent or significant legal
                        notices and the risk is elevated, so suppliers should
                        seek suitable assurances or guarantees. 1 Red Flag
                        companies are among the weakest 20% of UK companies.
                    </p>
                );
            case 'TWO_RED_FLAGS':
                return (
                    <p>
                        2 Red Flag companies have a high chance of failure and
                        represent a significant risk.
                        <br />
                        <br />
                        Whilst a 2 Red Flag company may be able to trade back to
                        financial health they are more likely to fail. Any
                        credit offered is done so at extreme risk.
                        <br />
                        <br />
                        There will likely be evidence of recent and/or
                        significant legal notices. 2 Red Flag companies are in
                        the weakest 20% of UK companies.
                    </p>
                );
            case 'THREE_RED_FLAGS':
                return (
                    <p>
                        3 Red Flag Companies represent the most significant
                        financial risk and are on the verge of being declared
                        insolvent.
                        <br />
                        <br />
                        There is likely to be recent and/or significant legal
                        notices, recent and/or significant count court
                        judgements and will have a deteriorating financial
                        position.
                        <br />
                        <br />
                        No credit should be extended. 3 Red Flag companies will
                        makeup the weakest 1% of UK companies.
                    </p>
                );
            case 'NEWLY_INCORPORATED':
                return (
                    <p>
                        These companies are newly incorporated and have not
                        filed accounts, as such we do not have the data required
                        to score them, and they remain in this holding rating
                        until the point at which they have the information
                        necessary to score.
                    </p>
                );
            case 'PRE_INSOLVENT':
                return (
                    <p>
                        Pre-insolvent companies usually are in the early stages
                        of formally declaring or being declared insolvent and in
                        98% of circumstances will fail within 28 days. This
                        rating is a pre-cursor to insolvency and as such we
                        would not recommend credit in any circumstance.
                    </p>
                );
            case 'PROVISIONAL_BRONZE':
                return (
                    <>
                        <p>
                            Provisional ratings can be applied to Silver,
                            Bronze, 1 Red Flag and 2 Red Flags when a company
                            has only one set of filed accounts.
                            <br />
                            <br />
                            Any company with a provisional rating should be
                            considered as slightly more of a credit risk than
                            company with the equivalent non-provisional rating.
                        </p>
                        <p>
                            Bronze companies have passed our scorecard and are
                            deemed as healthy but are the lowest band to have
                            done so.
                            <br />
                            <br />
                            These companies still have a low chance of failure
                            but are less financially stable as Gold and Silver.
                            <br />
                            <br />
                            A Bronze company may have some suboptimal financial
                            ratios (eg. gearing, efficiency, liquidity and
                            profitability) that have held them back form a
                            higher rating.
                            <br />
                            <br />
                            These companies are in acceptable health and nothing
                            significantly detrimental is known, though there may
                            be some history of legal notices but are considered
                            to be a fair trade risk and recommended for open
                            credit. Between 35-40% of UK companies will be
                            Bronze rated at any given time.
                        </p>
                    </>
                );
            case 'PROVISIONAL_SILVER':
                return (
                    <>
                        <p>
                            Provisional ratings can be applied to Silver,
                            Bronze, 1 Red Flag and 2 Red Flags when a company
                            has only one set of filed accounts.
                            <br />
                            <br />
                            Any company with a provisional rating should be
                            considered as slightly more of a credit risk than
                            company with the equivalent non-provisional rating.
                        </p>
                        <p>
                            Silver companies, whilst not a strong as Gold, are
                            solid companies representing a low risk and have a
                            low chance of going insolvent.
                            <br />
                            <br />
                            These companies still have strong financial ratios
                            but may have slightly higher gearing or lower
                            liquidity, efficiency or profitability ratios than
                            ideal.
                            <br />
                            <br />
                            These companies are still very unlikely to fail but
                            are less financially stable than gold companies.
                            Silver companies will make up around 25% of UK
                            companies at any given time.
                        </p>
                    </>
                );
            case 'PROVISIONAL_ONE_RED_FLAG':
                return (
                    <>
                        <p>
                            Provisional ratings can be applied to Silver,
                            Bronze, 1 Red Flag and 2 Red Flags when a company
                            has only one set of filed accounts.
                            <br />
                            Any company with a provisional rating should be
                            considered as slightly more of a credit risk than
                            company with the equivalent non-provisional rating.
                        </p>
                        <p>
                            1 Red Flag companies have not passed our scorecard's
                            threshold to be described as healthy. They have a
                            much higher chance of failure than Bronze companies
                            and represent a moderate risk.
                            <br />
                            <br />
                            A 1 Red Flag company may be in the process of an
                            ongoing downward slide into insolvency or may have
                            held a 1 Red Flag rating for an extended period of
                            time. If the latter is the case then this is
                            typically due to connected parties giving unsecured
                            loans, leading to the business being well-funded and
                            unlikely to fail despite the poor financial
                            performance. These loans will typically make the
                            balance sheet of these companies appear even worse
                            while allowing the company to avoid insolvency and
                            continue trading; this may be the case for many
                            startups.
                            <br />
                            <br />
                            There may be evidence of recent or significant legal
                            notices and the risk is elevated, so suppliers
                            should seek suitable assurances or guarantees. 1 Red
                            Flag companies are among the weakest 20% of UK
                            companies.
                        </p>
                    </>
                );
            case 'PROVISIONAL_TWO_RED_FLAGS':
                return (
                    <>
                        <p>
                            Provisional ratings can be applied to Silver,
                            Bronze, 1 Red Flag and 2 Red Flags when a company
                            has only one set of filed accounts.
                            <br />
                            Any company with a provisional rating should be
                            considered as slightly more of a credit risk than
                            company with the equivalent non-provisional rating.
                        </p>
                        <p>
                            2 Red Flag companies have a high chance of failure
                            and represent a significant risk.
                            <br />
                            <br />
                            Whilst a 2 Red Flag company may be able to trade
                            back to financial health they are more likely to
                            fail. Any credit offered is done so at extreme risk.
                            <br />
                            <br />
                            There will likely be evidence of recent and/or
                            significant legal notices. 2 Red Flag companies are
                            in the weakest 20% of UK companies.
                        </p>
                    </>
                );
        }
    };

    useAsyncEffect(async () => {
        const link = Response.getLink(companyResponse, 'credit-health-check');
        if (link) {
            try {
                let response = await HTTP.get(link);
                const data = response?.data;
                if (data) {
                    data.metadata = JSON.parse(data.metadata);
                }

                setState({
                    rating: data,
                    hasCreditInfo: true,
                });
            } catch (error) {
                toast.error('Unable to load Credit Health Check');
            }
        }

        return () => {
            setState({
                hasCreditInfo: false,
                rating: undefined,
            });
        };
    }, []);

    return (
        <>
            <div className="credit-health-check-header-container">
                <H2>
                    <img
                        src={'/assets/images/redflag/redflag-title.png'}
                        alt={'Redflag Logo'}
                    />
                </H2>
                <p>
                    Financial health and performance data is sourced from Red
                    Flag Alert automatically
                </p>
            </div>
            <div className="rfa-container">
                {hasCreditInfo ? (
                    <div className="top-section">
                        <div className="rfa-header">
                            {hasCreditRatingImageAvailable(
                                rating?.rfaRating
                            ) && (
                                <div className="rating">
                                    <img
                                        src={`/assets/images/redflag/${rating?.rfaRating}.png`}
                                        alt="Credit Rating Logo"
                                    />
                                </div>
                            )}
                            <div className="description">
                                <H3>
                                    Red Flag Alert Rating :{' '}
                                    {camelCase(rating?.rfaRating)}
                                </H3>
                                <div>{rfaRatingDescription()}</div>
                            </div>
                        </div>
                        <div className="rfa-metadata full-width">
                            <Tabs className="full-width">
                                <Tab
                                    id="rfa-general"
                                    title="General"
                                    panel={
                                        <RfaGeneral data={rating?.metadata} />
                                    }
                                />
                                <Tab
                                    id="rfa-sic-codes"
                                    title="SIC Codes"
                                    panel={
                                        <RfaSicCodes data={rating?.metadata} />
                                    }
                                />
                                <Tab
                                    id="rfa-profit-loss"
                                    title="Profit / Loss"
                                    panel={
                                        <RfaProfitLoss
                                            data={
                                                rating?.metadata['profit_loss']
                                            }
                                        />
                                    }
                                />
                                <Tab
                                    id="rfa-balance-sheet"
                                    title="Balance Sheet"
                                    panel={
                                        <RfaBalanceSheet
                                            data={
                                                rating?.metadata[
                                                    'balance_sheet'
                                                ]
                                            }
                                        />
                                    }
                                />
                                <Tab
                                    id="rfa-active-directors"
                                    title="Active Directors"
                                    panel={
                                        <RfaActiveDirectors
                                            data={
                                                rating?.metadata[
                                                    'active_directors'
                                                ]
                                            }
                                        />
                                    }
                                />
                            </Tabs>
                        </div>
                    </div>
                ) : (
                    <div className="no-data">
                        <p>No data is available for this Service Provider</p>
                    </div>
                )}
            </div>
        </>
    );
};

export default RedFlagSection;
