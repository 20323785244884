import { NONE_SELECTED_OPTION } from 'constants/none-selected';

export enum RefundReason {
    DUPLICATE_PURCHASE = 1,
    NO_LONGER_REQUIRED,
    PURCHASED_INCORRECT_ASSESSMENT,
    SSIP_DTS_RECOMMENDATION,
    OTHER,
    CLIENT_REQUEST,
    SERVICE_PROVIDER_REQUEST,
}

export const RefundReasonMappings = {
    1: 'Duplicate Purchase',
    2: 'No Longer Required',
    3: 'Purchased Incorrect Assessment',
    4: 'SSIP DTS Recommendation',
    5: 'Other',
    6: 'Client Request',
    7: 'Service Provider Request',
};

export const REFUND_REASON_OPTIONS = [
    NONE_SELECTED_OPTION,
    {
        label: RefundReasonMappings[RefundReason.DUPLICATE_PURCHASE],
        value: RefundReason.DUPLICATE_PURCHASE,
    },
    {
        label: RefundReasonMappings[RefundReason.NO_LONGER_REQUIRED],
        value: RefundReason.NO_LONGER_REQUIRED,
    },
    {
        label: RefundReasonMappings[
            RefundReason.PURCHASED_INCORRECT_ASSESSMENT
        ],
        value: RefundReason.PURCHASED_INCORRECT_ASSESSMENT,
    },
    {
        label: RefundReasonMappings[RefundReason.SSIP_DTS_RECOMMENDATION],
        value: RefundReason.SSIP_DTS_RECOMMENDATION,
    },
    {
        label: RefundReasonMappings[RefundReason.OTHER],
        value: RefundReason.OTHER,
    },
];

export interface RefundPaymentData {
    reason: RefundReason;
    detail: string;
}

export interface PartialRefundPaymentData extends RefundPaymentData {
    amount: string;
    refundDate: Date;
    tax: string;
}
