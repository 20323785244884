import { H1 } from '@blueprintjs/core';
import React from 'react';

import {
    ItemCount,
    LoadingIndicator,
    Reload,
    Table,
} from 'components/elements';
import { NoResultsFound } from 'components/elements/wrappers';
import { AssessmentTypeInstanceData } from 'interface';

import "./styles.scss";

interface AssessmentTypeTableProps {
    title: string;
    isLoading: boolean;
    count: number;
    data: object[];
    columns: object;
    onSort?: Function;
    onReload?: Function;
    ordering?: object;
    headerContent?: React.ReactNode;
    onRowClicked?: Function;
}

const AssessmentTypeInstanceTable = (props: AssessmentTypeTableProps) => {
    const {
        title,
        isLoading,
        count,
        onReload,
        data,
        columns,
        onSort,
        ordering,
        headerContent,
        onRowClicked,
    } = props;

    const handleRowClicked = (row: AssessmentTypeInstanceData, e: any) => {
        e.stopPropagation();

        if (typeof onRowClicked === 'function') {
            onRowClicked(row);
        }
    }

    return (
        <div className='assessment-table'>
            <H1>
                {title}
                {isLoading ? (
                    <LoadingIndicator inline size="sm" />
                ) : (
                    <ItemCount count={count} />
                )}
                <Reload load={onReload} />
            </H1>
            {headerContent}
            <NoResultsFound count={count}>
                <Table
                    data={data}
                    columns={columns}
                    onSort={onSort}
                    ordering={ordering}
                    onRowClicked={handleRowClicked}
                    pointerOnHover
                />
            </NoResultsFound>
        </div>
    );
};

export default AssessmentTypeInstanceTable;