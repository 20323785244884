import { Redirect, Switch } from 'react-router-dom';

import { ProtectedRoute } from 'components/elements';
import IntegrationTabs from 'components/user/Integrations/IntegrationsTabs';
import IntegrationsSetup from 'components/user/Integrations/IntegrationsSetup';

const Integrations = () => {
    return (
        <Switch>
            <ProtectedRoute
                path={'/integrations/setup'}
                component={IntegrationsSetup}
                exact
            />
            <ProtectedRoute path="/integrations" component={IntegrationTabs} />
            <Redirect to="/integrations/elogbooks" />
        </Switch>
    );
};

export default Integrations;
