import { Tab, Tabs } from '@blueprintjs/core';
import { Link, Redirect, Switch } from 'react-router-dom';

import IntegrationsList from 'components/admin/integrations/IntegrationsList';
import { Icon, ProtectedRoute } from 'components/elements';
import { Routing } from 'service';
import IntegrationsAdd from './IntegrationsAdd';
import IntegrationsAddMultiLinksTable from './IntegrationsAddMultiLinksTable';

const IntegrationsTabs = (props: any) => {
    const { match, location } = props;
    const tabId = Routing.getSelectedTabId(location.pathname, match.url);

    return (
        <div className="page-wrapper">
            <Tabs renderActiveTabPanelOnly={true} selectedTabId={tabId}>
                <Tab id={'/elogbooks'}>
                    <Link to={`${match.url}/elogbooks`}>
                        <Icon icon="building" />
                        Elogs
                    </Link>
                </Tab>
            </Tabs>

            <div className="TabContent">
                <Switch>
                    <ProtectedRoute
                        path={`${match.url}/elogbooks/add`}
                        component={IntegrationsAdd}
                        exact
                    />
                    <ProtectedRoute
                        path={`${match.url}/elogbooks/add/:clientId`}
                        component={IntegrationsAddMultiLinksTable}
                        exact
                    />
                    <ProtectedRoute
                        path={`${match.url}/elogbooks`}
                        component={IntegrationsList}
                        integrationType="Elogbooks"
                    />
                    <Redirect to={`${match.url}/elogbooks`} />
                </Switch>
            </div>
        </div>
    );
};

export default IntegrationsTabs;
