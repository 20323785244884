import React, { Component } from 'react';
import { Switch } from 'react-router-dom';
import UserList from 'components/admin/users/UserList';
import UserDetails from 'components/admin/users/UserDetails';
import UserAddEdit from 'components/admin/users/UserAddEdit';
import ProtectedRoute from 'components/elements/ProtectedRoute';

class Users extends Component
{
    render()
    {
        const { match } = this.props;

        return (
            <div className="Users">
                <Switch>
                    <ProtectedRoute path={`${match.url}`} component={ UserList } exact />
                    <ProtectedRoute path={`${match.url}/add`} component={ UserAddEdit } exact />
                    <ProtectedRoute path={`${match.url}/:id/edit`} component={ UserAddEdit } />
                    <ProtectedRoute path={`${match.url}/:id`} component={ UserDetails } />
                </Switch>
            </div>
        );
    }
}

export default Users;
