import { H1 } from '@blueprintjs/core';
import { ButtonLink } from 'components/elements';
import { Loading } from 'components/elements/wrappers';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { HTTP, Response } from 'service';

interface ClientIntegrationProps {
    clientResponse: { id: string };
    match: any;
}

interface ClientIntegrationData {
    clientId: string;
    hasElogs: boolean;
    hasMeridian: boolean;
    elogsClientName: string;
    elogsApiUrl: string;
}

const ClientIntegrations = (props: ClientIntegrationProps) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<ClientIntegrationData | null>(null);

    const load = useCallback(() => {
        const { clientResponse } = props;

        setLoading(true);

        HTTP.get(`/clients/${clientResponse?.id}/integration`).then(
            (response) => {
                if (response) {
                    setLoading(false);
                    setData(response.data);

                    return true;
                }

                toast.error('Unable to fetch integration details');
                setLoading(false);

                return false;
            }
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    useEffect(() => {
        load();
    }, [load]);

    const getStringFromBool = (value: any) => (value ? 'Yes' : 'No');

    const renderElogsUrl = () => {
        if (!data?.hasElogs) {
            return null;
        }

        return (
            <>
                <dt>Elogs Client Name</dt>
                <dd>{data?.elogsClientName || '-'}</dd>

                <dt>Elogs API URL</dt>
                <dd>{data?.elogsApiUrl || '-'}</dd>
            </>
        );
    };

    const renderMeridianCompanyId = () => {
        if (!data?.hasMeridian) {
            return null;
        }

        return (
            <>
                <dt>Company Reference (Meridian)</dt>
                <dd>{data?.clientId}</dd>
            </>
        );
    };

    return (
        <div className="ClientInfo">
            <H1 className="clearfix">
                Integrations
                {Response.getLink(props?.clientResponse, 'edit') ===
                null ? null : (
                    <ButtonLink
                        type="button"
                        to={`${props.match.url}/edit`}
                        intent="primary"
                        className="float-right"
                    >
                        Edit
                    </ButtonLink>
                )}
            </H1>
            <Loading isLoading={loading}>
                <dl className="horizontal">
                    <dt>Elogs CAFM Integration</dt>
                    <dd>{getStringFromBool(data?.hasElogs)}</dd>

                    {renderElogsUrl()}

                    <dt>Meridian Integration</dt>
                    <dd>{getStringFromBool(data?.hasMeridian)}</dd>

                    {renderMeridianCompanyId()}
                </dl>
            </Loading>
        </div>
    );
};

export default ClientIntegrations;
