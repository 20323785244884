import ReactDOM from 'react-dom';
import AppContainer from './AppContainer';

import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import GA4React from 'ga-4-react';
import { isMobile } from 'react-device-detect';
import store from 'store';
import './index.scss';
import * as serviceWorker from './serviceWorker';

import { FocusStyleManager } from '@blueprintjs/core';
import '@blueprintjs/icons/dist/icons.bundle';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

FocusStyleManager.onlyShowFocusOnTabs();

Sentry.init({
    dsn: 'https://35a1fc26b7bc421399d66a25eb75c950@sentry.v2.elogbooks.net/14',
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
});

(async () => {
    if (process.env.REACT_APP_GA_KEY !== undefined) {
        try {
            const ga4react = new GA4React(process.env.REACT_APP_GA_KEY);
            await ga4react.initialize();
        } catch (e) {
            console.log('Analytics Failure.');
            // catching errors that could be triggered by an adblocker
            // https://stackoverflow.com/a/68340096
        }
    }

    if (process.env.REACT_APP_GTAG_KEY !== undefined) {
        try {
            window.loadAnalytics = () => {
                var script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = 'https://www.googletagmanager.com/gtm.js?id=' + process.env.REACT_APP_GTAG_KEY;

                document.head.appendChild(script);

                window.measureLoadPerfomance();
            }

            window.measureLoadPerfomance = () => {
                var observer = new PerformanceObserver(function(list) {
                    var perfEntries = list.getEntries();

                    for (var i = 0; i < perfEntries.length; i++) {
                        // Check if the entry is of type "navigation"
                        if (perfEntries[i].entryType === "navigation") {
                            // Calculate the page load time
                            var pageLoadTime = ((perfEntries[i].loadEventEnd - perfEntries[i].startTime) / 1000).toFixed(2);

                            //log as global variable, so it can be picked up by GTM script
                            window.pageLoadTime = pageLoadTime;
                        }
                    }
                });

                observer.observe({ entryTypes: ["navigation"] });
            }
        } catch (e) {
            console.log('Analytics Failure.');
        }
    }

    ReactDOM.render(
        <HashRouter>
            <Provider store={store}>
                <AppContainer />
                <ToastContainer
                    position={
                        isMobile
                            ? toast.POSITION.BOTTOM_RIGHT
                            : toast.POSITION.TOP_RIGHT
                    }
                />
            </Provider>
        </HashRouter>,
        document.getElementById('root')
    );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
