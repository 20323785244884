import React from 'react';
import uuid from 'uuid/v4';
import * as _isJSON from 'is-json';

class Utility
{
    static isInteger = Number.isInteger || function(value) {
        return typeof value === 'number' && 
            isFinite(value) && 
            Math.floor(value) === value;
    };

    static isJSON(string, isPassingObject)
    {
        return _isJSON(string, isPassingObject);
    }

    static isStrictJSON(string)
    {
        return _isJSON.strict(string);
    }

    static generateUuid()
    {
        return uuid();
    }

    static generate32BitNumber()
    {
        return Math.ceil(Math.random() * 2147483647);
    }

    static validateMinimumOneKey(source = {}, properties = [])
    {
        for (var index in properties) {
            let property = properties[index];

            if (source.hasOwnProperty(property)) {
                return true;
            }
        }

        return false;
    }

    static validateAllKeys(source = {}, properties = [], enforceNotEmpty = false)
    {
        for (var index in properties) {
            let property = properties[index];

            if (!source.hasOwnProperty(property) ||
                (enforceNotEmpty === true &&
                    typeof source[property] !== 'boolean' &&
                    (
                        (typeof source[property].length !== 'undefined' && source[property].length === 0)
                    )
                )) {
                return false;
            }
        }

        return true;
    }

    static highlightText(text, query)
    {
        if (!query) {
            return text;
        }

        let lastIndex = 0;
        const words = query
            .split(/\s+/)
            .filter(word => word.length > 0)
            .map(text => text.replace(/([.*+?^=!:${}()|[\]/\\])/g, "\\$1"));

        if (words.length === 0) {
            return [text];
        }

        const regExp = new RegExp(words.join('|'), 'gi');
        const tokens = [];

        while (true) {
            const match = regExp.exec(text);
            if (!match) {
                break;
            }

            const length = match[0].length;
            const before = text.slice(lastIndex, regExp.lastIndex - length);
            if (before.length > 0) {
                tokens.push(before);
            }
            lastIndex = regExp.lastIndex;
            tokens.push(<strong className="highlight" key={ lastIndex }>{ match[0] }</strong>);
        }

        const rest = text.slice(lastIndex);
        if (rest.length > 0) {
            tokens.push(rest);
        }

        return tokens;
    }
}

export default Utility;
