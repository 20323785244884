import { Link, Redirect, Switch } from 'react-router-dom';
import { Tabs, Tab } from '@blueprintjs/core';

import { Routing } from 'service';
import { Icon, ProtectedRoute } from 'components/elements';
import IntegrationsList from 'components/user/Integrations/IntegrationsList';
import IntegrationAdd from 'components/user/Integrations/IntegrationAdd';

const IntegrationsTabs = (props: any) => {
    const { match, location } = props;
    const tabId = Routing.getSelectedTabId(location.pathname, match.url);

    return (
        <div className="integrations">
            <div>
                <Tabs renderActiveTabPanelOnly={true} selectedTabId={tabId}>
                    <Tab id={'/elogbooks'}>
                        <Link to={`${match.url}/elogbooks`}>
                            <Icon icon="building" />
                            Elogs
                        </Link>
                    </Tab>
                </Tabs>

                <div className="TabContent">
                    <Switch>
                        <ProtectedRoute
                            path={`${match.url}/elogbooks`}
                            component={IntegrationsList}
                            integrationType="Elogbooks"
                            exact
                        />
                        <ProtectedRoute
                            path={`${match.url}/elogbooks/add`}
                            component={IntegrationAdd}
                            integrationType="Elogbooks"
                            exact
                        />
                        <Redirect to={`${match.url}/elogbooks`} />
                    </Switch>
                </div>
            </div>
        </div>
    );
};

export default IntegrationsTabs;
