import { Link, Redirect, RouteComponentProps, Switch } from 'react-router-dom';
import ProtectedRoute from 'components/elements/ProtectedRoute';
import CampaignList from 'components/clients/campaign/CampaignList';
import CampaignAddEdit from 'components/clients/campaign/CampaignAddEdit';
import CampaignSummary from 'components/clients/campaign/CampaignSummary';
import { Tab, Tabs } from '@blueprintjs/core';
import { Icon } from 'components/elements';
import { Routing } from 'service';
import CampaignInviteeTracker from 'components/clients/campaign/CampaignInviteeTracker';
import CampaignUpdatesList from 'components/clients/campaign/CampaignUpdatesList';

const Campaigns = (props: RouteComponentProps<any>) => {
    const { match, location } = props;
    const tabId = Routing.getSelectedTabId(location.pathname, match.url);

    return (
        <div className="ClientsCampaigns">
            <Tabs renderActiveTabPanelOnly={true} selectedTabId={tabId}>
                <Tab id='/list'>
                    <Link to='/clients/campaigns/list'>
                        <Icon icon="info" />
                        Campaigns
                    </Link>
                </Tab>
                <Tab id='/all-invitees'>
                    <Link to='/clients/campaigns/all-invitees'>
                        <Icon icon="info" />
                        All Invitees List
                    </Link>
                </Tab>
                <Tab id='/updates-list'>
                    <Link to='/clients/campaigns/updates-list'>
                        <Icon icon="info" />
                        Updates List
                    </Link>
                </Tab>
            </Tabs>
            <div className='p-2'></div>
            <Switch>
                <ProtectedRoute
                    path="/clients/campaigns/list"
                    component={CampaignList}
                    exact
                />
                <ProtectedRoute
                    path="/clients/campaigns/all-invitees"
                    component={CampaignInviteeTracker}
                    exact
                />
                <ProtectedRoute
                    path="/clients/campaigns/updates-list"
                    component={CampaignUpdatesList}
                    exact
                />
                <ProtectedRoute
                    path="/clients/campaigns/new"
                    component={CampaignAddEdit}
                    exact
                />
                <ProtectedRoute
                    path="/clients/campaigns/list/:campaignId"
                    component={CampaignSummary}
                />

                <ProtectedRoute
                    path="/clients/campaigns/list/:campaignId/details/edit"
                    component={CampaignAddEdit}
                />

                <Redirect to="/clients/campaigns/list" />
            </Switch>
        </div>
    );
};

export default Campaigns;
