const routes = {
    admin_questions: {
        href: '/admin/questions',
        text: 'Questions',
        icon: 'question-circle',
    },
    admin_questions_add: {
        href: '/admin/questions/add',
        text: 'Add new Question',
        icon: 'plus-circle',
    },
    admin_questions_details: {
        href: '/admin/questions/:id',
        text: '{question.summary}',
        icon: 'info',
    },
    admin_questions_details_edit: {
        href: '/admin/questions/:id/edit',
        text: 'Edit',
        icon: 'pencil',
        skipNavigation: true,
    },
};

export default routes;
