import { Component } from 'react';
import { Redirect, Switch } from 'react-router-dom';

import PurchasesFromChasersListList from 'components/admin/reports/PurchasesFromChasersListList';
import ProtectedRoute from 'components/elements/ProtectedRoute';

class Reports extends Component {
    render() {
        return (
            <div className="Reports">
                <Switch>
                    <ProtectedRoute
                        path="/admin/reports"
                        component={PurchasesFromChasersListList}
                        exact
                    />
                    <Redirect to="/" />
                </Switch>
            </div>
        );
    }
}

export default Reports;
