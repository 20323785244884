import qs from 'querystring';
import { Utility } from 'service';

class Filters
{
    static convertFilterDataToRequestData(filterData = {})
    {
        let filters = {
            modifiers: {},
        };

        Object.entries(filterData).forEach((filter) => {
            if (Array.isArray(filter) &&
                filter.length === 2) {

                if (Utility.isStrictJSON(filter[1]) &&
                    filter[1].value) {

                    if (typeof filter[1].value === 'object' &&
                        typeof filter[1].value.value !== 'undefined') {
                        filters[filter[0]] = filter[1].value.value;
                    } else {
                        filters[filter[0]] = filter[1].value;
                    }

                    // apply isNull modifier
                    if (filter[1].value === '~') {
                        filters.modifiers[filter[0]] = {
                            type: 'isNull',
                        };
                    }
                } else if (
                    (!Utility.isStrictJSON(filter[1]) && filter[1])
                    || Utility.isInteger(filter[1]) // isStrictJSON with a numeric value will return true
                    || typeof filter[1] === "string"
                    || typeof filter[1] === "boolean"
                ) {
                    if (filter[1] instanceof Array) {
                        if (filter[1].length > 0) {
                            filters[filter[0]] = filter[1].join('-');
                        }
                    } else {
                        filters[filter[0]] = filter[1];
                    }
                }
            }
        });

        return filters;
    }

    static getFilterStateFromUrl(queryString, filterData = {})
    {
        const queryParams = qs.parse(queryString);
        let filters = {};

        if (Object.entries(queryParams).length > 0) {
            Object.entries(queryParams).forEach((filter) => {
                if (Array.isArray(filter) &&
                    filter.length === 2) {
                    if (Utility.isStrictJSON(filter[1])) {
                        filters[filter[0]] = JSON.parse(filter[1]);
                    } else if (!Utility.isStrictJSON(filter[1]) &&
                        filter[1]) {
                        filters[filter[0]] = filter[1];
                    }
                }
            });

            // return filter data built from URL
            return filters;
        }

        // return passed filter data
        return filterData;
    }

    static updateFilterStateFromUrl(queryString, filterData, callback) {
        const queryParams = qs.parse(queryString);
        let filters = {};

        if (Object.entries(queryParams).length > 0) {
            Object.entries(queryParams).forEach((filter) => {
                filters[filter[0]] = JSON.parse(filter[1]);
            });

            // update state with action method passed
            callback(Filters.convertFilterDataToRequestData(filters), filters);

            // return filter data built from URL
            return filters;
        }

        // return passed filter data
        return filterData;
    }
}

export default Filters;
