import React, { Component } from 'react';
import { Switch } from 'react-router-dom';
import { ProtectedRoute } from 'components/elements';
import ServiceList from 'components/admin/services/ServiceList';
import ServiceAddEdit from 'components/admin/services/ServiceAddEdit';
import ServiceDetails from 'components/admin/services/ServiceDetails';

class Services extends Component {
    render() {
        const { match } = this.props;

        return (
            <div className="Services">
                <Switch>
                    <ProtectedRoute
                        path={`${match.url}`}
                        component={ServiceList}
                        exact
                    />
                    <ProtectedRoute
                        path={`${match.url}/add`}
                        component={ServiceAddEdit}
                        exact
                    />
                    <ProtectedRoute
                        path={`${match.url}/edit`}
                        component={ServiceAddEdit}
                        exact
                    />
                    <ProtectedRoute
                        path={`${match.url}/:id`}
                        component={ServiceDetails}
                    />
                </Switch>
            </div>
        );
    }
}

export default Services;
