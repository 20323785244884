import { Card, H1 } from '@blueprintjs/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ItemCount, Paginator, Reload } from 'components/elements';
import { Loading } from 'components/elements/wrappers';
import { INSTANCE_STATUSES } from 'constants/assessmentTypeInstance';
import { HTTP, Response } from 'service';

class AssessmentTypeInstanceHistory extends Component {
    static propTypes = {
        assessmentTypeInstanceResponse: PropTypes.object.isRequired,
    };

    defaultFilters = {
        order: '-createdAt',
    };

    constructor(props) {
        super(props);

        this.state = {
            collectionResponse: {
                events: [],
            },
            filter: queryString.parse(props.location.search),
            isLoading: false,
            events: [],
        };

        this.load = this.load.bind(this);
    }

    componentDidMount() {
        this.load();
    }

    getTrendUpdateText(updatedTo) {
        let content = [];
        const valueMap = {
            ytd: "Year To Date: ",
            lastYear: "Last Year: ",
            yearBeforeLast: "Year Before Last: ",
        };

        for (const key in updatedTo) {
            content.push(
                <div>
                    {`${valueMap[key]} ${updatedTo[key]}`}
                </div>
            );
        }

        return content;
    };

    render() {
        const collection = this.state.collectionResponse || {};

        const mappedEvents = this.state.collectionResponse.events.map(
            (event, index) => {
                let eventType;
                let eventUser = 'System user';
                let createdAt = moment(event.createdAt).format(
                    'MMMM Do YYYY H:mm'
                );
                let metaData = null;
                if (event.metaData) {
                    let metaDatafields = event.metaData.map((field, index) => {
                        const updateFields = [];

                        if ('answer' in field) {
                            if (
                                'updatedTo' in field.answer &&
                                'previous' in field.answer
                            ) {
                                const { answer, questionSummary, questionId } = field;
                                const { updatedTo, previous } = answer;

                                if (typeof updatedTo === 'object') {
                                    const updateText = this.getTrendUpdateText(updatedTo);
                                    const previousUpdateText = this.getTrendUpdateText(previous);

                                    updateFields.push(
                                        <div key={index}>
                                            <span>{questionSummary}</span> values were 
                                            set to{' '}
                                            {updateText}
                                            answer changed from{' '}
                                            {previousUpdateText}
                                        </div>
                                    );
                                }
                                else {
                                    updateFields.push(
                                        <div key={index}>
                                            <span>
                                                {' '}
                                                {questionSummary
                                                    ? questionSummary
                                                    : questionId}{' '}
                                            </span>{' '}
                                            answer changed from{' '}
                                            <span>{previous}</span>, to{' '}
                                            <span>{updatedTo}</span>
                                        </div>
                                    );
                                }
                            } else if ('updatedTo' in field.answer) {
                                const { updatedTo } = field.answer;

                                if (typeof updatedTo === 'object') {
                                    const updateText = this.getTrendUpdateText(updatedTo);

                                    updateFields.push(
                                        <div key={index}>
                                            <span>{field.questionSummary}</span> values were 
                                            set to{' '}
                                            {updateText}
                                        </div>
                                    );
                                }
                                else {
                                    updateFields.push(
                                        <div key={index}>
                                            <span>{field.questionSummary}</span> was
                                            set to{' '}
                                            <span>{field.answer.updatedTo}</span>
                                        </div>
                                    );
                                }
                            }
                        }
                        if ('isApproved' in field) {
                            if (
                                'updatedTo' in field.isApproved &&
                                'previous' in field.isApproved
                            ) {
                                updateFields.push(
                                    <div key={index}>
                                        <span>
                                            {' '}
                                            {field.questionSummary
                                                ? field.questionSummary
                                                : field.questionId}{' '}
                                        </span>{' '}
                                        changed from{' '}
                                        <span>
                                            {field.isApproved.previous
                                                ? 'Approved'
                                                : 'Pending'}
                                        </span>
                                        , to{' '}
                                        <span>
                                            {field.isApproved.updatedTo
                                                ? 'Approved'
                                                : 'Pending'}
                                        </span>
                                    </div>
                                );
                            } else if ('updatedTo' in field.isApproved) {
                                updateFields.push(
                                    <div key={index}>
                                        <span>{field.questionSummary}</span> was
                                        set to{' '}
                                        <span>
                                            {field.isApproved.updatedTo
                                                ? 'Approved'
                                                : 'Pending'}
                                        </span>
                                    </div>
                                );
                            }
                        }
                        if ('comment' in field) {
                            if (
                                'updatedTo' in field.comment &&
                                'previous' in field.comment &&
                                field.comment.previous.length
                            ) {
                                updateFields.push(
                                    <div key={`${index}_comment`}>
                                        <span>
                                            {' '}
                                            {field.questionSummary
                                                ? field.questionSummary
                                                : field.questionId}{' '}
                                        </span>{' '}
                                        comments changed from{' '}
                                        <span>{field.comment.previous}</span>,
                                        to{' '}
                                        <span>{field.comment.updatedTo}</span>
                                    </div>
                                );
                            } else if ('updatedTo' in field.comment) {
                                updateFields.push(
                                    <div key={`${index}_comment`}>
                                        <span>{field.questionSummary}</span>{' '}
                                        comment was added:{' '}
                                        <span>{field.comment.updatedTo}</span>
                                    </div>
                                );
                            }
                        }
                        if ('user' in field) {
                            if ('name' in field.user) {
                                updateFields.push(
                                    <div key={`${index}_comment`}>
                                        Assessment assigned to{' '}
                                        <span>{field.user.name}</span>
                                    </div>
                                );
                            }
                        }
                        if ('resendTo' in field) {
                            if ('email' in field.resendTo) {
                                updateFields.push(
                                    <div key={`${index}_comment`}>
                                        Emails sent to{' '}
                                        <span>{field.resendTo.email}</span>
                                    </div>
                                );
                            }
                        }

                        return updateFields;
                    });

                    metaData = (
                        <div>
                            <dt>Fields updated:</dt>
                            <dd className="historyFields">
                                {' '}
                                {metaDatafields}{' '}
                            </dd>
                        </div>
                    );
                }

                const status = INSTANCE_STATUSES.find(
                    (status) => status.value === event.type
                );
                eventType = status ? status.label : 'Unknown';

                if (event._links) {
                    eventUser = event._links.user.username;
                }

                return (
                    <Card key={event.id}>
                        <div>
                            <dt>Event Type:</dt>
                            <dd>{eventType}</dd>
                        </div>
                        <div>
                            <dt>Raised by:</dt>
                            <dd>{eventUser}</dd>
                        </div>
                        <div>
                            <dt>Raised On:</dt>
                            <dd>{createdAt}</dd>
                        </div>
                        {metaData}
                    </Card>
                );
            }
        );

        return (
            <div className="AssessmentTypeInstanceHistory">
                <H1 className="clearfix">
                    History
                    <ItemCount count={collection.count} />
                    <Reload load={this.load} />
                </H1>

                <Loading isLoading={this.state.isLoading}>
                    <div className="events">{mappedEvents}</div>

                    <Paginator
                        page={collection.page}
                        count={collection.count}
                        limit={collection.limit}
                        onPage={this.load}
                    />
                </Loading>
            </div>
        );
    }

    load(params = {}) {
        if (this.state.isLoading) {
            return;
        }

        this.setState({ isLoading: true });

        const filterParams = Object.assign(
            {},
            this.defaultFilters,
            this.props.filter,
            this.state.filter,
            params
        );
        let queryStringFilterParams = queryString.stringify(filterParams);
        if (
            this.props.location.search &&
            '?' + queryStringFilterParams !== this.props.location.search
        ) {
            this.props.history.push({ search: queryStringFilterParams });
        }

        this.setState({ isLoading: true, filter: filterParams });

        const getUrl = Response.getLink(
            this.props.assessmentTypeInstanceResponse,
            'events'
        );

        HTTP.get(getUrl, filterParams).then(
            (response) => {
                this.setState({
                    isLoading: false,
                    collectionResponse: response.data,
                });
                return true;
            },
            (response) => {
                this.setState({ isLoading: false });
                toast.error(
                    'Unable to fetch a list of Assessment Type Instance Events'
                );
                return false;
            }
        );
    }
}

export default withRouter(AssessmentTypeInstanceHistory);
