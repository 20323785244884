import {
    ANSWERS_COPIED,
    CLEAR_CHANGES,
    SET_CHANGES,
    CLEAR_REVIEW_CHANGES,
    SET_REVIEW_CHANGES,
    SET_ERRORS,
    CLEAR_ERRORS,
    GET_ASSESSMENT_REVIEW_DATA,
    ASSESSMENT_REVIEW_DATA_LOADED,
    CLEAR_ASSESSMENT_SUBMITTED,
    SET_ASSESSMENT_SUBMITTED,
    CLEAR_ASSESSMENT_DISCOUNT,
    SET_ASSESSMENT_DISCOUNT,
    SET_SORTED_QUESTIONS,
} from "action/assessments";

const defaultState = {
    errors: [],
    changes: {},
    reviewChanges: [],
    openTopics: [],
    answersHaveBeenCopied: false,
    isExpanded: false,
    isLoading: false,
    assessmentTypeInstance: undefined,
    topicCollection: undefined,
    questionsCollection: undefined,
    answersCollection: undefined,
    reviewsCollection: undefined,
    sortedQuestions: undefined,
    assessmentSubmitted: false,
    discount: undefined,
};

export default function assessments(state = defaultState, action)
{
    const baseState = Object.assign({}, defaultState, state);

    switch (action.type) {
        case ANSWERS_COPIED:
            return {
                ...baseState,
                answersHaveBeenCopied: true
            };
        case SET_ERRORS:
            return {
                ...baseState,
                errors: action.errors
            };
        case CLEAR_ERRORS:
            return {
                ...baseState,
                errors: []
            };
        case SET_CHANGES:
            return {
                ...baseState,
                changes: action.changes
            };
        case CLEAR_CHANGES:
            return {
                ...baseState,
                changes: {}
            };
        case SET_REVIEW_CHANGES:
            return {
                ...baseState,
                reviewChanges: action.changes
            };
        case CLEAR_REVIEW_CHANGES:
            return {
                ...baseState,
                reviewChanges: []
            };
        case SET_SORTED_QUESTIONS:
            return {
                ...baseState,
                sortedQuestions: action.sortedQuestions
            };
        case GET_ASSESSMENT_REVIEW_DATA:
            return {
                ...baseState,
                isLoading: true
            };
        case CLEAR_ASSESSMENT_SUBMITTED:
                return {
                    ...baseState,
                    assessmentSubmitted: false
                };
        case SET_ASSESSMENT_SUBMITTED:
            return {
                ...baseState,
                assessmentSubmitted: true
            };
        case ASSESSMENT_REVIEW_DATA_LOADED:
            return {
                ...baseState,
                isLoading: false,
                answersHaveBeenCopied: false,
                assessmentTypeInstance: action.payload.assessmentResponse,
                topicCollection: action.payload.topics,
                questionsCollection: action.payload.questions,
                answersCollection: action.payload.answers,
                reviewsCollection: action.payload.reviews,
                stockResponsesCollection: action.payload.stockResponses,
                sortedQuestions: action.payload.sortedQuestions,
                reviewChanges: action.payload.reviewChanges,
            };
        case CLEAR_ASSESSMENT_DISCOUNT:
            return {
                ...baseState,
                discount: void 0,
            };
        case SET_ASSESSMENT_DISCOUNT:
            return {
                ...baseState,
                discount: action.discount,
            };
        default:
            return Object.assign(baseState);
    }
}
