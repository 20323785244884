import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Button } from '@blueprintjs/core';
import PropTypes from 'prop-types';

class ButtonLink extends Component
{
    static propTypes = {
        to: PropTypes.string.isRequired,
    };

    render()
    {
        const {
            history,
            location,
            match,
            onClick,
            staticContext,
            to,
            ...rest
        } = this.props;

        return (
            <Button {...rest}
                onClick={(event) => {
                    onClick && onClick(event);
                    history.push(to);
                }} />
        );
    }
}

export default withRouter(ButtonLink);
