import React from 'react';
import { SuggestRenderer } from 'components/elements';

interface CategoryFilterProps {
    children: React.ReactNode;
    categoryData: any;
    selectCategory: any;
}

export const CategoryFilter = (props: Partial<CategoryFilterProps>) => {
    const { selectCategory, categoryData } = props;

    return (
        <div className="search-widget">
            <form>
                {props.children}
                <div className="single-dropdown">
                    <SuggestRenderer
                        id="parent"
                        onItemSelect={(item: any) => selectCategory(item)}
                        items={categoryData.categories}
                        defaultItem={categoryData.selectedCategory}
                        isRequired={false}
                        popoverProps={{ position: 'bottom' }}
                        placeholder={'All Service Provider Categories'}
                        showClearButton={false}
                    />
                </div>
            </form>
        </div>
    );
};
