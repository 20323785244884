import { ComponentProps, FormEvent, useState } from 'react';
import {
    Button,
    Classes,
    Dialog,
    FormGroup,
    InputGroup,
    Intent,
} from '@blueprintjs/core';

interface ResendReceiptDialogProps extends ComponentProps<any> {
    onClose: Function;
    isDialogOpen: boolean;
    onSubmithandler: Function;
}

const ResendReceiptDialog = (props: ResendReceiptDialogProps) => {
    const { onClose, isDialogOpen, onSubmithandler } = props;
    const [email, setEmail] = useState<string>('');

    return (
        <Dialog
            className="dialog-form"
            onClose={() => onClose()}
            title="Resend Receipt"
            autoFocus={true}
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            usePortal={true}
            isOpen={isDialogOpen}
        >
            <form
                className="dialog-form"
                onSubmit={(event) => {
                    onClose();
                    onSubmithandler(event, { email });
                }}
            >
                <div className={Classes.DIALOG_BODY}>
                    <FormGroup
                        label="Email Address"
                        inline={true}
                        className="form-fill required"
                        labelFor="email"
                    >
                        <InputGroup
                            id="email"
                            type="email"
                            placeholder="Email Address"
                            minLength={1}
                            maxLength={75}
                            value={email}
                            onChange={(e: FormEvent<HTMLInputElement>) => {
                                const { value } = e.target as HTMLInputElement;
                                setEmail(value);
                            }}
                            required
                        />
                    </FormGroup>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className="dialog-footer-buttons">
                        <Button onClick={() => onClose()}>Cancel</Button>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button type="submit" intent={Intent.SUCCESS}>
                                Confirm
                            </Button>
                        </div>
                    </div>
                </div>
            </form>
        </Dialog>
    );
};

export default ResendReceiptDialog;
