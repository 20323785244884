import { AxiosResponse } from 'axios';

import { HTTP } from 'service';
import { BenefitsFilter, BenefitsListData } from 'interface';

class BenefitsService {
    static loadList: (
        filter?: Partial<BenefitsFilter>
    ) => Promise<BenefitsListData> = async (
        filter?: Partial<BenefitsFilter>
    ) => {
        const response: AxiosResponse | void = await HTTP.action(
            'get',
            '/benefit-interests',
            filter ? filter : {},
            {},
            'Unable to load benefits registered interest list'
        );
        if (response) {
            return {
                ...response.data,
                data: response.data.benefitInterests,
            } as BenefitsListData;
        } else {
            return {
                page: 0,
                limit: 0,
                pages: 0,
                count: 0,
                _links: [],
                benefitInterests: [],
                data: [],
            } as BenefitsListData;
        }
    };
}

export default BenefitsService;
