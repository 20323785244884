import { Button, Card, FormGroup, H3, H5, TextArea } from '@blueprintjs/core';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { updateRegisterForm } from 'action/auth';
import { HTMLSelect, InputElementIcon, TooltipIcon } from 'components/elements';
import {
    IS_PART_OF_GROUP_OPTIONS,
    ORGANISATION_TYPES,
} from 'constants/company';
import { COUNTRY_CODE_OPTIONS } from 'constants/countries';
import { YES_NO_OPTIONS } from 'constants/general';
import StateHandler from 'service/StateHandler';

const mapStateToProps = (state) => {
    return {
        form: state.auth.form,
    };
};

const mapDispatchToProps = {
    updateRegisterForm: updateRegisterForm,
};

class CompanyFields extends Component {
    constructor(props) {
        super(props);

        this.state = {
            form: this.props.form,
        };
    }

    updateFormData = (data) => {
        let formData = { ...this.state.form, ...data };
        formData.organisationSize =
            (parseInt(formData.directEmployees) || 0) +
            (parseInt(formData.labourOnly) || 0);
        this.props.updateRegisterForm(formData);
        this.setState({ form: formData });
    };

    render() {
        return (
            <>
                <div className="header small">
                    <div className="header-desc">
                        <H3>Tell us about your company</H3>
                        <span className="mb-4">
                            We need a little information about your company as
                            part of Prosure360 registration.
                        </span>
                    </div>
                    <img
                        className="header-logo"
                        src="/assets/images/logo-new.png"
                        alt="Prosure360 logo"
                    />
                </div>

                <div className="body">
                    {!this.props.errorMessage ? null : (
                        <Card className="alert">{this.props.errorMessage}</Card>
                    )}

                    <FormGroup className="form-fill">
                        <input
                            id="registeredCompanyName"
                            placeholder="Registered Company Name *"
                            value={this.state.form.registeredCompanyName}
                            onChange={(e) =>
                                this.updateFormData(
                                    StateHandler.getStateObject(e)
                                )
                            }
                            minLength={2}
                            maxLength={100}
                            required
                        />
                        <TooltipIcon
                            text="The full name of the business"
                            icon="info-circle"
                            className="px-2"
                        />
                        <div className="input-error">
                            Registered company name is required
                        </div>
                    </FormGroup>
                    <FormGroup className="form-fill">
                        <input
                            id="tradingName"
                            placeholder="Trading Name"
                            value={this.state.form.tradingName}
                            onChange={(e) =>
                                this.updateFormData(
                                    StateHandler.getStateObject(e)
                                )
                            }
                            minLength={2}
                            maxLength={100}
                        />
                        <TooltipIcon
                            text="Enter if different from Registered Company Name"
                            icon="info-circle"
                            className="px-2"
                        />
                        <div className="input-error">
                            Please enter at valid Trading name
                        </div>
                    </FormGroup>
                    <FormGroup className="form-fill">
                        <TextArea
                            id="companyBio"
                            fill="true"
                            placeholder="Tell us in a sentence or two a little about your business, and the services you offer *"
                            value={this.state.form.companyBio}
                            required
                            onChange={(e) =>
                                this.updateFormData(
                                    StateHandler.getStateObject(e)
                                )
                            }
                        />
                        <TooltipIcon
                            text="The business short summary"
                            icon="info-circle"
                            className="px-2"
                        />
                        <div className="input-error">
                            Company bio is required
                        </div>
                    </FormGroup>
                    <FormGroup className="form-fill">
                        <input
                            id="companiesHouseRegistrationNumber"
                            placeholder="Companies House Registration Number"
                            value={
                                this.state.form.companiesHouseRegistrationNumber
                            }
                            onChange={(e) =>
                                this.updateFormData(
                                    StateHandler.getStateObject(e)
                                )
                            }
                            minLength={8}
                            maxLength={8}
                        />
                        <TooltipIcon
                            text="The business's Companies House registration number. Must be 8 digits if added"
                            icon="info-circle"
                            className="px-2"
                        />
                    </FormGroup>
                    <FormGroup className="form-fill">
                        <input
                            id="webAddress"
                            placeholder="Web Address"
                            value={this.state.form.webAddress}
                            onChange={(e) =>
                                this.updateFormData(
                                    StateHandler.getStateObject(e)
                                )
                            }
                            pattern="^(https?://)?([\da-zA-Z.-]+)\.([a-zA-Z.]{2,6})(/.*)*"
                        />
                        <TooltipIcon
                            text="Enter your company web address ( if applicable)"
                            icon="info-circle"
                            className="px-2"
                        />
                        <div className="input-error">
                            Please enter a valid web address
                        </div>
                    </FormGroup>

                    <H5 className="box-sub-heading">Address</H5>
                    <FormGroup className="form-fill">
                        <input
                            id="addressLine1"
                            fill="true"
                            placeholder="Address Line 1 *"
                            value={this.state.form.addressLine1}
                            onChange={(e) =>
                                this.updateFormData(
                                    StateHandler.getStateObject(e)
                                )
                            }
                            required
                        />
                        <TooltipIcon
                            text="The first line of the business address"
                            icon="info-circle"
                            className="px-2"
                        />
                        <div className="input-error">
                            First line of address is required
                        </div>
                    </FormGroup>
                    <FormGroup className="form-fill">
                        <input
                            id="addressLine2"
                            fill="true"
                            placeholder="Address Line 2"
                            value={this.state.form.addressLine2}
                            onChange={(e) =>
                                this.updateFormData(
                                    StateHandler.getStateObject(e)
                                )
                            }
                        />
                        <TooltipIcon
                            text="The second line of the business address"
                            icon="info-circle"
                            className="px-2"
                        />
                    </FormGroup>
                    <FormGroup inline={true} className="form-fill">
                        <input
                            id="addressLine3"
                            fill="true"
                            placeholder="Address Line 3"
                            value={this.state.form.addressLine3}
                            onChange={(e) =>
                                this.updateFormData(
                                    StateHandler.getStateObject(e)
                                )
                            }
                        />
                        <TooltipIcon
                            text="The third line of the business address"
                            icon="info-circle"
                            className="px-2"
                        />
                    </FormGroup>
                    <FormGroup className="form-fill">
                        <input
                            id="town"
                            fill="true"
                            placeholder="Town/City *"
                            value={this.state.form.town}
                            onChange={(e) =>
                                this.updateFormData(
                                    StateHandler.getStateObject(e)
                                )
                            }
                            required
                        />
                        <TooltipIcon
                            text="The business address town"
                            icon="info-circle"
                            className="px-2"
                        />
                        <div className="input-error">
                            Town / City is required
                        </div>
                    </FormGroup>
                    <FormGroup className="form-fill">
                        <input
                            id="county"
                            fill="true"
                            placeholder="County/Region"
                            value={this.state.form.county}
                            onChange={(e) =>
                                this.updateFormData(
                                    StateHandler.getStateObject(e)
                                )
                            }
                        />
                        <TooltipIcon
                            text="The business address county or region"
                            icon="info-circle"
                            className="px-2"
                        />
                    </FormGroup>
                    <FormGroup className="form-fill">
                        <input
                            id="postCode"
                            fill="true"
                            placeholder="Postal/Zip Code"
                            value={this.state.form.postCode}
                            maxLength={10}
                            onChange={(e) =>
                                this.updateFormData(
                                    StateHandler.getStateObject(e)
                                )
                            }
                        />
                        <TooltipIcon
                            text="The business address postcode"
                            icon="info-circle"
                            className="px-2"
                        />
                    </FormGroup>

                    <FormGroup className="form-fill">
                        <label htmlFor="country" className="full-width">
                            Country *
                        </label>
                        <HTMLSelect
                            id="country"
                            required
                            fill="true"
                            options={COUNTRY_CODE_OPTIONS}
                            value={this.state.form.country}
                            onChange={(e) =>
                                this.updateFormData(
                                    StateHandler.getStateObject(e)
                                )
                            }
                        />
                    </FormGroup>

                    <H5 className="box-sub-heading">Company Information</H5>
                    <FormGroup className="form-fill">
                        <input
                            id="uniqueTaxReferenceNumber"
                            placeholder="Unique Tax Reference Number"
                            value={this.state.form.uniqueTaxReferenceNumber}
                            onChange={(e) =>
                                this.updateFormData(
                                    StateHandler.getStateObject(e)
                                )
                            }
                            minLength={10}
                            maxLength={10}
                        />
                        <TooltipIcon
                            text="Enter if applicable"
                            icon="info-circle"
                        />
                        <div className="input-error">
                            Tax Reference number must be exactly 10 characters
                            long
                        </div>
                    </FormGroup>
                    <FormGroup className="form-fill">
                        <input
                            id="cisNumber"
                            placeholder="CIS Number"
                            value={this.state.form.cisNumber}
                            onChange={(e) =>
                                this.updateFormData(
                                    StateHandler.getStateObject(e)
                                )
                            }
                            minLength={2}
                            maxLength={75}
                        />
                        <TooltipIcon
                            text="Construction Industry Scheme (CIS) number, if applicable"
                            icon="info-circle"
                        />
                        <div className="input-error">
                            Please Enter a valid CIS number
                        </div>
                    </FormGroup>
                    <FormGroup className="form-fill">
                        <input
                            id="vatNumber"
                            placeholder="VAT Number"
                            value={this.state.form.vatNumber}
                            onChange={(e) =>
                                this.updateFormData(
                                    StateHandler.getStateObject(e)
                                )
                            }
                            minLength={2}
                            maxLength={75}
                        />
                        <TooltipIcon
                            text="Enter if applicable"
                            icon="info-circle"
                        />
                        <div className="input-error">
                            Please enter a valid VAT number
                        </div>
                    </FormGroup>
                    <FormGroup className="form-fill ">
                        <label htmlFor="directEmployees" className="full-width">
                            Direct Employees *
                        </label>
                        <input
                            id="directEmployees"
                            fill="true"
                            placeholder="Direct Employees *"
                            value={this.state.form.directEmployees}
                            type="number"
                            min="0"
                            onChange={(e) =>
                                this.updateFormData(
                                    StateHandler.getStateObject(e)
                                )
                            }
                            required
                        />
                        <TooltipIcon
                            text={`Direct employees are workers hired by a company and paid a salary or hourly wages through the company's payroll.`}
                            icon="info-circle"
                        />
                        <div className="input-error">
                            Number of direct employees is required
                        </div>
                    </FormGroup>
                    <FormGroup className="form-fill ">
                        <label
                            htmlFor="bonaFideSubContractors"
                            className="full-width"
                        >
                            Bona Fide Sub Contractors *
                        </label>
                        <input
                            id="bonaFideSubContractors"
                            fill="true"
                            placeholder="Bona Fide Sub Contractors"
                            value={this.state.form.bonaFideSubContractors}
                            type="number"
                            min="0"
                            required
                            onChange={(e) =>
                                this.updateFormData(
                                    StateHandler.getStateObject(e)
                                )
                            }
                        />
                        <TooltipIcon
                            text={`Contractors who work without direction, hold their own insurance and provide their own materials and tools.`}
                            icon="info-circle"
                        />
                    </FormGroup>
                    <FormGroup className="form-fill ">
                        <label htmlFor="labourOnly" className="full-width">
                            Labour Only *
                        </label>
                        <input
                            id="labourOnly"
                            fill="true"
                            placeholder="Labour Only *"
                            value={this.state.form.labourOnly}
                            type="number"
                            min="0"
                            onChange={(e) =>
                                this.updateFormData(
                                    StateHandler.getStateObject(e)
                                )
                            }
                            required
                        />
                        <TooltipIcon
                            text={`According to UK law a labour only subcontractor is considered an employee. They are working under your supervision and using material, equipment, and tools that you have provided.`}
                            icon="info-circle"
                        />
                        <div className="input-error">
                            Number of labour only employees is required
                        </div>
                    </FormGroup>
                    <FormGroup className="form-fill ">
                        <label
                            htmlFor="organisationSize"
                            className="full-width"
                        >
                            Number of effective employees
                        </label>
                        <input
                            id="organisationSize"
                            fill="true"
                            placeholder="Number of effective employees"
                            value={this.state.form.organisationSize}
                            type="number"
                            disabled
                        />
                        <TooltipIcon
                            text={`The total number of direct employees, full-time, part-time, permanent and temporary,
                            as part of your PAYE scheme. Also to include the total number of labour-only
                            subcontractors. Labour-only subcontractors are typically considered third-party
                            works who have worked under your supervision, using materials, tools and equipment
                            that you have provided, and would typically be covered by your insurance policies.`}
                            icon="info-circle"
                        />
                    </FormGroup>
                    <FormGroup className="form-fill">
                        <label
                            htmlFor="organisationType"
                            className="full-width"
                        >
                            Organisation Type *
                        </label>
                        <HTMLSelect
                            id="organisationType"
                            fill="true"
                            value={this.state.form.organisationType}
                            onChange={(e) =>
                                this.updateFormData(
                                    StateHandler.getStateObject(e)
                                )
                            }
                            rightElement={InputElementIcon(
                                'What description best describes your business',
                                'info-circle'
                            )}
                            options={ORGANISATION_TYPES}
                        />
                    </FormGroup>
                    <FormGroup className="form-fill ">
                        <label htmlFor="isPartOfGroup" className="full-width">
                            Are you registering as part of a group, with
                            subsidiaries? *
                        </label>
                        <HTMLSelect
                            id="isPartOfGroup"
                            fill="true"
                            value={this.state.form.isPartOfGroup}
                            onChange={(e) =>
                                this.updateFormData(
                                    StateHandler.getStateObject(e)
                                )
                            }
                            rightElement={InputElementIcon(
                                `
                                If you are part of a group of businesses, with subsidiaries who you wish to also
                                register on Prosure360, select yes. Fill in the remainder of the registration
                                form as your parent company, and once registration is complete, you will be able
                                to add details of your subsidiaries.
                            `,
                                'info-circle'
                            )}
                            options={IS_PART_OF_GROUP_OPTIONS}
                        />
                    </FormGroup>
                    <FormGroup className="form-fill ">
                        <label
                            htmlFor="isServiceBySiteVisit"
                            className="full-width"
                        >
                            To Deliver Your Services Do You Attend Client Sites
                            ? *
                        </label>
                        <HTMLSelect
                            id="isServiceBySiteVisit"
                            fill="true"
                            defaultValue={this.state.form.isServiceBySiteVisit}
                            onChange={(e) =>
                                this.updateFormData(
                                    StateHandler.getStateObject(e)
                                )
                            }
                            rightElement={InputElementIcon(
                                `
                                Do you typically attend and access a client site/property to deliver the services
                                you offer (broadly defined as a contractor) or do you typically sell items or
                                deliver your services remotely (broadly defined as a supplier)?
                            `,
                                'info-circle'
                            )}
                            options={YES_NO_OPTIONS}
                        />
                    </FormGroup>
                    <div className="flex-between mt-5">
                        <Button
                            type="button"
                            text="Previous"
                            large={true}
                            onClick={this.props.closePanel}
                        />
                        <Button
                            type="submit"
                            text="Next"
                            large={true}
                            intent="primary"
                            onClick={this.props.processForm}
                        />
                    </div>
                </div>
                <div className="footer box-footer flex-start">
                    <Link to={this.props.cancelUrl ?? '/login'}>Cancel</Link>
                </div>
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyFields);
