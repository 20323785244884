import { Button, Checkbox, FormGroup, H1, InputGroup } from '@blueprintjs/core';
import { RouterHistory } from '@sentry/react/types/reactrouter';
import { ButtonLink, InputElementIcon } from 'components/elements';
import { Loading } from 'components/elements/wrappers';
import { useCallback, useEffect, useReducer, useState } from 'react';
import { toast } from 'react-toastify';
import { HTTP, Routing } from 'service';

interface ClientIntergrationsState {
    hasElogs: boolean;
    hasMeridian: boolean;
    elogsClientName: string;
    elogsApiUrl: string;
    elogsApiKey: string;
}

interface ClientIntegrationEditProps {
    clientResponse: { id: string };
    history: RouterHistory;
    match: any;
}

const DEFAULT_STATE = {
    hasElogs: false,
    hasMeridian: false,
    elogsClientName: '',
    elogsApiUrl: '',
    elogsApiKey: '',
};

const ClientIntegrationsEdit = (props: ClientIntegrationEditProps) => {
    const [loading, setLoading] = useState(true);

    const [state, setState]: [
        state: ClientIntergrationsState,
        setState: React.ReducerWithoutAction<any>
    ] = useReducer(
        (state: any, edits: any) => ({ ...state, ...edits }),
        DEFAULT_STATE
    );

    const load = useCallback(() => {
        const { clientResponse } = props;

        setLoading(true);

        HTTP.get(`/clients/${clientResponse?.id}/integration`).then(
            (response: any) => {
                if (response) {
                    if (response.data) {
                        const {
                            hasElogs,
                            hasMeridian,
                            elogsClientName,
                            elogsApiUrl,
                            elogsApiKey,
                        } = response.data;

                        setState({
                            hasElogs,
                            hasMeridian,
                            elogsClientName,
                            elogsApiUrl,
                            elogsApiKey,
                        });
                    } else {
                        setState(DEFAULT_STATE);
                    }

                    setLoading(false);

                    return true;
                }

                toast.error('Unable to fetch integration details');
                setLoading(false);

                return false;
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    useEffect(() => {
        load();
    }, [load]);

    const onError = () => {
        toast.error('Unable to update integrations');

        return false;
    };

    const handleSubmit = (e: any) => {
        HTTP.put(`/clients/${clientResponse?.id}/integration`, {
            ...state,
            hasElogs: Number(state.hasElogs),
            hasMeridian: Number(state.hasMeridian),
        }).then((response) => {
            if (!response) {
                onError();
            }

            toast.success('Integrations Updated');
            Routing.navigateUpOneLevel(props.history, props.match);

            return true;
        }, onError);
    };

    const renderElogsInstallationId = () => {
        if (!state.hasElogs) {
            return null;
        }

        const elogsNameLabel = (
            <>
                Elogs Client Name
                {InputElementIcon(
                    `CAFM Client name, that will be used by CAFM installations to query Prosure supply chain data.  Dev note: on CAFM systems this is defined by env var: ELOGBOOKS_CLIENT_NAME`,
                    'info'
                )}
            </>
        );

        const elogsAPILabel = (
            <>
                Elogs API URL
                {InputElementIcon(
                    `CAFM API Url, that is used by Prosure client users to query the CAFM Job list for a particular client installation`,
                    'info'
                )}
            </>
        );

        const elogsAPIKeyLabel = (
            <>
                Elogs API Key
                {InputElementIcon(
                    `CAFM User Token (intentionally x'd out here for security reasons, but can be updated), that is used by Prosure client users to query the CAFM Job list for a particular client installation`,
                    'info'
                )}
            </>
        );

        return (
            <>
                <FormGroup
                    label={elogsNameLabel}
                    labelFor="elogsClientName"
                    inline={true}
                    className="form-fill required"
                >
                    <InputGroup
                        id="elogsClientName"
                        fill={true}
                        placeholder="e.g. CBRE"
                        required
                        value={state.elogsClientName}
                        onChange={(e) =>
                            setState({
                                elogsClientName: e.target.value,
                            })
                        }
                    />
                </FormGroup>

                <FormGroup
                    label={elogsAPILabel}
                    labelFor="elogsApiUrl"
                    inline={true}
                    className="form-fill required"
                >
                    <InputGroup
                        id="elogsApiUrl"
                        fill={true}
                        placeholder="e.g. CBRE"
                        required
                        value={state.elogsApiUrl}
                        onChange={(e) =>
                            setState({
                                elogsApiUrl: e.target.value,
                            })
                        }
                    />
                </FormGroup>

                <FormGroup
                    label={elogsAPIKeyLabel}
                    labelFor="elogsApiKey"
                    inline={true}
                    className="form-fill required"
                >
                    <InputGroup
                        id="elogsApiKey"
                        fill={true}
                        placeholder="e.g. eyJ0eX....."
                        required
                        value={state.elogsApiKey}
                        onChange={(e) => {
                            setState({
                                elogsApiKey: e.target.value,
                            });
                        }}
                    />
                </FormGroup>
            </>
        );
    };

    const { clientResponse } = props;

    return (
        <div className="ClientIntegrationsAddEdit">
            <H1 className="clearfix">Edit Integrations</H1>

            <Loading isLoading={loading}>
                <form onSubmit={(event) => handleSubmit(event)}>
                    <FormGroup
                        label="Elogs CAFM Integration"
                        inline={true}
                        className="form-fill"
                        labelFor="elogsIntegration"
                    >
                        <Checkbox
                            id="elogsIntegration"
                            checked={state.hasElogs}
                            onChange={(e: any) =>
                                setState({
                                    hasElogs: !state.hasElogs,
                                })
                            }
                        />
                    </FormGroup>

                    {renderElogsInstallationId()}

                    <FormGroup
                        label="Meridian Integration"
                        inline={true}
                        className="form-fill"
                        labelFor="meridianIntegration"
                    >
                        <Checkbox
                            id="meridianIntegration"
                            checked={state.hasMeridian}
                            onChange={(e: any) =>
                                setState({
                                    hasMeridian: !state.hasMeridian,
                                })
                            }
                        />
                    </FormGroup>

                    <FormGroup>
                        <ButtonLink
                            type="button"
                            intent="default"
                            className="float-left"
                            to={`/clients/list/${clientResponse?.id}/integrations`}
                        >
                            Cancel
                        </ButtonLink>
                        <Button
                            type="submit"
                            intent="primary"
                            className="float-right"
                        >
                            Update
                        </Button>
                    </FormGroup>
                </form>
            </Loading>
        </div>
    );
};

export default ClientIntegrationsEdit;
