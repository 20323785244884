import { H1 } from '@blueprintjs/core';

import { ButtonLink, Icon } from 'components/elements';
import { ROLE_OPTIONS } from 'constants/role';
import { ClientComponentProps } from 'interface/Client';
import { isEmpty } from 'lodash';
import { Response } from 'service';

const DEFAULT_TEXT = 'No Recipients';

const NotificationsSummary = (props: ClientComponentProps) => {
    const { clientResponse, match } = props;

    const {
        notificationInviteeDeclined,
        notificationWeeklySummary,
        notificationSPJoinedSupplyChain,
        notificationSPLeavingSupplyChain
    } = clientResponse;

    const getRolesString = (notificationRoles: Array<string>) => {
        if (isEmpty(notificationRoles)) {
            return DEFAULT_TEXT;
        }

        return notificationRoles.map((roleValue) => {
                return ROLE_OPTIONS.filter(
                    (role) => role.value === roleValue
                )[0].label;
            })
            .join(', ');
    }

    return (
        <div className="NotificationsDetails">
            <H1 className="clearfix text-right">
                Notification Settings
                {Response.getLink(clientResponse, 'notification-settings') && (
                    <ButtonLink
                        type="button"
                        to={`${match.url}/edit`}
                        intent="primary"
                        className="float-right"
                    >
                        <Icon icon="pencil" />
                        Edit
                    </ButtonLink>
                )}
            </H1>
            <dl className="horizontal">
                <dt>Campaign Invitee Declined to Join</dt>
                <dd>{getRolesString(notificationInviteeDeclined)}</dd>
            </dl>

            <dl className="horizontal">
                <dt>Client Weekly Summary</dt>
                <dd>{getRolesString(notificationWeeklySummary)}</dd>
            </dl>

            <dl className="horizontal">
                <dt>New Service Provider in Supply Chain</dt>
                <dd>{getRolesString(notificationSPJoinedSupplyChain)}</dd>
            </dl>

            <dl className="horizontal">
                <dt>Service Provider Leaving Supply Chain</dt>
                <dd>{getRolesString(notificationSPLeavingSupplyChain)}</dd>
            </dl>
        </div>
    );
};

export default NotificationsSummary;
