import { ComponentProps } from 'react';
import { AssessmentErrorCollection, AssessmentStateData } from 'interface';
import { useSelector } from 'react-redux';
import { Card } from '@blueprintjs/core';
import { Icon } from 'components/elements';

interface QuestionErrorsProps extends ComponentProps<any> {
    questionId: string;
}

const QuestionErrors = (props: QuestionErrorsProps) => {
    const { errors }: AssessmentStateData = useSelector(
        (state: any) => state.assessments
    );

    const getError = (
        questionId: string
    ): AssessmentErrorCollection | undefined => {
        return errors.find((error) => error.question.id === questionId);
    };

    const error = getError(props.questionId);

    return (
        <>
            {error &&
                error.errors.map((assessmentError, index) => {
                    return (
                        <Card
                            key={'question_error_' + index}
                            className={`error question-error ${assessmentError.type}`}
                        >
                            <Icon icon="exclamation-triangle" />
                            {assessmentError.message}
                        </Card>
                    );
                })}
        </>
    );
};

export default QuestionErrors;
