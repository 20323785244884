import { useState, useEffect } from 'react';
import { Button, FormGroup, H1, TextArea } from '@blueprintjs/core';
import { toast } from 'react-toastify';

import { ButtonLink, Icon, InputElementIcon } from 'components/elements';
import { HTTP, Response, Routing, StateHandler } from 'service';
import { Loading } from 'components/elements/wrappers';
import MultiFileUpload from 'components/elements/MultiFileUpload';

const SupplyChainEdit = (props) => {
    const { history, match, clientResponse } = props;

    const [isLoading, setIsLoading] = useState(true);
    const [form, setForm] = useState({
        entryRequirementsDescription: null,
        files: [],
    });

    const load = () => {
        (async () => {
            if (clientResponse) {
                let assessmentLink = Response.getLink(
                    clientResponse,
                    'assessment-type'
                );

                if (
                    clientResponse.hasOwnProperty('clientAssessment') ||
                    Response.getLink(clientResponse.clientAssessment, 'self')
                ) {
                    assessmentLink = Response.getLink(
                        clientResponse.clientAssessment,
                        'self'
                    );
                }

                let files = [];
                if (Response.getLink(clientResponse, 'supply-chain-files')) {
                    const filesResponse = await HTTP.get(
                        Response.getLink(clientResponse, 'supply-chain-files')
                    );
                    files = filesResponse.data.files;
                }

                setForm({
                    entryRequirementsDescription:
                        clientResponse.entryRequirementsDescription,
                    requiresClientSpecificAssessment:
                        clientResponse.requiresClientSpecificAssessment,
                    clientAssessment: clientResponse.clientAssessment,
                    requiresProsurePreQualified:
                        clientResponse.requiresProsurePreQualified,
                    requiresProsureSSIPVerified:
                        clientResponse.requiresProsureSSIPVerified,
                    allowProsureDTS: clientResponse.allowProsureDTS,
                    files: files,
                    _links: {
                        'assessment-type': {
                            href: assessmentLink,
                        },
                    },
                });
            }

            setIsLoading(false);
        })();
    };

    useEffect(load, [clientResponse]);

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        return HTTP.put(
            Response.getLink(clientResponse, 'edit-supply-chain-config'),
            form
        ).then((response) => {
            if (response) {
                toast.success('Supply Chain Config updated');
                Routing.navigateUpOneLevel(history, match);

                if (props.reloadData) {
                    props.reloadData();
                }

                return true;
            }

            toast.error('Unable to update Supply Chain Config');

            return false;
        });
    };

    const onFileUpdate = (files, initialise) => {
        const links = [];
        files.forEach((file, index) => {
            links.push(file);
        });
        setForm({ ...form, files: links });
    };

    const entryRequirementsLogo = (
        <>
            Entry Requirements Description
            {InputElementIcon(
                'The text shown to the Service Provider when they are invited to join a Client Supply Chain',
                'info'
            )}
        </>
    );

    return (
        <div className="supplChainConfigEdit">
            <H1>Edit Supply Chain Config</H1>

            <Loading isLoading={isLoading}>
                <form className="AddEdit" onSubmit={(e) => handleSubmit(e)}>
                    <FormGroup
                        label={entryRequirementsLogo}
                        inline={true}
                        className="form-fill required"
                        labelFor="entryRequirementsDescription"
                    >
                        <TextArea
                            id="entryRequirementsDescription"
                            placeholder="Entry Requirements Description (Optional)"
                            onChange={(e) =>
                                setForm(StateHandler.getStateObject(e, form))
                            }
                            rows={5}
                            required
                            maxLength={500}
                            value={form.entryRequirementsDescription}
                        />
                    </FormGroup>

                    <FormGroup
                        label="Documents to Confirm"
                        inline={true}
                        className="form-fill"
                    >
                        <MultiFileUpload
                            onUpdate={onFileUpdate}
                            uploadLinksObject={form.files}
                            allowDocumentsOnly={true}
                            isRequired={false}
                            inputID="documents"
                        />
                    </FormGroup>

                    <FormGroup>
                        <ButtonLink
                            type="button"
                            intent="default"
                            className="float-left"
                            to={Routing.getRouteUpOneLevel(history, match)}
                        >
                            <Icon icon="ban" />
                            Cancel
                        </ButtonLink>
                        <Button
                            type="submit"
                            intent="primary"
                            className="float-right"
                        >
                            <Icon icon="paper-plane" />
                            Submit
                        </Button>
                    </FormGroup>
                </form>
            </Loading>
        </div>
    );
};

export default SupplyChainEdit;
