import {
    Button,
    FormGroup,
    H2,
    HTMLSelect,
    InputGroup,
    TextArea,
} from '@blueprintjs/core';
import { DateInput } from '@blueprintjs/datetime';
import { DateTime } from 'luxon';
import moment from 'moment';
import { FormEvent, useEffect, useState } from 'react';
import { withRouter } from 'react-router';

import {
    ButtonLink,
    Icon,
    InputElementIcon,
    SuggestRenderer,
} from 'components/elements';
import { globalDebounce } from 'helpers/helpers';
import { ClientData, ClientListData } from 'interface';
import {
    Campaign,
    CAMPAIGN_STATUS_OPTIONS,
    CampaignComponentProps,
    CampaignStatus,
} from 'interface/Client/Campaign';
import { toast } from 'react-toastify';
import { ClientService, HTTP, Response, Routing } from 'service';

interface CampaignAddEditComponentProps extends CampaignComponentProps {
    merge?: (form: Campaign) => void;
}

const CampaignAddEdit = (props: CampaignAddEditComponentProps) => {
    const { campaign, history, match } = props;
    const [form, setForm] = useState<Campaign>(
        campaign ?? ({ status: CampaignStatus.ACTIVE } as Campaign)
    );
    const [clients, setClients] = useState<ClientData[]>([]);
    const [clientSearchValue, setClientSearchValue] = useState<string>('');
    const [isLoading, setIsLoading] = useState(true);
    const isEditing = !!campaign;
    const maxDate = DateTime.local().plus({ years: 5 }).toJSDate();

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if (Response.getLink(form, 'edit')) {
            const response = await HTTP.put(
                Response.getLink(form, 'edit'),
                form
            );
            if (response) {
                toast.success('Successfully Edited Campaign');
            }
        } else {
            const response = await HTTP.post('/clients/campaigns/new', form);
            if (response) {
                toast.success('Successfully Created New Campaign');
            }
        }

        if (props.merge) {
            props.merge(form);
        }

        Routing.navigateUpOneLevel(history, match, false);
    };

    const clientSearch = (value: string) => {
        globalDebounce(
            () => {
                setClientSearchValue(value ?? '');
                loadClients(value);
            },
            'clientSearch',
            250
        );
    };

    const loadClients = async (value: string) => {
        let params: any = {};

        if (value) {
            params.name = value;
        }

        const response: ClientListData | null =
            await ClientService.loadClientList(params);
        if (response) {
            setClients(response.clients);
        }
    };

    useEffect(() => {
        if (isLoading) {
            loadClients('');
            setIsLoading(false);
        }
    }, [isLoading]);

    return (
        <div className="CampaignAddEdit">
            <H2>{campaign ? 'Edit' : 'Add'} Campaign</H2>
            <form
                className="AddEdit"
                onSubmit={(event: FormEvent<HTMLFormElement>) =>
                    handleSubmit(event)
                }
            >
                <FormGroup
                    label="Client"
                    key="client"
                    inline={true}
                    className="form-fill required"
                    labelFor="client"
                >
                    <SuggestRenderer
                        id="client"
                        valueProperty="name"
                        onItemSelect={(item: ClientData) => {
                            let clientObject = {
                                href: Response.getLink(item, 'self'),
                                name: item.name,
                            };
                            let links = Object.assign({}, form._links, {
                                client: clientObject,
                            });
                            let newFormState = Object.assign({}, form, {
                                _links: links,
                            });
                            setForm(newFormState);

                            return;
                        }}
                        defaultItem={form.client}
                        items={clients}
                        onKeyUp={(event: KeyboardEvent) => {
                            const { value } = event.target as HTMLFormElement;
                            if (clientSearchValue !== value.trim()) {
                                clientSearch(value);
                            }
                        }}
                        isRequired={true}
                    />
                </FormGroup>

                <FormGroup
                    label="Name"
                    inline={true}
                    className="form-fill required"
                    labelFor="name"
                >
                    <InputGroup
                        id="name"
                        placeholder="Name"
                        minLength={1}
                        maxLength={75}
                        value={form.name}
                        onChange={(e) =>
                            setForm({ ...form, name: e.target.value })
                        }
                        required
                    />
                </FormGroup>

                <FormGroup
                    label="Description"
                    inline={true}
                    className="form-fill"
                    labelFor="description"
                >
                    <TextArea
                        id="description"
                        placeholder="Description"
                        value={form.description}
                        onChange={(e) =>
                            setForm({ ...form, description: e.target.value })
                        }
                    />
                </FormGroup>

                <FormGroup
                    label="Campaign Start Date"
                    inline={true}
                    className="form-fill required"
                    labelFor="startDate"
                >
                    <DateInput
                        maxDate={maxDate}
                        parseDate={(str) => moment(str, 'DD/MM/YYYY').toDate()}
                        formatDate={(date) =>
                            DateTime.fromJSDate(date).toLocaleString()
                        }
                        popoverProps={{ position: 'bottom', fill: true }}
                        inputProps={{
                            fill: true,
                            id: 'startDate',
                            required: true,
                            rightElement: InputElementIcon(
                                'Select a date...',
                                'calendar-alt'
                            ),
                        }}
                        onChange={(newDate) =>
                            setForm({
                                ...form,
                                startDate: new Date(+newDate + 1000),
                            })
                        }
                        value={form.startDate ? new Date(form.startDate) : null}
                    />
                </FormGroup>

                <FormGroup
                    label="State"
                    key="isActive"
                    inline={true}
                    className="form-fill"
                    labelFor="isActive"
                >
                    <HTMLSelect
                        id="isActive"
                        fill={true}
                        defaultValue={form.status}
                        onChange={(e: any) => {
                            setForm({
                                ...form,
                                status: e.target.value,
                            });
                        }}
                        options={CAMPAIGN_STATUS_OPTIONS}
                    />
                </FormGroup>

                <FormGroup>
                    <ButtonLink
                        type="button"
                        intent="default"
                        className="float-left"
                        to={
                            isEditing
                                ? `/clients/campaigns/list/${form.id}`
                                : '/clients/campaigns/list'
                        }
                    >
                        <Icon icon="ban" />
                        Cancel
                    </ButtonLink>
                    <Button
                        type="submit"
                        intent="primary"
                        className="float-right"
                    >
                        <Icon icon="paper-plane" />
                        {isEditing ? 'Update' : 'Create'}
                    </Button>
                </FormGroup>
            </form>
        </div>
    );
};

export default withRouter(CampaignAddEdit);
