import { NONE_SELECTED_OPTION } from 'constants/none-selected';


const CONTRACT_STATUSES = {
    ACTIVE: 'Active',
    EXPIRED: 'Expired',
    PENDING: 'Pending',
};
const INSURANCE_ACTIVE = { label: CONTRACT_STATUSES.ACTIVE, value: 'active' };
const INSURANCE_EXPIRED = { label: CONTRACT_STATUSES.EXPIRED, value: 'expired' };

const CONTRACT_STATUS_OPTIONS = [
    NONE_SELECTED_OPTION,
    INSURANCE_ACTIVE,
    INSURANCE_EXPIRED,
];

export {
    CONTRACT_STATUSES,
    CONTRACT_STATUS_OPTIONS,
}
