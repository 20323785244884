import { Redirect, Switch } from 'react-router-dom';

import SubscriptionAddEdit from 'components/clients/subscriptions/SubscriptionAddEdit';
import SubscriptionDetails from 'components/clients/subscriptions/SubscriptionDetails';
import SubscriptionList from 'components/clients/subscriptions/SubscriptionList';
import { Loading } from 'components/elements/wrappers';
import { Routing } from 'service';

const Subscriptions = (props) => {
    const { match, clientResponse } = props;

    return (
        <>
            <Loading isLoading={typeof clientResponse.id === 'undefined'}>
                <Switch>
                    <Routing.ProppedRoute
                        path={`${match.url}`}
                        component={SubscriptionList}
                        clientResponse={clientResponse}
                        exact
                    />
                    <Routing.ProppedRoute
                        path={`${match.url}/add`}
                        component={SubscriptionAddEdit}
                        clientResponse={clientResponse}
                        exact
                    />
                    <Routing.ProppedRoute
                        path={`${match.url}/:id`}
                        component={SubscriptionDetails}
                        clientResponse={clientResponse}
                    />
                    <Redirect to={`${match.url}`} />
                </Switch>
            </Loading>
        </>
    );
};

export default Subscriptions;
