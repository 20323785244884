import { CompanyData, ContractData, ContractList, FlatObject } from 'interface';
import { HTTP, Response, Filters } from 'service';

export const loadContracts = (
    company: CompanyData,
    filters: FlatObject
): Promise<ContractList> => {
    return HTTP.action(
        'get',
        Response.getLink(company, 'view-contracts'),
        Filters.convertFilterDataToRequestData(filters),
        void 0,
        'Could not get Contract list'
    ).then((response) => response?.data);
};

export const loadContract = (
    company: CompanyData,
    id: string
): Promise<ContractData> => {
    return HTTP.action(
        'get',
        Response.getLink(company, 'view-contracts') + "/" + id,
        void 0,
        void 0,
        'Could not get Contract'
    ).then((response) => response?.data);
};

export const editContract = (
    contract: ContractData,
    form: ContractData
): Promise<ContractData> => {
    return HTTP.action(
        'put',
        Response.getLink(contract, 'edit'),
        form,
        undefined,
        'Could not update Contract'
    ).then((response) => response?.data);
};

export const addContract = (
    company: CompanyData,
    form: ContractData
): Promise<ContractData | void> => {
    return HTTP.action(
        'post',
        Response.getLink(company, 'add-contract'),
        form,
        undefined,
        'Could not create Contract',
        HTTP.handleFormErrors
    ).then((response) => response?.data);
};
