import { H1, H4 } from '@blueprintjs/core';
import { Table } from 'components/elements';
import { Loading, NoResultsFound } from 'components/elements/wrappers';
import { CAFMSiteData } from 'interface';
import { ELBSitesListData } from 'interface/Integrations';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { loadELBSites } from 'service/Integration/IntegrationService';

interface SitesBelowRequirementsTableProps {
    clientId: string;
}

const SitesBelowRequirementsTable = (
    props: SitesBelowRequirementsTableProps
) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [response, setResponse] = useState<ELBSitesListData>(
        {} as ELBSitesListData
    );

    useEffect(() => {
        if (props.clientId) {
            loadSites();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.clientId]);

    const loadSites = async () => {
        setIsLoading(true);

        const response = await loadELBSites(props.clientId);

        setResponse(response);

        setIsLoading(false);
    };

    const columns = [
        {
            name: 'Site Name',
            property: 'elogbooksName',
            grow: 3,
        },
        {
            name: 'Jobs Completed',
            property: 'belowRequirementCount',
            type: 'callback',
            grow: 3,
            callback: (row: CAFMSiteData) => {
                const spURL = encodeURI(
                    `/supply-chain/elogs-cafm-stats/list?limit=10&type=reactive&order=-completionAt&page=1&site=${row.elogbooksID}&statusAtCompletion=2&site-object={"id":${row.elogbooksID},"name":"${row.elogbooksName}"}`
                );
                return <Link to={spURL}>{row.belowRequirementCount}</Link>;
            },
        },
    ];

    return (
        <div>
            <H1>Site Overview - Below Requirements</H1>
            <H4>
                (Showing all Jobs completed Below Requirements per site for the
                last 30 days)
            </H4>
            <Loading isLoading={isLoading}>
                <NoResultsFound count={response?.sites?.length}>
                    <Table data={response?.sites} columns={columns} />
                </NoResultsFound>
            </Loading>
        </div>
    );
};

export default SitesBelowRequirementsTable;
