import {
    SIDEBAR_COLLAPSE,
    SIDEBAR_TOGGLE
} from 'action/sidebar';

const defaultState = {
    isExpanded: true,
};

export default function sidebar(state = defaultState, action)
{
    const baseState = Object.assign({}, defaultState, state);

    switch (action.type) {
        case SIDEBAR_COLLAPSE:
            return Object.assign(baseState, {
                isExpanded: false,
            });

        case SIDEBAR_TOGGLE:
            return Object.assign(baseState, {
                isExpanded: !state.isExpanded,
            });

        // case LOGOUT:
        //     return Object.assign({}, defaultState);

        default:
            return Object.assign(baseState);
    }
}
