import { H3 } from '@blueprintjs/core';
import { useEffect, useReducer } from 'react';

import { DonutChart, TooltipIcon } from 'components/elements';
import { Loading } from 'components/elements/wrappers';
import { SupplyChainReducerState } from 'interface';
import { ClientService } from 'service';

const SupplyChainChart = (props: any) => {
    const { client, selectedCategory } = props;
    const [supplyChainState, setSupplyChainState]: [
        state: SupplyChainReducerState,
        setState: React.ReducerWithoutAction<any>
    ] = useReducer(
        (state: SupplyChainReducerState, edits: any) => {
            return { ...state, ...edits };
        },
        {
            isVisible: true,
            isLoading: true,
            counts: void 0,
            satisfiedFraction: 0,
            colours: [
                { name: 'below', label: 'Below', colour: '#ed5565' },
                { name: 'expiring', label: 'Expiring', colour: '#d9822b' },
                { name: 'meetsAll', label: 'Meets All', colour: '#7bbb4c' },
            ],
        }
    );
    const load = async () => {
        setSupplyChainState({ isLoading: true });
        if (client === null || !client?.id) {
            setSupplyChainState({ isLoading: false, counts: void 0 });
            return;
        }
        if (client.id === 'all-clients') {
            setSupplyChainState({
                isVisible: false,
                isLoading: false,
                counts: void 0,
            });
        } else {
            setSupplyChainState(
                await ClientService.getSupplyChainRequirementsState(
                    client,
                    selectedCategory
                )
            );
        }
    };

    useEffect(() => {
        load();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client, selectedCategory]);

    return (
        <>
            {supplyChainState.isVisible && (
                <section className="supply-chain-card info-card chart-parent">
                    <section className="info-card-header">
                        <h2 className="title">
                            <TooltipIcon
                                text={
                                    <section>
                                        Status of your service providers in your
                                        supply chain.
                                        <br />
                                        <br />
                                        Green - Meets Client Requirements
                                        <br />
                                        Amber - Requirements Expiring Soon
                                        <br />
                                        Red - Below Client Requirements
                                        <br />
                                        <br />
                                        Central figure is a percentage sum of
                                        green and amber service providers, all
                                        those currently meeting your
                                        requirements.
                                    </section>
                                }
                                icon="info"
                            />
                            <span className="ps-2">Supply Chain Status</span>
                        </h2>
                    </section>
                    <section className="info-card-body flex-justify-center flex-v-center">
                        <Loading isLoading={supplyChainState.isLoading}>
                            {!supplyChainState.counts ? (
                                <H3>
                                    There are currently no Service Providers in
                                    your supply chain
                                </H3>
                            ) : (
                                <DonutChart
                                    data={supplyChainState.counts}
                                    height={250}
                                    width={250}
                                    legendWidth={210}
                                    text={
                                        supplyChainState.satisfiedFraction + '%'
                                    }
                                    colours={supplyChainState.colours ?? []}
                                />
                            )}
                        </Loading>
                    </section>
                </section>
            )}
        </>
    );
};

export default SupplyChainChart;
