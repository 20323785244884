import { FlatObject } from 'interface';
import { MouseEvent } from 'react';
import { toast } from 'react-toastify';
import './index.scss';

const MultiLevelDisplay = (props: any) => {
    // https://stackoverflow.com/questions/51805395/navigator-clipboard-is-undefined
    async function copyToClipboard(textToCopy: string) {
        // Navigator clipboard api needs a secure context (https)
        if (navigator.clipboard && window.isSecureContext) {
            await navigator.clipboard.writeText(textToCopy);
            toast.success(`copied token to clipboard`);
        } else {
            // Use the 'out of viewport hidden text area' trick
            const textArea = document.createElement('textarea');
            textArea.value = textToCopy;

            // Move textarea out of the viewport so it's not visible
            textArea.style.position = 'absolute';
            textArea.style.left = '-999999px';

            document.body.prepend(textArea);
            textArea.select();

            try {
                document.execCommand('copy');
            } catch (error) {
                console.error(error);
            } finally {
                textArea.remove();
                toast.success(`copied token to clipboard`);
            }
        }
    }

    const displaySection = (
        data: FlatObject,
        key: string,
        parentText = null
    ) => {
        return (
            <div
                key={key}
                className={'section' + (data.children ? ' parent' : ' barren')}
            >
                <span
                    className="title"
                    onMouseUp={(e: MouseEvent) => {
                        copyToClipboard(
                            `{{${parentText ? parentText + '.' : ''}${
                                data.value
                            }}}`
                        );
                    }}
                >
                    {data.value}
                </span>
                {data.children && (
                    <div className="children">
                        {data.children.map(
                            (childData: FlatObject, index: number) =>
                                displaySection(
                                    childData,
                                    data.value + '-child-' + index,
                                    parentText
                                        ? parentText + '.' + data.value
                                        : data.value
                                )
                        )}
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className="multi-level-section">
            {props.data.map((childData: FlatObject, index: number) =>
                displaySection(childData, 'section-start-' + index)
            )}
        </div>
    );
};

export default MultiLevelDisplay;
