const routes = {
    assessments: {
        href: '/assessments',
        text: 'Assessments',
        icon: 'cubes',
    },
    assessments_in_progress: {
        href: '/assessments/in-progress',
        text: 'In Progress',
        icon: 'cubes',
    },
    assessments_completed: {
        href: '/assessments/completed',
        text: 'Completed Assessments',
        icon: 'cubes',
    },
    assessments_view: {
        href: '/assessments/:id/view',
        text: '{assessment.completion}',
        skipNavigation: true,
    },
    assessment_view_questions: {
        href: '/assessments/:id/view/questions',
        text: 'Questions'
    },
    assessments_purchase: {
        href: '/assessments/:id/purchase',
        text: '{assessment.purchase}',
        skipNavigation: true,
    },
    assessments_payment: {
        href: '/assessments/:id/payment',
        text: '{assessment.payment}',
        skipNavigation: true,
    },
};

export default routes;
