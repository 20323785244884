const INSTANCE_STATUS_CREATED = {
    label: 'Created',
    value: 'instance_created',
};
const INSTANCE_STATUS_PURCHASED = {
    label: 'Purchased',
    value: 'instance_purchased',
};
const INSTANCE_STATUS_STARTED = {
    label: 'Started',
    value: 'instance_started',
};
const INSTANCE_RESEND_RECEIPT = {
    label: 'Resend Receipt',
    value: 'instance_resend_receipt',
};
const INSTANCE_STATUS_UPDATED = {
    label: 'In-Progress',
    value: 'instance_updated',
};
const INSTANCE_STATUS_SUBMITTED = {
    label: 'Submitted - Awaiting Assessor',
    value: 'instance_submitted',
};
const INSTANCE_STATUS_RETURNED = {
    label: 'Submitted - Returned with Feedback',
    value: 'instance_returned',
};
const INSTANCE_STATUS_SEND_TO_REVIEWER = {
    label: 'Submitted - Awaiting Reviewer',
    value: 'instance_send_to_reviewer',
};
const INSTANCE_STATUS_COMPLETED = {
    label: 'Completed - Current',
    value: 'instance_completed',
};
const INSTANCE_STATUS_COMPLETED_EXPIRED = {
    label: 'Completed - Expired',
    value: 'instance_completed_expired',
};
const INSTANCE_STATUS_ASSESSMENT_UPDATED = {
    label: 'In-Progress',
    value: 'instance_assessment_updated',
};
const INSTANCE_STATUS_RETURN_TO_ASSESSOR = {
    label: 'Reviewed - Awaiting Assessor',
    value: 'instance_return_to_assessor',
};
const INSTANCE_STATUS_ASSIGNED_TO_ASSESSOR = {
    label: 'Submitted - Being Assessed',
    value: 'instance_assigned_to_assessor',
};
const INSTANCE_STATUS_ASSIGNED_TO_REVIEWER = {
    label: 'Submitted - Being Reviewed',
    value: 'instance_assigned_to_reviewer',
};
const INSTANCE_STATUS_ADMIN_ASSIGN_ASSESSOR = {
    label: 'Submitted - Admin Change Assessor',
    value: 'instance_admin_assign_assessor',
};
const INSTANCE_STATUS_ASSESSOR_DTS_UPDATED = {
    label: 'Submitted - Assessor DTS fields updated',
    value: 'instance_assessor_dtsupdated',
};

export const SLA_STATUSES = {
    sla_met: 'Met',
    sla_failed: 'Failed',
    sla_ongoing: 'Ongoing',
};

export const RENEWAL_UPDATE_DIALOG_REASONS = [
    {
        label: 'No Longer Working For Client',
        value: 'No Longer Working For Client'
    },
    {
        label: 'No Longer Want To Be On Prosure',
        value: 'No Longer Want To Be On Prosure'
    },
    {
        label: 'Not Interested',
        value: 'Not Interested'
    },
    {
        label: 'Other',
        value: 'Other'
    },
];

const INSTANCE_STATUSES = [
    INSTANCE_STATUS_CREATED,
    INSTANCE_STATUS_PURCHASED,
    INSTANCE_STATUS_STARTED,
    INSTANCE_RESEND_RECEIPT,
    INSTANCE_STATUS_UPDATED,
    INSTANCE_STATUS_SUBMITTED,
    INSTANCE_STATUS_RETURNED,
    INSTANCE_STATUS_SEND_TO_REVIEWER,
    INSTANCE_STATUS_COMPLETED,
    INSTANCE_STATUS_ASSESSMENT_UPDATED,
    INSTANCE_STATUS_RETURN_TO_ASSESSOR,
    INSTANCE_STATUS_ASSIGNED_TO_ASSESSOR,
    INSTANCE_STATUS_ASSIGNED_TO_REVIEWER,
    INSTANCE_STATUS_ADMIN_ASSIGN_ASSESSOR,
    INSTANCE_STATUS_ASSESSOR_DTS_UPDATED,
];

export interface NoRenewalUpdate {
    comment?: string;
    reason: string;
}

export {
    INSTANCE_RESEND_RECEIPT,
    INSTANCE_STATUSES,
    INSTANCE_STATUS_ADMIN_ASSIGN_ASSESSOR,
    INSTANCE_STATUS_ASSESSMENT_UPDATED,
    INSTANCE_STATUS_ASSESSOR_DTS_UPDATED,
    INSTANCE_STATUS_ASSIGNED_TO_ASSESSOR,
    INSTANCE_STATUS_ASSIGNED_TO_REVIEWER,
    INSTANCE_STATUS_COMPLETED,
    INSTANCE_STATUS_COMPLETED_EXPIRED,
    INSTANCE_STATUS_CREATED,
    INSTANCE_STATUS_PURCHASED,
    INSTANCE_STATUS_RETURNED,
    INSTANCE_STATUS_RETURN_TO_ASSESSOR,
    INSTANCE_STATUS_SEND_TO_REVIEWER,
    INSTANCE_STATUS_STARTED,
    INSTANCE_STATUS_SUBMITTED,
    INSTANCE_STATUS_UPDATED
};

