import { useEffect, useState } from 'react';
import { Switch } from 'react-router';

import { ProtectedRoute } from 'components/elements';
import { loadContract } from 'service/Client/CompanyContractService';
import { Loading } from 'components/elements/wrappers';
import ContractInfo from './ContractInfo';
import ContractAddEdit from './ContractAddEdit';

const ContractDetails = (props: any) => {
    const { companyResponse, match } = props;
    const id = match.params.id;
    const [contract, setContract] = useState((state: any) => state);

    useEffect(() => {
        load();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const load = async () => {
        const contract = await loadContract(companyResponse, id);
        setContract(contract);
    }

    return <>
        <div className="certificationMembershipDetails">
            <Loading isLoading={ !contract }>
                <Switch>
                    <ProtectedRoute
                        path={`${match.url}`}
                        component={ ContractInfo }
                        companyResponse={ companyResponse }
                        contract={ contract }
                        exact
                    />
                    <ProtectedRoute
                        path={`${match.url}/edit`}
                        component={ ContractAddEdit }
                        reloadData= { load }
                        companyResponse={ companyResponse }
                        contract={ contract }
                        exact
                    />
                </Switch>
            </Loading>
        </div>
    </>;
};

export default ContractDetails;
