import { useEffect, useState } from "react";
import { Link, Redirect, Switch } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Tab, Tabs } from "@blueprintjs/core";

import { Icon, ProtectedRoute } from "components/elements";
import { Loading } from "components/elements/wrappers";
import { Routing } from "service";
import DiscountSummary from "components/admin/discounts/DiscountSummary";
import { DiscountData } from "interface/Discount";
import DiscountService from "service/Discount/DiscountService";
import { updatingBreadcrumbResolves } from "action/breadcrumb";
import DiscountAddEdit from './DiscountAddEdit';

const DiscountDetails = (props: any) => {
    const { match, location } = props;
    const dispatch = useDispatch();
    const [ isLoading, setIsLoading ] = useState(false);
    const tabId = Routing.getSelectedTabId(location.pathname, match.url);
    const [ discountResponse, setDiscountResponse ] = useState<DiscountData>(DiscountService.createDiscount);

    const load = () =>
    {
        (async () => {
            if (isLoading) { return; }

            setIsLoading(true);
            const discountData : DiscountData = await DiscountService.loadDiscount(props.match.params.discountId);
            dispatch(updatingBreadcrumbResolves({discount: {code: discountData.code}}));
            setDiscountResponse(discountData);
            setIsLoading(false);
        })();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(load, []);

    return <div className="DiscountDetails">
        <Loading isLoading={ isLoading }>
            <h1 className="clearfix">
                { discountResponse.code }
            </h1>

            <Tabs renderActiveTabPanelOnly={true} selectedTabId={tabId}>
                <Tab id='/summary'>
                    <Link to={`${match.url}/summary`}>
                        <Icon icon="info" />
                        Summary
                    </Link>
                </Tab>
            </Tabs>
            <div className="TabContent">
                <Switch>
                    <ProtectedRoute
                        path={`${match.url}/summary`}
                        component={ DiscountSummary }
                        discountResponse={ discountResponse }
                        reloadData={ load }
                        exact />
                    <ProtectedRoute
                        path={`${match.url}/summary/edit`}
                        component={ DiscountAddEdit }
                        discountResponse={ discountResponse }
                        reloadData={ load }
                        exact />

                    <Redirect to={`${match.url}/summary`} />
                </Switch>
            </div>
        </Loading>
    </div>
}

export default DiscountDetails;
