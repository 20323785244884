import {
    ComponentProps,
    SyntheticEvent,
    useEffect,
    useReducer,
    useState,
} from 'react';

import { Company, ListCollection } from 'interface';
import {
    CampaignUpdatesListFilter,
    InviteeStatus,
} from 'interface/Client/Campaign';
import { toast } from 'react-toastify';
import { CompanyService } from 'service';
import CampaignUpdateDialog from './CampaignUpdateDialog';

interface AllCompanyUpdatesProps extends ComponentProps<any> {
    company: Company;
    isOpen: boolean;
    onClose: Function;
    reloadData?: Function;
}

const AllCompanyUpdates = (props: AllCompanyUpdatesProps) => {
    const { company, isOpen, onClose, reloadData } = props;
    const [state, setState] = useReducer(
        (state: any, edits: any) => {
            return { ...state, ...edits };
        },
        {
            dialogOpen: !!isOpen,
            isLoading: true,
            statusMessage: '',
            updateType: null,
        }
    );

    const [statuses, setStatuses] = useState<ListCollection<InviteeStatus>>(
        {} as ListCollection<InviteeStatus>
    );

    useEffect(() => {
        (async () => {
            setState({ isLoading: true });
            await load();
            setState({ dialogOpen: !!isOpen, isLoading: false });
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [company, isOpen]);

    // cleanup
    useEffect(
        () => () => {
            setStatuses({} as ListCollection<InviteeStatus>);
        },
        []
    );

    const load = async () => {
        const data = await CompanyService.getInviteeStatuses(
            company,
            {} as CampaignUpdatesListFilter
        );

        data && setStatuses(data);
    };

    const dialogClose = (event: SyntheticEvent) => {
        setState({
            dialogOpen: false,
            statusMessage: '',
            isLoading: false,
            updateType: '',
        });
        typeof onClose === 'function' && onClose(event);
    };

    const dialogConfirm = async () => {
        if (!state.updateType) {
            toast.error('Please select a update type');
            return;
        }

        //use invitee and submit here
        setState({ isLoading: true });

        const { statusMessage, isDeclined, updateType, followUpDate } = state;

        const response = await CompanyService.postCompanyInviteeStatus(
            company,
            {
                statusMessage,
                isDeclined,
                updateType,
                followUpDate,
            }
        );

        response && toast.success('Status has been updated successfully.');

        if (typeof reloadData === 'function') {
            reloadData();
        }

        dialogClose({} as SyntheticEvent);
    };

    const handleOnChange = (key: string, value: any) => {
        setState({ [key]: value });
    };

    return (
        <CampaignUpdateDialog
            onClose={dialogClose}
            updates={statuses}
            isOpen={state.dialogOpen}
            title={`View Campaign Updates - ${company.registeredCompanyName}`}
            onChange={handleOnChange}
            initialData={state}
            isLoading={state.isLoading}
            onConfirm={dialogConfirm}
            isGlobalUpdate={true}
        />
    );
};

export default AllCompanyUpdates;
