import { Redirect, Switch } from 'react-router-dom';

import { ProtectedRoute } from 'components/elements';
import AssessmentTypes from 'components/review/assessment-types/AssessmentTypes';
import PurchasedSupplyChainsList from 'components/review/supply-chains/PurchasedSupplyChainsList';

const Review = (props) => {
    const { match } = props;

    return (
        <Switch>
            <ProtectedRoute
                path={`${match.url}/purchased-supplychains`}
                component={PurchasedSupplyChainsList}
                exact
            />
            <ProtectedRoute path={`${match.url}`} component={AssessmentTypes} />
            <Redirect to={`${match.url}`} />
        </Switch>
    );
};

export default Review;
