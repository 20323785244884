import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip2 } from '@blueprintjs/popover2';

import { SHAPE_COMPANY_RESPONSE } from 'constants/company';
import './index.scss';

const ClientRequirements = (props) => {
    const { company, selectedCategory, supplyChain } = props;
    const STATUS_ALL_MET = 'SUCCESS';
    const STATUS_EXPIRING = 'EXPIRING';
    const STATUS_BELOW = 'BELOW';
    const [requirements, setRequirements] = useState({
        status: STATUS_ALL_MET,
        messages: [],
    });

    useEffect(() => {
        supplyChain && setRequirements({
            status: supplyChain.requirementStatus,
            messages: supplyChain.clientRequirementsRequired,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [company, selectedCategory, supplyChain]);

    const clientRequirementsStatus = () => {
        switch (requirements.status) {
            case STATUS_ALL_MET: // Meets All Requirements
                return (
                    <div className="client-requirements all-met">
                        <span>Meets All Requirements</span>
                        <img
                            className="icon"
                            src="/assets/custom-icons/all-met.svg"
                            alt="tick icon"
                            title="Meets All Requirements"
                        />
                    </div>
                );
            case STATUS_EXPIRING: // Requirements Expiring Soon
                return (
                    <Tooltip2
                        content={
                            <ul className="client-requirements-tooltip expiring">
                                {requirements.messages.map((message, key) => (
                                    <li key={`message-${key}`}>{message}</li>
                                ))}
                            </ul>
                        }
                    >
                        <div className="client-requirements expiring">
                            <span>Requirements Expiring Soon</span>
                            <img
                                className="icon"
                                src="/assets/custom-icons/expiring.svg"
                                alt="expiring icon"
                                title="Requirements Expiring Soon"
                            />
                        </div>
                    </Tooltip2>
                );
            case STATUS_BELOW: // Below Your Requirements
                return (
                    <Tooltip2
                        content={
                            <ul className="client-requirements-tooltip below">
                                {requirements.messages.map((message, key) => (
                                    <li key={`message-${key}`}>{message}</li>
                                ))}
                            </ul>
                        }
                    >
                        <div className="client-requirements below">
                            <span>Below Requirements</span>
                            <img
                                className="icon"
                                src="/assets/custom-icons/below.svg"
                                alt="alert icon"
                                title="Below Your Requirements"
                            />
                        </div>
                    </Tooltip2>
                );
            default:
                return null;
        }
    };

    return clientRequirementsStatus();
};

ClientRequirements.propTypes = {
    company: PropTypes.shape(SHAPE_COMPANY_RESPONSE).isRequired,
};

export default ClientRequirements;
