const STATUS_PENDING = 0;
const STATUS_ACCEPTED = 1;
const STATUS_DECLINED = 2;
const STATUS_REMOVED = 3;
const STATUSES = {
    STATUS_PENDING: 'status_pending',
    STATUS_ACCEPTED: 'status_accepted',
    STATUS_DECLINED: 'status_declined',
    STATUS_REMOVED: 'status_removed',
};

export {
    STATUSES,
    STATUS_PENDING,
    STATUS_ACCEPTED,
    STATUS_DECLINED,
    STATUS_REMOVED,
};
