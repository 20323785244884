import campaignsRoutes from 'routes/client/campaign';
import serviceProviderCategoryRoutes from 'routes/client/sp-category';
import supplyChainConfigRoutes from 'routes/client/supply-chain-config';

const routes = {
    clients: {
        href: '/clients',
        text: 'Clients',
        icon: 'user-tie',
        skipNavigation: true,
        skipBreadcrumb: false,
    },
    clients_list: {
        href: '/clients/list',
        text: 'Active Clients',
        icon: 'user-tie',
    },
    clients_campaigns: {
        href: '/clients/campaigns',
        text: 'Campaign Management',
    },
    clients_add: {
        href: '/clients/list/add',
        text: 'Add new Client',
        skipNavigation: true,
    },
    clients_details: {
        href: '/clients/list/:clientId',
        text: '{client.name}',
        skipNavigation: true,
    },
    clients_details_info: {
        href: '/clients/list/:clientId/info',
        text: 'Information',
    },
    clients_details_integrations: {
        href: '/clients/list/:clientId/integrations',
        text: 'Integrations',
    },
    clients_details_service_provider: {
        href: '/clients/list/:clientId/companies',
        text: 'Service Providers',
    },
    clients_details_users: {
        href: '/clients/list/:clientId/users',
        text: 'Users',
    },
    clients_details_users_add: {
        href: '/clients/list/:clientId/users/add',
        skipNavigation: true,
        text: 'Add User',
    },
    clients_details_users_edit: {
        href: '/clients/list/:clientId/users/:userId',
        skipNavigation: true,
        text: ['{user.forename}', '{user.surname}'],
    },
    clients_details_prosure: {
        href: '/clients/list/:clientId/supply-chain-fees-prosure',
        text: `Supply Chain Fees - Prosure`,
    },
    clients_details_clients: {
        href: '/clients/list/:clientId/supply-chain-fees-clients',
        text: `Supply Chain Fees - Clients`,
    },
    clients_details_subscriptions: {
        href: '/clients/list/:clientId/subscriptions',
        text: `Prosure Subscriptions`,
    },
    clients_details_subscriptions_add: {
        href: '/clients/list/:clientId/subscriptions/add',
        text: 'Add Prosure Subscription',
    },
    clients_details_subscriptions_details: {
        href: '/clients/list/:clientId/subscriptions/:id',
        text: 'Prosure Subscription Details',
    },
    clients_details_subscriptions_details_edit: {
        href: '/clients/list/:clientId/subscriptions/:id/edit',
        text: 'Edit',
    },
    clients_details_notifications: {
        href: '/clients/list/:clientId/notifications',
        text: `Notifications`,
    },
    clients_details_notifications_edit: {
        href: '/clients/list/:clientId/notifications/Edit',
        text: `Edit`,
        skipNavigation: true,
    },
    clients_archived_list: {
        href: '/clients/archived-list',
        text: 'Archived Clients',
    },
    clients_archived_details: {
        href: '/clients/archived-list/:clientId',
        text: '{client.name}',
        skipNavigation: true,
    },
    clients_archived_details_info: {
        href: '/clients/archived-list/:clientId/info',
        text: 'Information',
    },
    clients_archived_details_integrations: {
        href: '/clients/archived-list/:clientId/integrations',
        text: 'Integrations',
    },
    clients_archived_details_service_provider: {
        href: '/clients/archived-list/:clientId/companies',
        text: 'Service Providers',
    },
    clients_archived_details_users: {
        href: '/clients/archived-list/:clientId/users',
        text: 'Users',
    },
    clients_archived_details_users_add: {
        href: '/clients/archived-list/:clientId/users/add',
        skipNavigation: true,
        text: 'Add User',
    },
    clients_archived_details_users_edit: {
        href: '/clients/archived-list/:clientId/users/:userId',
        skipNavigation: true,
        text: ['{user.forename}', '{user.surname}'],
    },
    clients_archived_details_prosure: {
        href: '/clients/archived-list/:clientId/supply-chain-fees-prosure',
        text: `Supply Chain Fees - Prosure`,
    },
    clients_archived_details_clients: {
        href: '/clients/archived-list/:clientId/supply-chain-fees-clients',
        text: `Supply Chain Fees - Clients`,
    },
    clients_archived_details_subscriptions: {
        href: '/clients/archived-list/:clientId/subscriptions',
        text: `Prosure Subscriptions`,
    },
    clients_archived_details_subscriptions_add: {
        href: '/clients/archived-list/:clientId/subscriptions/add',
        text: 'Add Prosure Subscription',
    },
    clients_archived_details_subscriptions_details: {
        href: '/clients/archived-list/:clientId/subscriptions/:id',
        text: 'Prosure Subscription Details',
    },
    clients_archived_details_subscriptions_details_edit: {
        href: '/clients/archived-list/:clientId/subscriptions/:id/edit',
        text: 'Edit',
    },
    clients_archived_details_notifications: {
        href: '/clients/archived-list/:clientId/notifications',
        text: `Notifications`,
    },
    clients_archived_details_notifications_edit: {
        href: '/clients/archived-list/:clientId/notifications/Edit',
        text: `Edit`,
        skipNavigation: true,
    },
};

export default Object.assign(
    {},
    routes,
    campaignsRoutes,
    supplyChainConfigRoutes,
    serviceProviderCategoryRoutes
);
