import { findIndex } from 'lodash';
import { Response } from 'service';

class Data
{
    static convertFromFlatStructureToHierarchical(
        data = [],
        rootItem = null,
        options = {}
    ) {
        var rootItems = [],
            allItems = {};

        data.forEach((item, index) => {
            allItems[Response.getLink(item, 'self')] = item;
        });

        data.forEach((item, index) => {
            let cachedItem = allItems[Response.getLink(item, 'self')],
                parentLink = Response.getLink(cachedItem, 'parent');

            if (parentLink === rootItem) {
                rootItems.push(cachedItem);
            } else if (parentLink in allItems) {
                let parent = allItems[parentLink];
                if (!('children' in parent)) {
                    parent.children = [];
                }

                if (findIndex(parent.children, {_links: {self: {href: Response.getLink(item, 'self')}}}) === -1) {
                    parent.children.push(cachedItem);
                }
            }
        });

        return rootItems;
    }

    static flattenHierarchy(data = [])
    {
        let allItems = [];

        data.forEach((item, index) => {
            allItems.push(item);

            if ('children' in item) {
                let children = Data.flattenHierarchy(item.children);

                children.forEach((child) => {
                    if (findIndex(allItems, {_links: {self: {href: Response.getLink(child, 'self')}}}) === -1) {
                        allItems.push(child);
                    }
                });
            }
        })

        return allItems;
    }
}

export default Data;
