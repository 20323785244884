import { H2 } from "@blueprintjs/core";
import { Link } from "react-router-dom";

interface DashboardLinkCountProps {
    to: string;
    value: number|string;
    text: string;
}

const DashboardLinkCount = (props: DashboardLinkCountProps) => {
    const { to, value, text } = props;

    return (
        <Link
            className="no-decoration"
            to={to}
        >
            <H2 className="rating">
                {value}
            </H2>
            <span className="rating-text">{text}</span>
        </Link>
    );
}

export default DashboardLinkCount;