import { ListHeaderControls, Table } from "components/elements";
import { Loading, NoResultsFound } from "components/elements/wrappers";
import { InsuranceData } from "interface";

interface InsuranceTableProps {
    data?: InsuranceData[];
    isLoading?: boolean;
    isFilterOpen?: boolean;
    ordering?: string;
    onSort: () => any;
    onFilterClick?: () => any;
    reloadData: () => any;
    columns: any[];
    title?: string;
};

const InsuranceTable = (props: InsuranceTableProps) => {
    const {
        columns = [],
        data = [],
        isFilterOpen = false,
        isLoading = false,
        onSort,
        ordering,
        onFilterClick,
        reloadData,
        title = 'Insurance List'
    } = props;

    return (
        <div>
            <ListHeaderControls 
                count={data.length}
                onReload={reloadData}
                filterOpen={isFilterOpen}
                onFilter={onFilterClick}
                title={title}
            />

            <Loading isLoading={isLoading}>
                <NoResultsFound count={data.length}>
                    <Table
                        data={data}
                        columns={columns}
                        onSort={onSort}
                        ordering={ordering}
                    />
                </NoResultsFound>
            </Loading>
        </div>
    );
};

export default InsuranceTable;
