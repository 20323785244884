import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Switch } from 'react-router-dom';
import { ProtectedRoute } from 'components/elements';
import CompanyRegionsList from 'components/company/regions/CompanyRegionsList';
import CompanyRegionsEdit from 'components/company/regions/CompanyRegionsEdit';

class CompanyRegions extends Component
{
    static propTypes = {
        companyResponse: PropTypes.object.isRequired,
    };

    static defaultProps = {};

    render()
    {
        const { companyResponse, match } = this.props;
        return (
            <div className="CompanyRegions">
                <Switch>
                    <ProtectedRoute path={`${match.url}`} component={ CompanyRegionsList } companyResponse={ companyResponse } exact />
                    <ProtectedRoute path={`${match.url}/edit`} component={ CompanyRegionsEdit } companyResponse={ companyResponse } exact />

                    <Redirect to={`${match.url}`} />
                </Switch>
            </div>
        );
    }
}

export default CompanyRegions;
