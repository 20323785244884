import React from 'react';
import MultiLevelCheckbox from "./MultiLevelCheckbox";


const MultiLevelCheckboxArray = (props) => {
    const { fields, onUpdate } = props;

    return (
        <>
            {
                !Array.isArray(fields) ? null :
                    fields.map((field, index) => <MultiLevelCheckbox key={ 'checkbox' + index }
                        field={ field }
                        onUpdate={ onUpdate }
                        classNames='root '
                    />)
            }
        </>
    );
}
export default MultiLevelCheckboxArray;
