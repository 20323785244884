import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Classes, Dialog, Intent } from '@blueprintjs/core';

import { clearAssessmentSubmitted } from 'action';

const AssessmentFeedback = (props: any) => {
    const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);
    const assessmentState = useSelector((state: any) => state.assessments);
    const dispatch = useDispatch();

    useEffect(() => {
        if (assessmentState.isLoading) {
            return;
        }

        if (
            assessmentState.assessmentSubmitted === true &&
            assessmentState.assessmentTypeInstance.status ===
                'status_auto_returned'
        ) {
            dispatch(clearAssessmentSubmitted());

            setShowFeedbackDialog(true);
        }
    }, [assessmentState, dispatch]);

    const onClose = () => {
        setShowFeedbackDialog(false);
    };

    return (
        <Dialog
            onClose={onClose}
            title="Assessment Feedback"
            autoFocus={true}
            canEscapeKeyClose={false}
            canOutsideClickClose={false}
            isCloseButtonShown={false}
            usePortal={true}
            isOpen={showFeedbackDialog}
        >
            <div className={Classes.DIALOG_BODY}>
                One or more of the answers you provided in this assessment do
                not meet the requirements to pass.
                <br />
                <br />
                Please review the assessment which will now have guidance notes
                where the criteria has not been met.
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className="dialog-footer-buttons">
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button intent={Intent.SUCCESS} onClick={onClose}>
                            Confirm
                        </Button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default AssessmentFeedback;
