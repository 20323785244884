import { NONE_SELECTED_OPTION } from 'constants/none-selected';
import { FlatObject, ListData, ListFilter } from './index';

export interface ElogsCAFMStatsData {
    id: string;
    companyId: string;
    summary: string;
    type: string;
    approvedBy: string;
    approvedByName: string;
    serviceProviderName: string;
    approvedAt: string;
    completedAt: string;
    prosureStatusApproval: string;
    prosureStatusCompletion: string;
    _links: FlatObject;
}

export interface ElogsCAFMStatsListData extends ListData {
    count: number;
    // Added this duplicate property as TypeScript Generic types does not like Partial for collections
    jobs: ElogsCAFMStatsData[];
    updatedAt?: string;
}

export const CAFM_TYPE_OPTIONS = [
    { label: 'None', value: '' },
    { label: 'Reactive', value: 'reactive' },
    { label: 'Planned', value: 'planned' },
];

interface CAFMStatusMap {
    [key: string]: string;
}

export const CAFM_STATUS_MAP: CAFMStatusMap = {
    PROSURE_REQUIREMENTS_BELOW: 'Below Client Requirements',
    PROSURE_REQUIREMENTS_EXPIRING: 'Requirement Expiring Soon',
    PROSURE_REQUIREMENTS_SUCCESS: 'Meets Client Requirements',
    PROSURE_SUSPENDED: 'Suspended',
};

export const CAFM_STATUS_COLOURS: CAFMStatusMap = {
    PROSURE_REQUIREMENTS_BELOW: 'error',
    PROSURE_REQUIREMENTS_EXPIRING: 'warning',
    PROSURE_REQUIREMENTS_SUCCESS: 'success',
    PROSURE_SUSPENDED: 'warning',
};

export const FILTER_JOB_TYPES = [
    NONE_SELECTED_OPTION,
    { label: 'Reactive', value: 'reactive' },
    { label: 'Planned', value: 'planned' },
];

export const FILTER_CAFM_STATUS = [
    NONE_SELECTED_OPTION,
    {
        label: 'Below Client Requirements',
        value: 'PROSURE_REQUIREMENTS_BELOW',
    },
    {
        label: 'Requirement Expiring Soon',
        value: 'PROSURE_REQUIREMENTS_EXPIRING',
    },
    {
        label: 'Meets Client Requirements',
        value: 'PROSURE_REQUIREMENTS_SUCCESS',
    },
    {
        label: 'Suspended',
        value: 'PROSURE_SUSPENDED',
    },
];

export const FILTER_JOB_STATUS_ALL = {
    label: 'All',
    value: '',
};

export const FILTER_JOB_STATUS_OPTIONS = [
    FILTER_JOB_STATUS_ALL,
    {
        label: 'Meets Client Requirements',
        value: 0
    },
    {
        label: 'Requirement Expiring Soon',
        value: 1
    },
    {
        label: 'Below Client Requirements',
        value: 2
    },
    {
        label: 'Suspended',
        value: 3
    },
    {
        label: 'N/A',
        value: -1
    },
];

export interface ElogsCAFMStatsFilter extends ListFilter {
    serviceProvider: number;
    site: number;
    type: string;
    client?: string;
    jobOrder: string;
}
