import { Card, H2 } from '@blueprintjs/core';
import { DateTime } from 'luxon';

import { FileUpload, ButtonLink, Icon } from 'components/elements';
import { Response } from 'service';
import "./styles.scss";

const CertificationMembershipInfo = props => {
    const { certificationMembershipDetails, match } = props;

    return <>
        <Card>
            <H2>
                Certificate/Membership Info
                {
                    Response.getLink(certificationMembershipDetails, 'edit') !== null &&
                        (
                            <ButtonLink type="button"
                                to={`${match.url}/edit`}
                                intent="primary"
                                className="float-right">
                                <Icon icon="pencil" />
                                Edit
                            </ButtonLink>
                        )
                }

            </H2>

            <dl className="grid medium">
                <dt>Certification/Membership</dt>
                <dd>{certificationMembershipDetails.name}</dd>

                <dt>Certificate/Registration Number</dt>
                <dd>{certificationMembershipDetails.certificateRegistrationNumber}</dd>

                <dt>Commencement Date</dt>
                <dd>{  DateTime
                        .fromISO(certificationMembershipDetails.commencementDate)
                        .toLocaleString(DateTime.DATE_MED)
                }</dd>

                <dt>Expiry Date</dt>
                <dd>{  DateTime
                        .fromISO(certificationMembershipDetails.expiresAt)
                        .toLocaleString(DateTime.DATE_MED)
                }</dd>

                { certificationMembershipDetails.comments &&
                    (
                        <>
                            <dt>Comments</dt>
                            <dd>{ certificationMembershipDetails.comments }</dd>
                        </>
                    )
                }

                { certificationMembershipDetails._links &&
                    (
                        <>
                            <dt>Certificate</dt>
                            <dd><FileUpload inputID="certificate"
                                isEditable={ false }
                                onUpdate={ () => {} }
                                onUpload={ () => {} }
                                response={ certificationMembershipDetails }
                                responseLink="certificate-file"
                                uploadDataObject={ {} } />
                            </dd>
                        </>
                    )
                }
            </dl>
        </Card>
    </>;
}

export default CertificationMembershipInfo;
