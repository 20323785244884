import { Button, Classes, H2 } from '@blueprintjs/core';
import PropTypes, { shape } from 'prop-types';
import { Link } from 'react-router-dom';

import ClientRequirements from 'components/elements/company/client-requirement/ClientRequirements';
import SupplyChainDescription from 'components/elements/supply-chain/SupplyChainDescription';
import './index.scss';

const SupplyChainCard = (props) => {
    const {
        company,
        supplyChainInstance,
        supplyChainStatus,
        canLeave,
        onLeaveClick,
    } = props;
    const client = supplyChainInstance._links.client;
    let headingClass = 'company-heading';

    let inviteId = supplyChainInstance.id;
    let inviteText = 'View Invitation';

    if (supplyChainInstance._links['accept-renewal']) {
        inviteId = supplyChainInstance._links['accept-renewal'].renewalId;
    }

    if (
        supplyChainInstance._links['accept-renewal'] ||
        supplyChainInstance.isRenewing
    ) {
        inviteText = 'View Renewal Invite';
    }

    return (
        <div
            className={'supply-chain-card ' + Classes.CARD}
            onDragStart={(e) => {
                e.preventDefault();
            }}
        >
            {supplyChainInstance._links['client-logo'] ? (
                <div className="logo">
                    <img
                        onDragStart={(e) => {
                            e.preventDefault();
                        }}
                        alt={supplyChainInstance._links['client-logo'].title}
                        title={supplyChainInstance._links['client-logo'].title}
                        src={
                            process.env.REACT_APP_BASE_URL +
                            supplyChainInstance._links['client-logo'].publicUrl
                        }
                    ></img>
                </div>
            ) : null}
            <div className="company-info">
                <div className="actions">
                    {supplyChainInstance.supplyChainServiceProviderCategory && (
                        <ClientRequirements
                            supplyChainStatus={supplyChainStatus}
                            company={company} // ensure incldued badges
                            client={client}
                            supplyChain={supplyChainInstance}
                            selectedCategory={
                                supplyChainInstance.supplyChainServiceProviderCategory
                            }
                        />
                    )}

                    {!(
                        supplyChainInstance._links['accept-invite'] ||
                        supplyChainInstance._links['accept-renewal'] ||
                        supplyChainInstance._links['purchase']
                    ) ? null : (
                        <>
                            <Link
                                className="add-to-supply-chain invite-text icon-only bp3-button bp3-intent-primary"
                                to={`/supply-chains/pending/${inviteId}`}
                            >
                                {inviteText}
                            </Link>
                        </>
                    )}
                </div>

                <div className="company-details">
                    <H2 className={headingClass}>{client.name}</H2>

                    <SupplyChainDescription
                        supplyChain={supplyChainInstance}
                        canLeaveButton={
                            canLeave ? (
                                <Button
                                    minimal
                                    className="bp3-intent-secondary leave-sc-button"
                                    icon="trash"
                                    onClick={onLeaveClick}
                                >
                                    Leave supply chain.
                                </Button>
                            ) : null
                        }
                    />
                </div>
            </div>
        </div>
    );
};

SupplyChainCard.propTypes = {
    supplyChainInstance: PropTypes.shape({
        id: PropTypes.string,
        status: PropTypes.number,
        acceptedAt: PropTypes.string,
        _links: shape({
            client: shape({
                href: PropTypes.string,
                name: PropTypes.string,
            }),
            'client-logo': shape({
                href: PropTypes.string,
                method: PropTypes.string,
                title: PropTypes.string,
                download: PropTypes.string,
                publicUrl: PropTypes.string,
            }),
        }),
    }).isRequired,
};

export default SupplyChainCard;
