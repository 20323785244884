class StateHandler
{
    static getStateObject(event, mergeObject = {}, defaultProperty, overrideValue)
    {
        const target = event.target;
        const property = defaultProperty || target.id || target.name;
        let value = target.type === 'checkbox' ? target.checked : target.value;

        if (typeof overrideValue !== 'undefined') {
            value = overrideValue;
        }

        if (target.hasAttribute('multiple')) {
            value = Array.prototype.map.call(target.selectedOptions, (selection) => {
                return selection.value;
            });
        }

        if (mergeObject.hasOwnProperty(property) &&
            Array.isArray(mergeObject[property]) &&
            !Array.isArray(value)) {
            value = [value];
        }

        return Object.assign({}, mergeObject, {
            [property]: value,
        });
    }
}

export default StateHandler;
