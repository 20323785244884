import { useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { FormGroup } from '@blueprintjs/core';
import {
    RedFlagStatusChart,
    SPAssessmentCharts,
    SPSuspendedStatusChart,
    SupplyChainChart,
    SupplyChainInviteStatusChart,
    SupplyChainSPTotalsChart,
    SupplyChainTrendChart,
} from 'components/elements/charts/client';
import { isAdmin } from 'helpers/helpers';
import { ClientData } from 'interface';
import { ClientService } from 'service';

import { CategoryFilter } from 'components/supply-chain/analytics/CategoryFilter';

import './index.scss';

const SupplyChainAnalytics = () => {
    const root = useSelector((state: any) => state.root);
    const [clientResponse, setClientResponse] = useState<ClientData|null>(null);

    const allCategories = {
        id: 'all-categories',
        name: 'All Service Provider Categories',
    };

    const [categoryData, setCategoryData] = useReducer(
        (state: any, edits: any) => ({ ...state, ...edits }),
        {
            categories: [],
            selectedCategory: allCategories,
        }
    );

    const selectCategory = (item: any) => {
        setCategoryData({
            selectedCategory: item,
        });
    };

    const load = async () => {
        setCategoryData({
            categories: [],
            selectedCategory: allCategories,
        });

        const clientId = root.response._links.client?.id;

        const clientResponse: ClientData | null = clientId
            ? await ClientService.loadClient(clientId)
            : root.client
            ? root.client
            : null;

        setClientResponse(clientResponse);

        if (clientResponse !== null && clientResponse?.id !== 'all-clients') {
            const categoriesData = await ClientService.getSPCategories(
                clientResponse
            );
            if (categoriesData && categoriesData.length > 0) {
                const categories = [allCategories, ...categoriesData];
                setCategoryData({
                    categories: categories,
                });
            }
        }
    };

    useEffect(() => {
        load();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [root.client]);

    const getFilters = () => {
        return (
            <>
                <FormGroup
                    label={'Category'}
                    labelFor={'category-selector'}
                    className={'bp3-inline form-fill multi-select-container'}
                ></FormGroup>
            </>
        );
    };

    if (isAdmin() && (!clientResponse || clientResponse?.id === 'all-clients')) {
        return (
            <div className="SearchPage SupplyChainSearch reduced">
                <div className="no-styling search-results">
                    <div className="no-results">
                        Please select a Client to view Supply Chain Analytics
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="SupplyChainAnalyticsPage page">
            <div className="no-styling  category-filter-widget">
                <CategoryFilter
                    children={getFilters()}
                    categoryData={categoryData}
                    selectCategory={selectCategory}
                />
            </div>

            <div className="no-styling supply-chain">
                {/*Supply Chain Status*/}
                {clientResponse && (
                    <SupplyChainChart
                        selectedCategory={categoryData.selectedCategory.id}
                        client={clientResponse}
                    />
                )}
                {clientResponse && (
                    <SupplyChainTrendChart
                        selectedCategory={categoryData.selectedCategory.id}
                        client={clientResponse}
                    />
                )}

                {/* Supply Chain vs Invited but Pending */}
                {clientResponse && (
                    <SupplyChainInviteStatusChart
                        selectedCategory={categoryData.selectedCategory.id}
                        client={clientResponse}
                    />
                )}

                {/* Supply Chain Service Provider Totals */}
                {clientResponse && (
                    <SupplyChainSPTotalsChart
                        selectedCategory={categoryData.selectedCategory.id}
                        client={clientResponse}
                    />
                )}

                {/*Red Flag status*/}
                <RedFlagStatusChart
                    selectedCategory={categoryData.selectedCategory.id}
                    client={clientResponse}
                />

                {/*SP SSIP Assessment & SSIP Prequalified*/}
                <SPAssessmentCharts
                    selectedCategory={categoryData.selectedCategory.id}
                    client={clientResponse}
                />

                {/*SP Suspended Status*/}
                {clientResponse && (
                    <SPSuspendedStatusChart
                        selectedCategory={categoryData.selectedCategory.id}
                        client={clientResponse}
                    />
                )}
            </div>
        </div>
    );
};

export default withRouter(SupplyChainAnalytics);
