import { Button, Classes, Dialog, Intent, TextArea } from '@blueprintjs/core';
import { ComponentProps, SyntheticEvent, useEffect, useReducer } from 'react';
import { toast } from 'react-toastify';

import { Company } from 'interface';
import { Response } from 'service';
import SupplyChainService from 'service/SupplyChain/SupplyChainService';

interface RemoveServiceProviderProps extends ComponentProps<any> {
    company?: Company;
    isOpen: boolean;
    onClose: Function;
}

const RemoveServiceProvider = (props: RemoveServiceProviderProps) => {
    const { company, isOpen, onClose, onYes } = props;
    const expiryLink = Response.getLink(company, 'set-remove-when-expired');
    const [state, setState] = useReducer(
        (state: any, edits: any) => {
            return { ...state, ...edits };
        },
        {
            dialogOpen: !!isOpen,
            isLoading: false,
            removeMessage: '',
            errorMessage: null,
        }
    );

    useEffect(() => {
        setState({ dialogOpen: isOpen });
    }, [isOpen]);

    const dialogClose = (event: SyntheticEvent) => {
        setState({
            dialogOpen: false,
            removeMessage: '',
            errorMessage: null,
            isLoading: false,
        });
        typeof onClose === 'function' && onClose(event, state.removeMessage);
    };

    const dialogConfirm = async () => {
        if (!company || !expiryLink) {
            setState({
                errorMessage: 'Submission not possible. Please contact support',
            });
            toast.error('Service Provider cannot be removed');
            return;
        }
        if (state.removeMessage.length < 1) {
            setState({ errorMessage: 'Please provide a reason' });
            toast.error('Could not submit. Please fill in all fields.');
            return;
        }
        setState({ isLoading: true });
        const response = await SupplyChainService.setRemovedWhenExpired(
            expiryLink,
            {
                reason: state.removeMessage,
            }
        );
        response &&
            toast.success('Service Provider has been marked for removal.');
        response && typeof onYes === 'function' && onYes();
        dialogClose({} as SyntheticEvent);
    };

    return (
        <Dialog
            onClose={dialogClose}
            title="Remove Service Provider"
            autoFocus={true}
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            isCloseButtonShown={false}
            usePortal={true}
            isOpen={state.dialogOpen}
        >
            <div className={Classes.DIALOG_BODY}>
                <div className="mb-2">
                    Are you sure you want to remove this Service Provider from
                    your Supply Chain?
                </div>
                <label className="bp3-label" htmlFor="removeMessage">
                    Please provide a reason for this removal.
                </label>
                <TextArea
                    id="removeMessage"
                    onChange={(e: any) =>
                        setState({ removeMessage: e.target.value })
                    }
                    value={state.removeMessage}
                    className="full-width no-resize"
                    minLength={1}
                    required
                    rows={8}
                />
                {state.errorMessage && (
                    <span className="error">{state.errorMessage}</span>
                )}
                <div className="my-2">
                    Note, if removed, the Service Provider will stay until their
                    expiry date.
                </div>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className="dialog-footer-buttons">
                    <Button onClick={dialogClose}>Cancel</Button>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button
                            type="submit"
                            intent={Intent.SUCCESS}
                            disabled={state.isLoading}
                            onClick={dialogConfirm}
                        >
                            Confirm
                        </Button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default RemoveServiceProvider;
