import { TooltipIcon } from 'components/elements';
import './widget-card.scss';

interface WidgetCardProps {
    title?: string;
    children?: any;
    tooltip?: string;
}

const WidgetCard = (props: WidgetCardProps) => {
    const { children, title, tooltip } = props;

    return (
        <div className="analytics-widget-card">
            <section className="analytics-widget-header">
                <h2 className="title">
                    {tooltip && (
                        <TooltipIcon
                            text={<section>{tooltip}</section>}
                            icon="info"
                        />
                    )}
                    <span className="ps-2">{title || ''}</span>
                </h2>
            </section>
            {children}
        </div>
    );
};

export default WidgetCard;
