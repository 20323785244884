import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, Position } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';

import Icon from 'components/elements/Icon';
import NumberFormatting from 'service/NumberFormatting';

class Paginator extends Component
{
    static propTypes = {
        page: PropTypes.number.isRequired,
        count: PropTypes.number.isRequired,
        limit: PropTypes.number.isRequired,

        show: PropTypes.number,

        showFirst: PropTypes.bool,
        showPrevious: PropTypes.bool,
        showNext: PropTypes.bool,
        showLast: PropTypes.bool,

        showEllipsis: PropTypes.bool,

        onPage: PropTypes.func.isRequired,
    };

    static defaultProps = {
        page: 1,
        count: 0,
        limit: 10,

        show: 5,

        showFirst: true,
        showPrevious: true,
        showNext: true,
        showLast: true,

        showEllipsis: true,
    };

    constructor(props) {
        super(props);

        this.state = {
            numberOfPages: Math.ceil(this.props.count / this.props.limit),
            pages: [],
        };

        this.changePage = this.changePage.bind(this);
        this.getButton = this.getButton.bind(this);
        this.getShowingText = this.getShowingText.bind(this);
    }

    componentDidMount() {
        const numberOfPages = Math.ceil(this.props.count / this.props.limit);
        const pagesBeforeAfter = Math.floor((this.props.show - 1) / 2);
        let startPage = this.props.page > (pagesBeforeAfter + 1) ? pagesBeforeAfter : 1;
        let finalPage = ((this.props.page + pagesBeforeAfter) < numberOfPages) ? (this.props.page + pagesBeforeAfter) : numberOfPages;

        let startPageDifference = ((startPage + pagesBeforeAfter) - this.props.page);

        if (startPageDifference > 0) {
            finalPage += startPageDifference;
        }

        if (finalPage > numberOfPages) {
            finalPage = numberOfPages;
        }

        if (startPage < (this.props.page - pagesBeforeAfter)) {
            startPage = (this.props.page - pagesBeforeAfter);
        }

        this.setState({
            pages: [...Array((finalPage - startPage) + 1).keys()].map((i) => i + startPage),
        });
    }

    wrapInTooltip(button, tooltipText)
    {
        return (
            <Tooltip2 content={ tooltipText }
                position={ Position.TOP }
                key={ tooltipText }>
                { button }
            </Tooltip2>
        );
    }

    getButton(icon, text, page)
    {
        let classes = [];

        if (icon === null) {
            classes.push('icon-free');

            if (text === page &&
                text === this.props.page) {
                classes.push('active');
            }
        }

        if (text === null) {
            classes.push('icon-only');
        }

        return (
            <Button intent="default"
                className={ classes }
                onClick={ (event) => { this.changePage(page) } }>
                { icon ? <Icon icon={ icon } /> : null }
                { text }
            </Button>
        );
    }

    getPages()
    {
        return this.state.pages.map((page, index) => {
            return this.wrapInTooltip(this.getButton(null, page, page), 'Page ' + page);
        });
    }

    getShowingText()
    {
        let startOffset = ((this.props.page - 1) * this.props.limit) + 1;
        let endOffset = (this.props.page * this.props.limit);
        let count = this.props.count;

        if (endOffset > count) {
            endOffset = count;
        }

        return (
            <div>
                Showing { NumberFormatting.format(startOffset) } to { NumberFormatting.format(endOffset) } of { NumberFormatting.format(count) }
            </div>
        );

    }

    render()
    {
        const firstButton = this.wrapInTooltip(this.getButton('chevron-double-left', null, 1), 'First Page');
        const previousButton = this.wrapInTooltip(this.getButton('chevron-left', null, this.props.page - 1), 'Previous Page');
        const nextButton = this.wrapInTooltip(this.getButton('chevron-right', null, this.props.page + 1), 'Next Page');
        const lastButton = this.wrapInTooltip(this.getButton('chevron-double-right', null, this.state.numberOfPages), 'Last Page');

        return (
            <div className="Paginator">
                <ButtonGroup>
                    { this.props.showFirst ? firstButton : null }
                    { this.props.showPrevious ? previousButton : null }

                    { this.getPages() }

                    { this.props.showNext ? nextButton : null }
                    { this.props.showLast ? lastButton : null }
                </ButtonGroup>

                { this.getShowingText() }
            </div>
        );
    }

    changePage(page) {
        if (page <= 0 ||
            page > this.state.numberOfPages ||
            page === this.props.page) {
            return;
        }

        this.props.onPage({page: page});
    }
}

export default Paginator;
