const SSIP_CONTRACTOR = {
    value: 'contractor',
    label: 'Contractor (Construction)',
};
const SSIP_DESIGNER = { value: 'designer', label: 'Designer' };
const SSIP_NON_CONSTRUCTION = {
    value: 'non-construction',
    label: 'Non-Construction',
};
const SSIP_PRINCIPAL_CONTRACTOR = {
    value: 'principal-contractor',
    label: 'Principal Contractor',
};
const SSIP_PRINCIPAL_DESIGNER = {
    value: 'principal-designer',
    label: 'Principal Designer',
};
const SSIP_APPROVED_IE = {
    value: 'ssip-approved-ie',
    label: 'SSIP Approved IE',
};
const SSIP_APPROVED_IE_CONTRACTOR = {
    value: 'ssip-approved-ie-contractor',
    label: 'SSIP Approved IE Contractor',
};
const SSIP_APPROVED_IE_PSCS = {
    value: 'ssip-approved-ie-pscs',
    label: 'SSIP Approved IE Project Supervisor Construction Stage (PSCS)',
};

const SSIP_SCOPE_CATEGORY_OPTIONS = [
    SSIP_CONTRACTOR,
    SSIP_DESIGNER,
    SSIP_NON_CONSTRUCTION,
    SSIP_PRINCIPAL_CONTRACTOR,
    SSIP_PRINCIPAL_DESIGNER,
    SSIP_APPROVED_IE,
    SSIP_APPROVED_IE_CONTRACTOR,
    SSIP_APPROVED_IE_PSCS,
];

export {
    SSIP_APPROVED_IE,
    SSIP_APPROVED_IE_CONTRACTOR,
    SSIP_APPROVED_IE_PSCS,
    SSIP_CONTRACTOR,
    SSIP_DESIGNER,
    SSIP_NON_CONSTRUCTION,
    SSIP_PRINCIPAL_CONTRACTOR,
    SSIP_PRINCIPAL_DESIGNER,
    SSIP_SCOPE_CATEGORY_OPTIONS,
};
