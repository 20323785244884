import { ComponentProps, SyntheticEvent, useCallback, useEffect, useReducer } from 'react';
import {
    Button,
    Classes,
    Dialog,
    FormGroup,
    Intent,
    HTMLSelect
} from '@blueprintjs/core';

import { ClientService, Response } from 'service';
import { toast } from 'react-toastify';
import { SupplyChain } from 'interface/SupplyChain';
import { globalDebounce } from 'helpers/helpers';
import { ServiceProviderCategory } from 'interface/Client/ServiceProviderCategory';
import { Loading } from 'components/elements/wrappers';

import './index.scss';

interface EditClientProps extends ComponentProps<any> {
    client?: SupplyChain;
    isOpen: boolean;
    onClose: Function;
}

interface CategoryDropdownOption {
    value: string,
    label: string,
}

const EditClient = (props: EditClientProps) => {
    const { client, isOpen, onClose } = props;

    const initialState = {
        dialogOpen: !!isOpen,
        isLoading: false,
        categoryOptions: [],
        defaultCategory: null,
        selectedCategory: null,
        categoriesLoading: true,
    };

    const [state, setState] = useReducer(
        (state: any, edits: any) => ({ ...state, ...edits }),
        initialState
    );

    useEffect(() => {
        const startingCategory = getClientCategory();

        setState({
            dialogOpen: isOpen,
            defaultCategory: startingCategory,
            selectedCategory: startingCategory
        });

        loadSpCategories();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const loadSpCategories = useCallback(async () => {
        if (!client) {
            return;
        }

        setState({ categoriesLoading: true });

        const getSpCategories = async () => {
            const clientId = getClientId();
            const categories = await ClientService.getSPCategoriesByClientId(clientId);

            setState({
                categories,
                categoryOptions: getCategoryDropdownOptions(categories),
                categoriesLoading: false,
            });
        };

        globalDebounce(getSpCategories, 'getCategories', 250);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client]);

    const getClientName = () => Response.getLinkAttribute(client, 'client', 'name');
    const getClientId = () => Response.getLinkAttribute(client, 'client', 'id');
    const getClientCategory = () => Response.getLinkAttribute(client, 'sp-category', 'id');

    const getCategoryDropdownOptions = (categories: Array<ServiceProviderCategory>) => {
        const options: Array<CategoryDropdownOption> = [];

        categories.forEach(({ isActive, id, name }) => {

            if (isActive) {
                options.push({
                    label: name,
                    value: id,
                });
            }
        });

        return options;
    };

    const dialogClose = (event: SyntheticEvent) => {
        setState({
            dialogOpen: false,
            isLoading: false,
        });
        
        if (typeof onClose === 'function') {
            onClose(event);
        }
    };

    const dialogConfirm = async () => {
        if (!state.selectedCategory) {
            toast.error("Please select a category");
            return;
        }

        setState({ isLoading: true });

        if (client) {
            const { categories, selectedCategory } = state;
            const { _links: categoryLinks } = categories.find((sc: SupplyChain) => (sc.id === selectedCategory));
            
            await ClientService.putSupplyChain(
                client.id,
                { 
                    _links: {
                        "sp-category": categoryLinks.self
                    }
                },
                (resp) => resp && toast.success("Category has been updated successfully.")
            );
        }

        dialogClose({} as SyntheticEvent);
    };

    const { categoriesLoading, categoryOptions, dialogOpen } = state;

    return (
        <Dialog
            className='edit-client-category-dialog'
            onClose={dialogClose}
            title="Edit SP Category"
            autoFocus
            canEscapeKeyClose
            canOutsideClickClose
            isCloseButtonShown={false}
            usePortal
            isOpen={dialogOpen}
        >
            <div className={Classes.DIALOG_BODY}>
                <Loading isLoading={categoriesLoading}>
                    { (!categoriesLoading && !categoryOptions.length) ? (
                        <p>
                            {' '}
                            There are currently no categories setup for your account.
                        </p>
                    ) : (
                        <>
                            <p>
                                Please select the category this Service Provider should be in the {getClientName()} Supply Chain.
                            </p>

                            <FormGroup
                                label="SP Category"
                                key="sp-category"
                                inline
                                className="form-fill required"
                                labelFor="sp-category"
                            >
                                <HTMLSelect
                                    id="sp-category"
                                    fill
                                    required
                                    defaultValue={state.defaultCategory}
                                    onChange={(e: any) => {
                                        setState({
                                            selectedCategory: e.target.value,
                                        });
                                    }}
                                    options={state.categoryOptions}
                                />
                            </FormGroup>
                        </>
                    )}
                </Loading>
            </div>

            <div className={Classes.DIALOG_FOOTER}>
                <FormGroup>
                    <div className="dialog-footer-buttons footer-btn-container">
                        <Button onClick={dialogClose}>Cancel</Button>
                        <Button
                            type="submit"
                            intent={Intent.SUCCESS}
                            disabled={state.isLoading}
                            onClick={dialogConfirm}
                        >
                            Confirm
                        </Button>
                    </div>
                </FormGroup>
            </div>
        </Dialog>
    );
};

export default EditClient;
