import { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip2 } from '@blueprintjs/popover2';
import { PopoverPosition } from '@blueprintjs/core';

class Reload extends Component
{
    render() {
        return (
            <Tooltip2 className="Reload" position={ PopoverPosition.TOP }
                content={ this.props.text || "Reload" }>
                <FontAwesomeIcon icon={['fas', 'sync']}
                    onClick={() => this.props.load()}/>
            </Tooltip2>
        );
    }
}

export default Reload;
