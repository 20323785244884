import {
    Button,
    Classes,
    Dialog,
    FormGroup,
    InputGroup,
} from '@blueprintjs/core';
import { DateInput } from '@blueprintjs/datetime';
import { AxiosResponse } from 'axios';
import { DateTime } from 'luxon';
import moment from 'moment';
import { FormEvent, useState } from 'react';
import { toast } from 'react-toastify';

import { HTMLSelect, InputElementIcon } from 'components/elements/index';
import { NONE_SELECTED_OPTION } from 'constants/none-selected';
import { AssessmentTypeInstanceData, SupplyChain } from 'interface';
import { CANCEL_REASON_OPTIONS, CancelPaymentData, CancelReason, CancelReasonMappings } from 'interface/CancelSupplyChain';
import { HTTP } from 'service';

interface CancelDialogProps {
    refundableObject: AssessmentTypeInstanceData | SupplyChain;
    reload: Function;
    cancelText?: string;
}

const CancelDialog = (props: CancelDialogProps) => {
    const { refundableObject, reload, cancelText } = props;
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [cancelPayment, setCancelPayment] = useState<CancelPaymentData>(
        {
            date: new Date()
        } as CancelPaymentData 
    );

    const cancelReasons = [
        NONE_SELECTED_OPTION,
        {
            label: CancelReasonMappings[CancelReason.CLIENT_REQUEST],
            value: CancelReason.CLIENT_REQUEST,
        },
        {
            label: CancelReasonMappings[CancelReason.SERVICE_PROVIDER_REQUEST],
            value: CancelReason.SERVICE_PROVIDER_REQUEST,
        },
        {
            label: CancelReasonMappings[CancelReason.OTHER],
            value: CancelReason.OTHER,
        },
    ];

    const handleClose = () => {
        setCancelPayment({} as CancelPaymentData);
        setIsDialogOpen(false);
    };

    const submitCancelPayment = async (event: FormEvent<HTMLFormElement>) => {
        event.stopPropagation();
        event.preventDefault();

        const { id } = refundableObject;

        await HTTP.post(`/supply-chain/${id ?? ''}/cancel`, cancelPayment)
            .then((response: AxiosResponse | void) => {
                if (response) {
                    toast.success(
                        `Supply Chain Cancelled.`
                    );
                    handleClose();
                    reload();
                }
            })
            .catch((error: any) => {
                const { response } = error;

                if (response?.data && response.data.length > 0) {
                    toast.error(response.data[0].message);
                }
            });
    };

    const openDialog = () => {
        setIsDialogOpen(true);
    };

    return (
        <>
            <Button
                onClick={openDialog}
                intent="danger"
                title="Cancel Supply Chain Entry"
            >
                {cancelText ?? 'Cancel Entry'}
            </Button>
            <Dialog
                title="Cancel Supply Chain Entry"
                className="cancel-payment-modal"
                onClose={handleClose}
                autoFocus={true}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                usePortal={true}
                isOpen={isDialogOpen}
            >
                <form
                    id="cancel-supply-chain-form"
                    onSubmit={(event: FormEvent<HTMLFormElement>) =>
                        submitCancelPayment(event)
                    }
                >
                    <div className={Classes.DIALOG_BODY}>
                        <FormGroup
                            label="Cancel Reason"
                            inline={true}
                            className="form-fill required"
                            labelFor="reason"
                        >
                            <HTMLSelect
                                id="reason"
                                fill={true}
                                value={cancelPayment.reason}
                                onChange={(
                                    event: FormEvent<HTMLSelectElement>
                                ) => {
                                    const { value } =
                                        event.target as HTMLInputElement;

                                    setCancelPayment({
                                        ...cancelPayment,
                                        reason: parseInt(value) as CancelReason,
                                    });
                                }}
                                options={cancelReasons ?? CANCEL_REASON_OPTIONS}
                                required
                            />
                        </FormGroup>
                        {cancelPayment.reason === CancelReason.OTHER && (
                            <FormGroup
                                label="Details"
                                inline={true}
                                className="form-fill required"
                                labelFor="Details"
                            >
                                <InputGroup
                                    placeholder="Details"
                                    minLength={1}
                                    maxLength={255}
                                    value={cancelPayment.detail}
                                    onChange={(
                                        e: FormEvent<HTMLInputElement>
                                    ) => {
                                        const { value } =
                                            e.target as HTMLInputElement;
                                        setCancelPayment({
                                            ...cancelPayment,
                                            detail: value,
                                        });
                                    }}
                                    required
                                />
                            </FormGroup>
                        )}
                        <FormGroup
                            label="Cancel Date:"
                            inline
                            className="form-fill choice required"
                        >
                            <DateInput
                                parseDate={(str) => moment(str, 'DD/MM/YYYY').toDate()}
                                formatDate={(date) => DateTime.fromJSDate(date).toLocaleString()}
                                popoverProps={{ position: 'bottom', fill: true }}
                                inputProps={{
                                    fill: true,
                                    rightElement: InputElementIcon(
                                        'Select a date...',
                                        'calendar-alt'
                                    ),
                                }}
                                onChange={(newDate) => {
                                    newDate = new Date(+newDate + 1000);
                                    setCancelPayment({
                                        ...cancelPayment,
                                        date: newDate
                                    });
                                }}
                                value={cancelPayment.date}
                            />
                        </FormGroup>

                        <FormGroup className={Classes.DIALOG_FOOTER}>
                            <Button
                                type="button"
                                className="float-left"
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                intent="success"
                                className="float-right"
                                form='cancel-supply-chain-form'
                            >
                                Cancel Entry
                            </Button>
                        </FormGroup>
                    </div>
                </form>
            </Dialog>
        </>
    );
};

export default CancelDialog;
