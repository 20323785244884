import { memo, useEffect, useRef, useState } from 'react';
import { canNavigateWithRestriction } from "helpers/helpers";

const ProtectedRouteGroup = (props) => {
    const [customStyle, setCustomStyle] = useState({});
    const ref = useRef();

    useEffect(() => {
        if(!ref.current) {
            return;
        }
        fixOverflow();
        window.addEventListener('resize', fixOverflow);

        return () => window.removeEventListener('resize', fixOverflow);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref.current]);

    const fixOverflow = () => {
        const boxSizing = ref.current.getBoundingClientRect();
        const parentSizing = ref.current.parentNode.getBoundingClientRect();
        let style = {
            left: 0,
            top: "calc(100% - 5px)", // compensate for border-top
            whiteSpace: 'nowrap',
        };

        // compensate for overflow
        if(window.innerWidth < (parentSizing.left + boxSizing.width )) {
            style.left = parentSizing.width - 5 - boxSizing.width;
        }

        // compensate for really long titles
        if(window.innerWidth * 0.8 < boxSizing.width) {
            style.whiteSpace = 'normal';
        }

        setCustomStyle(style);
    };

    return (
        <>
            {
                canNavigateWithRestriction(props.roleRestriction) ?
                    <ul className="protectedGroup" ref={ ref } style={ customStyle }>
                        { props.children }
                    </ul> : null
            }
        </>
    );
}

export default memo(ProtectedRouteGroup);
