export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const REQUEST_LOGIN = 'REQUEST_LOGIN';
export const TOKEN_INVALID = 'TOKEN_INVALID';
export const TOKEN_VALID = 'TOKEN_VALID';
export const SET_REDIRECT = 'SET_REDIRECT';
export const CLEAR_REDIRECT = 'CLEAR_REDIRECT';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';
export const UPDATE_REGISTER_FORM = 'UPDATE_REGISTER_FORM';
export const SET_AUTH_ERRORS = 'SET_AUTH_ERRORS';
export const SET_ERRORS = 'SET_ERRORS';
export const CLEAR_AUTH_ERRORS = 'CLEAR_AUTH_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const SET_BUILD_TIMESTAMP = 'SET_BUILD_TIMESTAMP';
