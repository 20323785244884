import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup } from '@blueprintjs/core';
import { Icon } from 'components/elements';

class MultiSwitch extends Component
{
    static propTypes = {
        options: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.any.isRequired,
                intent: PropTypes.string,
            })
        ).isRequired,
        selected: PropTypes.any,
        onClick: PropTypes.func.isRequired,
    };

    static defaultProps = {};

    constructor(props)
    {
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.getButtons = this.getButtons.bind(this);
    }

    handleClick(event, value)
    {
        this.props.onClick(event, value);
    }

    render()
    {
        return (
            <>
                <ButtonGroup>
                    { this.getButtons() }
                </ButtonGroup>
            </>
        );
    }

    getButtons()
    {
        const { disabled } = this.props;

        return this.props.options.map((option, index) => {
            const selected = option.value === this.props.selected;

            return (
                <Button type="button"
                   intent={ option.intent || (selected ? 'primary' : 'default') }
                   key={ index }
                   onClick={ e => this.handleClick(e, option.value) }
                   className={ selected ? 'active' : null }
                   disabled={ disabled }>
                   { option.icon ? <Icon icon={ option.icon } /> : null }
                   { option.label }
                </Button>
            );
        });
    }
}

export default MultiSwitch;
