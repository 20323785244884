import { SyntheticEvent, useEffect, useState } from 'react';
import { H1 } from '@blueprintjs/core';

import {
    ButtonLink,
    Icon,
    ItemCount,
    Paginator,
    Reload,
    Table,
} from 'components/elements';
import { Location } from 'service';
import { Loading, NoResultsFound } from 'components/elements/wrappers';
import {
    loadIntegrationList,
    removeIntegration,
} from 'service/Integration/IntegrationService';
import {
    IntegrationsComponentProps,
    IntegrationData,
    IntegrationsListData,
    IntegrationsFilter,
} from 'interface/Integrations';
import Confirmation from 'components/dialogs/Confirmation';

const IntegrationsList = (props: Partial<IntegrationsComponentProps>) => {
    const { history, location } = props;

    const [collectionResponse, setCollectionResponse] =
        useState<IntegrationsListData>();

    const [collectionTotal, setCollectionTotal] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const defaultFilters: IntegrationsFilter = {
        order: '-createdAt',
        status: 'status_live',
        type: 'elogbooks',
    };

    const [filters, setFilters] = useState<{ [str: string]: string }>({
        order: defaultFilters.order,
    });

    const [showRemoveLinkConfirm, setShowRemoveLinkConfirm] =
        useState<boolean>(false);

    const [integrationSelected, setIntegrationSelected] =
        useState<IntegrationData | null>(null);

    const confirmRemove = (column: SyntheticEvent, row: IntegrationData) => {
        setIntegrationSelected(row);
        setShowRemoveLinkConfirm(true);
    };

    const columns: object[] = [
        {
            name: `${props.integrationType} Client`,
            property: 'clientName',
            sortable: false,
            grow: 3,
        },
        {
            name: `${props.integrationType} Service Provider`,
            property: 'companyReferenceName',
            sortable: false,
            grow: 3,
        },
        {
            name: 'Link Created By',
            property: 'createdby',
            type: 'string',
            sortable: false,
            grow: 2,
        },
        {
            name: 'Link Created Date',
            property: 'createdAt',
            type: 'date',
            sortable: false,
            grow: 1,
        },
        {
            name: '',
            property: '',
            type: 'button',
            grow: 1,
            onClick: confirmRemove,
            intent: 'primary',
            text: 'Remove',
            right: true,
        },
    ];

    const load = (params = {}) => {
        (async () => {
            setIsLoading(true);
            const filterParams = Object.assign(
                {},
                defaultFilters,
                props.filter,
                filters,
                params
            );
            setFilters({ ...filters, ...params });
            const integrationList: IntegrationsListData =
                await loadIntegrationList(filterParams);
            setCollectionTotal(integrationList.count);
            setCollectionResponse(integrationList);

            Location.setQueryStringFromObject(history, location, filterParams);

            setIsLoading(false);
        })();
    };

    const clearRemoveConfirmation = () => {
        setIntegrationSelected(null);
        setShowRemoveLinkConfirm(false);
    };

    const handleRemoveLink = () => {
        removeIntegration(
            integrationSelected?._links.deactivate.href, // todo get link
            () => load({ page: 1 })
        );

        clearRemoveConfirmation();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(load, []);

    return (
        <div className="IntegrationsList">
            <Confirmation
                isOpen={showRemoveLinkConfirm}
                onYes={() => handleRemoveLink()}
                onClose={() => {
                    setShowRemoveLinkConfirm(false);
                }}
            >
                <div className="m-2">
                    This will remove the integration link for{' '}
                    {integrationSelected?.name} /{' '}
                    {integrationSelected?.companyReferenceName}
                </div>
            </Confirmation>

            <H1 className="clearfix">
                Integrations List
                <ItemCount count={collectionTotal} />
                <Reload load={load} />
                <ButtonLink
                    type="button"
                    to={`${props.match.url}/add`}
                    intent="primary"
                    className="float-right"
                >
                    <Icon icon="plus-circle" />
                    {`Add ${props.integrationType} Integration`}
                </ButtonLink>
            </H1>

            <Loading isLoading={isLoading}>
                <NoResultsFound count={collectionTotal}>
                    <Table
                        data={collectionResponse?.integrations}
                        columns={columns}
                        onSort={load}
                        ordering={defaultFilters.order}
                    />

                    <Paginator
                        page={collectionResponse?.page}
                        count={collectionResponse?.count}
                        limit={collectionResponse?.limit}
                        onPage={load}
                    />
                </NoResultsFound>
            </Loading>
        </div>
    );
};

export default IntegrationsList;
