import { FormEvent } from 'react';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

import { Response, HTTP } from 'service';
import {
    AssessmentTypeData,
    AssessmentTypeFilter,
    AssessmentTypeListData,
    SLASData,
} from 'interface';
import { NONE_SELECTED_OPTION } from 'constants/none-selected';

class AssessmentTypeService {
    static loadList: (
        filter?: Partial<AssessmentTypeFilter>
    ) => Promise<AssessmentTypeListData> = async (
        filter?: Partial<AssessmentTypeFilter>
    ) => {
        const response: AxiosResponse | void = await HTTP.action(
            'get',
            '/assessment-types',
            filter ? filter : {},
            {},
            'Unable to load assessment list list'
        );
        if (response) {
            return {
                ...response.data,
                data: response.data.assessmentTypes,
            } as AssessmentTypeListData;
        } else {
            return {
                page: 0,
                limit: 0,
                pages: 0,
                count: 0,
                _links: [],
                assessmentTypes: [],
                data: [],
            } as AssessmentTypeListData;
        }
    };

    static loadAssessment: (id: string) => Promise<AssessmentTypeData> = async (
        id: string
    ) => {
        const response: AxiosResponse | void = await HTTP.action(
            'get',
            '/assessment-types/' + id,
            { datagroup: 'details' },
            {},
            'Unable to load assessment'
        );

        return response?.data;
    };

    static createAssessmentTypeFilter(): AssessmentTypeFilter {
        return {
            name: '',
            isActive: true,
            order: 'name',
        } as AssessmentTypeFilter;
    }

    static submitSLASHandler: (
        e: FormEvent<HTMLFormElement>,
        form: SLASData,
        assessmentType: AssessmentTypeData,
        mergeResponse: { (assessmentType: AssessmentTypeData): void }
    ) => void = async (
        e: FormEvent<HTMLFormElement>,
        form: SLASData,
        assessmentType: AssessmentTypeData,
        mergeResponse: { (assessmentType: AssessmentTypeData): void }
    ) => {
        e.preventDefault();
        e.stopPropagation();

        const url = Response.getLink(assessmentType, 'edit-slas');

        if (url) {
            const response: AxiosResponse | void = await HTTP.action(
                'put',
                url,
                form,
                {},
                'Unable to update SLAs'
            );
            if (response) {
                assessmentType.slas = form;
                mergeResponse(assessmentType);
                toast.success('Updated SLAs');
            }
        } else {
            toast.error('Unable to update SLAs');
        }
    };

    static getAssessmentTypeListForDropdown = async () => {
        const options = [NONE_SELECTED_OPTION];
        const assessmentTypes: AssessmentTypeListData = await this.loadList({
            limit: 999,
            isActive: true,
            order: 'name',
        });
        assessmentTypes.assessmentTypes.forEach((type) => {
            options.push({
                label: type.name as string,
                value: type.rootId as string,
            });
        });

        return options;
    };
}

export default AssessmentTypeService;
