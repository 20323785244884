import { NONE_SELECTED_OPTION } from 'constants/none-selected';

export const AF = { label: 'Afghanistan', value: 'AF' };
export const AX = { label: 'Aland Islands', value: 'AX' };
export const AL = { label: 'Albania', value: 'AL' };
export const DZ = { label: 'Algeria', value: 'DZ' };
export const AS = { label: 'American Samoa', value: 'AS' };
export const AD = { label: 'Andorra', value: 'AD' };
export const AO = { label: 'Angola', value: 'AO' };
export const AI = { label: 'Anguilla', value: 'AI' };
export const AQ = { label: 'Antarctica', value: 'AQ' };
export const AG = { label: 'Antigua And Barbuda', value: 'AG' };
export const AR = { label: 'Argentina', value: 'AR' };
export const AM = { label: 'Armenia', value: 'AM' };
export const AW = { label: 'Aruba', value: 'AW' };
export const AU = { label: 'Australia', value: 'AU' };
export const AT = { label: 'Austria', value: 'AT' };
export const AZ = { label: 'Azerbaijan', value: 'AZ' };
export const BS = { label: 'Bahamas', value: 'BS' };
export const BH = { label: 'Bahrain', value: 'BH' };
export const BD = { label: 'Bangladesh', value: 'BD' };
export const BB = { label: 'Barbados', value: 'BB' };
export const BY = { label: 'Belarus', value: 'BY' };
export const BE = { label: 'Belgium', value: 'BE' };
export const BZ = { label: 'Belize', value: 'BZ' };
export const BJ = { label: 'Benin', value: 'BJ' };
export const BM = { label: 'Bermuda', value: 'BM' };
export const BT = { label: 'Bhutan', value: 'BT' };
export const BO = { label: 'Bolivia', value: 'BO' };
export const BA = { label: 'Bosnia And Herzegovina', value: 'BA' };
export const BW = { label: 'Botswana', value: 'BW' };
export const BV = { label: 'Bouvet Island', value: 'BV' };
export const BR = { label: 'Brazil', value: 'BR' };
export const IO = { label: 'British Indian Ocean Territory', value: 'IO' };
export const BN = { label: 'Brunei Darussalam', value: 'BN' };
export const BG = { label: 'Bulgaria', value: 'BG' };
export const BF = { label: 'Burkina Faso', value: 'BF' };
export const BI = { label: 'Burundi', value: 'BI' };
export const KH = { label: 'Cambodia', value: 'KH' };
export const CM = { label: 'Cameroon', value: 'CM' };
export const CA = { label: 'Canada', value: 'CA' };
export const CV = { label: 'Cape Verde', value: 'CV' };
export const KY = { label: 'Cayman Islands', value: 'KY' };
export const CF = { label: 'Central African Republic', value: 'CF' };
export const TD = { label: 'Chad', value: 'TD' };
export const CL = { label: 'Chile', value: 'CL' };
export const CN = { label: 'China', value: 'CN' };
export const CX = { label: 'Christmas Island', value: 'CX' };
export const CC = { label: 'Cocos (Keeling) Islands', value: 'CC' };
export const CO = { label: 'Colombia', value: 'CO' };
export const KM = { label: 'Comoros', value: 'KM' };
export const CG = { label: 'Congo', value: 'CG' };
export const CD = { label: 'Congo, Democratic Republic', value: 'CD' };
export const CK = { label: 'Cook Islands', value: 'CK' };
export const CR = { label: 'Costa Rica', value: 'CR' };
export const CI = { label: 'Cote D"Ivoire', value: 'CI' };
export const HR = { label: 'Croatia', value: 'HR' };
export const CU = { label: 'Cuba', value: 'CU' };
export const CY = { label: 'Cyprus', value: 'CY' };
export const CZ = { label: 'Czech Republic', value: 'CZ' };
export const DK = { label: 'Denmark', value: 'DK' };
export const DJ = { label: 'Djibouti', value: 'DJ' };
export const DM = { label: 'Dominica', value: 'DM' };
export const DO = { label: 'Dominican Republic', value: 'DO' };
export const EC = { label: 'Ecuador', value: 'EC' };
export const EG = { label: 'Egypt', value: 'EG' };
export const SV = { label: 'El Salvador', value: 'SV' };
export const GQ = { label: 'Equatorial Guinea', value: 'GQ' };
export const ER = { label: 'Eritrea', value: 'ER' };
export const EE = { label: 'Estonia', value: 'EE' };
export const ET = { label: 'Ethiopia', value: 'ET' };
export const FK = { label: 'Falkland Islands (Malvinas)', value: 'FK' };
export const FO = { label: 'Faroe Islands', value: 'FO' };
export const FJ = { label: 'Fiji', value: 'FJ' };
export const FI = { label: 'Finland', value: 'FI' };
export const FR = { label: 'France', value: 'FR' };
export const GF = { label: 'French Guiana', value: 'GF' };
export const PF = { label: 'French Polynesia', value: 'PF' };
export const TF = { label: 'French Southern Territories', value: 'TF' };
export const GA = { label: 'Gabon', value: 'GA' };
export const GM = { label: 'Gambia', value: 'GM' };
export const GE = { label: 'Georgia', value: 'GE' };
export const DE = { label: 'Germany', value: 'DE' };
export const GH = { label: 'Ghana', value: 'GH' };
export const GI = { label: 'Gibraltar', value: 'GI' };
export const GR = { label: 'Greece', value: 'GR' };
export const GL = { label: 'Greenland', value: 'GL' };
export const GD = { label: 'Grenada', value: 'GD' };
export const GP = { label: 'Guadeloupe', value: 'GP' };
export const GU = { label: 'Guam', value: 'GU' };
export const GT = { label: 'Guatemala', value: 'GT' };
export const GG = { label: 'Guernsey', value: 'GG' };
export const GN = { label: 'Guinea', value: 'GN' };
export const GW = { label: 'Guinea-Bissau', value: 'GW' };
export const GY = { label: 'Guyana', value: 'GY' };
export const HT = { label: 'Haiti', value: 'HT' };
export const HM = { label: 'Heard Island & Mcdonald Islands', value: 'HM' };
export const VA = { label: 'Holy See (Vatican City State)', value: 'VA' };
export const HN = { label: 'Honduras', value: 'HN' };
export const HK = { label: 'Hong Kong', value: 'HK' };
export const HU = { label: 'Hungary', value: 'HU' };
export const IS = { label: 'Iceland', value: 'IS' };
export const IN = { label: 'India', value: 'IN' };
export const ID = { label: 'Indonesia', value: 'ID' };
export const IR = { label: 'Iran, Islamic Republic Of', value: 'IR' };
export const IQ = { label: 'Iraq', value: 'IQ' };
export const IE = { label: 'Ireland', value: 'IE' };
export const IM = { label: 'Isle Of Man', value: 'IM' };
export const IL = { label: 'Israel', value: 'IL' };
export const IT = { label: 'Italy', value: 'IT' };
export const JM = { label: 'Jamaica', value: 'JM' };
export const JP = { label: 'Japan', value: 'JP' };
export const JE = { label: 'Jersey', value: 'JE' };
export const JO = { label: 'Jordan', value: 'JO' };
export const KZ = { label: 'Kazakhstan', value: 'KZ' };
export const KE = { label: 'Kenya', value: 'KE' };
export const KI = { label: 'Kiribati', value: 'KI' };
export const KR = { label: 'Korea', value: 'KR' };
export const KP = { label: 'North Korea', value: 'KP' };
export const KW = { label: 'Kuwait', value: 'KW' };
export const KG = { label: 'Kyrgyzstan', value: 'KG' };
export const LA = { label: 'Lao People"s Democratic Republic', value: 'LA' };
export const LV = { label: 'Latvia', value: 'LV' };
export const LB = { label: 'Lebanon', value: 'LB' };
export const LS = { label: 'Lesotho', value: 'LS' };
export const LR = { label: 'Liberia', value: 'LR' };
export const LY = { label: 'Libyan Arab Jamahiriya', value: 'LY' };
export const LI = { label: 'Liechtenstein', value: 'LI' };
export const LT = { label: 'Lithuania', value: 'LT' };
export const LU = { label: 'Luxembourg', value: 'LU' };
export const MO = { label: 'Macao', value: 'MO' };
export const MK = { label: 'Macedonia', value: 'MK' };
export const MG = { label: 'Madagascar', value: 'MG' };
export const MW = { label: 'Malawi', value: 'MW' };
export const MY = { label: 'Malaysia', value: 'MY' };
export const MV = { label: 'Maldives', value: 'MV' };
export const ML = { label: 'Mali', value: 'ML' };
export const MT = { label: 'Malta', value: 'MT' };
export const MH = { label: 'Marshall Islands', value: 'MH' };
export const MQ = { label: 'Martinique', value: 'MQ' };
export const MR = { label: 'Mauritania', value: 'MR' };
export const MU = { label: 'Mauritius', value: 'MU' };
export const YT = { label: 'Mayotte', value: 'YT' };
export const MX = { label: 'Mexico', value: 'MX' };
export const FM = { label: 'Micronesia, Federated States Of', value: 'FM' };
export const MD = { label: 'Moldova', value: 'MD' };
export const MC = { label: 'Monaco', value: 'MC' };
export const MN = { label: 'Mongolia', value: 'MN' };
export const ME = { label: 'Montenegro', value: 'ME' };
export const MS = { label: 'Montserrat', value: 'MS' };
export const MA = { label: 'Morocco', value: 'MA' };
export const MZ = { label: 'Mozambique', value: 'MZ' };
export const MM = { label: 'Myanmar', value: 'MM' };
export const NA = { label: 'Namibia', value: 'NA' };
export const NR = { label: 'Nauru', value: 'NR' };
export const NP = { label: 'Nepal', value: 'NP' };
export const NL = { label: 'Netherlands', value: 'NL' };
export const AN = { label: 'Netherlands Antilles', value: 'AN' };
export const NC = { label: 'New Caledonia', value: 'NC' };
export const NZ = { label: 'New Zealand', value: 'NZ' };
export const NI = { label: 'Nicaragua', value: 'NI' };
export const NE = { label: 'Niger', value: 'NE' };
export const NG = { label: 'Nigeria', value: 'NG' };
export const NU = { label: 'Niue', value: 'NU' };
export const NF = { label: 'Norfolk Island', value: 'NF' };
export const MP = { label: 'Northern Mariana Islands', value: 'MP' };
export const NO = { label: 'Norway', value: 'NO' };
export const OM = { label: 'Oman', value: 'OM' };
export const PK = { label: 'Pakistan', value: 'PK' };
export const PW = { label: 'Palau', value: 'PW' };
export const PS = { label: 'Palestinian Territory, Occupied', value: 'PS' };
export const PA = { label: 'Panama', value: 'PA' };
export const PG = { label: 'Papua New Guinea', value: 'PG' };
export const PY = { label: 'Paraguay', value: 'PY' };
export const PE = { label: 'Peru', value: 'PE' };
export const PH = { label: 'Philippines', value: 'PH' };
export const PN = { label: 'Pitcairn', value: 'PN' };
export const PL = { label: 'Poland', value: 'PL' };
export const PT = { label: 'Portugal', value: 'PT' };
export const PR = { label: 'Puerto Rico', value: 'PR' };
export const QA = { label: 'Qatar', value: 'QA' };
export const RE = { label: 'Reunion', value: 'RE' };
export const RO = { label: 'Romania', value: 'RO' };
export const RU = { label: 'Russian Federation', value: 'RU' };
export const RW = { label: 'Rwanda', value: 'RW' };
export const BL = { label: 'Saint Barthelemy', value: 'BL' };
export const SH = { label: 'Saint Helena', value: 'SH' };
export const KN = { label: 'Saint Kitts And Nevis', value: 'KN' };
export const LC = { label: 'Saint Lucia', value: 'LC' };
export const MF = { label: 'Saint Martin', value: 'MF' };
export const PM = { label: 'Saint Pierre And Miquelon', value: 'PM' };
export const VC = { label: 'Saint Vincent And Grenadines', value: 'VC' };
export const WS = { label: 'Samoa', value: 'WS' };
export const SM = { label: 'San Marino', value: 'SM' };
export const ST = { label: 'Sao Tome And Principe', value: 'ST' };
export const SA = { label: 'Saudi Arabia', value: 'SA' };
export const SN = { label: 'Senegal', value: 'SN' };
export const RS = { label: 'Serbia', value: 'RS' };
export const SC = { label: 'Seychelles', value: 'SC' };
export const SL = { label: 'Sierra Leone', value: 'SL' };
export const SG = { label: 'Singapore', value: 'SG' };
export const SK = { label: 'Slovakia', value: 'SK' };
export const SI = { label: 'Slovenia', value: 'SI' };
export const SB = { label: 'Solomon Islands', value: 'SB' };
export const SO = { label: 'Somalia', value: 'SO' };
export const ZA = { label: 'South Africa', value: 'ZA' };
export const GS = { label: 'South Georgia And Sandwich Isl.', value: 'GS' };
export const ES = { label: 'Spain', value: 'ES' };
export const LK = { label: 'Sri Lanka', value: 'LK' };
export const SD = { label: 'Sudan', value: 'SD' };
export const SR = { label: 'Suriname', value: 'SR' };
export const SJ = { label: 'Svalbard And Jan Mayen', value: 'SJ' };
export const SZ = { label: 'Swaziland', value: 'SZ' };
export const SE = { label: 'Sweden', value: 'SE' };
export const CH = { label: 'Switzerland', value: 'CH' };
export const SY = { label: 'Syrian Arab Republic', value: 'SY' };
export const TW = { label: 'Taiwan', value: 'TW' };
export const TJ = { label: 'Tajikistan', value: 'TJ' };
export const TZ = { label: 'Tanzania', value: 'TZ' };
export const TH = { label: 'Thailand', value: 'TH' };
export const TL = { label: 'Timor-Leste', value: 'TL' };
export const TG = { label: 'Togo', value: 'TG' };
export const TK = { label: 'Tokelau', value: 'TK' };
export const TO = { label: 'Tonga', value: 'TO' };
export const TT = { label: 'Trinidad And Tobago', value: 'TT' };
export const TN = { label: 'Tunisia', value: 'TN' };
export const TR = { label: 'Turkey', value: 'TR' };
export const TM = { label: 'Turkmenistan', value: 'TM' };
export const TC = { label: 'Turks And Caicos Islands', value: 'TC' };
export const TV = { label: 'Tuvalu', value: 'TV' };
export const UG = { label: 'Uganda', value: 'UG' };
export const UA = { label: 'Ukraine', value: 'UA' };
export const AE = { label: 'United Arab Emirates', value: 'AE' };
export const GB = { label: 'United Kingdom', value: 'GB' };
export const US = { label: 'United States', value: 'US' };
export const UM = { label: 'United States Outlying Islands', value: 'UM' };
export const UY = { label: 'Uruguay', value: 'UY' };
export const UZ = { label: 'Uzbekistan', value: 'UZ' };
export const VU = { label: 'Vanuatu', value: 'VU' };
export const VE = { label: 'Venezuela', value: 'VE' };
export const VN = { label: 'Vietnam', value: 'VN' };
export const VG = { label: 'Virgin Islands, British', value: 'VG' };
export const VI = { label: 'Virgin Islands, U.S.', value: 'VI' };
export const WF = { label: 'Wallis And Futuna', value: 'WF' };
export const EH = { label: 'Western Sahara', value: 'EH' };
export const YE = { label: 'Yemen', value: 'YE' };
export const ZM = { label: 'Zambia', value: 'ZM' };
export const ZW = { label: 'Zimbabwe', value: 'ZW' };

export const COUNTRY_CODE_OPTIONS = [
    NONE_SELECTED_OPTION,
    AF,
    AX,
    AL,
    DZ,
    AS,
    AD,
    AO,
    AI,
    AQ,
    AG,
    AR,
    AM,
    AW,
    AU,
    AT,
    AZ,
    BS,
    BH,
    BD,
    BB,
    BY,
    BE,
    BZ,
    BJ,
    BM,
    BT,
    BO,
    BA,
    BW,
    BV,
    BR,
    IO,
    BN,
    BG,
    BF,
    BI,
    KH,
    CM,
    CA,
    CV,
    KY,
    CF,
    TD,
    CL,
    CN,
    CX,
    CC,
    CO,
    KM,
    CG,
    CD,
    CK,
    CR,
    CI,
    HR,
    CU,
    CY,
    CZ,
    DK,
    DJ,
    DM,
    DO,
    EC,
    EG,
    SV,
    GQ,
    ER,
    EE,
    ET,
    FK,
    FO,
    FJ,
    FI,
    FR,
    GF,
    PF,
    TF,
    GA,
    GM,
    GE,
    DE,
    GH,
    GI,
    GR,
    GL,
    GD,
    GP,
    GU,
    GT,
    GG,
    GN,
    GW,
    GY,
    HT,
    HM,
    VA,
    HN,
    HK,
    HU,
    IS,
    IN,
    ID,
    IR,
    IQ,
    IE,
    IM,
    IL,
    IT,
    JM,
    JP,
    JE,
    JO,
    KZ,
    KE,
    KI,
    KR,
    KP,
    KW,
    KG,
    LA,
    LV,
    LB,
    LS,
    LR,
    LY,
    LI,
    LT,
    LU,
    MO,
    MK,
    MG,
    MW,
    MY,
    MV,
    ML,
    MT,
    MH,
    MQ,
    MR,
    MU,
    YT,
    MX,
    FM,
    MD,
    MC,
    MN,
    ME,
    MS,
    MA,
    MZ,
    MM,
    NA,
    NR,
    NP,
    NL,
    AN,
    NC,
    NZ,
    NI,
    NE,
    NG,
    NU,
    NF,
    MP,
    NO,
    OM,
    PK,
    PW,
    PS,
    PA,
    PG,
    PY,
    PE,
    PH,
    PN,
    PL,
    PT,
    PR,
    QA,
    RE,
    RO,
    RU,
    RW,
    BL,
    SH,
    KN,
    LC,
    MF,
    PM,
    VC,
    WS,
    SM,
    ST,
    SA,
    SN,
    RS,
    SC,
    SL,
    SG,
    SK,
    SI,
    SB,
    SO,
    ZA,
    GS,
    ES,
    LK,
    SD,
    SR,
    SJ,
    SZ,
    SE,
    CH,
    SY,
    TW,
    TJ,
    TZ,
    TH,
    TL,
    TG,
    TK,
    TO,
    TT,
    TN,
    TR,
    TM,
    TC,
    TV,
    UG,
    UA,
    AE,
    GB,
    US,
    UM,
    UY,
    UZ,
    VU,
    VE,
    VN,
    VG,
    VI,
    WF,
    EH,
    YE,
    ZM,
    ZW,
];

export const findCountryByLabel = (countryLabel) => {
    return COUNTRY_CODE_OPTIONS.find(({ label }) => label === countryLabel);
};
