import moment from 'moment';

interface CAFMUpdateTimeProps {
    updatedAt: string | null;
    isLoading: boolean;
    includeDateMessage?: boolean;
}

const CAFMUpdateTime = (props: CAFMUpdateTimeProps) => {
    const { updatedAt, isLoading, includeDateMessage = true } = props;

    if (isLoading === true) {
        return <></>;
    }

    const dataCorrectMessage =
        includeDateMessage === true
            ? `Data accurate as of ${moment(updatedAt).format(
                  'DD/MM/YYYY HH:mm'
              )}. `
            : '';

    return updatedAt !== null ? (
        <span>
            {dataCorrectMessage}
            Jobs completed in the last 30 days (completed from{' '}
            {moment(updatedAt)
                .subtract(30, 'days')
                .format('DD/MM/YYYY 00:00')}{' '}
            until {moment(updatedAt).format('DD/MM/YYYY HH:mm')})
        </span>
    ) : (
        <span>No data received from CAFM yet</span>
    );
};

export default CAFMUpdateTime;
