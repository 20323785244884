import {
    Button,
    FormGroup,
    H1,
    H2,
    InputGroup,
    Radio,
    RadioGroup,
    TextArea,
} from '@blueprintjs/core';
import { AxiosResponse } from 'axios';
import { isEmpty, merge } from 'lodash';
import PropTypes from 'prop-types';
import { ChangeEvent, Component, FormEvent } from 'react';
import { isMobile } from 'react-device-detect';
import { RouteComponentProps, withRouter } from 'react-router';
import { toast } from 'react-toastify';

import {
    ButtonLink,
    HTMLSelect,
    Icon,
    InputElementIcon,
    MultiSelectRenderer,
    SuggestRenderer,
} from 'components/elements';
import FileSingleUpload from 'components/elements/file-upload/FileSingleUpload';
import { Loading } from 'components/elements/wrappers';
import {
    REVIEW_FREQUENCY_ANNUAL,
    REVIEW_FREQUENCY_BY_CLIENT,
    REVIEW_FREQUENCY_CASE_BY_CASE,
    REVIEW_FREQUENCY_ONE_OFF,
} from 'constants/assessmentTypeReviewFrequencies';
import { ASSESSMENT_TYPE_SERVICE_PROVIDER_SIZE_APPLICABILITY_OPTIONS } from 'constants/assessmentTypeServiceProviderSizeApplicability';
import {
    ASSESSMENT_TYPE_TYPE_CLIENT,
    ASSESSMENT_TYPE_TYPE_OPTIONS,
    ASSESSMENT_TYPE_TYPE_PROSURE,
} from 'constants/assessmentTypeTypes';
import { MAX_FILE_UPLOAD_SIZE_MB } from 'constants/general';
import { SSIP_SCOPE_CATEGORY_OPTIONS } from 'constants/ssipScopeCategories';
import { globalDebounce } from 'helpers/helpers';
import { AssessmentTypeData, ClientData } from 'interface';
import { HTTP, Response, Routing, StateHandler } from 'service';

interface AssessmentTypeAddEditProps extends RouteComponentProps<any> {
    assessmentTypeResponse: AssessmentTypeData;
}

class AssessmentTypeAddEdit extends Component {
    static defaultProps = {};
    static propTypes = {
        assessmentTypeResponse: PropTypes.any.isRequired,
    };
    public readonly state: any;
    public readonly props: any;

    constructor(props: AssessmentTypeAddEditProps) {
        super(props);

        const state: any = {
            form: Object.assign(
                {
                    name: '',
                    shortName: '',
                    description: '',
                    isActive: 'false',
                    isSSIPAccreditation: 'false',
                    isSSIPDTS: 'false',
                    isProsurePreQualified: 'false',
                    isProsureAssessorReviewerRequired: 'true',
                    isClientAssessorRequired: 'false',
                    isVisibleToClient: 'false',
                    isSLAApplicable: 'false',
                    SSIPScopeCategories: [],
                    reviewFrequency: REVIEW_FREQUENCY_ONE_OFF,
                    type: 'prosure',
                    allowAutomatedPassFail: 'false',
                    serviceProviderSizeApplicability: null,
                    _links: {},
                },
                props.assessmentTypeResponse || {}
            ),
            selectedClient: {},
            isLoading: false,
            isLoadingClients: false,
            isLoadingSelectedClient: false,
            clientSearchValue: '',
            assessmentTypeId: props.assessmentTypeResponse
                ? props.assessmentTypeResponse.id
                : undefined,
            clientsCollection: { clients: [] },
            selectedSsipScopeCategories: [],
            clientAssessorProcess: 'none',
        };

        if (state.form.type === 'client') {
            if (
                state.form.isProsureAssessorReviewerRequired === 'true' ||
                state.form.isProsureAssessorReviewerRequired === true
            ) {
                state.clientAssessorProcess =
                    'isProsureAssessorReviewerRequired';
            } else if (
                state.form.isClientAssessorRequired === 'true' ||
                state.form.isClientAssessorRequired === true
            ) {
                state.clientAssessorProcess = 'isClientAssessorRequired';
            } else if (
                state.form.allowAutomatedPassFail === 'true' ||
                state.form.allowAutomatedPassFail === true
            ) {
                state.clientAssessorProcess = 'allowAutomatedPassFail';
            } else {
                state.clientAssessorProcess = 'none';
            }
        }

        this.state = state;
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        const { assessmentTypeResponse } = this.props;
        this.loadClients();
        this.parseSelectedSSIPScopeCategories();

        if (Response.getLink(assessmentTypeResponse, 'client')) {
            this.loadSelectedClient(assessmentTypeResponse);
        }
        this.setState({ isLoading: false });
    }

    clientSearch = (query = null) => {
        globalDebounce(
            () => {
                this.setState({ clientSearchValue: query ?? '' });
                this.loadClients(query);
            },
            'clientSearch',
            250
        );
    };

    handleSubmit(event: FormEvent<HTMLFormElement>) {
        event.stopPropagation();
        event.preventDefault();

        const { assessmentTypeResponse, mergeResponse, history, match } =
            this.props;
        const { form } = this.state as any;

        if (isEmpty(form?._links?.file)) {
            delete form._links.file;
        }

        if (
            form.isSSIPAccreditation === false ||
            form.isSSIPAccreditation === 'false'
        ) {
            delete form.SSIPScopeCategories;
        }

        if (typeof assessmentTypeResponse === 'object') {
            return HTTP.put(
                Response.getLink(assessmentTypeResponse, 'edit'),
                form
            )
                .then((response) => {
                    if (response) {
                        toast.success('Assessment Type updated');
                        Routing.navigateUpOneLevel(history, match);

                        if (mergeResponse) {
                            mergeResponse(form);
                        }

                        return true;
                    }

                    toast.error('Unable to update Assessment Type');

                    return false;
                })
                .catch((error) => {
                    let response = error.response;

                    if (
                        response?.data?.length &&
                        response.data[0].hasOwnProperty('message')
                    ) {
                        toast.error(
                            `${response.data[0].property_path}: ${response.data[0].message}`
                        );
                        return false;
                    }
                    toast.error(error.message);

                    return false;
                });
        }

        HTTP.post('/assessment-types/new', form)
            .then((response) => {
                if (response) {
                    toast.success('Assessment Type created');

                    Routing.navigateUpOneLevel(
                        history,
                        match,
                        response.data.id
                    );

                    return true;
                }

                toast.error('Unable to create Assessment Type');

                return false;
            })
            .catch((error) => {
                let response = error.response;

                if (
                    response?.data?.length &&
                    response.data[0].hasOwnProperty('message')
                ) {
                    toast.error(
                        `${response.data[0].property_path}: ${response.data[0].message}`
                    );
                    return false;
                }
                toast.error(error.message);

                return false;
            });
    }

    onUpdate = (fileJson: any) => {
        const { form } = this.state as any;
        const result = merge({}, form, fileJson);
        this.setState({ form: result });
    };

    loadClients = (query = null) => {
        if (query === null) {
            // on initial load only set as loading
            this.setState({ isLoadingClients: true });
        }

        let params: any = {
            datagroup: 'details',
            order: 'name',
            invitable: true,
            isActive: 'true',
        };

        if (query) {
            params.name = query;
        }

        HTTP.get('/clients', params).then((response: AxiosResponse | void) => {
            this.setState({
                clientsCollection: response?.data,
                isLoadingClients: false,
            });
        });
    };

    loadSelectedClient = (assessmentTypeResponse: AssessmentTypeData) => {
        this.setState({ isLoadingSelectedClient: true });

        HTTP.get(Response.getLink(assessmentTypeResponse, 'client')).then(
            (response) => {
                if (response) {
                    this.setState({
                        selectedClient: response.data,
                        isLoadingSelectedClient: false,
                    });

                    return true;
                }

                toast.error('Unable to fetch Client');

                return false;
            }
        );
    };

    onProsureAssessmentUpdate = (
        event: FormEvent<HTMLInputElement>,
        property: string
    ) => {
        const updatedState = {
            form: {},
            ...this.state,
            clientAssessorProcess: 'none',
        };
        const { value } = event.target as HTMLInputElement;
        updatedState.form = {
            ...updatedState.form,
            isSSIPAccreditation: 'false',
            isSSIPDTS: 'false',
            isProsurePreQualified: 'false',
            isProsureAssessorReviewerRequired: 'true',
            isClientAssessorRequired: 'false',
            [property]: value,
        };

        this.setState(updatedState);
    };

    onClientAssessorProcessorUpdate = (event: FormEvent<HTMLInputElement>) => {
        const { value } = event.target as HTMLInputElement;

        const updatedState = {
            form: {},
            ...this.state,
            clientAssessorProcess: value,
        };
        updatedState.form = {
            ...updatedState.form,
            isSSIPAccreditation: 'false',
            isSSIPDTS: 'false',
            isProsurePreQualified: 'false',
            isProsureAssessorReviewerRequired:
                value === 'isProsureAssessorReviewerRequired'
                    ? 'true'
                    : 'false',
            isClientAssessorRequired:
                value === 'isClientAssessorRequired' ? 'true' : 'false',
            allowAutomatedPassFail:
                value === 'allowAutomatedPassFail' ? 'true' : 'false',
        };

        this.setState(updatedState);
    };

    onAssessmentTypeUpdate = (event: FormEvent) => {
        const { assessmentTypeResponse } = this.props;
        const { value } = event.target as HTMLFormElement;
        const updatedState = { ...this.state } as any;
        const form = { ...updatedState.form, type: value };

        if (updatedState.form.type !== form.type) {
            if (form.type === ASSESSMENT_TYPE_TYPE_CLIENT.value) {
                form.isSSIPAccreditation = 'false';
                form.isSSIPDTS = 'false';
                form.isProsurePreQualified = 'false';
                form.isProsureAssessorReviewerRequired = 'false';
                form.isClientAssessorRequired = 'false';
                form.isVisibleToClient = 'true';
                form.allowAutomatedPassFail = 'false';
                form.reviewFrequency = REVIEW_FREQUENCY_BY_CLIENT;
                updatedState.clientAssessorProcess = 'none';
            } else {
                form.isSSIPAccreditation = 'false';
                form.isSSIPDTS = 'false';
                form.isProsurePreQualified = 'false';
                form.isProsureAssessorReviewerRequired = 'true';
                form.isClientAssessorRequired = 'false';
                form.isVisibleToClient = assessmentTypeResponse
                    ? assessmentTypeResponse.isVisibleToClient
                    : 'false';
                form.allowAutomatedPassFail = 'false';
                form.reviewFrequency = REVIEW_FREQUENCY_ONE_OFF;
                if (
                    assessmentTypeResponse &&
                    assessmentTypeResponse.reviewFrequency !==
                        REVIEW_FREQUENCY_BY_CLIENT
                ) {
                    form.reviewFrequency =
                        assessmentTypeResponse.reviewFrequency;
                }
            }
        }
        updatedState.form = form;

        this.setState(updatedState);
    };

    parseSSIPScopeCategories = (categories: any[] | string) => {
        if (!(categories instanceof Array)) {
            return [];
        }

        categories.forEach((item, index, arr) => {
            arr[index] = { ...item, id: index };
        });

        return categories;
    };

    parseSelectedSSIPScopeCategories = () => {
        const categories = this.parseSSIPScopeCategories(
            SSIP_SCOPE_CATEGORY_OPTIONS
        );
        const { assessmentTypeResponse } = this.props;
        const parsedCategories: string[] = [];
        let SSIPScopeCategories: string[] = [];

        if (typeof assessmentTypeResponse !== 'undefined') {
            SSIPScopeCategories = assessmentTypeResponse.SSIPScopeCategories;
        }

        SSIPScopeCategories.forEach((item) => {
            let categoryIndex = categories.findIndex(
                (category) => category.value === item
            );
            if (categoryIndex !== -1) {
                parsedCategories.push(categories[categoryIndex]);
            }
        });

        this.setState({ selectedSsipScopeCategories: parsedCategories });
    };

    parseUpdatedSSIPScopeCategories = (items: { value: string }[]) => {
        const categories: string[] = [];
        items.forEach((item, index) => {
            categories[index] = item.value;
        });

        return categories;
    };

    render() {
        const {
            assessmentTypeId,
            form,
            selectedClient,
            isLoading,
            isLoadingClients,
            isLoadingSelectedClient,
            clientsCollection,
            clientSearchValue,
            clientAssessorProcess,
            selectedSsipScopeCategories,
        } = this.state as any;
        const { assessmentTypeResponse } = this.props;
        const isEditing = typeof assessmentTypeId !== 'undefined';

        const lockAssessmentInUse =
            typeof assessmentTypeResponse === 'object'
                ? assessmentTypeResponse.lockAssessmentInUse
                : undefined;

        const fileLabel = (
            <>
                Badge Image
                {InputElementIcon(
                    `Required size 175x175px. Max file size ${MAX_FILE_UPLOAD_SIZE_MB}MB`,
                    'info'
                )}
            </>
        );

        const shotNameLabel = (
            <>
                Assessment Short Name
                {InputElementIcon(`Maximum 10 characters`, 'info')}
            </>
        );

        const isVisibleToClientLabel = (
            <>
                Answers & Evidence Visible to Clients
                {InputElementIcon(
                    `If yes, then clients will be able to see information uploaded against assessments logged by Service Providers.`,
                    'info'
                )}
            </>
        );

        const frequencyLabel = (
            <>
                Assessment Review Frequency
                {InputElementIcon(
                    `One-Off – The assessment does not expire and remains valid forever.
                    Annual – The assessment is valid for one-year from the date the assessment is passed.
                    Case-by-Case – The length of validity of the assessment can vary and will be set by the assessor/reviewer.`,
                    'info'
                )}
            </>
        );

        const assessmentProcessLabel = (
            <>
                Assessment Process?
                {InputElementIcon(
                    `Select whether the assessment is to be assessed by our internal Prosure assessors,
                    if the client will take on the assessing role, if questions will be set to automatically fail,
                    or if there is no assessment process, and the assessment is immediately set to completed..`,
                    'info'
                )}
            </>
        );

        const clients =
            clientsCollection && clientsCollection.clients
                ? clientsCollection.clients
                : [];

        let SSIPScopeCategory =
            form.isSSIPAccreditation !== 'true' &&
            form.isSSIPAccreditation !== true ? null : (
                <FormGroup
                    label="SSIP Scope Category"
                    inline={true}
                    className="form-fill required"
                    labelFor="SSIPScopeCategory"
                >
                    <MultiSelectRenderer
                        id={'ssipScopeCategory'}
                        onRemoveItem={(
                            items: { value: string }[],
                            event: FormEvent
                        ) =>
                            this.setState({
                                ...this.state,
                                selectedSsipScopeCategories: items,
                                form: {
                                    ...form,
                                    SSIPScopeCategories:
                                        this.parseUpdatedSSIPScopeCategories(
                                            items
                                        ),
                                },
                            })
                        }
                        onItemSelect={(
                            items: { value: string }[],
                            event: FormEvent
                        ) =>
                            this.setState({
                                ...this.state,
                                selectedSsipScopeCategories: items,
                                form: {
                                    ...form,
                                    SSIPScopeCategories:
                                        this.parseUpdatedSSIPScopeCategories(
                                            items
                                        ),
                                },
                            })
                        }
                        fill={true}
                        items={this.parseSSIPScopeCategories(
                            SSIP_SCOPE_CATEGORY_OPTIONS
                        )}
                        selectedItems={selectedSsipScopeCategories}
                        valueProperty={'label'}
                    />
                </FormGroup>
            );

        return (
            <div className="AssessmentTypeAddEdit">
                <H1>
                    {isEditing
                        ? 'Edit Assessment Type'
                        : 'Add new Assessment Type'}
                </H1>

                <Loading
                    isLoading={
                        isLoading || isLoadingClients || isLoadingSelectedClient
                    }
                >
                    <form
                        className="AddEdit"
                        onSubmit={(event: FormEvent<HTMLFormElement>) =>
                            this.handleSubmit(event)
                        }
                    >
                        <H2 className="clearfix">
                            Assessment Type Information
                        </H2>

                        <FormGroup
                            label="Name"
                            inline={true}
                            className="form-fill required"
                            labelFor="name"
                        >
                            <InputGroup
                                id="name"
                                placeholder="Name"
                                minLength={1}
                                maxLength={255}
                                value={form.name}
                                onChange={(e) =>
                                    this.setState({
                                        form: StateHandler.getStateObject(
                                            e,
                                            form
                                        ),
                                    })
                                }
                                required
                            />
                        </FormGroup>

                        <FormGroup
                            label={shotNameLabel}
                            inline={true}
                            className="form-fill required"
                            labelFor="shortName"
                        >
                            <InputGroup
                                id="shortName"
                                placeholder="Assessment Short Name"
                                minLength={1}
                                maxLength={10}
                                value={form.shortName}
                                onChange={(e) =>
                                    this.setState({
                                        form: StateHandler.getStateObject(
                                            e,
                                            form
                                        ),
                                    })
                                }
                                required
                            />
                        </FormGroup>

                        <FormGroup
                            label="Description"
                            inline={true}
                            className="form-fill required"
                            labelFor="name"
                        >
                            <TextArea
                                id="description"
                                placeholder="Description"
                                onChange={(e) =>
                                    this.setState({
                                        form: StateHandler.getStateObject(
                                            e,
                                            form
                                        ),
                                    })
                                }
                                minLength={1}
                                required
                                value={form.description}
                            />
                        </FormGroup>

                        <FormGroup
                            label="Prosure or Client Assessment"
                            inline={true}
                            className="form-fill required"
                            labelFor="type"
                        >
                            <HTMLSelect
                                disabled={lockAssessmentInUse}
                                id="type"
                                fill={true}
                                defaultValue={form.type?.toString()}
                                onChange={this.onAssessmentTypeUpdate}
                                options={ASSESSMENT_TYPE_TYPE_OPTIONS}
                            />
                        </FormGroup>

                        <FormGroup
                            label="Service Provider Size Applicability"
                            inline={true}
                            className="form-fill required"
                            labelFor="serviceProviderSizeApplicability"
                        >
                            <HTMLSelect
                                id="serviceProviderSizeApplicability"
                                fill={true}
                                defaultValue={
                                    form.serviceProviderSizeApplicability
                                }
                                onChange={(e: ChangeEvent) => {
                                    const { value } =
                                        e.target as HTMLInputElement;
                                    this.setState({
                                        form: StateHandler.getStateObject(
                                            e,
                                            form,
                                            undefined,
                                            !isNaN(parseInt(value))
                                                ? parseInt(value)
                                                : null
                                        ),
                                    });
                                }}
                                options={
                                    ASSESSMENT_TYPE_SERVICE_PROVIDER_SIZE_APPLICABILITY_OPTIONS
                                }
                            />
                        </FormGroup>

                        {form.type === 'client' ? (
                            <FormGroup
                                label="Client"
                                key="client"
                                inline={true}
                                className="form-fill required"
                                labelFor="client"
                            >
                                <SuggestRenderer
                                    id="client"
                                    valueProperty="name"
                                    onItemSelect={(item: ClientData) => {
                                        let clientObject = {
                                            href: Response.getLink(
                                                item,
                                                'self'
                                            ),
                                            name: item?.name,
                                        };
                                        let links = Object.assign(
                                            {},
                                            form._links,
                                            { client: clientObject }
                                        );
                                        let newFormState = Object.assign(
                                            {},
                                            form,
                                            { _links: links }
                                        );
                                        this.setState({ form: newFormState });

                                        return;
                                    }}
                                    defaultItem={selectedClient}
                                    items={clients}
                                    onKeyUp={(event: KeyboardEvent) => {
                                        const { value } =
                                            event.target as HTMLFormElement;
                                        if (
                                            clientSearchValue !== value.trim()
                                        ) {
                                            this.clientSearch(value);
                                        }
                                    }}
                                    isRequired={true}
                                />
                            </FormGroup>
                        ) : null}

                        <FileSingleUpload
                            form={form}
                            fileId="file"
                            isImage={true}
                            isMobile={isMobile}
                            fileLabel={fileLabel}
                            onUpdate={this.onUpdate}
                            isRequired
                        ></FileSingleUpload>

                        {form.type === ASSESSMENT_TYPE_TYPE_PROSURE.value && (
                            <>
                                <FormGroup
                                    label="Prosure SSIP Verified Assessment?"
                                    inline={!isMobile}
                                    className="form-fill required"
                                >
                                    <RadioGroup
                                        disabled={lockAssessmentInUse}
                                        inline={true}
                                        className="form-fill"
                                        selectedValue={form.isSSIPAccreditation?.toString()}
                                        onChange={(
                                            e: FormEvent<HTMLInputElement>
                                        ) =>
                                            this.onProsureAssessmentUpdate(
                                                e,
                                                'isSSIPAccreditation'
                                            )
                                        }
                                    >
                                        <Radio label="Yes" value={'true'} />
                                        <Radio label="No" value={'false'} />
                                    </RadioGroup>
                                </FormGroup>

                                {SSIPScopeCategory}

                                <FormGroup
                                    label="Prosure DTS Assessment?"
                                    inline={!isMobile}
                                    className="form-fill required"
                                >
                                    <RadioGroup
                                        disabled={lockAssessmentInUse}
                                        inline={true}
                                        className="form-fill"
                                        selectedValue={form.isSSIPDTS?.toString()}
                                        onChange={(e) =>
                                            this.onProsureAssessmentUpdate(
                                                e,
                                                'isSSIPDTS'
                                            )
                                        }
                                    >
                                        <Radio label="Yes" value={'true'} />
                                        <Radio label="No" value={'false'} />
                                    </RadioGroup>
                                </FormGroup>

                                <FormGroup
                                    label="Prosure Pre-Qualified"
                                    inline={!isMobile}
                                    className="form-fill"
                                >
                                    <RadioGroup
                                        disabled={lockAssessmentInUse}
                                        inline={true}
                                        className="form-fill"
                                        selectedValue={form.isProsurePreQualified?.toString()}
                                        onChange={(e) =>
                                            this.onProsureAssessmentUpdate(
                                                e,
                                                'isProsurePreQualified'
                                            )
                                        }
                                    >
                                        <Radio label="Yes" value={'true'} />
                                        <Radio label="No" value={'false'} />
                                    </RadioGroup>
                                </FormGroup>
                            </>
                        )}

                        {form.type === ASSESSMENT_TYPE_TYPE_CLIENT.value && (
                            <FormGroup
                                inline={!isMobile}
                                className="form-fill required"
                                label={assessmentProcessLabel}
                            >
                                <RadioGroup
                                    inline={true}
                                    className="form-fill"
                                    selectedValue={clientAssessorProcess?.toString()}
                                    onChange={(e) =>
                                        this.onClientAssessorProcessorUpdate(e)
                                    }
                                >
                                    <Radio
                                        label="Internal Prosure Assessor"
                                        value={
                                            'isProsureAssessorReviewerRequired'
                                        }
                                    />
                                    <Radio
                                        label="Client Assessed"
                                        value={'isClientAssessorRequired'}
                                    />
                                    <Radio
                                        label="Automatic"
                                        value={'allowAutomatedPassFail'}
                                    />
                                    <Radio label="None" value={'none'} />
                                </RadioGroup>
                            </FormGroup>
                        )}

                        {form.reviewFrequency !==
                            REVIEW_FREQUENCY_BY_CLIENT && (
                            <FormGroup
                                inline={!isMobile}
                                className="form-fill required"
                                label={frequencyLabel}
                            >
                                <RadioGroup
                                    inline={true}
                                    className="form-fill"
                                    selectedValue={form.reviewFrequency?.toString()}
                                    onChange={(e) =>
                                        this.setState({
                                            form: StateHandler.getStateObject(
                                                e,
                                                form,
                                                'reviewFrequency'
                                            ),
                                        })
                                    }
                                >
                                    <Radio
                                        label="One-Off"
                                        value={REVIEW_FREQUENCY_ONE_OFF}
                                    />
                                    <Radio
                                        label="Annual"
                                        value={REVIEW_FREQUENCY_ANNUAL}
                                    />
                                    <Radio
                                        label="Case-by-Case"
                                        value={REVIEW_FREQUENCY_CASE_BY_CASE}
                                    />
                                </RadioGroup>
                            </FormGroup>
                        )}

                        {form.type === ASSESSMENT_TYPE_TYPE_PROSURE.value && (
                            <FormGroup
                                label={isVisibleToClientLabel}
                                inline={!isMobile}
                                className="form-fill required"
                            >
                                <RadioGroup
                                    inline
                                    className="form-fill"
                                    selectedValue={form.isVisibleToClient?.toString()}
                                    onChange={(e) =>
                                        this.setState({
                                            form: StateHandler.getStateObject(
                                                e,
                                                form,
                                                'isVisibleToClient'
                                            ),
                                        })
                                    }
                                >
                                    <Radio label="Yes" value="true" />
                                    <Radio label="No" value="false" />
                                </RadioGroup>
                            </FormGroup>
                        )}

                        <FormGroup
                            className="form-fill required"
                            label="SLA Applicable"
                            inline
                        >
                            <RadioGroup
                                inline
                                className="form-fill"
                                selectedValue={form.isSLAApplicable?.toString()}
                                onChange={(e) =>
                                    this.setState({
                                        form: StateHandler.getStateObject(
                                            e,
                                            form,
                                            'isSLAApplicable'
                                        ),
                                    })
                                }
                            >
                                <Radio label="Yes" value="true" />
                                <Radio label="No" value="false" />
                            </RadioGroup>
                        </FormGroup>

                        <FormGroup>
                            <ButtonLink
                                type="button"
                                intent="default"
                                className="float-left"
                                to={
                                    isEditing
                                        ? `/admin/assessment-types/${assessmentTypeId}`
                                        : '/admin/assessment-types'
                                }
                            >
                                <Icon icon="ban" />
                                Cancel
                            </ButtonLink>
                            <Button
                                type="submit"
                                intent="primary"
                                className="float-right"
                            >
                                <Icon icon="paper-plane" />
                                {isEditing ? 'Update' : 'Create'}
                            </Button>
                        </FormGroup>
                    </form>
                </Loading>
            </div>
        );
    }
}

export default withRouter(AssessmentTypeAddEdit);
