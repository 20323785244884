const routes = {
    benefits: {
        href: '/benefits',
        icon: 'columns',
        text: 'Membership Benefits',
        skipNavigation: true,
        skipBreadcrumb: false,
    },
    benefits_membership: {
        href: '/benefits/membership',
        icon: 'columns',
        text: 'Benefits',
    },
    benefits_interest: {
        href: '/benefits/interest',
        icon: 'columns',
        text: 'Registered Interest',
    },
};

export default Object.assign({}, routes);
