import ClientDetails from 'components/clients/ClientDetails';
import { useSelector } from 'react-redux';
import { Redirect, Switch } from 'react-router-dom';

import ProtectedRoute from 'components/elements/ProtectedRoute';
import { Response } from 'service';

const ClientBio = () => {
    const root = useSelector((state: any) => state.root);
    const clientId = Response.getLinkAttribute(root.response, 'client', 'id');

    return (
        <div className="Clients">
            <Switch>
                <ProtectedRoute
                    path="/client-bio"
                    component={ClientDetails}
                    clientId={clientId}
                    isClientBio
                />
                <Redirect to="/client-bio" />
            </Switch>
        </div>
    );
}

export default ClientBio;
