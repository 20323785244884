import { H1 } from "@blueprintjs/core";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import List from "components/elements/List";
import { isAdmin } from "helpers/helpers";
import {
    AssessmentTypeInstanceData,
    Company,
    CompanyReportFilter,
    FlatObject,
    InsuranceData,
    ListCollection
} from "interface";
import { ClientService, Response } from "service";

const SupplyChainRequirementStatusReport = () => {
    const storeClient = useSelector((state: any) => state?.root.client);
    const rootResponse = useSelector((state: any) => state.root.response);
    const [data, setData] = useState<ListCollection<Company>>({} as ListCollection<Company>);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const clientId = isAdmin()
        ? storeClient?.id
        : Response.getLinkAttribute(rootResponse, 'client', 'id');

    const defaultFilters: any = {
        order: 'registeredCompanyName'
    };

    const [filters, setFilters] = useState({
        ...defaultFilters
    });

    const columns: FlatObject[] = [
        {
            name: 'Service Provider Name',
            property: 'registeredCompanyName',
            sortable: true,
            grow: 3,
        },
        {
            name: 'Trading Name',
            property: 'tradingName',
            grow: 2,
        },
        {
            name: 'Supply Chain Status',
            type: 'callback',
            callback: (row: Company) => getRequirementStatus(row),
            grow: 2,
        },
        {
            name: 'Assessments',
            type: 'callback',
            callback: (row: Company) => getAssessmentList(row.activeAssessments),
            grow: 4,
        },
        {
            name: 'Insurances',
            type: 'callback',
            callback: (row: Company) => getInsuranceList(row.insurances),
            grow: 2,
        },
    ];

    const getRequirementStatus = (company: Company) => {
        const supplyChain = company.currentSupplyChainInstance;

        switch (supplyChain.requirementStatus) {
            case "BELOW":
                return <span className="error">Below Requirements</span>;
            case "SUCCESS":
                return <span className="success">All Requirements Met</span>;
            case "EXPIRING":
                return <span className="warning">Requirements Expiring Soon</span>;
        }
    }

    const getInsuranceList = (insurances: Array<InsuranceData>) => {
        let content: Array<any> = [];

        insurances.forEach((insurance: InsuranceData) => {
            const { id, status, insuranceType } = insurance;

            if (status === 'ACTIVE') {
                content.push(getInsuranceText(insuranceType));
                content.push(<br key={id}/>);
            }
        });

        return content;
    }

    const getInsuranceText = (type: string) => {
        let insuranceTypeLabel = "";

        switch (type) {
            case 'professional_indemnity_insurance':
                insuranceTypeLabel = 'Professional Indemnity'
                break;
            case 'public_liability_insurance':
                insuranceTypeLabel = 'Public Liability';
                break;
            case 'employers_liability_insurance':
                insuranceTypeLabel = 'Employers Liability';
                break;
            case 'products_liability_insurance':
                insuranceTypeLabel = 'Products Liability';
                break;
            case 'contractor_all_risk_insurance':
                insuranceTypeLabel = 'Contractor All Risk';
                break;
        }

        return `- ${insuranceTypeLabel}`;
    }

    const getAssessmentList = (assessments: Array<AssessmentTypeInstanceData>) => {
        let content: Array<any> = [];

        assessments.forEach((assessment: AssessmentTypeInstanceData) => {
            content.push(`- ${assessment.assessmentName}`);
            content.push(<br key={assessment.id}/>);
        });

        return content;
    }

    const load = async (newFilters: any = {}) => {
        setFilters(newFilters);
        setIsLoading(true);

        const restFilters = {
            ...filters,
            datagroup: 'search',
            supplyChain: 'active',
            ...newFilters,
        } as CompanyReportFilter;

        const data = await ClientService.loadSupplyChainRequirementsReport(
            restFilters,
            clientId
        );

        setData(data);
        setIsLoading(false);
    }

    const onDownload = async () => {
        const filter = {
            datagroup: 'search',
            supplyChain: 'active',
        } as CompanyReportFilter;

        await ClientService.downloadloadSupplyChainRequirementsReport(clientId, filter)
            .then(() => toast.success('Report Downloaded'))
            .catch(() => toast.error('Report could not be downloaded'));
    };

    return (
        <div className="SupplyChainRequirementStatusReport page">
            {clientId && clientId !== 'all-clients' && (
                <List
                    title="Supply Chain Requirement Status Report"
                    columns={columns}
                    load={load}
                    collection={data}
                    filter={filters}
                    hideFilters
                    isLoading={isLoading}
                    downloadHandler={onDownload}
                />
            )}

            {(!clientId || clientId === 'all-clients') && isAdmin() && (
                <div>
                    <H1>Supply Chain Requirement Status Report</H1>
                    <span>Please select a client</span>
                </div>
            )}
        </div>
    );
}

export default SupplyChainRequirementStatusReport;