import DashboardLinkCount from "./DashboardLinkCount";

interface AssessmentsCountCardProps {
    counts: {
        totalInProgress: number,
        awaitingAssessorCount: number,
        beingAssessedCount: number,
        returnedCount: number,
        awaitingReviewerCount: number,
        beingReviewedCount: number,
    }
}

const DashboardAssessmentsCountCard = (props: AssessmentsCountCardProps) => {
    const { counts } = props;

    const {
        totalInProgress,
        awaitingAssessorCount,
        beingAssessedCount,
        returnedCount,
        awaitingReviewerCount,
        beingReviewedCount,
    } = counts;

    return (
        <ul className="supply-chain-rating">
            <li className="rating-row">
                <DashboardLinkCount
                    to="/review/assessments?currentStatus=status_submitted_awaiting_assessor&isClientAssessed=false"
                    text="Awaiting Assessor"
                    value={awaitingAssessorCount}
                />
            </li>
            <li className="rating-row">
                <DashboardLinkCount
                    to="/review/assessments?currentStatus=status_instance_assigned_to_assessor&isClientAssessed=false"
                    text="Being Assessed"
                    value={beingAssessedCount}
                />
            </li>
            <li className="rating-row">
                <DashboardLinkCount
                    to="/review/assessments?currentStatus=status_submitted_returned&isClientAssessed=false"
                    text="Returned with Feedback"
                    value={returnedCount}
                />
            </li>
            <li className="rating-row">
                <DashboardLinkCount
                    to="/review/assessments?currentStatus=status_pending_awaiting_reviewer&isClientAssessed=false"
                    text="Awaiting Reviewer"
                    value={awaitingReviewerCount}
                />
            </li>
            <li className="rating-row ">
                <DashboardLinkCount
                    to="/review/assessments?currentStatus=status_instance_assigned_to_reviewer&isClientAssessed=false"
                    text="Being Reviewed"
                    value={beingReviewedCount}
                />
            </li>
        </ul>
    );
}

export default DashboardAssessmentsCountCard;