import { ComponentProps, useState } from 'react';
import { Button, Card, Classes, Dialog, Intent } from '@blueprintjs/core';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { copyPreviousAnswers } from 'action';
import { Icon } from 'components/elements';
import { AssessmentStateData } from 'interface';

interface CopyExistingAnswersProp extends ComponentProps<any> {
    afterCopy: Function;
}

const CopyExistingAnswers = (props: CopyExistingAnswersProp) => {
    const { afterCopy } = props;
    const [isCopyAnswersDialogOpen, setIsCopyAnswersDialogOpen] =
        useState(false);
    const {
        assessmentTypeInstance,
        answersHaveBeenCopied,
    }: AssessmentStateData = useSelector((state: any) => state.assessments);
    const dispatch = useDispatch();

    const openCopyAnswersDialog = () => setIsCopyAnswersDialogOpen(true);
    const closeCopyAnswersDialog = () => setIsCopyAnswersDialogOpen(false);

    const processCopyAnswers = async () => {
        await Promise.all([
            dispatch(copyPreviousAnswers())
        ])
        closeCopyAnswersDialog();
        typeof afterCopy === 'function' && afterCopy();
        toast.success('Previous answers copied.');
    };

    return (
        <>
            {assessmentTypeInstance._links['previous-answers'] &&
                !answersHaveBeenCopied && (
                    <>
                        <Card className="success copy-answers clearfix">
                            <p className="float-left">
                                Good news! We've noticed you've answered some of
                                these questions in a previous assessment. You're
                                able to reuse these answers, but you can always
                                overtype the answers if anything has changed. If
                                you'd like to copy across the available answers,
                                click the 'Copy Existing Answers' button.
                            </p>

                            <Button
                                type="button"
                                className="float-right"
                                onClick={openCopyAnswersDialog}
                                intent="primary"
                            >
                                <Icon icon="pencil" />
                                Copy Existing Answers
                            </Button>
                        </Card>
                        <Dialog
                            onClose={closeCopyAnswersDialog}
                            title="Copy Existing Answers"
                            autoFocus={true}
                            canEscapeKeyClose={true}
                            canOutsideClickClose={true}
                            usePortal={true}
                            isOpen={isCopyAnswersDialogOpen}
                        >
                            <div className={Classes.DIALOG_BODY}>
                                Answers provided in other assessments can be
                                copied over to this assessment. Once copied,
                                these answers can be overtyped and updated as
                                necessary. The answers will only be updated in
                                this assessment, not previous ones.
                            </div>
                            <div className={Classes.DIALOG_FOOTER}>
                                <div className="dialog-footer-buttons">
                                    <Button onClick={closeCopyAnswersDialog}>
                                        Cancel
                                    </Button>
                                    <div
                                        className={
                                            Classes.DIALOG_FOOTER_ACTIONS
                                        }
                                    >
                                        <Button
                                            intent={Intent.SUCCESS}
                                            onClick={processCopyAnswers}
                                        >
                                            Confirm
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Dialog>
                    </>
                )}
        </>
    );
};

export default CopyExistingAnswers;
