import { H1, Tab, Tabs } from '@blueprintjs/core';
import { merge } from 'lodash';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, Switch } from 'react-router-dom';
import { toast } from 'react-toastify';

import { updatingBreadcrumbResolves } from 'action/breadcrumb';
import ClientUsers from 'components/client/users/Users';
import ClientAddEdit from 'components/clients/ClientAddEdit';
import ClientInfo from 'components/clients/ClientInfo';
import ClientIntegrations from 'components/clients/ClientIntegrations';
import ClientIntegrationsEdit from 'components/clients/ClientIntegrationsEdit';
import ServiceProviderCategory from 'components/clients/ServiceProviderCategory';
import Subscriptions from 'components/clients/Subscriptions';
import SupplyChainConfig from 'components/clients/SupplyChainConfig';
import NotificationsEdit from 'components/clients/notifications/NotificationsEdit';
import NotificationsSummary from 'components/clients/notifications/NotificationsSummary';
import { Icon, ProtectedRoute, Reload } from 'components/elements';
import { Loading, NoResultsFound } from 'components/elements/wrappers';
import OrganisationSizesPricingForm from 'components/pricing-matrices/OrganisationSizesPricingForm';
import { HTTP, Response, Routing } from 'service';

import './ClientDetails.scss';

const mapStateToProps = (state) => {
    return {
        rootResponse: state.root.response,
    };
};

const mapDispatchToProps = {
    updatingBreadcrumbResolves: updatingBreadcrumbResolves,
};


class ClientDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            clientId: props.match.params.clientId || props.clientId,
            isLoading: false,
            response: {},
        };

        this.load = this.load.bind(this);
        this.mergeClientResponse = this.mergeClientResponse.bind(this);
    }

    componentDidMount() {
        this.props.updatingBreadcrumbResolves({ client: undefined });
        this.load();
    }

    mergeClientResponse(data = {}) {
        const { response } = this.state;
        this.setState({ response: merge({}, response, data) });
    }

    getTabs() {
        const { response } = this.state;
        const { isClientBio } = this.props;

        return [
            {
                id: "/info",
                to: "/info",
                icon: "info",
                text: "Info",
            },
            {
                id: "/integrations",
                to: "/integrations",
                icon: "plug",
                text: "Integrations",
                showTab: !isClientBio
            },
            {
                id: "/users",
                to: "/users",
                icon: "users",
                text: "Users",
            },
            {
                id: "/companies",
                to: "/companies",
                icon: "user-hard-hat",
                text: "Service Providers",
                showTab: !isClientBio
            },
            {
                id: "/sp-requirements",
                to: "/sp-requirements",
                icon: "list-alt",
                text: "SP Requirements",
            },
            {
                id: "/supply-chain-config",
                to: "/supply-chain-config",
                icon: "handshake",
                text: "Supply Chain Config",
                showTab: !isClientBio
            },
            {
                id: "/supply-chain-fees-prosure",
                to: "/supply-chain-fees-prosure",
                icon: "handshake",
                text: "Supply Chain Fees - Prosure",
                showTab: isClientBio
                    ? false
                    : (response._links && response._links['prosure-pricings'])
            },
            {
                id: "/supply-chain-fees-clients",
                to: "/supply-chain-fees-clients",
                icon: "handshake",
                text: "Supply Chain Fees - Clients",
                showTab: isClientBio
                    ? false
                    : (response._links && response._links['client-pricings'])
            },
            {
                id: "/subscriptions",
                to: "/subscriptions",
                icon: "repeat",
                text: "Prosure Subscriptions",
                showTab: isClientBio
                    ? false
                    : (response._links && response._links['subscriptions'])
            },
            {
                id: "/notifications",
                to: "/notifications",
                icon: "bell",
                text: "Notifications",
                showTab: isClientBio
                    ? false
                    : (response._links && response._links['subscriptions'])
            },
        ];
    }

    renderTabs() {
        const tabs = this.getTabs();

        return tabs.map((tab) => {
            if ('showTab' in tab && !tab.showTab) {
                return null;
            }

            return (
                <Tab id={tab.id}>
                    <Link to={`${this.props.match.url}${tab.to}`}>
                        <Icon icon={tab.icon} />
                        {tab.text}
                    </Link>
                </Tab>
            );
        })
    }

    render() {
        const { isLoading, response } = this.state;
        const { location, match } = this.props;

        const tabId = Routing.getSelectedTabId(location.pathname, match.url);

        const prosurePricingBands = [
            {
                name: 'Prosure admin fee excluding tax',
                property: 'prosureAdminFeeExcludingTaxInput',
                propertyField: 'prosureAdminFeeExcludingTax',
                disableCellClick: true
            },
            {
                name: 'Prosure admin fee tax',
                property: 'prosureAdminFeeTaxInput',
                propertyField: 'prosureAdminFeeTax',
                disableCellClick: true
            },
            {
                name: 'Prosure admin fee including Tax',
                property: 'prosureAdminFeeIncludingTaxInput',
                propertyField: 'prosureAdminFeeIncludingTax',
                disableCellClick: true
            },
        ];

        const clientPricingBands = [
            {
                name: 'Client charge excluding tax',
                property: 'clientChargeExcludingTaxInput',
                propertyField: 'clientChargeExcludingTax',
                disableCellClick: true
            },
            {
                name: 'Client charge tax',
                property: 'clientChargeTaxInput',
                propertyField: 'clientChargeTax',
                disableCellClick: true
            },
            {
                name: 'Client charge including tax',
                property: 'clientChargeIncludingTaxInput',
                propertyField: 'clientChargeIncludingTax',
                disableCellClick: true
            },
        ];

        return (
            <div className="ClientDetails">
                <Loading
                    isLoading={isLoading || typeof response.id === 'undefined'}
                >
                    <h1 className="clearfix">{response.name}</h1>

                    <Tabs
                        className='client-tabs'
                        renderActiveTabPanelOnly
                        selectedTabId={tabId}
                    >
                        {this.renderTabs()}
                    </Tabs>
                    <div className="TabContent">
                        <Switch>
                            <ProtectedRoute
                                path={`${match.url}/info`}
                                component={ClientInfo}
                                clientResponse={response}
                                isClientBio={this.props.isClientBio}
                                exact
                            />
                            <ProtectedRoute
                                path={`${match.url}/info/edit`}
                                component={ClientAddEdit}
                                clientResponse={response}
                                mergeClientResponse={this.mergeClientResponse}
                                exact
                            />
                            <ProtectedRoute
                                path={`${match.url}/users`}
                                component={ClientUsers}
                                clientResponse={response}
                                isClientBio={this.props.isClientBio}
                            />
                            <ProtectedRoute
                                path={`${match.url}/integrations`}
                                component={ClientIntegrations}
                                clientResponse={response}
                                exact
                            />
                            <ProtectedRoute
                                path={`${match.url}/integrations/edit`}
                                component={ClientIntegrationsEdit}
                                clientResponse={response}
                                exact
                            />
                            <ProtectedRoute
                                path={`${match.url}/companies`}
                                component={() => {
                                    return (
                                        <>
                                            <H1>
                                                Service Providers
                                                <Reload load={() => {}} />
                                            </H1>
                                            <NoResultsFound count={0} />
                                        </>
                                    );
                                }}
                                clientResponse={response}
                            />
                            <ProtectedRoute
                                path={`${match.url}/supply-chain-config`}
                                component={SupplyChainConfig}
                                reloadData={this.load}
                                clientResponse={response}
                            />
                            <ProtectedRoute
                                path={`${match.url}/sp-requirements`}
                                component={ServiceProviderCategory}
                                clientResponse={response}
                                reload={this.load}
                            />
                            <ProtectedRoute
                                path={`${match.url}/supply-chain-fees-prosure`}
                                component={OrganisationSizesPricingForm}
                                loadLink={Response.getLink(
                                    response,
                                    'prosure-pricings'
                                )}
                                updateLink={Response.getLink(
                                    response,
                                    'update-prosure-pricings'
                                )}
                                pricingBands={prosurePricingBands}
                                exact
                            />
                            <ProtectedRoute
                                path={`${match.url}/supply-chain-fees-clients`}
                                component={OrganisationSizesPricingForm}
                                loadLink={Response.getLink(
                                    response,
                                    'client-pricings'
                                )}
                                updateLink={Response.getLink(
                                    response,
                                    'update-client-pricings'
                                )}
                                pricingBands={clientPricingBands}
                                exact
                            />
                            <ProtectedRoute
                                path={`${match.url}/subscriptions`}
                                component={Subscriptions}
                                clientResponse={response}
                            />
                            <ProtectedRoute
                                path={`${match.url}/notifications`}
                                component={NotificationsSummary}
                                clientResponse={response}
                                exact
                            />
                            <ProtectedRoute
                                path={`${match.url}/notifications/edit`}
                                component={NotificationsEdit}
                                clientResponse={response}
                                reloadData={this.load}
                                exact
                            />
                            <Redirect to={`${match.url}/info`} />
                        </Switch>
                    </div>
                </Loading>
            </div>
        );
    }

    load(params = {}) {
        if (this.state.isLoading) {
            return;
        }

        this.setState({ isLoading: true });

        HTTP.get(`/clients/${this.state.clientId}`, {
            datagroup: 'details',
        }).then((response) => {
            if (response) {
                this.setState({ isLoading: false, response: response.data });
                this.props.updatingBreadcrumbResolves({
                    client: { name: response.data.name },
                });

                return true;
            }

            toast.error('Unable to fetch Client details');
            this.setState({ isLoading: false });

            return false;
        });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientDetails);
