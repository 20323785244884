import { elipsesString, parseDateFormat } from "helpers/helpers";
import { CampaignInvitee } from "interface/Client/Campaign"
import { Response } from "service";

import '../index.scss';

interface InviteeUpdateInfoProps {
    invitee: CampaignInvitee,
    onClick: (event: any, campaign: CampaignInvitee) => void
}

const InviteeUpdateInformation = (props: InviteeUpdateInfoProps) => {
    const { invitee, onClick } = props;
    const hasUpdateLink = !!Response.getLink(invitee, 'update-status');

    const getStatusMessage = (message?: string) => {
        if (!message) {
            return null;
        }

        if (message.length > 50) {
            return elipsesString(message);
        }

        return message;
    }

    const getUpdateButton = (text: string) => {
        return (
            <button
                onClick={(event) => onClick(event, invitee)}
                className="clear-button success" 
            >
                {text ? text : ''}
            </button>
        )
    }

    const getUpdateInfo = () => {
        const { latestUpdateType, latestUpdatedByName } = invitee;
        const updateInfo = [];

        if (latestUpdateType) {
            updateInfo.push(latestUpdateType);
        }

        if (latestUpdatedByName) {
            updateInfo.push(latestUpdatedByName);
        }

        if (!updateInfo.length) {
            return null;
        }

        return (
            <p className="wrap-word bold update-info-text">
                {updateInfo.join(" - ")}
            </p>
        );
    }

    if (invitee.hasOwnProperty('latestStatusUpdate')) {
        let updateStatus = parseDateFormat(invitee.latestStatusUpdate);

        if (invitee.statusIsDeclined) {
            updateStatus += " - Declined";
        }

        const containerOnClick = hasUpdateLink
            ? (e: any) => onClick(e, invitee)
            : undefined

        return (
            <div
                className={`${containerOnClick ? 'update-info-clickable' : ''}`}
                onClick={containerOnClick}
            >
                <span className={invitee.statusIsDeclined ? 'error' : ''}>
                    {updateStatus}
                </span>
                {getUpdateInfo()}
                {invitee.latestStatusMessage && (
                    <p className="wrap-word">
                        {getStatusMessage(invitee.latestStatusMessage)}
                    </p>
                )}
            </div>
        );
    }

    if (hasUpdateLink) {
        return getUpdateButton('Update');
    }

    return <></>;
}

export default InviteeUpdateInformation;