import {
    RESET_ROOT,
    SETTING_ROOT_CLIENT,
    SETTING_ROOT_RESPONSE,
} from 'action/rootConstants';

const defaultState = {
    response: undefined,
    client: null,
};

export default function root(state = defaultState, action) {
    const baseState = Object.assign({}, defaultState, state);

    switch (action.type) {
        case SETTING_ROOT_RESPONSE:
            return Object.assign(baseState, {
                response: action.response,
            });
        case SETTING_ROOT_CLIENT:
            return Object.assign(baseState, {
                client: action.client,
            });
        case RESET_ROOT:
            return Object.assign(defaultState);

        default:
            return Object.assign(baseState);
    }
}
