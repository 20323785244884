import { PopoverPosition } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { Icon } from 'components/elements';

import { FlatObject } from 'interface';
import './company-rating.scss';

interface CompanyRatingProps {
    allRatings?: FlatObject;
    rating?: number;
    total?: number;
    large?: boolean;
    badge?: boolean;
}

const CompanyRating = (props: CompanyRatingProps) => {
    const {
        allRatings = {
            rating: 0,
            activityRating: 0,
            assessmentRating: 0,
            bioCompletionRating: 0,
            certificationMembershipsRating: 0,
            insuranceRating: 0,
            rfaRating: 0,
            logoRating: 0,
        },
        rating = 0,
        total = 100,
        large = false,
        badge = false,
    } = props;

    const ratingTooltipText = (
        <ul>
            <li>
                <h4>Company Bio Completion</h4>
                <ul>
                    <li>
                        Maximum Allocation of Score: 20/100
                        <br />- Service Provider Score:{' '}
                        {allRatings.bioCompletionRating}
                    </li>
                    <li>
                        Score Criteria:
                        <br />0 if less than 10% bio completion rating.
                        <br />2 if 11%-20% bio completion rating.
                        <br />5 if 21%-50% bio completion rating.
                        <br />
                        15 if 51%-75% bio completion rating.
                        <br />
                        18 if 75%-99% bio completion rating.
                        <br />
                        20 if 100% bio completion rating.
                    </li>
                </ul>
            </li>
            <li>
                <h4>Company Logo</h4>
                <ul>
                    <li>
                        Maximum Allocation of Score: 10/100
                        <br />- Service Provider Score:{' '}
                        {allRatings.logoRating}
                    </li>
                    <li>
                        Score Criteria:
                        <br />
                        Uploaded - 10
                    </li>
                </ul>
            </li>
            <li>
                <h4>Live Assessments</h4>
                <ul>
                    <li>
                        Maximum Allocation of Score: 30/100
                        <br />- Service Provider Score:{' '}
                        {allRatings.assessmentRating}
                    </li>
                    <li>
                        Score Criteria:
                        <br />
                        1st Assessment Purchased - 6
                        <br />
                        1st Assessment Completed (active) - 15
                        <br />
                        2nd Assessment Purchased (active) - 6
                        <br />
                        2nd Assessment Completed - 15
                    </li>
                </ul>
            </li>
            <li>
                <h4>Active Insurances</h4>
                <ul>
                    <li>
                        Maximum Allocation of Score: 10/100
                        <br />- Service Provider Score:{' '}
                        {allRatings.insuranceRating}
                    </li>
                    <li>
                        Score Criteria:
                        <br />
                        0 if no insurances ever or expired insurance/s.
                        <br />
                        5 if one Active insurance.
                        <br />
                        10 two or more memberships.
                    </li>
                </ul>
            </li>
            <li>
                <h4>Certification & Memberships</h4>
                <ul>
                    <li>
                        Maximum Allocation of Score: 10/100
                        <br />- Service Provider Score:{' '}
                        {allRatings.certificationMembershipsRating}
                    </li>
                    <li>
                        Score Criteria:
                        <br />0 if no memberships.
                        <br />
                        10 if one or more memberships.
                    </li>
                </ul>
            </li>
            <li>
                <h4>Redflag Alert Status</h4>
                <ul>
                    <li>
                        Maximum Allocation of Score: 10/100
                        <br />- Service Provider Score: {allRatings.rfaRating}
                    </li>
                    <li>
                        Score Criteria:
                        <br />
                        0 if one or more red flags / no data.
                        <br />
                        4 Bronze.
                        <br />
                        8 Silver.
                        <br />
                        10 Gold.
                    </li>
                </ul>
            </li>
            <li>
                <h4>Activity</h4>
                <ul>
                    <li>
                        Maximum Allocation of Score: 10/100
                        <br />- Service Provider Score:{' '}
                        {allRatings.activityRating}
                    </li>
                    <li>
                        Score Criteria:
                        <br />0 if no login from a user ever.
                        <br />3 if login older than 90 days from one or more
                        user.
                        <br />
                        6 if login from 30 days-89 days from one or more user.
                        <br />
                        10 if login in less than 30 days from one or more user.
                    </li>
                </ul>
            </li>
        </ul>
    );

    const getScoreColor = (score: number) => {
        if (score >= 80) {
            return 'success';
        }

        if (score >= 50) {
            return 'warning';
        }

        return 'error';
    };

    const renderScore = () => (
        <div>
            <div className={'rating-score ' + (badge ? 'badge' : '')}>
                <span className={getScoreColor(rating ?? 0)}>
                    {rating ?? 0}
                </span>
            </div>
            <span className="rating-total">/{total}</span>
        </div>
    );

    const renderInfoText = (showTooltip: boolean) => {
        const content = (
            <div className="rating-engagement-container">
                <span className="rating-engagement-label">Company Rating</span>
                {!badge && (
                    <div className="rating-help-icon">
                        <Icon icon="info" />
                    </div>
                )}
            </div>
        );

        return showTooltip ? (
            <Popover2
                placement={PopoverPosition.TOP}
                interactionKind="hover"
                hasBackdrop={false}
                content={
                    <div className="engagement-tooltip">
                        {ratingTooltipText}
                    </div>
                }
            >
                {content}
            </Popover2>
        ) : (
            content
        );
    };

    const content = (
        <div
            className={
                'rating-badge-container' +
                (large ? ' large' : '') +
                (badge ? ' badge' : '')
            }
        >
            {renderScore()}
            {!badge && (
                <img
                    className="rating-prosure-badge"
                    src="/assets/images/rating-logo.svg"
                    alt="prosure-rating-badge"
                />
            )}
            {renderInfoText(!badge)}
        </div>
    );

    if (badge) {
        return (
            <Popover2
                className="inline-block"
                placement={PopoverPosition.TOP}
                interactionKind="hover"
                hasBackdrop={false}
                onClose={(e) => e.preventDefault()}
                content={
                    <div className="engagement-tooltip">
                        {ratingTooltipText}
                    </div>
                }
            >
                {content}
            </Popover2>
        );
    }

    return content;
};

export default CompanyRating;
