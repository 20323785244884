import Icon from './Icon';
import { Tooltip2 } from '@blueprintjs/popover2';

const TooltipIcon = (props) => {
    const { text, icon, callback, wrappingClass } = props;

    const onClickHandler = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (callback) {
            callback(event);
        }
    };

    return (
        <Tooltip2
            className={wrappingClass}
            content={text}
            popoverClassName="the-tooltip"
            key="tooltip-child"
        >
            <Icon
                className="cursor-as-clickable tooltip-icon"
                icon={icon}
                tabIndex="-1"
                onClick={onClickHandler}
            />
        </Tooltip2>
    );
};

export default TooltipIcon;
