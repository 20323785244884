import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import React from 'react';
import { H2 } from '@blueprintjs/core';

import { UserData } from 'interface/User';
import { ButtonLink, Icon } from 'components/elements';

interface ProfileDetailsProps extends RouteComponentProps<any> {
    user: UserData;
}

const ProfileDetails = (props: ProfileDetailsProps) => {
    const {user} = props;

    return (
        <div className="ProfileDetails">
            <H2>
                Details
                <div className="float-right">
                    <ButtonLink
                        type="button"
                        to={`/profile/details/edit`}
                        intent="primary"
                        className="me-3"
                    >
                        <Icon icon="lock-open"/>
                        Edit Details
                    </ButtonLink>
                </div>
            </H2>
            <dl className="horizontal">
                <dt>Forename</dt>
                <dd>{user.forename}</dd>
                <dt>Surname</dt>
                <dd>{user.surname}</dd>
                <dt>Job Title</dt>
                <dd>{user.jobTitle}</dd>
                <dt>Phone Number</dt>
                <dd>{user.phone}</dd>
                <dt>Email Address</dt>
                <dd>{user.email}</dd>
                <dt>Password</dt>
                <dd>
                    <Link to={`/profile/change-password`}>Change Password</Link>
                </dd>
            </dl>
        </div>
    );
};

export default ProfileDetails;
