import { H1 } from '@blueprintjs/core';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import {
    ButtonLink,
    ItemCount,
    Paginator,
    Reload,
    Table,
} from 'components/elements';
import { Loading, NoResultsFound } from 'components/elements/wrappers';
import { SUBSCRIPTION_STATUSES } from 'constants/client';
import { getColourClass } from 'helpers/helpers';
import { HTTP, Response } from 'service';

const SubscriptionList = (props) => {
    const { clientResponse, match } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [subscriptionData, setSubscriptionData] = useState({
        data: [],
    });

    const parsedUrlParams = Object.fromEntries(
        new URLSearchParams(props.location.search)
    );

    const getRoute = (row) => {
        return `/clients/list/${clientResponse.id}/subscriptions/${row.id}`;
    };

    const columns = [
        {
            name: 'Valid From',
            type: 'date',
            property: 'validFrom',
            sortable: false,
            grow: 1,
        },
        {
            name: 'Status',
            type: 'mapping',
            property: 'status',
            sortable: false,
            colourClass: (row) => getColourClass(row),
            mapping: SUBSCRIPTION_STATUSES,
            grow: 1,
        },
        {
            name: 'Valid Until',
            type: 'date',
            property: 'validUntil',
            sortable: false,
            grow: 1,
        },
        {
            name: 'Invoice Number',
            property: 'invoiceId',
            type: 'text',
            sortable: false,
            grow: 1,
        },
        {
            name: 'Chargeable',
            property: 'chargeable',
            type: 'yesNo',
            sortable: false,
            grow: 1,
        },
        {
            name: 'Invoice Amount Ex Tax',
            property: 'invoiceAmountExcludingTax',
            type: 'currency',
            sortable: false,
            grow: 2,
        },
        {
            name: 'Invoice Date',
            property: 'invoiceDate',
            type: 'date',
            sortable: false,
            grow: 1,
        },
        {
            name: 'Payment Date',
            property: 'paymentDate',
            type: 'date',
            sortable: false,
            grow: 1,
        },
        {
            name: 'Payment ID',
            property: 'paymentId',
            type: 'text',
            sortable: false,
            grow: 1,
        },
        {
            name: 'Level',
            property: 'level',
            type: 'text',
            sortable: false,
            grow: 1,
        },
        {
            name: 'Contract',
            type: 'callback',
            callback: (row) => {
                const value = Response.getLink(row, 'contract');

                return <>{value ? 'Yes' : 'No'}</>;
            },
            sortable: false,
            grow: 2,
        },
        {
            name: '',
            property: '',
            type: 'nav-button',
            grow: 1,
            route: getRoute,
            buttonIntent: 'primary',
            buttonText: 'View',
            right: true,
        },
    ];

    const defaultFilters = {
        order: '-validUntil',
    };

    useEffect(() => {
        if (isLoading === false) {
            load();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const load = (params = {}) => {
        if (isLoading === true) {
            setIsLoading(false);
            return;
        }

        setIsLoading(true);

        const getUrl = Response.getLink(clientResponse, 'subscriptions');
        const filterParams = {
            ...defaultFilters,
            ...props.filter,
            ...parsedUrlParams,
            ...params,
        };

        // update url
        let queryStringFilterParams = new URLSearchParams(
            filterParams
        ).toString();
        if (
            props.location.search &&
            '?' + queryStringFilterParams !== props.location.search
        ) {
            props.history.push({ search: queryStringFilterParams });
        }

        HTTP.get(getUrl, filterParams).then(
            (response) => {
                setIsLoading(false);
                setSubscriptionData({
                    ...subscriptionData,
                    data: response.data.subscriptions,
                    page: response.data.page,
                    count: response.data.count,
                    limit: response.data.limit,
                });

                return true;
            },
            (response) => {
                setIsLoading(false);
                toast.error('Unable to fetch a list of Prosure Subscriptions');
                return false;
            }
        );
    };

    return (
        <>
            <H1 className="clearfix">
                Prosure Subscriptions
                <ItemCount count={subscriptionData.count} />
                <Reload load={load} />
                {Response.getLink(clientResponse, 'add-subscription') && (
                    <ButtonLink
                        type="button"
                        to={`${match.url}/add`}
                        intent="primary"
                        className="float-right"
                    >
                        Add Subscription
                    </ButtonLink>
                )}
            </H1>
            <Loading isLoading={isLoading}>
                <NoResultsFound count={subscriptionData.data.length}>
                    <Table
                        data={subscriptionData.data}
                        columns={columns}
                    />
                    <Paginator
                        page={subscriptionData.page}
                        count={subscriptionData.count}
                        limit={subscriptionData.limit}
                        onPage={load}
                    />
                </NoResultsFound>
            </Loading>
        </>
    );
};

export default SubscriptionList;
