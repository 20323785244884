interface FooterProps {
    fixed?: boolean;
};

const MarloweFooter = (props: FooterProps) => {
    const {
        fixed = true
    } = props;

    return (
        <div className={`marlowe-footer ${fixed ? 'fixed' : ''}`}>
            <div className="wm-logo-wrap">
                <img
                    className="wm-logo"
                    src="/assets/images/wm-logo.svg"
                    alt="William Martin Compliance"
                    title="William Martin Compliance"
                />
            </div>
            <div className="links-wrap">
                <div>
                    <a
                        href="https://prosure360.co.uk/privacy_policy"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Privacy Policy
                    </a>
                    <br />
                    <span>
                        <a href="https://www.marlowe-src.com/">SRC</a>{' '}
                        is home to{' '}
                        <a href="https://wmcompliance.co.uk/">William Martin</a>
                        , <a href="https://www.elogs.co.uk/">Elogs</a> and{' '}
                        <a href="https://barbour-ehs.com/">Barbour EHS</a>
                    </span>
                </div>
            </div>
            <div className="cert-wrap">&nbsp;</div>
        </div>
    );
};

export default MarloweFooter;
