import { Button, FormGroup, TextArea } from "@blueprintjs/core";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";

import { HTTP, Response, StateHandler, InstanceService } from "service";
import { HTMLSelect } from "components/elements";
import { COMMENT_POLARITY_NEUTRAL, COMMENT_POLARITY_OPTIONS, COMMENT_TYPE_INTERNAL, COMMENT_TYPE_OPTIONS } from "constants/comments";
import { translate } from "service/Translator";
import "./index.scss";

const Comments = (props) => {
    const { assessmentTypeInstance } = props;
    const [commentsData, setCommentsData] = useState([]);
    const [form, setForm] = useState({
        type: COMMENT_TYPE_INTERNAL,
        polarity: COMMENT_POLARITY_NEUTRAL,
        comment: '',
    });

    useEffect(() => {
        getComments();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getComments = async () => {
        const comments = await InstanceService.getComments(assessmentTypeInstance);
        setCommentsData(comments);
    }

    const commentText = (comment) => {
        return (
            <div className="comment">
                <h3>
                    { translate(comment.type, COMMENT_TYPE_OPTIONS) } &nbsp;-&nbsp;
                    { moment(comment.createdAt).format('MMMM Do YYYY - HH:mm') } &nbsp;-&nbsp;&nbsp;
                    { comment.createdBy }
                </h3>
                <p>{ comment.comment }</p>
            </div>
        );
    }

    const handleSubmit = async (event) => {
        event.stopPropagation();
        event.preventDefault();

        HTTP
            .post(Response.getLink(assessmentTypeInstance, 'add-comments'), form)
            .then((response) => {
                if (response) {
                    toast.success('Comment added');
                    // reload comments list
                    getComments();

                    // reset form
                    setForm({
                        type: COMMENT_TYPE_INTERNAL,
                        polarity: COMMENT_POLARITY_NEUTRAL,
                        comment: '',
                    });

                    return true;
                }

                toast.error('Unable to add comment');

                return false;
            });
    }

    const commentForm = () => {
        if (!Response.getLink(assessmentTypeInstance, 'add-comments')) {
            return null;
        }

        return (
            <div className="commentForm pt-3">
                <h2 className="pb-3">Add a new comment</h2>

                <form className="AddComment" onSubmit={ event => handleSubmit(event) }>
                    <FormGroup label="Internal / General"
                        inline={true}
                        className="form-fill required"
                        labelFor="type">
                        <HTMLSelect id="type"
                            fill={true}
                            value={ form.type }
                            onChange={ event => setForm(StateHandler.getStateObject(event, form)) }
                            options={ COMMENT_TYPE_OPTIONS } />
                    </FormGroup>

                    <FormGroup label="Polarity"
                        inline={true}
                        className="form-fill required"
                        labelFor="polarity">
                        <HTMLSelect id="polarity"
                            fill={true}
                            value={ form.polarity }
                            onChange={ event => setForm(StateHandler.getStateObject(event, form)) }
                            options={ COMMENT_POLARITY_OPTIONS } />
                    </FormGroup>

                    <FormGroup label="Comment"
                        inline={true}
                        className="form-fill required"
                        labelFor="comment">
                        <TextArea
                            id="comment"
                            placeholder="Comment"
                            onChange={ event => setForm(StateHandler.getStateObject(event, form)) }
                            minLength={1}
                            required
                            value={ form.comment }
                        />
                    </FormGroup>

                    <FormGroup>
                        <Button type="submit"
                            intent="primary"
                            className="float-right">
                            Create
                        </Button>
                    </FormGroup>
                </form>
            </div>
        );
    }

    return <>
        {
            !commentsData.length ? 'No comments' :
                <div className="assessment-comments">
                    {
                        commentsData.map((comment, index) => <div key={ 'comment' + index }>{ commentText(comment) }</div>)
                    }
                </div>
        }
        { commentForm() }
    </>
};

export default Comments;
