import { Accordion, MultiSwitchToggle } from 'components/elements';

const RedFlagFilter = (props: any) => {
    const redFlagOptions = [
        {
            id: 'GOLD',
            key: 'redFlagStatus',
            value: props.initialValues?.includes('GOLD') ?? false,
            label: 'Gold',
        },
        {
            id: 'SILVER',
            key: 'redFlagStatus',
            value: props.initialValues?.includes('SILVER') ?? false,
            label: 'Silver',
        },
        {
            id: 'BRONZE',
            key: 'redFlagStatus',
            value: props.initialValues?.includes('BRONZE') ?? false,
            label: 'Bronze',
        },
        {
            id: 'ONE_RED_FLAG',
            key: 'redFlagStatus',
            value: props.initialValues?.includes('ONE_RED_FLAG') ?? false,
            label: '1 Red Flag',
        },
        {
            id: 'TWO_RED_FLAGS',
            key: 'redFlagStatus',
            value: props.initialValues?.includes('TWO_RED_FLAGS') ?? false,
            label: '2 Red Flags',
        },
        {
            id: 'THREE_RED_FLAGS',
            key: 'redFlagStatus',
            value: props.initialValues?.includes('THREE_RED_FLAGS') ?? false,
            label: '3 Red Flags',
        },
        {
            id: 'NEWLY_INCORPORATED',
            key: 'redFlagStatus',
            value: props.initialValues?.includes('NEWLY_INCORPORATED') ?? false,
            label: 'Newly Incorporated',
        },
        {
            id: 'PRE_INSOLVENT',
            key: 'redFlagStatus',
            value: props.initialValues?.includes('PRE_INSOLVENT') ?? false,
            label: 'Pre-Insolvent',
        },
        {
            id: 'PROVISIONAL_BRONZE',
            key: 'redFlagStatus',
            value: props.initialValues?.includes('PROVISIONAL_BRONZE') ?? false,
            label: 'Provisional Bronze',
        },
        {
            id: 'PROVISIONAL_SILVER',
            key: 'redFlagStatus',
            value: props.initialValues?.includes('PROVISIONAL_SILVER') ?? false,
            label: 'Provisional Silver',
        },
        {
            id: 'PROVISIONAL_ONE_RED_FLAG',
            key: 'redFlagStatus',
            value:
                props.initialValues?.includes('PROVISIONAL_ONE_RED_FLAG') ??
                false,
            label: 'Provisional 1 Red Flag',
        },
        {
            id: 'PROVISIONAL_TWO_RED_FLAGS',
            key: 'redFlagStatus',
            value:
                props.initialValues?.includes('PROVISIONAL_TWO_RED_FLAGS') ??
                false,
            label: 'Provisional 2 Red Flags',
        },
    ];

    return (
        <Accordion title="RED FLAG STATUS">
            <MultiSwitchToggle
                options={redFlagOptions}
                onUpdate={(options: Array<any>) => props.onUpdate(options)}
                clear={props.clear}
            />
        </Accordion>
    );
};

export default RedFlagFilter;
