
const routes = {
    admin_discounts: {
        href: '/admin/discounts',
        text: 'Discounts',
    },
    admin_discounts_add: {
        href: '/admin/discounts/add',
        text: 'Add new Discount'
    },
    admin_discounts_details: {
        href: '/admin/discounts/:id',
        text: '{discount.code}',
        skipNavigation: true,
    },
    admin_discounts_details_summary: {
        href: '/admin/discounts/:id/summary',
        text: 'Summary',
    },
    admin_discounts_edit: {
        href: '/admin/discounts/:id/summary/edit',
        text: 'Edit'
    }
}

export default routes;
