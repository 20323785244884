import { useEffect, useState } from 'react';
import { Switch } from 'react-router-dom';

import SubscriptionAddEdit from 'components/clients/subscriptions/SubscriptionAddEdit';
import SubscriptionInfo from 'components/clients/subscriptions/SubscriptionInfo';
import { HTTP, Response } from 'service';
import { Loading } from 'components/elements/wrappers';
import { ProtectedRoute } from 'components/elements';

const SubscriptionDetails = (props) => {
    const { clientResponse, match } = props;
    const subscriptionId = match.params.id;
    const [subscriptionDetails, setSubscriptionDetails] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        loadSubscriptionData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadSubscriptionData = async () => {
        setIsLoading(true);

        const collectionLink = Response.getLink(
            clientResponse,
            'subscriptions'
        );

        await HTTP.get(`${collectionLink}/${subscriptionId}`, {
            datagroup: 'details',
        }).then((response) => {
            setSubscriptionDetails(response.data);
        });

        setIsLoading(false);
    };

    return (
        <>
            <div className="subscriptionDetails">
                <Loading isLoading={isLoading || subscriptionDetails === false}>
                    <Switch>
                        <ProtectedRoute
                            path={`${match.url}`}
                            component={SubscriptionInfo}
                            clientResponse={clientResponse}
                            reloadData={loadSubscriptionData}
                            subscriptionDetails={subscriptionDetails}
                            exact
                        />
                        <ProtectedRoute
                            path={`${match.url}/edit`}
                            component={SubscriptionAddEdit}
                            reloadData={loadSubscriptionData}
                            clientResponse={clientResponse}
                            subscriptionDetails={subscriptionDetails}
                            exact
                        />
                    </Switch>
                </Loading>
            </div>
        </>
    );
};

export default SubscriptionDetails;
