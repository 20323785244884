import { Card, H1 } from '@blueprintjs/core';
import { useEffect, useState } from 'react';

import { ItemCount, Paginator, Reload } from 'components/elements';
import { Loading } from 'components/elements/wrappers';
import { parseDateFormat } from 'helpers/helpers';
import { ListCollection, ListFilter } from 'interface';
import { CampaignComponentProps } from 'interface/Client/Campaign';
import { Response } from 'service';
import CampaignService from 'service/Client/CampaignService';

const CampaignHistory = (props: CampaignComponentProps) => {
    const { campaign } = props;
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [collection, setCollection] = useState<ListCollection<any>>({
        _links: [],
        count: 0,
        data: [],
        limit: 0,
        page: 0,
        pages: 0,
    });
    const listFilter: ListFilter = { order: '-createdAt' } as ListFilter;

    const load = async (filter?: ListFilter) => {
        setIsLoading(true);
        setCollection(
            await CampaignService.loadEvents(
                Response.getLink(campaign, 'event-history'),
                filter
            )
        );
        setIsLoading(false);
    };

    useEffect(() => {
        (async () => {
            if (isLoading && Response.getLink(campaign, 'event-history')) {
                await load(listFilter);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getEventType = (event: any) => {
        switch (event.type) {
            case 'campaign_updated_event':
                return 'Campaign Updated';
            case 'campaign_multiple_emails_sent_event':
                return 'Campaign Multiple Emails Sent';
            case 'campaign_invitee_template_upload_success_event':
                return 'Campaign Invitees Successfully Uploaded';
            case 'campaign_invitee_template_upload_failure_event':
                return 'Campaign Invitees Upload Failed';
            default:
                return 'Campaign Created';
        }
    };

    const mappedEvents = () => {
        return collection.data.map((event: any) => {
            let eventUser = 'System user';
            let createdAt = parseDateFormat(event.createdAt);
            let metaData = null;

            if (
                event.type === 'campaign_invitee_template_upload_success_event'
            ) {
                metaData = (
                    <>
                        <dt>Information:</dt>
                        <dd>{`Invitee template upload succeeded with ${event.metaData?.rows} invitees added.`}</dd>
                    </>
                );
            } else if (
                event.type === 'campaign_invitee_template_upload_success_event'
            ) {
                metaData = (
                    <>
                        <dt>Information:</dt>
                        <dd>{`Invitee template upload failed with ${event.metaData?.errors} errors.`}</dd>
                    </>
                );
            } else if (
                event.metaData &&
                Object.keys(event.metaData).length > 0
            ) {
                let metaDataFields: any[] = [];
                Object.keys(event.metaData).forEach((key: string) => {
                    if (
                        event.metaData[key].hasOwnProperty('from') &&
                        typeof event.metaData[key].from !== 'object'
                    ) {
                        metaDataFields.push(
                            <div key={key}>
                                <span>{key}</span> changed from{' '}
                                <span>{event.metaData[key].from}</span>, to{' '}
                                <span>{event.metaData[key].to}</span>
                            </div>
                        );
                    } else if (key === 'emails') {
                        metaDataFields.push(
                            <div key={key}>
                                <span>Emails:</span>
                                {Object.keys(event.metaData[key]).map(
                                    (id: any) => (
                                        <div key={id} className="ps-4">
                                            <span>
                                                {event.metaData[key][id]}
                                            </span>
                                        </div>
                                    )
                                )}
                            </div>
                        );
                    } else if (key === 'isSendAll') {
                        metaDataFields.push(
                            <div key={key}>
                                <span>sent to all Invitees ? : </span>
                                <span>
                                    {event.metaData[key] ? 'yes' : 'no'}
                                </span>
                            </div>
                        );
                    } else if (key === 'context') {
                        const invitee = event.metaData[key]['invitee'];
                        metaDataFields.push(
                            <div key={key}>
                                <span>Sent to : </span>
                                <div className="ms-4">
                                    {invitee['email']}
                                    <br />
                                    {invitee['name']},
                                    <br />
                                    {invitee['forename']}&nbsp;
                                    {invitee['surname']}
                                </div>
                            </div>
                        );
                    }
                });

                metaData = (
                    <div>
                        <dt>Fields:</dt>
                        <dd className="historyFields"> {metaDataFields} </dd>
                    </div>
                );
            }

            if (event._links) {
                eventUser = event._links.user.username;
            }

            return (
                <Card key={event.id}>
                    <div>
                        <dt>Event Type:</dt>
                        <dd>{getEventType(event)}</dd>
                    </div>
                    <div>
                        <dt>Raised by:</dt>
                        <dd>{eventUser}</dd>
                    </div>
                    <div>
                        <dt>Raised On:</dt>
                        <dd>{createdAt}</dd>
                    </div>
                    {metaData}
                </Card>
            );
        });
    };

    return (
        <div className="CampaignHistory">
            <H1 className="clearfix">
                History
                <ItemCount count={collection.count} />
                <Reload load={load} />
            </H1>

            <Loading isLoading={isLoading}>
                <div className="events">{mappedEvents()}</div>

                <Paginator
                    page={collection.page}
                    count={collection.count}
                    limit={collection.limit}
                    onPage={load}
                />
            </Loading>
        </div>
    );
};

export default CampaignHistory;
