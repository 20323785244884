import { Button, Classes, Dialog } from '@blueprintjs/core';
import { SyntheticEvent, useEffect, useReducer } from 'react';

import type { AxiosResponse } from 'axios';
import { FileUpload } from 'components/elements';
import type { Campaign } from 'interface/Client/Campaign';
import { RouteComponentProps, withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { CampaignService, Response } from 'service';

interface UploadTemplateProps extends RouteComponentProps {
    isOpen: boolean;
    onClose: Function;
    onSubmit?: Function;
    campaign: Campaign;
}

const UploadTemplate = (props: UploadTemplateProps) => {
    const { isOpen, onClose, onSubmit, campaign, history } = props;

    const defaultState = {
        dialogOpen: !!isOpen,
        isLoading: false,
        error: undefined,
        form: {
            file: null,
            _links: {},
        },
    };

    const [state, setState] = useReducer((state: any, edits: any) => {
        return { ...state, ...edits };
    }, defaultState);

    useEffect(() => {
        setState({ dialogOpen: isOpen });
    }, [isOpen]);

    const dialogClose = (event: SyntheticEvent) => {
        setState({ ...defaultState, dialogOpen: false });
        typeof onClose === 'function' && onClose(event);
    };

    const onUpdate = () => {
        setState({ form: defaultState.form });
    };

    const onFileError = (message: string) => {
        setState({ error: message });
    };

    const onUpload = (response: AxiosResponse) => {
        const file = {
            href: Response.getLink(response.data, 'self'),
            title: response.data.title,
            download: Response.getLink(response.data, 'download'),
        };
        setState({ form: { file: response.data, _links: { file } } });
    };

    const submit = async (event: SyntheticEvent) => {
        event.stopPropagation();
        event.preventDefault();

        if (!state.form.file) {
            setState({ error: 'Please select a file for processing' });

            return;
        }

        setState({ isLoading: true });
        const response = await CampaignService.processInviteeUploadTemplate(
            campaign.id,
            state.form,
            onFail
        );
        if (response) {
            toast.success('Template processed successfully');
            typeof onSubmit === 'function' && onSubmit();
            dialogClose(event);
            setState({ isLoading: false });
        }
    };

    const onFail = (errors: any) => {
        toast.warn('Errors found. Redirecting to error page');
        typeof onSubmit === 'function' && onSubmit(errors);
        setState({ ...defaultState, isOpen: false });
        history.push(
            `/clients/campaigns/list/${campaign.id}/invitees/upload-errors`
        );
    };

    return (
        <Dialog
            className="email-invitee"
            onClose={dialogClose}
            title={`Upload Campaign Template`}
            autoFocus={true}
            canEscapeKeyClose={false}
            canOutsideClickClose={false}
            isCloseButtonShown={false}
            usePortal={true}
            isOpen={state.dialogOpen}
        >
            <div className={Classes.DIALOG_BODY}>
                <h3>
                    Please upload the .xsls template to import campaign data
                </h3>
                <FileUpload
                    inputID="file"
                    accept=".xlsx"
                    isEditable={true}
                    onUpdate={onUpdate}
                    onUpload={onUpload}
                    response={state.form}
                    responseLink="file"
                    uploadDataObject={{}}
                    uploadUrl="/files/new-xlsx"
                    updateFileError={onFileError}
                />
                {state.error && (
                    <div className="bp3-card error mt-4">{state.error}</div>
                )}
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className="dialog-footer-buttons">
                    <Button onClick={dialogClose}>Close</Button>
                    <Button
                        intent="success"
                        disabled={state.isLoading}
                        onClick={submit}
                    >
                        Process campaign data template
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default withRouter(UploadTemplate);
