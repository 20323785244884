import {
    Callout,
    FormGroup,
    InputGroup,
    NumericInput,
    Radio,
    RadioGroup,
    TextArea,
} from '@blueprintjs/core';
import { DateInput } from '@blueprintjs/datetime';
import { DateTime } from 'luxon';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { isMobile } from 'react-device-detect';

import {
    AdditionalComment,
    FileUploadMulti,
    Icon,
    InputElementIcon,
} from 'components/elements';
import { Loading } from 'components/elements/wrappers';
import { MAX_FILE_UPLOAD_SIZE_MB } from 'constants/general';
import { Response, Utility } from 'service';
import './assessment-questions.scss';

class Question extends Component {
    static propTypes = {
        answerIndex: PropTypes.number,
        answerResponse: PropTypes.object,
        companyResponse: PropTypes.object,
        questionResponse: PropTypes.object.isRequired,
        isEditable: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        isEditable: false,
        isUploading: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            answer: '',
        };

        this.getQuestionInstanceProperties =
            this.getQuestionInstanceProperties.bind(this);
        this.getRenderedQuestion = this.getRenderedQuestion.bind(this);
        this.getRenderedQuestionTypeText =
            this.getRenderedQuestionTypeText.bind(this);
        this.getRenderedQuestionTypeNumber =
            this.getRenderedQuestionTypeNumber.bind(this);
        this.getRenderedQuestionTypeFile =
            this.getRenderedQuestionTypeFile.bind(this);
        this.getRenderedQuestionTypeDatepicker =
            this.getRenderedQuestionTypeDatepicker.bind(this);
        this.getRenderedQuestionTypeBoolean =
            this.getRenderedQuestionTypeBoolean.bind(this);
        this.getRenderedQuestionTypeTrend =
            this.getRenderedQuestionTypeTrend.bind(this);
    }

    render() {
        const { answerResponse, questionResponse } = this.props;
        const isLoading = typeof questionResponse.summary === 'undefined';
        const renderedQuestion = this.getRenderedQuestion();

        return (
            <div className="Question">
                <Loading isLoading={isLoading}>
                    {answerResponse?.review &&
                        answerResponse.review.isApproved === false && (
                            <Callout
                                intent="warning"
                                className="question-review"
                            >
                                Returned Answer
                            </Callout>
                        )}

                    {renderedQuestion}
                </Loading>
            </div>
        );
    }

    getQuestionInstanceProperties() {
        const { questionResponse } = this.props;

        return {
            classNames: this.getQuestionInstanceClassNames(questionResponse),
            id: Utility.generateUuid(),
            label: this.getQuestionInstanceLabel(questionResponse),
        };
    }

    getQuestionInstanceClassNames(questionResponse) {
        const classNames = [
            'form-fill',
            'question-label',
            questionResponse.type,
        ];

        if (questionResponse.isRequired) {
            classNames.push('required');
        }

        return classNames.join(' ');
    }

    getQuestionInstanceLabel(questionResponse) {
        const filehelperText =
            questionResponse.type === 'file'
                ? `Max file size  ${MAX_FILE_UPLOAD_SIZE_MB}MB (per file)`
                : '';
        const guidanceNotes = `${
            questionResponse.guidanceNotes
                ? questionResponse.guidanceNotes + '. '
                : ''
        } ${filehelperText}`;

        return (
            <>
                {questionResponse.summary}
                {InputElementIcon(`${guidanceNotes}`, 'info')}
            </>
        );
    }

    displayCopyText = () => {
        const { answerResponse, mode } = this.props;

        return (
            mode === 'company' &&
            answerResponse.copiedFromText && (
                <div>
                    <p className="answerCopyText">
                        <Icon className="answerCopyIcon" icon="check-circle" />{' '}
                        {answerResponse.copiedFromText}
                    </p>
                </div>
            )
        );
    };

    getRenderedQuestion() {
        const { questionResponse } = this.props;

        switch (questionResponse.type) {
            case 'text':
                return this.getRenderedQuestionTypeText();

            case 'number':
                return this.getRenderedQuestionTypeNumber();

            case 'boolean':
                return this.getRenderedQuestionTypeBoolean();

            case 'choice':
                return this.getRenderedQuestionTypeChoice();

            case 'datepicker':
                return this.getRenderedQuestionTypeDatepicker();

            case 'file':
                return this.getRenderedQuestionTypeFile();

            case 'trend':
                return this.getRenderedQuestionTypeTrend();

            default:
                return <></>;
        }
    }

    updateAnswer = (answer, question, value) => {
        let copiedFromText =
            typeof answer === 'undefined' ||
            (typeof answer.copiedFromText !== 'undefined' &&
                answer.copiedFromText)
                ? null
                : answer.copiedFromText;

        this.props.onUpdate(answer, question, {
            value,
            copiedFromText,
        });
    };

    getRenderedQuestionTypeText() {
        const { answerResponse, isEditable, questionResponse } = this.props;
        const { classNames, label } = this.getQuestionInstanceProperties();

        return (
            <FormGroup
                label={label}
                inline={!isMobile}
                className={
                    classNames +
                    (questionResponse.fileRequirement === 0
                        ? ''
                        : ' multi-inputs')
                }
                labelFor={`text_${questionResponse.id}`}
            >
                {questionResponse.isTextarea || !isEditable ? (
                    <TextArea
                        id={`text_${questionResponse.id}`}
                        name={questionResponse.id}
                        fill={true}
                        minLength={questionResponse.minLength}
                        maxLength={questionResponse.maxLength || null}
                        defaultValue={
                            answerResponse ? answerResponse.value : ''
                        }
                        onChange={(event) =>
                            this.updateAnswer(
                                answerResponse,
                                questionResponse,
                                event.target.value
                            )
                        }
                        disabled={!isEditable}
                    />
                ) : (
                    <InputGroup
                        id={`text_${questionResponse.id}`}
                        name={questionResponse.id}
                        fill={true}
                        minLength={questionResponse.minLength}
                        maxLength={questionResponse.maxLength}
                        defaultValue={
                            answerResponse ? answerResponse.value : ''
                        }
                        onChange={(event) =>
                            this.updateAnswer(
                                answerResponse,
                                questionResponse,
                                event.target.value
                            )
                        }
                        disabled={!isEditable}
                    />
                )}

                <AdditionalComment
                    questionResponse={questionResponse}
                    answerResponse={answerResponse}
                    disabled={!isEditable}
                    onUpdate={(data) => {
                        if (typeof this.props.onUpdate === 'function') {
                            this.props.onUpdate(
                                answerResponse,
                                questionResponse,
                                data
                            );
                        }
                    }}
                />

                {questionResponse.fileRequirement === 0
                    ? null
                    : this.getRenderedQuestionTypeFile(false)}

                {answerResponse && this.displayCopyText()}
            </FormGroup>
        );
    }

    getRenderedQuestionTypeNumber() {
        const { answerResponse, isEditable, questionResponse } = this.props;
        const { classNames, label } = this.getQuestionInstanceProperties();
        const defaultValue = answerResponse ? answerResponse.value : '';

        return (
            <FormGroup
                label={label}
                inline={!isMobile}
                className={classNames}
                labelFor={`number_${questionResponse.id}`}
            >
                <NumericInput
                    id={`number_${questionResponse.id}`}
                    name={questionResponse.id}
                    fill={true}
                    min={questionResponse.minValue}
                    max={questionResponse.maxValue}
                    majorStepSize={null}
                    minorStepSize={null}
                    stepSize={questionResponse.step}
                    value={defaultValue}
                    onValueChange={(numericValue, stringValue) =>
                        this.updateAnswer(
                            answerResponse,
                            questionResponse,
                            stringValue
                        )
                    }
                    disabled={!isEditable}
                />
                <AdditionalComment
                    questionResponse={questionResponse}
                    answerResponse={answerResponse}
                    disabled={!isEditable}
                    onUpdate={(data) => {
                        if (typeof this.props.onUpdate === 'function') {
                            this.props.onUpdate(
                                answerResponse,
                                questionResponse,
                                data
                            );
                        }
                    }}
                />
                {answerResponse && this.displayCopyText()}
            </FormGroup>
        );
    }

    getRenderedQuestionTypeBoolean() {
        const { answerResponse, isEditable, questionResponse } = this.props;
        const { classNames, label } = this.getQuestionInstanceProperties();
        const defaultValue = answerResponse
            ? answerResponse.value.toString()
            : null;

        return (
            <>
                <FormGroup
                    label={label}
                    inline={!isMobile}
                    className={classNames}
                >
                    <RadioGroup
                        fill={true}
                        inline={!isMobile}
                        className={classNames}
                        selectedValue={defaultValue}
                        onChange={(event) =>
                            this.updateAnswer(
                                answerResponse,
                                questionResponse,
                                event.target.value
                            )
                        }
                        disabled={!isEditable}
                    >
                        <Radio
                            label="Yes"
                            name={questionResponse.id}
                            value={'true'}
                        />
                        <Radio
                            label="No"
                            name={questionResponse.id}
                            value={'false'}
                        />
                        <AdditionalComment
                            questionResponse={questionResponse}
                            answerResponse={answerResponse}
                            disabled={!isEditable}
                            onUpdate={(data) => {
                                if (typeof this.props.onUpdate === 'function') {
                                    this.props.onUpdate(
                                        answerResponse,
                                        questionResponse,
                                        data
                                    );
                                }
                            }}
                        />
                        {answerResponse && this.displayCopyText()}
                    </RadioGroup>
                </FormGroup>
            </>
        );
    }

    getRenderedQuestionTypeChoice() {
        const { answerResponse, isEditable, questionResponse } = this.props;
        const { classNames, label } = this.getQuestionInstanceProperties();
        const defaultValue = answerResponse
            ? answerResponse.value.toString()
            : null;

        return (
            <FormGroup label={label} inline={!isMobile} className={classNames}>
                <RadioGroup
                    fill={true}
                    inline={!isMobile}
                    className={classNames}
                    selectedValue={defaultValue}
                    onChange={(event) =>
                        this.updateAnswer(
                            answerResponse,
                            questionResponse,
                            event.target.value
                        )
                    }
                    disabled={!isEditable}
                >
                    {questionResponse.options.map((option) => (
                        <Radio
                            key={option.id}
                            label={option.value}
                            name={questionResponse.id}
                            value={option.value}
                        />
                    ))}
                    {answerResponse && this.displayCopyText()}
                </RadioGroup>
                <AdditionalComment
                    questionResponse={questionResponse}
                    answerResponse={answerResponse}
                    disabled={!isEditable}
                    onUpdate={(data) => {
                        if (typeof this.props.onUpdate === 'function') {
                            this.props.onUpdate(
                                answerResponse,
                                questionResponse,
                                data
                            );
                        }
                    }}
                />
            </FormGroup>
        );
    }

    getRenderedQuestionTypeDatepicker() {
        const { answerResponse, isEditable, questionResponse } = this.props;
        const { classNames, label } = this.getQuestionInstanceProperties();
        const defaultValue = moment.isDate(answerResponse?.value)
            ? new Date(answerResponse.value)
            : null;

        let minDate = undefined;
        if (questionResponse.hasMinDateOffset) {
            minDate = DateTime.local()
                .plus({ days: questionResponse.minDateOffset })
                .toJSDate();
        }

        let maxDate = DateTime.local().plus({ years: 3 }).toJSDate();
        if (questionResponse.hasMaxDateOffset) {
            maxDate = DateTime.local()
                .plus({ days: questionResponse.maxDateOffset })
                .toJSDate();
        }

        return (
            <FormGroup
                label={label}
                inline={!isMobile}
                className={classNames}
                labelFor={`datepicker_${questionResponse.id}`}
            >
                <DateInput
                    id={`datepicker_${questionResponse.id}`}
                    name={questionResponse.id}
                    parseDate={(str) => moment(str, 'DD/MM/YYYY').toDate()}
                    formatDate={(date) =>
                        DateTime.fromJSDate(date).toLocaleString()
                    }
                    maxDate={maxDate}
                    minDate={minDate}
                    popoverProps={{ position: 'bottom', fill: true }}
                    inputProps={{
                        fill: true,
                        rightElement: InputElementIcon(
                            'Select a date...',
                            'calendar-alt'
                        ),
                    }}
                    onChange={(newDate) => {
                        newDate = new Date(+newDate + 1000);
                        this.updateAnswer(
                            answerResponse,
                            questionResponse,
                            newDate
                        );
                    }}
                    value={defaultValue}
                    disabled={!isEditable}
                />
                <AdditionalComment
                    questionResponse={questionResponse}
                    answerResponse={answerResponse}
                    disabled={!isEditable}
                    onUpdate={(data) => {
                        if (typeof this.props.onUpdate === 'function') {
                            this.props.onUpdate(
                                answerResponse,
                                questionResponse,
                                data
                            );
                        }
                    }}
                />
                {answerResponse && this.displayCopyText()}
            </FormGroup>
        );
    }

    getRenderedQuestionTypeFile(displayLabel = true) {
        const { answerResponse, isEditable, isFileEditable, questionResponse } =
            this.props;
        const { classNames, label } = this.getQuestionInstanceProperties();

        const isInputEditable = ['undefined', 'null'].includes(
            typeof isFileEditable
        )
            ? isEditable
            : isFileEditable;

        return (
            <FormGroup
                label={displayLabel ? label : null}
                inline={!isMobile}
                className={
                    classNames +
                    (questionResponse.expiryRequirement === 0 &&
                    questionResponse.numberRequirement === 0
                        ? ''
                        : ' multi-inputs')
                }
                labelFor={`file_${questionResponse.id}`}
            >
                <FileUploadMulti
                    inputID={`file_${questionResponse.id}`}
                    name={questionResponse.id}
                    isEditable={isInputEditable}
                    onUpdate={(data, init) => {
                        // remove copy from text, if files change, except on initialisation
                        this.props.onUpdate(answerResponse, questionResponse, {
                            ...data,
                            copiedFromText:
                                (typeof answerResponse === 'undefined' ||
                                    answerResponse.copiedFromText) &&
                                init !== true
                                    ? null
                                    : answerResponse.copiedFromText,
                        });
                    }}
                    question={questionResponse}
                    answer={answerResponse || {}}
                    responseLink="files"
                    uploadDataObject={{
                        _links: {
                            answer: {
                                href: Response.getLink(
                                    this.props.answerResponse,
                                    'self'
                                ),
                            },
                            company: {
                                href: Response.getLink(
                                    this.props.companyResponse,
                                    'self'
                                ),
                            },
                            question: {
                                href: Response.getLink(
                                    this.props.questionResponse,
                                    'self'
                                ),
                            },
                        },
                    }}
                />

                <AdditionalComment
                    questionResponse={questionResponse}
                    answerResponse={answerResponse}
                    disabled={!isEditable}
                    onUpdate={(data) => {
                        if (typeof this.props.onUpdate === 'function') {
                            this.props.onUpdate(
                                answerResponse,
                                questionResponse,
                                data
                            );
                        }
                    }}
                />
                {answerResponse && displayLabel && this.displayCopyText()}
            </FormGroup>
        );
    }

    getRenderedQuestionTypeTrend() {
        const { answerResponse, isEditable, questionResponse } = this.props;
        const { classNames, label } = this.getQuestionInstanceProperties();
        const defaultValue = answerResponse ? answerResponse.value : {};

        return (
            <FormGroup label={label} inline={!isMobile} className={classNames}>
                <table>
                    <tbody>
                        <tr>
                            <td>Year Before Last</td>
                            <td>Last Year</td>
                            <td>YTD</td>
                        </tr>
                        <tr>
                            <td>
                                <InputGroup
                                    id={`text_${questionResponse.id}_year_before_last`}
                                    type="number"
                                    name={
                                        questionResponse.id +
                                        '[year-before-last]'
                                    }
                                    fill={true}
                                    defaultValue={defaultValue.yearBeforeLast}
                                    onChange={(event) =>
                                        this.updateAnswer(
                                            answerResponse,
                                            questionResponse,
                                            {
                                                yearBeforeLast:
                                                    event.target.value,
                                            }
                                        )
                                    }
                                    disabled={!isEditable}
                                />
                            </td>
                            <td>
                                <InputGroup
                                    id={`text_${questionResponse.id}_last_year`}
                                    type="number"
                                    name={questionResponse.id + '[last-year]'}
                                    fill={true}
                                    defaultValue={defaultValue.lastYear}
                                    onChange={(event) =>
                                        this.updateAnswer(
                                            answerResponse,
                                            questionResponse,
                                            {
                                                lastYear: event.target.value,
                                            }
                                        )
                                    }
                                    disabled={!isEditable}
                                />
                            </td>
                            <td>
                                <InputGroup
                                    id={`text_${questionResponse.id}_ytd`}
                                    type="number"
                                    name={questionResponse.id + '[ytd]'}
                                    fill={true}
                                    defaultValue={defaultValue.ytd}
                                    onChange={(event) =>
                                        this.updateAnswer(
                                            answerResponse,
                                            questionResponse,
                                            {
                                                ytd: event.target.value,
                                            }
                                        )
                                    }
                                    disabled={!isEditable}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <AdditionalComment
                    questionResponse={questionResponse}
                    answerResponse={answerResponse}
                    disabled={!isEditable}
                    onUpdate={(data) => {
                        if (typeof this.props.onUpdate === 'function') {
                            this.props.onUpdate(
                                answerResponse,
                                questionResponse,
                                data
                            );
                        }
                    }}
                />
                {answerResponse && this.displayCopyText()}
            </FormGroup>
        );
    }
}

export default Question;
