import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { HTTP } from "../../../service";

const ResendVerificationBanner = (props) =>
{
    const [user, setUser] = useState(undefined);
    const rootResponse = useSelector((state) => state.root.response);

    useEffect(() => {
        setUser(rootResponse ? rootResponse.user : null);
    }, [rootResponse]);

    const resend = async () => {
        const result = await HTTP.put('/resend-verification')
            .then(result => result? result.data : result);
        if (result) {
            toast(result.message, { type: "success" });
            return;
        }
        toast('Could not resend verification. Please check you are already verified by logging out and in again.', { type: "error" });
    }

    if (!user || user.isVerified) {
        return null;
    }

    return  (
        <div className="resend-verification-banner">
            <div className="inner">
                <div>
                    Your account is currently unverified. Click the link in your
                    sign-up email to verify your account. To resend this email,
                    click&nbsp;<button className="anchorButton" onClick={ resend } >here</button>.
                </div>
            </div>
        </div>
    );
};

export default ResendVerificationBanner;
