import { useEffect, useReducer } from 'react';
import { H3 } from '@blueprintjs/core';

import { SupplyChainStatusReducerState } from 'interface';
import { ClientService } from 'service';
import { TooltipIcon, BarChart } from 'components/elements';
import { Loading } from 'components/elements/wrappers';

const SupplyChainInviteStatusChart = (props: any) => {
    const { client, selectedCategory } = props;
    const [supplyChainStatusState, setSupplyChainStatusState]: [
        state: SupplyChainStatusReducerState,
        setState: React.ReducerWithoutAction<any>
    ] = useReducer(
        (state: SupplyChainStatusReducerState, edits: any) => {
            return { ...state, ...edits };
        },
        {
            isVisible: true,
            isLoading: true,
            counts: void 0,
            colors: [
                {
                    name: 'active',
                    label: 'in Supply Chain',
                    colour: '#7bbb4c',
                },
                {
                    name: 'pending',
                    label: 'Invited/Renewals',
                    colour: '#ed5565',
                },
            ],
        }
    );
    const load = async () => {
        setSupplyChainStatusState({ isLoading: true });
        if (client.id === 'all-clients') {
            setSupplyChainStatusState({
                isVisible: false,
                isLoading: false,
            });
        } else {
            setSupplyChainStatusState(
                await ClientService.getSupplyChainStatusState(
                    client,
                    selectedCategory
                )
            );
        }
    };

    useEffect(() => {
        load();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client, selectedCategory]);

    return (
        <>
            {supplyChainStatusState.isVisible && (
                <section className="supply-chain-card info-card chart-parent">
                    <section className="info-card-header">
                        <h2 className="title">
                            <TooltipIcon
                                text={
                                    <section>
                                        Number of service providers in your
                                        supply chain, and the number of service
                                        providers invited to join your supply
                                        chain, that have yet to accept or
                                        decline the invitation.
                                    </section>
                                }
                                icon="info"
                            />
                            <span className="ps-2">
                                In Supply Chain vs Invited/Renewals
                            </span>
                        </h2>
                    </section>
                    <section className="info-card-body flex-justify-center flex-v-center">
                        <Loading isLoading={supplyChainStatusState.isLoading}>
                            {!supplyChainStatusState.counts ? (
                                <H3>
                                    There are currently no Service Providers in
                                    your supply chain
                                </H3>
                            ) : (
                                <BarChart
                                    data={supplyChainStatusState.counts}
                                    height={300}
                                    width={480}
                                    yScale={{ type: 'linear' }}
                                    colours={supplyChainStatusState.colors}
                                />
                            )}
                        </Loading>
                    </section>
                </section>
            )}
        </>
    );
};

export default SupplyChainInviteStatusChart;
