class NumberFormatting
{
    static format(number, precision = 0, locale = 'en-GB')
    {
        return new Intl.NumberFormat(locale, {minimumFractionDigits: precision, maximumFractionDigits: precision}).format(isNaN(+number) ? 0 : number);
    }

    static formatCurrency(amount, currency = 'GBP')
    {
        currency = (typeof currency === 'string' ? currency : 'GBP').toUpperCase();

        return new Intl.NumberFormat(
            NumberFormatting.getLocaleFromCurrency(currency),
            {
                style: 'currency',
                currency: currency,
            }
        ).format(amount);
    }

    static getLocaleFromCurrency(currency = 'GBP')
    {
        currency = (typeof currency === 'string' ? currency : 'GBP').toUpperCase();

        switch (currency) {
            case 'GBP':
            default:
                return 'en-GB';
        }
    }
}

export default NumberFormatting;
