import { Button, FormGroup, H1 } from '@blueprintjs/core';
import { map } from 'lodash';
import { FormEvent, useEffect, useState } from 'react';

import NotificationsSelect from 'components/clients/notifications/NotificationSelect';
import { ButtonLink, Icon } from 'components/elements';
import { CLIENT_ROLE_OPTIONS } from 'constants/role';
import { ClientComponentProps } from 'interface/Client';
import { Option } from 'interfaces';
import { ClientService, Routing } from 'service';
import { translate } from 'service/Translator';

const NotificationsEdit = (props: ClientComponentProps) => {
    const { history, match, clientResponse, reloadData } = props;

    const [inviteeDeclinedRoles, setInviteeDeclinedRoles] = useState<Option[]>([]);

    const [weeklySummaryRoles, setWeeklySummaryRoles] = useState<Option[]>([]);

    const [spJoinedSupplyChainRoles, setSPJoinedSupplyChainRoles] = useState<Option[]>([]);

    const [spLeavingSupplyChainRoles, setSPLeavingSupplyChainRoles] = useState<Option[]>([]);

    const getRoleOptions = (roles: Array<string>) => {
        return map(roles, (role) => ({
            label: translate(role, CLIENT_ROLE_OPTIONS) as string,
            value: role,
        }))
    }

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        const res = await ClientService.updateNotifications(clientResponse, {
            notificationInviteeDeclined: inviteeDeclinedRoles,
            notificationWeeklySummary: weeklySummaryRoles,
            notificationSPJoinedSupplyChain: spJoinedSupplyChainRoles,
            notificationSPLeavingSupplyChain: spLeavingSupplyChainRoles
        });

        if (!res) return;

        reloadData();

        Routing.navigateUpOneLevel(history, match);
    };

    useEffect(() => {
        const { 
            notificationInviteeDeclined,
            notificationWeeklySummary,
            notificationSPJoinedSupplyChain,
            notificationSPLeavingSupplyChain
        } = clientResponse;

        setInviteeDeclinedRoles(getRoleOptions(notificationInviteeDeclined));
        setWeeklySummaryRoles(getRoleOptions(notificationWeeklySummary));
        setSPJoinedSupplyChainRoles(getRoleOptions(notificationSPJoinedSupplyChain));
        setSPLeavingSupplyChainRoles(getRoleOptions(notificationSPLeavingSupplyChain));
    }, [clientResponse]);

    return (
        <div className="supplChainConfigEdit">
            <H1>Edit Client Notification Settings</H1>
            <form
                className="AddEdit"
                onSubmit={(event: FormEvent<HTMLFormElement>) =>
                    handleSubmit(event)
                }
            >
                <NotificationsSelect
                    label="Campaign Invitee Declined to Join"
                    id="declinedToJoinNotification"
                    getRoles={inviteeDeclinedRoles}
                    setRoles={setInviteeDeclinedRoles}
                />

                <NotificationsSelect
                    label={'Client Weekly Summary'}
                    id="weeklySummaryRole"
                    getRoles={weeklySummaryRoles}
                    setRoles={setWeeklySummaryRoles}
                />

                <NotificationsSelect
                    label={'New Service Provider in Supply Chain'}
                    id="spJoinedSupplyChain"
                    getRoles={spJoinedSupplyChainRoles}
                    setRoles={setSPJoinedSupplyChainRoles}
                />

                <NotificationsSelect
                    label={'Service Provider Leaving Supply Chain'}
                    id="spJoinedSupplyChain"
                    getRoles={spLeavingSupplyChainRoles}
                    setRoles={setSPLeavingSupplyChainRoles}
                />

                <FormGroup>
                    <ButtonLink
                        type="button"
                        intent="default"
                        className="float-left"
                        to={Routing.getRouteUpOneLevel(history, match)}
                    >
                        <Icon icon="ban" />
                        Cancel
                    </ButtonLink>
                    <Button
                        type="submit"
                        intent="primary"
                        className="float-right"
                    >
                        <Icon icon="paper-plane" />
                        Submit
                    </Button>
                </FormGroup>
            </form>
        </div>
    );
};

export default NotificationsEdit;
