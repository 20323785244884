import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import List from 'components/elements/List';
import RefundPaymentDialog from 'components/elements/payment/refund/RefundPaymentDialog';
import {
    ASSESSMENT_INSTANCE_STATUS_OPTIONS,
    ASSESSMENT_TYPE_MILESTONES,
} from 'constants/assessmentTypeMilestones';
import { NONE_SELECTED_OPTION } from 'constants/none-selected';
import {
    AssessmentTypeInstanceData,
    AssessmentTypeInstanceListFilter,
    AssessmentTypeInstanceListProps,
    ListSearchFilter,
} from 'interface';
import { AssessmentTypeService, Response } from 'service';

import './index.scss';

interface AssessmentsPurchasedListFilters {
    rootAssessment: ListSearchFilter;
    currentStatus: ListSearchFilter;
}

const AssessmentTypeInstancePurchasedList = (
    props: AssessmentTypeInstanceListProps
) => {
    const { load, collection, filter, isLoading, defaultFilter } = props;
    const [searchFilters, setSearchFilters] =
        useState<AssessmentsPurchasedListFilters>({
            rootAssessment: {
                type: 'single-select',
                defaultValue: NONE_SELECTED_OPTION,
                label: 'Assessment',
                key: 'rootAssessment',
                options: [],
            },
            currentStatus: {
                type: 'single-select',
                defaultValue: NONE_SELECTED_OPTION,
                label: 'Status',
                key: 'currentStatus',
                options: ASSESSMENT_INSTANCE_STATUS_OPTIONS,
            },
        });

    const defaultSpecificFilter: AssessmentTypeInstanceListFilter = {
        ...defaultFilter,
        order: '-purchasedAt',
        status: 'in-progress',
        hideClientAssessments: true,
    };

    const hideRefundButton = (row: AssessmentTypeInstanceData) => {
        return !Response.getLink(row, 'refund');
    };

    const companyRoute = (row: AssessmentTypeInstanceData) => {
        return `/service-providers/${Response.getLinkAttribute(
            row,
            'company',
            'id'
        )}`;
    };

    const companyName = (row: AssessmentTypeInstanceData) => {
        return Response.getLinkAttribute(
            row,
            'company',
            'registeredCompanyName'
        );
    };

    const columns = [
        {
            name: 'ID',
            property: 'assessmentId',
            type: 'string',
            sortable: false,
            grow: 1,
        },
        {
            name: 'Service Provider',
            property: '_links.company.registeredCompanyName',
            type: 'nav-button',
            grow: 2,
            route: companyRoute,
            buttonIntent: 'default',
            buttonClass: 'clear-button',
            buttonText: companyName,
        },
        {
            name: 'Assessment',
            property: '_links.assessment-type.name',
            sortable: false,
            grow: 2,
        },
        {
            name: 'Purchased Date',
            type: 'datetime',
            property: 'purchasedAt',
            sortable: true,
            grow: 2,
        },
        {
            name: 'Status',
            type: 'mapping',
            mapping: ASSESSMENT_TYPE_MILESTONES,
            property: 'status',
            sortable: false,
            grow: 2,
        },
        {
            name: '',
            property: '',
            cellHide: hideRefundButton,
            type: 'callback',
            right: true,
            callback: (row: AssessmentTypeInstanceData) => (
                <RefundPaymentDialog
                    assessmentType={row}
                    reload={() => load({ ...defaultSpecificFilter, ...filter })}
                    onSuccess={() => toast.success('Assessment successfully refunded')}
                />
            ),
            grow: 2,
        },
    ];

    useEffect(() => {
        (async () => {
            const searchFiltersCopy = { ...searchFilters };
            searchFiltersCopy.rootAssessment.options =
                await AssessmentTypeService.getAssessmentTypeListForDropdown();

            setSearchFilters(searchFiltersCopy);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onClear = async () => {
        load(defaultSpecificFilter);
    };

    return (
        <div className="page ServiceProviderDetails">
            <div className="no-styling page-wrapper">
                <div className="AssessmentTypesList">
                    <List
                        title="Purchased Assessments"
                        columns={columns}
                        load={(filter) =>
                            load({ ...defaultSpecificFilter, ...filter })
                        }
                        collection={collection}
                        filter={filter}
                        filters={searchFilters}
                        isLoading={isLoading}
                        defaultFilter={defaultSpecificFilter}
                        onClear={onClear}
                    />
                </div>
            </div>
        </div>
    );
};

export default AssessmentTypeInstancePurchasedList;
