import { List } from 'components/elements';
import { NONE_SELECTED_OPTION } from 'constants/none-selected';
import { delay, parseDateFormat } from 'helpers/helpers';
import { ListCollection } from 'interface';
import {
    CAMPAINGN_UPDATE_TYPE_OPTIONS,
    CampaignUpdate,
    CampaignUpdatesListFilter,
    CampaignUpdatesSearchFilter,
} from 'interface/Client/Campaign';
import { useState } from 'react';
import { withRouter } from 'react-router';
import { ClientService, UserService } from 'service';
import CampaignService from 'service/Client/CampaignService';

const CampaignUpdatesList = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const defaultFilters = {
        order: '-updatedOn',
    };

    const [updates, setUpdates] = useState<ListCollection<CampaignUpdate>>(
        {} as ListCollection<CampaignUpdate>
    );

    const [listFilter, setListFilter] = useState<CampaignUpdatesListFilter>({
        ...defaultFilters,
    } as CampaignUpdatesListFilter);

    const defaultSearchFilters = {
        client: {
            type: 'single-select',
            label: 'Client Name',
            key: 'client',
            defaultValue: NONE_SELECTED_OPTION,
            options: [],
        },
        campaignName: {
            type: 'text',
            label: 'Campaign Name',
            key: 'campaignName',
            placeholder: '',
        },
        updateType: {
            type: 'single-select',
            label: 'Type of Update',
            key: 'updateType',
            defaultValue: NONE_SELECTED_OPTION,
            options: [NONE_SELECTED_OPTION, ...CAMPAINGN_UPDATE_TYPE_OPTIONS],
        },
        updatedBy: {
            type: 'single-select',
            label: 'Updated By',
            key: 'updatedBy',
            defaultValue: NONE_SELECTED_OPTION,
            options: [],
        },
    };

    const columns = [
        {
            name: 'Client Name',
            property: 'clientName',
            sortable: false,
            type: 'callback',
            callback: (row: CampaignUpdate) =>
                row.clientName
                    ? row.clientName
                    : 'All Client Campaigns for company',
            grow: 3,
        },
        {
            name: 'Campaign Name',
            property: 'campaignName',
            sortable: true,
            type: 'callback',
            callback: (row: CampaignUpdate) =>
                row.campaignName
                    ? row.campaignName
                    : 'All Campaigns for company',
            grow: 3,
        },
        {
            name: 'Invitee Name',
            property: 'inviteeName',
            sortable: true,
            type: 'callback',
            callback: (row: CampaignUpdate) =>
                row.inviteeName ? row.inviteeName : 'All Invitees for company',
            grow: 3,
        },
        {
            name: 'Service Provider Name',
            property: 'serviceProviderName',
            sortable: false,
            grow: 3,
            type: 'callback',
            callback: (row: CampaignUpdate) =>
                row.serviceProviderName ? row.serviceProviderName : 'TBC',
        },
        {
            name: 'Type Of Update',
            property: 'updateType',
            sortable: true,
            grow: 3,
        },
        {
            name: 'Date Of Update',
            property: 'updatedOn',
            type: 'callback',
            callback: (row: CampaignUpdate) => parseDateFormat(row.updatedOn),
            sortable: true,
            grow: 3,
        },
        {
            name: 'Updated By',
            property: 'updatedByName',
            sortable: true,
            grow: 3,
        },
        {
            name: 'Update Details',
            property: 'updateDetails',
            sortable: true,
            grow: 3,
        },
    ];

    const [searchFilters, setSearchFilters] =
        useState<CampaignUpdatesSearchFilter>(defaultSearchFilters);

    const load = async (filter: CampaignUpdatesListFilter) => {
        const copySearchFilters = { ...searchFilters };
        copySearchFilters.client.options =
            await ClientService.getClientListForDropdown();

        copySearchFilters.updatedBy.options =
            await UserService.getCampaignUpdatersDropdown();

        setUpdates(await CampaignService.loadUpdatesList(filter));
        setSearchFilters(copySearchFilters);
        setListFilter({ ...defaultFilters, ...filter });
        await delay(0);
        setIsLoading(false);
    };

    return (
        <div className="campaignUpdatesList">
            <List
                key="updates-table"
                title="Campaign Updates"
                columns={columns}
                load={(filter: CampaignUpdatesListFilter) => load(filter)}
                collection={updates}
                filter={listFilter}
                filters={searchFilters}
                defaultFilter={defaultFilters}
                isLoading={isLoading}
                noRecordsFoundText="There are no campaign updates"
            />
        </div>
    );
};

export default withRouter(CampaignUpdatesList);
