import { Card, H2 } from '@blueprintjs/core';
import { Tel } from 'components/elements';
import LogoUpload from 'components/elements/file-upload/LogoUpload';
import { Response } from 'service';
import CompanyRating from './CompanyRating';

import './company-info.scss';

const CompanyInfo = (props: any) => {
    const { data, onLogoUpdate } = props;

    const getLogo = () => {
        let content = (
            <img
                className="logo-wrapper"
                src="/assets/images/company-default.svg"
                onDragStart={(e) => e.preventDefault()}
                alt="No Company logo"
                title="No Company logo"
            />
        );

        if (Response.getLink(data, 'edit')) {
            content = (
                <LogoUpload
                    imageClass="logo-wrapper"
                    onUpdate={onLogoUpdate}
                    defaultLogo="/assets/images/company-default.svg"
                    uploadDataObject={{
                        _links: {
                            company: {
                                href: Response.getLink(data, 'self'),
                            },
                        },
                    }}
                />
            );
        }

        if (data._links.logo) {
            content = (
                <img
                    className="logo-wrapper"
                    alt={data._links.logo.title}
                    title={data._links.logo.title}
                    src={
                        process.env.REACT_APP_BASE_URL +
                        data._links.logo.publicUrl
                    }
                />
            );
        }

        return <div>{content}</div>;
    };

    const rating = Response.getLinkAttribute(data, 'rating', 'rating');
    const allRatings = data._links?.rating;

    return (
        <Card className="company-info-container large">
            <div className="company-info-flex">
                <div className="company-info-left">
                    {getLogo()}
                    <div className="rating-container">
                        <CompanyRating
                            rating={rating}
                            allRatings={allRatings}
                        />
                    </div>
                </div>
                <dl>
                    <H2>Company</H2>
                    <dt>Registered Company Name</dt>
                    <dd>{data.registeredCompanyName}</dd>
                    <dt>Trading Name</dt>
                    <dd>{data.tradingName}</dd>
                    <dt>Status</dt>
                    <dd>{data.isActive ? 'Active' : 'Inactive'}</dd>
                    <dt>Company Bio / Summary</dt>
                    <dd>{data.companyBio}</dd>
                    <dt>Companies House Registration Number</dt>
                    <dd>{data.companiesHouseRegistrationNumber}</dd>
                    <dt>Unique Tax Reference Number</dt>
                    <dd>{data.uniqueTaxReferenceNumber}</dd>
                    <dt>CIS Number</dt>
                    <dd>{data.cisNumber}</dd>
                    <dt>VAT Number</dt>
                    <dd>{data.vatNumber}</dd>
                    <dt>Web Address</dt>
                    <dd>{data.webAddress}</dd>
                    <dt>Phone Number</dt>
                    <dd>
                        <Tel tel={data.phone} />
                    </dd>
                    <dt>Number of Direct Employees</dt>
                    <dd>{data.directEmployees}</dd>
                    <dt>Number of Bona Fide Sub Contractors</dt>
                    <dd>{data.bonaFideSubContractors}</dd>
                    <dt>Number of Labour Only Employees</dt>
                    <dd>{data.labourOnly}</dd>
                    <dt>Number of Effective Employees</dt>
                    <dd>{data.organisationSize}</dd>
                    <dt>Organisation Type</dt>
                    <dd>{data.organisationType}</dd>
                    <dt>Minimum Value of Contracts/Works Undertaken (£)</dt>
                    <dd>{data.minWorkValue ?? '-'}</dd>
                    <dt>Maximum Value of Contracts/Works Undertaken (£)</dt>
                    <dd>{data.maxWorkValue ?? '-'}</dd>
                    <dt>Operate 24 Hours ?</dt>
                    <dd>
                        {data.is24hour === '' || data.is24hour === undefined
                            ? '-'
                            : data.is24hour
                            ? 'Yes'
                            : 'No'}
                    </dd>
                    <dt>To Deliver Your Services Do You Attend Client Sites</dt>
                    <dd>{data.isServiceBySiteVisit ? 'Yes' : 'No'}</dd>
                    <dt>Standard Payment Terms (Days)</dt>
                    <dd>{data.standardPaymentDays ?? '-'}</dd>
                </dl>
            </div>
        </Card>
    );
};

export default CompanyInfo;
