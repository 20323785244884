const routes = {
    login: {
        href: '/login',
    },
    dashboard: {
        href: '/dashboard',
        icon: 'columns',
        text: 'Dashboard',
    },
    profile: {
        href: '/profile',
        text: 'My Profile',
        skipNavigation: true,
    },
    profile_details: {
        href: '/profile/details',
        text: 'Details',
    },
    profile_details_edit: {
        href: '/profile/details/edit',
        text: 'Edit'
    },
    contactUs: {
        href: '/contact-us',
        icon: 'columns',
        text: 'Contact Us',
    },
    benefits: {
        href: '/benefits',
        icon: 'columns',
        text: 'Membership Benefits',
    }
};

export default routes;
