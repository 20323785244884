import { useState, useEffect } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { Button, FormGroup, H1, InputGroup, TextArea } from '@blueprintjs/core';
import { DateInput } from '@blueprintjs/datetime';
import { DateTime } from 'luxon';
import { isMobile } from 'react-device-detect';
import { toast } from 'react-toastify';
import moment from 'moment';

import { ButtonLink, Icon, HTMLSelect } from 'components/elements';
import { HTTP, Response, Routing, StateHandler } from 'service';
import { INSURANCE_TYPE_OPTIONS_NONE_SELECTED } from 'constants/insuranceTypes';
import { InputElementIcon } from 'components/elements';
import { Loading } from 'components/elements/wrappers';
import FileSingleUpload from 'components/elements/file-upload/FileSingleUpload';
import { MAX_FILE_UPLOAD_SIZE_MB } from 'constants/general';

const InsuranceAddEdit = (props) => {
    const { history, match, companyResponse, insuranceDetails } = props;

    const [isLoading, setIsLoading] = useState(true);

    const [form, setForm] = useState({
        insuranceType: '',
        indemnityLimit: null,
        insurer: '',
        policyCommencementDate: null,
        policyExpiryDate: null,
        exclusionsRestrictions: '',
        comments: '',
        _links: {
            certificate: null,
        },
    });

    useEffect(() => {
        if (insuranceDetails) {
            // if edit page, pre-load data and format dates correctly
            let loadedInsuranceDetails = {
                ...insuranceDetails,
                policyCommencementDate: new Date(
                    insuranceDetails.policyCommencementDate
                ),
                policyExpiryDate: new Date(insuranceDetails.policyExpiryDate),
            };

            setForm({
                ...form,
                ...loadedInsuranceDetails,
            });
        }

        setIsLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fileLabel = (
        <>
            Certificate Upload
            {InputElementIcon(
                `Max file size ${MAX_FILE_UPLOAD_SIZE_MB}MB`,
                'info'
            )}
        </>
    );

    const exclusionsRestrictionsLabel = (
        <>
            Exclusions & Restrictions
            {InputElementIcon(
                `Detail any notable exclusions and restrictions of the policy, such as cladding, safety, fire, or the use of sub-contractors/sub-consultants.`,
                'info'
            )}
        </>
    );

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (insuranceDetails) {
            return HTTP.put(
                Response.getLink(insuranceDetails, 'edit'),
                form
            ).then((response) => {
                if (response) {
                    toast.success('Insurance updated');
                    Routing.navigateUpOneLevel(history, match);

                    if (props.reloadData) {
                        props.reloadData(form);
                    }

                    return true;
                }

                toast.error('Unable to update Insurance');

                return false;
            });
        }

        HTTP.post(
            Response.getLink(companyResponse, 'add-insurance'),
            form
        ).then((response) => {
            if (response) {
                toast.success('Insurance added.');
                Routing.navigateUpOneLevel(history, match);

                return true;
            }

            toast.error('Unable to add User to Insurance.');

            return false;
        });
    };

    const handleIndemnityLimitChange = (value) => {
        if (isNaN(value) || value < 1) {
            value = '';
        }

        setForm({
            ...form,
            indemnityLimit: value,
        });
    };

    const updateFile = (fileJson) => {
        setForm({
            ...form,
            ...fileJson,
        });
    };

    let maxDate = DateTime.local().plus({ years: 5 }).toJSDate();

    return (
        <div className="insuranceAdd">
            <H1>{insuranceDetails ? 'Edit Insurance' : 'Add Insurance'}</H1>

            <Loading isLoading={isLoading}>
                <form className="AddEdit" onSubmit={(e) => handleSubmit(e)}>
                    <FormGroup
                        label="Insurance Type"
                        inline={true}
                        className="form-fill required"
                        labelFor="insuranceType"
                    >
                        <HTMLSelect
                            id="insuranceType"
                            fill={true}
                            defaultValue={form.insuranceType}
                            onChange={(e) =>
                                setForm(StateHandler.getStateObject(e, form))
                            }
                            options={INSURANCE_TYPE_OPTIONS_NONE_SELECTED}
                            required
                        />
                    </FormGroup>

                    <FormGroup
                        label="Policy Commencement Date:"
                        inline={!isMobile}
                        className="form-fill choice required"
                    >
                        <DateInput
                            id="policyCommencementDate"
                            name="policyCommencementDate"
                            maxDate={maxDate}
                            parseDate={(str) => moment(str, 'DD/MM/YYYY').toDate()}
                            formatDate={(date) => DateTime.fromJSDate(date).toLocaleString()}
                            popoverProps={{ position: 'bottom', fill: true }}
                            inputProps={{
                                fill: true,
                                id: 'policyCommencementDate',
                                rightElement: InputElementIcon(
                                    'Select a date...',
                                    'calendar-alt'
                                ),
                            }}
                            onChange={(newDate) => {
                                newDate = new Date(+newDate + 1000);
                                setForm({
                                    ...form,
                                    policyCommencementDate: newDate,
                                });
                            }}
                            required={true}
                            value={form.policyCommencementDate}
                        />
                    </FormGroup>

                    <FormGroup
                        label="Policy Expiry Date:"
                        inline={!isMobile}
                        className="form-fill choice required"
                    >
                        <DateInput
                            id="policyExpiryDate"
                            name="policyExpiryDate"
                            maxDate={maxDate}
                            parseDate={(str) => moment(str, 'DD/MM/YYYY').toDate()}
                            formatDate={(date) => DateTime.fromJSDate(date).toLocaleString()}
                            popoverProps={{ position: 'bottom', fill: true }}
                            inputProps={{
                                fill: true,
                                id: 'policyExpiryDate',
                                rightElement: InputElementIcon(
                                    'Select a date...',
                                    'calendar-alt'
                                ),
                            }}
                            onChange={(newDate) => {
                                newDate = new Date(+newDate + 1000);
                                setForm({
                                    ...form,
                                    policyExpiryDate: newDate,
                                });
                            }}
                            required
                            value={form.policyExpiryDate}
                        />
                    </FormGroup>

                    <FormGroup
                        label="Indemnity Limit"
                        inline={true}
                        className="form-fill required"
                        labelFor="indemnityLimit"
                    >
                        <CurrencyInput
                            id="indemnityLimit"
                            allowNegativeValue={false}
                            name="indemnityLimit"
                            className="bp3-input"
                            placeholder="Please enter a number"
                            maxLength={10}
                            value={form.indemnityLimit}
                            decimalsLimit={2}
                            disableAbbreviations={true}
                            step={1}
                            required
                            prefix="£"
                            onValueChange={(value, name) =>
                                handleIndemnityLimitChange(value)
                            }
                        />
                    </FormGroup>

                    <FormGroup
                        label="Insurer"
                        inline={true}
                        className="form-fill required"
                        labelFor="Insurer"
                    >
                        <InputGroup
                            id="insurer"
                            placeholder="Insurer"
                            minLength={0}
                            min={0}
                            maxLength={75}
                            value={form.insurer}
                            onChange={(e) =>
                                setForm(StateHandler.getStateObject(e, form))
                            }
                            required
                        />
                    </FormGroup>

                    <FormGroup
                        label={exclusionsRestrictionsLabel}
                        inline={true}
                        className="form-fill"
                        labelFor="exclusionsRestrictions"
                    >
                        <TextArea
                            id="exclusionsRestrictions"
                            placeholder="Exclusions & Restrictions (Optional)"
                            onChange={(e) =>
                                setForm(StateHandler.getStateObject(e, form))
                            }
                            minLength={1}
                            value={form.exclusionsRestrictions}
                        />
                    </FormGroup>

                    <FormGroup
                        label="Comments"
                        inline={true}
                        className="form-fill"
                        labelFor="name"
                    >
                        <TextArea
                            id="comments"
                            placeholder="Comments (Optional)"
                            onChange={(e) =>
                                setForm(StateHandler.getStateObject(e, form))
                            }
                            minLength={1}
                            value={form.comments}
                        />
                    </FormGroup>

                    <FileSingleUpload
                        form={form}
                        fileId="certificate"
                        isMobile={isMobile}
                        isRequired={true}
                        fileLabel={fileLabel}
                        onUpdate={updateFile}
                    ></FileSingleUpload>

                    <FormGroup>
                        <ButtonLink
                            type="button"
                            intent="default"
                            className="float-left"
                            to={Routing.getRouteUpOneLevel(history, match)}
                        >
                            <Icon icon="ban" />
                            Cancel
                        </ButtonLink>
                        <Button
                            type="submit"
                            intent="primary"
                            className="float-right"
                        >
                            <Icon icon="paper-plane" />
                            Submit
                        </Button>
                    </FormGroup>
                </form>
            </Loading>
        </div>
    );
};

export default InsuranceAddEdit;
