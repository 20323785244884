import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadingIndicator from 'components/elements/LoadingIndicator';

class Loading extends Component
{
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        isLoading: true,
    };

    render() {
        const { isLoading, children, ...rest } = this.props;

        return (
            isLoading ?
            <LoadingIndicator {...rest} /> :
            children
        );
    }
}

export default Loading;
