import { Button, FormGroup, H1, InputGroup, TextArea } from '@blueprintjs/core';
import { ChangeEvent, FormEvent, useEffect, useReducer, useState } from 'react';
import { withRouter } from 'react-router';

import { ButtonLink, Icon } from 'components/elements';
import HtmlEditor from 'components/elements/HtmlEditor/HtmlEditor';
import MultiLevelDisplay from 'components/elements/multiLevelDisplay/MultiLevelDisplay';
import { FlatObject } from 'interface';
import { EmailTemplateData, EmailTemplateProps } from 'interface/Email';
import { Response, Routing, StateHandler } from 'service';
import { handleEmailTemplateAddEditSubmit } from 'service/Email/TemplateService';

const TemplateAddEdit = (props: EmailTemplateProps) => {
    const { history, match, template, reload, campaign } = props;
    const [form, setForm]: [
        form: any,
        setForm: React.ReducerWithoutAction<any>
    ] = useReducer(
        (
            form: Partial<EmailTemplateData>,
            edits: Partial<EmailTemplateData>
        ) => {
            return { ...form, ...edits };
        },
        {
            name: '',
            subject: '',
            description: '',
            topContent: '',
            bottomContent: '',
            tokensArray: [],
            _links: {},
        }
    );
    const [isEditing, setIsEditing] = useState(false);

    const convertTokensToArray = (tokens: FlatObject) => {
        const tokenArray: FlatObject[] = [];
        Object.keys(tokens).forEach((key: string) => {
            const token = tokens[key];
            tokenArray.push({
                value: key,
                children:
                    typeof token !== 'object'
                        ? undefined
                        : convertTokensToArray(token),
            });
        });

        return tokenArray;
    };

    const load = () => {
        (async () => {
            if (template) {
                template.tokensArray = convertTokensToArray(template.tokens);
                setForm(template);
                setIsEditing(true);
            } else {
                setForm({
                    tokensArray: convertTokensToArray(
                        campaign?.emailTokens || {}
                    ),
                    _links: {
                        add: {
                            href: Response.getLink(
                                campaign,
                                'add-email-template'
                            ),
                            method: 'POST',
                        },
                        campaign: {
                            href: Response.getLink(campaign, 'self'),
                            method: 'GET',
                        },
                    },
                });
            }
        })();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(load, [campaign]);

    const editorOnChange = (content: string, key: string) => {
        setForm({ [key]: content });
    };

    return (
        <div className="ServiceAddEdit">
            <H1 className="clearfix">
                {isEditing ? 'Edit Email Template' : 'Add new Email Template'}
            </H1>

            <form
                className="AddEdit"
                onSubmit={(e: FormEvent<HTMLFormElement>) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleEmailTemplateAddEditSubmit(
                        form,
                        props,
                        isEditing,
                        reload
                    );
                }}
            >
                {!isEditing && (
                    <FormGroup
                        label="Name"
                        key="name"
                        inline={true}
                        className="form-fill required"
                        labelFor="name"
                    >
                        <InputGroup
                            id="name"
                            placeholder="Name"
                            minLength={1}
                            maxLength={255}
                            value={form.name}
                            onChange={(e) =>
                                setForm(StateHandler.getStateObject(e, form))
                            }
                            required
                        />
                    </FormGroup>
                )}
                <FormGroup
                    label="Subject"
                    key="subject"
                    inline={true}
                    className="form-fill required"
                    labelFor="subject"
                >
                    <InputGroup
                        id="subject"
                        placeholder="Subject"
                        minLength={1}
                        maxLength={255}
                        value={form.subject}
                        onChange={(e) =>
                            setForm(StateHandler.getStateObject(e, form))
                        }
                        required
                    />
                </FormGroup>
                <FormGroup
                    label="Description"
                    inline={true}
                    className="form-fill required text"
                    labelFor="description"
                >
                    <TextArea
                        id="description"
                        placeholder="Description"
                        onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                            setForm(StateHandler.getStateObject(e, form))
                        }
                        minLength={1}
                        maxLength={3000}
                        value={form.description}
                        rows={6}
                        required
                    />
                </FormGroup>
                {form.tokensArray && (
                    <FormGroup
                        label="Tokens"
                        inline={true}
                        className="form-fill flex-wrap"
                        labelFor="samples"
                    >
                        <MultiLevelDisplay data={form.tokensArray} />
                    </FormGroup>
                )}
                <FormGroup
                    label="Top Content"
                    key="topContent"
                    inline={true}
                    className="form-fill required"
                    labelFor="topContent"
                >
                    <HtmlEditor
                        content={form.topContent}
                        onChange={(content: string) => {
                            editorOnChange(content, 'topContent');
                        }}
                    />
                </FormGroup>
                <FormGroup
                    label="Bottom Content"
                    key="bottomContent"
                    inline={true}
                    className="form-fill required"
                    labelFor="bottomContent"
                >
                    <HtmlEditor
                        content={form.bottomContent}
                        onChange={(content: string) => {
                            editorOnChange(content, 'bottomContent');
                        }}
                    />
                </FormGroup>
                <FormGroup>
                    <ButtonLink
                        type="button"
                        intent="default"
                        className="float-left"
                        to={Routing.getRouteUpOneLevel(history, match)}
                    >
                        <Icon icon="ban" />
                        Cancel
                    </ButtonLink>
                    <Button
                        type="submit"
                        intent="primary"
                        className="float-right"
                    >
                        <Icon icon="paper-plane" />
                        Submit
                    </Button>
                </FormGroup>
            </form>
        </div>
    );
};

export default withRouter(TemplateAddEdit);
