import { ButtonLink, Icon, List } from 'components/elements';
import { ACTIVE_OPTIONS_WITH_NONE_SELECTED } from 'constants/active';
import { NONE_SELECTED_OPTION } from 'constants/none-selected';
import { ROLE_OPTIONS, ROLE_OPTIONS_WITHOUT_CLIENT } from 'constants/role';
import { delay } from 'helpers/helpers';
import { Component } from 'react';
import { toast } from 'react-toastify';
import { Filters, HTTP, Response } from 'service';

class UserList extends Component {
    columns = [
        { name: 'Forename', property: 'forename', sortable: true, grow: 2 },
        { name: 'Surname', property: 'surname', sortable: true, grow: 2 },
        { name: 'Role', property: 'displayRole', sortable: false, grow: 2 },
        {
            name: 'Email',
            property: 'email',
            sortable: true,
            grow: 3,
            // hide: 'sm',
        },
        {
            name: 'Is Active',
            property: 'isActive',
            type: 'mapping',
            mapping: ACTIVE_OPTIONS_WITH_NONE_SELECTED,
            sortable: false,
            grow: 2,
        },
        {
            name: 'Last Login Date',
            property: 'lastSuccessfulLogin',
            type: 'datetime',
            sortable: false,
            grow: 3,
        },
        {
            name: '',
            property: '',
            type: 'nav-button',
            grow: 1,
            route: this.getRoute,
            buttonIntent: 'primary',
            buttonText: 'View',
            right: true,
        },
    ];

    defaultFilters = {
        order: 'forename',
    };

    static defaultProps = {
        filterData: {},
    };

    constructor(props) {
        super(props);

        this.state = {
            collectionResponse: {},
            filterData: { ...this.defaultFilters, ...props.filterData },
            isLoading: false,
            searchFilters: {
                forename: {
                    type: 'text',
                    label: 'Forename',
                    key: 'forename',
                    placeholder: 'e.g. John',
                },
                surname: {
                    type: 'text',
                    label: 'Surname',
                    key: 'surname',
                    placeholder: 'e.g. Baptiste',
                },
                email: {
                    type: 'text',
                    label: 'Email',
                    key: 'email',
                    placeholder: 'e.g. john.b@elogs.co.uk',
                },
                isActive: {
                    type: 'single-select',
                    defaultValue: NONE_SELECTED_OPTION,
                    label: 'Active',
                    key: 'isActive',
                    options: ACTIVE_OPTIONS_WITH_NONE_SELECTED,
                },
                roles: {
                    type: 'single-select',
                    defaultValue: NONE_SELECTED_OPTION,
                    label: 'Role',
                    key: 'roles[]',
                    options: [
                        NONE_SELECTED_OPTION,
                        ...ROLE_OPTIONS_WITHOUT_CLIENT,
                    ],
                },
            },
        };

        this.load = this.load.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.getHeaderButton = this.getHeaderButton.bind(this);
    }

    getRoute(row, column) {
        return '/admin/users/' + row.id;
    }

    getHeaderButton() {
        const { collectionResponse } = this.state;
        const { match } = this.props;

        return Response.getLink(collectionResponse, 'create') ===
            null ? null : (
            <ButtonLink
                type="button"
                to={`${match.url}/add`}
                intent="primary"
                className="float-right"
            >
                <Icon icon="plus-circle" />
                Add new User
            </ButtonLink>
        );
    }

    render() {
        const { filterData, isLoading, searchFilters, collectionResponse } =
            this.state;

        return (
            <div className="UserList">
                <List
                    title="User List"
                    columns={this.columns}
                    load={(filter) => this.load(filter, true)}
                    collection={collectionResponse}
                    filter={filterData}
                    defaultFilter={this.defaultFilters}
                    isLoading={isLoading}
                    filters={searchFilters}
                    onFilter={this.onFilter}
                    headerButton={this.getHeaderButton}
                    preIsFilterOpen={true}
                />
            </div>
        );
    }

    clearFilters() {
        this.setState({
            filterData: {
                ...this.defaultFilters,
                page: 1,
            },
        });
    }

    onFilter(filterData) {
        this.setState({
            filterData: {
                ...this.state.filterData,
                ...filterData,
            },
        });
    }

    async loadUsers(response) {
        const userResponse = response.data;

        let userData = userResponse.users.map((user) => {
            let selectedRole = user.roles.filter(
                (role) => role !== 'ROLE_USER'
            )[0];
            user.displayRole = ROLE_OPTIONS.filter(
                (role) => role.value === selectedRole
            )[0].label;

            return user;
        });

        userResponse.users = userResponse.data = userData;

        this.setState({ collectionResponse: userResponse });
        await delay(0);
        this.setState({ isLoading: false });
    }

    load(params = {}, paramsOverride = false) {
        if (this.state.isLoading) {
            toast.error('Still loading last search.');
            return;
        }

        this.setState({ isLoading: true, filterData: params });

        HTTP.get('/users', Filters.convertFilterDataToRequestData(params)).then(
            (response) => {
                if (response) {
                    this.loadUsers(response);
                    return true;
                }

                toast.error('Unable to fetch a list of Users');
                this.setState({ isLoading: false });

                return false;
            }
        );
    }
}

export default UserList;
