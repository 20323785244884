import { Button, FormGroup, H2, HTMLSelect } from '@blueprintjs/core';
import { SyntheticEvent, createRef, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';

import { Icon, SuggestRenderer } from 'components/elements';
import { NONE_SELECTED_OPTION } from 'constants/none-selected';
import { delay, globalDebounce } from 'helpers/helpers';
import { CompanyData, CompanyFilter, CompanyListData, Option } from 'interface';
import {
    CampaignComponentProps,
    CampaignInvitee,
    CampaignPriority,
    CampaignPriorityOptions,
} from 'interface/Client/Campaign';
import { ClientService, CompanyService, Response, Routing } from 'service';
import CampaignService from 'service/Client/CampaignService';

interface CampaignAddEditComponentProps extends CampaignComponentProps {
    merge?: (form: CampaignInvitee) => void;
}

const CampaignInviteeAddExisting = (props: CampaignAddEditComponentProps) => {
    const { campaign, history, match, merge } = props;
    const [isReset, setIsReset] = useState<boolean>(() => false);
    const ref = createRef<HTMLFormElement>();

    const [form, setForm] = useState<CampaignInvitee>({
        campaign,
        priority: CampaignPriority.High,
    } as CampaignInvitee);

    const [SPCategoryOptions, setSPCategoryOptions] = useState<Option[]>(
        () => []
    );

    const [serviceProviderFilter, setServiceProviderFilter] = useState<
        Partial<CompanyFilter>
    >(CompanyService.createCompanyFilter());
    const [serviceProviders, setServiceProviders] = useState<CompanyData[]>();

    const handleSubmit = async (event: SyntheticEvent) => {
        event.preventDefault();
        event.stopPropagation();

        const response = await CampaignService.addExistingInvitee(
            campaign,
            form
        );

        if (!response) {
            return;
        }

        toast.success('Successfully Added Existing Service Provider');

        typeof merge === 'function' && merge(form);

        await delay(1); // wait for isReset to trigger (for create & add another)
        if (isReset) {
            (ref.current as HTMLFormElement).reset();
            setForm({
                campaign: campaign,
                priority: CampaignPriority.High,
            } as CampaignInvitee);
            setIsReset(false);
        } else {
            Routing.navigateUpOneLevel(history, match, false);
        }
    };

    useEffect(() => {
        // wait for campaign.client then fetch categories
        if (campaign && campaign.client) {
            loadSPCategories();
            loadServiceProviders();
            setServiceProviderFilter({
                ...serviceProviderFilter,
                excludeClientId: campaign.client.id,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaign]);

    const searchServiceProviders = (name?: string) => {
        const filter = {
            ...serviceProviderFilter,
            registeredCompanyName: name,
        };
        !name && delete filter.registeredCompanyName;
        setServiceProviderFilter(filter);
    };

    const loadServiceProviders = (callback?: Function) => {
        globalDebounce(
            async () => {
                const serviceProviderList: CompanyListData =
                    await CompanyService.loadList(serviceProviderFilter);
                setServiceProviders(serviceProviderList.companies);
                callback && callback();
            },
            'IntegrationServiceProviders',
            250
        );
    };

    const loadSPCategories = async () => {
        const categories = await ClientService.getSPCategories(campaign.client);
        const options = categories.map((category) => {
            return {
                value: Response.getLink(category, 'self'),
                label: category.name,
            };
        });
        setSPCategoryOptions([NONE_SELECTED_OPTION, ...options]);
    };

    const selectSp = (item: any = null) => {
        if (item === null) {
            searchServiceProviders('');

            return;
        }

        setLink('company', Response.getLink(item, 'self'));
        return;
    };

    const setLink = (name: string, href: string) => {
        setForm({
            ...form,
            ...Response.updateLink(form, name, href),
        });
    };

    useEffect(loadServiceProviders, [serviceProviderFilter]);

    return (
        <div className="CampaignAddEdit">
            <H2>Add Existing Service Provider</H2>
            <form className="AddEdit" onSubmit={handleSubmit} ref={ref}>
                <FormGroup
                    label="Service Provider Name"
                    key="serviceProvider"
                    inline={true}
                    className="form-fill required"
                    labelFor="serviceProvider"
                >
                    <SuggestRenderer
                        id="serviceProvider"
                        onItemSelect={(item: any) => selectSp(item)}
                        resetOnClose={false}
                        placeholder="Select Service Provider Name"
                        valueProperty="registeredCompanyName"
                        items={serviceProviders || []}
                        onKeyUp={(e: SyntheticEvent) => {
                            searchServiceProviders(
                                (e.target as HTMLFormElement).value
                            );
                        }}
                        isRequired={true}
                    />
                </FormGroup>

                <FormGroup
                    label="Priority for Client"
                    key="priority"
                    inline={true}
                    className="form-fill required"
                    labelFor="priority"
                >
                    <HTMLSelect
                        id="priority"
                        fill={true}
                        defaultValue={form.priority}
                        onChange={(e) =>
                            setForm({
                                ...form,
                                priority: e.target.value as CampaignPriority,
                            })
                        }
                        options={CampaignPriorityOptions}
                        required
                    />
                </FormGroup>

                <FormGroup
                    label="Service Provider Category"
                    key="sp-category"
                    inline={true}
                    className="form-fill required"
                    labelFor="sp-category"
                >
                    <HTMLSelect
                        id="sp-category"
                        fill={true}
                        onChange={(e) => setLink('sp-category', e.target.value)}
                        required
                        options={SPCategoryOptions}
                    />
                </FormGroup>

                <FormGroup>
                    <Button
                        type="button"
                        className="float-left"
                        onClick={() => {
                            Routing.navigateUpOneLevel(history, match, false);
                        }}
                    >
                        <Icon icon="ban" />
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        intent="primary"
                        className="float-right me-2"
                    >
                        <Icon icon="paper-plane" />
                        Confirm
                    </Button>
                </FormGroup>
            </form>
        </div>
    );
};

export default withRouter(CampaignInviteeAddExisting);
