import { Button } from '@blueprintjs/core';
import PropTypes from 'prop-types';
import { createRef, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';

import Text from 'components/elements/filter/Text';
import { Loading } from 'components/elements/wrappers';
import { globalDebounce } from 'helpers/helpers';
import { Filters, HTTP, Location } from 'service';
import './index.scss';

const SearchWidget = (props) => {
    const { refreshSearch, location, searchKey } = props;
    const pathname = location.pathname;
    const defaultFilters = {
        page: 1,
        limit: 20,
        datagroup: 'search',
        ...props.defaultFilters,
    };
    const [searchTextValue, setSearchTextValue] = useState(
        Filters.getFilterStateFromUrl(location.search.slice(1))[searchKey]
    );

    const [filters, setFilters] = useState(defaultFilters);
    const [activeFilters, setActiveFilters] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isUnmounted, setIsUnmounted] = useState(false);
    const formRef = createRef();

    //subscribe to history changes
    useEffect(() => {
        const { history, location } = props;

        // subscribe to URL changes
        const unsubscribe = props.history.listen((location, action) => {
            if (location.pathname !== pathname) {
                return;
            }
            const urlFilters = Filters.getFilterStateFromUrl(
                location.search.slice(1),
                filters
            );
            setActiveFilters(urlFilters);

            if (props.onSubmit) {
                props.onSubmit();
            }
        });

        const urlFilters = Filters.getFilterStateFromUrl(
            location.search.slice(1),
            {}
        );
        // dont search on load unless there is filters (i.e. when using browser history)
        if (Object.keys(urlFilters).length) {
            Location.setQueryStringFromObject(
                history,
                location,
                Filters.convertFilterDataToRequestData(urlFilters),
                false
            );
        }

        // focus on load
        formRef.current.querySelector('input').focus();

        // componentWillUnmount
        return () => {
            setIsUnmounted(true);
            unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // search on activeFilters change
    useEffect(() => {
        if (activeFilters || refreshSearch) {
            globalDebounce(doSearch, 'searchWidget', 250);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeFilters, refreshSearch]);

    const onSubmit = (event) => {
        const { history, location } = props;
        event.preventDefault();
        event.stopPropagation();

        const newFilters = { ...filters, ...props.defaultFilters };
        setFilters(newFilters);

        // set url with updated filters to trigger activeFilter change, which submits form
        Location.setQueryStringFromObject(
            history,
            location,
            Filters.convertFilterDataToRequestData(newFilters),
            false
        );
    };

    const onSearchTextChange = (value) => {
        if (props.onSearchTextChange) {
            props.onSearchTextChange(value);
        }

        setSearchTextValue(value.value);
        handleFilterChange(value, props.searchKey || 'name');
    };

    // TODO: handle additional filters as needed
    const handleFilterChange = (selected, filterKey) => {
        let newFilters = Object.assign({}, filters, { [filterKey]: selected });

        if (props.onFilterChange) {
            props.onFilterChange(
                Filters.convertFilterDataToRequestData(newFilters),
                newFilters
            );
        }

        setFilters(newFilters);
    };

    const doSearch = async () => {
        const { href, method } = props.searchLink;
        let response;
        setIsLoading(true);

        switch (method) {
            case 'POST':
                response = await HTTP.post(
                    href,
                    Filters.convertFilterDataToRequestData(activeFilters)
                );
                break;
            case 'GET':
            default:
                response = await HTTP.get(
                    href,
                    Filters.convertFilterDataToRequestData(activeFilters)
                );
        }
        setIsLoading(false);

        if (isUnmounted) {
            return;
        }

        if (response && response.data) {
            onResult(response.data);
            return;
        }

        toast.error('Search was unsuccessful, please try again');
    };

    const onResult = (results) => {
        if (props.onResult) {
            props.onResult(results);
        }
    };

    const onReset = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setSearchTextValue('');
        setFilters(defaultFilters);

        typeof props.onReset === 'function' && props.onReset();
        return false;
    };

    return (
        <div className="search-widget">
            <form onSubmit={onSubmit} ref={formRef}>
                <Text
                    onChange={onSearchTextChange}
                    value={searchTextValue}
                    filterKey="search-widget-text"
                    label={props.searchLabel || 'Name'}
                    name="search-field"
                    placeholder={props.searchPlaceholder}
                />

                {props.children}
                <Loading isLoading={isLoading} children={null} inline={true} />
                <div className="buttons">
                    <Button
                        type="submit"
                        disabled={isLoading}
                        intent="primary"
                        className="bp3-large icon-free"
                    >
                        {props.searchButtonLabel || 'Search'}
                    </Button>
                    <button className="clear-button success" onClick={onReset}>
                        [ Reset Filters ]
                    </button>
                </div>
            </form>
        </div>
    );
};

SearchWidget.propTypes = {
    searchLink: PropTypes.shape({
        href: PropTypes.string.isRequired,
        method: PropTypes.string.isRequired,
    }).isRequired,
    searchButtonLabel: PropTypes.string,
    searchKey: PropTypes.string.isRequired,
    searchLabel: PropTypes.string,
    searchPlaceholder: PropTypes.string,
    onSearchTextChange: PropTypes.func,
    onFilterChange: PropTypes.func,
    onSubmit: PropTypes.func,
    onResult: PropTypes.func.isRequired,
    defaultFilters: PropTypes.object,
};

export default withRouter(SearchWidget);
