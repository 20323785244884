const routes = {
    supply_chain: {
        href: '/supply-chain',
        text: 'Supply Chain',
        icon: 'cogs',
    },
    supply_chain_active: {
        href: '/supply-chain/list',
        text: 'List',
        icon: 'cogs',
    },
    supply_chain_analytics: {
        href: '/supply-chain/analytics',
        text: 'Analytics',
        icon: 'cogs',
    },
    supply_chain_elogs_cafm_stats: {
        href: '/supply-chain/elogs-cafm-stats',
        text: 'Elogs CAFM Stats',
    },
};

export default routes;
