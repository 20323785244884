import React from 'react';
import { Route } from 'react-router-dom';
import routes from 'routes';
import queryString from 'query-string';

class Routing
{
    static getSelectedTabId(pathname, matchedUrl)
    {
        return pathname.replace(new RegExp('^' + matchedUrl, 'i'), '').split('/', 2).join('/');
    }

    static ProppedRoute = ({component: Component, ...rest}) =>
    {
        return (
            <Route { ...rest } render={ routeProps => {
                return <Component { ...routeProps } { ...rest } />;
            }} />
        );
    }

    static generateProppedRoute(component, ...rest)
    {

    }

    static getRouteUpOneLevel(history, match, toBeAppended)
    {
        let lastSlashIndex = match.url.lastIndexOf('/');
        let newUrl = match.url.substring(0, lastSlashIndex);

        if (toBeAppended) {
            while (toBeAppended.substring(0, 1) === '/') {
                toBeAppended = toBeAppended.substring(1);
            }

            newUrl += '/' + toBeAppended;
        }

        return newUrl;
    }

    static navigateUpOneLevel(history, match, toBeAppended)
    {
        return history.push(Routing.getRouteUpOneLevel(history, match, toBeAppended));
    }

    static getParametisedRoute(route, params = {})
    {
        let routePath = route;
        if (routes.hasOwnProperty(route)) {
            routePath = routes[route].href;
        }

        let stringifiedQuery = {};
        Object.keys(params).forEach((key, index) => {
            stringifiedQuery[key] = JSON.stringify(params[key]);
        });

        return routePath + '?' + queryString.stringify(stringifiedQuery);

        // Object.keys(params).map(key => `${key}=${encodeURIComponent(params[key])}`).join('&');
    }

    static parseSearchParams(paramString) {
        let params = queryString.parse(paramString);

        if (params.page) {
            params.page = Number(params.page);
        }

        return params;
    }
}

export default Routing;
