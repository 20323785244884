import type { AxiosResponse } from 'axios';
import { NONE_SELECTED_OPTION } from 'constants/none-selected';
import type { FlatObject } from 'interface';
import Filters from 'service/Filters';
import HTTP from 'service/HTTP';

export const getQuestions = (
    filters: FlatObject
): Promise<AxiosResponse<any, any> | undefined> => {
    return HTTP.action(
        'get',
        '/questions',
        Filters.convertFilterDataToRequestData(filters),
        {},
        'Unable to fetch a list of Questions'
    );
};

export const getCategories = (): Promise<
    AxiosResponse<any, any> | undefined
> => {
    return HTTP.action(
        'get',
        '/question/categories',
        { limit: 500 },
        {},
        'Unable to fetch a list of Question Categories'
    );
};

export const getCategoriesListForDropdown = async () => {
    const options = [NONE_SELECTED_OPTION];
    const response = await getCategories();

    if (response) {
        response.data.categories.forEach((category: any) => {
            options.push({
                label: category.name as string,
                value: category.id as string,
            });
        });
    }

    return options;
};
