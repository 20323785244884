import { useEffect, useState } from 'react';

import { DonutChart, LoadingIndicator } from 'components/elements';
import { ELBSPJobCounts } from 'interface/Integrations';
import { loadClientELBServiceProviderCounts } from 'service/Integration/IntegrationService';
import WidgetCard from './WidgetCard';

interface CompletedJobsWidgetProps {
    clientId: string;
}

const defaultCounts = {
    belowRequirementCount: 0,
    expiringSoonCount: 0,
    meetsRequirementsCount: 0,
    notInSupplyChainCount: 0,
};

const CompletedJobs = (props: CompletedJobsWidgetProps) => {
    const [counts, setCounts] = useState<ELBSPJobCounts>(
        defaultCounts as ELBSPJobCounts
    );
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [noResults, setNoResults] = useState<boolean>(false);

    useEffect(() => {
        if (props.clientId) {
            updateCounts();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.clientId]);

    const updateCounts = async () => {
        setIsLoading(true);

        setNoResults(false);

        const jobCounts = await loadClientELBServiceProviderCounts(
            props.clientId
        );

        if (
            jobCounts.belowRequirementCount === 0 &&
            jobCounts.expiringSoonCount === 0 &&
            jobCounts.meetsRequirementsCount === 0 &&
            jobCounts.notInSupplyChainCount === 0
        ) {
            setNoResults(true);
        }

        setCounts(jobCounts);

        setIsLoading(false);
    };

    const renderChart = () => {
        if (isLoading) {
            return <LoadingIndicator />;
        }

        if (noResults) {
            return <p>No data received from CAFM yet</p>;
        }

        return (
            <>
                <DonutChart
                    data={[
                        {
                            label: 'Meet Requirements',
                            name: 'meet-requirements',
                            value: counts.meetsRequirementsCount,
                            link: '#/supply-chain/elogs-cafm-stats/list?page=1&statusAtCompletion=0&type=reactive',
                        },
                        {
                            label: 'Below Requirements',
                            name: 'below-requirements',
                            link: '#/supply-chain/elogs-cafm-stats/list?page=1&statusAtCompletion=2&type=reactive',
                            value: counts.belowRequirementCount,
                        },
                        {
                            label: 'Expiring Soon',
                            name: 'expiring-soon',
                            link: '#/supply-chain/elogs-cafm-stats/list?page=1&statusAtCompletion=1&type=reactive',
                            value: counts.expiringSoonCount,
                        },
                        {
                            label: 'Not In Supply Chain or not linked to Prosure',
                            name: 'not-in-supply-chain',
                            value: counts.notInSupplyChainCount,
                            link: '#/supply-chain/elogs-cafm-stats/list?page=1&statusAtCompletion=-1&type=reactive',
                        },
                    ]}
                    height={300}
                    width={250}
                    legendWidth={250}
                    text=""
                    colours={[
                        {
                            name: 'meet-requirements',
                            label: 'Meet Requirements',
                            colour: '#7bbb4c',
                        },
                        {
                            name: 'below-requirements',
                            label: 'Below Requirements',
                            colour: '#ed5565',
                        },
                        {
                            name: 'expiring-soon',
                            label: 'Expiring Soon',
                            colour: '#d9822b',
                        },
                        {
                            name: 'not-in-supply-chain',
                            label: 'Not In Supply Chain or not linked to Prosure',
                            colour: '#414243',
                        },
                    ]}
                />
            </>
        );
    };

    return (
        <WidgetCard
            title="Elogs CAFM Jobs completed by Service Provider Status (Last 30 days)"
            tooltip="This chart shows a breakdown of all the
         completed Reactive jobs in your Elogs CAFM over the last
        30 days and a breakdown of whether the Service
        Providers completing the jobs Met your Supply
        Chain Requirements or not."
        >
            {renderChart()}
        </WidgetCard>
    );
};

export default CompletedJobs;
