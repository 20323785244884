import {
    ASSESSMENT_TYPE_VERSION_ACTIVE,
    ASSESSMENT_TYPE_VERSION_DRAFT,
    ASSESSMENT_TYPE_VERSION_INACTIVE,
} from 'constants/AssessmentTypeVersion';
import {
    AssessmentTypeInstanceData,
    ListData,
    ListFilter,
    Option,
    SLASData,
} from './index';

export interface AssessmentTypeData {
    name: string;
    shortName: string;
    description: string;
    id: string;
    rootId: string;
    pricing: any;
    userOrganisationSize: string;
    isSSIPAccreditation: boolean;
    isSSIPDTS: boolean;
    isProsurePreQualified: boolean;
    reviewFrequency: 'one-off' | 'annual' | 'case-by-case' | 'by-client';
    SSIPScopeCategories: [string];
    isProsureAssessorReviewerRequired: boolean;
    isClientAssessorRequired: boolean;
    allowAutomatedPassFail: boolean;
    isVisibleToClient: boolean;
    serviceProviderSizeApplicability: null | 4 | 9999999;
    slas: SLASData;
    type: 'prosure' | 'client';
    lockAssessmentInUse?: boolean;
    versionStatus:
        | typeof ASSESSMENT_TYPE_VERSION_ACTIVE
        | typeof ASSESSMENT_TYPE_VERSION_INACTIVE
        | typeof ASSESSMENT_TYPE_VERSION_DRAFT;
    _links: any;
}

export const serviceProviderSizeApplicabilityMapping = {
    undefined: 'Any Number of Employees',
    null: 'Any Number of Employees',
    4: '0-4 Employees',
    9999999: '5 or More Employees',
};

export interface AssessmentTypeListData extends ListData {
    count: number;
    assessmentTypes: Partial<AssessmentTypeData>[];
    data: AssessmentTypeData[]; // Had to add this duplicate property as TypeScript Generic types does not like Partial for collections
}

export interface AssessmentTypeFilter extends ListData {
    name: string;
    isActive: boolean;
    order: string;
}

export interface AssessmentTypeListFilter extends ListFilter {
    isActive: boolean;
}

export interface TopicData {
    id: string;
    name: string;
    description: string;
    _links: any;
}

export interface AssessmentStateData {
    topicCollection: AssessmentTopicData;
    questionsCollection: AssessmentQuestionData;
    answersCollection: AssessmentAnswerData;
    reviewsCollection: AssessmentReviewData;
    stockResponsesCollection: StockResponsesData;
    sortedQuestions: SortedTopic[];
    errors: AssessmentErrorCollection[];
    changes: any;
    reviewChanges: ReviewData[];
    answersHaveBeenCopied: boolean;
    isExpanded: boolean;
    isLoading: boolean;
    assessmentTypeInstance: AssessmentTypeInstanceData;
    assessmentSubmitted: boolean;
    discount: any;
    clientName?: string;
}

export interface AssessmentTopicData extends ListData {
    topics: TopicData[];
}

export interface QuestionData {
    id: string;
    summary: string;
    isActive: boolean;
    type: string;
    isRequired: boolean;
    expiryRequirement: number;
    numberRequirement: number;
    fileRequirement: number;
    conditionRequirement: boolean;
    guidanceNotes: string;
    assessorGuidanceNotes: string;
    options: [];
    allowAnAnswerToFailAssessment: false;
    yesNoAutoFailOption: boolean;
    selectedFailOptions: [Option];
    minValue: string;
    maxValue: string;
    maxLength: number;
    minLength: number;
    maxDateOffset: number;
    minDateOffset: number;
    _links: any;
}

export interface QuestionInstanceData {
    id: string;
    summary: string;
    topicId: string;
    question: QuestionData;
    _links: any;
}

export interface AssessmentQuestionData extends ListData {
    questions: QuestionInstanceData[];
}

export interface AnswerData {
    id: string;
    value: string | TrendAnswerValue;
    review?: ReviewData;
    files?: any[];
    expiresAt?: string;

    _links: any;

    //added in assessment form
    errorMessages?: string[];
}

export interface TrendAnswerValue {
    yearBeforeLast: string;
    lastYear: string;
    ytd: string;
}

export interface AssessmentAnswerData extends ListData {
    answers: AnswerData[];
}

export interface ReviewData {
    id: string;
    isApproved?: boolean;
    comment: string;
    createdAt: string;
    _links: any;
}

export interface AssessmentReviewData extends ListData {
    reviews: ReviewData[];
}

export interface StockResponsesData {
    [questionId: string]: StockResponse;
}

export interface StockResponse {
    id: string;
    label: string;
}

export interface SortedTopic {
    topic: TopicData;
    sortedQuestions: QuestionInstanceData[];
    errorMessage?: string;
    warningMessage?: string;
    successMessage?: string;
    subtitle?: string;
    isOpen: boolean;
}

export interface AssessmentFormError {
    type: 'validation' | 'review';
    message: string;
}

export interface AssessmentErrorCollection {
    topic: TopicData;
    question: QuestionData;
    answer: AnswerData;
    errors: AssessmentFormError[];
}

export const ASSESSMENT_FORM_ERROR_TYPE_VALIDATION = 'validation';
export const ASSESSMENT_FORM_ERROR_TYPE_REVIEW = 'review';
