import React, { Component } from 'react';
import { HTMLSelect as BPHTMLSelect } from '@blueprintjs/core';

class HTMLSelect extends Component
{
    maybeRenderRightElement = () => {
        const { rightElement } = this.props;
        if (rightElement == null) {
            return undefined;
        }

        return (
            <span className="html-select-right-element" ref="html-select-right-element">
                {rightElement}
            </span>
        );
    }

    render()
    {
        const classes = ['bp3-html-select', 'bp3-fill'];
        if (this.props.multiple) {
            classes.push('multiple');
        }

        const { children, multiple, defaultValue, rightElement, ...rest } = this.props;

        if (multiple) {
            let renderedChildren = children;

            if (Array.isArray(children) &&
                Array.isArray(defaultValue)) {
                renderedChildren = children.map((option) => {
                    let newOption = Object.assign({}, option);

                    if (defaultValue.includes(option.props.value)) {
                        newOption.props = Object.assign({}, option.props, {selected: true});
                    }

                    return newOption;
                });
            }

            return (
                <div className={ classes.join(' ') }>
                    <select multiple={ multiple }
                        { ...rest }>
                        { renderedChildren }
                    </select>
                </div>
            );
        }

        return <div className="html-select-wrap">
            <BPHTMLSelect defaultValue={ defaultValue } { ...rest } />
            { this.maybeRenderRightElement() }
        </div>
    }
}

export default HTMLSelect;
