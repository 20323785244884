import { Button, Intent } from '@blueprintjs/core';
import { DateTime } from 'luxon';
import { ComponentProps, useState } from 'react';
import { useSelector } from 'react-redux';

import ChangeOwnershipDialog from 'components/dialogs/ChangeOwnershipDialog';
import { isAdmin, isClientAdmin } from 'helpers/helpers';
import { AssessmentStateData } from 'interface';
import { getAssessmentStatus, InstanceService, Response } from 'service';

interface AssessmentStatusProps extends ComponentProps<any> {
    mode: string;
    reloadData?: Function;
}

const AssessmentStatus = (props: AssessmentStatusProps) => {
    const { mode } = props;
    const { assessmentTypeInstance }: AssessmentStateData = useSelector(
        (state: any) => state.assessments
    );
    const userResponse = useSelector((state: any) => state.root.response);
    const [showChangeOwnershipDialog, setShowChangeOwnershipDialog] =
        useState(false);
    const assessorId = Response.getLinkAttribute(
        assessmentTypeInstance,
        'last-assessed-by',
        'id'
    );
    const reviewerId = Response.getLinkAttribute(
        assessmentTypeInstance,
        'last-reviewed-by',
        'id'
    );
    const forename = Response.getLinkAttribute(
        assessmentTypeInstance,
        'last-assessed-by',
        'forename'
    );
    const email = Response.getLinkAttribute(
        assessmentTypeInstance,
        'last-assessed-by',
        'email'
    );

    const getInfoMessage = () => {
        // Reviewer
        if (mode === 'review') {
            if (
                !assessmentTypeInstance._links['assess-answers'] &&
                !assessmentTypeInstance._links['review-answers']
            ) {
                return `Reviews are locked - ${getAssessmentStatus(
                    assessmentTypeInstance.status
                )}.`;
            } else if (assessmentTypeInstance._links['assess-answers']) {
                if (assessmentTypeInstance._links['take-assessor-ownership']) {
                    return `Awaiting assessor ownership.`;
                } else if (assessorId === userResponse.user.id) {
                    return 'You are the assigned assessor.';
                } else if (forename) {
                    return `This assessment has been assigned to ${forename}, with email ${email}`;
                } else if (
                    typeof assessmentTypeInstance.clientName !== 'undefined'
                ) {
                    return `This is a client assessment for ${assessmentTypeInstance.clientName}`;
                } else {
                    return `This assessment is not yet assignable to you`;
                }
            } else if (assessmentTypeInstance._links['review-answers']) {
                if (assessmentTypeInstance._links['take-reviewer-ownership']) {
                    return `Awaiting reviewer ownership.`;
                } else if (reviewerId === userResponse.user.id) {
                    return 'You are the assigned reviewer.';
                } else {
                    return `This assessment has been assigned to another reviewer`;
                }
            }
        }

        // SP
        if (mode === 'company') {
            if (
                assessmentTypeInstance.status ===
                'status_submitted_awaiting_assessor'
            ) {
                return `This assessment was submitted on ${DateTime.fromISO(
                    assessmentTypeInstance.submittedAt
                ).toLocaleString(
                    DateTime.DATETIME_MED_WITH_WEEKDAY
                )} and is now locked, awaiting an assessor.`;
            } else if (
                [
                    'status_instance_assigned_to_assessor',
                    'status_instance_assigned_to_reviewer',
                ].includes(assessmentTypeInstance.status)
            ) {
                return `Assessment being assessed by ${forename}, with email ${email}`;
            } else if (
                assessmentTypeInstance.status === 'status_submitted_returned'
            ) {
                return `Assessment returned from your assessor ${forename}, with email ${email}`;
            } else if (
                assessmentTypeInstance.status ===
                'status_pending_awaiting_reviewer'
            ) {
                return 'Your assessment is awaiting review.';
            } else if (assessmentTypeInstance.status === 'status_completed') {
                const assessorContact =
                    forename && email
                        ? `Your assessor was ${forename}, with email ${email}`
                        : '';
                return `This Assessment has been completed and is now locked. ${assessorContact}`;
            } else if (assessmentTypeInstance._links['update-answers']) {
                return 'Once you have submitted your assessment, you will be allocated an assessor. Their details will appear here.';
            }
        }
    };

    const reloadData = () => {
        typeof props.reloadData === 'function' && props.reloadData();
    };

    return (
        <div className="assessment-status">
            <span className="status-message">{getInfoMessage()}</span>
            {Response.getLink(assessmentTypeInstance, 'assess-answers') &&
                Response.getLink(
                    assessmentTypeInstance,
                    'take-assessor-ownership'
                ) && (
                    <Button
                        intent={Intent.SUCCESS}
                        className="assess me-2"
                        onClick={() =>
                            InstanceService.takeOwnership(
                                assessmentTypeInstance,
                                'assessor',
                                reloadData
                            )
                        }
                    >
                        Take Assessor Ownership
                    </Button>
                )}
            {Response.getLink(
                assessmentTypeInstance,
                'change-assessor-ownership'
            ) && (
                <Button
                    intent={Intent.SUCCESS}
                    className="assess me-2"
                    onClick={() => setShowChangeOwnershipDialog(true)}
                >
                    Change Assessor Ownership
                </Button>
            )}
            {Response.getLink(assessmentTypeInstance, 'review-answers') &&
                Response.getLink(
                    assessmentTypeInstance,
                    'take-reviewer-ownership'
                ) && (
                    <Button
                        intent={Intent.SUCCESS}
                        className="review me-2"
                        onClick={() =>
                            InstanceService.takeOwnership(
                                assessmentTypeInstance,
                                'reviewer',
                                reloadData
                            )
                        }
                    >
                        Take Reviewer Ownership
                    </Button>
                )}
            {(isAdmin() || isClientAdmin()) && (
                <ChangeOwnershipDialog
                    instance={assessmentTypeInstance}
                    isOpen={showChangeOwnershipDialog}
                    afterSubmit={reloadData}
                    onClose={() => {
                        setShowChangeOwnershipDialog(false);
                    }}
                />
            )}
        </div>
    );
};

export default AssessmentStatus;
