import { FormGroup, InputGroup, TextArea } from '@blueprintjs/core';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

import QuestionErrors from 'components/assessments/tabs/questions/components/QuestionErrors';
import { HTMLSelect, MultiSelectRenderer } from 'components/elements';
import {
    DTS_QUESTION_CERTIFICATION_NUMBER,
    DTS_QUESTION_SERVICES_UNDERTAKEN,
    DTS_QUESTION_SSIP_SCHEME_OPERATOR,
    DTS_QUESTION_SSIP_SCOPE_CATEGORIES,
} from 'constants/dtsQuestionKeys';
import {
    SSIP_SCHEME_OPERATOR_OPTIONS,
    SSIP_SCHEME_OPERATOR_OPTIONS_LEGACY,
} from 'constants/ssipSchemeOperator';
import { SSIP_SCOPE_CATEGORY_OPTIONS } from 'constants/ssipScopeCategories';

const AssessmentDTSQuestions = (props) => {
    const { assessmentTypeInstanceResponse, getAnswer } = props;
    const isEditable =
        !!assessmentTypeInstanceResponse._links['update-answers'] ||
        !!assessmentTypeInstanceResponse._links['reviewer-assessor-file-edit'];
    const [isLoading, setIsLoading] = useState(true);
    const [ssipScopeCategories, setSsipScopeCategories] = useState([]);

    useEffect(() => {
        if (isLoading) {
            parseSelectedSSIPScopeCategories();
            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const SSIPSchemeOperator = () => {
        // Find the item in the array by value
        const item = SSIP_SCHEME_OPERATOR_OPTIONS_LEGACY.find(
            (option) => option.value === getAnswer('SSIPSchemeOperator')
        );

        return (
            <div className="Question">
                <FormGroup
                    label="SSIP Scheme Operator / ISO 45001:2018 Provider:"
                    inline={!isMobile}
                    className="form-fill question-label choice required"
                >
                    {item && !isEditable ? (
                        <p>{item.label}</p>
                    ) : (
                        <p>{getAnswer('SSIPSchemeOperator')}</p>
                    )}

                    {isEditable && (
                        <HTMLSelect
                            options={SSIP_SCHEME_OPERATOR_OPTIONS}
                            defaultValue={getAnswer('SSIPSchemeOperator')}
                            onChange={(event) => {
                                props.onUpdate(
                                    DTS_QUESTION_SSIP_SCHEME_OPERATOR,
                                    event.target.value
                                );
                            }}
                            disabled={!isEditable}
                        />
                    )}
                </FormGroup>
            </div>
        );
    };

    const certificationNumber = () => {
        return (
            <div className="Question">
                <FormGroup
                    label="Assessment/Certification Number:"
                    inline={!isMobile}
                    className="form-fill question-label text"
                >
                    <InputGroup
                        id={`text_certificationNumber`}
                        name="certificationNumber"
                        fill={true}
                        minLength={1}
                        maxLength={100}
                        defaultValue={getAnswer('certificationNumber')}
                        onChange={(event) =>
                            props.onUpdate(
                                DTS_QUESTION_CERTIFICATION_NUMBER,
                                event.target.value
                            )
                        }
                        disabled={!isEditable}
                    />
                </FormGroup>
            </div>
        );
    };

    const parseSelectedSSIPScopeCategories = () => {
        const SSIPScopeCategories =
            assessmentTypeInstanceResponse.SSIPScopeCategories;
        const categories = parseSSIPScopeCategories(
            SSIP_SCOPE_CATEGORY_OPTIONS
        );
        const parsedCategories = [];

        SSIPScopeCategories.forEach((item) => {
            let categoryIndex = categories.findIndex(
                (category) => category.value === item
            );
            if (categoryIndex !== -1) {
                parsedCategories.push(categories[categoryIndex]);
            }
        });

        setSsipScopeCategories(parsedCategories);

        return parsedCategories;
    };

    const parseSSIPScopeCategories = (categories) => {
        if (!categories instanceof Array) {
            return [];
        }

        categories.forEach((item, index, arr) => {
            arr[index] = { ...item, id: index };
        });

        return categories;
    };

    const parseUpdatedSSIPScopeCategories = (items) => {
        const categories = [];

        items.forEach((item, index) => {
            categories[index] = item.value;
        });

        return categories;
    };

    const SSIPScopeCategory = () => {
        return (
            <div className="Question">
                <FormGroup
                    label="SSIP Scope Categories:"
                    inline={!isMobile}
                    className="form-fill question-label choice required"
                >
                    <MultiSelectRenderer
                        id={'ssipScopeCategories'}
                        onRemoveItem={(items, index) => {
                            setSsipScopeCategories(items);
                            props.onUpdate(
                                DTS_QUESTION_SSIP_SCOPE_CATEGORIES,
                                parseUpdatedSSIPScopeCategories(items)
                            );
                        }}
                        onItemSelect={(items, event) => {
                            setSsipScopeCategories(items);
                            props.onUpdate(
                                DTS_QUESTION_SSIP_SCOPE_CATEGORIES,
                                parseUpdatedSSIPScopeCategories(items)
                            );
                        }}
                        fill={true}
                        items={parseSSIPScopeCategories(
                            SSIP_SCOPE_CATEGORY_OPTIONS
                        )}
                        selectedItems={ssipScopeCategories}
                        valueProperty={'label'}
                        disabled={!isEditable}
                    />
                </FormGroup>
            </div>
        );
    };

    const servicesUndertaken = () => {
        return (
            <div className="Question">
                <FormGroup
                    label="Services Undertaken:"
                    inline={!isMobile}
                    className="form-fill question-label text required"
                >
                    <TextArea
                        id={`text_servicesUndertaken`}
                        name="servicesUndertaken"
                        fill={true}
                        minLength={1}
                        maxLength={3000}
                        defaultValue={getAnswer('servicesUndertaken')}
                        onChange={(event) =>
                            props.onUpdate(
                                DTS_QUESTION_SERVICES_UNDERTAKEN,
                                event.target.value
                            )
                        }
                        disabled={!isEditable}
                    />
                </FormGroup>
            </div>
        );
    };

    return (
        <>
            <QuestionErrors questionId={DTS_QUESTION_SSIP_SCHEME_OPERATOR} />
            {SSIPSchemeOperator()}
            <QuestionErrors questionId={DTS_QUESTION_CERTIFICATION_NUMBER} />
            {certificationNumber()}
            <QuestionErrors questionId={DTS_QUESTION_SSIP_SCOPE_CATEGORIES} />
            {SSIPScopeCategory()}
            <br />
            Please describe the services undertaken by your company. These
            should match the categories you were assessed against.
            <QuestionErrors questionId={DTS_QUESTION_SERVICES_UNDERTAKEN} />
            {servicesUndertaken()}
        </>
    );
};

export default AssessmentDTSQuestions;
