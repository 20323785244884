import { ListData } from './index';

import { AssessmentTypeData } from "./AssessmentType";
import { CompanyData } from "./Company";

export enum DiscountCodeStatus {
    EXPIRED = 0,
    LIVE = 1,
    FUTURE = 2,
}

export const DiscountCodeStatusMapping = {
    0: 'Expired',
    1: 'Live',
    2: 'Future'
}

export enum DiscountStatus {
    INACTIVE = 0,
    ACTIVE = 1
}

export const DiscountStatusMapping = {
    0: 'Inactive',
    1: 'Active'
}

const DISCOUNT_OPTIONS_ACTIVE = {label: 'Active', value: 1};
const DISCOUNT_OPTIONS_INACTIVE = {label: 'Inactive', value: 0};

export const DISCOUNT_STATUS_OPTIONS = [
    DISCOUNT_OPTIONS_ACTIVE,
    DISCOUNT_OPTIONS_INACTIVE,
];

export interface DiscountData {
    id: string,
    code: string,
    codeStatus: DiscountCodeStatus,
    startDate: Date,
    expiryDate: Date,
    assessments: AssessmentTypeData[],
    serviceProviders: CompanyData[],
    discount: number,
    usageLimit: number,
    timesUsed: number,
    usageRevenue: number,
    usageRevenueTax: number,
    usageRevenueIncludingTax: number,
    limitedUsage: boolean,
    isLimitedToSpecificServiceProviders: boolean,
    status: DiscountStatus,
}

export interface DiscountListData extends ListData {
    discounts: DiscountData[]
}

export interface DiscountFilter {
    order: string;
}
