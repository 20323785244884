import { Redirect, Switch } from 'react-router-dom';

import TaskList from 'components/company/TaskList';
import { ProtectedRoute } from 'components/elements';

const Tasks = (props: any) => {
    const { companyResponse, match } = props;

    return (
        <div className="page taskList">
            <div className="page-wrapper no-styling">
                <Switch>
                    <ProtectedRoute
                        path={`${match.url}/list`}
                        component={TaskList}
                        companyResponse={companyResponse}
                        title="Priority Tasks / Upcoming Tasks"
                        exact
                    />
                    <Redirect to={`${match.url}/list`} />
                </Switch>
            </div>
        </div>
    );
};

export default Tasks;
