import { Card, H2 } from '@blueprintjs/core';
import { DateTime } from 'luxon';

import { FileUpload, ButtonLink, Icon } from 'components/elements';
import { Response, NumberFormatting } from 'service';
import { INSURANCE_TYPES } from 'constants/insuranceTypes';
import "./styles.scss";

const InsuranceInfo = props => {
    const { insuranceDetails, match } = props;

    return <>
        <Card>
            <H2>
                Insurance Info

                {
                    Response.getLink(insuranceDetails, 'edit') === null ?
                        null :
                        (
                            <ButtonLink type="button"
                                to={`${match.url}/edit`}
                                intent="primary"
                                className="float-right">
                                <Icon icon="pencil" />
                                Edit
                            </ButtonLink>
                        )
                }

            </H2>

            <dl className="grid medium">
                <dt>Insurance Type</dt>
                <dd>{ INSURANCE_TYPES[insuranceDetails.insuranceType] }</dd>

                <dt>Policy Commencement Date</dt>
                <dd>{  DateTime
                        .fromISO(insuranceDetails.policyCommencementDate)
                        .toLocaleString(DateTime.DATE_MED)
                }</dd>

                <dt>Policy Expiry Date</dt>
                <dd>{  DateTime
                        .fromISO(insuranceDetails.policyExpiryDate)
                        .toLocaleString(DateTime.DATE_MED)
                }</dd>

                <dt>Indemnity Limit</dt>
                <dd>{ NumberFormatting.formatCurrency(insuranceDetails.indemnityLimit) }</dd>

                <dt>Insurer</dt>
                <dd>{ insuranceDetails.insurer }</dd>

                { insuranceDetails.exclusionsRestrictions ?
                    <>
                        <dt>Exclusions & Restrictions</dt>
                        <dd>{ insuranceDetails.exclusionsRestrictions }</dd>
                    </> : null
                }

                { insuranceDetails.comments ?
                    <>
                        <dt>Comments</dt>
                        <dd>{ insuranceDetails.comments }</dd>
                    </> : null
                }

                { insuranceDetails._links ?
                    <>
                        <dt>Certificate</dt>
                        <dd><FileUpload inputID="certificate"
                            isEditable={ false }
                            onUpdate={ () => {} }
                            onUpload={ () => {} }
                            response={ insuranceDetails }
                            responseLink="certificate"
                            uploadDataObject={ {} } />
                        </dd>
                    </> : null
                }
            </dl>
        </Card>
    </>;
}

export default InsuranceInfo;
