import React from 'react';
import { Redirect, Route, Switch } from "react-router-dom";

import ClientSearch from "components/supply-chains/client-search/ClientSearch";
import InvitationDetails from "components/supply-chains/InvitationDetails";
import InvitationPayment from "components/supply-chains/invitation/InvitationPayment";
import ThankYou from "components/elements/payment/ThankYou";
import { ProtectedRoute } from "components/elements";

const SupplyChains = (props) => {

    const getThankYouMessageContent = (
        <>
            <p>We will now redirect you back to the pending invitations.</p>
        </>
    );

    const getThankYouForwardUrl = '/supply-chains/pending';

    return <>
        <Switch>
            <ProtectedRoute path={'/supply-chains/thank-you'}
                            component={ ThankYou }
                            forwardUrl={ getThankYouForwardUrl }
                            messageContent={ getThankYouMessageContent }
            />
            <Route path={'/supply-chains/pending/:id/payment'} component={ InvitationPayment } />
            <Route path={'/supply-chains/pending/:id'} component={ InvitationDetails } />
            <Route path="/supply-chains/active" component={ ClientSearch } />
            <Route path="/supply-chains/pending" component={ ClientSearch } />
            <Redirect to="/supply-chains/active" />
        </Switch>
    </>
};

export default SupplyChains;
