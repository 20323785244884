import { NONE_SELECTED_OPTION } from 'constants/none-selected';

const BENEFIT_TYPES = {
    BARBER: 'Barbour Discount',
    FORTUS: 'Fortus Consultation & Discount',
    VINCI_WORKS: 'Vinci Works Free Online Training',
    FLEETMAXSOLUTIONS: 'Fleetmaxx Solutions Fuel Cards',
    WMCOMPLIANCE: 'William Martin Preferential Rates',
};

const BENEFIT_BARBER = { label: BENEFIT_TYPES.BARBER, value: 'barbour' };
const BENEFIT_FORTUS = { label: BENEFIT_TYPES.FORTUS, value: 'fortus' };
const BENEFIT_VINCI_WORKS = {
    label: BENEFIT_TYPES.VINCI_WORKS,
    value: 'vinciworks',
};
const BENEFIT_FLEETMAXSOLUTIONS = {
    label: BENEFIT_TYPES.FLEETMAXSOLUTIONS,
    value: 'fleetmaxxsolutions',
};
const BENEFIT_WMCOMPLIANCE = {
    label: BENEFIT_TYPES.WMCOMPLIANCE,
    value: 'wmcompliance',
};

const BENEFIT_OPTIONS_WITH_NONE_SELECTED = [
    NONE_SELECTED_OPTION,
    BENEFIT_BARBER,
    BENEFIT_FORTUS,
    BENEFIT_VINCI_WORKS,
    BENEFIT_FLEETMAXSOLUTIONS,
    BENEFIT_WMCOMPLIANCE,
];

export { BENEFIT_TYPES, BENEFIT_OPTIONS_WITH_NONE_SELECTED };
