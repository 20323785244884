import './index.scss';

interface ResultsCountProps {
    count?: number,
}

const ResultsCount = (props: ResultsCountProps) => {
    const { count } = props;

    if (!count) {
        return <span className='grey-text'>(0 Results)</span>;
    }

    return (
        <span className='grey-text'>
            {`(${count} Results)`}
        </span>
    );
}

export default ResultsCount;