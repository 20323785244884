import { FlatObject } from 'interface';
import MultiLevelSwitch from './MultiLevelSwitch';

interface Props {
    fields: FlatObject[];
    onUpdate: Function;
}

const MultiLevelSwitchArray = (props: Props) => {
    const { fields, onUpdate } = props;

    return (
        <>
            {!Array.isArray(fields)
                ? null
                : fields.map((field, index) => (
                    <MultiLevelSwitch
                        classNames="root "
                        key={'switch' + index}
                        field={field}
                        onUpdate={(field: FlatObject) => {
                            const updatedFields = [...fields];
                            updatedFields.splice(index, 1, field);
                            onUpdate(updatedFields);
                        }}
                    />
                ))
            }
        </>
    );
};
export default MultiLevelSwitchArray;
