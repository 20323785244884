import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { Button, FormGroup, H1, InputGroup } from '@blueprintjs/core';
import { HTTP, Response, StateHandler } from 'service';
import { ButtonLink, HTMLSelect, Icon, SuggestRenderer } from 'components/elements';
import { Loading } from 'components/elements/wrappers';
import { ACTIVE_OPTIONS } from 'constants/active';

class RegionAddEdit extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            form: {
                name: '',
                isActive: 'false',
            },
            id: props.match.params.id,
            isLoading: false,
            isLoadingParent: false,
            isLoadingRegions: false,
            regionCollection: {},
            response: {},
            selectedParent: {},
        };

        this.load = this.load.bind(this);
        this.loadRegions = this.loadRegions.bind(this);
        this.loadSelectedParent = this.loadSelectedParent.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount()
    {
        this.load();
    }

    handleSubmit(event)
    {
        event.stopPropagation();
        event.preventDefault();

        const { history } = this.props;

        if (this.state.id) {
            HTTP
                .put(Response.getLink(this.state.response, 'edit'), Object.assign({}, this.state.form))
                .then((response) => {
                    if (response) {
                        toast.success('Region updated');

                        return history.push(`/admin/regions/${this.state.id}`);
                    }

                    toast.error('Unable to update Region');

                    return false;
                });

            return;
        }

        HTTP
            .post('/regions/new', Object.assign({}, this.state.form))
            .then((response) => {
                if (response) {
                    toast.success('Region created');

                    return history.push(`/admin/regions/${response.data.id}`);
                }

                toast.error('Unable to create Region');

                return false;
            });
    }

    render()
    {
        const isEditing = typeof this.state.id !== 'undefined';
        const { form, isLoading, isLoadingParent, isLoadingRegions, selectedParent } = this.state;
        const regions = this.state.regionCollection.regions || [];

        return (
            <div className="RegionAddEdit">
                <H1 className="clearfix">
                    {
                        isEditing ?
                            'Edit Region' :
                            'Add new Region'
                    }
                </H1>

                <Loading isLoading={ isLoading || isLoadingParent || isLoadingRegions }>
                    <form className="AddEdit"
                        onSubmit={ this.handleSubmit }>
                        <FormGroup label="Name"
                            inline={true}
                            className="form-fill required"
                            labelFor="name">
                            <InputGroup id="name"
                                placeholder="Name"
                                minLength={1}
                                maxLength={75}
                                value={ form.name }
                                onChange={ e => this.setState({form: StateHandler.getStateObject(e, form)}) }
                                required />
                        </FormGroup>
                        <FormGroup label="Parent"
                            inline={true}
                            className="form-fill"
                            labelFor="parent">
                            <SuggestRenderer id="parent"
                                onItemSelect={ (item, event) => {
                                    let links = Object.assign({}, this.state.form._links, {parent: {href: Response.getLink(item, 'self')}});
                                    let form = Object.assign({}, this.state.form, {_links: links});

                                    this.setState({form: form});

                                    return;
                                } }
                                defaultItem={ selectedParent }
                                items={ regions }
                                isRequired={false} />
                        </FormGroup>
                        <FormGroup label="State"
                            inline={true}
                            className="form-fill"
                            labelFor="isActive">
                            <HTMLSelect id="isActive"
                                fill={true}
                                defaultValue={ form.isActive.toString() }
                                onChange={ e => this.setState({form: StateHandler.getStateObject(e, form)}) }
                                options={ ACTIVE_OPTIONS } />
                        </FormGroup>
                        <FormGroup>
                            <ButtonLink type="button"
                                intent="default"
                                className="float-left"
                                to={ isEditing ? `/admin/regions/${this.state.id}` : '/admin/regions' }>
                                <Icon icon="ban" />
                                Cancel
                            </ButtonLink>
                            <Button type="submit"
                                intent="primary"
                                className="float-right">
                                <Icon icon="paper-plane" />
                                { isEditing ? 'Update' : 'Create' }
                            </Button>
                        </FormGroup>
                    </form>
                </Loading>
            </div>
        );
    }

    load()
    {
        if (this.state.isLoading ||
            typeof this.state.id === 'undefined') {
            this.loadRegions();

            return;
        }

        this.setState({isLoading: true});

        HTTP
            .get(`/regions/${this.state.id}`)
            .then((response) => {
                if (response) {
                    this.setState({
                        form: Object.assign({}, response.data),
                        isLoading: false,
                        response: response.data,
                    }, () => {
                        this.loadRegions();

                        if (Response.getLink(response.data, 'parent')) {
                            this.loadSelectedParent(response.data);
                        }
                    });

                    return true;
                }

                toast.error('Unable to fetch Region details');
                this.setState({isLoading: false});

                return false;
            });
    }

    loadSelectedParent(regionResponse)
    {
        this.setState({isLoadingParent: true});

        HTTP
            .get(Response.getLink(regionResponse, 'parent'))
            .then((response) => {
                if (response) {
                    this.setState({isLoadingParent: false, selectedParent: response.data});

                    return true;
                }

                toast.error('Unable to fetch Parent');
                this.setState({isLoadingParent: false});

                return false;
            });
    }


    loadRegions()
    {
        if (this.state.isLoadingRegions) {
            return;
        }

        this.setState({isLoadingRegions: true});

        HTTP
            .get('/regions', {limit: 999})
            .then((response) => {
                if (response) {
                    this.setState({isLoadingRegions: false, regionCollection: response.data});

                    return true;
                }

                toast.error('Unable to load Regions');
                this.setState({isLoadingRegions: false});

                return false;
            });
    }
}

export default withRouter(RegionAddEdit);
