const DTS_QUESTION_SSIP_SCHEME_OPERATOR = 'SSIPSchemeOperator';
const DTS_QUESTION_CERTIFICATION_NUMBER = 'certificationNumber';
const DTS_QUESTION_SSIP_SCOPE_CATEGORIES = 'SSIPScopeCategories';
const DTS_QUESTION_SERVICES_UNDERTAKEN = 'servicesUndertaken';
const DTS_QUESTION_SSIP_LIST = [
    DTS_QUESTION_SSIP_SCHEME_OPERATOR,
    DTS_QUESTION_CERTIFICATION_NUMBER,
    DTS_QUESTION_SSIP_SCOPE_CATEGORIES,
    DTS_QUESTION_SERVICES_UNDERTAKEN,
]

export {
    DTS_QUESTION_SSIP_LIST,
    DTS_QUESTION_SSIP_SCHEME_OPERATOR,
    DTS_QUESTION_CERTIFICATION_NUMBER,
    DTS_QUESTION_SSIP_SCOPE_CATEGORIES,
    DTS_QUESTION_SERVICES_UNDERTAKEN,
}
