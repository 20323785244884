import {
    Button,
    Classes,
    Dialog,
    FormGroup,
    InputGroup,
    Intent,
} from '@blueprintjs/core';

import FileSingleUpload from 'components/elements/file-upload/FileSingleUpload';
import { HTMLSelect, InputElementIcon } from 'components/elements';
import { MAX_FILE_UPLOAD_SIZE_MB } from 'constants/general';
import { COUNTRY_CODE_OPTIONS } from 'constants/countries';
import './styles.scss';

const IssueInvoiceDialog = (props) => {
    const { form, onClose, isDialogOpen, onSubmithandler, setForm } = props;

    const emailLabel = (
        <>
            Invoice Email Address
            {InputElementIcon(
                `The email that the generated invoice will be sent to`,
                'info'
            )}
        </>
    );

    const fileLabel = (
        <>
            Purchase Order File
            {InputElementIcon(
                `Max file size ${MAX_FILE_UPLOAD_SIZE_MB}MB`,
                'info'
            )}
        </>
    );

    const onFileUpdate = (fileJson) => {
        setForm({
            ...form,
            ...fileJson,
        });
    };

    return (
        <Dialog
            className="dialog-form"
            onClose={onClose}
            title="Issue Invoice"
            autoFocus={true}
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            enforceClose={true}
            usePortal={true}
            isOpen={isDialogOpen}
        >
            <form
                className="dialog-form"
                onSubmit={(event) => onSubmithandler(event)}
            >
                <div className={Classes.DIALOG_BODY}>
                    <FormGroup
                        label="Invoice Contact Name"
                        inline={true}
                        className="form-fill required"
                        labelFor="invoiceContactName"
                    >
                        <InputGroup
                            id="invoiceContactName"
                            placeholder="Invoice Contact Name"
                            minLength={0}
                            min={0}
                            maxLength={75}
                            value={form.invoiceContactName}
                            required
                            onChange={(event) => {
                                setForm({
                                    ...form,
                                    invoiceContactName: event.target.value,
                                });
                            }}
                        />
                    </FormGroup>
                    <FormGroup
                        label={emailLabel}
                        labelFor="email"
                        inline={true}
                        className="form-fill required"
                    >
                        <InputGroup
                            id="email"
                            type="email"
                            placeholder="Invoice Email Address"
                            value={form.email}
                            required
                            onChange={(event) => {
                                setForm({
                                    ...form,
                                    email: event.target.value,
                                });
                            }}
                        />
                    </FormGroup>
                    <FormGroup
                        label="Invoice Address Line 1"
                        inline={true}
                        className="form-fill required"
                        labelFor="addressLine1"
                    >
                        <InputGroup
                            id="addressLine1"
                            fill={true}
                            maxLength={100}
                            required
                            placeholder="Invoice Address Line 1"
                            value={form.addressLine1}
                            onChange={(event) => {
                                setForm({
                                    ...form,
                                    addressLine1: event.target.value,
                                });
                            }}
                        />
                    </FormGroup>
                    <FormGroup
                        label="Invoice Address Line 2"
                        inline={true}
                        className="form-fill"
                        labelFor="addressLine2"
                    >
                        <InputGroup
                            id="addressLine2"
                            fill={true}
                            maxLength={100}
                            placeholder="Invoice Address Line 2"
                            value={form.addressLine2}
                            onChange={(event) => {
                                setForm({
                                    ...form,
                                    addressLine2: event.target.value,
                                });
                            }}
                        />
                    </FormGroup>
                    <FormGroup
                        label="Invoice Address Line 3"
                        inline={true}
                        className="form-fill"
                        labelFor="addressLine3"
                    >
                        <InputGroup
                            id="addressLine3"
                            fill={true}
                            maxLength={100}
                            placeholder="Invoice Address Line 3"
                            value={form.addressLine3}
                            onChange={(event) => {
                                setForm({
                                    ...form,
                                    addressLine3: event.target.value,
                                });
                            }}
                        />
                    </FormGroup>
                    <FormGroup
                        label="Invoice Address Town/City"
                        inline={true}
                        className="form-fill required"
                        labelFor="town"
                    >
                        <InputGroup
                            id="town"
                            fill={true}
                            required
                            maxLength={100}
                            placeholder="Invoice Address Town/City"
                            value={form.town}
                            onChange={(event) => {
                                setForm({
                                    ...form,
                                    town: event.target.value,
                                });
                            }}
                        />
                    </FormGroup>
                    <FormGroup
                        label="Invoice Address Post Code"
                        inline={true}
                        className="form-fill required"
                        labelFor="postCode"
                    >
                        <InputGroup
                            id="postCode"
                            fill={true}
                            required
                            maxLength={10}
                            placeholder="Invoice Address Post code"
                            value={form.postCode}
                            onChange={(event) => {
                                setForm({
                                    ...form,
                                    postCode: event.target.value,
                                });
                            }}
                        />
                    </FormGroup>
                    <FormGroup
                        inline={true}
                        label="Country"
                        labelFor="country"
                        className="form-fill required"
                    >
                        <HTMLSelect
                            id="country"
                            required
                            fill={true}
                            options={COUNTRY_CODE_OPTIONS}
                            defaultValue={form.country}
                            onChange={(event) => {
                                setForm({
                                    ...form,
                                    country: event.target.value,
                                });
                            }}
                        />
                    </FormGroup>
                    <FormGroup
                        label="Purchase Order Number"
                        inline={true}
                        className="form-fill required"
                        labelFor="purchaseOrderNumber"
                    >
                        <InputGroup
                            id="purchaseOrderNumber"
                            fill={true}
                            maxLength={100}
                            required
                            placeholder="Purchase Order Number"
                            value={form.purchaseOrderNumber}
                            onChange={(event) => {
                                setForm({
                                    ...form,
                                    purchaseOrderNumber: event.target.value,
                                });
                            }}
                        />
                    </FormGroup>
                    <FileSingleUpload
                        form={form}
                        fileId="purchaseOrderFile"
                        isImage={false}
                        fileLabel={fileLabel}
                        onUpdate={onFileUpdate}
                    ></FileSingleUpload>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className="dialog-footer-buttons">
                        <Button onClick={onClose}>Cancel</Button>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button type="submit" intent={Intent.SUCCESS}>
                                Issue Invoice
                            </Button>
                        </div>
                    </div>
                </div>
            </form>
        </Dialog>
    );
};

export default IssueInvoiceDialog;
