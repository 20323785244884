const routes = {
    admin_users: {
        href: '/admin/users',
        text: 'Users',
        icon: 'users',
    },
    admin_users_add: {
        href: '/admin/users/add',
        text: 'Add new User',
        icon: 'plus-circle',
    },
    admin_users_details: {
        href: '/admin/users/:id',
        text: ['{user.forename}', '{user.surname}'],
        icon: 'info',
    },
    admin_users_details_edit: {
        href: '/admin/users/:id/edit',
        text: 'Edit',
        icon: 'pencil',
    },
};

export default routes;
