import { FormEvent, useReducer } from 'react';
import { withRouter } from 'react-router';
import { isMobile } from 'react-device-detect';
import { toast } from 'react-toastify';
import { Button, FormGroup, H1, InputGroup, TextArea } from '@blueprintjs/core';
import { DateInput } from '@blueprintjs/datetime';
import { DateTime } from 'luxon';
import moment from 'moment';

import { File } from 'interface/File';
import { Response, Routing, StateHandler } from 'service';
import { Loading } from 'components/elements/wrappers';
import { ButtonLink, Icon, InputElementIcon } from 'components/elements';
import FileSingleUpload from 'components/elements/file-upload/FileSingleUpload';
import { MAX_FILE_UPLOAD_SIZE_MB } from 'constants/general';
import {
    addContract,
    editContract,
} from 'service/Client/CompanyContractService';

const ContractAddEdit = (props: any) => {
    const { companyResponse, contract, history, match, reloadData } = props;
    const isEdit = !!contract;
    const [form, setForm]: [
        form: any,
        setForm: React.ReducerWithoutAction<any>
    ] = useReducer(
        (form: any, edits: any) => {
            return { ...form, ...edits };
        },
        {
            name: '',
            comments: void 0,
            isLoading: false,
            _links: {
                company: {
                    href: Response.getLink(companyResponse, 'self'),
                },
            },
            ...contract,
            commencementDate: contract
                ? new Date(contract.commencementDate)
                : void 0,
            expiresAt: contract ? new Date(contract.expiresAt) : void 0,
        }
    );

    const updateFile = (fileJson: File) => {
        let updatedLinks = {
            ...form._links,
            ...fileJson._links,
        };
        setForm({
            _links: updatedLinks,
        });
    };

    let maxDate = DateTime.local().plus({ years: 5 }).toJSDate();

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        event.stopPropagation();
        setForm({ isLoading: true });

        if (contract) {
            await editContract(contract, form);
            toast.success('Certificate/Membership updated');
            Routing.navigateUpOneLevel(history, match);

            if (reloadData) {
                reloadData(form);
            }

            return true;
        }

        const response = await addContract(companyResponse, form);

        if (response) {
            toast.success('Certificate/Membership added.');
            Routing.navigateUpOneLevel(history, match);

            return true;
        }

        setForm({ isLoading: false });
    };

    return (
        <>
            <div className="contract-add-edit">
                <H1>{isEdit ? 'Edit Contract' : 'Add Contract'}</H1>
            </div>
            <Loading isLoading={form.isLoading}>
                <form onSubmit={handleSubmit} className="AddEdit">
                    <FormGroup
                        label="Contract Name"
                        inline={true}
                        className="form-fill required"
                        labelFor="name"
                    >
                        <InputGroup
                            id="name"
                            placeholder="Contract Name"
                            minLength={2}
                            maxLength={255}
                            value={form.name}
                            onChange={(e) =>
                                setForm(StateHandler.getStateObject(e, form))
                            }
                            required
                        />
                    </FormGroup>
                    <FormGroup
                        label="Commencement Date:"
                        inline={!isMobile}
                        className="form-fill choice required"
                    >
                        <DateInput
                            maxDate={maxDate}
                            parseDate={(str) => moment(str, 'DD/MM/YYYY').toDate()}
                            formatDate={(date) => DateTime.fromJSDate(date).toLocaleString()}
                            popoverProps={{ position: 'bottom', fill: true }}
                            inputProps={{
                                fill: true,
                                id: 'commencementDate',
                                rightElement: InputElementIcon(
                                    'Select a date...',
                                    'calendar-alt'
                                ),
                            }}
                            onChange={(newDate) => {
                                newDate = new Date(+newDate + 1000);
                                setForm({
                                    commencementDate: newDate,
                                });
                            }}
                            value={form.commencementDate}
                        />
                    </FormGroup>

                    <FormGroup
                        label="Expiry Date:"
                        inline={!isMobile}
                        className="form-fill choice required"
                    >
                        <DateInput
                            maxDate={maxDate}
                            parseDate={(str) => moment(str, 'DD/MM/YYYY').toDate()}
                            formatDate={(date) => DateTime.fromJSDate(date).toLocaleString()}
                            popoverProps={{ position: 'bottom', fill: true }}
                            inputProps={{
                                fill: true,
                                id: 'expiresAt',
                                rightElement: InputElementIcon(
                                    'Select a date...',
                                    'calendar-alt'
                                ),
                            }}
                            onChange={(newDate) => {
                                newDate = new Date(+newDate + 1000);
                                setForm({
                                    expiresAt: newDate,
                                });
                            }}
                            value={form.expiresAt}
                        />
                    </FormGroup>
                    <FormGroup
                        label="Comments"
                        inline={true}
                        className="form-fill"
                        labelFor="name"
                    >
                        <TextArea
                            id="comments"
                            placeholder="Comments (Optional)"
                            onChange={(e) =>
                                setForm(StateHandler.getStateObject(e, form))
                            }
                            minLength={1}
                            value={form.comments}
                        />
                    </FormGroup>

                    <FileSingleUpload
                        form={form}
                        fileId="file"
                        isMobile={isMobile}
                        isRequired={false}
                        fileLabel={
                            <>
                                File Upload
                                {InputElementIcon(
                                    `Max file size ${MAX_FILE_UPLOAD_SIZE_MB}MB`,
                                    'info'
                                )}
                            </>
                        }
                        onUpdate={updateFile}
                    ></FileSingleUpload>

                    <FormGroup>
                        <ButtonLink
                            type="button"
                            intent="default"
                            className="float-left"
                            to={Routing.getRouteUpOneLevel(history, match)}
                        >
                            <Icon icon="ban" />
                            Cancel
                        </ButtonLink>
                        <Button
                            type="submit"
                            intent="primary"
                            className="float-right"
                        >
                            <Icon icon="paper-plane" />
                            Submit
                        </Button>
                    </FormGroup>
                </form>
            </Loading>
        </>
    );
};

export default withRouter(ContractAddEdit);
