import "components/pre-auth/exhibition.scss";
import ButtonLink from 'components/elements/ButtonLink';

const ExhibitionSignUp = () => {
    return <section className="exhibition-signup-page">
        <section className="signup-card">
            <img src="/assets/images/logo.svg"
                alt="Prosure360 logo" />
            <ButtonLink type="submit"
                to="register-exhibitions"
                intent="primary"
                className="bp3-large">
                Sign up for free
            </ButtonLink>
        </section>
    </section>;
}

export default ExhibitionSignUp;
