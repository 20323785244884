import { H1 } from "@blueprintjs/core";
import List from "components/elements/List";
import { isAdmin, toMoney } from "helpers/helpers";
import { Company, FlatObject, ListCollection } from "interface";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ClientService, Response } from "service";

const SupplyChainInsuranceLevelsReport = () => {
    const storeClient = useSelector((state: any) => state?.root.client);
    const rootResponse = useSelector((state: any) => state.root.response);
    const [data, setData] = useState<ListCollection<Company>>({} as ListCollection<Company>);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const clientId = isAdmin()
        ? storeClient?.id
        : Response.getLinkAttribute(rootResponse, 'client', 'id');

    const defaultFilters: any = {
        order: 'registeredCompanyName',
    };

    const [filters, setFilters] = useState({
        order: defaultFilters.order,
    });

    const columns: FlatObject[] = [
        {
            name: 'Service Provider',
            property: 'registeredCompanyName',
            sortable: true,
            grow: 3,
        },
        {
            name: 'Contractor All Risk',
            type: 'callback',
            callback: (row: Company) => getInsuranceAmount(row, 'contractorAllRiskInsurance'),
            grow: 1,
        },
        {
            name: 'Employers Liability',
            type: 'callback',
            callback: (row: Company) => getInsuranceAmount(row, 'employersLiabilityInsurance'),
            grow: 1,
        },
        {
            name: 'Product Liability',
            type: 'callback',
            callback: (row: Company) => getInsuranceAmount(row, 'productsLiabilityInsurance'),
            grow: 1,
        },
        {
            name: 'Professional Indemnity',
            type: 'callback',
            callback: (row: Company) => getInsuranceAmount(row, 'professionalIndemnityInsurance'),
            grow: 1,
        },
        {
            name: 'Public Liability',
            type: 'callback',
            callback: (row: Company) => getInsuranceAmount(row, 'publicLiabilityInsurance'),
            grow: 1,
        },
    ];

    const getInsuranceAmount = (row: any, property: string) => {
        if (row[property]) {
            return toMoney(row[property]);
        }

        return 'No Insurance Provided';
    }

    const load = async (filters: any = {}) => {
        setFilters(filters);
        setIsLoading(true);

        const data = await ClientService.loadSupplyChainInsuranceLevelsReport(
            clientId,
            filters
        );

        setData(data);
        setIsLoading(false);
    }

    const onDownload = async () => {
        await ClientService.downloadSupplyChainInsuranceLevelsReport(clientId)
            .then(() => toast.success('Report Downloaded'))
            .catch(() => toast.error('Report could not be downloaded'));
    };

    return (
        <div className="SupplyChainInsuranceLevelsReport page">
            {clientId && clientId !== 'all-clients' && (
                <List
                    title="Supply Chain Insurance Levels Report"
                    columns={columns}
                    load={load}
                    collection={data}
                    filter={filters}
                    hideFilters
                    isLoading={isLoading}
                    downloadHandler={onDownload}
                />
            )}

            {(!clientId || clientId === 'all-clients') && isAdmin() && (
                <div>
                    <H1>Supply Chain Insurance Levels Report</H1>
                    <span>Please select a client</span>
                </div>
            )}
        </div>
    );
}

export default SupplyChainInsuranceLevelsReport;