import { Button, H1 } from '@blueprintjs/core';
import { useEffect, useReducer } from 'react';
import { useStore } from 'react-redux';
import { withRouter } from 'react-router';

import UploadTemplate from 'components/dialogs/UploadTemplate';
import { Table } from 'components/elements';
import type { CampaignComponentProps } from 'interface/Client/Campaign';

const CampaignInviteeUploadErrorsList = (props: CampaignComponentProps) => {
    const { campaign, history } = props;
    const store = useStore();
    const columns: object[] = [
        {
            name: 'Row Number',
            property: 'rowNumber',
            grow: 1,
        },
        { name: 'Invitee Name', property: 'inviteeName', grow: 2 },
        { name: 'Forename', property: 'forename', grow: 2 },
        { name: 'Surname', property: 'surname', grow: 2 },
        { name: 'Email Address', property: 'email', grow: 2 },
        { name: 'Telephone', property: 'telephone', grow: 2 },
        {
            name: 'Priority For Client',
            property: 'priority',
            grow: 2,
        },
        {
            name: 'Service Provider Category',
            property: '_links[sp-category]',
            grow: 2,
        },
    ];

    const defaultState = {
        errors: undefined,
        showUploadPopup: false,
    };

    useEffect(() => {
        setErrors();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [state, setState] = useReducer((state: any, edits: any) => {
        return { ...state, ...edits };
    }, defaultState);

    const setErrors = () => {
        const response = store.getState().auth.errors;
        if (response?.errors) {
            const errorsArray = Object.keys(response.errors).map((key: any) => {
                let row: any = translateData(response.data[key][0]);
                response.errors[key].forEach((rowObj: any) => {
                    row[rowObj['property_path']] = (
                        <>
                            <span>{row[rowObj['property_path']]}</span>
                            <br />
                            <span className="error">{rowObj['message']}</span>
                        </>
                    );
                });
                row['rowNumber'] = parseInt(key) + 1; // key is 0 indexed

                return row;
            });
            setState({ errors: errorsArray });
        }
    };

    const translateData = (row: any) => {
        return {
            inviteeName: row[0],
            forename: row[1],
            surname: row[2],
            email: row[3],
            telephone: row[4],
            priority: row[5],
            '_links[sp-category]': row[6],
        };
    };

    return (
        <div className="UploadErrorsList">
            <div className="flex-between">
                <H1>
                    File not uploaded. Errors detailed below. Please reupload
                    file with errors corrected.
                </H1>

                <Button
                    type="button"
                    intent="primary"
                    onClick={() => {
                        setState({ showUploadPopup: true });
                    }}
                    className="me-2"
                >
                    Upload Template
                </Button>
            </div>
            <Table data={state.errors} columns={columns} />
            <UploadTemplate
                campaign={campaign}
                isOpen={state.showUploadPopup}
                onClose={() => {
                    setState({ showUploadPopup: false });
                }}
                onSubmit={(errors: any) => {
                    if (!errors) {
                        history.push(
                            `/clients/campaigns/list/${campaign.id}/invitees`
                        );

                        return;
                    } else {
                        setTimeout(() => {
                            // for some bizzare reason a delay is needed here or the popup won't close
                            setState({ showUploadPopup: false });
                        }, 1);
                        setErrors();
                    }
                }}
            />
        </div>
    );
};

export default withRouter(CampaignInviteeUploadErrorsList);
