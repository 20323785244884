import { get } from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';

import { List, TooltipIcon } from 'components/elements';
import { SLA_STATUSES } from 'constants/assessmentTypeInstance';
import { YES_NO_OPTIONS } from 'constants/general';
import { NONE_SELECTED_OPTION } from 'constants/none-selected';
import {
    AssessmentTypeInstanceData,
    AssessmentTypeInstanceListFilter,
    AssessmentTypeInstanceListProps,
    ListSearchFilter,
} from 'interface';
import { AssessmentTypeService, HTTP, Response, UserService } from 'service';

import ActionButton from 'components/elements/action-button/ActionButton';
import RefundPaymentDialog from 'components/elements/payment/refund/RefundPaymentDialog';
import { isClientViewer } from 'helpers/helpers';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import './index.scss';

interface AssessmentCompletedListFilters {
    rootAssessment: ListSearchFilter;
    lastAssessedBy: ListSearchFilter;
    stickerPack?: ListSearchFilter;
}

const AssessmentTypeInstanceCompletedList = (
    props: AssessmentTypeInstanceListProps
) => {
    const {
        collection,
        defaultFilter,
        filter,
        isLoading,
        load,
        match,
    } = props;

    const filterSetup: AssessmentCompletedListFilters = {
        rootAssessment: {
            type: 'single-select',
            defaultValue: NONE_SELECTED_OPTION,
            label: 'Assessment',
            key: 'rootAssessment',
            options: [],
        },
        lastAssessedBy: {
            type: 'single-select',
            defaultValue: NONE_SELECTED_OPTION,
            label: 'Assessor',
            key: 'lastAssessedBy',
            options: [],
        },
    };

    const isClientUser = isClientViewer();

    if (isClientUser !== true) {
        filterSetup.stickerPack = {
            type: 'single-select',
            defaultValue: NONE_SELECTED_OPTION,
            label: 'Sticker Pack',
            key: 'stickerPack',
            options: [NONE_SELECTED_OPTION, ...YES_NO_OPTIONS],
        };
    }

    const history = useHistory();
    const [searchFilters, setSearchFilters] =useState<AssessmentCompletedListFilters>(filterSetup);
    const [refundAssessment, setRefundAssessment] = useState<AssessmentTypeInstanceData|null>(null);

    const getCompletedRoute = (row: AssessmentTypeInstanceData) => {
        return `${match.url}/${row.id}/view`;
    };

    const getCertificateRoute = (row: AssessmentTypeInstanceData) => {
        return Response.getLink(row, 'certificate');
    };

    const getCompanyRoute = (row: AssessmentTypeInstanceData) => {
        return `/service-providers/${Response.getLinkAttribute(
            row,
            'company',
            'id'
        )}`;
    };

    const shouldHideRefundButton = (row: AssessmentTypeInstanceData) => {
        return !Response.getLink(row, 'refund');
    };

    const companyName = (row: AssessmentTypeInstanceData) => {
        return Response.getLinkAttribute(
            row,
            'company',
            'registeredCompanyName'
        );
    };

    const defaultSpecificFilter: AssessmentTypeInstanceListFilter = {
        ...defaultFilter,
        order: '-completedAt',
        status: 'completed',
        clientOnly: false,
    };

    if (isClientUser === true) {
        defaultSpecificFilter.clientOnly = true;
    }

    const columns = [
        {
            name: 'ID',
            property: 'assessmentId',
            type: 'string',
            sortable: false,
            grow: 1,
        },
        {
            name: 'Service Provider',
            property: '_links.company.registeredCompanyName',
            type: 'nav-button',
            route: getCompanyRoute,
            buttonIntent: 'default',
            buttonClass: 'clear-button',
            buttonText: companyName,
            sortable: true,
            grow: 4,
        },
        {
            name: 'Assessment',
            property: '_links.assessment-type.name',
            sortable: false,
            grow: 2,
        },
        {
            name: 'Assessor',
            property: 'lastAssessedBy',
            sortable: false,
            grow: 2,
        },
        {
            name: 'Reviewer',
            type: 'callback',
            callback: (row: AssessmentTypeInstanceData) => {
                const value = get(row, 'lastReviewedBy');
                return value ? value : 'None';
            },
            sortable: false,
            grow: 2,
        },
        {
            name: 'Completed Date',
            type: 'datetime',
            property: 'completedAt',
            sortable: true,
            grow: 2,
        },
        {
            name: 'Expiry Date',
            type: 'datetime',
            property: 'expiryDate',
            sortable: true,
            grow: 2,
        },
        {
            name: 'Fast-Track',
            type: 'callback',
            property: 'isFastTrack',
            callback: (row: AssessmentTypeInstanceData) => {
                if (get(row, 'SLAStatus') === 'sla_not_applicable') {
                    return 'N/A';
                }

                return get(row, 'isFastTrack') === true ? 'Yes' : 'No';
            },
            sortable: false, // @todo P360-556, false always for now
            grow: 1,
        },
        {
            name: 'Assessor SLA',
            type: 'callback',
            property: 'SLADueAt',
            callback: (row: AssessmentTypeInstanceData) => {
                const SLAStatus = get(row, 'SLAStatus');

                if (SLAStatus === 'sla_not_applicable') {
                    return 'N/A';
                }

                const SLADueAt = get(row, 'SLADueAt');
                const ResolvedAt = get(row, 'SLAResolvedAt');

                if (SLADueAt !== undefined && SLAStatus !== undefined) {
                    let formattedDateTime = ResolvedAt ?? SLADueAt;

                    formattedDateTime = DateTime.fromISO(
                        formattedDateTime
                    ).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);

                    let slaClass =
                        SLAStatus === 'sla_met' || SLAStatus === 'sla_failed'
                            ? SLAStatus
                            : '';

                    return (
                        <span className={slaClass}>
                            {SLA_STATUSES[SLAStatus]} - {formattedDateTime}
                        </span>
                    );
                }
            },
            sortable: true,
            grow: 2,
        },
        {
            name: 'Sticker Pack',
            type: 'callback',
            callback: (row: AssessmentTypeInstanceData) => {
                const value = get(row, 'stickerPack');

                return value ? (
                    <>
                        {value}
                        {value === 'Yes' && getStickPackTooltip(row)}
                    </>
                ) : (
                    'tbc'
                );
            },
            sortable: false,
            grow: 2,
        },
        {
            name: '',
            type: 'callback',
            grow: 2,
            callback: (row: AssessmentTypeInstanceData) => {
                return (
                    <ActionButton
                        actions={[
                            {
                                text: "View Questions",
                                onClick: () => history.push(getCompletedRoute(row)),
                                hide: false,
                                icon: "eye"
                            },
                            {
                                text: "View Certificate",
                                onClick: () => HTTP.stream(getCertificateRoute(row)),
                                hide: !Boolean(getCertificateRoute(row)),
                                icon: "file-certificate"
                            },
                            {
                                text: "Refund",
                                onClick: () => setRefundAssessment(row),
                                hide: shouldHideRefundButton(row),
                                icon: 'pound-sign'
                            }
                        ]}
                    />
                );
            }
        },
    ];

    const getStickPackTooltip = (data: any) => {
        return (
            <TooltipIcon
                wrappingClass="padded-icon"
                text={
                    <section>
                        {data.title}
                        <br />
                        {data.firstName} {data.surname}
                        <br />
                        {data.registeredCompanyName}
                        <br />
                        {data.addressLine1}
                        <br />
                        {data.addressLine2}
                        <br />
                        {data.addressLine3}
                        <br />
                        {data.town}
                        <br />
                        {data.county}
                        <br />
                        {data.postCode}
                    </section>
                }
                icon="info"
            />
        );
    };

    useEffect(() => {
        (async () => {
            const copySearchFilters: AssessmentCompletedListFilters = {
                ...searchFilters,
            };

            copySearchFilters.rootAssessment.options =
                await AssessmentTypeService.getAssessmentTypeListForDropdown();

            copySearchFilters.lastAssessedBy.options =
                await UserService.getAssessorDropdown();

            setSearchFilters(copySearchFilters);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="page CompletedAssessmentsList">
            <div className="no-styling page-wrapper">
                <div className="AssessmentTypesList">
                    <List
                        title="Completed Assessments"
                        columns={columns}
                        load={(filter) =>
                            load({ ...defaultSpecificFilter, ...filter })
                        }
                        collection={collection}
                        filter={filter}
                        isLoading={isLoading}
                        filters={searchFilters}
                        defaultFilter={defaultSpecificFilter}
                    />
                    {refundAssessment && (
                        <RefundPaymentDialog
                            isOpen={Boolean(refundAssessment)}
                            assessmentType={refundAssessment}
                            reload={() =>load({ ...defaultSpecificFilter, ...filter })}
                            onSuccess={() => toast.success('Assessment successfully refunded')}
                            onClose={() => setRefundAssessment(null)}
                            showButton={false}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default AssessmentTypeInstanceCompletedList;
