import { Table } from 'components/elements';
import { FlatObject } from 'interface';

const RfaBalanceSheet = (props: { data?: FlatObject[] }) => {
    const columns = [
        {
            name: 'Accounting Date',
            property: 'account_date',
            type: 'date',
        },
        {
            name: 'Working Capital',
            property: 'working_capital',
            type: 'currency',
        },
    ];

    return (
        <div className="rfa-balance-sheet">
        {props.data?.length ? (
            <Table data={props.data} columns={columns} />
        ): "No data to display"}
        </div>
    );
};

export default RfaBalanceSheet;
