import { useRef } from 'react';
import { TextArea } from '@blueprintjs/core';
import './index.scss';

const AdditionalComment = (props) => {
    const { questionResponse, answerResponse, onUpdate, disabled } = props;
    const ref = useRef();

    if (
        !questionResponse.allowAdditionalComments &&
        !answerResponse?.additionalComments?.length
    ) {
        return null;
    }

    return (
        <section className="question-label optional-comment-wrapper">
            <button className='clear-button' onClick={() => ref.current.classList.toggle('show')}>
                Add Additional Comment
            </button>
            <section
                ref={ref}
                className={`optional-comment ${
                    answerResponse?.additionalComment?.length && 'show'
                }`}
            >
                <TextArea
                    id={`optional_comment_${questionResponse.id}`}
                    name={`optional-comment-${questionResponse.id}`}
                    fill={true}
                    defaultValue={answerResponse?.additionalComment}
                    onChange={(e) =>
                        typeof onUpdate === 'function' &&
                        onUpdate({ additionalComment: e.target.value })
                    }
                    disabled={disabled}
                />
            </section>
        </section>
    );
};

export default AdditionalComment;
