import { useEffect, useReducer } from 'react';
import { useSelector, useStore } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { H1 } from '@blueprintjs/core';
import List from 'components/elements/List';
import { isAdmin } from 'helpers/helpers';
import type { Company, FlatObject, ListCollection } from 'interface';
import { toast } from 'react-toastify';
import { ClientService, Response } from 'service';
import './index.scss';

const ActiveSupplyChainReport = (props: RouteComponentProps<any>) => {
    const defaultFilters = {
        order: 'registeredCompanyName',
    };
    const defaultState = {
        collection: {} as ListCollection<Company>,
        listFilter: defaultFilters,
        client: null,
    };

    const store = useStore();
    const storeClient = useSelector((state: any) => state?.root.client);

    const [state, setState]: [
        state: any,
        setState: React.ReducerWithoutAction<any>
    ] = useReducer(
        (state: any, edits: any) => ({ ...state, ...edits }),
        defaultState
    );

    const columns: FlatObject[] = [
        {
            name: 'Registered Company Name',
            property: 'registeredCompanyName',
            sortable: true,
            grow: 2,
        },
        {
            name: 'Trading Name',
            property: 'tradingName',
            sortable: true,
            grow: 2,
        },
        {
            name: 'Company Bio / Summary',
            property: 'companyBio',
            sortable: true,
            grow: 12,
        },
        {
            name: 'Companies House Registration Number',
            property: 'companiesHouseRegistrationNumber',
            sortable: true,
            grow: 2,
        },
        {
            name: 'Unique Tax Reference Number',
            property: 'uniqueTaxReferenceNumber',
            sortable: true,
            grow: 2,
        },
        { name: 'CIS Number', property: 'cisNumber', sortable: true, grow: 2 },
        { name: 'VAT Number', property: 'vatNumber', sortable: true, grow: 2 },
        {
            name: 'Web Address',
            property: 'webAddress',
            sortable: true,
            grow: 2,
        },
        {
            name: 'Phone Number',
            property: 'phone',
            sortable: true,
            grow: 2,
        },
        {
            name: 'Number of Direct Employees',
            property: 'directEmployees',
            sortable: true,
            grow: 2,
        },
        {
            name: 'Number of Bona Fide Sub Contractors',
            property: 'bonaFideSubContractors',
            sortable: true,
            grow: 2,
        },
        {
            name: 'Number of Labour Only Employees',
            property: 'labourOnly',
            sortable: true,
            grow: 2,
        },
        {
            name: 'Number of Effective Employees',
            property: 'organisationSize',
            sortable: true,
            grow: 2,
        },
        {
            name: 'Organisation Type',
            property: 'organisationType',
            sortable: true,
            grow: 2,
        },
        {
            name: 'Minimum Value of Contracts/ Works Undertaken (£) ',
            property: 'minWorkValue',
            sortable: true,
            grow: 2,
        },
        {
            name: 'Maximum Value of Contracts/ Works Undertaken (£)',
            property: 'maxWorkValue',
            sortable: true,
            grow: 2,
        },
        {
            name: 'Operate 24 Hours ?',
            property: 'is24hour',
            sortable: true,
            type: 'yesNo',
            grow: 2,
        },
        {
            name: 'To Deliver Your Services Do You Attend Client Sites',
            property: 'isServiceBySiteVisit',
            sortable: true,
            type: 'yesNo',
            grow: 2,
        },
        {
            name: 'Standard Payment Terms (Days)',
            property: 'standardPaymentDays',
            sortable: true,
            grow: 2,
        },
        {
            name: 'Prosure Company Rating',
            property: 'rating',
            sortable: true,
            grow: 2,
        },
        {
            name: 'Redflag Alert',
            property: 'rfaRating',
            sortable: true,
            grow: 2,
        },
    ];

    const getClient = async () => {
        const root = store.getState()?.root;
        if (root.client?.id === 'all-clients') {
            return null;
        }
        if (isAdmin()) {
            return root.client;
        } else {
            return await ClientService.loadClient(
                Response.getLinkAttribute(root.response, 'client', 'id')
            );
        }
    };

    const setClient = async () => {
        const client = await getClient();
        if (client?.id !== state.client?.id) {
            setState({ client });
        }
        if (!client) {
            setState(defaultState);
        }
    };

    useEffect(() => {
        setClient();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setClient();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storeClient]);

    const load = async (filters: any = {}) => {
        setState({ isLoading: true });
        const mergedFilters = {
            ...state.listFilter,
            ...filters,
        };
        const data = await ClientService.loadActiveSupplyChainReport(
            mergedFilters,
            state.client.id
        );
        setState({
            isLoading: false,
            collection: data,
            listFilter: mergedFilters,
        });
    };

    const onDownload = async () => {
        await ClientService.downloadActiveSupplyChainReport(
            state.listFilter,
            state.client.id
        )
            .then(() => toast.success('Report Downloaded'))
            .catch(() => toast.error('Report could not be downloaded'));
    };

    return (
        <div className="ActiveSupplyChainReport page">
            {state.client && (
                <List
                    title="Active Supply Chain Report"
                    columns={columns}
                    load={load}
                    collection={state.collection}
                    filter={state.listFilter}
                    hideFilters={true}
                    isLoading={state.isLoading}
                    downloadHandler={onDownload}
                />
            )}
            {!state.client && isAdmin() && (
                <div>
                    <H1>Active Supply Chain Report</H1>
                    <span>Please select a client</span>
                </div>
            )}
        </div>
    );
};

export default ActiveSupplyChainReport;
