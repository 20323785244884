import { Popover2, Classes as popoverClasses } from '@blueprintjs/popover2';
import { Button, Classes } from '@blueprintjs/core';
import { useEffect, useState } from 'react';

import { HTTP, Response } from 'service';
import { Icon } from 'components/elements';
import './accreditation.scss';

const ServicesBanner = (props) => {
    const { assessmentTypeInstance } = props;
    const [services, setServices] = useState();
    const [servicesCount, setServicesCount] = useState();

    useEffect(() => {
        if (assessmentTypeInstance) {
            loadServices();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assessmentTypeInstance]);

    const loadServices = async () => {
        let services;

        if(assessmentTypeInstance.metaData && assessmentTypeInstance.metaData.services) {
            setServicesCount(assessmentTypeInstance.metaData.services.length);
            services = Object.assign([], assessmentTypeInstance.metaData.services);
            services = formatServices(services, true);
        } else {
            const servicesRequest = await HTTP.get(
                `${Response.getLink(assessmentTypeInstance, 'company')}/services`,
                Object.assign({}, {
                    limit: 999,
                    order: 'treePath',
                })
            );
            services = formatServices(servicesRequest.data.services);
            setServicesCount(servicesRequest.data.count);
        }
        setServices(services);
    }

    const formatServices = (unformattedServices, isMetadata = false) => {
        let services = [];
        unformattedServices.map(service => {
            return {
                name: service.name,
                parent: isMetadata ? service.parent : Response.getLinkAttribute(service, 'parent', 'name'),
                children: []
            }
        }).forEach(service => {
            if (service.parent) {
                let parent = services.find(e => e.name === service.parent);
                if (!parent) {
                    parent = { name: service.parent, children: [] };
                    services.push(parent);
                }
                parent.children.push(service);
            }
            if (services.indexOf(service.name) !== -1) {
                services.push(service);
            }
        });

        return sortByName(services);
    }

    const sortByName = (services) => {
        services.sort((a,b) => {
            if (a.name > b.name) return 1;
            if (a.name < b.name) return -1;

            return 0;
        }).forEach(service => {
            sortByName(service.children);
        });

        return services;
    }

    const getServicesContent = () => {
        return <>
            <span>Services offered :</span>
            <div className="services-list">
                { servicesList(services) }
            </div>
        </>
    }

    const servicesList = (services) => {
        return services.length ? (
            <ul>
                {
                    services.map((service, index) => {
                        return (
                            <li key={ `service_${index}` }>
                                <span>{service.name}</span>
                                { servicesList(service.children) }
                            </li>
                        )
                    })
                }
            </ul>
        ) : null
    }

    const popoverProps = {
        canEscapeKeyClose: true,
        hasBackdrop: false,
        inheritDarkTheme: true,
        interactionKind: "hover",
        isControlled: false,
        minimal: false,
        modifiers: {
            arrow: { enabled: true },
            flip: { enabled: true },
            preventOverflow: { enabled: true },
        },
        placement: "auto",
        usePortal: true,
        popoverClassName: [popoverClasses.POPOVER2_CONTENT_SIZING, Classes.DARK].join(" "),
        boundary: document.body,
        enforceFocus: false
    };

    return <>
        { services && services.length ? (
                <div className="services-banner">
                    <Popover2
                        { ...popoverProps }
                        content={ getServicesContent() }
                    >
                        <Button className="icon-only"><Icon icon="info"/></Button>
                    </Popover2>
                    <span className="label">
                        { servicesCount }
                        { ['status_completed','status_completed_expired'].includes(assessmentTypeInstance?.status)?
                            ' service types were assessed against.'
                            : ` service types being assessed against. If these are incorrect,
                                it is the responsibility of the service provider to update
                                these within their profile.`
                        }
                    </span>
                </div>
            ) : null
        }
    </>
}

export default ServicesBanner;
