import React, { Component } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import Users from 'components/admin/users/Users';
import Services from 'components/admin/services/Services';
import Discounts from 'components/admin/discounts/Discounts';
import Integrations from 'components/admin/integrations/Integrations';
import AssessmentTypes from 'components/admin/assessment-types/AssessmentTypes';
import Reports from 'components/admin/reports/Reports';
import CertificationMemberships from 'components/admin/certification-memberships/CertificationMemberships';
import Questions from 'components/admin/questions/Questions';
import QuestionCategories from 'components/admin/question-categories/QuestionCategories';
import Regions from 'components/admin/regions/Regions';
import ProtectedRoute from 'components/elements/ProtectedRoute';
import Templates from 'components/admin/email/Templates';

class Admin extends Component {
    render() {
        return (
            <div className="Admin">
                <Switch>
                    <ProtectedRoute
                        path="/admin/assessment-types"
                        component={AssessmentTypes}
                    />
                    <ProtectedRoute
                        path="/admin/certification-memberships"
                        component={CertificationMemberships}
                    />
                    <ProtectedRoute
                        path="/admin/questions"
                        component={Questions}
                    />
                    <ProtectedRoute
                        path="/admin/question-categories"
                        component={QuestionCategories}
                    />
                    <ProtectedRoute path="/admin/regions" component={Regions} />
                    <ProtectedRoute
                        path="/admin/services"
                        component={Services}
                    />
                    <ProtectedRoute path="/admin/users" component={Users} />
                    <ProtectedRoute
                        path="/admin/discounts"
                        component={Discounts}
                    />
                    <ProtectedRoute
                        path="/admin/integrations"
                        component={Integrations}
                    />
                    <ProtectedRoute
                        path="/admin/email-templates"
                        component={Templates}
                    />
                    <ProtectedRoute path="/admin/reports" component={Reports} />
                    <Redirect to="/admin/assessment-types" />
                </Switch>
            </div>
        );
    }
}

export default Admin;
