import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormGroup, Button, Intent, Checkbox } from '@blueprintjs/core';
import moment from 'moment';
import { toast } from 'react-toastify';

import { Loading } from 'components/elements/wrappers';
import { ButtonLink, Icon } from 'components/elements';
import DeclineSupplyChainInvitationDialog from 'components/dialogs/DeclineSupplyChainInvititation';
import { updatingBreadcrumbResolves } from 'action/breadcrumb';
import { toMoney } from 'helpers/helpers';
import { HTTP, Response, NumberFormatting } from 'service';
import { clientInvitationCalculateTotal } from 'helpers/helpers';

import 'components/supply-chains/supply-chains.scss';
import './index.scss';

const InvitationDetails = (props) => {
    const dispatch = useDispatch();
    const { history } = props;
    const [isLoading, setIsLoading] = useState(true);
    const [invitation, setInvitation] = useState({
        total: 0.0,
        tax: 0.0,
        paymentRequired: false,
        client: {},
        categoryName: '',
        spCategory: {},
        supplyChain: {},
        filesRead: {},
        files: [],
    });

    useEffect(() => {
        (async () => {
            if (isLoading) {
                load();
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const load = async () => {
        try {
            const supplyChainResponse = await HTTP.get(
                `/supply-chain/${props.match.params.id}`,
                { datagroup: 'details' }
            );

            if (supplyChainResponse) {
                dispatch(
                    updatingBreadcrumbResolves({
                        client: { name: supplyChainResponse.data.client.name },
                    })
                );

                const categoryResponse = await HTTP.get(
                    Response.getLink(supplyChainResponse.data, 'sp-category'),
                    { datagroup: 'details' }
                );
                const paymentRequired =
                    categoryResponse.data.requiresClientAnnualFee ||
                    categoryResponse.data
                        .requiresProsureAnnualAdministrativeFee;

                const supplyChainFileUrl = Response.getLink(
                    supplyChainResponse.data,
                    'files'
                );

                let files = [];
                if (supplyChainFileUrl) {
                    await HTTP.get(supplyChainFileUrl)
                        .then((response) => {
                            files = response.data.files;
                        })
                        .catch((response) => {
                            toast.error(
                                'Failed to load files for the supply chain.'
                            );
                        });
                }

                const filesRead = {};
                files.forEach((key, index) => {
                    filesRead['document_' + index] = {
                        checked: false,
                        error: '',
                    };
                });

                const [total, tax] = clientInvitationCalculateTotal(
                    supplyChainResponse.data,
                    categoryResponse.data
                );

                setInvitation({
                    ...invitation,
                    supplyChain: supplyChainResponse.data,
                    spCategory: categoryResponse.data,
                    client: supplyChainResponse.data.client,
                    paymentRequired: paymentRequired,
                    files: files,
                    filesRead: filesRead,
                    total: total,
                    tax: tax,
                });

                setIsLoading(false);

                return true;
            }
        } catch (e) {
            toast.error('Unable to fetch Supply Chain invitation');

            return false;
        }

        return false;
    };

    const hasDocumentationBeenReadAndAccepted = () => {
        const readDocuments = [];
        const { filesRead } = invitation;

        for (const property in filesRead) {
            if (filesRead[property].checked === true) {
                readDocuments.push(property);
                filesRead[property].error = '';
            } else {
                filesRead[property].error =
                    'Please read and accept this document';
            }
        }
        setInvitation({ ...invitation, filesRead: filesRead });

        return invitation.files.length === readDocuments.length;
    };

    const acceptInvite = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        const response = await HTTP.post(
            Response.getLink(invitation.supplyChain, 'accept-invite')
        );

        if (
            !response ||
            (response && [200, 201].indexOf(response.code) !== -1)
        ) {
            toast.error(
                `Could not accept invite for  ${invitation.supplyChain._links.client.name}'s supply chain`
            );
            return;
        }

        const isDocumentsValid = hasDocumentationBeenReadAndAccepted();
        if (!isDocumentsValid) {
            toast.error('Please read and accept the documentation');
            return;
        }

        toast.success(
            `Accepted invite to  ${invitation.supplyChain._links.client.name}'s supply chain`
        );

        if (Response.getLink(response.data, 'assessment-instance')) {
            toast.success(
                `Client Assessment ${response.data._links['assessment-instance'].name} created`
            );
        }

        props.history.push('/supply-chains/active');
    };

    const purchaseInvite = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        const isDocumentsValid = hasDocumentationBeenReadAndAccepted();
        if (isDocumentsValid) {
            history.push(
                `/supply-chains/pending/${invitation.supplyChain.id}/payment`
            );
        } else {
            toast.error('Please read and accept the documentation');
        }
    };

    const clientImage = () => {
        return invitation.client._links ? (
            <img
                className="badge-img"
                alt={invitation.client._links.logo.title}
                title={invitation.client._links.logo.title}
                src={
                    process.env.REACT_APP_BASE_URL +
                    invitation.client._links.logo.publicUrl
                }
            />
        ) : null;
    };

    /**
     * Had a little issue putting isLoading and hasDocumentsLoaded into a conditional expression
     * @returns {boolean}
     */
    const hasDocumentsLoaded = () => {
        let result = true;

        if (Response.getLink(invitation.supplyChain, 'files')) {
            if (invitation.files.length === 0) {
                result = true;
            } else {
                result = isLoading ?? false;
            }
        } else {
            result = isLoading ?? false;
        }

        return result;
    };

    const handleDocumentCheckbox = (e) => {
        const invitationCopy = {
            ...invitation,
        };
        const file = {
            ...invitationCopy.filesRead[e.target.name],
            checked: !invitationCopy.filesRead[e.target.name].checked,
        };

        invitationCopy.filesRead = {
            ...invitationCopy.filesRead,
            [e.target.name]: file,
        };
        setInvitation(invitationCopy);
    };

    return (
        <div className="page InvitationDetails">
            <Loading isLoading={hasDocumentsLoaded()}>
                <div className="no-styling page-wrapper">
                    <div className="page-header">
                        <h1 className="page-title">
                            Invited to{' '}
                            {invitation.supplyChain.isRenewing === true
                                ? `renew entry to`
                                : `join`}{' '}
                            the Supply Chain of: {invitation.client.name}
                        </h1>
                    </div>
                    <div className="flex">
                        <div className="flex-1">{clientImage()}</div>
                        <div className="flex-5">
                            <h3 className="bp3-heading">
                                Date of{' '}
                                {invitation.supplyChain.isRenewing === true
                                    ? `renewal invitation`
                                    : `invitation`}{' '}
                                :{' '}
                                {moment(
                                    invitation.supplyChain.createdAt
                                ).format('Do MMMM YYYY')}
                            </h3>
                            <h3 className="bp3-heading">
                                Supply Chain Entry Requirements
                            </h3>
                            <p>
                                {
                                    invitation.client
                                        .entryRequirementsDescription
                                }
                            </p>
                            {invitation.spCategory && (
                                <>
                                    <h3 className="bp3-heading">
                                        You have Been Invited As
                                    </h3>
                                    <p>{invitation.spCategory.name}</p>
                                </>
                            )}
                            <h3 className="bp3-heading">
                                Assessment Requirements
                            </h3>
                            <p>
                                Assessments may be required to meet the
                                standards set by this client. If you do not
                                meet these requirements, you can still be
                                invited and join a client supply chain,
                                however you may be less likely to win work
                                with them. Both Prosure Pre-Qualified and
                                Prosure SSIP Verified assessments can be
                                purchased and completed at any point. Client
                                specific assessments become available upon
                                successful entry to the client supply chain.
                                Please note, assessment requirements may
                                change at any time.
                            </p>
                            <p>
                                The current assessment requirements of this
                                client are as follows:
                            </p>
                            <div className="invitation-requirements">
                                <p>
                                    <span>
                                        Requires Prosure Pre-Qualified:
                                    </span>
                                    {invitation.spCategory
                                        .requiresProsurePreQualified
                                        ? 'Yes'
                                        : 'No'}
                                </p>

                                <p>
                                    <span>Requires SSIP Verified:</span>
                                    {invitation.spCategory
                                        .requiresProsureSSIPVerified
                                        ? 'Yes'
                                        : 'No'}{' '}
                                    {invitation.spCategory
                                        .allowProsureDTS &&
                                        '(Allowing Prosure Deem-to-Satisfy)'}
                                </p>

                                <p>
                                    <span>
                                        Requires Client Specific
                                        Assessment(s):
                                    </span>
                                    {invitation.spCategory
                                        .requiresClientSpecificAssessment
                                        ? 'Yes'
                                        : 'No'}
                                </p>
                            </div>
                            <h3 className="bp3-heading">
                                Insurance Requirements
                            </h3>
                            <p>
                                Insurance indemnity limits may be required
                                to meet the standards set by this client. If
                                you do not meet these requirements, you can
                                still be invited and join a client supply
                                chain, however you may be less likely to win
                                work with them. Insurance details can be
                                added and updated on your company profile
                                page. Please note, assessment requirements
                                may change at any time
                            </p>
                            <p>
                                The current insurance requirements of this
                                client are as follows:
                            </p>
                            <div className="invitation-requirements">
                                <p>
                                    <span>
                                        Professional Indemnity Insurance:
                                    </span>
                                    {invitation.spCategory
                                        .professionalIndemnityInsurance
                                        ? NumberFormatting.formatCurrency(
                                                invitation.spCategory
                                                    .professionalIndemnityInsurance
                                            )
                                        : 'N/A'}
                                </p>

                                <p>
                                    <span>Public Liability Insurance:</span>
                                    {invitation.spCategory
                                        .publicLiabilityInsurance
                                        ? NumberFormatting.formatCurrency(
                                                invitation.spCategory
                                                    .publicLiabilityInsurance
                                            )
                                        : 'N/A'}
                                </p>

                                <p>
                                    <span>
                                        Employers Liability Insurance:
                                    </span>
                                    {invitation.spCategory
                                        .employersLiabilityInsurance
                                        ? NumberFormatting.formatCurrency(
                                                invitation.spCategory
                                                    .employersLiabilityInsurance
                                            )
                                        : 'N/A'}
                                </p>

                                <p>
                                    <span>
                                        Products Liability Insurance:
                                    </span>
                                    {invitation.spCategory
                                        .productsLiabilityInsurance
                                        ? NumberFormatting.formatCurrency(
                                                invitation.spCategory
                                                    .productsLiabilityInsurance
                                            )
                                        : 'N/A'}
                                </p>

                                <p>
                                    <span>
                                        Contractor All Risk Insurance:
                                    </span>
                                    {invitation.spCategory
                                        .contractorAllRiskInsurance
                                        ? NumberFormatting.formatCurrency(
                                                invitation.spCategory
                                                    .contractorAllRiskInsurance
                                            )
                                        : 'N/A'}
                                </p>
                            </div>
                            {invitation.files.length > 0 && (
                                <div className={'files-container'}>
                                    <h3 className="bp3-heading">
                                        Documentation To Read and Accept
                                    </h3>
                                    <p>
                                        The client has provided one or more
                                        documents for you to read and agree
                                        to, as part of joining their supply
                                        chain.
                                    </p>
                                    {invitation.files.map((file, index) => {
                                        return (
                                            <>
                                                {invitation.filesRead[
                                                    'document_' + index
                                                ].error.length > 0 && (
                                                    <div className="error-message">
                                                        {
                                                            invitation
                                                                .filesRead[
                                                                'document_' +
                                                                    index
                                                            ].error
                                                        }
                                                    </div>
                                                )}
                                                <FormGroup
                                                    className="required"
                                                    label="I have read and understood this document"
                                                    inline={true}
                                                    labelFor="documentRead"
                                                >
                                                    <Checkbox
                                                        name={
                                                            'document_' +
                                                            index
                                                        }
                                                        checked={
                                                            invitation
                                                                .filesRead[
                                                                'document_' +
                                                                    index
                                                            ].checked
                                                        }
                                                        onChange={(e) =>
                                                            handleDocumentCheckbox(
                                                                e
                                                            )
                                                        }
                                                        required
                                                    />
                                                    {file.title}
                                                    {Response.getLink(
                                                        file,
                                                        'download'
                                                    ) && (
                                                        <button
                                                            onClick={(
                                                                e
                                                            ) => {
                                                                e.stopPropagation();
                                                                e.preventDefault();
                                                                HTTP.stream(
                                                                    Response.getLink(
                                                                        file,
                                                                        'download'
                                                                    )
                                                                );
                                                            }}
                                                        >
                                                            <Icon
                                                                icon="download"
                                                                className="icon"
                                                            />
                                                            Download
                                                        </button>
                                                    )}
                                                </FormGroup>
                                            </>
                                        );
                                    })}
                                </div>
                            )}
                            {invitation.total > 0.0 && (
                                <h3 className="bp3-heading">Annual Fees</h3>
                            )}
                            {invitation.total > 0.0 && (
                                <p>
                                    To access this Supply Chain there is a
                                    joining fee. This fee will give you
                                    access to the Supply Chain for one-year
                                    from the joining date. Joining this
                                    Supply Chain will increase your
                                    visibility with this Client, and add you
                                    to their preferred list of Service
                                    Providers, however it is not a guarantee
                                    of any additional work. If you do not
                                    wish to join this Supply Chain, please
                                    click Decline.
                                </p>
                            )}
                            {invitation.total > 0.0 && (
                                <h3 className="bp3-heading">
                                    Annual Supply Chain Fee:
                                </h3>
                            )}
                            {invitation.total > 0.0 && (
                                <dl className="grid medium">
                                    <dt>Client Supply Chain Fee:</dt>
                                    <dd>{toMoney(invitation.total)}</dd>
                                    <dt>VAT:</dt>
                                    <dd>{toMoney(invitation.tax)}</dd>
                                    <dt>Total:</dt>
                                    <dd>{toMoney(invitation.total + invitation.tax)}</dd>
                                </dl>
                            )}
                            <FormGroup className="mt-5">
                                <ButtonLink to="/supply-chains/pending">
                                    Cancel
                                </ButtonLink>
                                <DeclineSupplyChainInvitationDialog 
                                    history={props.history}
                                    supplyChain={invitation.supplyChain}
                                />
                                {invitation.supplyChain._links &&
                                    Response.getLink(
                                        invitation.supplyChain,
                                        'accept-invite'
                                    ) && (
                                        <Button
                                            intent={Intent.SUCCESS}
                                            onClick={acceptInvite}
                                        >
                                            Accept
                                        </Button>
                                    )}
                                {invitation.supplyChain._links &&
                                    Response.getLink(
                                        invitation.supplyChain,
                                        'purchase'
                                    ) && (
                                        <Button
                                            intent={Intent.SUCCESS}
                                            onClick={purchaseInvite}
                                        >
                                            Buy Now
                                        </Button>
                                    )}
                            </FormGroup>
                        </div>
                    </div>
                </div>
            </Loading>
        </div>
    );
};

export default withRouter(InvitationDetails);
