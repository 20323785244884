import { Redirect, Switch } from 'react-router-dom';

import SupplyChainEdit from 'components/clients/supply-chain-config/SupplyChainEdit';
import SupplyChainInfo from 'components/clients/supply-chain-config/SupplyChainInfo';
import { Loading } from 'components/elements/wrappers';
import { Routing } from 'service';

const SupplyChainConfig = (props) => {
    const {
        component,
        computedMatch,
        history,
        location,
        match,
        path,
        staticContext,
        clientResponse,
        reloadData,
        ...rest
    } = props;

    return (
        <div className="SupplyChainConfig">
            <Loading isLoading={typeof clientResponse.id === 'undefined'}>
                <Switch>
                    <Routing.ProppedRoute
                        path={`${match.url}`}
                        component={SupplyChainInfo}
                        clientResponse={clientResponse}
                        clientId={clientResponse.id}
                        {...rest}
                        exact
                    />
                    <Routing.ProppedRoute
                        path={`${match.url}/edit`}
                        component={SupplyChainEdit}
                        clientResponse={clientResponse}
                        reloadData={reloadData}
                        clientId={clientResponse.id}
                        {...rest}
                        exact
                    />
                    <Redirect to={`${match.url}`} />
                </Switch>
            </Loading>
        </div>
    );
};

export default SupplyChainConfig;
