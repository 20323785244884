import { H1, PopoverPosition } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import queryString from 'query-string';
import { useEffect, useReducer } from 'react';
import { toast } from 'react-toastify';

import { Filters, Location, Response } from 'service';
import {
    CONTRACT_STATUSES,
    CONTRACT_STATUS_OPTIONS,
} from 'constants/ClientCompanyContract';
import { FlatObject, ContractListReducerState, ContractProps } from 'interface';
import { Filter, Loading, NoResultsFound } from 'components/elements/wrappers';
import { Icon, ItemCount, Paginator, Reload, Table } from 'components/elements';
import SingleSelect from 'components/elements/filter/SingleSelect';
import { loadContracts } from 'service/Client/CompanyContractService';

const ContractList = (props: ContractProps) => {
    const { companyResponse, match, history, location, filterData } = props;
    const defaultFilters = {
        order: '-expiry',
    };

    const [state, setState]: [
        state: ContractListReducerState,
        setState: React.ReducerWithoutAction<any>
    ] = useReducer(
        (state: ContractListReducerState, edits: any) => {
            return { ...state, ...edits };
        },
        {
            collection: {},
            isLoading: false,
            filterOpen: false,
            filter: queryString.parse(location.search),
            filterData: Filters.getFilterStateFromUrl(
                location.search.slice(1),
                filterData
            ),
        }
    );

    useEffect(() => {
        load();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getRoute = (row: FlatObject) => `${match.url}/${row.id}`;

    const getColourClass = (row: FlatObject) => {
        switch (row.status) {
            case 'ACTIVE':
                return 'success';
            case 'PENDING':
                return 'warning';
            case 'EXPIRED':
            default:
                return 'error';
        }
    };

    const clearFilters = () => {
        setState({ filterData: {} });
        onFilter({}, {});
    };

    const onFilter = (filters: FlatObject, filterData: FlatObject) => {
        delete filters.modifiers;
        load(Object.assign({}, defaultFilters, filters, { page: 1 }), true);
    };

    const load = async (
        params: FlatObject = {},
        paramsOverride: boolean = false
    ) => {
        if (state.isLoading) {
            return;
        }

        const { filter } = state;
        const filterParams = paramsOverride
            ? params
            : Object.assign({}, defaultFilters, filter, params);

        Location.setQueryStringFromObject(history, location, filterParams);

        setState({ filter: filterParams, isLoading: true });

        const collection = await loadContracts(companyResponse, filterParams);

        if (collection) {
            setState({
                collection: collection,
                isLoading: false,
            });
            return true;
        }
        toast.error('Unable to fetch Contracts');
        setState({ isLoading: false });
    };

    const columns = [
        {
            name: 'Client',
            type: 'text',
            property: 'clientName',
            grow: 2,
            hide: Boolean(!Response.getLink(companyResponse, 'view-contracts-admin')),
        },
        {
            name: 'Contract Name',
            type: 'text',
            property: 'name',
            grow: 2,
        },
        {
            name: 'Status',
            property: 'status',
            colourClass: getColourClass,
            type: 'mapping',
            mapping: CONTRACT_STATUSES,
            grow: 2,
        },
        {
            name: 'Commencement Date',
            property: 'commencementDate',
            type: 'date',
            sortable: false,
            grow: 2,
        },
        {
            name: 'Expiry Date',
            property: 'expiresAt',
            type: 'date',
            sortable: false,
            grow: 2,
        },
        { name: 'Comments', property: 'comments', type: 'text', grow: 2 },
        {
            name: '',
            property: '',
            type: 'nav-button',
            grow: 1,
            route: getRoute,
            buttonIntent: 'primary',
            buttonText: 'View',
            right: true,
        },
    ];

    return (
        <>
            <div className="contract-list">
                <p>List of contracts you have with this Service Provider.</p>
                <H1>
                    Contracts List
                    <ItemCount count={state.collection.count} />
                    <Reload load={load} text="Reload Contracts list" />
                    <Tooltip2
                        content={
                            !!state.filterOpen
                                ? 'Click to close filters'
                                : 'Click to open filters'
                        }
                        position={PopoverPosition.TOP}
                        className="ms-2"
                    >
                        <button
                            className={`clear-button ${
                                state.filterOpen && 'success'
                            }`}
                            onClick={() => {
                                setState({
                                    filterOpen: !state.filterOpen,
                                });
                            }}
                        >
                            <Icon icon="filter" className="icon" />
                        </button>
                    </Tooltip2>
                </H1>

                <Filter
                    onClear={clearFilters}
                    onFilter={(filters: FlatObject, filterData: FlatObject) =>
                        onFilter(filters, filterData)
                    }
                    filterData={state.filterData}
                    isOpen={state.filterOpen}
                >
                    <SingleSelect
                        defaultValue={state.filterData.currentOrExpired}
                        label="Status"
                        filterKey="currentOrExpired"
                        options={CONTRACT_STATUS_OPTIONS}
                    />
                </Filter>

                <Loading isLoading={state.isLoading}>
                    <NoResultsFound
                        label="No Contracts set"
                        count={state.collection.count}
                    >
                        <Table
                            data={state.collection.contracts}
                            columns={columns}
                            onSort={load}
                            ordering={state.filter.order}
                        />

                        <Paginator
                            page={state.collection.page}
                            count={state.collection.count}
                            limit={state.collection.limit}
                            onPage={load}
                        />
                    </NoResultsFound>
                </Loading>
            </div>
        </>
    );
};

export default ContractList;
