import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ClientData } from 'interface';
import { ClientService } from 'service';
import { loadClientELBSUpdatedAt } from 'service/Integration/IntegrationService';
import ServiceProvidersBelowRequirementsTable from './ServiceProvidersBelowRequirementsTable';
import SitesBelowRequirementsTable from './SitesBelowRequirementsTable';
import CAFMUpdateTime from './widgets/CAFMUpdateTime';
import CompletedJobs from './widgets/CompletedJobs';
import ProsureLinkedServiceProviders from './widgets/ProsureLinkedServiceProviders';

import { isAdmin } from 'helpers/helpers';
import './cafm-analytics.scss';

const ElogsCAFMAnalytics = () => {
    const [client, setClient] = useState<ClientData | null>(null);

    const root = useSelector((state: any) => state.root);
    const adminClient = root.client;
    const userClient = root.response._links?.client;
    const [isLoading, setIsLoading] = useState(false);

    const [updatedAt, setUpdatedAt] = useState<string | null>(null);

    useEffect(() => {
        loadClient();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [adminClient, userClient]);

    const loadClient = async () => {
        setIsLoading(true);
        setUpdatedAt(null);

        let clientValue = null;

        if (isAdmin() && adminClient?.id !== 'all-clients') {
            clientValue = adminClient;
        }

        if (!isAdmin()) {
            const client = await ClientService.loadClient(userClient.id);
            clientValue = client;
        }

        if (clientValue !== null) {
            setClient(clientValue);

            let updatedAtValue = await loadClientELBSUpdatedAt(clientValue.id);

            setUpdatedAt(updatedAtValue);
        }

        setIsLoading(false);
    };

    if (!client || client.id === 'all-clients') {
        return <h2>Please select a client...</h2>;
    }

    return (
        <div className="cafm-analytics-page">
            <div className="cafm-analytics-charts-header">
                {isLoading === false && (
                    <CAFMUpdateTime updatedAt={updatedAt} isLoading={false} />
                )}
            </div>

            {updatedAt !== null && isLoading === false && (
                <>
                    <div className="cafm-analytics-charts">
                        <ProsureLinkedServiceProviders clientId={client?.id} />
                        <CompletedJobs clientId={client?.id} />
                    </div>

                    <div className="cafm-analytics-tables">
                        <SitesBelowRequirementsTable clientId={client?.id} />
                        <ServiceProvidersBelowRequirementsTable
                            clientId={client?.id}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default ElogsCAFMAnalytics;
