import { Switch } from 'react-router-dom';

import ProtectedRoute from 'components/elements/ProtectedRoute';
import ContractList from './ContractList';
import ContractAddEdit from './ContractAddEdit';
import ContractDetails from './ContractDetails';

const Contracts = (props: any) => {
    const { companyResponse, match } = props;

    return <>
        <div className="client-company-contract">
            <Switch>
                <ProtectedRoute path={`${match.url}`} component={ ContractList } companyResponse={ companyResponse } exact />
                <ProtectedRoute path={`${match.url}/add`} component={ ContractAddEdit } companyResponse={ companyResponse } />
                <ProtectedRoute path={`${match.url}/:id`} component={ ContractDetails } companyResponse={ companyResponse } />
            </Switch>
        </div>
    </>;
}

export default Contracts;
