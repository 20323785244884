import { H2 } from '@blueprintjs/core';

import { AddressProps } from 'interface/Address';
import { COUNTRY_CODE_OPTIONS } from 'constants/countries';

const Address = (props: AddressProps) => {
    const { title, addressObject } = props;

    let invoiceCountry = '';

    if (addressObject.country) {
        let result = COUNTRY_CODE_OPTIONS.filter(
            (option) => option.value === addressObject.country
        );

        invoiceCountry = result[0].label;
    }

    return (
        <>
            <H2 className="clearfix">{title}</H2>

            <dl className="horizontal full">
                <dt>Address Line 1</dt>
                <dd>{addressObject.addressLine1}</dd>

                <dt>Address Line 2</dt>
                <dd>{addressObject.addressLine2}</dd>

                <dt>Address Line 3</dt>
                <dd>{addressObject.addressLine3}</dd>

                <dt>Town/City</dt>
                <dd>{addressObject.town}</dd>

                <dt>County/Region</dt>
                <dd>{addressObject.county}</dd>

                <dt>Postal/Zip Code</dt>
                <dd>{addressObject.postCode}</dd>

                <dt>Country</dt>
                <dd>{invoiceCountry}</dd>
            </dl>
        </>
    );
};

export default Address;
