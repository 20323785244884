import Icon from "components/elements/Icon";
import { canNavigateToRoute, getRouteKey } from "helpers/helpers";
import { memo, useEffect, useState } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import routes from "routes";

const ProtectedRouteLink = (props) => {
    const [isList, setIsList] = useState(true);

    useEffect(() => {
        //add or remove <li>
        if(typeof props.list === "boolean") {
            setIsList(props.list);
        }
    }, [props]);
    const hasCount = (typeof props.count !== 'undefined' && props.count > 0);

    const trimmedRoute = getRouteKey(props.route);
    const icon = props.icon || (routes[trimmedRoute] || {}).icon;
    const label = props.label || (routes[trimmedRoute] || {}).text;
    const className = "protectedLink " + (props.className || '') + (hasCount ? ' has-count ' : '');

    const routeItem = (
        <div className="protectedItem" onClick={ props.onClick }>
            { props.route ?
                <NavLink className={ className } to={ props.route }>
                    { !(label && label.length) && icon ? <Icon icon={icon} className="protectedLink-icon"/> : null }
                    <span className="protectedLink-name">{ label }</span>
                </NavLink> :
                <span className={ className }>
                    { !(label && label.length) && icon ? <Icon icon={icon} className="protectedLink-icon"/> : null }
                    <span className="protectedLink-name">{ label }</span>
                </span>
            }
            { hasCount && <span className='protectedLink-count'>{props.count}</span> }
        </div>
    );

    return !canNavigateToRoute(props.route, props.roleRestriction) ? null : (<>
        { !isList ? <>{ routeItem } { props.children }</> :
            <li>
                { routeItem } { props.children }
            </li>
        }
    </>);
}

export default withRouter(memo(ProtectedRouteLink));
