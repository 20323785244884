import { Table } from 'components/elements';
import { FlatObject } from 'interface';

const RfaProfitLoss = (props: { data?: FlatObject[] }) => {
    const columns = [
        {
            name: 'Accounting Date',
            property: 'account_date',
            type: 'date',
            grow: 1,
        },
        { name: 'Ebitda', property: 'ebitda', type: 'currency' },
        { name: 'Gross Profit', property: 'gross_profit', type: 'currency' },
        {
            name: 'Pre-Tax Profit',
            property: 'pre_tax_profit',
            type: 'currency',
        },
        {
            name: 'Post-Tax Profit',
            property: 'post_tax_profit',
            type: 'currency',
        },
    ];

    return (
        <div className="rfa-profit-loss">
            {props.data?.length ? (
                <Table data={props.data} columns={columns} />
            ) : (
                'No data to display'
            )}
        </div>
    );
};

export default RfaProfitLoss;
