import { ComponentProps, useEffect, useState } from 'react';

import { ButtonLink, Icon } from 'components/elements';
import List from 'components/elements/List';
import { ACTIVE_OPTIONS_WITH_NONE_SELECTED } from 'constants/active';
import { NONE_SELECTED_OPTION } from 'constants/none-selected';
import {
    AssessmentTypeData,
    AssessmentTypeListFilter,
    ListCollection,
    ListSearchFilter,
} from 'interface';
import { AssessmentTypeService, Response } from 'service';

interface AssessmentTypeListProps extends ComponentProps<any> {}

interface AssessmentTypeListSearchFilter {
    isActive: ListSearchFilter;
}

const AssessmentTypeList = (props: AssessmentTypeListProps) => {
    const { match } = props;
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [collection, setCollection] = useState<
        ListCollection<AssessmentTypeData>
    >({} as ListCollection<AssessmentTypeData>);
    const [listFilter, setListFilter] = useState<AssessmentTypeListFilter>({
        page: 1,
        limit: 8,
        isActive: true,
        order: 'name',
    });
    const searchFilters: AssessmentTypeListSearchFilter = {
        isActive: {
            type: 'single-select',
            defaultValue: NONE_SELECTED_OPTION,
            label: 'Active',
            key: 'isActive',
            options: ACTIVE_OPTIONS_WITH_NONE_SELECTED,
        },
    };

    const getRoute = (row: AssessmentTypeData) => {
        return '/admin/assessment-types/' + row.id;
    };

    const columns = [
        { name: 'Name', property: 'name', sortable: true, grow: 6 },
        {
            name: 'Prosure or Client Assessment',
            type: 'callback',
            grow: 5,
            callback: (row: AssessmentTypeData) => {
                return row._links.client
                    ? row._links.client.name
                    : 'Prosure Assessment';
            },
        },
        {
            name: 'State',
            type: 'callback',
            grow: 5,
            callback: (row: AssessmentTypeData) => {
                return row._links.client ? row._links.client.name : '';
            },
        },
        {
            name: '',
            property: '',
            type: 'nav-button',
            grow: 1,
            route: getRoute,
            buttonIntent: 'primary',
            buttonText: 'View',
            right: true,
        },
    ];

    const load = async (filter: AssessmentTypeListFilter) => {
        const copyFilter = { ...listFilter, ...filter };
        const assessmentTypes = await AssessmentTypeService.loadList(
            copyFilter
        );

        setCollection(assessmentTypes);
        setListFilter(copyFilter);
    };

    useEffect(() => {
        (async () => {
            if (isLoading) {
                await setIsLoading(false);
            }
        })();
    });

    const getHeaderButton = () => {
        return Response.getLink(collection, 'create') === null ? null : (
            <ButtonLink
                type="button"
                to={`${match.url}/add`}
                intent="primary"
                className="float-right"
            >
                <Icon icon="plus-circle" />
                Add new Assessment Type
            </ButtonLink>
        );
    };

    return (
        <div className="AssessmentTypesList">
            <List
                title="Assessment Type List"
                columns={columns}
                load={(filter) => load(filter as AssessmentTypeListFilter)}
                collection={collection}
                filter={listFilter}
                isLoading={isLoading}
                filters={searchFilters}
                headerButton={getHeaderButton}
            />
        </div>
    );
};

export default AssessmentTypeList;
