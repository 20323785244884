import { Button, Classes, H2, PopoverPosition } from '@blueprintjs/core';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { Link } from 'react-router-dom';

import { Popover2 } from '@blueprintjs/popover2';
import Badge from 'components/company/badge/Badge';
import CompanyRating from 'components/company/info/CompanyRating';
import RemoveServiceProvider from 'components/dialogs/RemoveServiceProvider';
import ClientRequirements from 'components/elements/company/client-requirement/ClientRequirements';
import SupplyChainDescription from 'components/elements/supply-chain/SupplyChainDescription';
import AddToSupplyChain from 'components/elements/supply-chain/add/AddToSupplyChain';
import { SHAPE_COMPANY_RESPONSE } from 'constants/company';
import {
    camelCase,
    hasCreditRatingImageAvailable,
    isAdmin,
    isClientAdmin,
} from 'helpers/helpers';
import { DECLINED_STATUS } from 'interface/SupplyChain';
import { Response } from 'service';

import Confirmation from 'components/dialogs/Confirmation';
import LinkElogsServiceProvider from 'components/dialogs/LinkElogsServiceProvider';
import { handleSubmitUnIntegration } from 'service/Integration/IntegrationService';
import './index.scss';

const CompanyCard = (props) => {
    const {
        company,
        clientResponse,
        showRequirements,
        selectedCategory,
        supplyChainStatus,
    } = props;
    const store = useStore();

    const [integrationCompanies, setIntegrationCompanies] = useState(
        company?.integrationCompanies
    );
    const [integration, setIntegration] = useState(false);

    const isSameClientName = useCallback(() => {
        return integrationCompanies.find(
            (clientSp) =>
                clientSp.client.toLowerCase() ===
                clientResponse?.elogsClientName?.toLowerCase()
        );
    }, [clientResponse, integrationCompanies]);

    const [supplyChain, setSupplyChain] = useState(
        company?.currentSupplyChainInstance
    );
    const hasClient =
        clientResponse || Response.getLink(company, 'supply-chain-add');

    const [showRemoveButton, setShowRemoveButton] = useState(
        !!Response.getLink(company, 'set-remove-when-expired')
    );

    const [
        showRemoveWhenExpiredConfirmation,
        setShowRemoveWhenExpiredConfirmation,
    ] = useState(false);

    const [openLinkElogsDialog, setOpenLinkElogsDialog] = useState(false);
    const [openUnlinkElogsDialog, setOpenUnlinkElogsDialog] = useState(false);

    const rating = Response.getLinkAttribute(company, 'rating', 'rating');
    const allRatings = company._links?.rating;

    useEffect(() => {
        setIntegration(isSameClientName());
    }, [isSameClientName]);

    const showRemoveWhenExpiredDialog = async (event) => {
        event.stopPropagation();
        event.preventDefault();

        setShowRemoveWhenExpiredConfirmation(true);
    };

    const showLinkElogsDialog = async (event) => {
        event.stopPropagation();
        event.preventDefault();

        setOpenLinkElogsDialog(true);
    };

    const showUnlinkElogsDialog = async (event) => {
        event.stopPropagation();
        event.preventDefault();

        setOpenUnlinkElogsDialog(true);
    };

    const onRemoveWhenExpired = async () => {
        setShowRemoveButton(false);
        setSupplyChain({
            ...supplyChain,
            expiredWhenRemoved: true,
        });
    };

    const logo = () =>
        company._links.logo ? (
            <div className="logo">
                <img
                    alt={company._links.logo.title}
                    title={company._links.logo.title}
                    src={
                        process.env.REACT_APP_BASE_URL +
                        company._links.logo.publicUrl
                    }
                ></img>
            </div>
        ) : (
            <div className="logo">
                <img
                    src="/assets/images/company-default.svg"
                    alt="No Company logo"
                    title="No Company logo"
                />
            </div>
        );

    const companyBadges = () => {
        return (
            company.badges &&
            company.badges.map((badge, key) => (
                <div key={'company-card-assessment-' + key}>
                    {badge._links.file ? <Badge badge={badge} /> : null}
                </div>
            ))
        );
    };
    const integrationBadge = () => {
        if (
            company?.requiresElogsCafmIntegration &&
            (isAdmin() || isClientAdmin())
        ) {
            if (integration) {
                return (
                    <button
                        className="clear-button"
                        onClick={showUnlinkElogsDialog}
                        key="badge-linked"
                    >
                        <img
                            className="logo"
                            key="company-card-elb-linked-logo"
                            alt="ELOGS"
                            title="This Service Provider has linked their account to your Elogs system. Click to un-link"
                            src="/assets/images/elb-logo-linked.svg"
                        />
                    </button>
                );
            } else {
                return (
                    <div key="badge-linked-ish">
                        <button
                            className={
                                !!integrationCompanies.length
                                    ? 'clear-button'
                                    : 'clear-button black-and-white'
                            }
                            onClick={showLinkElogsDialog}
                        >
                            <img
                                className="logo"
                                key="company-card-elb-logo"
                                alt="ELOGS"
                                title={
                                    !!integrationCompanies.length
                                        ? 'This Service Provider has linked their account to one or more Elogs systems, but not your Elogs system. Click to link'
                                        : 'This Service Provider is not linked to any Elogs systems. Click to link'
                                }
                                src="/assets/images/elb-logo.svg"
                            />
                        </button>
                    </div>
                );
            }
        } else {
            if (integration) {
                return (
                    <div key="badge-rating-linked">
                        <img
                            className="logo"
                            key="company-card-elb-linked-logo"
                            alt="ELOGS"
                            title="This Service Provider has linked their account to your Elogs system."
                            src="/assets/images/elb-logo-linked.svg"
                        />
                    </div>
                );
            } else {
                return (
                    <div
                        className={
                            !!integrationCompanies.length
                                ? ''
                                : ' black-and-white'
                        }
                        key="badge-half-linked"
                    >
                        <img
                            className="logo"
                            key="company-card-elb-logo"
                            alt="ELOGS"
                            title={
                                !!integrationCompanies.length
                                    ? 'This Service Provider has linked their account to one or more Elogs systems, but not your Elogs system.'
                                    : 'This Service Provider is not linked to any Elogs systems.'
                            }
                            src="/assets/images/elb-logo.svg"
                        />
                    </div>
                );
            }
        }
    };
    const rfaRatingBadge = () => {
        const rfaRating = Response.getLinkAttribute(
            company,
            'credit-health-check',
            'rating'
        );

        if (rfaRating && hasCreditRatingImageAvailable(rfaRating)) {
            return (
                <div className="rating" key="badge-rating">
                    <Popover2
                        placement={PopoverPosition.TOP}
                        interactionKind="hover"
                        hasBackdrop={false}
                        content={
                            <div className="red-flag-tooltip">
                                {`Red Flag Rating: ${rfaRating}`}
                            </div>
                        }
                    >
                        <div className="rfa-rating-logo">
                            <img
                                src={`/assets/images/redflag/${rfaRating}.png`}
                                alt={camelCase(rfaRating)}
                                title={camelCase(rfaRating)}
                            />
                        </div>
                    </Popover2>
                </div>
            );
        }
        return <></>;
    };

    const { currentSupplyChainInstance } = company;

    return (
        <>
            <RemoveServiceProvider
                company={company}
                isOpen={showRemoveWhenExpiredConfirmation}
                onYes={() => onRemoveWhenExpired()}
                onClose={(event, removeReason) => {
                    if (removeReason) {
                        const user = store.getState().root.response.user;
                        setSupplyChain({
                            ...supplyChain,
                            removedByForename: user.forename,
                            removedBySurname: user.surname,
                            removeReason,
                        });
                    }
                    setShowRemoveWhenExpiredConfirmation(false);
                }}
            />
            <LinkElogsServiceProvider
                company={company}
                client={clientResponse}
                isOpen={openLinkElogsDialog}
                onSubmit={(data) => {
                    const integrations = data.data['integrationsAdded'].map(
                        (row) => {
                            row.client = row.clientName;
                            return row;
                        }
                    );
                    setIntegrationCompanies([
                        ...integrationCompanies,
                        ...integrations,
                    ]);
                }}
                onClose={(event, data) => {
                    setOpenLinkElogsDialog(false);
                }}
            />
            <Confirmation
                isOpen={openUnlinkElogsDialog}
                onYes={() => {
                    handleSubmitUnIntegration(integration.id);
                    const integrations = integrationCompanies.filter(
                        (row) => row.id !== integration.id
                    );
                    setIntegrationCompanies(integrations);
                }}
                onClose={() => {
                    setOpenUnlinkElogsDialog(false);
                }}
            >
                This will remove the link between{' '}
                {company.registeredCompanyName} and Elogs.
            </Confirmation>

            <Link
                className={'company-card ' + Classes.CARD}
                to={'/service-providers/' + company.id}
                onDragStart={(e) => e.preventDefault()}
            >
                {logo()}
                <div className="company-info">
                    <div className="actions">
                        {showRequirements && (
                            <ClientRequirements
                                supplyChainStatus={supplyChainStatus}
                                company={company}
                                client={clientResponse}
                                supplyChain={
                                    company?.currentSupplyChainInstance
                                }
                                selectedCategory={
                                    selectedCategory
                                        ? selectedCategory
                                        : company.supplyChainServiceProviderCategory
                                }
                            />
                        )}
                        <AddToSupplyChain company={company} />
                    </div>

                    <H2
                        className={`company-heading ${
                            hasClient && ' client-heading'
                        }`}
                    >
                        <div className="company-heading-text">
                            <span>
                                {company.registeredCompanyName}
                                {company.tradingName &&
                                company.tradingName.length ? (
                                    <span>
                                        {' '}
                                        ( Trading name: {company.tradingName} )
                                    </span>
                                ) : null}
                                {company.currentSupplyChainInstance
                                    ?.isSuspended && (
                                    <span className="company-suspended-title">
                                        {' '}
                                        suspended
                                    </span>
                                )}
                            </span>
                        </div>
                        {!!company.isActive ? (
                            <img
                                className="icon small"
                                src="/assets/custom-icons/company-active.svg"
                                alt="icon"
                                title="active"
                            />
                        ) : (
                            <img
                                className="icon small"
                                src="/assets/custom-icons/company-inactive.svg"
                                alt="icon"
                                title="inactive"
                            />
                        )}
                    </H2>

                    <p>{company.companyBio}</p>

                    <SupplyChainDescription
                        supplyChain={supplyChain}
                        showRemoveButton={showRemoveButton}
                        removedWhenExpiredButton={
                            <Button
                                title="Remove When Expired"
                                small="true"
                                intent="primary"
                                icon="trash"
                                className="remove-when-expired"
                                onClick={(event) =>
                                    showRemoveWhenExpiredDialog(event)
                                }
                            ></Button>
                        }
                    />
                    {currentSupplyChainInstance?.status === DECLINED_STATUS ? (
                        <span className="error">Invitation Declined</span>
                    ) : null}

                    <div className="company-details">
                        <div className="badges">
                            <div className="company-card-rating-badge">
                                <CompanyRating
                                    badge
                                    rating={rating}
                                    allRatings={allRatings}
                                />
                            </div>
                            {rfaRatingBadge()}
                            {companyBadges()}
                            {integrationBadge()}
                        </div>
                        <span className="more-details">View details</span>
                    </div>
                </div>
            </Link>
        </>
    );
};

CompanyCard.propTypes = {
    company: PropTypes.shape(SHAPE_COMPANY_RESPONSE).isRequired,
};

export default CompanyCard;
