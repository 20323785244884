const ASSESSMENT_TYPE_VERSION_INACTIVE = 0;
const ASSESSMENT_TYPE_VERSION_ACTIVE = 1;
const ASSESSMENT_TYPE_VERSION_DRAFT = 2;

const ASSESSMENT_TYPE_VERSION_INACTIVE_TEXT = 'Inactive Version';
const ASSESSMENT_TYPE_VERSION_ACTIVE_TEXT = 'Active Version';
const ASSESSMENT_TYPE_VERSION__DRAFT_TEXT = 'Draft Version';

const ASSESSMENT_TYPE_VERSION_OPTIONS = {
    0: ASSESSMENT_TYPE_VERSION_INACTIVE_TEXT,
    1: ASSESSMENT_TYPE_VERSION_ACTIVE_TEXT,
    2: ASSESSMENT_TYPE_VERSION__DRAFT_TEXT,
};

const ASSESSMENT_TYPE_VERSION_KEYS = {
    0: 'inactive',
    1: 'active',
    2: 'draft',
};

export {
    ASSESSMENT_TYPE_VERSION_ACTIVE,
    ASSESSMENT_TYPE_VERSION_INACTIVE,
    ASSESSMENT_TYPE_VERSION_DRAFT,
    ASSESSMENT_TYPE_VERSION_OPTIONS,
    ASSESSMENT_TYPE_VERSION_KEYS,
};
