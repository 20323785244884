import { Droppable, Draggable } from "react-beautiful-dnd";
import { Card, Button } from '@blueprintjs/core';

import { Icon, } from 'components/elements';
import { NoResultsFound } from 'components/elements/wrappers';

const AssessmentTypeNestedQuestions = props => {
    const { topic, topicIndex, removeQuestionHandler } = props;

    const questions = topic.questions.map((question, index) => {
        return (
            <Draggable
              key={`${question.id}${index}`}
              draggableId={`droppable${topic.id}${index}`}
              type={topicIndex}
              index={index}>
                {(provided, snapshot) => (
                <div ref={provided.innerRef}
                    {...provided.draggableProps}>
                    <Card key={question.id}>
                        <span {...provided.dragHandleProps}>
                            <Icon icon="grip-vertical" />
                        </span>
                        { question.name }
                        <Button
                            small="true"
                            icon="trash"
                            intent="none"
                            minimal="true"
                            className="cardRemove"
                            onClick={() => removeQuestionHandler(index, topicIndex)}>
                        </Button>
                    </Card>
                </div>
                )}
            </Draggable>
        );
    });

    const droppable = (
        <Droppable droppableId={`droppable${topic.id}`} type={ topicIndex }>
            {(provided, snapshot) => (
                <div ref={provided.innerRef}>
                    { questions }
                    { provided.placeholder }
                </div>
            )}
        </Droppable>
    );

    return (
        <NoResultsFound count={ topic.questions.length }>
            { droppable }
        </NoResultsFound>
    );
};

export default AssessmentTypeNestedQuestions;
