import LogoUpload from 'components/elements/file-upload/LogoUpload';
import { CompanyData } from 'interface';
import { merge } from 'lodash';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { HTTP, Response } from 'service';
import DashboardCard from './DashboardCard';

import './dashboard-logo-card.scss';

interface DashboardLogoCardProps {
    isLoading: boolean;
    companyResponse: CompanyData;
}

const DashboardLogoCard = (props: DashboardLogoCardProps) => {
    const { isLoading, companyResponse } = props;
    const [isLogoLoading, setIsLogoLoading] = useState(false);
    const [isDefault, setIsDefault] = useState(true);
    const [fileUrl, setFileUrl]: any = useState();

    const buildUrl = (publicUrl: string) => {
        if (typeof publicUrl === 'string') {
            setIsDefault(false);
            return process.env.REACT_APP_BASE_URL + publicUrl;
        } else {
            return '/assets/images/company-default.svg';
        }
    };

    useEffect(() => {
        setFileUrl(
            buildUrl(
                Response.getLinkAttribute(companyResponse, 'logo', 'publicUrl')
            )
        );
    }, [companyResponse]);

    const setNewCompanyLogo = (newLogo: any) => {
        setIsLogoLoading(true);

        const updatedCompany = merge({}, companyResponse, {
            _links: { logo: newLogo },
        });

        HTTP.put(Response.getLink(companyResponse, 'edit'), updatedCompany)
            .then(() => {
                setFileUrl(buildUrl(newLogo.publicUrl));
                toast.success('Updated company logo');
            })
            .catch(() => toast.error('Could not update Company'))
            .finally(() => setIsLogoLoading(false));
    };

    return (
        <DashboardCard
            isLoading={!fileUrl || isLoading || isLogoLoading}
            title="Company Logo"
        >
            <div
                className={
                    `logo-card ` +
                    (isDefault ? 'default-logo' : 'not-default-logo')
                }
            >
                <LogoUpload
                    imageClass="image-card-logo"
                    loadingClass="loading-logo"
                    wrapperClass="logo-upload-wrapper"
                    showUploadingText={false}
                    defaultLogo={fileUrl}
                    onUpdate={setNewCompanyLogo}
                    uploadDataObject={{
                        _links: {
                            company: {
                                href: Response.getLink(companyResponse, 'self'),
                            },
                        },
                    }}
                />
            </div>
        </DashboardCard>
    );
};

export default DashboardLogoCard;
