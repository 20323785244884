const routes = {
    admin_regions: {
        href: '/admin/regions?order=name',
        text: 'Regions',
        icon: 'map-marker',
    },
    admin_regions_add: {
        href: '/admin/regions/add',
        text: 'Add new Region',
        icon: 'plus-circle',
        skipNavigation: true,
    },
    admin_region_details: {
        href: '/admin/regions/:id',
        text: '{region.name}',
    },
    admin_region_details_exit: {
        href: '/admin/regions/:id/edit',
        text: 'Edit',
        skipNavigation: true,
    }
};

export default routes;
