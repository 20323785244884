import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { Button, FormGroup, H1, InputGroup } from '@blueprintjs/core';
import { Data, HTTP, Response, StateHandler } from 'service';
import { Loading } from 'components/elements/wrappers';
import { ButtonLink, Icon } from 'components/elements';
import queryString from 'query-string';
import { findIndex } from 'lodash';

const mapStateToProps = (state) => {
    return {
        rootResponse: state.root.response,
    };
};
const mapDispatchToProps = {};

class CompanyServicesEdit extends Component
{
    static propTypes = {
        companyResponse: PropTypes.object.isRequired,
        mergeCompanyResponse: PropTypes.func,
    };

    static defaultProps = {};

    constructor(props)
    {
        super(props);

        this.state = {
            assignedCollection: {},
            availableCollection: {},
            data: {
                otherService: props.companyResponse.otherService,
            },
            filter: queryString.parse(props.location.search),
            id: props.companyResponse.id,
            isLoading: false,
        };

        this.load = this.load.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleItem = this.handleItem.bind(this);
    }

    componentDidMount()
    {
        this.load();
    }

    handleSubmit(event)
    {
        event.preventDefault();
        event.stopPropagation();

        const { companyResponse, history, match } = this.props;

        let data = {
            _links: {
                services: [],
            },
            otherService: this.state.data.otherService,
        };

        this.state.assignedCollection.services.forEach((service, index) => {
            data._links.services.push({
                href: Response.getLink(service, 'self'),
            });
        });

        HTTP
            .put(Response.getLink(companyResponse, 'services'), data)
            .then((response) => {
                if (response) {
                    toast.success('Services saved');

                    this.props.mergeCompanyResponse({otherService: data.otherService});

                    return history.push(match.url.substring(0, match.url.length - 5) + '?order=name');
                }

                toast.error('Unable to save Services');

                return false;
            })
    }

    getChildren(item)
    {
        let children = item.children || [];

        children.forEach((child) => {
            let lowerChildren = this.getChildren(child);

            lowerChildren.forEach((lowerChild) => {
                children.push(lowerChild);
            });
        });

        return children;
    }

    handleItem(item)
    {
        let { assignedCollection } = this.state;
        let index = findIndex(assignedCollection.services, {id: item.id});

        let children = this.getChildren(item);

        if (index === -1) {
            assignedCollection.services.push(item);
            children.forEach((child) => {
                let childIndex = findIndex(assignedCollection.services, {id: child.id});
                if (childIndex === -1) {
                    assignedCollection.services.push(child);
                }
            });
        } else if (index !== -1) {
            assignedCollection.services.splice(index, 1);

            children.forEach((child) => {
                let childIndex = findIndex(assignedCollection.services, {id: child.id});

                if (childIndex !== -1) {
                    assignedCollection.services.splice(childIndex, 1);
                }
            });

            if (Response.getLink(item, 'parent')) {
                let parentIndex = findIndex(assignedCollection.services, {_links: {self: {href: Response.getLink(item, 'parent')}}});

                if (parentIndex !== -1) {
                    assignedCollection.services.splice(parentIndex, 1);
                }
            }
        }

        this.setState({assignedCollection: {services: assignedCollection.services}});
    }

    getEntry(item)
    {
        const { assignedCollection } = this.state;

        let index = findIndex(assignedCollection.services, {id: item.id});
        let intent = index !== -1 ? 'warning' : 'primary';
        let text = index !== -1 ? 'Remove' : 'Add';

        return (
            <li key={ item.id }>
                { item.treePath }
                <Button type="button"
                    className="float-right"
                    intent={ intent }
                    onClick={ () => { this.handleItem(item); } }>
                    { text }
                </Button>
            </li>
        );
    }

    render()
    {
        const { availableCollection, isLoading, id } = this.state;
        const { services } = availableCollection;

        const hierarchicalServices = Data.convertFromFlatStructureToHierarchical(services);
        const flattenedHierarchy = Data.flattenHierarchy(hierarchicalServices);
        const itemEntries = flattenedHierarchy.map((item) => {
            return this.getEntry(item);
        });

        return (
            <>
                <div className="CompanyServicesEdit">
                    <H1>
                        Edit Services
                    </H1>

                    <Loading isLoading={ isLoading }>
                        <form className="AddEdit"
                            onSubmit={ event => this.handleSubmit(event) }>

                            <ul className="hierarchical-display">
                                { itemEntries }
                            </ul>

                            <FormGroup label="Other Service"
                                inline={true}
                                className="form-fill padded-left"
                                labelFor="otherService">
                                <InputGroup id="otherService"
                                    placeholder="Other Service"
                                    maxLength={255}
                                    value={ this.state.data.otherService }
                                    onChange={ e => this.setState({data: StateHandler.getStateObject(e, this.state.data)}) } />
                            </FormGroup>

                            <FormGroup>
                                <ButtonLink type="button"
                                    intent="default"
                                    className="float-left"
                                    to={ this.props.location.pathname.includes('service-providers') ? `/service-providers/${ id }/services` : '/company/services' }>
                                    <Icon icon="ban" />
                                    Cancel
                                </ButtonLink>
                                <Button type="submit"
                                    intent="primary"
                                    className="float-right">
                                    <Icon icon="paper-plane" />
                                    { 'Save' }
                                </Button>
                            </FormGroup>
                        </form>
                    </Loading>
                </div>
            </>
        );
    }

    load(params = {})
    {
        if (this.state.isLoading) {
            return true;
        }

        let filterParams = Object.assign({}, this.props.filter, this.state.filter, params);
        let queryStringFilterParams = queryString.stringify(filterParams)
        if ('?'+queryStringFilterParams !== this.props.location.search) {
            this.props.history.push({search: queryStringFilterParams});
        }

        this.setState({isLoading: true, filter: filterParams});
        const { companyResponse, rootResponse } = this.props;

        HTTP
            .getAll([
                [Response.getLink(companyResponse, 'services'), {order: 'name', limit: 999}],
                [Response.getLink(rootResponse, 'services'), {isActive: true, order: 'name', limit: 999}],
            ])
            .then((responses) => {
                if (responses[0] &&
                    responses[1]) {
                    this.setState({isLoading: false, assignedCollection: responses[0].data, availableCollection: responses[1].data});

                    return true;
                }

                toast.error('Unable to fetch Services');
                this.setState({isLoading: false});

                return false;
            });

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyServicesEdit);
