import { FormEvent, useState } from 'react';
import { Button, FormGroup } from '@blueprintjs/core';
import { isMobile } from 'react-device-detect';
import { Icon, PositiveNumericInput } from 'components/elements';
import AssessmentTypeService from 'service/AssessmentType/AssessmentTypeService';
import { AssessmentTypeData } from 'interface';

interface AssessmentTypeSLAsProps {
    assessmentType: AssessmentTypeData;
    mergeResponse: { (assessmentType: AssessmentTypeData): void };
}

const AssessmentTypeSLAs = (props: AssessmentTypeSLAsProps) => {
    const assessmentType: AssessmentTypeData = props.assessmentType;
    const [form, setForm] = useState(assessmentType.slas);

    const getAwaitingAssessmentLabel = () => {
        return (
            <>
                <p>
                    Moving status from <b>'Submitted - Awaiting Assessor'</b> to{' '}
                    <b>'Submitted - Returned with Feedback'</b>, or{' '}
                    <b>'Completed'</b>.
                </p>
                <p>
                    Requires an Assessor to take ownership of an assessment,
                    complete their assessment and pass back to the Service
                    Provider or mark as completed.
                </p>
                <p className="client-sla-green">Client SLA</p>
            </>
        );
    };

    return (
        <div className="AssessmentTypeSLAs">
            <form
                className={'AssessmentTypeSLASEdit'}
                onSubmit={(e: FormEvent<HTMLFormElement>) =>
                    AssessmentTypeService.submitSLASHandler(
                        e,
                        form,
                        assessmentType,
                        props.mergeResponse
                    )
                }
            >
                <FormGroup
                    label={getAwaitingAssessmentLabel()}
                    inline={!isMobile}
                    className="form-fill question-label text"
                >
                    <div className="positive-numeric-input-group">
                        <span className={'sla-input-label'}>Standard SLA</span>
                        <PositiveNumericInput
                            id="awaitingAssessmentStandard"
                            name="awaitingAssessmentStandard"
                            fill={false}
                            min={1}
                            value={form.awaitingAssessmentStandard}
                            handleValueChange={(number: number) => {
                                setForm({
                                    ...form,
                                    awaitingAssessmentStandard: number,
                                });
                            }}
                        />
                        <span className={'sla-input-label'}>Business Days</span>
                    </div>
                    <div className="positive-numeric-input-group">
                        <span className={'sla-input-label'}>
                            Fast-Track SLA
                        </span>
                        <PositiveNumericInput
                            id="awaitingAssessmentFastTrack"
                            name="awaitingAssessmentFastTrack"
                            fill={false}
                            min={1}
                            value={form.awaitingAssessmentFastTrack}
                            handleValueChange={(number: number) => {
                                setForm({
                                    ...form,
                                    awaitingAssessmentFastTrack: number,
                                });
                            }}
                        />
                        <span className={'sla-input-label'}>Business Days</span>
                    </div>
                </FormGroup>

                <FormGroup>
                    <Button
                        type="submit"
                        intent="primary"
                        className="float-right"
                    >
                        <Icon icon="paper-plane" />
                        Save
                    </Button>
                </FormGroup>
            </form>
        </div>
    );
};

export default AssessmentTypeSLAs;
