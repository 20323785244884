import PropTypes from 'prop-types';

const ABSTRACT_COLLECTION = {
    count: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    pages: PropTypes.number.isRequired,
};

export {
    ABSTRACT_COLLECTION,
};
