import React from 'react';
import AutocompleteBase from 'react-autocomplete';

class SuggestedComment extends AutocompleteBase {}

// Override the enter key listener to allow new lines in the input
SuggestedComment.keyDownHandlers.Enter = function Enter(event) {
    let _this7 = this;

    // Key code 229 is used for selecting items from character selectors (Pinyin, Kana, etc)
    if (event.key !== "Enter") return;
    // In case the user is currently hovering over the menu
    this.setIgnoreBlur(false);

    if (!this.isOpen()) {
        return;
    } else if (this.state.highlightedIndex == null) {
        // input has focus but no menu item is selected + enter is hit -> close the menu, highlight whatever's in input
        this.setState({
            isOpen: false
        }, function () {
            // This is line was commented out.
            //_this7.refs.input.select();
        });
    } else {
        // text entered + menu item has been highlighted + enter is hit -> update value to that of selected menu item, close the menu
        event.preventDefault();
        let item = this.getFilteredItems(this.props)[this.state.highlightedIndex];
        let value = this.props.getItemValue(item);
        this.setState({
            isOpen: false,
            highlightedIndex: null
        }, function () {
            _this7.refs.input.setSelectionRange(value.length, value.length);
            _this7.props.onSelect(value, item);
        });
    }
};

// Override default render to use textarea
SuggestedComment.defaultProps.renderInput = (props) => React.createElement('textarea', props);
SuggestedComment.defaultProps.shouldItemRender = (item, value) => item.label.toLowerCase().indexOf(value.toLowerCase()) > -1;
SuggestedComment.defaultProps.wrapperStyle = {
    flex: '1 1',
    marginLeft: '10px'
};
SuggestedComment.defaultProps.menuStyle = {
    borderRadius: '3px',
    boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
    background: 'rgba(255, 255, 255, 0.9)',
    padding: '0 0',
    fontSize: '90%',
    position: 'fixed',
    overflow: 'auto',
    maxHeight: '50%',
    zIndex: '10'
};
// Enforce blueprintJS form styles
SuggestedComment.defaultProps.inputProps = {
    className: 'bp3-fill bp3-input suggested-comment'
};
SuggestedComment.defaultProps.renderItem = (item, isHighlighted) => {
    return (
        <div key={item.label} style={{ background: isHighlighted ? 'lightgray' : 'white', lineHeight: '30px', paddingLeft: '10px', paddingRight: '10px' }}>
            {item.label}
        </div>
    );
};

export default SuggestedComment;
