import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link, Redirect, Switch } from 'react-router-dom';
import { Tab, Tabs } from '@blueprintjs/core';

import { updatingBreadcrumbResolves } from 'action/breadcrumb';
import { Icon, ProtectedRoute } from 'components/elements';
import { Loading } from 'components/elements/wrappers';
import { Routing } from 'service';
import { loadEmailTemplate } from 'service/Email/TemplateService';
import { EmailTemplateData } from 'interface/Email';
import TemplateAddEdit from './TemplateAddEdit';
import TemplateInfo from './TemplateInfo';

const TemplateDetails = (props: RouteComponentProps<any>) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [template, setTemplate] = useState<EmailTemplateData>({} as EmailTemplateData);
    const { match, location } = props;
    const tabId = Routing.getSelectedTabId(location.pathname, match.url);

    const getTemplate = async () => {
        const template = await loadEmailTemplate(props.match.params.id);

        if (template) {
            setTemplate(template);
        }
        return template;
    };

    const reload = () => {
        getTemplate();
    };

    const load = () => {
        (async () => {
            const template = await getTemplate();
            dispatch(updatingBreadcrumbResolves({ template: template }));
            setIsLoading(false);
        })();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(load, []);

    return (
        <div className="ServiceDetails">
            <Loading isLoading={isLoading}>
                <h1 className="clearfix">{template?.name}</h1>

                <Tabs renderActiveTabPanelOnly={true} selectedTabId={tabId}>
                    <Tab id={'/info'}>
                        <Link to={`${match.url}/info`}>
                            <Icon icon="info" />
                            Info
                        </Link>
                    </Tab>
                </Tabs>
                <div className="TabContent">
                    <Switch>
                        <ProtectedRoute
                            path={`${match.url}/info`}
                            component={TemplateInfo}
                            template={template}
                            exact
                        />
                        <ProtectedRoute
                            path={`${match.url}/info/edit`}
                            component={TemplateAddEdit}
                            template={template}
                            reload={reload}
                        />
                        <Redirect to={`${match.url}/info`} />
                    </Switch>
                </div>
            </Loading>
        </div>
    );
};

export default TemplateDetails;
