import {
    Button,
    FormGroup,
    H1,
    InputGroup,
    Radio,
    RadioGroup,
} from '@blueprintjs/core';
import { DateInput } from '@blueprintjs/datetime';
import { DateTime } from 'luxon';
import moment from 'moment';
import { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { isMobile } from 'react-device-detect';
import { toast } from 'react-toastify';

import {
    ButtonLink,
    FileUpload,
    HTMLSelect,
    Icon,
    InputElementIcon
} from 'components/elements';
import { Loading } from 'components/elements/wrappers';
import { SUBSCRIPTION_LEVELS } from 'constants/client';
import { NONE_SELECTED_OPTION } from 'constants/none-selected';
import { HTTP, Response, Routing, StateHandler } from 'service';

const SubscriptionAddEdit = (props) => {
    const { history, match, clientResponse, subscriptionDetails } = props;
    const [isLoading, setIsLoading] = useState(true);
    const entityName = 'Subscription';
    const defaultMinDate = DateTime.local(2021, 11, 1).toJSDate();
    const maxDate = DateTime.local().plus({ years: 10 }).toJSDate();

    const [form, setForm] = useState({
        validFrom: null,
        validUntil: null,
        chargeable: 'true',
        invoiceAmountExcludingTax: null,
        invoiceAmountTax: null,
        invoiceAmountIncludingTax: null,
        invoiceContactName: '',
        level: NONE_SELECTED_OPTION,
        email: '',
        _links: {
            contract: null,
        },
    });

    useEffect(() => {
        if (subscriptionDetails) {
            // if edit page, pre-load data and format so it plays nice
            let loadedSubscriptionDetails = {
                ...subscriptionDetails,
                validFrom: new Date(subscriptionDetails.validFrom),
                validUntil: new Date(subscriptionDetails.validUntil),
                chargeable: (
                    subscriptionDetails.chargeable === true
                ).toString(),
                level: subscriptionDetails.level
            };

            setForm({
                ...form,
                ...loadedSubscriptionDetails,
            });
        }

        setIsLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (subscriptionDetails) {
            try {
                const response = await HTTP.put(
                    Response.getLink(subscriptionDetails, 'edit'),
                    form
                );

                if (response) {
                    toast.success(`${entityName} updated`);
                    Routing.navigateUpOneLevel(history, match);

                    if (props.reloadData) {
                        props.reloadData(form);
                    }
                }
            } catch (error) {
                toast.error(`Unable to update ${entityName}`);
            }

            return;
        }

        try {
            const response = await HTTP.post(
                Response.getLink(clientResponse, 'add-subscription'),
                form
            );

            if (response) {
                toast.success(`${entityName} added.`);
                Routing.navigateUpOneLevel(history, match);
            }
        } catch (error) {
            toast.error(`Unable to add ${entityName}`);
        }
    };

    const handleDateInput = (field, date) => {
        if (date === null) {
            return;
        }

        let newDate = new Date(+date + 1000);

        let validUntil = form.validUntil;

        if (field === 'validFrom') {
            // reset validUntil if validFrom is set after this date
            validUntil =
                validUntil !== null && newDate >= validUntil
                    ? null
                    : validUntil;

            setForm({
                ...form,
                validFrom: newDate,
                validUntil: validUntil,
            });

            return;
        }

        setForm({
            ...form,
            validUntil: newDate,
        });
    };

    const handleCurrencyValue = (field, value) => {
        if (isNaN(value) || value < 1) {
            value = '';
        }

        setForm({
            ...form,
            [field]: value,
        });
    };

    const minValidUntilDate =
        form.validFrom !== null
            ? DateTime.fromJSDate(form.validFrom).plus({ days: 1 }).toJSDate()
            : defaultMinDate;

    return (
        <div className="insuranceAdd">
            <H1>{(subscriptionDetails ? 'Edit ' : 'Add ') + entityName}</H1>

            <Loading isLoading={isLoading}>
                <form className="AddEdit" onSubmit={(e) => handleSubmit(e)}>
                    <FormGroup
                        label="Valid From"
                        inline={!isMobile}
                        className="form-fill choice required"
                    >
                        <DateInput
                            id="validFrom"
                            name="validFrom"
                            minDate={defaultMinDate}
                            maxDate={maxDate}
                            parseDate={(str) => moment(str, 'DD/MM/YYYY').toDate()}
                            formatDate={(date) =>
                                DateTime.fromJSDate(date).toLocaleString()
                            }
                            popoverProps={{ position: 'bottom', fill: true }}
                            inputProps={{
                                fill: true,
                                id: 'validFrom',
                                required: true,
                                placeholder: 'Select Valid From date',
                                rightElement: InputElementIcon(
                                    'Select a date...',
                                    'calendar-alt'
                                ),
                            }}
                            onChange={(newDate) => {
                                handleDateInput('validFrom', newDate);
                            }}
                            required={true}
                            value={form.validFrom}
                        />
                    </FormGroup>

                    <FormGroup
                        label="Valid Until"
                        inline={!isMobile}
                        className="form-fill choice required"
                    >
                        <DateInput
                            id="validUntil"
                            name="validUntil"
                            minDate={minValidUntilDate}
                            maxDate={maxDate}
                            parseDate={(str) => moment(str, 'DD/MM/YYYY').toDate()}
                            formatDate={(date) =>
                                DateTime.fromJSDate(date).toLocaleString()
                            }
                            popoverProps={{ position: 'bottom', fill: true }}
                            inputProps={{
                                fill: true,
                                id: 'validUntil',
                                placeholder: 'Select Valid Until date',
                                required: true,
                                rightElement: InputElementIcon(
                                    'Select a date...',
                                    'calendar-alt'
                                ),
                            }}
                            onChange={(newDate) => {
                                handleDateInput('validUntil', newDate);
                            }}
                            required={true}
                            value={form.validUntil}
                        />
                    </FormGroup>

                    <FormGroup
                        label="Level"
                        inline={true}
                        className="form-fill required"
                        labelFor="level"
                    >
                        <HTMLSelect
                            id="level"
                            fill={true}
                            defaultValue={form.level}
                            onChange={(e) =>
                                setForm(
                                    StateHandler.getStateObject(e, form)
                                )
                            }
                            options={[NONE_SELECTED_OPTION, ...SUBSCRIPTION_LEVELS]}
                            required
                        />
                    </FormGroup>

                    <FormGroup
                        label="Chargeable"
                        inline={!isMobile}
                        className="form-fill required"
                    >
                        <RadioGroup
                            fill={true}
                            inline={true}
                            className="form-fill"
                            selectedValue={
                                ['boolean', 'string'].indexOf(
                                    typeof form.chargeable
                                ) !== -1
                                    ? form.chargeable.toString()
                                    : null
                            }
                            onChange={(e) =>
                                setForm(
                                    StateHandler.getStateObject(
                                        e,
                                        form,
                                        'chargeable'
                                    )
                                )
                            }
                            required
                        >
                            <Radio label="Yes" value={'true'} />
                            <Radio label="No" value={'false'} />
                        </RadioGroup>
                    </FormGroup>

                    {form.chargeable === 'true' && (
                        <>
                            <FormGroup
                                label="Invoice Amount Excluding Tax"
                                inline={true}
                                className="form-fill required"
                                labelFor="indemnityLimit"
                            >
                                <CurrencyInput
                                    id="invoiceAmountExcludingTax"
                                    allowNegativeValue={false}
                                    name="invoiceAmountExcludingTax"
                                    className="bp3-input"
                                    placeholder="Enter a number"
                                    maxLength={10}
                                    value={form.invoiceAmountExcludingTax}
                                    decimalsLimit={2}
                                    disableAbbreviations={true}
                                    step={1}
                                    required
                                    prefix="£"
                                    onValueChange={(value, name) =>
                                        handleCurrencyValue(
                                            `invoiceAmountExcludingTax`,
                                            value
                                        )
                                    }
                                />
                            </FormGroup>

                            <FormGroup
                                label="Invoice Amount Tax"
                                inline={true}
                                className="form-fill required"
                                labelFor="invoiceAmountTax"
                            >
                                <CurrencyInput
                                    id="invoiceAmountTax"
                                    allowNegativeValue={false}
                                    name="invoiceAmountTax"
                                    className="bp3-input"
                                    placeholder="Enter a number"
                                    maxLength={10}
                                    value={form.invoiceAmountTax}
                                    decimalsLimit={2}
                                    disableAbbreviations={true}
                                    step={1}
                                    required
                                    prefix="£"
                                    onValueChange={(value, name) =>
                                        handleCurrencyValue(
                                            `invoiceAmountTax`,
                                            value
                                        )
                                    }
                                />
                            </FormGroup>

                            <FormGroup
                                label="Invoice Amount Including Tax"
                                inline={true}
                                className="form-fill required"
                                labelFor="invoiceAmountIncludingTax"
                            >
                                <CurrencyInput
                                    id="invoiceAmountIncludingTax"
                                    allowNegativeValue={false}
                                    name="invoiceAmountIncludingTax"
                                    className="bp3-input"
                                    placeholder="Enter a number"
                                    maxLength={10}
                                    value={form.invoiceAmountIncludingTax}
                                    decimalsLimit={2}
                                    disableAbbreviations={true}
                                    step={1}
                                    required
                                    prefix="£"
                                    onValueChange={(value) =>
                                        handleCurrencyValue(
                                            `invoiceAmountIncludingTax`,
                                            value
                                        )
                                    }
                                />
                            </FormGroup>

                            <FormGroup
                                label="Invoice Contact Name"
                                inline={true}
                                className="form-fill"
                                labelFor="invoiceContactName"
                            >
                                <InputGroup
                                    id="invoiceContactName"
                                    placeholder="Invoice Contact Name"
                                    minLength={0}
                                    min={0}
                                    maxLength={75}
                                    value={form.invoiceContactName}
                                    onChange={(e) =>
                                        setForm(
                                            StateHandler.getStateObject(e, form)
                                        )
                                    }
                                />
                            </FormGroup>

                            <FormGroup
                                label="Invoice Email Address"
                                labelFor="email"
                                inline={true}
                                className="form-fill"
                            >
                                <InputGroup
                                    id="email"
                                    type="email"
                                    placeholder="Invoice Email Address"
                                    value={form.email}
                                    onChange={(e) =>
                                        setForm(
                                            StateHandler.getStateObject(e, form)
                                        )
                                    }
                                />
                            </FormGroup>
                        </>
                    )}
                    <FormGroup
                        label="Contract"
                        inline={!isMobile}
                        className="form-fill multi-inputs"
                        labelFor="contract"
                    >
                        <FileUpload
                            inputID="contract"
                            isEditable={true}
                            onUpdate={() => {
                                const copyForm = { ...form };
                                copyForm._links.contract = null;
                                setForm(copyForm);
                            }}
                            onUpload={(response) => {
                                const copyForm = { ...form };
                                copyForm._links.contract = {
                                    href: Response.getLink(
                                        response.data,
                                        'self'
                                    ),
                                    title: response.data.title,
                                    download: Response.getLink(
                                        response.data,
                                        'download'
                                    ),
                                };
                                setForm(copyForm);
                            }}
                            response={form}
                            responseLink="contract"
                            uploadDataObject={{}}
                        />
                    </FormGroup>

                    <FormGroup>
                        <ButtonLink
                            type="button"
                            intent="default"
                            className="float-left"
                            to={Routing.getRouteUpOneLevel(history, match)}
                        >
                            <Icon icon="ban" />
                            Cancel
                        </ButtonLink>
                        <Button
                            type="submit"
                            intent="primary"
                            className="float-right"
                        >
                            <Icon icon="paper-plane" />
                            {subscriptionDetails ? 'Update' : 'Create'}
                        </Button>
                    </FormGroup>
                </form>
            </Loading>
        </div>
    );
};

export default SubscriptionAddEdit;
