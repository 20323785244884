import { H1 } from '@blueprintjs/core';
import queryString from 'query-string';
import { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { ItemCount, Paginator, Reload, Table } from 'components/elements';
import { Filter, Loading, NoResultsFound } from 'components/elements/wrappers';
import {
    ACTIVE_OPTIONS,
    ACTIVE_OPTIONS_WITH_NONE_SELECTED,
} from 'constants/active';
import {
    ROLE_ADMINISTRATOR,
    ROLE_ASSESSMENT_ASSESSOR,
    ROLE_ASSESSMENT_REVIEWER,
    ROLE_COMPANY_ADMINISTRATOR,
    ROLE_PROSURE_CLIENT_SERVICES,
    ROLE_REGISTRATIONS_RENEWALS,
} from 'constants/role';
import { canNavigateWithRestriction } from 'helpers/helpers';
import { Filters, HTTP, Location, Response } from 'service';

const mapStateToProps = (state) => {
    return {
        rootResponse: state.root.response,
    };
};

class UserList extends Component {
    defaultFilters = {
        order: 'forename',
    };

    constructor(props) {
        super(props);

        this.state = {
            collectionResponse: {},
            filter: queryString.parse(props.location.search),
            filterData: Filters.getFilterStateFromUrl(
                this.props.location.search.slice(1),
                props.filterData
            ),
        };

        this.load = this.load.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.onFilter = this.onFilter.bind(this);
    }

    componentDidMount() {
        this.load();
    }

    render() {
        const collection = this.state.collectionResponse || {};
        const { isLoading } = this.state;
        const { companyResponse } = this.props;

        const columns = [
            { name: 'Forename', property: 'forename', sortable: true, grow: 2 },
            { name: 'Surname', property: 'surname', sortable: true, grow: 2 },
            {
                name: 'Email',
                property: 'email',
                sortable: true,
                grow: 2,
            },
            {
                name: 'Status',
                property: 'isActive',
                type: 'mapping',
                mapping: ACTIVE_OPTIONS,
                sortable: true,
                grow: 2,
            },
            {
                name: 'Last Login Date',
                property: 'lastSuccessfulLogin',
                type: 'datetime',
                sortable: false,
                grow: 4,
            },
            {
                name: '',
                property: '',
                type: 'button',
                grow: 1,
                intent: 'warning',
                right: true,
                text: () => 'Remove',
                icon: 'ban',
                onClick: (event, row) => {
                    HTTP.post(
                        Response.getLink(companyResponse, 'remove-user'),
                        {
                            _links: {
                                user: { href: Response.getLink(row, 'self') },
                            },
                        }
                    ).then((response) => {
                        if (response) {
                            toast.success('User removed from Company.');
                            this.load();
                            !!this.props.reloadData && this.props.reloadData();

                            return true;
                        }

                        toast.error('Unable to remove User from Company.');

                        return false;
                    });
                },
            },
        ];

        return (
            <div className="UserList">
                <H1>
                    User List
                    <ItemCount count={collection.count} />
                    <Reload load={this.load} />
                </H1>

                <Filter
                    onClear={this.clearFilters}
                    onFilter={(filters, filterData) =>
                        this.onFilter(filters, filterData)
                    }
                    filterData={{}}
                >
                    <Filter.Text label="Forename" filterKey="forename" />
                    <Filter.Text label="Surname" filterKey="surname" />
                    <Filter.Text label="Email Address" filterKey="email" />
                    <Filter.SingleSelect
                        label="Status"
                        filterKey="isActive"
                        options={ACTIVE_OPTIONS_WITH_NONE_SELECTED}
                    />
                </Filter>

                <Loading isLoading={isLoading}>
                    <NoResultsFound count={collection.count}>
                        <Table
                            data={collection.users}
                            columns={columns}
                            onSort={this.load}
                            ordering={this.state.filter.order}
                        />

                        <Paginator
                            page={collection.page}
                            count={collection.count}
                            limit={collection.limit}
                            onPage={this.load}
                        />
                    </NoResultsFound>
                </Loading>
            </div>
        );
    }

    clearFilters() {
        this.setState({ filterData: {} });
        this.onFilter({}, {});
    }

    onFilter(filters, filterData) {
        this.load(
            Object.assign({}, this.defaultFilters, filterData, { page: 1 }),
            true
        );
    }

    load(params = {}, paramsOverride = false) {
        if (this.state.isLoading) {
            return;
        }

        const { companyResponse, history, location } = this.props;
        const { filter } = this.state;

        const filterParams = paramsOverride
            ? params
            : Object.assign({}, this.defaultFilters, filter, params);
        Location.setQueryStringFromObject(history, location, filterParams);

        this.setState({ filter: filterParams, isLoading: true });

        HTTP.get(
            Response.getLink(companyResponse, 'users'),
            Filters.convertFilterDataToRequestData(filterParams)
        ).then((response) => {
            if (response) {
                const collection = response.data;
                collection.users.forEach((user) => {
                    if (
                        user.id === this.props.rootResponse.user.id ||
                        !canNavigateWithRestriction([
                            ROLE_ADMINISTRATOR,
                            ROLE_PROSURE_CLIENT_SERVICES,
                            ROLE_ASSESSMENT_ASSESSOR,
                            ROLE_ASSESSMENT_REVIEWER,
                            ROLE_COMPANY_ADMINISTRATOR,
                            ROLE_REGISTRATIONS_RENEWALS,
                        ])
                    ) {
                        user.hide = true;
                    }
                });

                this.setState({
                    collectionResponse: collection,
                    isLoading: false,
                });

                return true;
            }

            toast.error('Unable to fetch Users');
            this.setState({ isLoading: false });

            return false;
        });
    }
}

export default connect(mapStateToProps, null)(UserList);
