import { useEffect, MouseEvent, useState, ComponentProps } from 'react';

import './index.scss';

interface VerticalTabProps extends ComponentProps<any> {
    title: string;
    subtitle?: string;
    errorMessage?: string;
    warningMessage?: string;
    successMessage?: string;
    open?: boolean;
    onOpenChange?: Function;
}

const VerticalTab = (props: VerticalTabProps) => {
    let {
        title,
        subtitle,
        errorMessage,
        warningMessage,
        successMessage,
        open,
        children,
    } = props;
    const [isOpen, setIsOpen] = useState(open);
    useEffect(() => {
        setIsOpen(open);
    }, [open]);

    const onOpenChange = (event: MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
        const change = !isOpen;
        setIsOpen(change);
        typeof props.onOpenChange === 'function' && props.onOpenChange(change);
    };

    return (
        <div className={`vertical-tab ${isOpen && 'open'}`}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
                className="header-section flex-v-center"
                href="#"
                onClick={onOpenChange}
            >
                <section className="title-section">
                    <h2 className="title">{title}</h2>
                    <span className="subtitle">{subtitle}</span>
                    <span className="success">{successMessage}</span>
                    <span className="warning">{warningMessage}</span>
                    <span className="error">{errorMessage}</span>
                </section>
                <section className="actions">Expand / Collapse</section>
            </a>
            <section className="body-section">{children}</section>
        </div>
    );
};

export default VerticalTab;
