const COMMENT_TYPE_INTERNAL = 0;
const COMMENT_TYPE_SP = 1;
const COMMENT_TYPE_FEEDBACK = 2;

const COMMENT_TYPE_INTERNAL_TEXT = 'Internal Comments';
const COMMENT_TYPE_SP_TEXT = 'Comments to Service Provider';
const COMMENT_TYPE_FEEDBACK_TEXT = 'Reviewer Feedback on Assessment Process';
const COMMENT_TYPE_OPTIONS = [
    { label: COMMENT_TYPE_INTERNAL_TEXT, value: COMMENT_TYPE_INTERNAL },
    { label: COMMENT_TYPE_SP_TEXT, value: COMMENT_TYPE_SP },
    { label: COMMENT_TYPE_FEEDBACK_TEXT, value: COMMENT_TYPE_FEEDBACK },
];
const COMMENT_TYPE_MAPPING = {
    0: COMMENT_TYPE_INTERNAL_TEXT,
    1: COMMENT_TYPE_SP_TEXT,
    2: COMMENT_TYPE_FEEDBACK_TEXT,
};

const COMMENT_POLARITY_NEGATIVE = -1;
const COMMENT_POLARITY_NEUTRAL = 0;
const COMMENT_POLARITY_POSITIVE = 1;

const COMMENT_POLARITY_NEGATIVE_TEXT = 'Negative';
const COMMENT_POLARITY_NEUTRAL_TEXT = 'Neutral';
const COMMENT_POLARITY_POSITIVE_TEXT = 'Positive';
const COMMENT_POLARITY_OPTIONS = [
    { label: COMMENT_POLARITY_NEGATIVE_TEXT, value: COMMENT_POLARITY_NEGATIVE },
    { label: COMMENT_POLARITY_NEUTRAL_TEXT, value: COMMENT_POLARITY_NEUTRAL },
    { label: COMMENT_POLARITY_POSITIVE_TEXT, value: COMMENT_POLARITY_POSITIVE },
];
const COMMENT_POLARITY_MAPPING = {
    '-1': COMMENT_POLARITY_NEGATIVE_TEXT,
    0: COMMENT_POLARITY_NEUTRAL_TEXT,
    1: COMMENT_POLARITY_POSITIVE_TEXT,
};

export {
    COMMENT_TYPE_INTERNAL,
    COMMENT_TYPE_SP,
    COMMENT_TYPE_FEEDBACK,
    COMMENT_TYPE_INTERNAL_TEXT,
    COMMENT_TYPE_SP_TEXT,
    COMMENT_TYPE_FEEDBACK_TEXT,
    COMMENT_TYPE_OPTIONS,
    COMMENT_POLARITY_NEGATIVE,
    COMMENT_POLARITY_NEUTRAL,
    COMMENT_POLARITY_POSITIVE,
    COMMENT_POLARITY_NEGATIVE_TEXT,
    COMMENT_POLARITY_NEUTRAL_TEXT,
    COMMENT_POLARITY_POSITIVE_TEXT,
    COMMENT_POLARITY_OPTIONS,
    COMMENT_POLARITY_MAPPING,
    COMMENT_TYPE_MAPPING,
};
