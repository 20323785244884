import { Button, Card, Checkbox, FormGroup, H3 } from '@blueprintjs/core';
import { useState } from 'react';
import { useStore } from 'react-redux';
import { Link } from 'react-router-dom';

import { updateRegisterForm } from 'action/auth';
import { TooltipIcon } from 'components/elements';
import PasswordField from 'components/elements/PasswordField';
import { delay } from 'helpers/helpers';
import StateHandler from 'service/StateHandler';

const UserDetails = (props) => {
    const { errorMessage, closePanel, triggerHeightChange, processForm } =
        props;
    const store = useStore();
    const [form, updateForm] = useState(store.getState().auth.form);

    const updateFormData = async (data) => {
        const updatedForm = Object.assign({}, form, data);
        updateForm(updatedForm);
        store.dispatch(updateRegisterForm(updatedForm));
        await delay(500);
        triggerHeightChange();
    };

    const matchPassword = (event) => {
        const password = form.password;
        const passwordMatch = event.target.value;
        event.target.setCustomValidity(
            password === passwordMatch ? '' : 'Please match the above password'
        );
        updateFormData(StateHandler.getStateObject(event));
    };

    return (
        <>
            <div className="header small">
                <div className="header-desc">
                    <H3>Tell us about you</H3>
                    <span className="mb-4">
                        Finally, we need to know a little about you
                    </span>
                </div>
                <img
                    className="header-logo"
                    src="/assets/images/logo-new.png"
                    alt="Prosure360 logo"
                />
            </div>

            <div className="body user-details-register">
                {!errorMessage ? null : (
                    <Card className="alert">{errorMessage}</Card>
                )}
                <FormGroup className="form-fill ">
                    <input
                        id="firstName"
                        placeholder="First Name *"
                        value={form.firstName}
                        onChange={(e) =>
                            updateFormData(StateHandler.getStateObject(e))
                        }
                        minLength={2}
                        maxLength={75}
                        required
                    />
                    <TooltipIcon
                        text="Your first name"
                        icon="info-circle"
                        className="px-2"
                    />
                    <div className="input-error">First name is required</div>
                </FormGroup>

                <FormGroup className="form-fill">
                    <input
                        id="surname"
                        placeholder="Surname *"
                        value={form.surname}
                        onChange={(e) =>
                            updateFormData(StateHandler.getStateObject(e))
                        }
                        minLength={2}
                        maxLength={75}
                        required
                    />
                    <TooltipIcon
                        text="Your surname"
                        icon="info-circle"
                        className="px-2"
                    />
                    <div className="input-error">Surnname is required</div>
                </FormGroup>

                <FormGroup className="form-fill">
                    <input
                        id="jobTitle"
                        placeholder="Job Title"
                        value={form.jobTitle}
                        onChange={(e) =>
                            updateFormData(StateHandler.getStateObject(e))
                        }
                        minLength={2}
                        maxLength={50}
                    />
                    <TooltipIcon
                        text="Your job title"
                        icon="info-circle"
                        className="px-2"
                    />
                </FormGroup>

                <FormGroup className="form-fill">
                    <input
                        id="email"
                        type="email"
                        autoComplete="email"
                        placeholder="Email Address *"
                        value={form.email}
                        onChange={(e) =>
                            updateFormData(StateHandler.getStateObject(e))
                        }
                        required
                    />
                    <TooltipIcon
                        text="Your email"
                        icon="info-circle"
                        className="px-2"
                    />
                    <div className="input-error">
                        Please enter a valid email address
                    </div>
                </FormGroup>

                <FormGroup className="form-fill">
                    <input
                        id="phone"
                        fill={true}
                        type="phone"
                        placeholder="Phone Number *"
                        value={form.phone}
                        onChange={(e) =>
                            updateFormData(StateHandler.getStateObject(e))
                        }
                        required
                    />
                    <TooltipIcon
                        text="Your phone or mobile number"
                        icon="info-circle"
                        className="px-2"
                    />
                    <div className="input-error">Phone number is required</div>
                </FormGroup>

                <FormGroup className="form-fill required">
                    <PasswordField
                        id="password"
                        placeholder="Password *"
                        autoComplete="new-password"
                        value={form.password}
                        showInfo={true}
                        showError={true}
                        marloweTheme={true}
                        onChange={(e) => {
                            updateFormData(StateHandler.getStateObject(e));
                        }}
                        required
                    />
                </FormGroup>

                <FormGroup className="form-fill required">
                    <PasswordField
                        id="passwordMatch"
                        placeholder="Re-fill Password *"
                        autoComplete="new-password"
                        value={form.passwordMatch}
                        showInfo={true}
                        showError={true}
                        marloweTheme={true}
                        pattern=".*"
                        title="Please match the above password"
                        onChange={matchPassword}
                        required
                    />
                </FormGroup>

                <FormGroup className="form-fill mb-0 terms">
                    <label for="acceptsTerms">
                        Accept Terms &amp; Conditions{' '}
                        <span className="error">*</span>
                    </label>
                    <Checkbox
                        id="acceptsTerms"
                        checked={form.acceptsTerms}
                        onChange={(e) =>
                            updateFormData(StateHandler.getStateObject(e))
                        }
                        required
                    />
                </FormGroup>
                <div className="mb-4">
                    By ticking the checkbox above, I confirm that I have read
                    and accepted the Prosure360 Service Provider{' '}
                    <a
                        href="https://prosure360.co.uk/terms_and_conditions"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <span>Terms &amp; conditions</span>
                    </a>{' '}
                    on behalf of the company
                </div>

                <FormGroup className="form-fill mb-0 terms">
                    <label for="acceptsTermsOfUseAndPrivacy">
                        Accept Terms of Use and Privacy Policy{' '}
                        <span className="error">*</span>
                    </label>
                    <Checkbox
                        id="acceptsTermsOfUseAndPrivacy"
                        checked={form.acceptsTermsOfUseAndPrivacy}
                        onChange={(e) =>
                            updateFormData(StateHandler.getStateObject(e))
                        }
                        required
                    />
                </FormGroup>
                <div>
                    By ticking the checkbox above, I confirm that I have read
                    and accepted the Prosure360 Service Provider{' '}
                    <a
                        href="https://prosure360.co.uk/terms_of_use"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <span>Terms of use</span>
                    </a>{' '}
                    and{' '}
                    <a
                        href="https://prosure360.co.uk/privacy_policy"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <span>Privacy Policy</span>
                    </a>
                </div>
                <div className="flex-between mt-5">
                    <Button
                        type="button"
                        text="Previous"
                        large={true}
                        onClick={closePanel}
                    />
                    <Button
                        type="submit"
                        text="Register"
                        large={true}
                        intent="primary"
                        onClick={processForm}
                    />
                </div>
            </div>
            <div className="footer box-footer flex-start">
                <Link to={props.cancelUrl ?? '/login'}>Cancel</Link>
            </div>
        </>
    );
};

export default UserDetails;
