import { NONE_SELECTED_OPTION } from 'constants/none-selected';

const ROLE_ADMINISTRATOR = 'ROLE_ADMINISTRATOR';
const ROLE_PROSURE_CLIENT_SERVICES = 'ROLE_PROSURE_CLIENT_SERVICES';
const ROLE_CLIENT_USER = 'ROLE_CLIENT_USER';
const ROLE_CLIENT_VIEW_ONLY = 'ROLE_CLIENT_VIEW_ONLY';
const ROLE_CLIENT_ADMINISTRATOR = 'ROLE_CLIENT_ADMINISTRATOR';
const ROLE_COMPANY_ADMINISTRATOR = 'ROLE_COMPANY_ADMINISTRATOR';
const ROLE_REGISTRATIONS_RENEWALS = 'ROLE_REGISTRATIONS_RENEWALS';
const ROLE_ASSESSMENT_ASSESSOR = 'ROLE_ASSESSMENT_ASSESSOR';
const ROLE_ASSESSMENT_REVIEWER = 'ROLE_ASSESSMENT_REVIEWER';

const ROLES = [
    ROLE_ADMINISTRATOR,
    ROLE_PROSURE_CLIENT_SERVICES,
    ROLE_CLIENT_USER,
    ROLE_CLIENT_VIEW_ONLY,
    ROLE_CLIENT_ADMINISTRATOR,
    ROLE_COMPANY_ADMINISTRATOR,
    ROLE_ASSESSMENT_ASSESSOR,
    ROLE_ASSESSMENT_REVIEWER,
];

const CLIENT_ROLES = [
    ROLE_CLIENT_USER,
    ROLE_CLIENT_VIEW_ONLY,
    ROLE_CLIENT_ADMINISTRATOR,
];

const ROLE_OPTIONS_ROLE_ADMINISTRATOR = {
    label: 'Prosure Administrator',
    value: ROLE_ADMINISTRATOR,
};
const ROLE_OPTIONS_ROLE_PROSURE_CLIENT_SERVICES = {
    label: 'Prosure Client Services',
    value: ROLE_PROSURE_CLIENT_SERVICES,
};
const ROLE_OPTIONS_ROLE_CLIENT_USER = {
    label: 'Client User',
    value: ROLE_CLIENT_USER,
};
const ROLE_OPTIONS_ROLE_CLIENT_VIEW_ONLY = {
    label: 'Client View Only',
    value: ROLE_CLIENT_VIEW_ONLY,
};
const ROLE_OPTIONS_ROLE_CLIENT_ADMINISTRATOR = {
    label: 'Client Administrator',
    value: ROLE_CLIENT_ADMINISTRATOR,
};
const ROLE_OPTIONS_ROLE_COMPANY_ADMINISTRATOR = {
    label: 'Service Provider Administrator',
    value: ROLE_COMPANY_ADMINISTRATOR,
};
const ROLE_OPTIONS_ROLE_REGISTRATIONS_RENEWALS = {
    label: 'Prosure Registrations/Renewals',
    value: ROLE_REGISTRATIONS_RENEWALS,
};
const ROLE_OPTIONS_ROLE_ASSESSMENT_ASSESSOR = {
    label: 'Prosure Assessor',
    value: ROLE_ASSESSMENT_ASSESSOR,
};
const ROLE_OPTIONS_ROLE_ASSESSMENT_REVIEWER = {
    label: 'Prosure Reviewer',
    value: ROLE_ASSESSMENT_REVIEWER,
};
const ROLE_OPTIONS = [
    ROLE_OPTIONS_ROLE_ADMINISTRATOR,
    ROLE_OPTIONS_ROLE_PROSURE_CLIENT_SERVICES,
    ROLE_OPTIONS_ROLE_CLIENT_USER,
    ROLE_OPTIONS_ROLE_CLIENT_VIEW_ONLY,
    ROLE_OPTIONS_ROLE_CLIENT_ADMINISTRATOR,
    ROLE_OPTIONS_ROLE_COMPANY_ADMINISTRATOR,
    ROLE_OPTIONS_ROLE_REGISTRATIONS_RENEWALS,
    ROLE_OPTIONS_ROLE_ASSESSMENT_ASSESSOR,
    ROLE_OPTIONS_ROLE_ASSESSMENT_REVIEWER,
];
const ROLE_OPTIONS_WITH_NONE_SELECTED = [...ROLE_OPTIONS].unshift(
    NONE_SELECTED_OPTION
);

const ROLE_OPTIONS_WITHOUT_CLIENT = [
    ROLE_OPTIONS_ROLE_ADMINISTRATOR,
    ROLE_OPTIONS_ROLE_PROSURE_CLIENT_SERVICES,
    ROLE_OPTIONS_ROLE_COMPANY_ADMINISTRATOR,
    ROLE_OPTIONS_ROLE_REGISTRATIONS_RENEWALS,
    ROLE_OPTIONS_ROLE_ASSESSMENT_ASSESSOR,
    ROLE_OPTIONS_ROLE_ASSESSMENT_REVIEWER,
];

const CLIENT_ROLE_OPTIONS = [
    ROLE_OPTIONS_ROLE_CLIENT_USER,
    ROLE_OPTIONS_ROLE_CLIENT_VIEW_ONLY,
    ROLE_OPTIONS_ROLE_CLIENT_ADMINISTRATOR,
];

export {
    ROLE_OPTIONS_ROLE_ADMINISTRATOR,
    ROLE_OPTIONS_ROLE_PROSURE_CLIENT_SERVICES,
    ROLE_OPTIONS_ROLE_CLIENT_USER,
    ROLE_OPTIONS_ROLE_CLIENT_VIEW_ONLY,
    ROLE_OPTIONS_ROLE_CLIENT_ADMINISTRATOR,
    ROLE_OPTIONS_ROLE_COMPANY_ADMINISTRATOR,
    ROLE_OPTIONS_ROLE_REGISTRATIONS_RENEWALS,
    ROLE_OPTIONS_ROLE_ASSESSMENT_ASSESSOR,
    ROLE_OPTIONS_ROLE_ASSESSMENT_REVIEWER,
    ROLE_OPTIONS,
    ROLE_OPTIONS_WITH_NONE_SELECTED,
    ROLE_OPTIONS_WITHOUT_CLIENT,
    CLIENT_ROLE_OPTIONS,
    ROLE_ADMINISTRATOR,
    ROLE_PROSURE_CLIENT_SERVICES,
    ROLE_CLIENT_USER,
    ROLE_CLIENT_VIEW_ONLY,
    ROLE_CLIENT_ADMINISTRATOR,
    ROLE_COMPANY_ADMINISTRATOR,
    ROLE_ASSESSMENT_ASSESSOR,
    ROLE_ASSESSMENT_REVIEWER,
    ROLE_REGISTRATIONS_RENEWALS,
    ROLES,
    CLIENT_ROLES,
};
