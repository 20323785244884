import { Redirect, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ProtectedRoute } from 'components/elements';
import ProfileInfo from 'components/user/ProfileInfo';

const Profile = () => {
    const rootResponse = useSelector((state: any) => state.root.response);

    return (
        <>
            <Switch>
                <ProtectedRoute
                    path="/profile"
                    component={ProfileInfo}
                    user={rootResponse.user}
                />

                <Redirect to="/profile" />
            </Switch>
        </>
    );
};

export default Profile;
