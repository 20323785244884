const routes = {
    tasks: {
        href: '/tasks',
        text: 'Tasks',
        skipNavigation: true,
    },
    assessment_tasks: {
        href: '/tasks/list',
        text: 'Priority & Upcoming Tasks',
    },
};

export default routes;
