import React from 'react';
import { Redirect, Switch } from "react-router-dom";
import { ProtectedRoute } from 'components/elements';
import AssessmentTypes from "components/assessment-types/AssessmentTypes";
import AssessmentTypeAccreditation from "components/assessments/AssessmentTypeAccreditation";
import AssessmentTypePurchase from "components/assessment-types/AssessmentTypePurchase";
import AssessmentTypePayment from "components/assessment-types/AssessmentTypePayment";
import ThankYou from "components/elements/payment/ThankYou";

const AssessmentTypeRoot = (props) => {
    const { companyResponse, match } = props;

    const getThankYouMessageContent = (
        <>
            <p>We will now redirect you back to the assessments list page.</p>
            <p>Purchased assessments will appear under the "In Progress" tab.</p>
        </>
    );

    const getThankYouForwardUrl = '/assessments/in-progress';

    return <>
        <Switch>
            <ProtectedRoute path={`${match.url}/:id/view`} component={ AssessmentTypeAccreditation } mode="company" companyResponse={ companyResponse } />
            <ProtectedRoute path={`${match.url}/:id/purchase`} component={ AssessmentTypePurchase } companyResponse={ companyResponse } exact />
            <ProtectedRoute path={`${match.url}/:id/payment`} component={ AssessmentTypePayment } companyResponse={ companyResponse } exact />
            <ProtectedRoute path={`${match.url}/thank-you`}
                            component={ ThankYou }
                            companyResponse={ companyResponse }
                            forwardUrl={ getThankYouForwardUrl }
                            messageContent={ getThankYouMessageContent }
                            exact
            />
            <ProtectedRoute path={`${match.url}`} component={ AssessmentTypes } companyResponse={ companyResponse } />

            <Redirect to={`${match.url}`} />
        </Switch>
    </>
}

export default AssessmentTypeRoot;
