import * as d3 from 'd3';
import { createRef, RefObject, useEffect } from 'react';

import { BarChartData, BarChartInterface } from 'interface';
import './index.scss';

/**
 * REQUIRED: Put .chart-parent to the HTML parent element that is responsible for sizing.
 *           This is required so that sizing and placements are consistent.
 * @param props DonutChartInterface
 */
const BarChart = (props: BarChartInterface) => {
    const ref: RefObject<HTMLDivElement> = createRef();
    const margin = { top: 10, right: 45, bottom: 30, left: 38 };
    const data = props.data as BarChartData[];
    let y = d3.scaleLinear();
    let x = d3.scaleBand();
    let svg: d3.Selection<any, any, any, any>;
    let colour: d3.ScaleOrdinal<any, any>;
    let colourLabels: d3.ScaleOrdinal<any, any>;

    const draw = () => {
        if (!ref.current || !props.data) {
            return;
        }

        // clear old chart
        d3.select(ref.current).select('svg').remove();

        // eslint-disable-next-line react-hooks/exhaustive-deps
        svg = d3
            .select(ref.current)
            .append('svg')
            .attr('width', props.width)
            .attr('height', props.height);

        const colourNames = props.colours.map((colour) => colour.name);
        const colourLabelsA = props.colours.map((colour) => colour.label);
        const colourColours = props.colours.map((colour) => colour.colour);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        colour = d3.scaleOrdinal().domain(colourNames).range(colourColours);

        // eslint-disable-next-line react-hooks/exhaustive-deps
        colourLabels = d3
            .scaleOrdinal()
            .domain(colourColours)
            .range(colourLabelsA);

        drawScale();
        drawBars();
    };

    const drawScale = () => {
        // Y Axis
        const axis = svg
            .append('g')
            .attr('transform', `translate(${margin.left}, ${margin.top})`);
        let dataY;
        var epsilon = Math.pow(10, -7);

        switch (props.yScale.type) {
            case 'percentage':
                dataY = data.map((row: any) => row.y);
                y.domain([
                    Math.ceil(Math.max(50, ...dataY) / 10) * 10,
                    Math.floor(Math.min(40, ...dataY) / 10) * 10,
                ]).range([margin.top, props.height - margin.bottom]);
                axis.call(
                    d3
                        .axisLeft(y)
                        .tickSize(props.width - margin.left - margin.right)
                        .tickFormat((value) => `${value}%`)
                );
                break;
            case 'linear':
                dataY = data.map((row: any) => row.y);
                y.domain([Math.max(1, ...dataY), 0])
                    .range([margin.top, props.height - margin.bottom])
                    .range([margin.top, props.height - margin.bottom]);
                axis.call(
                    d3
                        .axisLeft(y)
                        .tickSize(props.width - margin.left - margin.right)
                        .tickFormat((d) => {
                            const d2 = parseFloat(d.toString());
                            if (
                                d2 - Math.floor(d2) > epsilon &&
                                Math.ceil(d2) - d2 > epsilon
                            )
                                return '';
                            return d.toString();
                        })
                );
                break;
        }
        axis.call((g) => g.select('.domain').remove()).call((g) => {
            g.selectAll('.tick line')
                .attr('x1', 0)
                .attr('x2', props.width - margin.left - margin.right)
                .attr('stroke-opacity', 0.5)
                .attr('stroke-dasharray', '2,2');
            g.selectAll('.tick text').attr('x', -10);

            return g;
        });

        // X Axis
        x = d3
            .scaleBand()
            .domain(data.map((row) => row.x))
            .range([margin.left, props.width - margin.right])
            .paddingInner(0.2)
            .paddingOuter(0.4)
            .align(0.5);
        svg.append('g')
            .attr(
                'transform',
                `translate(0, ${props.height - margin.bottom + margin.top})`
            )
            .call(d3.axisBottom(x).tickFormat((value) => colourLabels(value)));
    };

    const drawBars = () => {
        const bars = svg
            .append('g')
            .attr('class', 'data-bars')
            .style('transform', `translateY(${margin.top}px)`);

        data.forEach((row: any) => {
            bars.append('rect')
                .attr('x', x(row.x) || '')
                .attr('y', y(row.y))
                .attr('height', y(0) - y(row.y))
                .attr('width', x.bandwidth())
                .style('fill', colour(row.x))
                .attr('class', () => {
                    return row.link ? 'rect hover' : 'rect';
                })
                .on('click', () => {
                    if (row.link) {
                        window.location.href = row.link;
                    }
                });

            bars.append('text')
                .attr('text-anchor', 'middle')
                .attr('dx', x.bandwidth() / 2)
                .attr('dy', '-0.4em')
                .attr('y', y(row.y))
                .attr('x', x(row.x) || '')
                .text(row.y);
        });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(draw, [props.data, ref.current]);

    return (
        <div className="bar-chart" ref={ref}>
            <div className="chart-tooltip"></div>
        </div>
    );
};

export default BarChart;
