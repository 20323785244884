import { AxiosResponse } from 'axios';
import { ListFilter, emptyListData } from 'interface';
import { ElogsCAFMStatsListData } from 'interface/ElogsCAFMStats';
import { SupplyChainFilter, SupplyChainList } from 'interface/SupplyChain';
import { toast } from 'react-toastify';
import HTTP from 'service/HTTP';

class SupplyChainService {
    static loadSupplyChainsByCompany: (
        filter: SupplyChainFilter
    ) => Promise<SupplyChainList> = async (filter: SupplyChainFilter) => {
        const response: AxiosResponse | void = await HTTP.action(
            'get',
            `/supply-chain`,
            {
                ...filter,
                datagroup: 'list',
            },
            {},
            'Unable to load supply chains'
        );
        if (response) {
            return response.data as SupplyChainList;
        } else {
            return {} as SupplyChainList;
        }
    };

    static loadCafmStats = async (
        filter: ListFilter,
        clientId: string
    ): Promise<ElogsCAFMStatsListData> => {
        const response: AxiosResponse | void = await HTTP.action(
            'get',
            `/client/${clientId}/supply-chain/cafm-stats`,
            filter,
            {},
            'Unable to load cafm stats list'
        );
        if (response) {
            return response.data as ElogsCAFMStatsListData;
        } else {
            return {
                ...emptyListData,
                jobs: [],
            } as ElogsCAFMStatsListData;
        }
    };

    static downloadClientSupplyChain = async (
        clientId: string,
        filter?: SupplyChainFilter
    ): Promise<boolean | void> => {
        return HTTP.stream(
            `/clients/${clientId}/supply-chain/download`,
            {},
            filter ? filter : {}
        );
    };

    static downloadSupplyChains = async (
        filter?: SupplyChainFilter
    ): Promise<void> => {
        await HTTP.stream(`/supply-chain/download`, {}, filter ? filter : {});

        return;
    };

    static setRemovedWhenExpired = async (expiryLink: string, data: any) => {
        let response: AxiosResponse | void;

        response = await HTTP.action(
            'patch',
            expiryLink,
            data,
            void 0,
            'Unable to set remove on expire'
        );

        if (response) {
            toast.success('Company Set To Remove On Expire');
        }

        return response;
    };

    static leaveSupplyChain = async (id: string) => {
        let response: AxiosResponse | void;

        response = await HTTP.action(
            'post',
            `/supply-chain/${id}/leave`,
            {},
            void 0,
            'Unable to leave the supply chain'
        );

        if (response) {
            toast.success('Successfully left the supply chain');
        }

        return response;
    };
}

export default SupplyChainService;
