import { Button, Classes, Dialog, FormGroup, Intent } from '@blueprintjs/core';
import { SuggestRenderer } from 'components/elements';
import { globalDebounce } from 'helpers/helpers';
import { CompanyListData } from 'interface';
import { CampaignInvitee } from 'interface/Client/Campaign';
import { ComponentProps, SyntheticEvent, useEffect, useReducer } from 'react';
import { toast } from 'react-toastify';
import { CampaignService, CompanyService, Response } from 'service';
interface AssessmentStatusProps extends ComponentProps<any> {
    invitee?: CampaignInvitee;
    isOpen: boolean;
    onClose: Function;
}
const AssignInvitee = (props: AssessmentStatusProps) => {
    const { invitee, isOpen, onClose } = props;
    const [state, setState] = useReducer(
        (state: any, edits: any) => {
            return { ...state, ...edits };
        },
        {
            dialogOpen: !!isOpen,
            isLoading: false,
            companies: [],
            spFilter: CompanyService.createCompanyFilter(),
            _links: {},
        }
    );
    useEffect(() => {
        setState({ dialogOpen: isOpen });
    }, [isOpen]);

    const dialogClose = (event: SyntheticEvent) => {
        setState({
            dialogOpen: false,
            statusMessage: '',
            companies: [],
            company: null,
            spFilter: CompanyService.createCompanyFilter(),
        });

        typeof onClose === 'function' && onClose(event);
    };

    const searchSPs = (name?: string) => {
        const filter = {
            ...state.spFilter,
            registeredCompanyName: name,
        };
        !name && delete filter.registeredCompanyName;
        setState({ spFilter: filter });
    };

    const selectSP = (item: any) => {
        if (item === null) {
            searchSPs('');

            return;
        }

        setState({
            _links: { ...state._links, company: item._links['self'] },
        });

        return;
    };

    const loadSPs = (callback?: Function) => {
        globalDebounce(
            async () => {
                const serviceProviderList: CompanyListData =
                    await CompanyService.loadList(state.spFilter);
                setState({
                    companies: serviceProviderList.companies,
                    isLoading: false,
                });
                callback && callback();
            },
            'InviteeSPs',
            250
        );
    };

    useEffect(loadSPs, [state.spFilter]);

    const dialogConfirm = async () => {
        if (!invitee || !Response.getLink(invitee, 'assign')) {
            toast.error('Not possible to assign Service Provider to Invitee.');
            return;
        }

        if (!state._links['company']) {
            toast.error('Please select a company to link with');
            return;
        }

        //use invitee and submit here
        setState({ isLoading: true });

        const response = await CampaignService.assignInvitee(invitee, {
            _links: state._links,
        });

        response && toast.success('Status has been updated successfully.');
        dialogClose({} as SyntheticEvent);
    };

    return (
        <Dialog
            onClose={dialogClose}
            title="Link Invite to Service Provider"
            autoFocus={true}
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            isCloseButtonShown={false}
            usePortal={true}
            isOpen={state.dialogOpen}
        >
            <div className={Classes.DIALOG_BODY}>
                <label className="bp3-label" htmlFor="updateText">
                    Select an existing Service Provider that relates to this invitation.
                </label>
                <FormGroup
                        key="serviceProvider"
                        inline={true}
                        className="form-fill required"
                    >
                    <SuggestRenderer
                        id="serviceProvider"
                        onItemSelect={(item: any) => selectSP(item)}
                        resetOnClose={(e: any) => {
                            searchSPs(e.target.value);
                        }}
                        placeholder={'Service Provider Name'}
                        valueProperty={'registeredCompanyName'}
                        items={state.companies}
                        onKeyUp={(e: any) => {
                            searchSPs(e.target.value);
                        }}
                        isRequired={true}
                    />
                </FormGroup>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className="dialog-footer-buttons">
                    <Button onClick={dialogClose}>Cancel</Button>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button
                            intent={Intent.SUCCESS}
                            disabled={state.isLoading}
                            onClick={dialogConfirm}
                        >
                            Confirm
                        </Button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};
export default AssignInvitee;
