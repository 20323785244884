export const SET_TASKS_COUNT = 'SET_TASKS_COUNT';
export const CLEAR_TASKS_COUNT = 'CLEAR_TASKS_COUNT';

export { clearTasksCount, setTasksCount };

function setTasksCount(tasksCount) {
    return {
        type: SET_TASKS_COUNT,
        tasksCount,
    };
}

function clearTasksCount() {
    return {
        type: CLEAR_TASKS_COUNT,
    };
}
