import { Redirect, Switch } from 'react-router-dom';

import { ProtectedRoute } from 'components/elements';
import IntegrationTabs from 'components/admin/integrations/IntegrationsTabs';

const Integrations = () => {
    return (
        <>
            <Switch>
                <ProtectedRoute
                    path="/admin/integrations"
                    component={IntegrationTabs}
                />

                <Redirect to="/admin/integrations/elogbooks" />
            </Switch>
        </>
    );
};

export default Integrations;
