import {
    Button,
    Classes,
    Dialog,
    FormGroup,
    HTMLSelect,
    Intent,
} from '@blueprintjs/core';
import {
    Campaign,
    CampaignEmailListFilter,
    CampaignInvitee,
    CampaignInviteeListFilter,
} from 'interface/Client/Campaign';
import {
    ComponentProps,
    SyntheticEvent,
    useEffect,
    useReducer,
    useState,
} from 'react';
import { toast } from 'react-toastify';

import { Loading } from 'components/elements/wrappers';
import { NONE_SELECTED_OPTION } from 'constants/none-selected';
import { ListCollection } from 'interface';
import { EmailTemplateData } from 'interface/Email';
import { Option } from 'interfaces';
import { HTTP, Response } from 'service';
import CampaignService from 'service/Client/CampaignService';

interface SendCampaignEmailProps extends ComponentProps<any> {
    invitee: CampaignInvitee;
    invitees?: CampaignInvitee[];
    campaign?: Campaign;
    isOpen: boolean;
    isSendAll: boolean;
    onClose: Function;
    filter?: CampaignInviteeListFilter;
}

const SendCampaignEmail = (props: SendCampaignEmailProps) => {
    const {
        invitee,
        invitees,
        campaign,
        isOpen,
        isSendAll = false,
        onClose,
        filter
    } = props;

    const total = isSendAll && campaign
        ? parseInt(campaign.numberOfInvitees)
        : invitees?.length ?? 1;

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [state, setState] = useReducer(
        (state: any, edits: any) => {
            return { ...state, ...edits };
        },
        {
            isLoading: false,
            template: null,
        }
    );

    const defaultFilters = {
        order: '-name',
        limit: 999,
    };

    const [listFilter, setListFilter] = useState<CampaignEmailListFilter>({
        ...defaultFilters,
    } as CampaignEmailListFilter);

    const [templateCollection, setTemplateCollection] =
        useState<ListCollection<EmailTemplateData>>();
    const [templateOptions, setTemplateOptions] = useState<Option[]>([]);

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            await load({ ...listFilter });

            setIsLoading(false);
            setState({ dialogOpen: isOpen });
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, campaign, invitee]);

    const load = async (filter: CampaignEmailListFilter) => {
        let campaignData = null;

        if (!campaign) {
           const url = Response.getLink(invitee, 'campaign');
           const response = await HTTP.get(url);

           campaignData = response?.data;
        }

        campaignData = campaign || campaignData;

        const templateCollection = await CampaignService.loadEmailTemplates(
            Response.getLink(campaignData, 'email-templates'),
            filter
        );

        setTemplateCollection(templateCollection);
        setTemplateOptions(
            templateCollection.data.map((template: EmailTemplateData) => {
                return {
                    value: template.id,
                    key: template.id,
                    label: template.name,
                    id: template.id,
                } as Option;
            })
        );
        setIsLoading(false);
    };

    const dialogClose = (event: SyntheticEvent) => {
        setListFilter({ ...defaultFilters } as CampaignEmailListFilter);
        setState({
            dialogOpen: false,
            isLoading: false,
            template: null,
        });
        typeof onClose === 'function' && onClose(event);
    };

    const dialogConfirm = async () => {
        if (!invitee && !invitees) {
            toast.error('Could not send email to invitee.');
            return;
        }

        if (!state.template) {
            toast.error('Please select an email to send');
            return;
        }

        if (!Response.getLink(state.template, 'sendEmail')) {
            toast.error('Could not send email to invitee.');
            return;
        }

        //use invitee and submit here
        setState({ isLoading: true });

        const response = await CampaignService.sendEmail(
            state.template,
            isSendAll,
            invitees ?? [invitee],
            filter
        );

        response && toast.success('Email sent successfully.');
        !response && toast.error('Email could not be sent.');
        dialogClose({} as SyntheticEvent);
    };

    return (
        <Dialog
            className="email-invitee"
            onClose={dialogClose}
            title={`Select Email`}
            autoFocus={true}
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            isCloseButtonShown={false}
            usePortal={true}
            isOpen={isOpen}
        >
            <Loading isLoading={isLoading}>
                <div className={Classes.DIALOG_BODY}>
                    <label className="bp3-label" htmlFor="updateText">
                        Select an email to send to the invitee.
                    </label>

                    <FormGroup
                        label="Email Name"
                        key="template"
                        inline
                        className="form-fill required"
                        labelFor="template"
                    >
                        <HTMLSelect
                            id="template"
                            fill
                            required
                            defaultValue={NONE_SELECTED_OPTION.value}
                            onChange={(e: any) => {
                                setState({
                                    template: templateCollection?.data.find(
                                        (template: EmailTemplateData) =>
                                            template.id === e.target.value
                                    ),
                                });
                            }}
                            options={[NONE_SELECTED_OPTION, ...templateOptions]}
                        />
                    </FormGroup>

                    <FormGroup label="Description" inline className="form-fill">
                        {state.template?.description}
                    </FormGroup>

                    {total > 1 && (
                        <div className="form-card error" role="alert">
                            This will send out a multiple number of emails. Are
                            you sure?
                        </div>
                    )}
                </div>
            </Loading>
            <div className={Classes.DIALOG_FOOTER}>
                <FormGroup>
                    <div className="dialog-footer-buttons">
                        <Button onClick={dialogClose}>Cancel</Button>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button
                                type="submit"
                                intent={Intent.SUCCESS}
                                disabled={state.isLoading}
                                onClick={dialogConfirm}
                            >
                                Confirm
                            </Button>
                        </div>
                    </div>
                </FormGroup>
            </div>
        </Dialog>
    );
};

export default SendCampaignEmail;
