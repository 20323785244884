import { Switch } from 'react-router-dom';

import CertificationMembershipDetails from 'components/company/certification-memberships/CertificationMembershipDetails';
import CertificationMembershipAddEdit from 'components/company/certification-memberships/CertificationMembershipAddEdit';
import CertificationMembershipsList from 'components/company/certification-memberships/CertificationMembershipsList';
import ProtectedRoute from 'components/elements/ProtectedRoute';

const CertificationMemberships = props => {
    const { companyResponse, match } = props;

    return <>
        <div className="CertificationMemberships">
            <Switch>
                <ProtectedRoute path={`${match.url}`} component={ CertificationMembershipsList } companyResponse={ companyResponse } exact />
                <ProtectedRoute path={`${match.url}/add`} component={ CertificationMembershipAddEdit } companyResponse={ companyResponse } exact />
                <ProtectedRoute path={`${match.url}/:id`} component={ CertificationMembershipDetails } companyResponse={ companyResponse } />
            </Switch>
        </div>
    </>;
}

export default CertificationMemberships;
