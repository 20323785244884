import { useEffect, useState } from 'react';
import { HTTP } from 'service';
import queryString from "query-string";
import { Card } from "@blueprintjs/core";
import "components/pre-auth/_verification.scss";

const Verification = (props) =>
{
    const [message, setMessage] = useState("Please wait while we check your token.");
    const [messageType, setMessageType] = useState("");

    useEffect(() => {
        (async () => {
            const result = await HTTP.post('/verify-user', queryString.parse(props.location.search))
                .catch(({ response }) => {
                    const { data } = response;

                    if (Array.isArray(data) && data.length) {
                        setMessage(data[0].message)
                        setMessageType("error");
                    }
                    else {
                        setMessage("Could not verify the token provided. Please request a new token.");
                        setMessageType("error");
                    }
                });

            if (result) {
                setMessage("You are now verified! You may now close this browser tab.");
                setMessageType("success");
            } 
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <>
        <div className="verify">
            <div className="FlexContainer">
                <div className="logo">
                    <img src="/assets/images/logo.svg"
                        alt="Prosure360 logo" />
                    <h2>
                        Verifying your account
                    </h2>
                </div>
                <Card className={ messageType }>
                    <p>{ message }</p>
                </Card>
            </div>
        </div>
    </>;
}

export default Verification;
