import { useEffect, useState } from 'react';
import { H1 } from '@blueprintjs/core';

import { ItemCount, Paginator, Reload, Table } from 'components/elements';
import { Location } from 'service';
import { Loading, NoResultsFound } from 'components/elements/wrappers';
import {
    EmailTemplateData,
    EmailTemplateListData,
    EmailTemplateProps,
} from 'interface/Email';
import { FlatObject } from 'interface';
import { loadEmailTemplateList } from 'service/Email/TemplateService';

const TemplatesList = (props: Partial<EmailTemplateProps>) => {
    const { history, location } = props;

    const [collectionResponse, setCollectionResponse] =
        useState<EmailTemplateListData>();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const defaultFilters: FlatObject = {
        order: 'name',
    };

    const [filters, setFilters] = useState<FlatObject>({
        order: defaultFilters.order,
    });

    const getRoute = (row: EmailTemplateData, column: any) => {
        return '/admin/email-templates/' + row.id;
    };

    const columns: FlatObject[] = [
        {
            name: `Name`,
            property: 'name',
            sortable: true,
            grow: 3,
        },
        {
            name: `Description`,
            property: 'description',
            sortable: false,
            grow: 6,
        },
        {
            name: '',
            property: '',
            type: 'nav-button',
            grow: 1,
            route: getRoute,
            buttonIntent: 'primary',
            buttonText: 'View',
            right: true,
        },
    ];

    const load = async (
        params: FlatObject = {},
        paramsOverride: boolean = false
    ) => {
        (async () => {
            setIsLoading(true);
            const filterParams = Object.assign(
                {},
                defaultFilters,
                props.filter,
                filters,
                params
            );
            setFilters({ ...filters, ...params });
            const emailTemplateList: EmailTemplateListData =
                await loadEmailTemplateList(filterParams);
            setCollectionResponse(emailTemplateList);

            Location.setQueryStringFromObject(history, location, filterParams);

            setIsLoading(false);
        })();
    };

    useEffect(() => {
        load();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="EmailTemplateList">
            <H1 className="clearfix">
                Email Template List
                <ItemCount count={collectionResponse?.count ?? 0} />
                <Reload load={load} />
            </H1>

            <Loading isLoading={isLoading}>
                <NoResultsFound count={collectionResponse?.count ?? 0}>
                    <Table
                        data={collectionResponse?.emailTemplates}
                        columns={columns}
                        onSort={load}
                        ordering={filters.order}
                    />

                    <Paginator
                        page={collectionResponse?.page}
                        count={collectionResponse?.count}
                        limit={collectionResponse?.limit}
                        onPage={load}
                    />
                </NoResultsFound>
            </Loading>
        </div>
    );
};

export default TemplatesList;
