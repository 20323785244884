import adminRoutes from 'routes/admin';
import assessmentRoutes from 'routes/assessments';
import authRoutes from 'routes/auth';
import benefitRoutes from 'routes/benefits';
import clientRoutes from 'routes/client';
import companiesRoutes from 'routes/companies';
import companyRoutes from 'routes/company';
import reportRoutes from 'routes/reports';
import reviewRoutes from 'routes/review';
import serviceProviderRoutes from 'routes/service-providers';
import supplyChain from 'routes/supply-chain';
import taskRoutes from 'routes/tasks';
import userRoutes from 'routes/user';

const routes = Object.assign(
    {},
    adminRoutes,
    authRoutes,
    benefitRoutes,
    clientRoutes,
    companiesRoutes,
    companyRoutes,
    reviewRoutes,
    assessmentRoutes,
    serviceProviderRoutes,
    supplyChain,
    taskRoutes,
    userRoutes,
    reportRoutes
);

export default routes;
