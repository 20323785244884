import { Component } from 'react';
import { Redirect, Switch } from 'react-router-dom';

import Campaigns from 'components/clients/Campaigns';
import ClientAddEdit from 'components/clients/ClientAddEdit';
import ClientDetails from 'components/clients/ClientDetails';
import ClientList from 'components/clients/ClientList';
import ProtectedRoute from 'components/elements/ProtectedRoute';

class Clients extends Component {
    render() {
        return (
            <div className="page Clients">
                <div className="no-styling  page-wrapper">
                    <Switch>
                        <ProtectedRoute
                            path="/clients/list"
                            component={ClientList}
                            exact
                        />
                        <ProtectedRoute
                            path="/clients/archived-list"
                            component={ClientList}
                            isArchive={true}
                            exact
                        />
                        <ProtectedRoute
                            path="/clients/campaigns"
                            component={Campaigns}
                        />
                        <ProtectedRoute
                            path="/clients/list/add"
                            component={ClientAddEdit}
                            exact
                        />
                        <ProtectedRoute
                            path="/clients/list/:clientId/edit"
                            component={ClientAddEdit}
                        />
                        <ProtectedRoute
                            path="/clients/archived-list/:clientId/edit"
                            component={ClientAddEdit}
                        />
                        <ProtectedRoute
                            path="/clients/list/:clientId"
                            component={ClientDetails}
                        />
                        <ProtectedRoute
                            path="/clients/archived-list/:clientId"
                            component={ClientDetails}
                        />
                        <Redirect to="/clients/list" />
                    </Switch>
                </div>
            </div>
        );
    }
}

export default Clients;
