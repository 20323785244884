import PropTypes from "prop-types";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { MAX_FILE_UPLOAD_SIZE_MB } from "constants/general";
import { toast } from "react-toastify";
import { HTTP, Response } from "service";
import { LoadingIndicator } from "components/elements/index";

const LogoUpload = (props) => {
    const {
        onUpdate,
        defaultLogo,
        uploadDataObject,
        imageClass = "",
        loadingClass = "",
        wrapperClass = "",
        showUploadingText = true,
    } = props;

    const [file, setFile] = useState({
        mimeType: null,
        isUploading: false,
        isUploaded: false,
        title: null,
        progress: 0,
        hasErrors: false,
        errorText: null,
        _links: {}
    });
    const acceptedFormats = [
        '.jpg, .gif, .png',
        'image/png, image/jpg, image/gif' // to allow for file extension variations
    ];
    const maxFileSize = MAX_FILE_UPLOAD_SIZE_MB * 1024 ** 2;

    const onDrop = (acceptedFiles, fileRejections) => {
        fileRejections.forEach(file => {
            let rejectMessage = '';
            switch(file.errors[0].code) {
                case 'file-too-large':
                    rejectMessage = `File exceedes size limit of ${ MAX_FILE_UPLOAD_SIZE_MB }MB`;
                    break;
                case 'file-invalid-type':
                    rejectMessage = `Wrong file type. We accept JPG, JPEG, GIF or PNGs.`;
                    break;
                default:
                    rejectMessage = file.errors[0].message;
            }
            toast.error(`${ file.file.name } not uploaded (${ rejectMessage })`);
            recordError({ message: rejectMessage }, false);
        });
        acceptedFiles.forEach( async (file) => {
            let addedFile = {
                mimeType: file.type,
                isUploading: true,
                isUploaded: false,
                title: file.name,
                progress: 0,
                hasErrors: false,
                errorText: null,
                _links: null,
            };
            setFile(addedFile);

            const response = await HTTP
                .upload(
                    file,
                    Object.assign({}, uploadDataObject, {
                        title: file.name,
                        isImage: true,
                        isPrivate: false,
                    }),
                    (progressEvent, value) => { addedFile.progress = progressEvent.loaded / progressEvent.total; }
                ).catch((error) => recordError(error));

            if (response) {
                addedFile = {
                    ...addedFile,
                    isUploading: false,
                    isUploaded: true,
                    _links: {
                        href: Response.getLink(response.data, 'self'),
                        title: response.data.title,
                        download: Response.getLink(response.data, 'download'),
                        publicUrl: Response.getLink(response.data, 'public-url'),
                    },
                    title: response.data.title,
                };

                setFile(addedFile);
                onUpdate(addedFile._links);

                return true;
            }
            recordError({ message: "Unable to upload file" });

            return false;
        });
    }

    const recordError = (error, showToast = true) => {
        const response = error.response;
        const addedFile = { ...file, hasErrors: true, isUploading: false };

        if (error.request && error.request.status === 413) {
            addedFile.errorText = `File exceedes size limit of ${MAX_FILE_UPLOAD_SIZE_MB}MB`;
        } else if (response && response.data[0].hasOwnProperty('message')) {
            addedFile.errorText = response.data[0].message ;
        } else {
            addedFile.errorText = error.message;
        }

        setFile(addedFile);
        showToast && toast.error('Unable to upload file');
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: acceptedFormats.join(", "),
        maxSize: maxFileSize,
        minSize: 1,
        maxFiles: 1,
    });

    return <>
        <div className="file-upload-wrap">
            { file.isUploading && (
                <div className={`file-upload-loading ${loadingClass}`}>
                    <LoadingIndicator size="xl"/>
                    { showUploadingText && (
                        <>
                            <div>Uploading</div>
                            <i>Please wait while we upload your image to our server</i>
                        </>
                    )}
                </div>
            ) }
            <div {...getRootProps({
                className: `file-upload ${wrapperClass}`
            })} style={ { display: file.isUploading ? 'none' : undefined } }>
                <input {...getInputProps({
                    id: 'logo',
                })} />
                { defaultLogo && (
                    <img
                        src={ defaultLogo }
                        className={imageClass}
                        onDragStart={ (e) => e.preventDefault() }
                        alt="Default logo"
                        title="Default logo"
                    />
                ) }
                { file.hasErrors && (
                    <div className='file-upload-error'>
                        <p>{ file.errorText }</p>
                    </div> 
                )}
                {
                    isDragActive ?
                        <div className="dragging-frame">Drop the logo here ...</div> :
                        <div className="drag-frame">
                            Upload a company Logo.
                            <br/><i>Drag your file and drop it here,</i>
                            <br/><i>or <u>click to select file</u></i>
                        </div>
                }
            </div>
        </div>
    </>;
}
LogoUpload.propTypes = {
    onUpdate: PropTypes.func.isRequired,
    defaultLogo: PropTypes.string,
    uploadDataObject: PropTypes.object,
    imageClass: PropTypes.string,
    loadingClass: PropTypes.string,
    wrapperClass: PropTypes.string,
    showUploadingText: PropTypes.bool
}
export default LogoUpload;
