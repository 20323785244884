import { useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { withRouter } from 'react-router';

import { loginSuccess } from 'action/auth';
import { LoadingIndicator } from 'components/elements';
import {
    CompanyFields,
    CompanySearch,
    LocationsServiced,
    ReferralCode,
    ServicesOffered,
    UserDetails,
} from 'components/pre-auth/register';

const WizardPanel = (props) => {
    const {
        openPanel,
        closePanel,
        panel,
        page,
        submitForm,
        isExhibitions,
        cancelUrl,
        history,
        ...data
    } = props;
    const [formSubmitted, setFormSubmitted] = useState(false);
    const store = useStore();

    const nextPage = () => {
        openPanel({
            props: Object.assign(data, {
                page: page + 1,
                submitForm: submitForm,
                cancelUrl,
                isExhibitions,
                history,
            }),
            renderPanel: WizardPanel,
        });
    };

    const triggerHeightChange = () => {
        changeHeight();
    };

    useEffect(() => {
        changeHeight();
    });

    const changeHeight = () => {
        const parent = document.querySelector('.bp3-panel-stack2');
        const header = parent.querySelector('.header');
        const body = parent.querySelector('.body');
        const footer = parent.querySelector('.footer');
        if (header && body && footer) {
            const total =
                header.clientHeight + body.clientHeight + footer.clientHeight;
            parent.style.height = `${total}px`;
        }
    };

    const getErrors = () => {
        const errors = store.getState().auth.authErrors;
        return !errors ? undefined : (
            <div>
                <h4>Please correct the following errors:</h4>
                <ul>
                    {errors.map((error, index) => {
                        return (
                            <li key={'p-' + index}>
                                {error.property_path}: {error.message}
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    };
    const [errorMessage, setErrorMessage] = useState(getErrors());

    const processForm = async (event) => {
        event.preventDefault();
        const form =
            event.target.tagName.toLowerCase() === 'form'
                ? event.target
                : event.target.closest('form');
        form.querySelectorAll('input,textarea').forEach((elem) =>
            elem.classList.add('isTouched')
        );

        if (form.querySelectorAll(':invalid').length) {
            setErrorMessage(
                <div>
                    <p>Please complete the form as directed.</p>
                </div>
            );
            return false;
        }

        if (
            form.querySelectorAll('input[type="checkbox"]').length &&
            !form.querySelectorAll('input[type="checkbox"]:checked').length
        ) {
            setErrorMessage(
                <div>
                    <p>Please select at least one checkbox.</p>
                </div>
            );
            return false;
        }
        setErrorMessage(null);

        if (page + 1 < pages.length) {
            nextPage();
        } else {
            try {
                const response = await submitForm();

                if (response) {
                    setFormSubmitted(true);
                    document.querySelector('.bp3-panel-stack2').style.height =
                        '300px';

                    clearTimeout(window.registerTimer);
                    window.registerTimer = setTimeout(() => {
                        if (isExhibitions) {
                            return history.push(cancelUrl);
                        }
                        store.dispatch(loginSuccess(response.data));
                    }, 5000);
                } else {
                    updateErrors();
                }
            } catch (error) {
                updateErrors();
            }
        }
    };

    const updateErrors = () => {
        const errors = getErrors();
        if (!errors) {
            return;
        }
        setErrorMessage(errors);
    };

    const childProps = {
        nextPage,
        errorMessage,
        closePanel,
        page,
        cancelUrl,
        isExhibitions,
    };

    const pages = [
        <ReferralCode
            {...childProps}
            triggerHeightChange={triggerHeightChange}
        />,
        <CompanySearch
            {...childProps}
            triggerHeightChange={triggerHeightChange}
        />,
        <CompanyFields
            {...childProps}
            triggerHeightChange={triggerHeightChange}
            processForm={processForm}
        />,
        <ServicesOffered
            {...childProps}
            triggerHeightChange={triggerHeightChange}
        />,
        <LocationsServiced
            {...childProps}
            triggerHeightChange={triggerHeightChange}
        />,
        <UserDetails
            {...childProps}
            triggerHeightChange={triggerHeightChange}
            processForm={processForm}
        />,
    ];

    if (formSubmitted) {
        return (
            <div className="register-thank-you-message">
                <h2>Thank you</h2>
                <p>Check your inbox for your account verification email.</p>
                {!isExhibitions && (
                    <p>Please wait while we redirect you to the dashboard</p>
                )}
                <div className="center">
                    <LoadingIndicator />
                </div>
            </div>
        );
    }

    return (
        <form className="wizard-page" onSubmit={processForm}>
            {pages.map((child) => <>{child}</>)[page]}
        </form>
    );
};

export default withRouter(WizardPanel);
