import { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { H1 } from '@blueprintjs/core';

import {
    ButtonLink,
    Icon,
    ItemCount,
    Paginator,
    Reload,
    Table,
} from 'components/elements';
import { Loading, NoResultsFound } from 'components/elements/wrappers';
import DiscountService from 'service/Discount/DiscountService';
import {
    DiscountCodeStatusMapping,
    DiscountData,
    DiscountListData,
    DiscountFilter,
} from 'interface/Discount';
import { toMoney } from 'helpers/helpers';

const DiscountList = (props: any) => {
    const canAddEdit = true;
    const defaultFilters: DiscountFilter = {
        order: '-expiryDate',
    };
    const [filters, setFilters] = useState<{ [str: string]: string }>({
        order: defaultFilters.order,
    });
    const [discountCollectionResponse, setDiscountCollectionResponse] =
        useState<DiscountListData>();
    const [discountCollectionTotal, setDiscountCollectionTotal] =
        useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const getRoute = (discount: DiscountData) => {
        return '/admin/discounts/' + discount.id;
    };

    const columns: object[] = [
        { name: 'Discount Code', property: 'code', sortable: true, grow: 2 },
        {
            name: 'Start Date',
            property: 'startDate',
            type: 'date',
            sortable: true,
            grow: 1,
        },
        {
            name: 'Expiry Date',
            property: 'expiryDate',
            type: 'date',
            sortable: true,
            grow: 1,
        },
        {
            name: 'Assessment(s)',
            property: 'assessments',
            type: 'callback',
            callback: (row: DiscountData) => {
                if (row.assessments.length === 0) {
                    return 'Any Assessments';
                } else if (row.assessments.length === 1) {
                    return row.assessments[0].name;
                } else {
                    return 'Multiple Assessments';
                }
            },
            sortable: false,
            grow: 2,
        },
        {
            name: 'Service Provider(s)',
            property: 'serviceProviders',
            type: 'callback',
            callback: (row: DiscountData) => {
                if (row.serviceProviders.length === 0) {
                    return 'Any Service Providers';
                } else if (row.serviceProviders.length === 1) {
                    return row.serviceProviders[0].registeredCompanyName;
                } else {
                    return 'Multiple Service Providers';
                }
            },
            sortable: false,
            grow: 2,
        },
        {
            name: 'Discount %',
            property: 'discount',
            type: 'callback',
            callback: (row: DiscountData) => {
                return row.discount + '%';
            },
            sortable: false,
            grow: 1,
        },
        {
            name: 'Usage Limit',
            property: 'usageLimit',
            type: 'callback',
            callback: (row: DiscountData) => {
                if (row.isLimitedToSpecificServiceProviders) {
                    return row.usageLimit;
                } else {
                    return 'Unlimited';
                }
            },
            sortable: false,
            grow: 1,
        },
        {
            name: 'Times Used',
            property: 'usage',
            sortable: false,
            grow: 1,
        },
        {
            name: 'Code Status',
            property: 'codeStatus',
            type: 'mapping',
            mapping: DiscountCodeStatusMapping,
            sortable: false,
            grow: 1,
        },
        {
            name: <span>Revenue <br/>(Ex VAT)</span>,
            property: 'usageRevenue',
            type: 'callback',
            callback: (row: DiscountData) => {
                return toMoney(row.usageRevenue);
            },
            sortable: true,
            grow: 1,
        },
        {
            name: '',
            property: '',
            type: 'nav-button',
            grow: 1,
            route: getRoute,
            buttonIntent: 'primary',
            buttonText: 'View',
            right: true,
        },
    ];

    const load = (params = {}) => {
        (async () => {
            if (isLoading) {
                return;
            }

            setIsLoading(true);
            const filterParams = Object.assign(
                {},
                defaultFilters,
                props.filter,
                filters,
                params
            );
            setFilters({ ...filters, ...params });
            const discountList: DiscountListData =
                await DiscountService.loadList(filterParams);
            setDiscountCollectionTotal(discountList.count);
            setDiscountCollectionResponse(discountList);
            setIsLoading(false);
        })();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(load, []);

    return (
        <div className="DiscountList">
            <H1 className="clearfix">
                Discounts List
                <ItemCount count={discountCollectionTotal} />
                <Reload load={load} />
                {canAddEdit && (
                    <ButtonLink
                        type="button"
                        to={`${props.match.url}/add`}
                        intent="primary"
                        className="float-right"
                    >
                        <Icon icon="plus-circle" />
                        Add new Discount
                    </ButtonLink>
                )}
            </H1>

            <Loading isLoading={isLoading}>
                <NoResultsFound count={discountCollectionTotal}>
                    <Table
                        data={discountCollectionResponse?.discounts}
                        columns={columns}
                        onSort={load}
                        ordering={filters.order}
                    />
                </NoResultsFound>

                <Paginator
                    page={discountCollectionResponse?.page}
                    count={discountCollectionResponse?.count}
                    limit={discountCollectionResponse?.limit}
                    onPage={load}
                />
            </Loading>
        </div>
    );
};

export default withRouter(DiscountList);
