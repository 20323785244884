const USER_PASSWORD_LENGTH_MINIMUM = 8;

const USER_TITLE_OPTIONS_MR = 'Mr';
const USER_TITLE_OPTIONS_MRS = 'Mrs';
const USER_TITLE_OPTIONS_MS = 'Ms';
const USER_TITLE_OPTIONS_MISS = 'Miss';
const USER_TITLE_OPTIONS_DR = 'Dr';
const USER_TITLE_OPTIONS_OTHER = 'Other';
const USER_TITLE_OPTIONS = [
    USER_TITLE_OPTIONS_MR,
    USER_TITLE_OPTIONS_MRS,
    USER_TITLE_OPTIONS_MS,
    USER_TITLE_OPTIONS_MISS,
    USER_TITLE_OPTIONS_DR,
    USER_TITLE_OPTIONS_OTHER,
];

const STATUS_INACTIVE = false;
const STATUS_ACTIVE = true;

const USER_STATUSES = [STATUS_ACTIVE, STATUS_INACTIVE];
const USER_STATUS_TRANSLATION = [
    { value: STATUS_ACTIVE, label: 'Active' },
    { value: STATUS_INACTIVE, label: 'Inactive' },
]

export {
    USER_PASSWORD_LENGTH_MINIMUM,

    USER_TITLE_OPTIONS_MR,
    USER_TITLE_OPTIONS,

    STATUS_INACTIVE,
    STATUS_ACTIVE,
    USER_STATUSES,
    USER_STATUS_TRANSLATION,
};
