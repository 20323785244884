import { H3 } from '@blueprintjs/core';
import { useEffect, useReducer } from 'react';

import { DonutChart, TooltipIcon } from 'components/elements';
import { Loading } from 'components/elements/wrappers';
import { SupplyChainSPCountReducerState } from 'interface';
import { ClientService } from 'service';

const SPAssessmentCharts = (props: any) => {
    const { client, selectedCategory } = props;
    const [supplyChainSPState, setSupplyChainSPState]: [
        state: SupplyChainSPCountReducerState,
        setState: React.ReducerWithoutAction<any>
    ] = useReducer(
        (state: SupplyChainSPCountReducerState, edits: any) => {
            return { ...state, ...edits };
        },
        {
            isLoading: true,
            SSIPCounts: void 0,
            prequalifiedCounts: void 0,
            SSIPFraction: 0,
            PrequalifiedFraction: 0,
            SSIPColours: [
                {
                    name: 'ssipverified',
                    label: 'Prosure SSIP Verified',
                    colour: '#7bbb4c',
                },
                {
                    name: 'ssipdts',
                    label: 'Prosure SSIP DTS',
                    colour: '#1785aa',
                },
                { name: 'neither', label: 'Neither', colour: '#ed5565' },
            ],
            prequalifiedColours: [
                {
                    name: 'prequalified',
                    label: 'Pre-Qualified Active',
                    colour: '#7bbb4c',
                },
                { name: 'none', label: 'No Assessment', colour: '#ed5565' },
            ],
        }
    );
    const load = async () => {
        setSupplyChainSPState({ isLoading: true });

        if (client === null || !client?.id) {
            setSupplyChainSPState({
                isLoading: false,
                SSIPCounts: void 0,
                prequalifiedCounts: void 0,
            });
            return;
        }

        setSupplyChainSPState(
            await ClientService.getSPCountData(client, selectedCategory)
        );
    };

    useEffect(() => {
        load();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client, selectedCategory]);

    return (
        <>
            <section className="supply-chain-card info-card chart-parent">
                <section className="info-card-header">
                    <h2 className="title">
                        <TooltipIcon
                            text={
                                <section>
                                    Percentage of the Service Providers in your
                                    supply chain that have a Prosure SSIP
                                    Verified or DTS assessment in place
                                    <br />
                                    <br />
                                    Green - Prosure SSIP Verified
                                    <br />
                                    Blue - Prosure SSIP DTS
                                    <br />
                                    Red - Neither
                                    <br />
                                    <br />
                                    Central figure is a percentage sum of green
                                    and blue service providers, all those
                                    currently with a Prosure SSIP Verified or
                                    DTS assessment in place.
                                </section>
                            }
                            icon="info"
                        />
                        <span className="ps-2">
                            Service Providers with Prosure SSIP or DTS
                            Assessments
                        </span>
                    </h2>
                </section>
                <section className="info-card-body flex-justify-center flex-v-center">
                    <Loading isLoading={supplyChainSPState.isLoading}>
                        {!supplyChainSPState.SSIPCounts ? (
                            <H3>
                                There are currently no Service Providers in your
                                supply chain
                            </H3>
                        ) : (
                            <DonutChart
                                data={supplyChainSPState.SSIPCounts}
                                height={300}
                                width={250}
                                legendWidth={210}
                                text={supplyChainSPState.SSIPFraction + '%'}
                                colours={supplyChainSPState.SSIPColours ?? []}
                            />
                        )}
                    </Loading>
                </section>
            </section>

            <section className="supply-chain-card info-card chart-parent">
                <section className="info-card-header">
                    <h2 className="title">
                        <TooltipIcon
                            text={
                                <section>
                                    Percentage of the Service Providers in your
                                    supply chain that have a Prosure
                                    Pre-Qualified assessment in place
                                    <br />
                                    <br />
                                    Green - Pre-Qualified Active
                                    <br />
                                    Red - No assessment
                                    <br />
                                    <br />
                                    Central figure is a percentage sum of green
                                    service providers, all those currently with
                                    a Prosure Pre-Qualified assessment in place.
                                </section>
                            }
                            icon="info"
                        />
                        <span className="ps-2">
                            Service Providers with Prosure Pre-qualified
                            Assessments
                        </span>
                    </h2>
                </section>
                <section className="info-card-body flex-justify-center flex-v-center">
                    <Loading isLoading={supplyChainSPState.isLoading}>
                        {!supplyChainSPState.prequalifiedCounts ? (
                            <H3>
                                There are currently no Service Providers in your
                                supply chain
                            </H3>
                        ) : (
                            <DonutChart
                                data={supplyChainSPState.prequalifiedCounts}
                                height={250}
                                width={250}
                                legendWidth={210}
                                text={
                                    supplyChainSPState.prequalifiedFraction +
                                    '%'
                                }
                                colours={
                                    supplyChainSPState.prequalifiedColours ?? []
                                }
                            />
                        )}
                    </Loading>
                </section>
            </section>
        </>
    );
};

export default SPAssessmentCharts;
