import { ComponentProps } from 'react';
import { useSelector } from 'react-redux';

import QuestionErrors from 'components/assessments/tabs/questions/components/QuestionErrors';
import QuestionReview from 'components/assessments/tabs/questions/components/QuestionReview';
import { Question } from 'components/elements';
import {
    isAssessorReviewer,
    isClientAdmin,
    isCompanyAdmin,
} from 'helpers/helpers';
import {
    AssessmentStateData,
    QuestionInstanceData,
    SortedTopic,
} from 'interface';
import {
    checkIsReviewDisabled,
    getAnswerOrNew,
    isValidConditionalQuestion,
} from 'service';
import { checkIsClientAssessorReviewerRequired } from 'service/AssessmentType/Questions/QuestionService';

interface TopicQuestionsProps extends ComponentProps<any> {
    mode: string;
    sortedTopic: SortedTopic;
    isSubmitDisabled: boolean;
    onUpdate: Function;
}

const TopicQuestions = (props: TopicQuestionsProps) => {
    const { mode, sortedTopic, isSubmitDisabled, onUpdate } = props;
    const { assessmentTypeInstance }: AssessmentStateData = useSelector(
        (state: any) => state.assessments
    );

    const isEditable = !!assessmentTypeInstance._links['update-answers'];
    const canReviewerFileEdit =
        !!assessmentTypeInstance._links['reviewer-assessor-file-edit'];

    let showReviewForm =
        mode === 'review' ||
        (((checkIsClientAssessorReviewerRequired() === true &&
            isClientAdmin()) ||
            isAssessorReviewer() ||
            isCompanyAdmin()) &&
            [
                // not these statuses
                'status_purchased',
                'status_in_progress',
                'status_submitted_awaiting_assessor',
            ].indexOf(assessmentTypeInstance.status) === -1);

    return (
        <>
            {sortedTopic.sortedQuestions
                .filter((instance: QuestionInstanceData) =>
                    isValidConditionalQuestion(instance, sortedTopic)
                )
                .map((questionInstance, index: number) => {
                    const question = questionInstance.question;
                    const answerResponse = getAnswerOrNew(question);

                    const ownershipTaken = !checkIsReviewDisabled();
                    const isFileInput = question.type === 'file';
                    const hasFileRequirement = question.fileRequirement;

                    const isFileEditable =
                        mode === 'company'
                            ? isEditable && !isSubmitDisabled
                            : mode === 'review' &&
                              ownershipTaken &&
                              (isFileInput || hasFileRequirement) &&
                              canReviewerFileEdit &&
                              !isSubmitDisabled;

                    return (
                        <div
                            className="flex flex-wrap"
                            key={`question_${question.id}`}
                        >
                            <div className="flex-50">
                                <QuestionErrors questionId={question.id} />
                                <Question
                                    key={index}
                                    questionResponse={question}
                                    onUpdate={onUpdate}
                                    answerResponse={answerResponse}
                                    isEditable={isEditable && !isSubmitDisabled}
                                    isFileEditable={isFileEditable}
                                    mode={mode}
                                />
                            </div>
                            {showReviewForm && (
                                <QuestionReview
                                    question={question}
                                    answer={answerResponse}
                                    mode={mode}
                                />
                            )}
                        </div>
                    );
                })}
        </>
    );
};

export default TopicQuestions;
