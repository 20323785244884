import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class Icon extends Component
{
    render() {
        const { prefix, icon, ...others } = this.props;

        return <FontAwesomeIcon icon={[prefix || 'fas', icon]} {...others} />
    }
}
