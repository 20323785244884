export const SIDEBAR_COLLAPSE = 'SIDEBAR_COLLAPSE';
export const SIDEBAR_TOGGLE = 'SIDEBAR_TOGGLE';

export function collapse()
{
    return {
        type: SIDEBAR_COLLAPSE,
    };
}

export function toggle()
{
    return {
        type: SIDEBAR_TOGGLE,
    };
}
