import React, { Component } from 'react';
import { Switch } from 'react-router-dom';
import { ProtectedRoute } from 'components/elements';
import RegionList from 'components/admin/regions/RegionList';
import RegionAddEdit from 'components/admin/regions/RegionAddEdit';
import RegionDetails from 'components/admin/regions/RegionDetails';

class Regions extends Component
{
    render()
    {
        const { match } = this.props;

        return (
            <div className="Regions">
                <Switch>
                    <ProtectedRoute path={`${match.url}`} component={ RegionList } exact />
                    <ProtectedRoute path={`${match.url}/add`} component={ RegionAddEdit } exact />
                    <ProtectedRoute path={`${match.url}/:id/edit`} component={ RegionAddEdit } />
                    <ProtectedRoute path={`${match.url}/:id`} component={ RegionDetails } />
                </Switch>
            </div>
        );
    }
}

export default Regions;
