import type { RouteComponentProps } from 'react-router';
import { FlatObject, ListData } from './index';

export interface SupplyChain {
    id: string;
    status: number;
    createdAt: string;
    updatedAt: string;
    expiresOn: string;
    acceptedAt: string;
    suspensionReason: string;
    isSuspended: boolean;
    calculatedStatus: string;
    serviceProviderCategoryName: string;
    originalAcceptedAtDate: string;
    originalInvitedAtDate: string;
    removedWhenExpired: boolean;
    isBeingRenewed: boolean;
    isRenewing: boolean;
    isRenewal: boolean;
    removedAt: string;
    removeReason: string;
    removedByForename: string;
    removedBySurname: string;
    declinedAt: string;
    declinedReason: string;
    paymentAmount?: string;
    paymentTax?: string;
    refundAmount?: string;
    refundTax?: string;
    clientPricePortion?: string;
    prosurePricePortion?: string;
    cancelledAt?: string;
    archivedAt?: string;
    cancelledBy?: string;
    cancelledReason?: string;
    cancelledReasonDetail?: string;
    registeredCompanyName?: string;
    _links?: FlatObject;
}

export const DECLINED_STATUS = 2;
export const REMOVED_STATUS = 3;
export const CLIENT_REMOVED_STATUS = 4;

export const SupplyChainStatusMapping = {
    0: 'Pending',
    1: 'Active',
};

type StringStatusMap = {
    [key: string]: string;
};

export const SupplyChainCalculatedStatusMapping: StringStatusMap = {
    'in-supply-chain': 'In Supply Chain',
    'invited-renewal': 'Invited / Renewal',
    'removed-expired': 'Removed / Expired',
    declined: 'Declined',
};

export interface SupplyChainFilter {
    order: string;
    _links: any;
    status: string;
    registeredCompanyName?: string;
    clientName?: string;
}

export interface SupplyChainList extends ListData {
    supplyChainInstances: SupplyChain[];
}

export interface ElogsCAFMStatsProps extends RouteComponentProps<any> {
    supplyChain: SupplyChain;
}
