import { FlatObject } from 'interface';
import { uniq } from 'lodash';

const RfaSicCodes = (props: { data?: FlatObject }) => {
    const data = uniq([
        ...(props.data?.sic07_codes ?? []),
        ...(props.data?.sic92_codes ?? []),
    ]);

    const desc = uniq([
        ...(props.data?.sic07_descriptions ?? []),
        ...(props.data?.sic92_descriptions ?? []),
    ]);

    return (
        <div className="rfa-profit-loss">
            {data.length
                ? data.map((row: string, index: number) => (
                      <dl key={'sic-' + index}>
                          <dt>{row}</dt>
                          <dd>{desc[index]}</dd>
                      </dl>
                  ))
                : 'No data to display'}
        </div>
    );
};

export default RfaSicCodes;
