const routes = {
    admin_serviceproviders: {
        href: '/admin/serviceproviders',
        text: 'Service Providers',
        icon: 'user-hard-hat',
    },
    admin_serviceproviders_details: {
        href: '/admin/serviceproviders/:id',
        text: '{serviceProvider.registeredCompanyName}',
        icon: 'info',
    },
    admin_serviceproviders_details_edit: {
        href: '/admin/serviceproviders/:id/info/edit',
        text: 'Edit',
        icon: 'pencil',
        skipNavigation: true,
    },
    admin_serviceproviders_details_regions: {
        href: '/admin/serviceproviders/:id/regions',
        text: 'Regions',
        icon: 'map-marker',
    },
    admin_serviceproviders_details_users: {
        href: '/admin/serviceproviders/:id/users',
        text: 'Users',
    },
    admin_serviceproviders_details_clients: {
        href: '/admin/serviceproviders/:id/clients',
        text: 'Clients',
    },
    admin_serviceproviders_details_services: {
        href: '/admin/serviceproviders/:id/services',
        text: 'Services',
    },
    admin_serviceproviders_details_info: {
        href: '/admin/serviceproviders/:id/info',
        text: 'Company Details',
    },
    admin_serviceproviders_details_regions_edit: {
        href: '/admin/serviceproviders/:id/regions/edit',
        text: 'Edit',
        icon: 'pencil',
    },
};

export default routes;
