import React, { Component } from 'react';
import { ButtonLink } from 'components/elements';

class SubmitAllowable extends Component
{
    render()
    {
        const { className } = this.props;

        return (
            <>
                <div className={ className + " SubmitAllowable intent intent-info" }>
                    Submit Allowable

                    <ButtonLink type="button"
                        to="/company/submit">
                        Submit Application
                    </ButtonLink>
                </div>
            </>
        );
    }
}

export default SubmitAllowable;
