import { PanelStack2 } from '@blueprintjs/core';
import { clearAuthErrors, updateRegisterForm } from 'action/auth';
import { ORGANISATION_TYPE_OTHER } from 'constants/company';
import { useEffect } from 'react';
import { useStore } from 'react-redux';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import HTTP from 'service/HTTP';
import { WizardPanel } from './register';
import './register/index.scss';

window.grecaptcha = window.grecaptcha || { execute: () => {} };

const RegisterWizard = (props) => {
    let recaptchaScript,
        recaptchaWidget,
        captchaReady = false;
    const store = useStore();
    const isExhibitions = props.location.pathname.includes('exhibitions');
    const cancelUrl = isExhibitions ? '/signup-exhibitions' : '/login';

    useEffect(() => {
        // useEffect doesn't support async, this is the recommended alternative
        (async () => {
            const services = await getServices();
            const regions = await getRegions();
            // reset on load
            store.dispatch(
                updateRegisterForm({
                    referralCode: '',
                    registeredCompanyName: '',
                    tradingName: '',
                    companyBio: '',
                    companiesHouseRegistrationNumber: '',
                    webAddress: '',
                    addressLine1: '',
                    addressLine2: '',
                    addressLine3: '',
                    town: '',
                    county: '',
                    country: 'GB',
                    postCode: '',
                    uniqueTaxReferenceNumber: '',
                    cisNumber: '',
                    directEmployees: '1',
                    bonaFideSubContractors: '0',
                    labourOnly: '0',
                    organisationSize: '1',
                    organisationType: ORGANISATION_TYPE_OTHER,
                    isPartOfGroup: false,
                    isServiceBySiteVisit: 'true',
                    firstName: '',
                    surname: '',
                    jobTitle: '',
                    email: '',
                    phone: '',
                    password: '',
                    passwordMatch: '',
                    captcha: '',
                    services: services,
                    regions: regions,
                })
            );
        })();
        // clear any previous form errors
        store.dispatch(clearAuthErrors());

        const url = 'https://www.google.com/recaptcha/api.js';
        const queryString = '?onload=onRecaptchaLoad&render=explicit';
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url + queryString;
        script.async = true;
        script.defer = true;

        // eslint-disable-next-line
        recaptchaScript = document.body.appendChild(script);
        window.onRecaptchaLoad = onRecaptchaLoad;

        return () => {
            document.body.removeChild(recaptchaScript);
            document.body.removeChild(recaptchaWidget);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onRecaptchaLoad = () => {
        const widget = document.createElement('div');
        widget.id = 'g-recaptcha';
        recaptchaWidget = document.body.appendChild(widget);

        window.grecaptcha.render('g-recaptcha', {
            sitekey: process.env.REACT_APP_RECAPTCHA3_KEY,
            size: 'invisible',
        });

        window.grecaptcha.ready(() => {
            captchaReady = true;
        });
    };

    const convertToCheckboxObject = (apiObject, name) => {
        return {
            title: apiObject.name,
            value: apiObject._links.self,
            name: name,
            checked: false,
            children:
                apiObject.children && apiObject.children.length
                    ? apiObject.children.map((child) =>
                          convertToCheckboxObject(child, name)
                      )
                    : void 0,
        };
    };

    const convertToFlatSelectedArray = (
        checkboxObjectArray,
        selectedArray = []
    ) => {
        checkboxObjectArray.forEach((checkboxObject) => {
            if (checkboxObject.checked) {
                selectedArray.push(checkboxObject.value);
            }
            if (checkboxObject.children) {
                convertToFlatSelectedArray(
                    checkboxObject.children,
                    selectedArray
                );
            }
        });
        return selectedArray;
    };

    const getServices = () => {
        return HTTP.get('/registration/services', {
            limit: 999,
            order: 'name',
        }).then((response) => {
            if (response && response.data && response.data.services) {
                return response.data.services.map((apiService) =>
                    convertToCheckboxObject(apiService, 'services[]')
                );
            }

            toast.error('Unable to fetch Services');

            return [];
        });
    };

    const getRegions = () => {
        return HTTP.get('/registration/regions', { limit: 999 })
            .then((response) => {
                if (response && response.data && response.data.regions) {
                    return response.data.regions.map((apiService) =>
                        convertToCheckboxObject(apiService, 'regions[]')
                    );
                }

                toast.error('Unable to fetch Regions');

                return [];
            })
            .then((checkboxData) => {
                // open up UK wide dropdown by default
                if (checkboxData.length === 1) {
                    checkboxData[0].showChildren = true;
                }
                return checkboxData;
            });
    };

    const submitForm = async () => {
        if (!captchaReady) {
            toast.error('Captcha not loaded! Submission not possible.');
            return;
        }

        const form = Object.assign({}, store.getState().auth.form);
        form.captcha = await window.grecaptcha.execute();
        form._links = {
            services: await convertToFlatSelectedArray(form.services),
            regions: await convertToFlatSelectedArray(form.regions),
        };
        delete form.services;
        delete form.regions;

        return await HTTP.action(
            'post',
            '/register',
            form,
            {},
            'Registration error',
            HTTP.handleFormErrors
        );
    };

    const initialPanel = {
        props: { page: 0, submitForm, cancelUrl, isExhibitions },
        renderPanel: WizardPanel,
    };

    return (
        <div className="RegisterWizard">
            <PanelStack2
                className="box"
                initialPanel={initialPanel}
                showPanelHeader={false}
            />
        </div>
    );
};

export default withRouter(RegisterWizard);
