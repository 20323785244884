import { H2 } from '@blueprintjs/core';
import { Link } from 'react-router-dom';

import DashboardCard from 'components/dashboard/DashboardCard';
import { FlatObject } from 'interface';

interface DashboardTaskCardProps {
    isLoading: boolean;
    counts: FlatObject;
}

const DashboardCompanyCountsCard = (props: DashboardTaskCardProps) => {
    const { isLoading, counts } = props;

    return (
        <DashboardCard
            isFullWidth
            isLoading={isLoading}
            title="Service Providers"
        >
            <ul className="info-card-stats service-providers">
                <li>
                    <Link
                        className="no-decoration"
                        to="/service-providers?datagroup=search"
                    >
                        <H2 className="stat">
                            {counts.companyCountActive +
                                counts.companyCountInactive}
                        </H2>
                        <span className="stat-text">All Service Providers</span>
                    </Link>
                </li>
                <li>
                    <Link
                        className="no-decoration"
                        to="/service-providers?isActive=true&datagroup=search"
                    >
                        <H2 className="stat">{counts.companyCountActive}</H2>
                        <span className="stat-text">
                            Active Service Providers
                        </span>
                    </Link>
                </li>
                <li>
                    <Link
                        className="no-decoration"
                        to="/service-providers?isActive=false&datagroup=search"
                    >
                        <H2 className="stat">{counts.companyCountInactive}</H2>
                        <span className="stat-text">
                            Inactive Service Providers
                        </span>
                    </Link>
                </li>
            </ul>
        </DashboardCard>
    );
};

export default DashboardCompanyCountsCard;
