import React, { useState } from 'react';
import { Button, Checkbox } from "@blueprintjs/core";
import StateHandler from "service/StateHandler";
import Icon from "components/elements/Icon";
import './index.scss';

/**
 * Each checkbox field should have the properties as below:
 *
 * title: string,
 * value: ID string,
 * name: string,
 * checked: boolean,
 * indeterminate: undefined or boolean,
 * children: undefined or array of checkboxes
 *
 */
const MultiLevelCheckbox = (props) => {
    const { field, onUpdate, classNames } = props;
    const [showChildren, setShowChildren] = useState(!!field.showChildren);
    const className = 'multi-level-checkbox '
        + (field.children ? 'parent ' : 'single ')
        + (showChildren ? 'showChildren ' : 'hideChildren ')
        + classNames;

    const setTreeValues = (fieldUpdated) => {
        if (!field.children) {
            field.indeterminate = false;
        } else if (field.children && field.children.every((child) => child.checked)){
            field.indeterminate = false;
            field.checked = true;
        } else {
            field.indeterminate = field.children.reduce((isIndeterminate, child) => {
                return isIndeterminate || !!child.checked || !!child.indeterminate;
            }, false);
            field.checked = false;
        }

        if (typeof onUpdate === 'function') {
            // always return parent node (due to propagating changes)
            onUpdate(field);
        }
    }

    const checkChildren = (children) => {
        return children.forEach((child) => {
            child.indeterminate = false;
            child.checked = field.checked;
            if (child.children) {
                checkChildren(child.children);
            }
        });
    }

    const checkChecked = (fieldObj) => {
        field.checked = fieldObj[field.name];
        if (field.children) {
            checkChildren(field.children);
        }
        setTreeValues(field);
    }

    const toggleShowChildren = () => {
        field.showChildren = !showChildren;
        setShowChildren(field.showChildren);

        // wait for styling to catch up
        setTimeout(()=>{
            if (typeof onUpdate === 'function') {
                onUpdate(field);
            }
        }, 0);
    }

    return (
        <>
            {
                <div className={ className } >
                    <label>
                        <div className="title">
                            {
                                !field.children ? null :
                                    <Button onClick={ toggleShowChildren }>
                                        <Icon icon={ showChildren ? 'minus':'plus' } />
                                    </Button>
                            }
                            <span>{ field.title }</span>
                        </div>
                        <Checkbox
                            className="multi-level-checkbox-input"
                            type="checkbox"
                            name={ field.name }
                            value={ field.value }
                            indeterminate={ field.indeterminate }
                            checked={ field.checked }
                            onChange={ e => checkChecked(StateHandler.getStateObject(e)) }
                        />
                    </label>
                    {
                        !field.children ? null :
                            <div className="children">
                                { field.children.map((child, key) =>
                                    <MultiLevelCheckbox
                                        field={child}
                                        key={ 'checkbox-child-' + key }
                                        onUpdate={ setTreeValues } />
                                )}
                            </div>
                    }
                </div>
            }
        </>
    );
}
export default MultiLevelCheckbox;
