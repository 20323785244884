import { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { Button, FormGroup } from "@blueprintjs/core";
import { toast } from "react-toastify";

import { HTTP } from 'service';
import { Loading } from "components/elements/wrappers";
import { ORGANISATION_SIZES } from "constants/company";
import { Table } from "components/elements";
import { delay, globalDebounce } from "helpers/helpers";

const OrganisationSizesPricingForm = (props) => {
    const [formRows, setFormRows] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const columns = [
        { name: 'Number of effective employees', property: 'organisationSize' },
        ...props.pricingBands
    ];

    /** on mount **/
    useEffect(() => {
        let rows = [];
        ORGANISATION_SIZES.forEach(organisationSize => {
            const pricingFields = [];

            props.pricingBands.forEach(band => {
                pricingFields[band.propertyField] = { value: "0.00", toString: function(){ return this.value; } };
            });

            rows.push({
                organisationSize,
                ...pricingFields
            });
        });
        setFormRows(rows);
    }, [ props.pricingBands ]);

    /** on state change **/
    useEffect(() => {
        if (formRows.length) {
            globalDebounce(mergeSavedRows, 'mergeSavedRows', 50);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formRows]);

    /** generate text field */
    const getRowTextfield = (row, name) => {
        return (
            <FormGroup
                inline={true}
                className="form-fill table-input">
                <CurrencyInput
                    id={ row.organisationSize + "_" + name + "Input" }
                    name={ row.organisationSize + "_" + name + "Input" }
                    className="bp3-input currencyInput"
                    placeholder="Please enter a number"
                    value={ row[name] }
                    allowNegativeValue={ false }
                    decimalsLimit={2}
                    maxLength={10}
                    disableAbbreviations={true}
                    step={1}
                    required
                    prefix="£"
                    onValueChange={
                        (value) =>
                            updateRowValue(row.organisationSize, name, value)
                    }
                />
            </FormGroup>
        );
    }

    const updateRowValue = (organisationSize, name, value) => {
        if (isNaN(value)) {
            value = '0';
        }

        const index = formRows
            .map(row => row.organisationSize)
            .indexOf(organisationSize);

        formRows[index][name].value = value;
    }

    /** fetch on mount */
    const mergeSavedRows = async () => {
        setIsLoading(true);
        const response = await HTTP.get(props.loadLink);
        formRows.forEach((row) => {
            props.pricingBands.forEach(band => {
                row[band.property] = getRowTextfield(row, band.propertyField)
            });
        });

        if (!response) {
            return toast.error('Unable to fetch saved Pricing');
        }

        response.data.pricing.forEach(pricing => {
            const index = formRows
                .map(row => row.organisationSize)
                .indexOf(pricing.organisationSize);

            props.pricingBands.forEach(band => {
                formRows[index][band.propertyField].value = pricing[band.propertyField].toFixed(2);
            });
        });
        setIsLoading(false);
        await delay(50);
        if (document.querySelector('.currencyInput')) {
            document.querySelector('.currencyInput').focus();
        }
    }

    /** on save */
    const saveForm = async (e) => {
        e.preventDefault();

        const formValues = formRows.map(row => {
            const pricingFields = [];

            props.pricingBands.forEach(band => {
                pricingFields[band.propertyField] = typeof row[band.propertyField].value !== 'object' ? row[band.propertyField].value : "0.00";
            });

            return {
                organisationSize: row.organisationSize,
                ...pricingFields
            }
        });

        const result = await HTTP.put(props.updateLink, { 'pricings' : formValues });

        if(result) {
            return toast.success("Successfully saved pricing");
        }
    }

    return <>
        <Loading isLoading={isLoading}>
            <form onSubmit={ saveForm } className="clearfix">
                <Table data={ formRows }
                    columns={ columns } />
                <div className="mt-4 float-right">
                    <Button type="submit" intent="primary">Submit</Button>
                </div>
            </form>
        </Loading>
    </>
}

export default OrganisationSizesPricingForm;
