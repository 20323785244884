import { NONE_SELECTED_OPTION } from 'constants/none-selected';

const ACTIVE_OPTIONS_ACTIVE = {label: 'Active', value: 'true'};
const ACTIVE_OPTIONS_INACTIVE = {label: 'Inactive', value: 'false'};
const ACTIVE_OPTIONS = [
    ACTIVE_OPTIONS_ACTIVE,
    ACTIVE_OPTIONS_INACTIVE,
];
const ACTIVE_OPTIONS_WITH_NONE_SELECTED = [
    NONE_SELECTED_OPTION,
    ACTIVE_OPTIONS_ACTIVE,
    ACTIVE_OPTIONS_INACTIVE,
]

export {
    ACTIVE_OPTIONS_ACTIVE,
    ACTIVE_OPTIONS_INACTIVE,
    ACTIVE_OPTIONS,
    ACTIVE_OPTIONS_WITH_NONE_SELECTED,
};
