import React, { Component } from 'react';

class Logout extends Component
{
    render()
    {
        return (
            <button className="Logout"
                onClick={e => { this.props.logout() } }>
                Logout
            </button>
        );
    }
}

export default Logout;
