import { H2, Classes } from '@blueprintjs/core';
import { Link } from 'react-router-dom';
import { Loading } from 'components/elements/wrappers';

const DashboardCard = (props) => {
    const {
        className,
        isLoading,
        isLarge,
        isFullWidth,
        title,
        link,
        moreDetailsLink,
        value,
        moreText,
        children,
    } = props;

    const getContent = () => {
        return (
            <Loading isLoading={isLoading}>
                {value !== undefined && <H2>{value}</H2>}
                {children}
                {moreDetailsLink ? (
                    <Link className="more-details" to={moreDetailsLink}>
                        {moreText}
                    </Link>
                ) : (
                    <div className="more-details">{moreText}</div>
                )}
            </Loading>
        );
    };

    return (
        <div
            className={`dashboard-card no-styling ${isLarge ? 'large' : ''} ${
                isFullWidth ? 'full-width' : ''
            } ${className || ''}`}
        >
            <div className="dashboard-card-header">
                <h1>{title}</h1>
            </div>

            <div className="dashboard-card-body">
                {link ? (
                    <Link
                        className={
                            'dashboard-card-details dashboard-card-link ' +
                            Classes.CARD
                        }
                        to={link}
                    >
                        {getContent()}
                    </Link>
                ) : (
                    <div className={'dashboard-card-details ' + Classes.CARD}>
                        {getContent()}
                    </div>
                )}
            </div>
        </div>
    );
};

export default DashboardCard;
