import IntegationsAddMulti from './IntegrationsAddMulti';
import IntegationsAddSingle from './IntegrationsAddSingle';

const IntegrationsAdd = (props: any) => {
    return (
        <>
            <IntegationsAddSingle {...props} />
            <IntegationsAddMulti {...props} />
        </>
    );
};

export default IntegrationsAdd;
