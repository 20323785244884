import { ButtonLink, Icon, List } from 'components/elements';
import {
    ACTIVE_OPTIONS_ACTIVE,
    ACTIVE_OPTIONS_WITH_NONE_SELECTED,
} from 'constants/active';
import { NONE_SELECTED_OPTION } from 'constants/none-selected';
import { QUESTION_TYPE_OPTIONS } from 'constants/question';
import queryString from 'query-string';
import { Component } from 'react';
import { toast } from 'react-toastify';
import { Filters, Response } from 'service';
import {
    getCategoriesListForDropdown,
    getQuestions,
} from 'service/Admin/QuestionService';

const defaultFilterDataProp = {
    isActive: {
        value: ACTIVE_OPTIONS_ACTIVE.value,
        data: ACTIVE_OPTIONS_ACTIVE.value,
    },
};

class QuestionList extends Component {
    columns = [
        { name: 'Summary', property: 'summary', sortable: true, grow: 3 },
        {
            name: 'Category',
            property: 'category',
            type: 'callback',
            callback: (row) => {
                return (
                    <>{Response.getLinkAttribute(row, 'category', 'name')}</>
                );
            },
            sortable: true,
            grow: 3,
        },
        {
            name: 'Guidance Notes',
            property: 'guidanceNotes',
            sortable: true,
            grow: 8,
        },
        {
            name: 'Status',
            property: 'isActive',
            type: 'mapping',
            mapping: ACTIVE_OPTIONS_WITH_NONE_SELECTED,
            sortable: true,
            grow: 2,
        },
        { name: 'Type', property: 'type', grow: 1 },
        {
            name: '',
            property: '',
            type: 'nav-button',
            grow: 1,
            route: this.getRoute,
            buttonIntent: 'primary',
            buttonText: 'View',
            right: true,
        },
    ];

    defaultFilters = {
        order: 'summary',
    };

    static defaultProps = {
        filterData: defaultFilterDataProp,
    };

    constructor(props) {
        super(props);

        this.state = {
            collectionResponse: {},
            categoriesList: [],
            filter: queryString.parse(props.location.search),
            filterData: Filters.getFilterStateFromUrl(
                this.props.location.search.slice(1),
                props.filterData
            ),
            filterConfig: {
                category: {
                    type: 'single-select',
                    defaultValue: NONE_SELECTED_OPTION.value,
                    label: 'Category',
                    key: 'categoryId',
                    options: [NONE_SELECTED_OPTION],
                },
                isActive: {
                    type: 'single-select',
                    defaultValue: NONE_SELECTED_OPTION.value,
                    label: 'Status',
                    key: 'isActive',
                    options: ACTIVE_OPTIONS_WITH_NONE_SELECTED,
                },
                type: {
                    type: 'single-select',
                    defaultValue: NONE_SELECTED_OPTION.value,
                    label: 'Type',
                    key: 'type[]',
                    options: [NONE_SELECTED_OPTION, ...QUESTION_TYPE_OPTIONS],
                },
            },
        };

        this.load = this.load.bind(this);
        this.loadCategories = this.loadCategories.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.getHeaderButton = this.getHeaderButton.bind(this);
    }

    componentDidMount() {
        this.loadCategories();
        this.load();
    }

    getRoute(row, column) {
        return '/admin/questions/' + row.id;
    }

    getHeaderButton = (collection, match) => {
        return Response.getLink(collection, 'create') === null ? null : (
            <ButtonLink
                type="button"
                to={`${match.url}/add`}
                intent="primary"
                className="float-right"
            >
                <Icon icon="plus-circle" />
                Add New Question
            </ButtonLink>
        );
    };

    render() {
        const collection = this.state.collectionResponse || {};
        const { match } = this.props;

        return (
            <div className="QuestionList">
                <List
                    title="Question List"
                    columns={this.columns}
                    load={(filter) => this.load({ ...filter })}
                    collection={collection}
                    filter={this.state.filterData}
                    filters={this.state.filterConfig}
                    defaultFilter={this.defaultFilters}
                    isLoading={this.state.isLoading}
                    headerButton={() => this.getHeaderButton(collection, match)}
                    onClear={this.clearFilters}
                    noRecordsFoundText="There are no Clients"
                />
            </div>
        );
    }

    clearFilters() {
        this.setState({ filterData: {} });
        this.load(
            Object.assign({}, this.defaultFilters, {}, { page: 1 }),
            true
        );
    }

    changePage(page) {
        this.load(
            Object.assign(this.state.filterData, { page: page.page }),
            true
        );
    }

    loadCategories = () => {
        getCategoriesListForDropdown().then((categoriesList) => {
            this.setState({
                categoriesList,
                filterConfig: {
                    ...this.state.filterConfig,
                    category: {
                        ...this.state.filterConfig.category,
                        options: categoriesList,
                    },
                },
            });

            return true;
        });
    };

    load(params = {}, paramsOverride = false) {
        if (this.state.isLoading) {
            return;
        }

        const filterParams = Object.assign(
            {},
            this.defaultFilters,
            this.props.filter,
            params
        );

        this.setState({ isLoading: true, filterData: filterParams });

        getQuestions(filterParams).then((response) => {
            if (response) {
                this.setState({
                    isLoading: false,
                    collectionResponse: {
                        ...response.data,
                        data: response.data.questions,
                    },
                });

                return true;
            }

            toast.error('Unable to fetch a list of Questions');
            this.setState({ isLoading: false });

            return false;
        });
    }
}

export default QuestionList;
