import { NONE_SELECTED_OPTION } from 'constants/none-selected';

export enum CancelReason {
    DUPLICATE_PURCHASE = 1,
    NO_LONGER_REQUIRED,
    PURCHASED_INCORRECT_ASSESSMENT,
    SSIP_DTS_RECOMMENDATION,
    OTHER,
    CLIENT_REQUEST,
    SERVICE_PROVIDER_REQUEST,
}

export const CancelReasonMappings = {
    1: 'Duplicate Purchase',
    2: 'No Longer Required',
    3: 'Purchased Incorrect Assessment',
    4: 'SSIP DTS Recommendation',
    5: 'Other',
    6: 'Client Request',
    7: 'Service Provider Request',
};

export const CANCEL_REASON_OPTIONS = [
    NONE_SELECTED_OPTION,
    {
        label: CancelReasonMappings[CancelReason.DUPLICATE_PURCHASE],
        value: CancelReason.DUPLICATE_PURCHASE,
    },
    {
        label: CancelReasonMappings[CancelReason.NO_LONGER_REQUIRED],
        value: CancelReason.NO_LONGER_REQUIRED,
    },
    {
        label: CancelReasonMappings[CancelReason.PURCHASED_INCORRECT_ASSESSMENT],
        value: CancelReason.PURCHASED_INCORRECT_ASSESSMENT,
    },
    {
        label: CancelReasonMappings[CancelReason.SSIP_DTS_RECOMMENDATION],
        value: CancelReason.SSIP_DTS_RECOMMENDATION,
    },
    {
        label: CancelReasonMappings[CancelReason.OTHER],
        value: CancelReason.OTHER,
    },
];

export interface CancelPaymentData {
    reason: CancelReason;
    detail: string;
    date: Date;
}
