import { filter } from 'lodash';
import { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';

import { Button } from '@blueprintjs/core';
import UploadTemplate from 'components/dialogs/UploadTemplate';
import { ButtonLink, Icon, List } from 'components/elements';
import { NONE_SELECTED_OPTION } from 'constants/none-selected';
import { parseDateFormat } from 'helpers/helpers';
import { ListCollection, ListSearchFilterList } from 'interface';
import {
    CampaignComponentProps,
    CampaignInvitee,
    CampaignInviteeListFilter,
    CampaignPriorityMapping,
    CampaignPriorityOptions,
} from 'interface/Client/Campaign';
import { HTTP, Response, Routing } from 'service';
import CampaignService from 'service/Client/CampaignService';
import ServiceProviderCategoryPopup from './ServiceProviderCategoryPopup/ServiceProviderCategoryPopup';

const CampaignInviteeList = (props: CampaignComponentProps) => {
    const { match, campaign, location } = props;
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [showUploadPopup, setShowUploadPopup] = useState<boolean>(false);
    const [collection, setCollection] = useState<
        ListCollection<CampaignInvitee>
    >({} as ListCollection<CampaignInvitee>);
    const defaultFilters = {
        order: 'inviteeName',
    };
    const [listFilter, setListFilter] = useState<CampaignInviteeListFilter>({
        ...defaultFilters,
        ...Routing.parseSearchParams(location.search),
    } as CampaignInviteeListFilter);

    const showInviteButton = (invitee: CampaignInvitee) => {
        return Response.getLink(invitee, 'invite') ? (
            <button
                onClick={(event) => clickInviteButton(event, invitee)}
                className="clear-button success"
            >
                Invite
            </button>
        ) : (
            <>{parseDateFormat(invitee.invitedDate)}</>
        );
    };

    const downloadHandler = async (filter: CampaignInviteeListFilter) => {
        await CampaignService.downloadCampaignInviteeList(campaign.id, filter);

        toast.success('Invitee List Downloaded');
    };

    const downloadInviteeTemplateHandler = async () => {
        await CampaignService.downloadInviteeUploadTemplate(campaign.id);

        toast.success('Campaign Invitee Template Downloaded');
    };

    const clickDeleteButton = async (event: any, invitee: CampaignInvitee) => {
        event.preventDefault();
        event.stopPropagation();

        const deleteLink = Response.getLink(invitee, 'delete');

        if (deleteLink) {
            const response = await HTTP.action(
                'delete',
                deleteLink,
                {},
                {},
                'Could not delete invitee',
                HTTP.handleFormErrors
            );

            if (response) {
                load(listFilter);
                toast.success('Deleted the invitee');
            }
        }
    };

    const hideDeleteButton = (row: CampaignInvitee) => {
        return !Response.getLink(row, 'delete');
    };

    const [searchFilters, setSearchFilters] = useState<ListSearchFilterList>({
        inviteeName: {
            type: 'text',
            label: 'Invitee Name',
            key: 'inviteeName',
            placeholder: 'e.g. John Smith Industries',
        },
        priority: {
            type: 'single-select',
            defaultValue: NONE_SELECTED_OPTION,
            label: 'Priority for Client',
            key: 'priority',
            options: [NONE_SELECTED_OPTION, ...CampaignPriorityOptions],
        },
        spCategory: {
            type: 'single-select',
            defaultValue: NONE_SELECTED_OPTION,
            label: 'Service Provider Category',
            key: 'spCategory',
            options: [],
        },
    });

    const hideDeleteBtnColumn = () => {
        const invitees = collection.data;

        const links = filter(invitees, (invitee) => {
            return invitee?._links?.delete;
        });

        return !links.length;
    };

    const columns: object[] = [
        {
            name: 'Invitee Name',
            property: 'inviteeName',
            sortable: true,
            grow: 4,
        },
        { name: 'Forename', property: 'forename', sortable: false, grow: 2 },
        { name: 'Surname', property: 'surname', sortable: false, grow: 2 },
        { name: 'Email Address', property: 'email', sortable: false, grow: 5 },
        { name: 'Telephone', property: 'telephone', sortable: false, grow: 2 },
        {
            name: 'Priority For Client',
            property: 'priority',
            type: 'mapping',
            mapping: CampaignPriorityMapping,
            sortable: true,
            grow: 2,
        },
        {
            name: 'Service Provider Category',
            property: 'spCategoryName',
            sortable: false,
            grow: 3,
            type: 'callback',
            callback: (invitee: CampaignInvitee) => (
                <ServiceProviderCategoryPopup invitee={invitee} />
            )
        },
        {
            name: 'Unique Referral Code',
            property: 'referralCode',
            sortable: false,
            grow: 3,
        },
        {
            name: 'Initial Invite',
            property: 'invitedDate',
            type: 'callback',
            callback: showInviteButton,
            sortable: true,
            grow: 3,
        },
        {
            name: '',
            property: '',
            type: 'nav-button',
            grow: 1,
            route: (row: CampaignInvitee) => `${match.url}/${row.id}/edit`,
            buttonIntent: 'primary',
            buttonText: 'Edit',
            right: true,
        },
        {
            name: '',
            property: '',
            type: 'button',
            onClick: clickDeleteButton,
            hide: hideDeleteBtnColumn,
            cellHide: hideDeleteButton,
            grow: 1,
            intent: 'primary',
            text: 'Delete',
            right: true,
        },
    ];

    const load = async (filter: CampaignInviteeListFilter) => {
        setIsLoading(true);
        setCollection(await CampaignService.loadInviteeList(campaign, filter));
        setListFilter({ ...defaultFilters, ...filter });
        setIsLoading(false);
    };

    useEffect(() => {
        if (campaign?.id) {
            (async () => {
                const searchFiltersCopy = { ...searchFilters };
                searchFiltersCopy.spCategory.options =
                    await CampaignService.getSpCategoryOptions(campaign);

                setSearchFilters(searchFiltersCopy);
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaign]);

    const getHeaderButton = () => {
        return (
            <div className="float-right">
                <Button
                    type="button"
                    intent="primary"
                    onClick={downloadInviteeTemplateHandler}
                    className="me-2"
                >
                    <Icon icon="plus-circle" />
                    Download Template
                </Button>
                <Button
                    type="button"
                    intent="primary"
                    onClick={() => {
                        setShowUploadPopup(true);
                    }}
                    className="me-2"
                >
                    <Icon icon="plus-circle" />
                    Upload Template
                </Button>
                <ButtonLink
                    type="button"
                    to={`${match.url}/add`}
                    intent="primary"
                >
                    <Icon icon="plus-circle" />
                    Add Invitee
                </ButtonLink>
            </div>
        );
    };

    const clickInviteButton = async (event: any, invitee: CampaignInvitee) => {
        event.preventDefault();
        event.stopPropagation();
        const inviteLink = Response.getLink(invitee, 'invite');

        if (inviteLink) {
            const response = await HTTP.action(
                'put',
                inviteLink,
                {},
                {},
                'Could not mark as invite sent',
                HTTP.handleFormErrors
            );

            if (response) {
                load(listFilter);
                toast.success('Marked as invite sent');
            }
        }
    };

    return (
        <div className="CampaignInviteeList">
            {campaign?.id && (
                <List
                    title="Campaign Invitees"
                    columns={columns}
                    load={load}
                    collection={collection}
                    filter={listFilter}
                    filters={searchFilters}
                    isLoading={isLoading}
                    noRecordsFoundText="There are no campaign invitees"
                    headerButton={getHeaderButton}
                    downloadHandler={downloadHandler}
                />
            )}
            <UploadTemplate
                campaign={campaign}
                isOpen={showUploadPopup}
                onClose={() => {
                    setShowUploadPopup(false);
                }}
                onSubmit={(errors: any) => {
                    if (errors) {
                        return;
                    }
                    load(listFilter);
                }}
            />
        </div>
    );
};

export default withRouter(CampaignInviteeList);
