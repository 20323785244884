import { AxiosResponse } from 'axios';

import { FlatObject, emptyListData } from 'interface';
import { Campaign } from 'interface/Client/Campaign';
import { EmailTemplateData, EmailTemplateListData, EmailTemplateProps } from 'interface/Email';
import { toast } from 'react-toastify';
import { HTTP, Response, Routing } from 'service';

export const loadEmailTemplateList = async (
    filter: FlatObject | null
): Promise<EmailTemplateListData> => {
    const response: AxiosResponse | void = await HTTP.action(
        'get',
        '/email/templates',
        filter ?? {},
        {},
        'Unable to load email template list'
    );

    if (response && response.data) {
        return response.data as EmailTemplateListData;
    } else {
        return { ...emptyListData, emailTemplates: [] } as EmailTemplateListData;
    }
};

export const loadEmailTemplate = async (
    id: string,
    campaign?: Campaign,
): Promise<EmailTemplateData|null> => {
    const url = !!campaign ? Response.getLink(campaign, 'email-templates') + '/' + id : '/email/templates/' + id;
    const response: AxiosResponse | void = await HTTP.action(
        'get',
        url,
        {},
        {},
        'Unable to load email template'
    );

    if (response && response.data) {
        return response.data as EmailTemplateData;
    } else {
        return null;
    }
};

export const sendSampleEmail = async (
    template: EmailTemplateData
): Promise<void> => {
    const response = await HTTP.action(
        'post',
        Response.getLink(template, 'sendSampleEmail'),
        {},
        {},
        'Unable to send sample email',
        () => {
            toast.error('Unable to send sample email');
        }
    );
    if (response) {
        toast.success('Email queued successfully');
    } else {
        toast.error('Unable to send sample email');
    }
};

export const handleEmailTemplateAddEditSubmit = async (
    form: Partial<EmailTemplateData>,
    props: EmailTemplateProps,
    isEditing: boolean = false,
    reload?: Function,
): Promise<void> => {
    let response: AxiosResponse | null | void = null;

    if (isEditing) {
        response = await HTTP.action(
            'put',
            Response.getLink(form, 'edit'),
            form,
            {},
            'Unable to update email template'
        );
    } else {
        response = await HTTP.action(
            'post',
            Response.getLink(form, 'add'),
            form,
            {},
            'Unable to create email template'
        );
    }
    if (response) {
        if (isEditing) {
            toast.success('Email template updated');
            if (typeof reload === 'function') {
                reload();
            }
        } else {
            toast.success('Email Template created');
        }

        Routing.navigateUpOneLevel(props.history, props.match);
    }
};
