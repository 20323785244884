import { ComponentProps, useEffect } from 'react';
import { toast } from 'react-toastify';

import { LoadingIndicator } from 'components/elements';

import 'components/user/Integrations/Integrations.scss';

const IntegrationsSetup = (props: ComponentProps<any>) => {
    const { history } = props;
    const forwardUrl = '/integrations/elogbooks';

    useEffect(() => {
        const timer = setTimeout(() => {
            history.replace(forwardUrl);
        }, 2000);

        return () => {
            clearTimeout(timer);
            toast.success('Integrations Updated');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="page integration-setup-message">
            <div className="page-wrapper no-styling">
                <div className="thank-you-message">
                    <h2>Updating your Integrations..</h2>
                    <p>
                        We will redirect you to the integrations list once
                        done.
                    </p>
                    <div className="center">
                        <LoadingIndicator />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IntegrationsSetup;
