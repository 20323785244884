import { Component, ComponentProps } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Tab, Tabs } from '@blueprintjs/core';

import { updatingBreadcrumbResolves } from 'action/breadcrumb';
import { HTTP, Response } from 'service';
import { ButtonLink, Icon } from 'components/elements';
import { Loading } from 'components/elements/wrappers';
import Question from 'components/questions/Question';
import QuestionStockResponseList from "components/admin/question-stock-responses/QuestionStockResponseList";

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = {
    updatingBreadcrumbResolves: updatingBreadcrumbResolves,
};

class QuestionDetails extends Component
{
    public readonly state: any;
    public readonly props: ComponentProps<any>;

    constructor(props: ComponentProps<any>)
    {
        super(props);

        this.state = {
            id: props.match.params.id,
            isLoading: false,
            selectedTabId: 'details',
            response: {},
            stockResponseResponse: {},
        };

        this.load = this.load.bind(this);
        this.handleNavbarTabChange = this.handleNavbarTabChange.bind(this);
    }

    componentDidMount()
    {
        this.load();
    }

    render()
    {
        const { isLoading, response } = this.state;
        const { match } = this.props;

        return (
            <div className="QuestionDetails">
                <Tabs renderActiveTabPanelOnly={true}
                      onChange={this.handleNavbarTabChange}
                      selectedTabId={this.state.selectedTabId}>
                    <Tab id={'details'}
                         title={' Question Details '}
                         className={'padded'}
                         panel={
                             <Loading isLoading={ isLoading }>
                                <h1 className="clearfix">
                                    { response.summary }

                                    {
                                        Response.getLink(response, 'edit') === null ?
                                            null :
                                            (
                                                <ButtonLink type="button"
                                                            to={`${match.url}/edit`}
                                                            intent="primary"
                                                            className="float-right">
                                                    <Icon icon="pencil" />
                                                    Edit
                                                </ButtonLink>
                                            )
                                    }
                                </h1>
                                <Question questionResponse={ response } />
                             </Loading>
                        }/>
                    <Tab id={'stock-responses'}
                         title={'Stock Responses'}
                         className={'padded'}
                         panel={
                             <QuestionStockResponseList question={response}/>
                        }/>
                </Tabs>
            </div>
        );
    }

    handleNavbarTabChange(tabId: string) {
        this.setState({ selectedTabId: tabId });
    }

    load()
    {
        if (this.state.isLoading) {
            return;
        }

        this.setState({isLoading: true});

        HTTP
            .get(`/questions/${this.state.id}`, {datagroup: 'details'})
            .then((response) => {
                if (response) {
                    this.setState({response: response.data});
                    this.props.updatingBreadcrumbResolves({question: {summary: response.data.summary}});

                    return true;
                }

                toast.error('Unable to fetch Question details');

                return false;
            }, (error) => {
                toast.error('Unable to fetch Question details');

                return false;
            })
            .finally(() => {
                this.setState({isLoading: false});
            });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionDetails);
