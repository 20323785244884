import JWT from '@elb/jsonwebtoken';
import { toast } from 'react-toastify';
import HTTP from 'service/HTTP';
import {
    CLEAR_AUTH_ERRORS,
    CLEAR_ERRORS,
    CLEAR_MESSAGE,
    CLEAR_REDIRECT,
    LOGIN_FAILURE,
    LOGIN_SUCCESS,
    LOGOUT,
    REQUEST_LOGIN,
    SET_AUTH_ERRORS,
    SET_BUILD_TIMESTAMP,
    SET_ERRORS,
    SET_REDIRECT,
    TOKEN_INVALID,
    TOKEN_VALID,
    UPDATE_REGISTER_FORM,
} from './authConstants';

export {
    checkToken,
    clearAuthErrors,
    clearErrors,
    clearMessage,
    clearRedirect,
    login,
    loginSuccess,
    logout,
    setAuthErrors,
    setBuildTimestamp,
    setErrors,
    setRedirect,
    updateRegisterForm,
};

function login(credentials) {
    return (dispatch) => {
        dispatch(requestLogin(credentials));

        return HTTP.post('/auth', credentials).then(async (response) => {
            if (response) {
                toast.success('Successfully logged in');
                dispatch(loginSuccess(response.data));

                return;
            }

            dispatch(loginFailure());
        });
    };
}

function logout() {
    return (dispatch) => {
        dispatch(processLogout());
    };
}

function checkToken(token) {
    return (dispatch) => {
        if (JWT.isValid(token)) {
            return dispatch(existingTokenIsValid(token));
        }

        return dispatch(existingTokenIsInvalid(token));
    };
}

function setRedirect(redirect) {
    return (dispatch) => {
        return dispatch(processRedirect(redirect));
    };
}

function clearRedirect() {
    return (dispatch) => {
        return dispatch(processClearRedirect());
    };
}

function clearMessage() {
    return (dispatch) => {
        return dispatch(processClearMessage());
    };
}

function updateRegisterForm(form) {
    return (dispatch) => {
        return dispatch(processUpdateRegisterForm(form));
    };
}

function requestLogin(credentials) {
    return {
        type: REQUEST_LOGIN,
    };
}

function loginSuccess(response) {
    return {
        type: LOGIN_SUCCESS,
        token: response.token || response.tokenString,
    };
}

function loginFailure(response) {
    return {
        type: LOGIN_FAILURE,
    };
}

function existingTokenIsValid(token) {
    return {
        type: TOKEN_VALID,
        token: token,
    };
}

function existingTokenIsInvalid(token) {
    return {
        type: TOKEN_INVALID,
    };
}

function processLogout() {
    return {
        type: LOGOUT,
    };
}

function processRedirect(redirect) {
    return {
        type: SET_REDIRECT,
        redirect: redirect,
    };
}

function processClearRedirect() {
    return {
        type: CLEAR_REDIRECT,
    };
}

function processClearMessage() {
    return {
        type: CLEAR_MESSAGE,
    };
}

function processUpdateRegisterForm(form) {
    return {
        type: UPDATE_REGISTER_FORM,
        form: form,
    };
}

function setAuthErrors(errors) {
    return {
        type: SET_AUTH_ERRORS,
        errors: errors,
    };
}

function setErrors(errors) {
    return {
        type: SET_ERRORS,
        errors: errors,
    };
}

function clearAuthErrors() {
    return {
        type: CLEAR_AUTH_ERRORS,
    };
}

function clearErrors() {
    return {
        type: CLEAR_ERRORS,
    };
}

function setBuildTimestamp(timeStamp) {
    return {
        type: SET_BUILD_TIMESTAMP,
        timeStamp: timeStamp,
    };
}
