import React, { ChangeEvent, ComponentProps, FormEvent, useReducer, useRef } from 'react';
import { Button, FormGroup, H2 } from '@blueprintjs/core';

import { UserData, ErrorResponseRow } from 'interface';
import { StateHandler, UserService } from 'service';
import { PasswordField, ButtonLink, Icon } from 'components/elements';

interface UpdatePasswordProps extends ComponentProps<any> {
    user: UserData;
    mergeUserResponse: { (): void };
}

const ChangePassword = (props: UpdatePasswordProps) => {
    const oldPasswordRef = useRef();
    const passwordRef = useRef();
    const matchRef = useRef();
    const [state, setState] = useReducer(
        (state: any, edits: any) => ({...state, ...edits}),
        {
            form: {
                oldPassword: '',
                password: '',
                passwordMatch: '',
            },
            matchError: undefined,
        }
    )
    const {mergeUserResponse} = props;

    const notMatchOldPassword = () => {
        const passwordHtml = (passwordRef.current as any) as HTMLInputElement;
        const oldPasswordHtml = (oldPasswordRef.current as any) as HTMLInputElement;
        oldPasswordHtml.setCustomValidity('');
        passwordHtml.setCustomValidity(passwordHtml.value !== oldPasswordHtml.value ? '' : 'Please use a different password from your old one')
    }

    const matchPassword = () => {
        const passwordHtml = (passwordRef.current as any) as HTMLInputElement;
        const matchHtml = (matchRef.current as any) as HTMLInputElement;
        matchHtml.setCustomValidity(passwordHtml.value === matchHtml.value ? '' : 'Please match the above password')
    }

    const processErrors = (errors: [ErrorResponseRow]) => {
        errors.forEach((error) => {
            switch(error.property_path) {
                case 'oldPassword':
                    const oldPasswordHtml = (oldPasswordRef.current as any) as HTMLInputElement;
                    oldPasswordHtml.setCustomValidity(error.message);
                    break;
                case 'password':
                    const passwordHtml = (passwordRef.current as any) as HTMLInputElement;
                    passwordHtml.setCustomValidity(error.message);
                    break;
            }
        });
    }

    return (
        <div className="ProfileEdit">
            <H2>Edit Profile</H2>
            <form
                className="UpdatePassword"
                onSubmit={(e: FormEvent<HTMLFormElement>) =>
                    UserService.handleSubmitPasswordChange(e, props.user, state.form, props, (errors: [ErrorResponseRow] | void) => {
                        errors && processErrors(errors);
                        mergeUserResponse();
                    })
                }
            >
                <div className="field-group">
                    <FormGroup label="Old Password"
                        labelFor="old-password"
                        inline={true}
                        className="form-fill required">
                        <PasswordField
                            inputRef={oldPasswordRef}
                            id="oldPassword"
                            autoComplete="oldPassword"
                            value={state.form.oldPassword}
                            showInfo={true}
                            showError={!!state.form.oldPassword?.length}
                            /* pattern needs to be removed here since some of our passwords do not meet it */
                            pattern=".*"
                            title="Please enter your current password"
                            onChange={(e: ChangeEvent) => {
                                notMatchOldPassword();
                                setState({form: StateHandler.getStateObject(e, state.form)});
                            }}
                            required/>
                    </FormGroup>
                </div>
                <div className="field-group">
                    <FormGroup label="Password"
                        labelFor="password"
                        inline={true}
                        className="form-fill required">
                        <PasswordField
                            inputRef={passwordRef}
                            id="password"
                            autoComplete="new-password"
                            value={state.form.password}
                            showInfo={true}
                            showError={!!state.form.password?.length}
                            onChange={(e: ChangeEvent) => {
                                notMatchOldPassword();
                                matchPassword();
                                setState({form: StateHandler.getStateObject(e, state.form)});
                            }}
                            required/>
                    </FormGroup>
                </div>

                <div className="field-group">
                    <FormGroup label="Re-Enter Password"
                        labelFor="passwordMatch"
                        inline={true}
                        className="form-fill required">
                        <PasswordField
                            inputRef={matchRef}
                            id="passwordMatch"
                            autoComplete="new-password"
                            value={state.form.passwordMatch}
                            showInfo={true}
                            showError={!!state.form.passwordMatch?.length}
                            pattern=".*"
                            title="Please match the above password"
                            errorMessage={state.matchError}
                            onChange={(e: ChangeEvent) => {
                                matchPassword();
                                setState({form: StateHandler.getStateObject(e, state.form)});
                            }}
                            required/>
                    </FormGroup>
                </div>

                <FormGroup>
                    <ButtonLink
                        type="button"
                        intent="default"
                        className="float-left"
                        to="/profile"
                    >
                        <Icon icon="ban"/>
                        Cancel
                    </ButtonLink>
                    <Button
                        type="submit"
                        intent="primary"
                        className="float-right"
                    >
                        <Icon icon="paper-plane"/>
                        Update
                    </Button>
                </FormGroup>
            </form>
        </div>
    );
};

export default ChangePassword;
