import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Button, FormGroup, H1, InputGroup, Switch } from '@blueprintjs/core';
import { Response, StateHandler } from 'service';
import { isMobile } from 'react-device-detect';
import {
    ButtonLink,
    HTMLSelect,
    Icon,
    InputElementIcon,
    SuggestRenderer,
} from 'components/elements';
import { Loading } from 'components/elements/wrappers';
import { ACTIVE_OPTIONS } from 'constants/active';
import FileSingleUpload from 'components/elements/file-upload/FileSingleUpload';
import { MAX_FILE_UPLOAD_SIZE_MB } from 'constants/general';
import { Service, ServiceListData } from 'interface/Service';
import ServiceService from 'service/Service/ServiceService';
import { globalDebounce } from 'helpers/helpers';

interface ServiceAddEditProps extends RouteComponentProps<any> {
    service: Service;
    reload: () => void;
}

const ServiceAddEdit = (props: ServiceAddEditProps) => {
    const { history, match, service, reload } = props;
    const [form, setForm] = useState<Service>(ServiceService.createService());
    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [services, setServices] = useState<Service[]>();
    const [selectedParent, setSelectedParent] = useState<Service>();

    const serviceSearch = (query?: string) => {
        globalDebounce(
            () => {
                loadServices(query);
            },
            'userSearch',
            250
        );
    };

    const loadServices = async (query?: string) => {
        const serviceList: ServiceListData = await ServiceService.loadServices(
            query
        );
        setServices(serviceList.services);
    };

    const load = () => {
        (async () => {
            if (isLoading) {
                if (service) {
                    setForm(service);
                    setIsEditing(true);

                    if (Response.getLink(service, 'parent')) {
                        const parent: Service = await ServiceService.loadParent(
                            service
                        );
                        setSelectedParent(parent);
                    }
                }

                await loadServices();

                setIsLoading(false);
            }
        })();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(load, []);

    const fileLabel = (
        <>
            Image
            {InputElementIcon(
                `Required size 175x175px. Max file size ${MAX_FILE_UPLOAD_SIZE_MB}MB`,
                'info'
            )}
        </>
    );

    const updateFile = (file: any) => {
        const links = { ...form._links, ...file._links };
        const stateForm = { ...form, _links: links };
        setForm(stateForm);
    };

    return (
        <div className="ServiceAddEdit">
            <H1 className="clearfix">
                {isEditing ? 'Edit Service' : 'Add new Service'}
            </H1>

            <Loading isLoading={isLoading}>
                <form
                    className="AddEdit"
                    onSubmit={(e: FormEvent<HTMLFormElement>) => {
                        ServiceService.handleSubmit(
                            e,
                            form,
                            history,
                            match,
                            reload,
                            isEditing
                        );
                    }}
                >
                    <FormGroup
                        label="Name"
                        inline={true}
                        className="form-fill required"
                        labelFor="name"
                    >
                        <InputGroup
                            id="name"
                            placeholder="Name"
                            minLength={1}
                            maxLength={75}
                            value={form.name}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                setForm({
                                    ...form,
                                    ...StateHandler.getStateObject(e, form),
                                })
                            }
                            required
                        />
                    </FormGroup>
                    <FormGroup
                        label="Parent"
                        inline={true}
                        className="form-fill"
                        labelFor="parent"
                    >
                        <SuggestRenderer
                            id="parent"
                            onItemSelect={(item: any, event: any) => {
                                const formCopy = { ...form };
                                formCopy._links = {
                                    ...formCopy._links,
                                    parent: {
                                        href: Response.getLink(item, 'self'),
                                    },
                                };

                                setForm(formCopy);

                                return;
                            }}
                            defaultItem={selectedParent}
                            items={services}
                            isRequired={false}
                            onKeyUp={(e: FormEvent<HTMLInputElement>) => {
                                serviceSearch(e.currentTarget.value);
                            }}
                        />
                    </FormGroup>
                    <FormGroup
                        label="Requires Certificate"
                        inline={true}
                        className="form-fill"
                        labelFor="requiresCertificate"
                    >
                        <Switch
                            id="requiresCertificate"
                            checked={form.requiresCertificate}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                setForm({
                                    ...form,
                                    ...StateHandler.getStateObject(e, form),
                                })
                            }
                        />
                    </FormGroup>

                    <FileSingleUpload
                        form={form}
                        fileId="image"
                        isImage={true}
                        isMobile={isMobile}
                        fileLabel={fileLabel}
                        onUpdate={updateFile}
                    />

                    <FormGroup
                        label="State"
                        inline={true}
                        className="form-fill"
                        labelFor="isActive"
                    >
                        <HTMLSelect
                            id="isActive"
                            fill={true}
                            defaultValue={form.isActive.toString()}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                setForm({
                                    ...form,
                                    ...StateHandler.getStateObject(e, form),
                                })
                            }
                            options={ACTIVE_OPTIONS}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ButtonLink
                            type="button"
                            intent="default"
                            className="float-left"
                            to={
                                isEditing
                                    ? `/admin/services/${form.id}`
                                    : '/admin/services'
                            }
                        >
                            <Icon icon="ban" />
                            Cancel
                        </ButtonLink>
                        <Button
                            type="submit"
                            intent="primary"
                            className="float-right"
                        >
                            <Icon icon="paper-plane" />
                            {isEditing ? 'Update' : 'Create'}
                        </Button>
                    </FormGroup>
                </form>
            </Loading>
        </div>
    );
};

export default withRouter(ServiceAddEdit);
