import { FormGroup } from '@blueprintjs/core';
import SuggestRenderer from 'components/elements/SuggestRenderer';

import './typeahead.scss';

interface TypeAheadProps {
    label: string;
    filterKey: string;
    onChange?: (item: any, filterKey: string) => any;
    onSelect?: (item: any) => any;
    url?: string;
    searchParams?: object;
    responseProperty?: string;
    valueProperty?: string;
    searchProperty?: string;
    value?: number | string;
    defaultValue?: any;
}

const TypeAhead = (props: TypeAheadProps) => {
    const {
        label,
        filterKey,
        onChange,
        onSelect,
        url = '',
        searchParams = {},
        responseProperty,
        valueProperty = 'name',
        searchProperty,
        defaultValue,
    } = props;

    const handleChange = (item: any, event: any) => {
        if (
            (!item || item?.hasOwnProperty(valueProperty)) &&
            typeof onChange === 'function'
        ) {
            onChange(
                { value: !item ? item : item[valueProperty], data: item },
                filterKey
            );
        }

        if (typeof onSelect === 'function') {
            onSelect(item);
        }
    };

    return (
        <div className="filter-typeahead">
            <FormGroup
                label={label}
                inline
                className="form-fill"
                labelFor={filterKey}
            >
                <SuggestRenderer
                    id={filterKey}
                    onItemSelect={handleChange}
                    searchUrl={url}
                    searchParams={searchParams}
                    searchKey={searchProperty}
                    searchObjectName={responseProperty}
                    valueProperty={valueProperty}
                    defaultItem={defaultValue}
                />
            </FormGroup>
        </div>
    );
};

export default TypeAhead;
