class Response
{
    static getLink(response, link)
    {
        return Response.getLinkAttribute(response, link, 'href');
    }

    static getLinkAttribute(response, link, attribute)
    {
        if (response &&
            response._links &&
            response._links[link] &&
            typeof response._links[link][attribute] !== 'undefined'
        ) {
            return response._links[link][attribute];
        }

        return null;
    }

    static getUpdatableResponse(response)
    {
        return Object.assign({}, response, {onUpdate: function(property, value) {
            this[property] = value;

            return this;
        }});
    }

    static updateLink = (obj, attr, href) => {
        obj._links = obj._links || {};

        obj._links = {
            ...obj._links,
            [attr]: {
                ...obj._links[attr],
                href,
            },
        };

        return obj;
    }
}

export default Response;
