import { PopoverPosition } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import Icon from 'components/elements/Icon';

type OnClickHandler = () => void;

interface Props {
    tooltipText: string;
    tooltipPosition: PopoverPosition,
    disabled: boolean,
    onClick: OnClickHandler,
}

const DownloadButton = (props: Props) => {
    const {
        disabled,
        onClick,
        tooltipPosition,
        tooltipText,
    } = props;

    return (
        <Tooltip2
            content={tooltipText}
            position={tooltipPosition}
        >
            <button
                className="clear-button download-button"
                disabled={disabled}
                onClick={onClick}
            >
                <Icon icon="download" className="icon" />
            </button>
        </Tooltip2>
    );
};

export default DownloadButton;
