import React, { Component } from 'react';
import Icon from 'components/elements/Icon';

class LoadingIndicator extends Component
{
    render() {
        const { size, inline } = this.props;

        return (
            <div className={ "LoadingIndicator" + (inline ? ' inline-block' : '') } data-size={size} >
                <Icon icon="spinner-third" spin />
            </div>
        );
    }
}

export default LoadingIndicator;
