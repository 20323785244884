import { DateTime } from 'luxon';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { List } from 'components/elements';
import { NONE_SELECTED_OPTION } from 'constants/none-selected';
import { elipsesString, isAdmin } from 'helpers/helpers';
import { emptyListData, FlatObject, ListCollection } from 'interface';
import {
    CAFM_STATUS_COLOURS,
    CAFM_STATUS_MAP,
    CAFM_TYPE_OPTIONS,
    ElogsCAFMStatsData,
    ElogsCAFMStatsFilter,
    FILTER_JOB_STATUS_ALL,
    FILTER_JOB_STATUS_OPTIONS,
    FILTER_JOB_TYPES,
} from 'interface/ElogsCAFMStats';
import { Response } from 'service';
import { loadClientELBSUpdatedAt } from 'service/Integration/IntegrationService';
import SupplyChainService from 'service/SupplyChain/SupplyChainService';
import { translate } from 'service/Translator';
import CAFMUpdateTime from './widgets/CAFMUpdateTime';

interface CAFMFilters {
    site?: any;
    serviceProvider?: any;
    page: number;
    limit: number;
    order: string;
}

const ElogsCAFMStats = (props: any) => {
    const root = useSelector((state: any) => state.root);
    const params: any = queryString.parse(props.location.search);

    const defaultFilters = {
        page: 1,
        limit: 10,
        order: '-completedAt',
    } as CAFMFilters;

    const [client, setClient] = useState(root?.client?.id);
    const [isLoading, setIsLoading] = useState(false);
    const [filterData, setFilterData] = useState<CAFMFilters>(
        {} as CAFMFilters
    );
    const [updatedAt, setUpdatedAt] = useState<string | null>(null);

    const [collection, setCollection] = useState<
        ListCollection<ElogsCAFMStatsData>
    >({} as ListCollection<ElogsCAFMStatsData>);

    const [listFilter, setListFilter] = useState<ElogsCAFMStatsFilter>({
        ...defaultFilters,
        ...params,
    } as ElogsCAFMStatsFilter);

    const updateFilterData = (key: string, value: any) => {
        setFilterData({
            ...filterData,
            [key]: value,
        });

        setListFilter({
            ...listFilter,
            [key]: value?.id,
        });
    };

    const onClear = () => {
        setFilterData(defaultFilters);
    };

    const searchFilters = {
        site: {
            type: 'typeahead',
            label: 'Site',
            key: 'site',
            url: `/client/${client}/supply-chain/cafm-stats/sites`,
            responseProperty: 'sites',
            valueProperty: 'name',
            searchProperty: 'name',
            onSelect: (item: object) => updateFilterData('site', item),
            defaultValue: filterData?.site,
        },
        jobType: {
            type: 'single-select',
            defaultValue: NONE_SELECTED_OPTION.value,
            label: 'Job Type',
            key: 'type',
            options: FILTER_JOB_TYPES,
        },
        serviceProvider: {
            type: 'typeahead',
            label: 'Service Provider',
            key: 'serviceProvider',
            url: `/client/${client}/supply-chain/cafm-stats/service-providers`,
            responseProperty: 'serviceproviders',
            valueProperty: 'name',
            searchProperty: 'name',
            onSelect: (item: object) =>
                updateFilterData('serviceProvider', item),
            defaultValue: filterData?.serviceProvider,
        },
        statusAtApproval: {
            type: 'single-select',
            defaultValue: FILTER_JOB_STATUS_ALL.value,
            label: 'Status At Approval',
            key: 'statusAtApproval',
            options: FILTER_JOB_STATUS_OPTIONS,
        },
        statusAtCompletion: {
            type: 'single-select',
            defaultValue: FILTER_JOB_STATUS_ALL.value,
            label: 'Status At Completion',
            key: 'statusAtCompletion',
            options: FILTER_JOB_STATUS_OPTIONS,
        },
    } as FlatObject;

    const loadStats = async (filter: ElogsCAFMStatsFilter, clientId = null) => {
        setIsLoading(true);

        if (clientId === 'all-clients' || !clientId) {
            setCollection({
                ...emptyListData,
                data: [],
            });

            setListFilter(filter);
            setIsLoading(false);

            return;
        }

        if (clientId !== null) {
            if (updatedAt !== null) {
                const cafmStats = await SupplyChainService.loadCafmStats(
                    filter,
                    clientId
                );

                setCollection({
                    ...cafmStats,
                    data: cafmStats.jobs,
                });

                setListFilter({
                    ...filter,
                    limit: cafmStats.limit,
                    page: cafmStats.page,
                });
            }
        }

        setIsLoading(false);
    };

    const getClient = () => {
        if (isAdmin()) {
            return root?.client?.id;
        }

        return Response.getLinkAttribute(root.response, 'client', 'id');
    };

    const loadUpdatedAt = async (clientId = null) => {
        setIsLoading(true);
        setUpdatedAt(null);

        if (clientId === 'all-clients' || !clientId) {
            setIsLoading(false);

            return;
        }

        if (clientId !== null) {
            let updatedAtValue = await loadClientELBSUpdatedAt(clientId);
            setUpdatedAt(updatedAtValue);
        }

        setIsLoading(false);
    };

    useEffect(() => {
        const clientId = getClient();
        setClient(clientId);

        loadUpdatedAt(clientId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [root?.client?.id, client]);

    const getStatusCell = (cafmStatus: string) => {
        if (!cafmStatus || !cafmStatus.length) {
            return 'N/A';
        }

        return (
            <span className={CAFM_STATUS_COLOURS[cafmStatus]}>
                {CAFM_STATUS_MAP[cafmStatus]}
            </span>
        );
    };

    const columns = [
        {
            name: 'Site',
            property: 'siteName',
            grow: 1,
            sortable: false,
        },
        {
            name: 'Job Summary',
            property: 'summary',
            grow: 1,
            sortable: false,
            type: 'callback',
            callback: (row: ElogsCAFMStatsData) =>
                elipsesString(row.summary, 100),
        },
        {
            name: 'Job Type',
            property: 'type',
            grow: 1,
            sortable: false,
            type: 'callback',
            callback: (row: ElogsCAFMStatsData) =>
                translate(row.type, CAFM_TYPE_OPTIONS),
        },
        {
            name: 'Approver',
            property: 'approvedByName',
            grow: 1,
            sortable: false,
            type: 'callback',
            callback: (row: ElogsCAFMStatsData) => row.approvedByName || 'N/A',
        },
        {
            name: 'Approval Date',
            property: 'approvedAt',
            grow: 1,
            sortable: false,
            type: 'callback',
            callback: (row: ElogsCAFMStatsData) => {
                if (!row.approvedAt) {
                    return 'N/A';
                }

                return DateTime.fromISO(row.approvedAt).toLocaleString(
                    DateTime.DATETIME_SHORT_WITH_SECONDS
                );
            },
        },
        {
            name: 'Service Provider',
            property: 'serviceProviderName',
            grow: 1,
            sortable: false,
            type: 'callback',
            callback: (row: ElogsCAFMStatsData) => {
                if (!row.companyId) {
                    return row.serviceProviderName;
                }

                return (
                    <Link to={`/service-providers/${row.companyId}`}>
                        {row.serviceProviderName}
                    </Link>
                );
            },
        },
        {
            name: 'Completion Date',
            property: 'completedAt',
            grow: 1,
            sortable: false,
            type: 'datetime',
        },
        {
            name: 'Status at Approval',
            property: 'prosureStatusApproval',
            grow: 1,
            sortable: false,
            type: 'callback',
            callback: (row: ElogsCAFMStatsData) =>
                getStatusCell(row.prosureStatusApproval) || 'N/A',
        },
        {
            name: 'Status at Completion',
            property: 'prosureStatusCompletion',
            grow: 1,
            sortable: false,
            type: 'callback',
            callback: (row: ElogsCAFMStatsData) =>
                getStatusCell(row.prosureStatusCompletion) || 'N/A',
        },
    ];

    const listRender =
        updatedAt !== null ? (
            <List
                title="Elogs CAFM Stats"
                columns={columns}
                load={(filter) =>
                    loadStats(filter as ElogsCAFMStatsFilter, client)
                }
                preLoadList={false}
                delayedLoad={!!client}
                collection={collection}
                filters={searchFilters}
                filter={listFilter}
                preIsFilterOpen={true}
                defaultFilter={defaultFilters}
                isLoading={isLoading}
                onClear={onClear}
                noRecordsFoundText="No Jobs Found"
            />
        ) : (
            ''
        );

    return (
        <div className="page page-cafm">
            <CAFMUpdateTime
                includeDateMessage={false}
                updatedAt={updatedAt}
                isLoading={isLoading}
            />
            {listRender}
        </div>
    );
};

export default ElogsCAFMStats;
