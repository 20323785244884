import { Button, Classes, Dialog, FormGroup, Intent } from "@blueprintjs/core";
import { map } from "lodash";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { LoadingIndicator } from "components/elements";
import { Company } from "interface";
import { CompanyService, HTTP, Response } from "service";

import styles from './deactivate-service-provider-dialog.module.scss';

interface DeactivateServiceProviderDialogProps {
    companyResponse: Company;
    buttonText?: string;
    onConfirm?: () => void;
}

const DeactivateServiceProviderDialog = (props: DeactivateServiceProviderDialogProps) => {
    const {
        companyResponse,
        buttonText,
        onConfirm
    } = props;

    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [response, setResponse] = useState<any>(null);

    useEffect(() => {
        if (isOpen) {
            loadCompanyUsers();
        }

        return () => {
            setResponse(null);
            setIsLoading(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen])

    const loadCompanyUsers = async () => {
        setIsLoading(true);

        HTTP.get(
            Response.getLink(companyResponse, 'users'),
            { limit: 999 }
        ).then((response) => {
            if (response) {
                setResponse(response.data);
            }
        })

        setIsLoading(false);
    }

    const getUsersText = () => {
        if (!response) {
            return [];
        }

        return map(response.users, ({ id, forename, surname }) => (
            <div
                key={id}
                className={styles.user}
            >
                {forename + " " + surname}
            </div>
        ));

    };

    const handleOnClose = () => {
        setIsOpen(false);
    };

    const handleOnOpen = () => {
        setIsOpen(true);
    };

    const handleOnConfirm = async () => {
        const response = await CompanyService.postDeactivateCompany(
            companyResponse,
        );

        if (response) {
            toast.success('Company Successfully Deactivated');
        }
        else {
            toast.error('Unable to diable company');
        }

        onConfirm && onConfirm();
        setIsOpen(false);
    };

    return (
        <>
            <Button
                onClick={handleOnOpen}
                intent={Intent.WARNING}
                title="Make Service Provider Inactive"
            >
                {buttonText ?? 'Make Service Provider Inactive'}
            </Button>
            <Dialog
                title="Make Service Provider Inactive"
                autoFocus
                canEscapeKeyClose
                canOutsideClickClose
                isCloseButtonShown={false}
                usePortal
                isOpen={isOpen}
            >
                <div className={Classes.DIALOG_BODY}>
                    <p>
                        Are you sure? Performing this action will make the Service
                        Provider Inactive and will make the following users inactive:
                    </p>
                    <div className={styles.users_container}>
                        { !response
                            ? <LoadingIndicator />
                            : getUsersText()
                        }
                    </div>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <FormGroup>
                        <div className="dialog-footer-buttons">
                            <Button onClick={handleOnClose}>Cancel</Button>
                            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                <Button
                                    type="submit"
                                    intent={Intent.SUCCESS}
                                    disabled={isLoading}
                                    onClick={handleOnConfirm}
                                >
                                    Confirm
                                </Button>
                            </div>
                        </div>
                    </FormGroup>
                </div>
            </Dialog>
        </>
    )
}

export default DeactivateServiceProviderDialog;
