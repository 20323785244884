import { Accordion, MultiSwitchToggle } from 'components/elements';

const RatingFilter = (props: any) => {
    const ratingOptions = [
        {
            id: '80_and_above',
            key: 'rating',
            value: props.initialValue === '80_and_above' ?? false,
            label: '80+',
        },
        {
            id: '50_80',
            key: 'rating',
            value: props.initialValue === '50_80' ?? false,
            label: '50 - 80',
        },
        {
            id: 'below_50',
            key: 'rating',
            value: props.initialValue === 'below_50' ?? false,
            label: '50 and below',
        },
    ];
    return (
        <Accordion title="RATINGS">
            <MultiSwitchToggle
                options={ratingOptions}
                onUpdate={(options: Array<any>) => props.onUpdate(options)}
                clear={props.clear}
            />
        </Accordion>
    );
};

export default RatingFilter;
