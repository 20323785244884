import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { Redirect, Switch } from 'react-router-dom';
import { HTTP, Response } from 'service';
import { Loading } from 'components/elements/wrappers';
import { ProtectedRoute } from 'components/elements';
import { isEqual, merge } from 'lodash';
import { updatingBreadcrumbResolves } from 'action/breadcrumb';
import ResubmitApplication from 'components/company/ResubmitApplication';
import ServiceProviderDetails from "components/service-provider/ServiceProviderDetails";
import ServiceProviderAddEdit from "components/service-provider/ServiceProviderAddEdit";

const mapStateToProps = (state) => {
    return {
        rootResponse: state.root.response,
    };
};

const mapDispatchToProps = {
    updatingBreadcrumbResolves: updatingBreadcrumbResolves,
};

class Company extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            isLoading: false,
            response: {},
        };

        this.load = this.load.bind(this);
        this.mergeCompanyResponse = this.mergeCompanyResponse.bind(this);
    }

    componentDidMount()
    {
        this.props.updatingBreadcrumbResolves({company: undefined});
        this.load();
    }

    mergeCompanyResponse(data = {})
    {
        const { response } = this.state;
        this.setState({response: merge({}, response, data)});
    }

    shouldComponentUpdated(nextProps, nextState)
    {
        return !(isEqual(nextProps, this.props) && isEqual(nextState, this.state));
    }

    render()
    {
        const { match } = this.props;
        const { isLoading, response } = this.state;

        return (
            <>
                <Loading isLoading={ isLoading || typeof response.id === 'undefined' }>
                    <Switch>
                        <ProtectedRoute
                            path={`${match.url}/edit`}
                            component={ ServiceProviderAddEdit }
                            companyResponse={ response }
                            mergeCompanyResponse={ this.mergeCompanyResponse }
                            exact />
                        <ProtectedRoute
                            path={`${match.url}`}
                            component={ ServiceProviderDetails }
                            companyResponse={ response }
                            mergeCompanyResponse={ this.mergeCompanyResponse }
                        />

                        <ProtectedRoute path={`${match.url}/resubmit`} component={ ResubmitApplication } companyResponse={ response } />

                        <Redirect to={`${match.url}/info`} />
                    </Switch>
                </Loading>
            </>
        );
    }

    load()
    {
        if (this.state.isLoading) {
            return;
        }

        this.setState({isLoading: true});

        HTTP
            .get(Response.getLink(this.props.rootResponse, 'company'))
            .then((response) => {
                if (response) {
                    this.props.updatingBreadcrumbResolves({company: response.data});
                    this.setState({isLoading: false, response: response.data});

                    return true;
                }

                toast.error('Unable to fetch Company');
                this.setState({isLoading: false});

                return false;
            });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Company);
