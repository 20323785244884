import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ItemCount extends Component
{
    static propTypes = {
        count: PropTypes.number.isRequired,
    };

    static defaultProps = {
        count: 0,
    };

    render() {
        return (
            <>
                {
                    this.props.count === 0 ?
                        '' :
                        ' (' + this.props.count.toLocaleString('default', { minimumFractionDigits: 0 }) + ')'
                }
            </>
        );
    }
}

export default ItemCount;
