import { ComponentProps, FormEvent, useState } from 'react';

import { handleAssessmentSubmit, handleAssessmentReviewsSubmit } from 'service';
import { delay } from 'helpers/helpers';
import { Loading } from 'components/elements/wrappers';
import AssessmentFeedback from 'components/dialogs/AssessmentFeedback';
import AssessmentStatus from 'components/assessments/AssessmentStatus';
import QuestionActions from 'components/assessments/tabs/questions/components/QuestionActions';
import CopyExistingAnswers from 'components/assessments/tabs/questions/components/CopyExistingAnswers';
import VerticalQuestions from 'components/assessments/tabs/questions/components/VerticalQuestions';
import './QuestionsForm.scss';

interface QuestionFormProps extends ComponentProps<any> {
    mode: string;
    reloadData: Function;
}

const QuestionsForm = (props: QuestionFormProps) => {
    const { mode, reloadData } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

    const handleFormSubmit = async (event?: FormEvent, type?: string) => {
        event?.preventDefault();
        event?.stopPropagation();
        setIsSubmitDisabled(true);
        const isReload = await handleAssessmentSubmit(type);
        // note: in old Questions, reloadData is the "onSubmit" prop
        isReload && typeof reloadData === 'function' && reloadData();
        setIsSubmitDisabled(false);
    };

    const handleReviewsFormSubmit = async (event?: FormEvent, data?: any) => {
        event?.preventDefault();
        event?.stopPropagation();
        setIsSubmitDisabled(true);
        const isReload = await handleAssessmentReviewsSubmit(data);
        isReload && typeof reloadData === 'function' && reloadData();
        setIsSubmitDisabled(false);
    };

    const reloadQuestions = async () => {
        setIsLoading(true);
        await delay(150);
        setIsLoading(false);
    };

    if (isLoading) {
        return <Loading isLoading={isLoading} />;
    }

    return (
        <>
            <form onSubmit={handleFormSubmit}>
                <section className="vertical-questions">
                    <section className="header-section">
                        <AssessmentStatus mode={ mode } reloadData={ reloadData } />
                        <QuestionActions
                            mode={mode}
                            isSubmitDisabled={isSubmitDisabled}
                            handleSubmit={handleFormSubmit}
                            handleReviewsSubmit={handleReviewsFormSubmit}
                            reloadData={ reloadData }
                        />
                    </section>

                    <CopyExistingAnswers afterCopy={reloadQuestions} />

                    <VerticalQuestions
                        mode={mode}
                        isSubmitDisabled={isSubmitDisabled}
                    />
                </section>
            </form>
            <AssessmentFeedback />
        </>
    );
};

export default QuestionsForm;
