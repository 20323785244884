import { Button } from '@blueprintjs/core';
import Icon from 'components/elements/Icon';
import React, { useState } from 'react';

import './index.scss';

interface Props {
    title: string;
    children: React.ReactNode;
}

const Accordion = (props: Props) => {
    const [isOpen, setIsOpen] = useState(true);
    const { title, children } = props;

    const toggleIsOpen = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="accordion">
            <div className="title-row">
                <Button
                    type="button"
                    className="icon-only expand-button"
                    onClick={toggleIsOpen}
                >
                    <Icon icon={isOpen ? 'minus' : 'plus'} />
                </Button>
                <h3 className="accordion-title">{title}</h3>
            </div>
            <div className={'accordion-content ' + (isOpen ? 'open' : '')}>
                {children}
            </div>
        </div>
    );
};

export default Accordion;
