import { Button, H1 } from '@blueprintjs/core';
import {
    ButtonLink,
    Icon,
    ItemCount,
    Paginator,
    Reload,
    Table,
} from 'components/elements';
import { Filter, Loading, NoResultsFound } from 'components/elements/wrappers';
import { ACTIVE_OPTIONS_WITH_NONE_SELECTED } from 'constants/active';
import { CLIENT_ROLE_OPTIONS } from 'constants/role';
import { isAdmin, isClientAdmin } from 'helpers/helpers';
import queryString from 'query-string';
import { Component } from 'react';
import { toast } from 'react-toastify';
import { ClientService, Filters, HTTP, Location, Response } from 'service';

class UserList extends Component {
    defaultFilters = {
        order: 'forename',
    };

    constructor(props) {
        super(props);

        this.state = {
            collectionResponse: {},
            filter: queryString.parse(props.location.search),
            filterData: Filters.getFilterStateFromUrl(
                this.props.location.search.slice(1),
                props.filterData
            ),
            addDisabled: false,
            addDisabledText: '',
        };

        this.load = this.load.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.onFilter = this.onFilter.bind(this);
        this.getRoute = this.getRoute.bind(this);
        this.updateIsActive = this.updateIsActive.bind(this);
    }

    componentDidMount() {
        this.load();
    }

    render() {
        const collection = this.state.collectionResponse || {};
        const { isLoading } = this.state;
        const { match, isClientBio } = this.props;

        let columns = [
            {
                name: 'Forename',
                property: 'forename',
                sortable: true,
                grow: 2,
            },
            { name: 'Surname', property: 'surname', sortable: true, grow: 2 },
            { name: 'Role', property: 'displayRole', sortable: false, grow: 2 },
            {
                name: 'Email',
                property: 'email',
                sortable: true,
                grow: 3,
                // hide: 'sm',
            },
            {
                name: 'Status',
                property: 'isActive',
                type: 'mapping',
                mapping: ACTIVE_OPTIONS_WITH_NONE_SELECTED,
                sortable: true,
                grow: 2,
            },
            {
                name: 'Last Login Date',
                property: 'lastSuccessfulLogin',
                type: 'datetime',
                sortable: false,
                grow: 3,
            },
        ];

        if (!isClientBio || isClientAdmin() || isAdmin()) {
            columns.push({
                name: '',
                type: 'callback',
                grow: 2,
                callback: (row) => {
                    return (
                        <div className="full-width flex-end">
                            {!Response.getLink(row, 'update-is-active') ? (
                                ''
                            ) : (
                                <Button
                                    type="button"
                                    intent={
                                        row.isActive ? 'warning' : 'success'
                                    }
                                    onClick={() => this.updateIsActive(row)}
                                >
                                    {row.isActive
                                        ? 'Make Inactive'
                                        : 'Make Active'}
                                </Button>
                            )}
                            &nbsp;
                            <ButtonLink
                                type="button"
                                to={this.getRoute(row)}
                                intent="primary"
                            >
                                <Icon icon="eye" />
                                Edit
                            </ButtonLink>
                        </div>
                    );
                },
            });
        }

        return (
            <div className="UserList">
                <H1>
                    User List
                    <ItemCount count={collection.count} />
                    <Reload load={this.load} />
                    {Response.getLink(collection, 'create') === null ? null : (
                        <ButtonLink
                            type="button"
                            to={`${match.url}/add`}
                            intent="primary"
                            className="float-right"
                            disabled={this.state.addDisabled}
                            title={this.state.addDisabledText}
                        >
                            <Icon icon="plus-circle" />
                            Add new User
                        </ButtonLink>
                    )}
                </H1>

                <Filter
                    onClear={this.clearFilters}
                    onFilter={(filters, filterData) =>
                        this.onFilter(filters, filterData)
                    }
                    filterData={{}}
                >
                    <Filter.Text label="Forename" filterKey="forename" />
                    <Filter.Text label="Surname" filterKey="surname" />
                    <Filter.Text label="Email Address" filterKey="email" />
                    <Filter.SingleSelect
                        label="Status"
                        filterKey="isActive"
                        options={ACTIVE_OPTIONS_WITH_NONE_SELECTED}
                    />
                </Filter>

                <Loading isLoading={isLoading}>
                    <NoResultsFound count={collection.count}>
                        <Table
                            data={collection.users}
                            columns={columns}
                            onSort={this.load}
                            ordering={this.state.filter.order}
                        />

                        <Paginator
                            page={collection.page}
                            count={collection.count}
                            limit={collection.limit}
                            onPage={this.load}
                        />
                    </NoResultsFound>
                </Loading>
            </div>
        );
    }

    getRoute(row, column) {
        return (
            Response.getLink(row, 'edit') && `${this.props.match.url}/${row.id}`
        );
    }

    clearFilters() {
        this.setState({ filterData: {} });
        this.onFilter({}, {});
    }

    onFilter(filters, filterData) {
        this.load(
            Object.assign({}, this.defaultFilters, filterData, { page: 1 }),
            true
        );
    }

    load = async (params = {}, paramsOverride = false) => {
        if (this.state.isLoading) {
            return;
        }

        const { clientResponse, history, location } = this.props;

        const updatedClientResponse = await HTTP.get(
            Response.getLink(clientResponse, 'self'),
            { datagroup: 'details' }
        );

        let disabled = false;
        let disabledText = '';
        let subscriptionLevel = updatedClientResponse.data.subscriptionLevel;
        let numberActiveusers = updatedClientResponse.data.numberActiveusers;

        if (
            (subscriptionLevel === 'Silver' && numberActiveusers >= 10) ||
            (subscriptionLevel === 'Bronze' && numberActiveusers >= 5) ||
            (typeof subscriptionLevel === 'undefined' && numberActiveusers >= 5)
        ) {
            disabled = true;
            disabledText = `In order to add more users an upgrade to ${
                isClientAdmin() ? 'your' : "the client's"
            } subscription level is required`;
        }

        this.setState({
            addDisabled: disabled,
            addDisabledText: disabledText,
        });

        const { filter } = this.state;

        const filterParams = paramsOverride
            ? params
            : Object.assign({}, this.defaultFilters, filter, params);
        Location.setQueryStringFromObject(history, location, filterParams);

        this.setState({ filter: filterParams, isLoading: true });

        ClientService.loadClientUsers(clientResponse.id, filterParams).then(
            (response) => {
                if (response) {
                    response.users.forEach((user) => {
                        let selectedRole = user.roles.filter(
                            (role) => role !== 'ROLE_USER'
                        )[0];
                        user.displayRole = CLIENT_ROLE_OPTIONS.filter(
                            (role) => role.value === selectedRole
                        )[0].label;
                    });
                    this.setState({
                        collectionResponse: response,
                        isLoading: false,
                    });

                    return true;
                }

                toast.error('Unable to fetch Users');
                this.setState({ isLoading: false });

                return false;
            }
        );
    };

    updateIsActive = async (user) => {
        const isActiveLink = Response.getLink(user, 'update-is-active');
        const success = await HTTP.action(
            'patch',
            isActiveLink,
            {
                isActive: !user.isActive,
            },
            {},
            null,
            HTTP.handleFormErrors
        );

        if (success) {
            toast.success(
                `Successfully updated user to be ${
                    !user.isActive ? 'active' : 'inactive'
                }`
            );
            this.load();
        }
    };
}

export default UserList;
