import React, { useState, useEffect } from 'react';
import { H1 } from '@blueprintjs/core';
import { toast } from 'react-toastify';

import {
    ButtonLink,
    Icon,
    ItemCount,
    Reload,
    Table,
} from 'components/elements';
import { HTTP, Response } from 'service';
import { Loading, NoResultsFound } from 'components/elements/wrappers';
import { toMoney } from 'helpers/helpers';

const ServiceProviderCategoryList = (props) => {
    const { clientResponse, match } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [state, setState] = useState({
        data: [],
    });

    const getEditRoute = (row) => `${match.url}/${row.id}/edit`;

    const getEditButton = (row) => {
        if (!Response.getLink(row, 'edit-sp-category')) {
            return null;
        }

        return (
            <ButtonLink
                to={getEditRoute(row)}
                type="button"
                intent="primary"
            >
                Edit
            </ButtonLink>
        );
    };

    const editColumn = {
        name: '',
        property: '',
        grow: 1,
        type: 'callback',
        callback: getEditButton,
        right: true,
    };

    let columns = [
        {
            name: 'Name',
            property: 'name',
            type: 'text',
            sortable: false,
            grow: 4,
        },
        {
            name: 'Professional Indemnity',
            property: 'professionalIndemnityInsurance',
            type: 'text',
            sortable: false,
            grow: 6,
        },
        {
            name: 'Public Liability',
            property: 'publicLiabilityInsurance',
            type: 'text',
            sortable: false,
            grow: 6,
        },
        {
            name: 'Employers Liability',
            property: 'employersLiabilityInsurance',
            type: 'text',
            sortable: false,
            grow: 6,
        },
        {
            name: 'Products Liability',
            property: 'productsLiabilityInsurance',
            type: 'text',
            sortable: false,
            grow: 6,
        },
        {
            name: 'Contractor All Risk',
            property: 'contractorAllRiskInsurance',
            type: 'text',
            sortable: false,
            grow: 6,
        },
        {
            name: 'SSIP',
            property: 'requiresProsureSSIPVerified',
            type: 'yesNo',
            sortable: false,
            grow: 6,
        },
        {
            name: 'DTS',
            property: 'allowProsureDTS',
            type: 'yesNo',
            sortable: false,
            grow: 6,
        },
        {
            name: 'Prosure Pre Qualified',
            property: 'requiresProsurePreQualified',
            type: 'yesNo',
            sortable: false,
            grow: 6,
        },
        {
            name: 'Client Assessment',
            property: 'requiresClientSpecificAssessment',
            type: 'yesNo',
            sortable: false,
            grow: 6,
        },
        {
            name: 'Requires Prosure Fee',
            property: 'requiresProsureAnnualAdministrativeFee',
            type: 'yesNo',
            sortable: false,
            grow: 6,
        },
        {
            name: 'Requires Client Fee',
            property: 'requiresClientAnnualFee',
            type: 'yesNo',
            sortable: false,
            grow: 6,
        },
    ];

    useEffect(() => {
        load();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (state.data && props.updateData) {
            const index = state.data
                .map((row) => row.id)
                .indexOf(props.updateData.id);

            if (index !== -1) {
                setState(state.splice(index, 1, props.updateData));
            }
        }
    }, [props.updateData]); // eslint-disable-line react-hooks/exhaustive-deps

    const load = () => {
        if (isLoading) {
            return;
        }

        setIsLoading(true);
        const getUrl = Response.getLink(clientResponse, 'sp-categories');

        HTTP.get(getUrl).then(
            (response) => {
                setIsLoading(false);
                const { categories = [] } = response?.data;
                let showEditColumn = false;

                categories.forEach((category) => {
                    if (Response.getLink(category, 'edit-sp-category')) {
                        showEditColumn = true;
                    }

                    category.professionalIndemnityInsurance = toMoney(
                        category.professionalIndemnityInsurance
                    );
                    category.publicLiabilityInsurance = toMoney(
                        category.publicLiabilityInsurance
                    );
                    category.employersLiabilityInsurance = toMoney(
                        category.employersLiabilityInsurance
                    );
                    category.productsLiabilityInsurance = toMoney(
                        category.productsLiabilityInsurance
                    );
                    category.contractorAllRiskInsurance = toMoney(
                        category.contractorAllRiskInsurance
                    );
                });

                setState({
                    data: response.data.categories,
                    showEditColumn
                });

                return true;
            },
            (response) => {
                setIsLoading(false);
                toast.error('Unable to fetch a list of SP Categories');
                return false;
            }
        );
    };

    if (state.showEditColumn) {
        columns.push(editColumn);
    }

    return (
        <div className="ServiceProviderCategoryList">
            <H1 className="clearfix">
                SP Requirements
                <ItemCount count={state.data.length} />
                <Reload load={load} />
                {Response.getLink(clientResponse, 'add-sp-category') === null ||
                state.data.length >= 15 ? null : (
                    <ButtonLink
                        type="button"
                        to={`${match.url}/new`}
                        intent="primary"
                        className="float-right"
                    >
                        <Icon icon="pencil" />
                        Add new Category
                    </ButtonLink>
                )}
            </H1>
            <Loading isLoading={isLoading}>
                <NoResultsFound count={state.data.length}>
                    <Table data={state.data} columns={columns} />
                </NoResultsFound>
            </Loading>
        </div>
    );
};

export default ServiceProviderCategoryList;
