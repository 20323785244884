import { Button, FormGroup } from '@blueprintjs/core';
import queryString from 'query-string';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Icon, MarloweFooter } from 'components/elements';
import PasswordField from 'components/elements/PasswordField';
import { HTTP, StateHandler } from 'service';

class PasswordReset extends Component {
    constructor(props) {
        super(props);

        this.state = {
            password: null,
            showPassword: false,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.passwordForm = this.passwordForm.bind(this);
    }

    handleSubmit(event) {
        event.stopPropagation();
        event.preventDefault();

        const { history } = this.props;

        let parsedQueryString = queryString.parse(this.props.location.search);
        const data = {
            token: parsedQueryString.token,
            password: this.state.password,
        };

        HTTP.post('/password-reset', data).then((response) => {
            if (response) {
                toast.success('Password reset successfully');
                return history.push('/');
            }

            toast.error('Unable to reset password');

            return false;
        });
    }

    render() {
        return (
            <div className="password-reset-page marlowe-theme box-page">
                <div className="password-reset-box box">
                    <div className="box-body">
                        <img
                            className="box-logo"
                            src="/assets/images/logo.svg"
                            alt="Prosure360 logo"
                        />
                        <div className="box-body-inner">
                            {this.passwordForm()}
                        </div>
                    </div>
                    <div className="box-footer">
                        <a href="tel:0203 404 6750">
                            <Icon icon="phone-square" />
                            <span>0203 404 6750</span>
                        </a>
                        <a href="mailto:support@prosure360.co.uk">
                            <Icon icon="envelope" />
                            <span>Contact us</span>
                        </a>
                    </div>
                </div>
                <MarloweFooter />
            </div>
        );
    }

    passwordForm = () => {
        return (
            <form
                className="password-reset-fields"
                onSubmit={(e) => this.handleSubmit(e)}
            >
                <FormGroup>
                    <PasswordField
                        id="password"
                        autoComplete="new-password"
                        className="form-field"
                        value={this.state.password}
                        showInfo={true}
                        marloweTheme={true}
                        onChange={(e) =>
                            this.setState(StateHandler.getStateObject(e))
                        }
                        required
                    />
                </FormGroup>
                <FormGroup className="form-buttons">
                    <Link className="float-left" to="/login">
                        Cancel
                    </Link>

                    <Button
                        type="submit"
                        intent="primary"
                        className="float-right"
                        large={true}
                    >
                        Submit
                    </Button>
                </FormGroup>
            </form>
        );
    };
}

export default PasswordReset;
