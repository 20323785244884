import { useState } from 'react';
import { withRouter } from 'react-router';

import { List } from 'components/elements';
import { delay, parseDateFormat } from 'helpers/helpers';
import { ListCollection } from 'interface';
import {
    CampaignComponentProps,
    CampaignRevenueByMonth,
} from 'interface/Client/Campaign';
import { ListFilter } from 'interface/List';
import CampaignService from 'service/Client/CampaignService';

const CampaignRevenuesByMonth = (props: CampaignComponentProps) => {
    const { campaign } = props;
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [collection, setCollection] = useState<
        ListCollection<CampaignRevenueByMonth>
    >({} as ListCollection<CampaignRevenueByMonth>);

    const defaultFilters = {
        page: 1,
        limit: 14,
        order: '-month_starting_on',
    };

    const [listFilter, setListFilter] = useState<ListFilter>({
        ...defaultFilters,
    } as ListFilter);

    const columns: object[] = [
        {
            name: 'Month',
            type: 'callback',
            callback: (monthRevenue: CampaignRevenueByMonth) => {
                return parseDateFormat(
                    monthRevenue.monthStartingOn,
                    'MMMM YYYY'
                );
            },
            grow: 4,
        },
        {
            name: 'Assessment Revenue',
            property: 'assessmentRevenueExVat',
            type: 'currency',
            sortable: false,
            grow: 3,
        },
        {
            name: 'Supply Chain - Prosure',
            property: 'supplyChainRevenueProsureExVat',
            type: 'currency',
            sortable: false,
            grow: 3,
        },
        {
            name: 'Supply Chain - Client',
            property: 'supplyChainRevenueClientExVat',
            type: 'currency',
            sortable: false,
            grow: 3,
        },
    ];

    const load = async (filter: ListFilter) => {
        setIsLoading(true);
        setCollection(
            await CampaignService.loadRevenueByMonth(campaign, filter)
        );
        setListFilter({ ...defaultFilters, ...filter });
        await delay(0);
        setIsLoading(false);
    };

    return (
        <div className="CampaignInviteeList">
            <List
                title="Revenue By Month"
                columns={columns}
                load={load}
                preLoadList={false}
                delayedLoad={!!campaign?.id}
                collection={collection}
                filter={listFilter}
                isLoading={isLoading}
                hideFilters={true}
                defaultFilter={defaultFilters}
            />
        </div>
    );
};

export default withRouter(CampaignRevenuesByMonth);
