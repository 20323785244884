import { useState } from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from '@blueprintjs/core';

import { FileUpload } from 'components/elements';
import { Response } from 'service';
import './single-file.scss';

const FileSingleUpload = (props) =>
{
    const { form, fileId, isEditable, isImage, isMobile, isRequired, fileLabel, onUpdate } = props;
    const [fileError, setFileError] = useState(false);
    const requiredClass = isRequired && ' required';

    return <>
         <FormGroup
            label={ fileLabel }
            inline={ !isMobile }
            className={`form-fill multi-inputs${requiredClass}`}
            labelFor={fileId}>

            <FileUpload inputID={fileId}
                        isImage={isImage}
                        isEditable={isEditable}
                        isRequired={isRequired}
                        onUpdate={ () => {
                            onUpdate(
                                {
                                    _links: {
                                        [fileId]: null,
                                    },
                                }
                            );
                        } }
                        onUpload={ (response) => {
                            onUpdate(
                                {
                                    _links: {
                                        [fileId]: {
                                            href: Response.getLink(response.data, 'self'),
                                            title: response.data.title,
                                            download: Response.getLink(response.data, 'download'),
                                            publicUrl: Response.getLink(response.data, 'public-url'),
                                        }
                                    }
                                }
                            );
                        } }
                        response={ form }
                        responseLink={ fileId }
                        updateFileError={ setFileError }
                        uploadDataObject={ {} } />
        </FormGroup>

        { fileError && <div className='fileFieldError'><p>{ fileError }</p></div> }
    </>
}

FileSingleUpload.propTypes = {
    form: PropTypes.object.isRequired,
    fileId: PropTypes.string.isRequired,
    isEditable: PropTypes.bool,
    isImage: PropTypes.bool,
    isMobile: PropTypes.bool,
    isRequired: PropTypes.bool,
    fileLabel: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired,
};

FileSingleUpload.defaultProps = {
    isEditable: true,
    isImage: false,
    isMobile: false,
    isRequired: false,
};

export default FileSingleUpload;
