import { PopoverPosition } from "@blueprintjs/core";
import { Tooltip2 } from "@blueprintjs/popover2";
import { isNumber } from "lodash";

import { toMoney } from "helpers/helpers";
import { CampaignInvitee } from "interface/Client/Campaign";

import styles from './styles.module.scss';

interface ServiceProviderCategoryPopupProps {
    invitee: CampaignInvitee
}

const ServiceProviderCategoryPopup = (props: ServiceProviderCategoryPopupProps) => {
    const {
        spCategoryName,
        spCategoryProfessionalIndemnity,
        spCategoryPublicLiability,
        spCategoryEmployersLiability,
        spCategoryProductsLiability,
        spCategoryContractorAllRisk,
        spCategoryRequiresSSIP,
        spCategoryRequiresPreQualified
    } = props.invitee;

    const getSPCategoryLimitText = (type: string, value: number|undefined) => {
        if (!isNumber(value)) {
            return null;
        }

        const amountText = toMoney(value);
        let insuranceTypeLabel = "";

        switch(type) {
            case 'professionalIndemnity':
                insuranceTypeLabel = 'Professional Indemnity'
                break;
            case 'publicLiability':
                insuranceTypeLabel = 'Public Liability';
                break;
            case 'employersLiability':
                insuranceTypeLabel = 'Employers Liability';
                break;
            case 'productsLiability':
                insuranceTypeLabel = 'Products Liability';
                break;
            case 'contractorAllRisk':
                insuranceTypeLabel = 'Contractor All Risk';
                break;
        }

        return (
            <p className={styles.popupText}>
                {`- ${insuranceTypeLabel} Limit ${amountText}`}
            </p>
        );
    }

    const content = (
        <div>
            <strong>Client Requirements</strong>
            <br/><br/>
            <strong>{spCategoryName}</strong>
            {spCategoryRequiresSSIP && (
                <p className={styles.popupText}>- Prosure SSIP Verified</p>
            )}
            {spCategoryRequiresPreQualified && (
                <p className={styles.popupText}>- Prosure Pre-Qualified</p>
            )}
            {getSPCategoryLimitText(
                'professionalIndemnity',
                spCategoryProfessionalIndemnity
            )}
            {getSPCategoryLimitText(
                'publicLiability',
                spCategoryPublicLiability
            )}
            {getSPCategoryLimitText(
                'employersLiability',
                spCategoryEmployersLiability
            )}
            {getSPCategoryLimitText(
                'productsLiability',
                spCategoryProductsLiability
            )}
            {getSPCategoryLimitText(
                'contractorAllRisk',
                spCategoryContractorAllRisk
            )}
        </div>
    );

    return (
        <Tooltip2
            content={content}
            position={PopoverPosition.TOP}
            className="ms-2 contact-tooltip"
        >
            {spCategoryName}
        </Tooltip2>
    );
}

export default ServiceProviderCategoryPopup;
