import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import StateHandler from "../../../service/StateHandler";
import { Button, Card, FormGroup, InputGroup, Switch } from '@blueprintjs/core';
import { Loading } from 'components/elements/wrappers';
import { Icon } from 'components/elements';
import {DragDrop, HTTP} from 'service';
import './styles.scss';

class QuestionStockResponseList extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            question: props.question,
            isLoading: false,
            isSubmitting: false,
            form: {
                _links: {
                    "question": {
                        href: props.question._links.self.href,
                    },
                },
                name: '',
                isActive: true,
            },
            response: {},
        };

        this.load = this.load.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeActive = this.handleChangeActive.bind(this);
        this.putChange = this.putChange.bind(this);
        this.drawStockResponses = this.drawStockResponses.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.removeStockResponse = this.removeStockResponse.bind(this);
    }

    componentDidMount()
    {
        this.load();
    }

    render()
    {
        const { form, isLoading, isSubmitting } = this.state;
        const response = this.drawStockResponses();

        return (
            <div className="StockResponses">
                {/* New Stock Response Form */}
                <Card className="AddStockResponse">
                    <form
                          onSubmit={ event => this.handleSubmit(event) }>
                        <div className="iconWrap">
                            <Loading isLoading={isSubmitting}>
                                <Icon icon="plus" />
                            </Loading>
                        </div>
                        <FormGroup className="text">
                            <InputGroup
                                id="name"
                                placeholder="Stock Response text"
                                disabled={isSubmitting}
                                value={form.name}
                                minLength={2}
                                maxLength={200}
                                onChange={ e => this.setState({form: StateHandler.getStateObject(e, form)}) } />
                        </FormGroup>
                        <FormGroup>
                            <Button
                                type="submit"
                                intent="primary"
                                disabled={isSubmitting}
                                className="float-right">
                                Add
                            </Button>
                        </FormGroup>
                    </form>
                </Card>

                {/* Stock Response List */}
                <div className="StockResponsesList">
                    <DragDropContext
                        onDragStart={ this.onDragStart }
                        onDragUpdate={ this.onDragUpdate }
                        onDragEnd={ this.onDragEnd }>
                        <Droppable droppableId={1}>
                            { provided => {
                                return (
                                    <div className="Droppable"
                                         ref={ provided.innerRef }
                                         { ...provided.droppableProps }>
                                        <Loading isLoading={ isLoading }>
                                            { response }
                                        </Loading>

                                        { provided.placeholder }
                                    </div>
                                );
                            } }
                        </Droppable>
                    </DragDropContext>
                </div>
            </div>
        );
    }

    /**
     * Fetch all stock responses for question
     */
    load()
    {
        if (this.state.isLoading) {
            return;
        }

        this.setState({isLoading: true});

        HTTP
            .get(`/question/stock-responses`, { questionId: this.state.question.id })
            .then((response) => {
                if (response) {
                    this.setState({response: response.data});

                    return true;
                }
                toast.error('Unable to fetch stock responses');
                console.warn('error fetching stock responses', response);

                return false;
            }, (error) => {
                toast.error('Unable to fetch stock responses');
                console.warn('error fetching stock responses', error);

                return false;
            })
            .finally(() => {
                this.setState({isLoading: false});
            });
    }

    /**
     * Add a new stock response
     * @param e
     */
    handleSubmit(e)
    {
        this.setState({isSubmitting: true});
        e.preventDefault();

        const form = this.state.form;

        HTTP
            .post('/question/stock-responses', form)
            .then((response) => {
                if (response) {
                    toast.success('Added new stock response');

                    this.setState({
                        form: Object.assign({}, form, {
                            name: '',
                            isActive: true,
                        })
                    });
                    this.load();

                    return true;
                }

                toast.error('Unable to save stock response');
                console.warn('error saving stock response', response);

                return false;
            }, (error) => {
                toast.error('Unable to save stock response');
                console.warn('error saving stock response', error);

                return false;
            })
            .finally(() => {
                this.setState({isSubmitting: false});
            });
    }

    /**
     * Construct Cards based on stock response data
     * @returns {Card[]}
     */
    drawStockResponses()
    {
        const { response } = this.state;
        const cards = [];

        if (response &&
            Array.isArray(response.stockResponses)) {
            response.stockResponses.forEach((stockResponse,  index) => {
                cards.push((
                    <Draggable draggableId={ stockResponse.id }
                               index={ index }
                               key={ stockResponse.id }>
                        { provided => {
                            let className = "stockResponse";
                            if(!stockResponse.isActive) {
                                className += " inactive";
                            }
                            return (
                                <div ref={ provided.innerRef }
                                     key={ stockResponse.id }
                                     { ...provided.draggableProps }>
                                    <Card className={className}>
                                        <div { ...provided.dragHandleProps } className="drag-wrap" >
                                            <Icon icon="grip-vertical" />
                                        </div>
                                        <FormGroup>
                                            <Switch
                                                id="isActive"
                                                checked={stockResponse.isActive}
                                                alignIndicator="right"
                                                onChange={ e => this.handleChangeActive(e,stockResponse) } />
                                        </FormGroup>
                                        <div className="name">{ stockResponse.name }</div>
                                        <Button minimal onClick={() => {this.removeStockResponse(stockResponse)}} className="close-button icon-only"><Icon icon="trash-alt" /></Button>
                                    </Card>
                                </div>
                            );
                        } }
                    </Draggable>
                ));
            });
        }

        return cards;
    }

    /**
     * Detect change and submit for update
     * @param e
     * @param stockResponse
     */
    handleChangeActive(e, stockResponse) {
        const newStockResponse = StateHandler.getStateObject(e, stockResponse);

        this.putChange(newStockResponse);

    }

    /**
     * Update stock response with updates
     * @param newStockResponse
     */
    putChange(newStockResponse) {
        HTTP
            .put(`/question/stock-responses/${newStockResponse.id}`, newStockResponse)
            .then((response) => {
                if (response) {
                    toast.success('Stock Response Updated');

                    let newStockResponses = this.state.response.stockResponses.map(stockResponse => {
                        if(stockResponse.id === newStockResponse.id) {
                            return newStockResponse;
                        }
                        return stockResponse;
                    });
                    this.setState({ response: Object.assign(this.state.response, { stockResponses: newStockResponses }) });

                    return true;
                }

                toast.error('Unable to update Stock Response order');

                return false;
            });

    }

    /**
     * Update ordering once the card is dropped.
     * @param result
     */
    onDragEnd(result)
    {
        // fix when dragged outside container
        if(!result.destination) {
            return;
        }

        let newStockResponses = DragDrop.getSingularReorderedList(result, this.state.response.stockResponses);

        if (!newStockResponses) {
            return;
        }
        this.setState({response: Object.assign({}, this.state.response, {stockResponses: newStockResponses})});

        let order = newStockResponses.map((stockResponse) => {
            return stockResponse.id;
        });

        HTTP
            .put(`/question/stock-responses/order`, {
                _links: {
                    "question": {
                        href: this.state.question._links.self.href,
                    },
                },
                order: order,
            })
            .then((response) => {
                if (response) {
                    toast.success('Stock Response order Updated');
                    //this.load();

                    return true;
                }

                toast.error('Unable to update Stock Response order');

                return false;
            });
    }

    /**
     * Remove a stock response on click delete
     * @param stockResponse
     */
    removeStockResponse(stockResponse)
    {
        if(stockResponse && stockResponse.id) {
            HTTP
                .delete(`/question/stock-responses/${stockResponse.id}`)
                .then((response) => {
                    if (response) {
                        toast.success('Stock Response deleted');
                        let newStockResponses = this.state.response.stockResponses.filter( e => e.id !== stockResponse.id);
                        this.setState({ response: Object.assign(this.state.response, { stockResponses: newStockResponses }) });

                        return true;
                    }

                    toast.error('Unable to update Stock Response order');
                    return false;
                }, () => {
                    toast.error('Unable to update Stock Response order');
                    return false;
                });
        }
    }
}

export default QuestionStockResponseList;
