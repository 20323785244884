import {
    Button,
    Classes,
    Dialog,
    FormGroup,
    InputGroup,
} from '@blueprintjs/core';
import { DateInput } from '@blueprintjs/datetime';
import { AxiosResponse } from 'axios';
import { DateTime } from 'luxon';
import moment from 'moment';
import { FormEvent, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { toast } from 'react-toastify';

import { HTMLSelect, InputElementIcon } from 'components/elements/index';
import { NONE_SELECTED_OPTION } from 'constants/none-selected';
import { AssessmentTypeInstanceData, SupplyChain } from 'interface';
import {
    PartialRefundPaymentData,
    REFUND_REASON_OPTIONS,
    RefundReason,
    RefundReasonMappings,
} from 'interface/Payment/RefundPayment';
import { HTTP, Response } from 'service';

interface PartialRefundPaymentDialogProps {
    refundableObject: AssessmentTypeInstanceData | SupplyChain;
    reload: Function;
    refundText?: string;
}

const PartialRefundPaymentDialog = (props: PartialRefundPaymentDialogProps) => {
    const { refundableObject, reload, refundText } = props;
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [refundPayment, setRefundPayment] = useState<PartialRefundPaymentData>(
        {
            refundDate: new Date()
        } as PartialRefundPaymentData
    );

    const refundReasons = [
        NONE_SELECTED_OPTION,
        {
            label: RefundReasonMappings[RefundReason.CLIENT_REQUEST],
            value: RefundReason.CLIENT_REQUEST,
        },
        {
            label: RefundReasonMappings[RefundReason.SERVICE_PROVIDER_REQUEST],
            value: RefundReason.SERVICE_PROVIDER_REQUEST,
        },
        {
            label: RefundReasonMappings[RefundReason.OTHER],
            value: RefundReason.OTHER,
        },
    ];

    const handleClose = () => {
        setRefundPayment({} as PartialRefundPaymentData);
        setIsDialogOpen(false);
    };

    const submitRefundPayment = async (event: FormEvent<HTMLFormElement>) => {
        event.stopPropagation();
        event.preventDefault();

        const refundLink = Response.getLink(refundableObject, 'partial-refund');
        if (refundLink) {
            await HTTP.post(refundLink, refundPayment)
                .then((response: AxiosResponse | void) => {
                    if (response) {
                        toast.success(
                            `Refunded £${refundPayment.amount} successfully`
                        );
                        handleClose();
                        reload();
                    }
                })
                .catch((error: any) => {
                    const { response } = error;

                    if (response?.data && response.data.length > 0) {
                        toast.error(response.data[0].message);
                    }
                });
        } else {
            toast.error('Refund not possible, please contact support');
        }
    };

    const openDialog = () => {
        setIsDialogOpen(true);
    };

    return (
        <>
            <Button
                onClick={openDialog}
                intent="warning"
                title="Refund Supply Chain Entry"
            >
                {refundText ?? 'Refund'}
            </Button>
            <Dialog
                title="Refund Supply Chain Entry"
                className="refund-modal"
                onClose={handleClose}
                autoFocus={true}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                usePortal={true}
                isOpen={isDialogOpen}
            >
                <form
                    id="partial-refund-form"
                    onSubmit={(event: FormEvent<HTMLFormElement>) =>
                        submitRefundPayment(event)
                    }
                >
                    <div className={Classes.DIALOG_BODY}>
                        <FormGroup
                            label="Refund Reason"
                            inline={true}
                            className="form-fill required"
                            labelFor="reason"
                        >
                            <HTMLSelect
                                id="reason"
                                fill={true}
                                value={refundPayment.reason}
                                onChange={(
                                    event: FormEvent<HTMLSelectElement>
                                ) => {
                                    const { value } =
                                        event.target as HTMLInputElement;

                                    setRefundPayment({
                                        ...refundPayment,
                                        reason: parseInt(value) as RefundReason,
                                    });
                                }}
                                options={refundReasons ?? REFUND_REASON_OPTIONS}
                                required
                            />
                        </FormGroup>
                        {refundPayment.reason === RefundReason.OTHER && (
                            <FormGroup
                                label="Details"
                                inline={true}
                                className="form-fill required"
                                labelFor="Details"
                            >
                                <InputGroup
                                    placeholder="Details"
                                    minLength={1}
                                    maxLength={255}
                                    value={refundPayment.detail}
                                    onChange={(
                                        e: FormEvent<HTMLInputElement>
                                    ) => {
                                        const { value } =
                                            e.target as HTMLInputElement;
                                        setRefundPayment({
                                            ...refundPayment,
                                            detail: value,
                                        });
                                    }}
                                    required
                                />
                            </FormGroup>
                        )}
                        <FormGroup
                            label="Refund Value to be Issued"
                            labelFor="refundValue"
                            inline={true}
                            className="form-fill required"
                        >
                            <CurrencyInput
                                id="refundValue"
                                name="refundValue"
                                className="bp3-input currencyInput"
                                placeholder="Please enter a number"
                                value={refundPayment.amount}
                                allowNegativeValue={false}
                                decimalsLimit={2}
                                maxLength={10}
                                disableAbbreviations={true}
                                step={1}
                                required
                                prefix="£"
                                onValueChange={(value) =>
                                    setRefundPayment({
                                        ...refundPayment,
                                        amount: value ?? '',
                                    })
                                }
                            />
                        </FormGroup>
                        <FormGroup
                            label="Refund Tax"
                            labelFor="refundTax"
                            inline={true}
                            className="form-fill required"
                        >
                            <CurrencyInput
                                id="refundTax"
                                name="refundTax"
                                className="bp3-input currencyInput"
                                placeholder="Please enter a number"
                                value={refundPayment.tax}
                                allowNegativeValue={false}
                                decimalsLimit={2}
                                maxLength={10}
                                disableAbbreviations={true}
                                step={1}
                                required
                                prefix="£"
                                onValueChange={(value) =>
                                    setRefundPayment({
                                        ...refundPayment,
                                        tax: value ?? '',
                                    })
                                }
                            />
                        </FormGroup>

                        <FormGroup
                            label="Refund Date:"
                            inline
                            className="form-fill choice required"
                        >
                            <DateInput
                                parseDate={(str) => moment(str, 'DD/MM/YYYY').toDate()}
                                formatDate={(date) => DateTime.fromJSDate(date).toLocaleString()}
                                popoverProps={{ position: 'bottom', fill: true }}
                                inputProps={{
                                    fill: true,
                                    id: 'policyCommencementDate',
                                    rightElement: InputElementIcon(
                                        'Select a date...',
                                        'calendar-alt'
                                    ),
                                }}
                                onChange={(newDate) => {
                                    newDate = new Date(+newDate + 1000);
                                    setRefundPayment({
                                        ...refundPayment,
                                        refundDate: newDate
                                    });
                                }}
                                value={refundPayment.refundDate}
                            />
                        </FormGroup>

                        <FormGroup className={Classes.DIALOG_FOOTER}>
                            <Button
                                type="button"
                                className="float-left"
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                intent="success"
                                className="float-right"
                                form='partial-refund-form'
                            >
                                Refund
                            </Button>
                        </FormGroup>
                    </div>
                </form>
            </Dialog>
        </>
    );
};

export default PartialRefundPaymentDialog;
