import { H1 } from "@blueprintjs/core";
import moment from "moment";

import Icon from "components/elements/Icon";
import { Response } from "service";
import { ButtonLink } from "components/elements";
import DiscountService from "service/Discount/DiscountService";
import { Loading }  from "../../elements/wrappers";
import './index.scss';
import { AssessmentTypeData } from "../../../interface/AssessmentType";
import { CompanyData } from "../../../interface/Company";

const DiscountSummary = (props: any) =>
{
    const { match, discountResponse } = props;

    return <div className="discountDetails">
        <Loading isLoading={ typeof discountResponse === 'undefined' } >
            <H1>
                View Discount Code
                {
                    Response.getLink(discountResponse, 'edit') === null ?
                        null :
                        (
                            <div className="float-right">
                                <ButtonLink type="button"
                                            to={ `${match.url}/edit` }
                                            intent="primary"
                                            className="me-3">
                                    <Icon icon="lock-open" />
                                    Edit
                                </ButtonLink>
                            </div>
                        )
                }

            </H1>

            <dl className="horizontal">
                <dt>Discount Code</dt>
                <dd>{ discountResponse.code }</dd>
                <dt>Start Date</dt>
                <dd>{ moment(discountResponse.startDate).format('MMMM Do YYYY') }</dd>
                <dt>Expiry Date</dt>
                <dd>{ moment(discountResponse.expiryDate).format('MMMM Do YYYY') }</dd>
                <dt>Assessment(s)</dt>
                <dd>{ discountResponse.assessments.length > 0 ? discountResponse.assessments.map((a: AssessmentTypeData) => a.name).join(', ') : 'False' }</dd>
                <dt>Limited to Specific Service Provider(s)</dt>
                <dd>{ discountResponse.serviceProviders.length > 0 ? discountResponse.serviceProviders.map((c: CompanyData) => c.registeredCompanyName).join(', ') : "False" }</dd>
                <dt>Discount %</dt>
                <dd>{ discountResponse.discount }</dd>
                <dt>Number of times can be used</dt>
                <dd>{ discountResponse.usageLimit }</dd>
                <dt>Times Used</dt>
                <dd>{ discountResponse.limitedUsage }</dd>
                <dt>Code Status</dt>
                <dd>{ DiscountService.getCodeStatus(discountResponse.codeStatus) }</dd>
                <dt>Status</dt>
                <dd>{ DiscountService.getStatus(discountResponse.status) }</dd>
            </dl>
        </Loading>
    </div>;
}

export default DiscountSummary;
