const routes = {
    admin_services: {
        href: '/admin/services?order=name',
        text: 'Services',
        icon: 'toolbox',
    },
    admin_services_add: {
        href: '/admin/services/add',
        text: 'Add new Service',
    },
    admin_services_details: {
        href: '/admin/services/:id',
        text: '{service.name}',
        skipNavigation: true,
    },
    admin_services_details_info: {
        href: '/admin/services/:id',
        text: 'Information',
    },
    admin_services_details_info_edit: {
        href: '/admin/services/:id/info/edit',
        text: 'Edit',
    },
};

export default routes;
