import { NONE_SELECTED_OPTION } from 'constants/none-selected';

const INSURANCE_TYPES = {
    professional_indemnity_insurance: 'Professional Indemnity Insurance',
    public_liability_insurance: 'Public Liability Insurance',
    employers_liability_insurance: 'Employers Liability Insurance',
    products_liability_insurance: 'Products Liability Insurance',
    contractor_all_risk_insurance: 'Contractor All Risk Insurance',
};

const PROFESSIONAL_INDEMNITY_INSURANCE = {
    label: INSURANCE_TYPES['professional_indemnity_insurance'],
    value: 'professional_indemnity_insurance',
};
const PUBLIC_LIABILITY_INSURANCE = {
    label: INSURANCE_TYPES['public_liability_insurance'],
    value: 'public_liability_insurance',
};
const EMPLOYERS_LIABILITY_INSURANCE = {
    label: INSURANCE_TYPES['employers_liability_insurance'],
    value: 'employers_liability_insurance',
};
const PRODUCTS_LIABILITY_INSURANCE = {
    label: INSURANCE_TYPES['products_liability_insurance'],
    value: 'products_liability_insurance',
};
const CONTRACTOR_ALL_RISK_INSURANCE = {
    label: INSURANCE_TYPES['contractor_all_risk_insurance'],
    value: 'contractor_all_risk_insurance',
};

const INSURANCE_TYPE_OPTIONS = [
    CONTRACTOR_ALL_RISK_INSURANCE,
    EMPLOYERS_LIABILITY_INSURANCE,
    PRODUCTS_LIABILITY_INSURANCE,
    PROFESSIONAL_INDEMNITY_INSURANCE,
    PUBLIC_LIABILITY_INSURANCE,
];

const INSURANCE_TYPE_OPTIONS_NONE_SELECTED = [
    NONE_SELECTED_OPTION,
    ...INSURANCE_TYPE_OPTIONS,
];

const INSURANCE_STATUSES = {
    ACTIVE: 'Active',
    EXPIRED: 'Expired',
    PENDING: 'Upcoming',
};

const INSURANCE_ACTIVE = { label: INSURANCE_STATUSES.ACTIVE, value: 'current' };
const INSURANCE_PENDING = { label: INSURANCE_STATUSES.PENDING, value: 'pending' };
const INSURANCE_EXPIRED = { label: INSURANCE_STATUSES.EXPIRED, value: 'expired' };

const INSURANCE_EXPIRED_OPTIONS = [
    NONE_SELECTED_OPTION,
    INSURANCE_ACTIVE,
    INSURANCE_PENDING,
    INSURANCE_EXPIRED,
];

export {
    INSURANCE_TYPES,
    INSURANCE_TYPE_OPTIONS,
    INSURANCE_TYPE_OPTIONS_NONE_SELECTED,
    INSURANCE_EXPIRED_OPTIONS,
    INSURANCE_STATUSES,
};
