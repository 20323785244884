import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { H1 } from '@blueprintjs/core';

import { Service } from 'interface/Service';
import { Response } from 'service';
import { ButtonLink, Icon } from 'components/elements';

interface ServiceProps extends RouteComponentProps<any> {
    service: Service;
}

const ServiceInfo = (props: ServiceProps) => {
    const { service, match } = props;

    const displayImage = () => {
        return Response.getLink(service, 'image') ? (
            <img
                className="badge-img"
                alt={Response.getLinkAttribute(service, 'image', 'title')}
                title={Response.getLinkAttribute(service, 'image', 'title')}
                src={
                    process.env.REACT_APP_BASE_URL +
                    Response.getLinkAttribute(service, 'image', 'publicUrl')
                }
            />
        ) : null;
    };

    return (
        <div className="ServiceDetails">
            <H1>
                Info
                <div className="float-right">
                    <ButtonLink
                        type="button"
                        to={`${match.url}/edit`}
                        intent="primary"
                        className="me-3"
                    >
                        <Icon icon="lock-open" />
                        Edit
                    </ButtonLink>
                </div>
            </H1>
            <dl className="horizontal">
                <dt>Name</dt>
                <dd>{service.name}</dd>
                <dt>Tree Path</dt>
                <dd>{service.treePath}</dd>
                <dt>Requires Certificate</dt>
                <dd>{service.requiresCertificate ? 'Yes' : 'No'}</dd>
                <dt>Is Active</dt>
                <dd>{service.isActive ? 'Yes' : 'No'}</dd>
                <dt>Image</dt>
                <dd>{displayImage()}</dd>
            </dl>
        </div>
    );
};

export default withRouter(ServiceInfo);
