import React, { Component } from 'react';
import PropTypes from 'prop-types';
import StateHandler from 'service/StateHandler';
import { FormGroup } from '@blueprintjs/core';
import HTMLSelect from 'components/elements/HTMLSelect';

class SingleSelect extends Component {
    static propTypes = {
        filterKey: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        options: PropTypes.array.isRequired,
        placeholder: PropTypes.string,
        theValue: PropTypes.string,
    };

    static defaultProps = {};

    constructor(props) {
        super(props);

        this.state = {
            value: props.defaultValue,
        };

        this.handleChange = this.handleChange.bind(this);
    }

    render() {
        return (
            <div className="FilterSingleSelect">
                <FormGroup
                    label={this.props.label}
                    inline={true}
                    className="form-fill"
                    labelFor={this.props.filterKey}
                >
                    <HTMLSelect
                        id={this.props.filterKey}
                        fill={true}
                        defaultValue={this.state.value}
                        onChange={ this.onChange }
                        onBlur={(event) => {
                            this.handleChange(event);
                        }}
                        options={this.props.options}
                    />
                </FormGroup>
            </div>
        );
    }

    onChange = (event) => {
        this.setState(
            StateHandler.getStateObject(event, {}, 'value'),
            () => {
                this.handleChange(event);
            }
        );
    }

    handleChange(event) {
        this.props.onChange({
            value: this.state.value,
            data: this.state.value,
        }, this.props.filterKey);
    }
}

export default SingleSelect;
