class DragDrop
{
    static getSingularReorderedList(result, sourceArray)
    {
        const { destination, source/*, draggableId*/ } = result;

        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId &&
            destination.index === source.index) {
            return;
        }

        let newQuestions = Array.from(sourceArray);
            newQuestions.splice(source.index, 1);
            newQuestions.splice(destination.index, 0, sourceArray[source.index]);

        return newQuestions;
    }
}

export default DragDrop;
