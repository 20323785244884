import { FormEvent } from 'react';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';

import { Service, ServiceListData } from 'interface/Service';
import { HTTP, Response, Routing } from 'service';

class ServiceService {
    static handleSubmit: (
        e: FormEvent<HTMLFormElement>,
        form: Service,
        history: any,
        match: any,
        reload: () => void,
        isEditing: boolean
    ) => void = async (
        e: FormEvent<HTMLFormElement>,
        form: Service,
        history: any,
        match: any,
        reload: () => void,
        isEditing: boolean
    ) => {
        let response: AxiosResponse | null | void = null;

        if (isEditing) {
            response = await HTTP.action(
                'put',
                Response.getLink(form, 'edit'),
                form,
                {},
                'Unable to update service'
            );
        } else {
            response = await HTTP.action(
                'post',
                '/services/new',
                form,
                {},
                'Unable to create service'
            );
        }
        if (response) {
            if (isEditing) {
                toast.success('Service updated');
                if (typeof reload === 'function') {
                    reload();
                }
            } else {
                toast.success('Service created');
            }

            Routing.navigateUpOneLevel(history, match);
        }
    };

    static loadServices: (name?: string) => Promise<ServiceListData> = async (
        name?: string
    ) => {
        const response: AxiosResponse | void = await HTTP.action(
            'get',
            '/services',
            name ? { name: name } : {},
            {},
            'Unable to load service list'
        );

        if (response && response.data) {
            return response.data as ServiceListData;
        } else {
            return {
                services: [],
            } as ServiceListData;
        }
    };

    static loadParent: (service: Service) => Promise<Service> = async (
        service: Service
    ) => {
        const url = Response.getLink(service, 'parent');

        if (url) {
            const response: AxiosResponse | void = await HTTP.action(
                'get',
                url,
                {},
                {},
                'Unable to load service list'
            );

            if (response && response.data) {
                return response.data as Service;
            }
        }

        return {} as Service;
    };

    static loadService: (id: string) => Promise<Service> = async (
        id: string
    ) => {
        const response: AxiosResponse | void = await HTTP.action(
            'get',
            '/services/' + id,
            {},
            {},
            'Unable to load service'
        );
        if (response) {
            return response.data as Service;
        } else {
            return this.createService();
        }
    };

    static createService(): Service {
        return {
            id: '',
            name: '',
            isActive: false,
            requiresCertificate: false,
            treePath: '',
        } as Service;
    }
}

export default ServiceService;
